import React from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Image, Input, Text, Tooltip} from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import UploadIcon from '../../../assets/images/upload.svg'
import DownloadIcon from '../../../assets/images/download.png'
import { Document, Page, pdfjs } from 'react-pdf';
import { InfoOutlineIcon } from '@chakra-ui/icons'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SoloPrioDocument({refs, fileData, userData, rejectData, isReject, pageNumber, showOpenFileDialog, onChangeFile}) {

  const displayGst = () => {
    if (typeof(fileData?.gst) === 'string') {
      return (
        <>
          {fileData?.gst && (fileData?.gst.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.gst} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.gst} download="uploaded_gst.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.gst} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.gst && (fileData?.gst.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.gst} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.gst)} download="uploaded_gst.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.gst ? URL.createObjectURL(fileData?.gst) : UploadIcon}
              className={fileData?.gst ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  const displayBankStatement = () => {
    if (typeof(fileData?.bankStatement) === 'string') {
      return (
        <>
          {fileData?.bankStatement && (fileData?.bankStatement.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.bankStatement} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.bankStatement} download="uploaded_bankStatement.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.bankStatement} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.bankStatement && (fileData?.bankStatement.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.bankStatement} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.bankStatement)} download="uploaded_bankStatement.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.bankStatement ? URL.createObjectURL(fileData?.bankStatement) : UploadIcon}
              className={fileData?.bankStatement ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayIncorpCertificate = () => {
    if (typeof(fileData?.incorpCertificate) === 'string') {
      return (
        <>
          {fileData?.incorpCertificate && (fileData?.incorpCertificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.incorpCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.incorpCertificate} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.incorpCertificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.incorpCertificate && (fileData?.incorpCertificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.incorpCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.incorpCertificate)} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.incorpCertificate ? URL.createObjectURL(fileData?.incorpCertificate) : UploadIcon}
              className={fileData?.incorpCertificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  const Udyam_certificate = () => {
    if (typeof(fileData?.udyam_certificate) === 'string') {
      return (
        <>
          {fileData?.udyam_certificate && (fileData?.udyam_certificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.udyam_certificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.udyam_certificate} download="uploaded_rbi.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.udyam_certificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.udyam_certificate && (fileData?.udyam_certificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.udyam_certificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.udyam_certificate)} download="uploaded_rbi.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.udyam_certificate ? URL.createObjectURL(fileData?.udyam_certificate) : UploadIcon}
              className={fileData?.udyam_certificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  const displayPartnershipDeed = () => {
    if (typeof(fileData?.partnership_deed) === 'string') {
      return (
        <>
          {fileData?.partnership_deed && (fileData?.partnership_deed.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.partnership_deed} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.partnership_deed} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.partnership_deed} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.partnership_deed && (fileData?.partnership_deed.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.partnership_deed} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.partnership_deed)} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.partnership_deed ? URL.createObjectURL(fileData?.partnership_deed) : UploadIcon}
              className={fileData?.partnership_deed ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  const displayRegActCertificate = () => {
    if (typeof(fileData?.reg_act_certificate) === 'string') {
      return (
        <>
          {fileData?.reg_act_certificate && (fileData?.reg_act_certificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.reg_act_certificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.reg_act_certificate} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.reg_act_certificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.reg_act_certificate && (fileData?.reg_act_certificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.reg_act_certificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.reg_act_certificate)} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.reg_act_certificate ? URL.createObjectURL(fileData?.reg_act_certificate) : UploadIcon}
              className={fileData?.reg_act_certificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  console.log("fileData?.reg_act_certificate",fileData?.reg_act_certificate)
  return (
    <>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">GST Certificate (Optional)
              <Tooltip label="Either GST certificate to be provided or Undertaking on letterhead to be uploaded."  hasArrow fontSize='xs' placement='bottom-start'>
                <InfoOutlineIcon ml={1} />
              </Tooltip>
            </Heading>
            {rejectData?.gst_registration_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.gst_registration_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayGst()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.gst ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('gst')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'gst')}
                className="d-none"
                ref={refs?.gst}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading"> Registration certificate under shop and establishment act. 
              {/* <Tooltip label="Registration certificate under shop and establishment act - if applicable."  hasArrow fontSize='xs' placement='bottom-start'>
                <InfoOutlineIcon ml={1} />
              </Tooltip> */}
            </Heading>
            {rejectData?.reg_act_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.reg_act_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayRegActCertificate()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.reg_act_certificate ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('reg_act_certificate')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'reg_act_certificate')}
                className="d-none"
                ref={refs?.reg_act_certificate}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      {userData?.employment_type === '7' &&
          <GridItem>
            <Box className='form_kyc'>
              <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                <Heading as="h1" className="font-poppins label_heading">Partnership Deed</Heading>
                {rejectData?.partnership_deed_reject_reason &&
                  <Text className="font-poppins">Admin Comment
                    <Tooltip label={rejectData?.partnership_deed_reject_reason}
                      hasArrow fontSize='xs' placement='bottom-start'>
                      <InfoOutlineIcon />
                    </Tooltip>
                  </Text>
                }
              </Box>
              <Box className='upload_container'>
                {displayPartnershipDeed()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.partnership_deed ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('partnership_deed')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'partnership_deed')}
                    className="d-none"
                    ref={refs?.partnership_deed}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
              </Box>
            </Box>
          </GridItem>
      }
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Registration on Udyam Portal (Optional)</Heading>
            {rejectData?.udyam_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.udyam_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {Udyam_certificate()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.udyam_certificate ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('udyam_certificate')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'udyam_certificate')}
                className="d-none"
                ref={refs?.udyam_certificate}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
          <Box className='form_kyc'>
            <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
              <Heading as="h1" className="font-poppins label_heading"> Bank Statement for 3 Months</Heading>
              {rejectData?.v2_bank_statement_reject_reason &&
                <Text className="font-poppins">Admin Comment
                  <Tooltip label={rejectData?.v2_bank_statement_reject_reason}
                    hasArrow fontSize='xs' placement='bottom-start'>
                    <InfoOutlineIcon />
                  </Tooltip>
                </Text>
              }
            </Box>
            <Box className='upload_container'>
              {displayBankStatement()}
                <CustomButton
                  className="btn_theme font-poppins"
                  title={fileData?.bankStatement ? 'Re-Upload' : 'Browse'}
                  onClick={() => showOpenFileDialog('bankStatement')}
                />
                <Input
                  size="md"
                  name="upload"
                  type="file"
                  accept="image/*,.pdf"
                  onChange={(e) => onChangeFile(e, 'bankStatement')}
                  className="d-none"
                  ref={refs?.bankStatement}
                />
                <Heading mt={4} as="p" className="font-poppins note">
                  Supported File .png, .jpg & .pdf Upto 5 MB
                </Heading>
            </Box>
          </Box>
        </GridItem>
      
    </>
  )
}
