import React, { useState } from 'react'
import {Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import Pagination from '../../../../elements/Pagination';

export default function ManualInvestTable(props) {
    const isCompleted = props?.isKycCompleted

  return (
    <Box mt={3} className='custom_table_wrapper'>
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Loan Application ID</Th>
                        <Th>Loan Amount </Th>
                        <Th>Alpha Score</Th>
                        <Th>Tenure</Th>
                        <Th>Loan Type</Th>
                        <Th>Interest Rate p.a. </Th>
                        <Th>Repayment History</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.manualInvestData?.slice(props.offset, props.offset + props.PER_PAGE)?.map((item, index) => {
                        return (
                            <Tr key={index}>
                                <Td>
                                    <Text className="font-poppins table_text">{item.loan_number}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.loan_amount}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.alpha_rating}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">
                                        {item.tenure_duration}
                                    </Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">
                                        {item.products.name}
                                    </Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">
                                        {item?.products?.investor_return + "%"}
                                    </Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text green">
                                        {item?.repayment_behaviour?.on_time_count}/{item?.repayment_behaviour?.total_countemi} {' '}
                                        <span>{item?.repayment_behaviour?.percentage ? '(' + item?.repayment_behaviour?.percentage?.toFixed(2) + '%)' : "(N/A)"}</span>
                                    </Text>
                                </Td>
                                <Td>
                                    <Box className='actions_td'>
                                        <CustomButton size="md" type="submit" title="View & Invest" className="btn_theme font-poppins" onClick={() => props.handleOpenInvestSidebar(item)} isDisabled={isCompleted ? false : true} />
                                    </Box>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
        <Pagination pageCount={props.pageCount} handlePageClick={props.handlePageClick} />
    </Box> 
  )
}
