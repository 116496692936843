import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import authReducers from './authReducers'
import kycReducers from './kycReducers'
import masterReducers from './masterReducers'
import adminReducers from './adminReducers'
import borrowerReducers from './borrowerReducers'
import investorReducer from './investorReducer'
import cpReducer from './cpReducers'
import reportsReducer from './reportsReducers'
import salesReducer from './salesReducers'

const appReducer = combineReducers({
  routerState: routerReducer,
  authState: authReducers,
  kycState: kycReducers,
  masterState: masterReducers,
  adminState: adminReducers,
  borrowerState: borrowerReducers,
  investorState:investorReducer,
  cpState:cpReducer,
  reportState: reportsReducer,
  salesState: salesReducer,
})

const rootReducer = (state, action) => {
//   if (action.type === 'AUTH_LOGOUT_USER') {
//     return appReducer(undefined, action)
//   }
  return appReducer(state, action)
}

export default rootReducer
