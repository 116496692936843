import React, { useEffect, useRef, useState } from 'react'
import {Box, Grid, GridItem, Heading, Image, Input, Select, Text} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import CustomCard from '../../../elements/CustomCard'
import LoanChart from './components/LoanChart';
import LoanSlider from './components/LoanSlider';
import CreditScore from './components/CreditScore';
import LoanCalender from './components/LoanCalender'; 
import FundsInProcess from '../../../../assets/images/fundInProces.svg'
import FundsTransfer from '../../../../assets/images/fundTransfer.svg'
import {useHistory, Link} from 'react-router-dom'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import * as routesNames from '../../../../constants/routes'
import { setItem } from '../../../../utilities/authUtils'
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../../redux/actions';
import { connect, useSelector } from 'react-redux';
import Loader from '../../../elements/loader';
import CustomButton from '../../../elements/customButton';
import CompleteKYCDialog from '../components/CompleteKYCDialog';
import UnderWriterStepIcon from '../../../../assets/images/underwritterStep.svg'
import LoanEligible from '../../../../assets/images/loanEligible.svg'
import ImportantDialog from '../../../elements/ImportantDialog';
import PaymentDialogBorrower from '../../../modules/PaymentModule/PaymentDialogBorrower';
import RejectKYCDialog from '../components/RejectKYCDialog';

const BorrowerDashboard = (props) => {

  const borrowerState = useSelector((state) => state.borrowerState)
  const masterState = useSelector((state) => state.masterState)
  const history = useHistory()
  const [score, setScore] = useState(0)
  const [openCommpleteKYCModal, setOpenCommpleteKYCModal] = useState(false)
  const [openRepayModal, setOpenRepayModal] = useState(false)
  const [payableAmount, setPayableAmount] = useState(null)
  const [loanNumber, setLoanNumber] = useState(null)
  const [isKYCReject, setIsKYCReject] = useState(false)
  const userDetails = borrowerState?.getUserDashboardDetails?.data?.data
  const userData = masterState?.getCurrentUserDetails?.data?.data
  const borrowerData = borrowerState?.getBorrowerDetails?.data?.data
  const creditData = borrowerState?.getBorrowerCreditData?.data?.data
  const loanListStatusData = borrowerState?.getLoanListStatus?.data?.data
  const loanJourneyData = borrowerState?.trackLoanJourney?.data?.data
  const isReject = userData?.[0]?.document_data?.doc_approval_status === 1
  const rejectData = userData?.[0]?.user_document_checked_data?.document &&  JSON.parse(userData?.[0]?.user_document_checked_data?.document)

  useEffect(() => {
    setTimeout(() => {
      if(borrowerData?.credit_score){
        setScore(borrowerData?.credit_score);
      } else{
        setScore(0);
      }
    }, 2000);
  }, [borrowerData]);

  useEffect(() => {
    props.actions.getCurrentUserDetailsAction()
    props.actions.getUserDashboardDetailsAction()
    props.actions.getBorrowerDetailsAction()
    props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
    setItem('iskyc',userDetails?.user?.iskyc)
  }, []);

  useEffect(() => {
    if (masterState?.getCurrentUserDetails?.data?.success === true){
      props.actions.getBorrowerCreditDataAction({id: userData?.[0]?.id})
      // props.actions.getBorrowerCreditDataAction({id: '1175'})
    }
  }, [masterState?.getCurrentUserDetails]);

  useEffect(() => {
    if(loanListStatusData?.[0]?.id) {
        props.actions.trackLoanJourneyAction({loanId: loanListStatusData?.[0]?.id})
    }
}, [loanListStatusData]);

  useEffect(() => {
    if (isKYCPending) {
      setOpenCommpleteKYCModal(true)
    } else if (isKYCCompleted && loanListStatusData?.length === 0) {
      setOpenCommpleteKYCModal(true)
    } else if (isKYCUnderReview) {
      setOpenCommpleteKYCModal(false)
    }
  }, [borrowerState?.getUserDashboardDetails])

  useEffect(() => {
    if (isReject) {
      setIsKYCReject(true)
    }
  }, [isReject])

  const data = {
    labels: [
      borrowerData?.insta_loan ? `Insta Loan (${borrowerData?.insta_loan})` : 'Insta Loan ()',
      borrowerData?.personal_loan ? `Personal Loan (${borrowerData?.personal_loan})` : 'Personal Loan ()',
      borrowerData?.bullet_loan ? `Bullet Loan (${borrowerData?.bullet_loan})` : 'Bullet Loan ()'
    ],
    datasets: [
      {
        data: [
          borrowerData?.insta_loan,
          borrowerData?.personal_loan,
          borrowerData?.bullet_loan,
        ],
        backgroundColor: ['#97B1AB', '#357066', '#1A3732'],
        hoverBackgroundColor: ['#97B1AB', '#357066', '#1A3732'],
      }
    ]
  };
  
  const options = {
    layout: {
      padding: {
        right: 15
      }
   },
    legend: {
      display: true,
      onClick: function(e, legendItem) {
        e.preventDefault();
        e.stopPropagation();
      },
      position: 'right',
      labels: {
        fontFamily: "Poppins",
        fontColor: '#000',
        fontSize: 12,
        fontStyle: 'normal',
        usePointStyle: true,
        padding: 30,
      },
    },
    maintainAspectRatio: true,
    cutoutPercentage: 70,
    tooltips: {
      position: 'nearest',
      backgroundColor: 'rgba(255,255,255,1)',
      titleFontColor: '#000',
      bodyFontColor: '#000',
      titleFontSize: 14,
      titleFontFamily: "Poppins",
      bodyFontFamily: "Poppins",
      bodyFontSize: 14,
      bodySpacing: 4,
      xPadding: 12,
      yPadding: 12,
      borderColor: '#D3D2D5',
      borderWidth: 1,
      displayColors: false,
      // xAlign: 'right',
      callbacks: {
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index]; // Display the label as the title
        },
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          // const formattedValue = value.toLocaleString('en-US');
          // return `(${'₹ ' + formattedValue})`;
          return value
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
   },
  };

  const calculateRotation = () => {
    if (score === 0 || (score >= 1 && score <= 299)) {
      return 0;
    }
    const minScore = 300;
    const maxScore = 900;
    const rotationRange = 225;
    const min = (score - minScore)
    const max = (maxScore - minScore);
    const percentage = min / max
    const rotation = percentage * rotationRange;
    return rotation;
  };

  const rotationNumber = calculateRotation()

  const handleCloseKYCModal = () => {
    setOpenCommpleteKYCModal(false)
  }

  const handleCloseRejectKYCModal = () => {
    setIsKYCReject(false)
  }

  const handleStart = () => {
    if (isKYCPending) {
      history.push(routesNames.BORROWERKYC)
    } else if (isKYCCompleted) {
      history.push(routesNames.BORROWERAPPLYLOAN)
    }
  }

  // handle loan Payment pay now direct Dialog open
  const handleOpenLoanPaymentDialog = (amount, contractNumber) => {
    setPayableAmount(amount)
    setLoanNumber(contractNumber)
    setOpenRepayModal(true)
  }

  // handle loan Payment pay now direct Dialog close
  const handleCloseLoanPaymentDialog = () => {
    setPayableAmount(null)
    setLoanNumber(null)
    setOpenRepayModal(false)
  }

  const handleStartKYCAgain = () => {
    history.push(routesNames.BORROWERKYC)
  }

  const handleApplyLoan = () => {
    history.push(routesNames.BORROWERAPPLYLOAN)
  }

  const handleViewStatus = () => {
    history.push(routesNames.BORROWERLOANSTATUS)
  }

  const handleUpdateKYC = () => {
    history.push(routesNames.BORROWERKYC)
  }

  const handleProfile = () => {
    history.push(routesNames.BORROWERPROFILE)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  const isKYCPending = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 0)
  const isKYCUnderReview = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 1)
  const isKYCCompleted = (userDetails?.user?.iskyc === 1 && userDetails?.user?.form_completed_status === 1)
  const checkENachReq = (loanListStatusData?.[0]?.enach_request && loanListStatusData?.[0]?.enach_request.status === 0)
  const journeyCurrentStep = loanJourneyData?.filter((item) => (item.status_id))
  const totalFunded = (loanListStatusData?.[0]?.committed_loan_amount / loanListStatusData?.[0]?.eligible_loan_amt) * 100

  const renderStepDots = () => {
    let arr = []
    loanJourneyData?.map((item, index) => {
        arr.push(<Box className={item.status_level === 'green' ? 'rounded_circle green_circle' : item.status_level === 'red' ? 'rounded_circle red_circle' : 'rounded_circle'} key={index} />)
    })
    return arr
  }

  return (
    <Box>
      {(borrowerState?.getUserDashboardDetails?.loading || masterState?.getCurrentUserDetails?.loading || borrowerState?.getBorrowerDetails?.loading ||
      borrowerState?.getBorrowerCreditData?.loading || borrowerState?.getLoanListStatus?.loading) &&
        <div>
            <Loader />
        </div>
      }
      <Grid className="main_grid_container">
        <GridItem colSpan={8}>
          <Box className='dashboard_box bg_shadow'>
            <Box className='d-flex align-items-center justify-content-between'>
              <Heading as="h2" className='font-poppins page_heading'>Dashboard</Heading>
              <ImportantDialog />
            </Box>
            {isKYCUnderReview ?
              <Box mt={4} className='kyc_verify'>
                  <Heading as="h3" className='font-poppins'>
                    <Image src={IconkYC} />
                    Your documents under review
                    <Link to={routesNames.BORROWERKYC}>Tap to check your documents</Link>
                  </Heading> 
              </Box> : isKYCPending ?
              <Box mt={4} className='kyc_verify'>
                <Heading as="h3" className='font-poppins'>
                    <Image src={IconkYC} />
                    Your ALPHAMONEY account needs Verification,
                    <Link to={routesNames.BORROWERKYC}>Tap to start your KYC</Link>
                </Heading>
              </Box> : null
            }
            <Box mt={4}>
              <Grid className="main_grid_container">
                <GridItem colSpan={3}>
                  <CustomCard className="cardOne" title="Outstanding Amt." value={borrowerData?.outstanding_amount ? `₹ ${borrowerData?.outstanding_amount}` : 0} />
                </GridItem>
                <GridItem colSpan={3}>
                  <CustomCard className="cardTwo" title="Total Disbursed Amt." value={borrowerData?.total_disb_amount ? `₹ ${borrowerData?.total_disb_amount}` : 0} />
                </GridItem>
                <GridItem colSpan={3}>
                  <CustomCard className="cardThree" title="Total Repaid Amt." value={borrowerData?.total_repaid_amount ? `₹ ${borrowerData?.total_repaid_amount}` : 0} />
                </GridItem>
                <GridItem colSpan={3}>
                  <CustomCard className="cardFour" title="Total Loan Raised" value={borrowerData?.total_loan ? borrowerData?.total_loan : 0} />
                </GridItem>
              </Grid>
            </Box>
            <Box mt={5}>
              <Grid className="main_grid_container">
                <GridItem colSpan={6}>
                  <LoanChart data={data} options={options} chartData={borrowerData} />
                </GridItem>
                <GridItem colSpan={6}>
                  <LoanSlider loansData={borrowerData?.active_loans} handleUpdateKYC={handleUpdateKYC} isKYCCompleted={isKYCCompleted}
                    handleApplyLoan={handleApplyLoan} handleOpenLoanPaymentDialog={handleOpenLoanPaymentDialog}
                  />
                </GridItem>
              </Grid>
            </Box>
            <LoanCalender creditData={creditData} />
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          <NavBar data={userData} handleProfile={handleProfile} />
          <Box mt={4} className='alpha_score bg_shadow'>
            <Heading as="h2" className='font-poppins alpha_score_heading'>ALPHA Score</Heading>
            <Box className='score_bg'>
              <Heading as="h1" className='font-poppins alpha_score_data'>{borrowerData?.alpha_rating ? borrowerData?.alpha_rating : 'N/A'}</Heading>
            </Box>
          </Box>
          <CreditScore score={score} calculateRotation={rotationNumber} />
          <Box mt={4} className='credit_score bg_shadow'>
            {loanListStatusData?.length > 0 ?
              <Box>
                <Heading mb={5} as="h2" className='font-poppins card_heading'>Loan Application Status</Heading>
                <Box className='loan_status_shadow' mb={4}>
                  {journeyCurrentStep?.slice(-1)?.map((elem, index) => {
                    // const completedArr = ['Received', 'Done', 'Checked', 'Pledged', 'In Process'].includes(elem.state_status);
                    return (
                      <Box className='loan_status_box' mb={8} key={index}>
                        <Image src={elem.state_name === 'underwriting_loan_process' ? UnderWriterStepIcon : elem.state_name === 'loan_eligibility' ? FundsInProcess : elem.state_name === 'funding_in_process' ? FundsInProcess : elem.state_name === 'fund_transfer' ? FundsTransfer :  null} />
                        <Box className='loan_status_label'>
                          <Heading as="h3" className='font-poppins'>{elem.state_label}</Heading>
                          <Heading as="h4" className={elem.status_level === 'green' ? 'completed_status status font-poppins' : 'status font-poppins'}>{elem.state_status ? elem.state_status : 'Pending`'}</Heading>
                          {elem?.status_id === 5 ?
                            <Box mt={1} className='action_status'>
                              <Text className="font-poppins">(Waiting For Action)</Text>
                            </Box> : null
                          }
                          {(elem.status_id === 7 && elem.state_name === 'funding_in_process') ?
                            <Heading mt={2} as="p" className={totalFunded === 100 ? 'completed_status status font-poppins' : 'status font-poppins'}>
                                {`(${'₹'}${Number(loanListStatusData?.[0]?.committed_loan_amount)})${' '}${totalFunded.toFixed(0)}${'%'}${' '}Funded Done`}
                            </Heading> :
                          (!elem.status_id && elem.state_name === 'funding_in_process') ?
                            <Heading mt={2} as="p" className={totalFunded === 100 ? 'completed_status status font-poppins' : 'status font-poppins'}>
                                {`(${'₹'}${0})${' '}${totalFunded.toFixed(0)}${'%'}${' '}Funded`}
                            </Heading> : null
                          }
                          {(loanListStatusData?.[0]?.loan_status === '3' && elem?.status_id === 10 && !(loanListStatusData?.[0]?.esign_request)) ?
                              <Box mt={1} className='action_status'>
                                <Text className="font-poppins">(Waiting For Action)</Text>
                              </Box> : 
                            (checkENachReq && elem?.status_id === 11) ?
                              <Box mt={1} className='action_status'>
                                 <Text className="font-poppins">(Waiting For Action)</Text>
                              </Box> : null
                          }
                        </Box>
                      </Box>
                    )
                  })}
                  <Box className='rounded-flex'>
                    {renderStepDots()}
                  </Box>
                </Box>
                <Box className='d-flex justify-content-end'>
                  <CustomButton size="md" type="submit" title="View" className="btn_theme font-poppins" onClick={handleViewStatus} />
                </Box>
              </Box> :
              <Box>
                <Heading mb={5} as="h2" className='font-poppins card_heading'>Apply Loan</Heading>
                <Box mb={4} className='form_inputs_apply'>
                  <Heading as="h5" className='font-poppins'>Loan Type</Heading>
                  <Select placeholder="Please Select Loan Type" variant="filled" className="font-poppins input_box_select" name="loan_type">
                    <option>Insta Loan</option>
                    <option>Bullet Loan</option>
                    <option>Personal Loan</option>
                  </Select>
                </Box>
                <Box mb={5} className='form_inputs_apply'>
                  <Heading as="h5" className='font-poppins'>Loan Amount</Heading>
                  <Input size="md" name="loan_amount"  type="text" placeholder="Please Enter Loan Amount" className="input_box font-poppins"
                      // onChange={handleChange} value={inputValues?.otp ?? ''}
                  />
                </Box>
                <Box className='d-flex justify-content-end'>
                  <CustomButton size="md" type="submit" title="Next" className="btn_theme font-poppins" onClick={handleApplyLoan}
                    isDisabled={isKYCUnderReview || isKYCPending}
                  />
                </Box>
              </Box>
            }
          </Box>
        </GridItem>
      </Grid>
      <CompleteKYCDialog
        handleCloseKYCModal={handleCloseKYCModal}
        handleStart={handleStart}
        openCommpleteKYCModal={openCommpleteKYCModal} 
        isKYCCompleted={isKYCCompleted}
      />
      <PaymentDialogBorrower title="Loan Repayment" openPayModal={openRepayModal} payableAmount={payableAmount}
        handleCloseLoanPaymentDialog={handleCloseLoanPaymentDialog} userData={userData} loanNumber={loanNumber}
      />
      <RejectKYCDialog isKYCReject={isKYCReject} handleCloseRejectKYCModal={handleCloseRejectKYCModal}
        handleStart={handleStartKYCAgain} rejectData={rejectData}
      />
    </Box>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(BorrowerDashboard)
