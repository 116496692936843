import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import Pagination from '../../../elements/Pagination'
import NoData from '../../../elements/NoData'

export default function LenderDetailsTable(props) {

    const reportFile = props?.fileUrl
    const reportItems = props?.reportData
    const tableHeaders = reportItems?.length > 0 ? Object.keys(reportItems[0]) : [];
    const reportLength = props?.fileLength?.userslength
    const pageCount = Math.ceil(reportLength / props.PER_PAGE);

  return (
    <>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        {reportItems?.length > 0 ?
            <Box mt={2}>
                <Box className='d-flex justify-content-end'>
                    <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
                </Box>
                <Box mt={3} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    {tableHeaders?.map((item, index) => (
                                        <Th key={index}>{item}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {reportItems?.map((item, index) => {
                                    return (
                                        <Tr key={index}>
                                            {tableHeaders?.map((elem, idx) => (
                                                <Td key={idx}>
                                                    <Text className="font-poppins table_text">{item[elem] || 'N/A'}</Text>
                                                </Td>
                                            ))}
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={props.handlePageClick} />
                </Box>
            </Box> :
            <NoData title="No Record Found" />
        }
    </>
  )
}
