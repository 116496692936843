import apiInstance from '../config/api/axios'

export const getSummaryReport = async(payload) => {
    const response = await apiInstance.post('/v1/sales/getSummaryReport', payload)
    return response
}

export const getLenderReport = async(payload, query) => {
    // const response = await apiInstance.post('/v1/sales/getLenderData', payload)
    const response = await apiInstance.post(`/v1/sales/getLenderData/query?page=${query.page}&limit=${query.limit}`, payload)
    return response
}

export const getBorrowerReport = async(payload, query) => {
    const response = await apiInstance.post(`/v1/sales/getBorrowerData/query?page=${query.page}&limit=${query.limit}`, payload)
    return response
}

export const getBorrowerLoanReport = async(payload, query) => {
    // const response = await apiInstance.post('/v1/sales/getLoanData', payload)
    const response = await apiInstance.post(`/v1/sales/getLoanData/query?page=${query.page}&limit=${query.limit}`, payload)
    return response
}

export const getBorrowerRepaymentReport = async(payload, query) => {
    // const response = await apiInstance.post('/v1/sales/getRepaymentDetails', payload)
    const response = await apiInstance.post(`/v1/sales/getRepaymentDetails/query?page=${query.page}&limit=${query.limit}`, payload)
    return response
}