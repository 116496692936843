import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { Bar } from 'react-chartjs-2';

export default function InvestorStackedChart(props) {
  return (
    <Box className='investor_chart' mt={4}>
      <Heading as="h2" className='font-poppins card_heading'>Principal Received Per Month & Expected</Heading>
      <Box mt={6}>
        <Bar options={props.options} data={props.data} />
      </Box>
    </Box>
  )
}
