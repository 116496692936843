import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import NewApplication from './components/NewApplication'
import ApprovedApplication from './components/ApprovedApplication'
import RejectedApplication from './components/RejectedApplication'
import AssignedFinops from './components/AssignedFinops'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { ActionCreators } from '../../../../redux/actions'
import Loader from '../../../elements/loader'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, setItem } from '../../../../utilities/authUtils'
import CustomToast from '../../../elements/customToast'
import { getCreditScore } from '../../../../services/masterServices'

const tabNames = [
  {
      id: 1,
      name: 'New Application',
  },
  {
      id: 2,
      name: 'Approved Application',
  },
  {
      id: 3,
      name: 'Rejected Application',
  },
  {
    id: 4,
    name: 'Assigned To FinOps',
  },
]

const LoanApplication = (props) => {

  const history = useHistory()
  const {addToast} = CustomToast()
  const adminState = useSelector((state) => state.adminState)
  const masterState = useSelector((state) => state.masterState)
  const [isLoading, setIsLoading] = useState(false)
  const [filterNew, setFilterNew] = useState([])
  const [filterApproved, setFilterApproved] = useState([])
  const [filterRejected, setFilterRejected] = useState([])
  const loanData = adminState?.getApprovedBorrowerLoanData?.data?.data
  const userData = adminState?.getUserByToken?.data?.data
  const userType = userData?.usertype

  useEffect(() => {
    props?.actions?.getApprovedBorrowerLoanDataAction({status: 'all'})
    props.actions.getUserByTokenAction()
  }, [])
  const rejectedApplication = loanData?.filter((item) => item.loan_status === '7' && item?.product_id !== 3)
  const newApplication = loanData?.filter((item) => item.loan_status === '0')
  const approvedApplication = loanData?.filter((item) => (item.loan_status === '5' || item.loan_status === '4' || item.loan_status === '3' || item.loan_status === '1' || item.loan_status === '2') && item?.product_id !== 3)
  useEffect(() => {
    if (adminState?.getApprovedBorrowerLoanData?.data?.success === true) {
      setFilterApproved(approvedApplication)
      setFilterRejected(rejectedApplication)
      setFilterNew(newApplication)
    }
  }, [adminState?.getApprovedBorrowerLoanData])

  const handleVerify = (id, loanNumber) => {
    setItem('userID', id)
    setItem('loanNumber', loanNumber)
    let data = {
      user_id: id
    }
    setIsLoading(true)
    getCreditScore(data).then((res) => {
      setIsLoading(false)
      if (res?.data?.status === true && res?.data?.success === true) {
        history.push(routesNames.UNDERWRITERPROCESS)
      } else if (res?.data?.status === false || res?.data?.success === false) {
        addToast({message: 'Something went Wrong while we are feching credit score for this user. Please try after sometime.', status: 'error'})
        history.push(routesNames.UNDERWRITERLOANAPPLICATION)
      }
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        addToast({message: error?.response?.data?.msg, status: 'error'})
        setIsLoading(false)
      }
    })
  }

  const handleSearchNewApplication = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = newApplication.filter((item) => {
        return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterNew(result)
    } else {
      setFilterNew(newApplication)
    }
  }

  const handleSearchApproveApplication = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = approvedApplication.filter((item) => {
        return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterApproved(result)
    } else {
      setFilterApproved(approvedApplication)
    }
  }

  const handleSearchRejectedApplication = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = rejectedApplication.filter((item) => {
        return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterRejected(result)
    } else {
      setFilterRejected(rejectedApplication)
    }
  }

  const handleView = (id, loanNumber) => {
    setItem('userID', id)
    setItem('loanNumber', loanNumber)
    history.push(routesNames.UNDERWRITERVIEWSUMMARY)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  return (
    <>
      {(isLoading || adminState?.getApprovedBorrowerLoanData?.loading || masterState?.getCreditScore?.loading || adminState?.getUserByToken?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
              <GridItem colSpan={8}>
                  <Heading as="h2" className='font-poppins page_heading'>Loan Applications</Heading>
              </GridItem>
              <GridItem colSpan={4}>
                  <NavBar data={userData} isAdmin />
              </GridItem>
          </Grid>
          <Box mt={5}>
              <Tabs className='tabs_container'>
                  <TabList className='tabs_list'>
                      {tabNames.map((item, index) => {
                        return (
                          <Tab key={index}>{item.name}</Tab>
                        )
                      })}
                  </TabList>
                  <TabPanels>
                      {tabNames.map((item, index) => {
                        return (
                          <TabPanel key={index} className='tab_panel'>
                            {item.name === 'New Application' &&
                              <NewApplication
                                handleVerify={handleVerify}
                                userType={userType}
                                handleSearch={handleSearchNewApplication}
                                filterNew={filterNew}
                              />
                            }
                            {item.name === 'Approved Application' &&
                              <ApprovedApplication
                                handleView={handleView}
                                handleSearch={handleSearchApproveApplication}
                                filterApproved={filterApproved}
                              />
                            }
                            {item.name === 'Rejected Application' &&
                              <RejectedApplication
                                handleView={handleView}
                                handleSearch={handleSearchRejectedApplication}
                                filterRejected={filterRejected}
                              />
                            }
                            {item.name === 'Assigned To FinOps' && <AssignedFinops />}
                          </TabPanel>
                        )
                      })}
                  </TabPanels>
              </Tabs>
          </Box>
      </Box>
      </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(LoanApplication)