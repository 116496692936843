import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import AppLayout from '../../../config/default/default'
import UpdateProfile from '../../modules/KYCModule/UpdateProfile'
import BorrowerDashboard from '../borrow/Dashboard/BorrowerDashboard'
import ApplyLoan from '../borrow/LoanApplications/ApplyLoan'
import LoanStatus from '../borrow/LoanApplications/LoanStatus'
import ActiveLoan from '../borrow/AllLoans/ActiveLoan'
import ClosedLoan from '../borrow/AllLoans/ClosedLoan'
import ExpiredLoan from '../borrow/AllLoans/ExpiredLoan'
import WithdrawnLoan from '../borrow/AllLoans/WithdrawnLoan'
import BorrowerProfile from '../borrow/BorrowersProfile/BorrowerProfile'
import NotFound from '../NotFound'

export default function BorrowerRoutes() {
  return (
    <>
      <Route exact path={routesNames.BORROWERKYC} component={UpdateProfile}/>
      <AppLayout>
        <Switch>
          <Route exact path={routesNames.BORROWERDASHBOARD} component={BorrowerDashboard} />
          <Route exact path={routesNames.BORROWERAPPLYLOAN} component={ApplyLoan} />
          <Route exact path={routesNames.BORROWERLOANSTATUS} component={LoanStatus} />
          <Route exact path={routesNames.BORROWERACTIVELOAN} component={ActiveLoan} />
          <Route exact path={routesNames.BORROWERCLOSEDLOAN} component={ClosedLoan} />
          <Route exact path={routesNames.BORROWEREXPIREDLOAN} component={ExpiredLoan} />
          <Route exact path={routesNames.BORROWERWITHDRAWNLOAN} component={WithdrawnLoan} />
          <Route exact path={routesNames.BORROWERPROFILE} component={BorrowerProfile} />
          <Route component={NotFound} />
        </Switch>
      </AppLayout>
    </>
  )
}
