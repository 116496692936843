import { Box, Heading, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text} from '@chakra-ui/react'
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../redux/actions';
import CustomButton from '../../elements/customButton';
import Logo from "../../../assets/images/app_logo.svg";
import CustomToast from '../../elements/customToast';

const DeleteAccount = (props) => {

    const { addToast } = CustomToast()
    const [isDelete, setIsDelete] = useState(false)
    const [confirmDeleted, setConfirmDeleted] = useState(false)
    const [inputValues, setInputValues] = useState({})

    const handleDelete = () => {
        setIsDelete(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            const truncatedValue = value.slice(0, 10);
            setInputValues({ ...inputValues, [name]: truncatedValue });
        } else {
            setInputValues({ ...inputValues, [name]: value })
        }
    }

    const handleSubmit = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues?.name) {
            addToast({message: 'Please Enter Full Name', status: 'error'})
            return
        }
        if (inputValues?.phone_number?.length !== 10) {
            addToast({message: 'Please Enter Valid Mobile Number', status: 'error'})
            return
        }
        if (!emailPattern.test(inputValues?.email)) {
            addToast({ message: 'Please Enter a Valid Email', status: 'error' })
            return
        }
        setConfirmDeleted(true)
    }

    const handleCloseAccount = () => {
        props.handleCloseDeleteAccount()
        setIsDelete(false)
        setConfirmDeleted(false)
    }

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent className='delete_modal_content'>
                <ModalCloseButton />
                <ModalBody className='delete_modal_body' pb={5} pt={6}>
                    {confirmDeleted ?
                        <Box className='delete_body'>
                            <Heading as="h4" mb={6} className='font-poppins'>Account Delete</Heading>
                            <Heading as="h4" className='font-poppins'>Thank you for your request. Your account deletion is in progress and may take 2-4 days to complete. We appreciate your patience.</Heading>
                            <Box mt={6} className='d-flex align-items-center justify-content-center col-gap-15'>
                                <CustomButton size="md" type="submit" title="Go Back" className="btn_theme_transparent font-poppins" onClick={handleCloseAccount} />
                            </Box>
                        </Box> :
                    isDelete ?
                        <Box className='delete_body'>
                            <Box className='app_logo_box' mb={6}>
                                <Image src={Logo} />
                                <Text className="font-poppins">SUPPORT</Text>
                            </Box>
                            <Heading as="h4" className='font-poppins'>Please Enter Your Details to <br/> Delete your ALPHAMONEY Account</Heading>
                            <Box mt={6}>
                                <Box mb={5} className='form_inputs_apply'>
                                    <Heading as="h5" className='font-poppins'>Name</Heading>
                                    <Input size="md" name="name" type="text" placeholder="Please Enter Your Full Name" className="input_box font-poppins"
                                        onChange={handleChange} value={inputValues?.name ?? ''}
                                    />
                                </Box>
                                <Box mb={5} className='form_inputs_apply'>
                                    <Heading as="h5" className='font-poppins'>Phone Number</Heading>
                                    <Input size="md" name="phone_number" type="number" placeholder="Please Enter Your Phone Number" className="input_box font-poppins"
                                        onChange={handleChange} value={inputValues?.phone_number ?? ''}
                                    />
                                </Box>
                                <Box className='form_inputs_apply'>
                                    <Heading as="h5" className='font-poppins'>Emial Id</Heading>
                                    <Input size="md" name="email" type="text" placeholder="Please Enter Email Id" className="input_box font-poppins"
                                        onChange={handleChange} value={inputValues?.email ?? ''}
                                    />
                                </Box>
                            </Box>
                            <Box mt={6} className='d-flex align-items-center justify-content-center col-gap-15'>
                                <CustomButton size="md" type="submit" title="Submit" className="btn_theme font-poppins" onClick={handleSubmit} />
                            </Box>
                        </Box> :
                        <Box className='delete_body'>
                            <Heading as="h4" mb={6} className='font-poppins'>Account Delete</Heading>
                            <Heading as="h4" className='font-poppins'>Are You Sure Want To Delete Your Account</Heading>
                            <Box mt={6} className='d-flex align-items-center justify-content-center col-gap-15'>
                                <CustomButton size="md" type="submit" title="Go Back" className="btn_theme_transparent font-poppins" onClick={handleCloseAccount} />
                                <CustomButton size="md" type="submit" title="Delete" className="btn_theme font-poppins" onClick={handleDelete} />
                            </Box>
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <>
        <Modal
            isCentered
            onClose={handleCloseAccount}
            isOpen={props.openDeleteAccount}
            motionPreset="slideInBottom"
            // size={'xl'}
        >
        {PopupBox()}
        </Modal>
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(DeleteAccount)