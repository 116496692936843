/* eslint-disable no-unused-vars */
import * as kycServices from '../../services/kycServices'
import * as actionTypes from './actionsTypes'
import { getCurrentUserDetailsAction } from './masterActions'

 //actions for post personal details
 const postPersonalDetailsRequest = () => {
  return {
    type: actionTypes.POST_PERSONAL_DETAILS_REQUEST,
  }
}

const postPersonalDetailsSuccess = (data) => {
  return {
    type: actionTypes.POST_PERSONAL_DETAILS_SUCCESS,
    payload: data,
  }
}

const postPersonalDetailsFailure = (error) => {
  return {
    type: actionTypes.POST_PERSONAL_DETAILS_FAILURE,
    payload: error,
  }
}

//postPersonalDetails action
export const postPersonalDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(postPersonalDetailsRequest())
    try {
      const responseData = await kycServices.postPersonalDetails(payload)
      if (responseData?.status === 200) {
        dispatch(postPersonalDetailsSuccess(responseData?.data))
        // dispatch(getCurrentUserDetailsAction())
      } else {
        dispatch(postPersonalDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(postPersonalDetailsFailure(error?.response?.data))
    }
  }

   //actions for get pii data
 const getPiiDataRequest = () => {
  return {
    type: actionTypes.GET_PII_DATA_REQUEST,
  }
}

const getPiiDataSuccess = (data) => {
  return {
    type: actionTypes.GET_PII_DATA_SUCCESS,
    payload: data,
  }
}

const getPiiDataFailure = (error) => {
  return {
    type: actionTypes.GET_PII_DATA_FAILURE,
    payload: error,
  }
}

//getPiiData action
export const getPiiDataAction = (payload) =>
  async(dispatch) => {
    dispatch(getPiiDataRequest())
    try {
      const responseData = await kycServices.getPiiData(payload)
      if (responseData?.status === 200) {
        dispatch(getPiiDataSuccess(responseData?.data))
      } else {
        dispatch(getPiiDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getPiiDataFailure(error?.response?.data))
    }
  }

  //actions for create digi locker
 const createDigiLockerRequest = () => {
  return {
    type: actionTypes.CREATE_DIGI_LOCKER_REQUEST,
  }
}

const createDigiLockerSuccess = (data) => {
  return {
    type: actionTypes.CREATE_DIGI_LOCKER_SUCCESS,
    payload: data,
  }
}

const createDigiLockerFailure = (error) => {
  return {
    type: actionTypes.CREATE_DIGI_LOCKER_FAILURE,
    payload: error,
  }
}

//createDigiLocker action
export const createDigiLockerAction = (payload) =>
  async(dispatch) => {
    dispatch(createDigiLockerRequest())
    try {
      const responseData = await kycServices.createDigiLocker(payload)
      if (responseData?.status === 200) {
        dispatch(createDigiLockerSuccess(responseData?.data))
      } else {
        dispatch(createDigiLockerFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createDigiLockerFailure(error?.response?.data))
    }
  }

//actions for download aadhar data
const getDownloadAadharDataRequest = () => {
  return {
    type: actionTypes.GET_DOWNLOAD_AADHAR_DATA_REQUEST,
  }
}

const getDownloadAadharDataSuccess = (data) => {
  return {
    type: actionTypes.GET_DOWNLOAD_AADHAR_DATA_SUCCESS,
    payload: data,
  }
}

const getDownloadAadharDataFailure = (error) => {
  return {
    type: actionTypes.GET_DOWNLOAD_AADHAR_DATA_FAILURE,
    payload: error,
  }
}

//getDownloadAadharData action
export const getDownloadAadharDataAction = (payload) =>
  async(dispatch) => {
    dispatch(getDownloadAadharDataRequest())
    try {
      const responseData = await kycServices.getdownloadAadhaarData(payload)
      if (responseData?.status === 200) {
        dispatch(getDownloadAadharDataSuccess(responseData?.data))
        dispatch(getCurrentUserDetailsAction())
      } else {
        dispatch(getDownloadAadharDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getDownloadAadharDataFailure(error?.response?.data))
    }
  }

//actions for save bak details
 const saveBankDetailsRequest = () => {
  return {
    type: actionTypes.SAVE_BANK_DETAILS_REQUEST,
  }
}

const saveBankDetailsSuccess = (data) => {
  return {
    type: actionTypes.SAVE_BANK_DETAILS_SUCCESS,
    payload: data,
  }
}

const saveBankDetailsFailure = (error) => {
  return {
    type: actionTypes.SAVE_BANK_DETAILS_FAILURE,
    payload: error,
  }
}

//saveBankDetails action
export const saveBankDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(saveBankDetailsRequest())
    try {
      const responseData = await kycServices.saveBankDetails(payload)
      if (responseData?.status === 200) {
        dispatch(saveBankDetailsSuccess(responseData?.data))
        dispatch(getCurrentUserDetailsAction())
      } else {
        dispatch(saveBankDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(saveBankDetailsFailure(error?.response?.data))
    }
  }

  //actions for save documents
 const saveDocsRequest = () => {
  return {
    type: actionTypes.SAVE_DOCS_REQUEST,
  }
}

const saveDocsSuccess = (data) => {
  return {
    type: actionTypes.SAVE_DOCS_SUCCESS,
    payload: data,
  }
}

const saveDocsFailure = (error) => {
  return {
    type: actionTypes.SAVE_DOCS_FAILURE,
    payload: error,
  }
}

//saveDocs action
export const saveDocsAction = (payload) =>
  async(dispatch) => {
    dispatch(saveDocsRequest())
    try {
      const responseData = await kycServices.saveDocsBorrower(payload)
      if (responseData?.status === 200) {
        dispatch(saveDocsSuccess(responseData?.data))
        // dispatch(getCurrentUserDetailsAction())
      } else {
        dispatch(saveDocsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(saveDocsFailure(error?.response?.data))
    }
  }

  //actions for get payment methods
 const getPaymentMethodsRequest = () => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_REQUEST,
  }
}

const getPaymentMethodsSuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_SUCCESS,
    payload: data,
  }
}

const getPaymentMethodsFailure = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_FAILURE,
    payload: error,
  }
}

//getPaymentMethods action
export const getPaymentMethodsAction = (payload) =>
  async(dispatch) => {
    dispatch(getPaymentMethodsRequest())
    try {
      const responseData = await kycServices.getPaymentMethods(payload)
      if (responseData?.status === 200) {
        dispatch(getPaymentMethodsSuccess(responseData?.data))
      } else {
        dispatch(getPaymentMethodsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getPaymentMethodsFailure(error?.response?.data))
    }
  }

//actions for generate order
 const generateOrderRequest = () => {
  return {
    type: actionTypes.GENERATE_ORDER_REQUEST,
  }
}

const generateOrderSuccess = (data) => {
  return {
    type: actionTypes.GENERATE_ORDER_SUCCESS,
    payload: data,
  }
}

const generateOrderFailure = (error) => {
  return {
    type: actionTypes.GENERATE_ORDER_FAILURE,
    payload: error,
  }
}

//generateOrder action
export const generateOrderAction = (payload) =>
  async(dispatch) => {
    dispatch(generateOrderRequest())
    try {
      const responseData = await kycServices.generateOrder(payload)
      if (responseData?.status === 200) {
        dispatch(generateOrderSuccess(responseData?.data))
      } else {
        dispatch(generateOrderFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(generateOrderFailure(error?.response?.data))
    }
  }

  //actions for get payment status
 const getPaymentStatusRequest = () => {
  return {
    type: actionTypes.GET_PAYMENT_STATUS_REQUEST,
  }
}

const getPaymentStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENT_STATUS_SUCCESS,
    payload: data,
  }
}

const getPaymentStatusFailure = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_STATUS_FAILURE,
    payload: error,
  }
}

//getPaymentStatus action
export const getPaymentStatusAction = (payload) =>
  async(dispatch) => {
    dispatch(getPaymentStatusRequest())
    try {
      const responseData = await kycServices.getPaymentStatus(payload)
      if (responseData?.status === 200) {
        dispatch(getPaymentStatusSuccess(responseData?.data))
      } else {
        dispatch(getPaymentStatusFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getPaymentStatusFailure(error?.response?.data))
    }
  }

//actions for update order status
 const updateOrderStatusRequest = () => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_REQUEST,
  }
}

const updateOrderStatusSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS,
    payload: data,
  }
}

const updateOrderStatusFailure = (error) => {
  return {
    type: actionTypes.UPDATE_ORDER_STATUS_FAILURE,
    payload: error,
  }
}

//updateOrderStatus action
export const updateOrderStatusAction = (payload) =>
  async(dispatch) => {
    dispatch(updateOrderStatusRequest())
    try {
      const responseData = await kycServices.updateOrderStatus(payload)
      if (responseData?.status === 200) {
        dispatch(updateOrderStatusSuccess(responseData?.data))
      } else {
        dispatch(updateOrderStatusFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(updateOrderStatusFailure(error?.response?.data))
    }
  }