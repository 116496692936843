/* eslint-disable no-unused-vars */
import * as borrowerServices from '../../services/borrowerServices'
import * as actionTypes from './actionsTypes'

//actions for get user dashboard data
const getUserDashboardDetailsRequest = () => {
  return {
    type: actionTypes.GET_USER_DASHBOARD_DETAILS_REQUEST,
  }
}

const getUserDashboardDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DASHBOARD_DETAILS_SUCCESS,
    payload: data,
  }
}

const getUserDashboardDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_USER_DASHBOARD_DETAILS_FAILURE,
    payload: error,
  }
}

//getUserDashboardDetails action
export const getUserDashboardDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(getUserDashboardDetailsRequest())
    try {
      const responseData = await borrowerServices.getUserDashboardDetails(payload)
      if (responseData?.status === 200) {
        dispatch(getUserDashboardDetailsSuccess(responseData?.data))
      } else {
        dispatch(getUserDashboardDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getUserDashboardDetailsFailure(error?.response?.data))
    }
  }

//actions for get borrower details
const getBorrowerDetailsRequest = () => {
  return {
    type: actionTypes.GET_BORROWER_DETAILS_REQUEST,
  }
}

const getBorrowerDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_BORROWER_DETAILS_SUCCESS,
    payload: data,
  }
}

const getBorrowerDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_BORROWER_DETAILS_FAILURE,
    payload: error,
  }
}

//getBorrowerDetails action
export const getBorrowerDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(getBorrowerDetailsRequest())
    try {
      const responseData = await borrowerServices.getBorrowerDetails(payload)
      if (responseData?.status === 200) {
        dispatch(getBorrowerDetailsSuccess(responseData?.data))
      } else {
        dispatch(getBorrowerDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getBorrowerDetailsFailure(error?.response?.data))
    }
  }

//actions for get product lsit
const getProductListRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_REQUEST,
  }
}

const getProductListSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_SUCCESS,
    payload: data,
  }
}

const getProductListFailure = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_FAILURE,
    payload: error,
  }
}

//getProductList action
export const getProductListAction = (payload) =>
  async(dispatch) => {
    dispatch(getProductListRequest())
    try {
      const responseData = await borrowerServices.getProductList(payload)
      if (responseData?.status === 200) {
        dispatch(getProductListSuccess(responseData?.data))
      } else {
        dispatch(getProductListFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getProductListFailure(error?.response?.data))
    }
  }

//actions for post applyLoan
const applyLoanRequest = () => {
  return {
    type: actionTypes.APPLY_LOAN_REQUEST,
  }
}

const applyLoanSuccess = (data) => {
  return {
    type: actionTypes.APPLY_LOAN_SUCCESS,
    payload: data,
  }
}

const applyLoanFailure = (error) => {
  return {
    type: actionTypes.APPLY_LOAN_FAILURE,
    payload: error,
  }
}

//applyLoan action
export const applyLoanAction = (payload) =>
  async(dispatch) => {
    dispatch(applyLoanRequest())
    try {
      const responseData = await borrowerServices.applyLoan(payload)
      if (responseData?.status === 200) {
        dispatch(applyLoanSuccess(responseData?.data))
      } else {
        dispatch(applyLoanFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(applyLoanFailure(error?.response?.data))
    }
  }

//actions for get loan list details
const getLoanListDetailsRequest = () => {
  return {
    type: actionTypes.GET_LOAN_LIST_DETAILS_REQUEST,
  }
}

const getLoanListDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_LOAN_LIST_DETAILS_SUCCESS,
    payload: data,
  }
}

const getLoanListDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_LOAN_LIST_DETAILS_FAILURE,
    payload: error,
  }
}

//getLoanListDetails action
export const getLoanListDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(getLoanListDetailsRequest())
    try {
      const responseData = await borrowerServices.getLoanList(payload)
      if (responseData?.status === 200) {
        dispatch(getLoanListDetailsSuccess(responseData?.data))
      } else {
        dispatch(getLoanListDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getLoanListDetailsFailure(error?.response?.data))
    }
  }

//actions for getAmmortizationSchedule
const getAmmortizationScheduleRequest = () => {
  return {
    type: actionTypes.GET_AMMORTIZATION_SCHEDULE_REQUEST,
  }
}

const getAmmortizationScheduleSuccess = (data) => {
  return {
    type: actionTypes.GET_AMMORTIZATION_SCHEDULE_SUCCESS,
    payload: data,
  }
}

const getAmmortizationScheduleFailure = (error) => {
  return {
    type: actionTypes.GET_AMMORTIZATION_SCHEDULE_FAILURE,
    payload: error,
  }
}

//getAmmortizationSchedule action
export const getAmmortizationScheduleAction = (payload) =>
  async(dispatch) => {
    dispatch(getAmmortizationScheduleRequest())
    try {
      const responseData = await borrowerServices.getAmmortizationSchedule(payload)
      if (responseData?.status === 200) {
        dispatch(getAmmortizationScheduleSuccess(responseData?.data))
      } else {
        dispatch(getAmmortizationScheduleFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getAmmortizationScheduleFailure(error?.response?.data))
    }
  }

//actions for get loan payment transactions
const getLoanPaymentTransactionRequest = () => {
  return {
    type: actionTypes.GET_LOAN_PAYMENT_TRANSACTION_REQUEST,
  }
}

const getLoanPaymentTransactionSuccess = (data) => {
  return {
    type: actionTypes.GET_LOAN_PAYMENT_TRANSACTION_SUCCESS,
    payload: data,
  }
}

const getLoanPaymentTransactionFailure = (error) => {
  return {
    type: actionTypes.GET_LOAN_PAYMENT_TRANSACTION_FAILURE,
    payload: error,
  }
}

//getLoanPaymentTransaction action
export const getLoanPaymentTransactionAction = (payload) =>
  async(dispatch) => {
    dispatch(getLoanPaymentTransactionRequest())
    try {
      const responseData = await borrowerServices.getLoanPaymentTransaction(payload)
      if (responseData?.status === 200) {
        dispatch(getLoanPaymentTransactionSuccess(responseData?.data))
      } else {
        dispatch(getLoanPaymentTransactionFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getLoanPaymentTransactionFailure(error?.response?.data))
    }
  }

//actions for get loan lis status
const getLoanListStatusRequest = () => {
  return {
    type: actionTypes.GET_LOAN_LIST_STATUS_REQUEST,
  }
}

const getLoanListStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_LOAN_LIST_STATUS_SUCCESS,
    payload: data,
  }
}

const getLoanListStatusFailure = (error) => {
  return {
    type: actionTypes.GET_LOAN_LIST_STATUS_FAILURE,
    payload: error,
  }
}

//getLoanListStatus action
export const getLoanListStatusAction = (payload) =>
  async(dispatch) => {
    dispatch(getLoanListStatusRequest())
    try {
      const responseData = await borrowerServices.getLoanListStatus(payload)
      if (responseData?.status === 200) {
        dispatch(getLoanListStatusSuccess(responseData?.data))
      } else {
        dispatch(getLoanListStatusFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getLoanListStatusFailure(error?.response?.data))
    }
  }

//actions for get borrower credit card
const getBorrowerCreditDataRequest = () => {
  return {
    type: actionTypes.GET_BORROWER_CREDIT_DATA_REQUEST,
  }
}

const getBorrowerCreditDataSuccess = (data) => {
  return {
    type: actionTypes.GET_BORROWER_CREDIT_DATA_SUCCESS,
    payload: data,
  }
}

const getBorrowerCreditDataFailure = (error) => {
  return {
    type: actionTypes.GET_BORROWER_CREDIT_DATA_FAILURE,
    payload: error,
  }
}

//getBorrowerCreditData action
export const getBorrowerCreditDataAction = (payload) =>
  async(dispatch) => {
    dispatch(getBorrowerCreditDataRequest())
    try {
      const responseData = await borrowerServices.getBorrowerCreditData(payload)
      if (responseData?.status === 200) {
        dispatch(getBorrowerCreditDataSuccess(responseData?.data))
      } else {
        dispatch(getBorrowerCreditDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getBorrowerCreditDataFailure(error?.response?.data))
    }
  }

//actions for create esign request
const createESignRequest = () => {
  return {
    type: actionTypes.CREATE_ESIGN_REQUEST,
  }
}

const createESignSuccess = (data) => {
  return {
    type: actionTypes.CREATE_ESIGN_SUCCESS,
    payload: data,
  }
}

const createESignFailure = (error) => {
  return {
    type: actionTypes.CREATE_ESIGN_FAILURE,
    payload: error,
  }
}

//createESign action
export const createESignAction = (payload) =>
  async(dispatch) => {
    dispatch(createESignRequest())
    try {
      const responseData = await borrowerServices.createESignRequest(payload)
      if (responseData?.status === 200) {
        dispatch(createESignSuccess(responseData?.data))
      } else {
        dispatch(createESignFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createESignFailure(error?.response?.data))
    }
  }

//actions for acceprt loan eligibility
const acceptLoanEligibilityRequest = () => {
  return {
    type: actionTypes.ACCEPT_LOAN_ELIGIBILITY_REQUEST,
  }
}

const acceptLoanEligibilitySuccess = (data) => {
  return {
    type: actionTypes.ACCEPT_LOAN_ELIGIBILITY_SUCCESS,
    payload: data,
  }
}

const acceptLoanEligibilityFailure = (error) => {
  return {
    type: actionTypes.ACCEPT_LOAN_ELIGIBILITY_FAILURE,
    payload: error,
  }
}

//acceptLoanEligibility action
export const acceptLoanEligibilityAction = (payload) =>
  async(dispatch) => {
    dispatch(acceptLoanEligibilityRequest())
    try {
      const responseData = await borrowerServices.acceptLoanEligibility(payload)
      if (responseData?.status === 200) {
        dispatch(acceptLoanEligibilitySuccess(responseData?.data))
      } else {
        dispatch(acceptLoanEligibilityFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(acceptLoanEligibilityFailure(error?.response?.data))
    }
  }

//actions for withdrawn loan eligibility
const withdrawnLoanEligibilityRequest = () => {
  return {
    type: actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_REQUEST,
  }
}

const withdrawnLoanEligibilitySuccess = (data) => {
  return {
    type: actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_SUCCESS,
    payload: data,
  }
}

const withdrawnLoanEligibilityFailure = (error) => {
  return {
    type: actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_FAILURE,
    payload: error,
  }
}

//withdrawnLoanEligibility action
export const withdrawnLoanEligibilityAction = (payload) =>
  async(dispatch) => {
    dispatch(withdrawnLoanEligibilityRequest())
    try {
      const responseData = await borrowerServices.withdrawnLoanEligibility(payload)
      if (responseData?.status === 200) {
        dispatch(withdrawnLoanEligibilitySuccess(responseData?.data))
      } else {
        dispatch(withdrawnLoanEligibilityFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(withdrawnLoanEligibilityFailure(error?.response?.data))
    }
  }

//actions for track loan journey
const trackLoanJourneyRequest = () => {
  return {
    type: actionTypes.GET_TRACK_LOAN_JOURNEY_REQUEST,
  }
}

const trackLoanJourneySuccess = (data) => {
  return {
    type: actionTypes.GET_TRACK_LOAN_JOURNEY_SUCCESS,
    payload: data,
  }
}

const trackLoanJourneyFailure = (error) => {
  return {
    type: actionTypes.GET_TRACK_LOAN_JOURNEY_FAILURE,
    payload: error,
  }
}

//trackLoanJourney action
export const trackLoanJourneyAction = (payload) =>
  async(dispatch) => {
    dispatch(trackLoanJourneyRequest())
    try {
      const responseData = await borrowerServices.trackLoanJourney(payload)
      if (responseData?.status === 200) {
        dispatch(trackLoanJourneySuccess(responseData?.data))
      } else {
        dispatch(trackLoanJourneyFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(trackLoanJourneyFailure(error?.response?.data))
    }
  }

  // cleanUp esign  data
const cleanUp = () => {
  return {
    type: actionTypes.CLEANUP_ESIGN_DATA,
    payload: undefined,
  }
}
export const cleanEsignRequestData = () =>
  async(dispatch) => {
    dispatch(cleanUp())
}