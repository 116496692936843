import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../../elements/customButton'
import AmortizationSchedule from './AmortizationSchedule'
import TransactionRecord from './TransactionRecord'
import Charges from './Charges'

const tabNames = [
    {
        id: 1,
        name: 'Amortization Schedule',
    },
    {
        id: 2,
        name: 'Transaction Record',
    },
    {
        id: 3,
        name: 'Charges',
    },
  ]

export default function ViewMoreModal(props) {

    const ammortizationData = props.ammortizationScheduleData
    const transactionData = props.paymentTransactionData
    const disbursalData = props.disbursalData

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody>
                    <Box mt={2} mb={4}>
                        <Tabs className='tabs_container'>
                            <TabList className='tabs_list_gray'>
                                {tabNames.map((item, index) => {
                                return (
                                    <Tab key={index}>{item.name}</Tab>
                                )
                                })}
                            </TabList>
                            <TabPanels>
                                {tabNames.map((item, index) => {
                                return (
                                    <TabPanel key={index} className='tab_panel'>
                                        {item.name === 'Amortization Schedule' && <AmortizationSchedule ammortizationData={ammortizationData} />}
                                        {item.name === 'Transaction Record' && <TransactionRecord transactionData={transactionData} disbursalData={disbursalData} />}
                                        {item.name === 'Charges' && <Charges />}
                                    </TabPanel>
                                )
                                })}
                            </TabPanels>
                        </Tabs>
                    </Box>
                    <Box className='d-flex justify-content-end' mb={4}>
                        <CustomButton size="md" type="submit" title="Cancel" className="btn_theme_transparent" onClick={props.handleViewMoreDialogClose} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleViewMoreDialogClose}
        isOpen={props.openViewMore}
        motionPreset="slideInBottom"
        size={'5xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
