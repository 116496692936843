import apiInstance from '../config/api/axios'

export const getKycEmailOtp = async(payload) => {
  const response = await apiInstance.post('/get-kyc-email-otp', payload)
  return response
}

export const verifyKycEmailOtp = async(payload) => {
  const response = await apiInstance.post('/verify-kyc-email-otp', payload)
  return response
}

export const postPersonalDetails = async(payload) => {
  const response = await apiInstance.post('/v2/borrower/personal-detail', payload)
  return response
}

export const getPiiData = async(payload) => {
  const response = await apiInstance.post('/get-pii-data', payload)
  return response
}

export const createDigiLocker = async(payload) => {
  const response = await apiInstance.post('/common/create-digilocker-request', payload)
  return response
}

export const getdownloadAadhaarData = async(payload) => {
  const response = await apiInstance.post('/common/download-aadhaar-data', payload)
  return response
}

export const saveBankDetails = async(payload) => {
  const response = await apiInstance.post('/borrower/save-bank-detail', payload)
  return response
}

export const saveDocsBorrower = async(payload) => {
  const formData = new FormData()
  formData.append('photo', payload?.photo)
  formData.append('pan_card', payload?.pan)
  formData.append('aadhaar_front', payload?.aadhaarFront)
  formData.append('aadhaar_back', payload?.aadhaarBack)
  formData.append('mobile', payload?.mobile)
  if (payload.employment_type === '4') {
    formData.append('gst_registration_certificate', payload.gst)
    formData.append('rbi_licence_copy', payload.rbi)
    formData.append('board_resolution', payload.boardResolution)
    formData.append('cob_certificate', payload.cobCertificate)
    formData.append('mem_art_association', payload.memAssociation)
    formData.append('incorp_certificate', payload.incorpCertificate)
    formData.append('bank_statement_1', payload.bankStatement)
  }
  if (payload.employment_type === '5') {
    formData.append('gst_registration_certificate', payload.gst)
    formData.append('additional_address_proof', payload.addressProof)
    formData.append('board_resolution', payload.boardResolution)
    formData.append('cob_certificate', payload.cobCertificate)
    formData.append('mem_art_association', payload.memAssociation)
    formData.append('incorp_certificate', payload.incorpCertificate)
    formData.append('bank_statement_1', payload.bankStatement)
    formData.append('udyam_certificate', payload.udyam_certificate)

  }
  if (payload.employment_type === '6') {
    formData.append('gst_registration_certificate', payload.gst)
    formData.append('udyam_certificate', payload.udyam_certificate)
    formData.append('reg_act_certificate', payload.reg_act_certificate)
    formData.append('bank_statement_1', payload.bankStatement)
  }
  if (payload.employment_type === '7') {
    formData.append('gst_registration_certificate', payload.gst)
    formData.append('partnership_deed', payload.partnership_deed)
    formData.append('reg_act_certificate', payload.reg_act_certificate)
    formData.append('udyam_certificate', payload.udyam_certificate)
    formData.append('bank_statement_1', payload.bankStatement)
  }
  const response = await apiInstance.post('/save-kyc-documents', formData)
  return response
}

export const getPaymentMethods = async(payload) => {
  const response = await apiInstance.get('/common/getPaymentMethods', payload)
  return response
}

export const generateOrder = async(payload) => {
  const response = await apiInstance.post('/common/generate-order', payload)
  return response
}

export const getPaymentStatus = async(payload) => {
  const response = await apiInstance.post('/common/check-payment-status', payload)
  return response
}

export const updateOrderStatus = async(payload) => {
  const response = await apiInstance.put('/common/update-order-status', payload)
  return response
}