import { ArrowBackIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, Heading, IconButton, Input, Modal, ModalBody, ModalContent, ModalOverlay, Spacer, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../../elements/customButton'
import Lottie from 'lottie-react'
import NoLoanTicketsAnimation from '../../../../../assets/lottiFiles/noLoanTickets.json'

export default function NoLoanTicketsDialog(props) {

    const PopupBox = () => (
        <Box className='preference_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-start col-gap-15 preference_header'>
                    <IconButton onClick={props.handleCloseNoTickets}><ArrowBackIcon /></IconButton>
                    <Heading as="h3" className="font-poppins">Select Your Lending Preference</Heading>
                </Box>
                <ModalBody className='lending_body'>
                    <Box className='no_loan_tickets_box'>
                        <Lottie animationData={NoLoanTicketsAnimation} loop={false} />
                        <Box className='no_loan_tickets'>
                            <Heading as="h2" className="font-poppins">Oops!</Heading>
                            <Text className="font-poppins">No More Matching Loan Tickets Found</Text>
                        </Box>
                        <Box mt={10}>
                            <CustomButton size="md" type="submit" title="Change Investment Preferences" className="font-poppins btn_theme w-100"
                                onClick={props.handleChangePreference}
                            />
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseNoTickets}
        isOpen={props.openNoLoanTickets}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
