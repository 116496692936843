import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import Pagination from '../../../elements/Pagination'
import NoData from '../../../elements/NoData'
import moment from 'moment'

export default function BorrowerDetailsTable(props) {
    const reportFile = props?.fileUrl
    const reportItems = props?.reportData
    const reportLength = props?.fileLength?.userslength
    const pageCount = Math.ceil(reportLength / props.PER_PAGE);

  return (
    <>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        {reportItems?.length > 0 ?
            <Box mt={2}>
                <Box className='d-flex justify-content-end'>
                    <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
                </Box>
                <Box mt={3} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No</Th>
                                    <Th>Registration Date/Time</Th>
                                    <Th>Name</Th>
                                    <Th>Mobile</Th>
                                    <Th>Email</Th>
                                    <Th>Father's Name</Th>
                                    <Th>Address</Th>
                                    <Th>CP Code.</Th>
                                    <Th>Escrow Account Id</Th>
                                    <Th>Stage</Th>
                                    <Th>Current Status</Th>
                                    <Th>Total Loans Disbursed till date</Th>
                                    <Th>No. of active loans</Th>
                                    <Th>Loan Contract</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {reportItems?.map((item, index) => {
                                    const serialNumber = index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {moment(item['registration_date']).format("DD MMM YY")}
                                                </Text>
                                                </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.name?.trim() ? item.name : 'NA'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['mobile']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item.Email ? item.Email : 'NA'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item.fathers_name ? item.fathers_name : 'NA'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins wrap-text">{item.address ? item.address : 'NA'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['cp_referral_code'] ? item['cp_referral_code'] : 'NA'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {item['escrow_account_ide'] ? item['Escrow Account Id'] : 'NA'}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['stage']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className={item['current_status'] === 'Profile Approved' ? "font-poppins table_text success_title" : 
                                                        item['current_status'] === 'Pending at Pre Sanction' ? "font-poppins table_text pending_title" : 
                                                        "font-poppins table_text incomplete_title"}>
                                                    {item['current_status']}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {item['Total Loans Disbursed till date'] ? item['total_loan_disbursed_till_date'] : 0}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['no_of_activeLoans']}</Text>
                                            </Td>
                            <Td>
                                    {item['loan_contracts'] && Array.isArray(item['loan_contracts']) ? (
                                        item['loan_contracts'].length > 0 ? (
                                        <ul className="font-poppins table_text no-dots">
                                            {item['loan_contracts'].map(contract => (
                                            <li key={contract.loan_contract_number}>
                                                {contract.loan_contract_number}
                                            </li>
                                            ))}
                                        </ul>
                                        ) : (
                                        <Text className="font-poppins table_text">NA</Text>
                                        )
                                    ) : (
                                        <Text className="font-poppins table_text">-</Text>
                                    )}
                                    </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={props.handlePageClick} />
                </Box>
            </Box> :
            <NoData title="No Record Found" />
        }
    </>
  )
}
