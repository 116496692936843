import React from 'react'
import { Box, Heading, Image } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CustomButton from '../../../../elements/customButton';
import PayIcon from '../../../../../assets/images/pay.svg'
import moment from 'moment';

function CustomArrow({ onClick, direction }) {

    const icon = direction === "prev" ? <ChevronLeftIcon /> : <ChevronRightIcon />; // Custom arrow icons
    const arrowStyle = {
        cursor: "pointer",
        fontSize: "30px",
        lineHeight: "30px",
      };
  
    return (
      <Box
        className={`arrow-${direction}`}
        onClick={onClick}
        style={arrowStyle}
      >
        {icon}
      </Box>
    );
  }

export default function LoanSlider(props) {

    const productClassMap = {
        'Insta Loan': 'cardThree',
        'Personal Loan': 'cardOne',
        'Bullet Loan': 'cardTwo',
    };

  return (
    <Box className='slider_box bg_shadow position-relative'>
        <Heading as="h2" className='font-poppins card_heading'>Active Loan Deatils</Heading>
        <Box mt={3}>
            {props?.loansData?.length > 0 ?
                <Carousel showArrows={true} showIndicators={true} showStatus={false} autoPlay interval={3000} stopOnHover infiniteLoop
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                    <CustomArrow onClick={() => onClickHandler()} direction="prev" />
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                    <CustomArrow onClick={() => onClickHandler()} direction="next" />
                    )
                }
                >
                {props?.loansData?.map((item, index) => {
                    const className = productClassMap[item?.product_name] || 'cardFour';
                    return (
                        <Box key={index} className={`loan_card ${className}`}>
                            <Box className='loan_top'>
                                <Box className='loan_heading'>
                                    <Heading as="h4" className='font-poppins'>{item?.product_name}</Heading>
                                </Box>
                                <Box className='loan_number'>
                                    <Heading as="p" className='font-poppins'>Loan Application Number</Heading>
                                    <Heading as="p" className='font-poppins'>{item?.loan_number}</Heading>
                                </Box>
                            </Box>
                            <Box className='loan_amount'>
                                <Heading as="p" className='font-poppins'>Approved Loan Amount</Heading>
                                <Heading as="h4" className='font-poppins'>{`₹ ${item?.loan_amount}`}</Heading>
                            </Box>
                            {item?.product_name === 'Personal Loan' ?
                                <Box className='repay_amount'>
                                    <Box className='loan_number'>
                                        <Heading as="p" className='font-poppins' mb={1}>Next EMI</Heading>
                                        <Heading as="p" className='font-poppins'>{item?.emi_date ? moment(item?.emi_date).format('DD MMM YYYY'): null}</Heading>
                                    </Box>
                                    <Box className='loan_number'>
                                        <Heading as="p" className='font-poppins' mb={1}>EMI Amount</Heading>
                                        <Heading as="p" className='font-poppins'>₹ {item?.emi_amount}</Heading>
                                    </Box>
                                </Box> :
                                <Box className='repay_amount'>
                                    <Box className='loan_number'>
                                        <Heading as="p" className='font-poppins' mb={1}>Repayments Date</Heading>
                                        <Heading as="p" className='font-poppins'>{item?.emi_date ? moment(item?.emi_date).format('DD MMM YYYY'): null}</Heading>
                                    </Box>
                                    <Box className='loan_number'>
                                        <Heading as="p" className='font-poppins' mb={1}>Repayments Amount</Heading>
                                        <Heading as="p" className='font-poppins'>₹ {item?.emi_amount}</Heading>
                                    </Box>
                                </Box>
                            }
                            <Box mt={4} mb={2} className='emi_btn'>
                                <CustomButton size="md" type="submit" title="Pay EMI" className="btn_theme_solid font-poppins" onClick={() => props.handleOpenLoanPaymentDialog(item?.emi_amount, item?.loan_contract_number)}
                                leftIcon={<Image src={PayIcon} />} />
                            </Box>
                        </Box>
                    )
                })}
                </Carousel> :
                <Box className='loan_card cardFour'>
                    <Box className='loan_top'>
                        <Box className='loan_heading'>
                            <Heading as="h4" className='font-poppins'>You Don’t Have Active Loan</Heading>
                        </Box>
                    </Box>
                    <Box className='loan_amount'>
                        <Heading as="p" className='font-poppins'>Approved Loan Amount</Heading>
                        <Heading as="h4" className='font-poppins'>₹ -</Heading>
                    </Box>
                    <Box className='repay_amount'>
                        <Box className='loan_number'>
                            <Heading as="p" className='font-poppins' mb={1}>Repayments Date</Heading>
                            <Heading as="p" className='font-poppins'>-</Heading>
                        </Box>
                        <Box className='loan_number'>
                            <Heading as="p" className='font-poppins' mb={1}>Repayments Amount</Heading>
                            <Heading as="p" className='font-poppins'>-</Heading>
                        </Box>
                    </Box>
                    <Box mt={4} mb={2} className='emi_btn'>
                        {props.isKYCCompleted ?
                            <CustomButton size="md" type="submit" title="Apply Loan" className="btn_theme_solid font-poppins" onClick={props.handleApplyLoan} /> :
                            <CustomButton size="md" type="submit" title="Update KYC" className="btn_theme_solid font-poppins" onClick={props.handleUpdateKYC} />
                        }
                    </Box>
                </Box>
            }
        </Box>
    </Box>
  )
}
