import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Table,
  TableContainer,
  IconButton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Pagination from "../../../../elements/Pagination";
import { ChevronRightIcon } from "@chakra-ui/icons";
import moment from "moment";
import Loader from "../../../../elements/loader";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../../../redux/actions";
import { connect, useSelector } from "react-redux";
import NoData from "../../../../elements/NoData";
import { removeItem, setItem } from "../../../../../utilities/authUtils.js";
import { Button } from "react-bootstrap";

const CurrentCollection = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const current_Collection = adminState?.getCurrentCollection?.data?.data;
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(filterData?.length / PER_PAGE);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (props.tabSelected === "Current") {
      let data = {
        type: 1,
      };
      props?.actions?.getCurrentCollectionAction(data);
    }
    removeItem("ckyc_number")
  }, [props.tabSelected]);

  useEffect(() => {
    if (adminState?.getCurrentCollection?.data?.success === true) {
      setFilterData(current_Collection);
    }
  }, [adminState?.getCurrentCollection]);

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const result = current_Collection.filter((item) => {
        return (
          item?.mobile?.toLowerCase().includes(value.toLowerCase()) ||
          item?.first_name?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilterData(result);
    } else {
      setFilterData(current_Collection);
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };
  return (
    <>
      {(adminState?.getCurrentCollection?.loading ||
        adminState?.getUserByToken?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box mt={1}>
        <Box className="search_box">
          <Input
            type="text"
            size="md"
            name="search"
            placeholder="Search"
            onChange={handleSearch}
            className="input_box font-poppins"
          />
        </Box>
        <>
          {filterData?.length > 0 ? (
            <Box mt={2} className="custom_table_wrapper custom_table_wrapper2">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>S.No.</Th>
                      <Th>Loan Details</Th>
                      <Th>Loan Contract</Th>
                      <Th>Loan Disbursed Date</Th>
                      <Th>Loan EMI Date</Th>
                      <Th>Loan Presentation date</Th>
                      <Th>Status</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filterData
                      ?.slice(offset, offset + PER_PAGE)
                      ?.map((item, index) => {
                        const serialNumber = offset + index + 1;
                        return (
                          <Tr key={index}>
                            <Td>
                              <Text className="font-poppins table_text">
                                {serialNumber}
                              </Text>
                            </Td>
                            <Td>
                            <Text className="font-poppins  gray_text">
                                {item?.UMRN ? item?.UMRN : "NA"}
                              </Text>
                              <Td>
                              
                              <Text className="font-poppins table_text">
                                {item?.loan_number ? item?.loan_number : "NA"}
                              </Text>
                            </Td>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.loan_contract_number
                                  ? item?.loan_contract_number
                                  : "Not Assigned"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.disbursed_date
                                  ? moment(item?.disbursed_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.emi_date
                                  ? moment(item?.emi_date).format("DD-MMM-YYYY")
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.presentation_date
                                  ? moment(item?.presentation_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                                                    <Button size="md" className={` font-poppins  ${ item?.transaction_state ? (item?.transaction_state == 'payment_success' || item?.transaction_state == 'scheduled' || item?.transaction_state == 'new') ? "btn_table_green-collection " : item?.transaction_state == 'payment_failed' && "btn_table_red-collection" : "btn_table_upcoming"}` } >
                                                          {item?.display_status}
                                                      </Button>
                                                      <Td>
                              <Text className="font-poppins red_text">
                                {item?.failure_response ? item?.failure_response : ""}
                              </Text>
                            </Td>

                            </Td>
                           
                            <Td>
                              <Box className="actions_td action_table">
                                {/* <IconButton  filterData= {filterData} onClick={() => props.handleVerify()}><ChevronRightIcon /></IconButton> */}
                                {/* <IconButton  onClick={() => props.handleVerify(item)}><ChevronRightIcon /></IconButton> */}
                                <IconButton
                                  onClick={() =>
                                    props.handleVerify(item?.loan_id,item?.borrower_id,1)
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </Box>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </Box>
          ) : (
            <NoData title="No Record Found" />
          )}
        </>
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(CurrentCollection);
