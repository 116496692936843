import React from 'react'
import {Box, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,} from '@chakra-ui/react'
import CustomButton from './customButton'

export default function TermsConditionsCPDialog(props) {

    const PopupBox = () => (
        <Box className="popup_container">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="modal_title font-poppins">Terms & Conditions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box className='modal_info_ploicy font-poppins'>
                    <p>
                                <span style={{fontSize:"13px"}}>
                                These terms are in addition to the <a>User’s Terms and Conditions, Privacy Policy</a> and other
                    documents/details displayed on the website and not in derogation of the same. Further capitalized
                    terms not defined herein are as defined under the User’s Terms and Conditions and Privacy
                    Policy.</span>
                                <br/><br/>
                                <p className="" style={{fontSize:"11px"}}>
                                BY FOLLOWING THE ONLINE ACCEPTANCE PROCESS PROVIDED BY CREDITECH
                    SOLUTIONS PRIVATE LIMITED (HEREINAFTER REFERRED TO AS COMPANY), YOU
                    AGREE TO BE LEGALLY BOUND BY THESE TERMS.  IF YOU ARE REPRESENTING
                    AN INCOROPRATED ENTITY AND COMPANY, YOU UNDERTAKE THAT YOU ARE
                    DULY AUTHORISED BY SUCH ENTITY AND COMPANY AND THIS AGREEMENT
                    SHALL BE BINDING ON SUCH ENTITY OR COMPANY. YOU ARE REQUESTED TO GO
                    THROUGH THESE TERMS &amp; CONDITIONS, USER’S TERMS &amp; CONDITIONS, AND
                    PRIVACY POLICY CAREFULLY BEFOREAGREEING TO THE SAME. IF YOU DON’T
                    AGREE TO THESE YOU MAY NOT ACCEPT THE SAME.

                                </p>
                            
                                <br/><span style={{fontSize: '15px'}}><b> 1. Who can be Channel Partner</b></span><br/>
                                <span style={{fontSize: '13px'}} >        Any individual, proprietorship, partnership, limited liability partnership , or any entity
                    incorporated under relevant applicable law, who/which is willing to perform his/her/it’s
                    obligation as channel partner to the Company and agree to abide by the terms &amp; conditions
                    laid out herein (“Terms”) can register to become a channel partner.
                    </span>
                                <br/>
                                <br/>
                                <span style={{fontSize: '15px'}}>  <b>2. Appointment</b></span><br/>
                                <span style={{fontSize:"13px"}}>   By accepting these Terms and registering yourself with the Company, you agree to be
                    appointed as the channel partner of the Company for the sales and marketing of its services
                    as per these Terms. You agree that your appointment is on a non-exclusive and non-
                    assignable basis. Nothing contained herein shall be deemed to create principle- agent,
                    employer-employee, partnership (notwithstanding the use of the word “partner” in this
                    agreement), joint venture relationship, and such other kind of relationship between you and
                    the Company. No act /conduct, direct or indirect carried out by you, shall be binding upon 
                    the Company, unless expressly authorized in writing by the Company. Further, your authority
                    is limited to what has been clearly, expressly and in unequivocally set forth in these Terms.<br/>
                                <br/>
                                </span>
                                    <span style={{fontSize: '15px'}}><b>3. Scope</b></span>
                                <br/>
                                <span style={{fontSize:"13px"}}>  By accepting these Terms you agree that your scope as a channel partner shall be confined to
                    the sales, and marketing of the services of the Company by referring the lender and/ or
                    borrower to it. The services shall have the same meaning as defined in the User’s Terms &amp;
                    Conditions.
                                </span>
                                <br/>
                                <br/>
                                <span style={{fontSize: '15px'}}>
                                <b>4. Your Consent/Obligations</b></span><br/>
                                <span style={{fontSize:"13px"}}>
                                <span>You agree that:</span>
                                (a) The Company may revise, add or discontinue or delete certain services without any prior
                    notice.  Any revision will apply to all referrals accepted after the effective date of the
                    revision.<br/>     
                        (b).All the terms &amp; conditions related to the User or to that of the Investor and/or Borrower
                    may be changed by the Company without any prior notification.
                                <br/>
                                (c)Availing of the services of the Company by the investor/borrower are subject to the
                    investor/borrower’s acceptance of the terms of the services.  If the investor/borrower
                    don’t accept such terms, they will not be able to avail the services.
                                <br/>
                                (d)You have no authority to modify the terms of the services under the , User’s Terms and
                    Conditions and other such document, in any manner whatsoever, or to bind the Company,
                    or make any representation or warranty on behalf of the Company.
                                <br/>
                                (e)You will assist the Company to take all action by the investor against the borrower in
                    respect of the enforcement of him/her/it’s rights.
                                <br/> 
                                    (f)You will always act in the manner that reflects favourably at all times in respect of the
                    services and the good name, goodwill, and reputation of the Company.
                                <br/>
                                (g)You will avoid deceptive, misleading, or unethical practices that are or might be
                    detrimental or disparaging to the Company or its services.
                                <br/>
                                (h)You will not publish or employ or cooperate in the publication or employment of any
                    misleading or deceptive materials relating to the services.
                                <br/>
                                (i)You will make no covenants, representations, warranties or guarantees to the referrals
                    (investor/borrower) or to the trade with respect to the specifications, features, or
                    capabilities of the services which are inconsistent with the literature provided by or
                    displayed on the Company’s website, or which are inconsistent with the then current User
                    Terms &amp; Conditions.
                                <br/>
                                (j)You will notify the Company immediately of any knowledge or suspicion of any
                    violations of the Company’s patents, copyrights, trademarks, or other proprietary rights.
                                <br/>
                                (k)You will make no promises, representations, warranties or guarantees with respect to the
                    financial products of the Company without its prior written approval.
                                <br/>
                                (l)You shall not incur or purport to incur, without the Company’s prior written consent any
                    liability or commitment on behalf of the Company.
                    </span>
                                <br/>
                                <br/>
                                <span style={{fontSize: '15px'}}><b>
                                    5. Restrictions</b></span><br/>
                                    <span style={{fontSize:"13px"}}>      You will not access (or attempt to access) any information pertaining to the Company by any
                    means other than the interface provided, and you will not use the same for any purpose other
                    than the purpose for which it was made available. The Company reserves the right to
                    investigate and take appropriate legal action against you if in the Company’s sole discretion,
                    the instant provision is violated. The illegal use of any information pertaining to the Company
                    is prohibited.
                    </span>
                    <br/>
                    <br/>
                                <span style={{fontSize: '15px'}}><b>6. LISTING</b></span><br />
                                <span>You further agree that:</span>
                                <br/>
                                <span style={{fontSize:"13px"}}>    (a) If you are a website owner, you will not display any materials (including but not limited
                    to)the Company’s logo, information etc. without first seeking prior written permission of
                    the Company. Pursuant to the grant of permission, you will display only such
                    information, content, logo etc., as may be expressly approved in writing by the Company.;<br/>
                                (b) If you want to promote the Company’s services through any other medium, you can do so
                    only after the written approval of the Company as to the mode, medium, contents,
                    information, logo etc. The Company may have a written policy in this respect, that may
                    be shared with you. Once this is shared with you, you hereby agree to abide by the same.<br />
                                (c) Notwithstanding the aforesaid, no material, information, logo or any promotional material
                    shall be displayed by you anywhere, or at any medium, unless it is expressly approved by
                    the Company.<br />
                                (d) Subject to the limitation of use as specified above, the Company authorizes you to use its
                    current and future trademarks, service marks and trade names <b>(“IP Marks”)</b> solely in
                    connection with the marketing and distribution of its Services pursuant to these Terms.
                    </span>
                    <br />
                                <br/>
                                <br/>
                                <span style={{fontSize: '15px'}}><b>7. Conflict of Interest</b></span> <br />
                                <span style={{fontSize:"13px"}}>        (a) You warrant to refrain from entering into any kind of ownership, contract, agreement,
                    distribution or selling agreement with any of the competitors of the Company or engaging
                    yourself for any commercial, marketing or promoting any services for any other P2P
                    platform or any other purpose which in the opinion of the Company is detrimental to its
                    interest during the term of this agreement and for a period of two (2) years from the date
                    of its termination.<br />
                                (b) Any violation of this undertaking/ warranty shall make you liable for any and all damages
                    towards loss of any business, future profits etc. whether direct or indirect, consequential
                    or remote suffered by the Company. The Company shall also be entitled to withhold and
                    forfeit any outstanding amount payable to you.
                    </span>
                    <br />
                                <br />
                                <span style={{fontSize: '15px'}}><b>8. Employment  </b></span><br />
                                <span style={{fontSize:"13px"}}>      If you employ personnel/staff for performing/assisting with the your scope of Services, you
                    will be solely liable to pay their salaries, cost, expenses including any statutory cost payable
                    to them. Further, you will be liable to comply with all applicable law, rules and regulations.
                    All such employee shall always been deemed as your employees. Such employees shall have
                    no claim against Company in any manner whatsoever.
                    </span>
                    <br />
                                <br />
                                <br />
                                <span style={{fontSize: '15px'}}><b>9. Indemnification</b></span><br />
                                <span style={{fontSize:"13px"}}>        You agree and undertake to defend, indemnify and hold the Company its directors, CEO,
                    managers, employee, agents, sub-agents, etc. (collectively referred to as “Indemnified
                    Party”) harmless from and against any and all damages, liabilities, costs and expenses
                    (including but not limited to attorneys’ fees, litigation cost) arising out of or incurred by the
                    Indemnified Party in connection with or as a result of or due to
                    <br/>
                    (a) breach of any of the Terms; and/or<br />
                    (b) breach of any of the undertaking provided by you pursuant to these Terms; and/or<br />
                    (c) breach of any of the limitation prescribed in respect of your scope of work, as provided
                    under these Terms and/or any instruction issued by the Company in this respect; and/or<br />
                    (d) any third party claim arising out of your non-compliances of the applicable law in relation
                    to your scope of work; and/or<br />
                    (e)any third party claim arising out of or relating to any of your acts or omissions; or act or
                    omission of your managers, employees, (or directors in case of a incorporated entity) in
                    connection with the Terms; and/or
                    (f)any third party claim arising out of or related to the breach of any of these Terms and
                    conditions or any instructions issued by the Company.
                    </span>
                                <br />
                                <br />
                                <span style={{fontSize: '15px'}}><b>10. Intellectual Property Rights/Marks</b></span> <br />
                                <span style={{fontSize:"13px"}}>    (a)You have no rights in the IP Marks of the Company except those provided under these
                    Terms.
                    <br/>
                    (b) If, in the course of performing your scope of work, you acquire any goodwill in any of the
                    IP Marks, all such goodwill will automatically vest in the Company. You shall not be
                    entitled to claim any monetary or other benefit arising out of this. If necessary, you shall
                    take all such actions or execute any documents necessary to make effective such vesting.
                    You shall not contest the validity of any of the IP Marks or the Company’s exclusive
                    ownership of the IP Marks. You shall not adopt, use, or register, whether as a corporate

                    name, trademark, service mark or other indication of origin, any of the IP Marks, or any
                    word or mark confusingly similar to the Company’s IP Marks in any jurisdiction.
                    (c) You further undertake not to make any modification or addition to any of the Company’s
                    IP Marks.
                    </span>
                                <br />
                                <br/>
                                <span style={{fontSize: '15px'}}><b>11. Proprietary Rights</b></span><br />
                            <span style={{fontSize:"13px"}}>
                            You acknowledge and agree that all non-public information related to the Company including
                    materials and all other items provided hereunder and all copies thereof constitute valuable
                    trade secrets of the Company, or are proprietary and Confidential Information (defined below)
                    of the Company, and the title thereto vest with the Company. All applicable copyrights, trade
                    secrets, patents and other intellectual and proprietary rights in the products/services offered
                    by the Company (“Products/Services”) and all other items provided hereunder are and will
                    remain in the ownership of the Company. All other aspects of the Products/Services and all
                    other items provided hereunder, including without limitation, programs, methods of
                    processing, and specific design of individual programs and their interaction and unique
                    programming techniques employed therein as well as screen formats shall remain the sole and
                    exclusive property of the Company and shall not be sold, revealed, disclosed or otherwise
                    communicated, directly or indirectly, by you, to any person, company or institution
                    whatsoever other than for the purposes set forth herein. It is expressly understood that no title
                    to or ownership of the Products/Services, or any part thereof is hereby transferred to you.
                    You shall not reverse engineer, decompile, disassemble or otherwise derive source code from
                    the Products/Services of the Company. All rights not expressly granted herein are reserved to
                    the Company.
                                </span> <br />
                                <br /><span style={{fontSize: '15px'}}><b>12. Non-Compete </b></span><br />
                                <span style={{fonSize:"13px"}}>During the period you are the channel partner and for a period of 24 (twenty four) months
                    from the date your services as channel partner is terminated, you undertake that you will not,
                    directly or indirectly, either as proprietor, stockholder, partner, officer, employee or
                    otherwise, distribute, sell, offer to sell, or solicit any orders for the purchase or distribution of
                    any products or services which are similar to those provided by the Company.</span><br />
                                
                                <br /><span style={{fontSize: '15px'}}><b>13. Term and Termination</b></span><br />
                                <span style={{fontSize:"13px"}}>      13.1 This agreement shall continue to bind you unless terminated by the Company in the following
                    circumstances:<br />
                                (a)
                                Automatically, in case you are in breach of any of these Terms. Such termination shall be
                    without prejudice to the rights of damages available to the Company. The communication
                    in respect of the termination under this clause may be provided by the Company through
                    email.
                                <br /> (b) Automatically, in case the services are discontinued or suspended by the Company. <br />
                                (c) Either party may terminate these Terms at any time by providing a 45 (forty five) days
                    prior written notice to the other party.
                    <br/>
                    (d) In case either you or the Company is declared insolvent or bankrupt.
                    <br/>
                    (e) If you are a company, if a receiver/administrator is appointed or a winding up proceeding
                    is initiated against you.
                                13.2 On termination of this agreement:<br /> (a) The Company may cancel any pending referral made by you; and.<br />

                                (b)You shall return all proprietary or other material belonging to the Company, including
                    without limitation all Confidential Information, immediately; and<br />
                    (c) You shall immediately remove/withdraw all of the Company’s proprietary material from
                    public view; and
                    <br/>
                    (d) You shall immediately stop representing yourself as the referral partner or channel
                    partner of the Company.
                    <br/>
                    (e) The Company, at its discretion may block your access to its proprietary material.
                    (f)Termination or expiration shall not relieve either party of the obligation to pay any sums
                    due hereunder. Other obligations which shall survive for a period of five (5) years from
                    the termination of expiration of these Terms including indemnities and limitation of
                    liability.
                    (h) The Company shall not be liable to you for any damages, expenditures, loss of profits or
                    prospective profits or goodwill on account of the termination of this Agreement for any
                    reason.
                    </span>
                    <br/>
                                <br/><span style={{fontSize: '15px'}}></span><b>14.</b>
                                <span style={{fontSize:"13px"}}>       Except as expressly set forth herein, to the maximum extent allowed under applicable law, the
                    company hereby disclaims any and all warranties on the services/products, maintenance and
                    support, and professional services, whether express, implied, or statutory, including without
                    limitation any implied warranties or conditions of merchantability, fitness for a particular
                    purpose, quiet enjoyment or non-infringement.
                    </span><br />
                    <br/>

                                <span style={{fontSize: '15px'}}></span><b>15.</b>
                                <span style={{fontSize:"13px"}}>  To the maximum extent allowed under applicable law, the company’s liability under this
                    agreement, regardless of the form of action, will not exceed the amounts payable by the
                    company to you. The company will not be liable for any special, direct, indirect,
                    consequential or incidental damages arising out of this agreement, whether or not you have
                    been advised of the possibility of such damages.</span><br />
                    <br/>

                                <span style={{fontSize: '15px'}}><b>16. Confidentiality</b></span><br />
                                <span style={{fontSize:"13px"}}>      For the purpose of these Terms “Confidential Information” means any information
                    disclosed by the Company to you, or otherwise acquired by you due to the relationship under
                    these Terms or otherwise, which is marked “Confidential”/“Proprietary” or which, under the
                    circumstances surrounding disclosure, would reasonably be expected to be confidential.<br />
                    (a) You shall treat as confidential all Confidential Information of the Company, and shall not
                    use such Confidential Information except to perform your obligations under these Terms
                    and shall not disclose such Confidential Information to any third party.<br />
                    (b) You hereby agree to effectuate, by agreement, instruction or otherwise, compliance with
                    these confidentiality obligations by your employees, agents, consultants and others who
                    are permitted access or use (in accordance with the Terms herein) of the Confidential
                    Information.
                            <br/>    
                            (c) You agree that if there is a breach or threatened breach of the provisions of this
                    agreement, the Company has no adequate remedy in money or damages and accordingly
                    shall be entitled to seek injunctive relief and any other appropriate equitable remedies for
                    any such breach without proof of actual injury. You further agree that you will not oppose
                    the granting of such relief and that you will not seek, and agree to waive any requirement
                    for, the posting of any bond in connection therewith.  Such remedies shall not be deemed
                    to be the exclusive remedies for any breaches of these Terms by you or your
                    representatives, and shall be in addition to all other remedies available to the Company at
                    law or in equity.</span>
                                <br/>
                                <br/>
                                <span style={{fontSize: '15px'}}><b>17. Compliance of law</b></span><br />
                                You agree to comply with all applicable laws in relation to your services.
                                <br/>
                                <br/><span style={{fontSize: '15px'}}><b>18. Miscellaneous</b></span><br />
                                <span style={{fontSize: '13px'}}  >    (a) You agree that if any portion of this agreement is held to be unenforceable, the remainder
                    will remain valid.
                    <br/>
                    (b) You are not entitled to assign your rights or obligation under these terms without the
                    Company’s prior written consent.
                    <br/>
                    (c) These terms will inure to the benefit of the parties’ successors and assigns.
                    <br/>
                    (d) All notice to the channel partner by the Company shall be through medium of courier,
                    registered post or hand-delivery or through email. All notices to the Company shall be by
                    way of courier, registered post or hand-delivery.
                    <br/>
                    (e) You covenant and agree not to threaten, file or pursue any action against the Company
                    based upon the clam of agency or similar claim based on the distribution relationship and
                    agrees to indemnify, defend, and hold the Company and its affiliated companies, and their
                    respective officers, directors, employees and agents, harmless from and against all claims,
                    liability, damages, fees and costs (including fees and costs of attorneys and other experts),
                    arising from or relating to such claim.
                    <br/>
                    (f) These Terms shall be governed by and construed in accordance with the laws of India.
                    (g) You expressly consent and agree that by going through the registration process and
                    pressing the acceptance button you confirm and agree to these Terms. These Terms may
                    be varied by the Company at its absolute discretion, and information as to the same shall
                    be through the display of the same on the website.
                    <br/>
                    </span>

                                <br/><span style={{fontSize: '15px'}}><b>19.Arbitration</b></span><br />
                                <span style={{fontSize: '15px'}} >   All dispute or differences arising out of these Terms shall be notified by either party to each
                    other in writing. Within a period of 15 (Fifteen) days or such other period, such dispute shall
                    be referred to the sole arbitrator appointed by the Company, who shall adjudicate such
                    dispute as per the provisions of the Arbitration &amp; Conciliation Act, 1996 or any statutory
                    modification or enactment thereof. The venue for the arbitration proceedings shall be New
                    Delhi and the language used will be English.</span>
                    <br/>
                    <br/>
                                <span style={{fontSize: '15px'}}><b>20. Declaration</b></span><br />

                                <span style={{fontSize: '15px'}} >         You hereby declare that you are not involved directly or indirectly in any complaint, case,
                    dispute or proceedings, whether civil or criminal, with any persons, authorities in connection
                    with marketing of various financial products or in relation to any other matter.
                    </span>

                  </p>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <CustomButton size="md" type="submit" title="Cancel" className="btn_theme" onClick={props.handleClickTerms} />
                </ModalFooter>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleClickTerms}
        isOpen={props.openTerms}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
