import React, { useEffect, useState } from "react";
import {Box, Image, Heading, Flex, Input} from '@chakra-ui/react'
import {ActionCreators} from '.././../../redux/actions'
import {Link, useHistory} from 'react-router-dom'
import { connect, useSelector } from "react-redux";
import * as routesNames from '../../../constants/routes'
import RBIlogo from "../../../assets/images/rbi_logo.svg";
import RBIlogoBlack from "../../../assets/images/rbi_black.svg";
import alphaLogo2 from "../../../assets/images/Asset_logo_3.svg";
import alphaLogo from "../../../assets/images/Asset_logo_2.svg";
import CustomButton from "../../elements/customButton.js";
import decode from 'jwt-decode';
import { bindActionCreators } from "redux";
import CustomToast from "../../elements/customToast.js";
import { setItem, removeItem, getItem } from "../../../utilities/authUtils.js";
import Loader from "../../elements/loader.js";
import { preventInvalidKey, validateMobileNumber } from "../../../utilities/CustomFunctions.js";

  const Login = (props) => {

    const history = useHistory()
    const {addToast} = CustomToast()
    const authState = useSelector((state) => state.authState)
    const [inputValues, setInputValues] = useState({})
    const [requestId, setRequestId] = useState(null)
    const [timerCount, setTimerCount] = useState(0)
    const [cpData, setCPData] = useState(null)
    const isSessionExpired = getItem('isSessionExpired')

    useEffect(() => {
      let params = window.location.search.split('?')[1];
      if (params) {
        let decodeToken = decode(params);
        setCPData(decodeToken)
        setItem('cp_token', params)
      }
    }, [])

    useEffect(() => {
      if (isSessionExpired) {
        addToast({message: 'Session Expired! Please login again', status: 'error'})
        removeItem('isSessionExpired')
      }
    }, [isSessionExpired])

    useEffect(() => {
      if (authState?.getMobileOTP?.data?.success === true){
        setRequestId(authState?.getMobileOTP?.data?.request_id)
        addToast({message: authState?.getMobileOTP?.data?.message, status: 'success'})
        setTimerCount(120);
      }
    }, [authState?.getMobileOTP])

    useEffect(() => {
      if (authState?.verifyMobileOTP?.data?.success === true){
        console.log(authState?.verifyMobileOTP?.data?.response, 'authState?.verifyMobileOTP?.data?.response')
        addToast({message: authState?.verifyMobileOTP?.data?.message, status: 'success'})
        if(authState?.verifyMobileOTP?.data?.header_token && authState?.verifyMobileOTP?.data?.response){
          let date = new Date();
          let milliseconds = date.getTime();
          setItem("id_token", authState?.verifyMobileOTP?.data?.response?.token);
          setItem("header_token", authState?.verifyMobileOTP?.data?.header_token);
          setItem("logtime", milliseconds);
          removeItem('req_id');
          removeItem('number');
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '1') {
            history.push(routesNames.ADMINDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '2') {
            history.push(routesNames.BORROWERDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '3') {
            history.push(routesNames.INVESTERDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '4') {
            history.push(routesNames.ADMINDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '5') {
            history.push(routesNames.ADMINDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '6') {
            history.push(routesNames.ADMINDASHBOARD);
          }
          if (authState?.verifyMobileOTP?.data?.response?.usertype === '7') {
            history.push(routesNames.ADMINDASHBOARD);
          }
        } else {
          if(cpData){
            console.log("authStateauthState",cpData)
            if(cpData.usertype === 2){
              setItem('usertype', cpData.usertype)
              history.push(routesNames.BORROWERSIGNUP);
            }
            if(cpData.usertype === 3){
              setItem('usertype', cpData.usertype)
              history.push(routesNames.LENDERSIGNUP);
            }
          }
          if(!cpData){
            history.push(routesNames.REGISTER)
          }
        }
      } else if (authState?.verifyMobileOTP?.data?.success === false){
        addToast({message: authState?.verifyMobileOTP?.data?.message, status: 'error'})
      }
    }, [authState?.verifyMobileOTP])

    useEffect(() => {
      let timer;
      if (timerCount > 0) {
        timer = setTimeout(() => setTimerCount(timerCount - 1), 1000);
      }
      return () => clearTimeout(timer);
    }, [timerCount]);

    useEffect(() => {
      return () => {
        props?.actions?.cleanUpToast()
      }
    }, [])

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'mobile_number') {
        const validValue = validateMobileNumber(value, addToast);
        if (validValue !== null) {
          setInputValues({ ...inputValues, [name]: validValue });
        }
      } 
      else if (name === 'otp') {
        const truncatedOTP = value.slice(0, 6);
        setInputValues({ ...inputValues, [name]: truncatedOTP });
      } else {
        setInputValues({ ...inputValues, [name]: value });
      }
    }

    const handleOTP = () => {
      if (inputValues?.mobile_number?.length !== 10) {
        addToast({message: ' Please Enter Valid Mobile Number', status: 'error'})
      } else if (inputValues?.mobile_number?.length === 10) {
        props.actions.getMobileOTPAction({mobile: inputValues.mobile_number})
      }
    }

    const handleResendOTP = () => {
      props.actions.getMobileOTPAction({ mobile: inputValues.mobile_number })
      setTimerCount(120);
    }
    const handleLogin = () => {
      if (inputValues?.mobile_number?.length !== 10) {
        addToast({message: 'Please Enter Valid Mobile Number', status: 'error'})
        return
      }
      if (inputValues?.otp?.length !== 6) {
        addToast({message: 'Please Enter OTP', status: 'error'})
        return
      }
      let verifiedData = {
        otp:  inputValues.otp,
        mobile: inputValues.mobile_number,
        request_id: requestId,
        user_device_id: 'NA',
        platform: 'web'
      }
      props.actions.verifymobileOTPAction(verifiedData)
    }

    return (
      <>
        {(authState?.getMobileOTP?.loading || authState?.verifyMobileOTP?.loading) &&
        <div>
          <Loader />
        </div>
        }
        <Box className="parent_container">
          <Box className="updateprofileContainerLogin main_container">       
            <Box className="auth_left_panel hide-xs">
              <Link to={routesNames.LOGIN}>
                  <Image className="" src={alphaLogo} />
              </Link>
              <Box className="about_alpha">
                <Heading as="h4">Welcome to <span style={{color:'#E61E25'}}>ALPHA</span>MONEY!</Heading>
                <Heading as="p">
                  Our Peer To Peer lending platform connects borrowers with investors for quick personal loans and great returns. Join us now for financial freedom.
                </Heading>
              </Box>
              <Box className="rbi">
                <Image className="" src={RBIlogo} />
              </Box>
            </Box> 
            <Box className="auth_right_panel">
              <Box className="form_container">
                <Box className="form_heading">
                  <Box className="mobile_logo hide-md visible-xs">
                    <Link to={routesNames.LOGIN}>
                      <Image alt="logo" src={alphaLogo2} />
                    </Link>
                  </Box>
                  <Heading as="h1">Just a couple of clicks and we <span>Start.</span></Heading>
                  <Heading as="p">We will send an OTP to your Mobile number</Heading>
                </Box>
                <Box className="login-left-container">
                  <Box className="formWraper">
                    <Box mb={5}>
                      <Input
                        type="number"
                        size="md"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="Mobile Number"
                        onChange={handleChange}
                        disabled={requestId}
                        className="input_box font-poppins"
                        value={inputValues?.mobile_number ?? ''}
                        onKeyDown={preventInvalidKey}
                      />
                    </Box>
                    <Box mb={5}>
                      <Input
                        size="md"
                        id="otp"
                        name="otp"
                        type="number"
                        disabled={!requestId}
                        placeholder="Mobile OTP"
                        onChange={handleChange}
                        className="input_box font-poppins"
                        value={inputValues?.otp ?? ''}
                        onKeyDown={preventInvalidKey}
                    />
                    </Box>
                    <Box mb={5} className="right_align">
                      {requestId ? 
                        <Flex className="right_align resend">
                          <CustomButton size="md" type="submit" title="Resend OTP" className="font-poppins btn_transparent" onClick={handleResendOTP}
                          isDisabled={timerCount === 0 ? false : true} />
                          {timerCount === 0 ? null : <Heading as="p"> {timerCount} Sec </Heading>}
                      </Flex>
                      :
                      <CustomButton size="md" type="submit" title="Get OTP" className="font-poppins btn_transparent" onClick={() => handleOTP()} />
                    }
                    </Box>
                    <Box className="submit_actions" mb={5}>
                      <CustomButton size="md" type="submit" title="Submit" onClick={() => handleLogin()} className="btn_theme font-poppins" />
                    </Box>

                    <Box className="register-channel">
                      <Heading className="font-poppins" as="h6"> Login as a {' '}
                        <Link to={routesNames.CPLOGIN} className="font-poppins"> Channel Partner </Link>
                    </Heading>
                    </Box>
                  </Box>
                </Box>
                <Box className="mobile_rbi_logo hide-md visible-xs">
                  <Image alt="rbi" src={RBIlogoBlack} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
   
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Login)