import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { Document, Page } from "react-pdf";
import NavBar from "../../../../elements/NavBar";
import {
  getItem,
  removeAll,
  removeItem,
  setItem,
} from "../../../../../utilities/authUtils";
import { useHistory } from "react-router-dom";
import * as routesNames from "../../../../../constants/routes";
import CustomButton from "../../../../elements/customButton";
import Loader from "../../../../elements/loader";
import { connect, useSelector } from "react-redux";
import { ActionCreators } from "../../../../../redux/actions";
import { bindActionCreators } from "redux";
import { ViewIcon } from "@chakra-ui/icons";
import OpenFIleDialog from "../../../../elements/OpenFIleDialog";
import CustomToast from "../../../../elements/customToast";
import BorrowerDocumentCard from "../components/DocumentUserCollection";
import { detectFileType } from "../../../../../utilities/DownloadDocuments";
import { uploadCKYCDocument } from "../../../../../services/masterServices";
import { DownloadIcon } from "../../../../../assets/images/download.png";

const KycDetails_Collection = (props) => {
  const history = useHistory();
  const { addToast } = CustomToast();
  const CKYCRef = useRef();
  const USERID = getItem("boRRower_ID");
  const presanction_name = getItem("presanction_name");
  const cKyc_number =getItem("ckyc_number")
 
  const adminState = useSelector((state) => state.adminState);
  const [userData, setUserData] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [currentFileType, setCurrentFileType] = useState(null);
  const [type, setType] = useState("v2_pan");
  const [isLoading, setIsLoading] = useState(false);
  const filteredUserData = userData?.checklist_master
    ?.filter(
      (item) =>
        item.version === 2 &&
        (item.title === "PAN Card" || item.title === "Aadhaar Card")
    )
    .slice(0, 2);
  let userByIdData = {
      user_id: USERID,
      type: 'pre-sanction'
  }
  useEffect(() => {
    props?.actions?.getUserDetailsByIdAction(userByIdData);
  }, [props?.actions]);
  useEffect(() => {
    if (adminState?.getUserDetailsById?.data?.success === true) {
      setUserData(adminState?.getUserDetailsById?.data);
    }
  }, [adminState?.getUserDetailsById]);

  useEffect(() => {
    if (adminState?.approveUser?.data?.success === true) {
      addToast({
        message: adminState?.approveUser?.data?.message,
        status: "success",
      });
      // removeItem('borrowerId')
      history.push(routesNames.PRESANCTIONBORROWERS);
      props?.actions?.cleanUpToast();
    } else if (adminState?.approveUser?.data?.success === false) {
      addToast({
        message: adminState?.approveUser?.data?.message,
        status: "error",
      });
      props?.actions?.cleanUpToast();
    }
  }, [adminState?.approveUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const fileType = {
    photo: detectFileType(userData?.data?.photo),
    panCard: detectFileType(userData?.data?.document_data?.pan_card),
    aadhaarFront: detectFileType(userData?.data?.document_data?.aadhaar_front),
    aadhaarBack: detectFileType(userData?.data?.document_data?.aadhaar_back),
  };

  const handleModal = (data, fileType) => {
    setOpenModal(!openModal);
    setModalData(data);
    setCurrentFileType(fileType);
  };

  const handleSelectDocument = (documentType) => {
    setType(documentType);
  };
  const showOpenFileDialog = () => {
    history.push(routesNames.ADMINUSERCOLLECTION_LOAN_DETAILS);
  };

  const onChangeFile = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    // formData.append('userId', USERID)
    setIsLoading(true);
    uploadCKYCDocument(formData)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === true) {
          addToast({ message: res?.data?.message, status: "success" });
        } else if (res?.data?.status === false) {
          addToast({ message: res?.data?.message, status: "error" });
        }
      })
      .catch((error) => {
        addToast({ message: error, status: "error" });
        setIsLoading(false);
      });
  };
  // const handleLogout = () => {
  //     removeAll()
  //     history.push(routesNames.LOGIN)
  // }

  return (
    <>
      {(adminState?.getUserDetailsById?.loading ||
        adminState?.getUserByToken?.loading ||
        adminState?.approveUser?.loading ||
        isLoading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box className="dashboard_box bg_shadow">
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className="font-poppins page_heading">
              Collection Kyc Details
            </Heading>
          </GridItem>
        </Grid>
        <Box mt={5}>
          <Grid className="main_grid_container">
            <GridItem colSpan={7} className="verify_container bg_shadow ">
              <Box className="d-flex align-items-center justify-content-between">
                <Box className="change_profile">
                  <Box className="change_profile_container">
                    <Image
                      src={userData?.data?.photo}
                      className="change_profile_image"
                    />
                    <IconButton
                      onClick={() =>
                        handleModal(userData?.data?.photo, fileType.photo)
                      }
                      className="icon_button_view_photo"
                      icon={<ViewIcon />}
                    />
                  </Box>
                  <Box className="user_change_profile">
                    <Heading as="h3" className="font-poppins">
                      {userData?.data?.first_name} {userData?.data?.last_name}{" "}
                      {userData?.data?.middle_name}
                    </Heading>
                    <Heading as="h5" className="font-poppins">
                      Borrower ID:{" "}
                      <span>{userData?.data?.bank_data?.user_id}</span>
                    </Heading>
                    <Text className={'font-poppins digio_active'}>
                    KYC Verified By Pre Sanction Admin   {presanction_name ? presanction_name :"NA"}
                                    </Text>
                  </Box>
                </Box>
                <Box className="user_profile_details">
                  <Heading as="h6" className="font-poppins">
                    {userData?.data?.first_name}
                  </Heading>
                  <Text className="font-poppins">{userData?.data?.mobile}</Text>
                  <CustomButton
                    size="sm"
                    type="submit"
                    title=" View Loan Details"
                    className="collection_btn_green font-poppins"
                    onClick={()=>showOpenFileDialog()}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept=".zip"
                    onChange={(e) => onChangeFile(e)}
                    className="d-none"
                    ref={CKYCRef}
                  />
                </Box>
              </Box>
              <Grid className="main_grid_container" mt={5}>
                {filteredUserData?.map((item, index) => {
                  return (
                    <BorrowerDocumentCard
                      item={item}
                      key={index}
                      inputValues={inputValues}
                      type={type}
                      userData={userData}
                      handleChange={handleChange}
                      handleSelectDocument={handleSelectDocument}
                    />
                  );
                })}
              </Grid>
              <Grid mt ={5}>
              <Box className="d-flex card_data_forkyc">
                    <Heading as="h6" className="font-poppins box_title w-55">
                CKyc Number
                </Heading>
                <Heading as="h6" className="font-poppinsbox_data_ckyc w-35">
                {" "}
                {cKyc_number ?? "NA"}
                </Heading>
                    </Box>
              </Grid>
            </GridItem>
            <GridItem colSpan={5}>
              <Box className="verify_container bg_shadow">
                {type === "v2_pan" ? (
                  <>
                    {userData?.data?.document_data?.pan_card ? (
                      <Box className="document_viewer">
                        <Heading
                          as="h4"
                          className="font-poppins document_name_heading"
                        >
                          PAN Card
                        </Heading>
                        <Box className="pdf_read" mt={0}>
                          {fileType.panCard === "pdf" ? (
                            <Document
                              file={userData?.data?.document_data?.pan_card}
                            >
                              <Page pageNumber={1} />
                            </Document>
                          ) : (
                            <Image
                              src={userData?.data?.document_data?.pan_card}
                            />
                          )}
                          {userData?.data?.document_data?.pan_card ? (
                            <Box>
                              <IconButton
                                onClick={() =>
                                  handleModal(
                                    userData?.data?.document_data?.pan_card,
                                    fileType.panCard
                                  )
                                }
                                className="icon_button_view"
                                icon={<ViewIcon />}
                              />
                              <a
                                target="_blank"
                                href={userData?.data?.document_data?.pan_card}
                              >
                                <IconButton
                                  className="icon_button_download"
                                  icon={
                                    <Image
                                      src={DownloadIcon}
                                      className="download-link"
                                    />
                                  }
                                />
                              </a>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="pdf_read no-data">
                        <Heading as="h6" className="font-poppins">
                          No document available
                        </Heading>
                      </Box>
                    )}
                  </>
                ) : type === "v2_aadhar" ? (
                  <>
                    {userData?.data?.document_data?.aadhaar_front ? (
                      <Box className="document_viewer">
                        <Heading
                          as="h4"
                          className="font-poppins document_name_heading"
                        >
                          Aadhaar Front
                        </Heading>
                        <Box className="pdf_read" mt={0}>
                          {fileType.aadhaarFront === "pdf" ? (
                            <Document
                              file={
                                userData?.data?.document_data?.aadhaar_front
                              }
                            >
                              <Page pageNumber={1} />
                            </Document>
                          ) : (
                            <Image
                              src={userData?.data?.document_data?.aadhaar_front}
                            />
                          )}
                          {userData?.data?.document_data?.aadhaar_front ? (
                            <Box>
                              <IconButton
                                onClick={() =>
                                  handleModal(
                                    userData?.data?.document_data
                                      ?.aadhaar_front,
                                    fileType.aadhaarFront
                                  )
                                }
                                className="icon_button_view"
                                icon={<ViewIcon />}
                              />
                              <a
                                target="_blank"
                                href={
                                  userData?.data?.document_data?.aadhaar_front
                                }
                              >
                                <IconButton
                                  className="icon_button_download"
                                  icon={
                                    <Image
                                      src={DownloadIcon}
                                      className="download-link"
                                    />
                                  }
                                />
                              </a>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="pdf_read no-data">
                        <Heading as="h6" className="font-poppins">
                          No document available
                        </Heading>
                      </Box>
                    )}
                    {userData?.data?.document_data?.aadhaar_back ? (
                      <Box className="document_viewer" mt={4}>
                        <Heading
                          as="h4"
                          className="font-poppins document_name_heading"
                        >
                          Aadhaar Back
                        </Heading>
                        <Box className="pdf_read" mt={0}>
                          {fileType.aadhaarBack === "pdf" ? (
                            <Document
                              file={userData?.data?.document_data?.aadhaar_back}
                            >
                              <Page pageNumber={1} />
                            </Document>
                          ) : (
                            <Image
                              src={userData?.data?.document_data?.aadhaar_back}
                            />
                          )}
                          {userData?.data?.document_data?.aadhaar_back ? (
                            <Box>
                              <IconButton
                                onClick={() =>
                                  handleModal(
                                    userData?.data?.document_data?.aadhaar_back,
                                    fileType.aadhaarBack
                                  )
                                }
                                className="icon_button_view"
                                icon={<ViewIcon />}
                              />
                              <a
                                target="_blank"
                                href={
                                  userData?.data?.document_data?.aadhaar_back
                                }
                              >
                                <IconButton
                                  className="icon_button_download"
                                  icon={
                                    <Image
                                      src={DownloadIcon}
                                      className="download-link"
                                    />
                                  }
                                />
                              </a>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="pdf_read no-data">
                        <Heading as="h6" className="font-poppins">
                          No document available
                        </Heading>
                      </Box>
                    )}
                  </>
                ) : null}
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <OpenFIleDialog
        handleModal={handleModal}
        openModal={openModal}
        fileType={currentFileType}
        modalData={modalData}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(KycDetails_Collection);
