import React from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text, Progress, Flex, Spacer, Link, Tooltip, Input, Checkbox, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import UserIcon from "../../../../../assets/images/userIcon.svg";
import moment from 'moment'

export default function InvestNowSidebar(props) {

    const data = props?.sidebarData
    const investAmount = data?.product_id === 2 ? data?.loan_amount : props.inputValues?.invest_amount
    const gstOnPledge = ((Number(investAmount) * data?.investment_fee) / 100) * 0.18
    const pledgeAmount = Number(investAmount) + (Number(investAmount) * data?.investment_fee) / 100 + gstOnPledge
    const pledgeAmountInvestFee = (Number(investAmount) * data?.investment_fee) / 100 + gstOnPledge
    const walletBalanceAfter = parseFloat(props.walletData?.effective_balance) - (Number(investAmount) + (Number(investAmount) * data?.investment_fee) / 100 + gstOnPledge)
    const walletBalance = props.walletData?.effective_balance
    
    let investNowData = {
        amount: pledgeAmount - pledgeAmountInvestFee,
        investment_charge: data?.product_id === 2 ? data?.investment_charges : pledgeAmountInvestFee,
        loan_id: data?.id,
        risk_category: data?.risk_category
    }

  const PopupBox = () => (
    <Box>
        {}
      <DrawerOverlay />
      <DrawerContent className='invest_sidebar_content'>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header' pb={0} pt={1}>
            <Box className='profile_info'>
                <Image src={data?.user_data ? data?.user_data?.photo : UserIcon} className="profile_image" />
                <Box className='user_profile'>
                <Heading as="h3" className='font-poppins'>{data?.borrowerDetailsData?.full_name}</Heading>
                <Heading as="h5" className='font-poppins'>{data?.loan_number}</Heading>
                </Box>
            </Box>
        </DrawerHeader>
        <DrawerBody>
            <Box className='lending_amount lending_card_bg lending_card_radius'>
                <Box className='preference_header center_text' mb={2}>
                    <Heading as="h3" className="font-poppins">Loan Amount</Heading>
                </Box>
                <Heading as="h1" className="font-poppins">₹ {data?.loan_amount}</Heading>
                <Progress className='custom_progress' bg='#00000014' size='sm' value={data?.committed_loan_amount ? parseFloat(data?.committed_loan_amount) : 0} max={data?.loan_amount} />
                <Flex alignItems='center' mt={1}>
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.committed_loan_amount ? data?.committed_loan_amount : 0}</Heading>
                        <Heading as="p" className="font-poppins">Already funded</Heading>
                    </Box>
                    <Spacer />
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.remaining_loan_amount}</Heading>
                        <Heading as="p" className="font-poppins">Remaining</Heading>
                    </Box>
                </Flex>
            </Box>
            <Box className='accordion_box lending_card_bg lending_card_radius' mt={1}>
                <Accordion allowToggle>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Loan Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                <Text className='font-poppins loan_type'>
                                    {data?.products?.name}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                <Text className='font-poppins'>
                                    {/* {data?.products?.investor_return + "%"} */}
                                    {data?.interest_rate + "%"}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                <Text className='font-poppins'>
                                    {data?.tenure_duration}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                <Text className='font-poppins'>₹ {data?.loan_amount}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>{data?. product_id === 1 ? 'EMI Amount': 'Total Payout Amount'}</Heading>
                                <Text className='font-poppins'>₹ {parseFloat(data?.emi_amount)?.toFixed(2)}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Expiry Date</Heading>
                                <Text className='font-poppins'>
                                    {data?.due_date ? moment(data?.due_date).format("DD-MM-YYYY") : "N/A"}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Date</Heading>
                                <Text className='font-poppins'>
                                    {data?.created_date ? moment(data?.created_date).format("DD-MM-YYYY") : 'N/A'}
                                </Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Borrower Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Full Name</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.full_name}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>City Name</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.city}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>CKYC Number</Heading>
                                <Text className='font-poppins'>{data?.ckyc_detail?.ckyc_number}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                <Text className='font-poppins'>{data?.loan_purpose}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>House Ownership</Heading>
                                <Text className='font-poppins'>{data?.personal_data?.display_premise_status}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Age</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.age + ' Years'}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Employment Type</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.employment_type}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Risk category & ALPHA Score </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Equifax Score</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.equifax_score}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.alpha_rating}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
            <Box mt={1} className='lending_amount_add lending_card_bg lending_card_radius'>
                <Box className='invest_amount_lender'>
                    <Heading as="p" className='font-poppins'>Enter Investment Amount</Heading>
                    <Box className="d-flex align-items-center col-gap-10 invest_amount_container">
                        {data?.product_id === 2 ?
                            <Input
                                type="number"
                                size="md"
                                placeholder="₹ 0.00"
                                className="input_box_invest font-poppins"
                                value={data?.loan_amount ?? ''}
                                isReadOnly
                            /> : 
                            <Input
                                type="number"
                                size="md"
                                name="invest_amount"
                                placeholder="₹ 0.00"
                                onChange={props.handleChange}
                                className="input_box_invest font-poppins"
                                value={props.inputValues?.invest_amount ?? ''}
                            />
                        }
                        <Tooltip label='The minimum investment amount is ₹1000 and can be in multiples thereof.' 
                            hasArrow fontSize='xs' placement='bottom-end'>
                            <InfoOutlineIcon />
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <Box mt={3}>
                <Box className='invest_now_lender'>
                    <Box className='d-flex align-items-center col-gap-10' mb={1}>
                        <Heading as="p" className='font-poppins'>Your Wallet Balance</Heading>
                        {(Number(investAmount) > Number(walletBalance)) &&
                            <Tooltip label='Low Wallet Balance' 
                                hasArrow fontSize='xs' placement='bottom-start'>
                                <InfoOutlineIcon />
                            </Tooltip>
                        }
                    </Box>
                    <Box className='d-flex align-items-center col-gap-10'>
                        <Input
                            type="number"
                            size="md"
                            placeholder="₹ 0.00"
                            className="input_box_lender bg_white font-poppins"
                            value={props.walletData?.effective_balance ?? ''}
                            isReadOnly
                        />
                        {(Number(investAmount) > Number(walletBalance)) &&
                            <CustomButton size="md" type="submit" title="Add Funds" className="btn_theme font-poppins" onClick={props.handleAddFundsDialog} />
                        }
                    </Box>
                </Box>
                <Box className='invest_now_lender' mt={3}>
                    <Box className='d-flex align-items-center col-gap-10' mb={1}>
                        <Heading as="p" className='font-poppins'>Pledged Amount</Heading>
                        <Tooltip label='The pleged amount will be locked for 3 days' 
                            hasArrow fontSize='xs' placement='bottom-start'>
                            <InfoOutlineIcon />
                        </Tooltip>
                    </Box>
                    <Input
                        type="number"
                        size="md"
                        placeholder="₹ 0.00"
                        className="input_box_lender bg_white font-poppins"
                        value={pledgeAmount?.toFixed(2) ?? ''}
                        isReadOnly
                    />
                    <Text className='invest_note font-poppins'>Including {data?.investment_fee}% Investment Fee With 18% GST</Text>
                </Box>
                <Box className='invest_now_lender' mt={3}>
                    <Heading as="p" className='font-poppins' mb={1}>Wallet Balance After Disbursal</Heading>
                    <Input
                        type="number"
                        size="md"
                        placeholder="₹ 0.00"
                        className="input_box_lender bg_white font-poppins"
                        value={walletBalanceAfter?.toFixed(2) ?? ''}
                        isReadOnly
                    />
                </Box>
                <Flex mt={3} mb={3} className="column_gap declaration_note">
                    <Checkbox onChange={(e) => props.handleCheck(e, 'agree_consent')} isChecked={props.declaredCheck} />
                    <Heading as="p" className="font-poppins">I hereby acknowledge that my total accumulated investment across all P2P platforms is capped at INR 50,00,000 by RBI and if I wish to invest more than INR 10,00,000 , a certificate from a practising Chartered Accountant certifying minimum net-worth of INR 50,00,000 will be required.</Heading>
                </Flex>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={2} mt={0}>
            <Box className='full-width'>
                <CustomButton size="md" type="submit" title="Invest Now" className="btn_theme font-poppins" onClick={() => props.handleInvestNow(investNowData)} />
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
        isOpen={props.openSidebar}
        placement="right"
        onClose={props.handleCloseSidebar}
        size={'md'}
    >
    {PopupBox()}
  </Drawer>
  )
}

