import React from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, Heading, IconButton, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import Lottie from "lottie-react";
import LoadingFailAnimation from "../../../assets/lottiFiles/PaymentFail.json";
import LoadingSuccessAnimation from "../../../assets/lottiFiles/paymentSuccess.json";

export default function PaymentSuccess(props) {

    const PopupBox = () => (
        <Box className='wallet_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-between payment_header'>
                    <Heading as="h3" className="font-poppins">Payment</Heading>
                    <IconButton onClick={props.handleClosePaymentSuccessDialog}><CloseIcon /></IconButton>
                </Box>
                {props?.isFailed ?
                    <Box className='payment_status_fail'>
                        <Box className='lottie-width'>
                            <Lottie animationData={LoadingFailAnimation} loop={false} />
                        </Box>
                        <Heading as="h2" className='font-poppins'>Payment Failed</Heading>
                        <Text className="font-poppins">Your payment was unsuccessful. If the amount was deducted from your account, it will be refunded to your bank account. You can retry your payment.</Text>
                    </Box> :
                    <Box className='payment_status_success'>
                        <Box className='lottie-width'>
                            <Lottie animationData={LoadingSuccessAnimation} loop={false} />
                        </Box>
                        <Heading as="h2" className='font-poppins'>Payment Successful</Heading>
                    </Box>
                }
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleClosePaymentSuccessDialog}
        isOpen={props.openSuccessPayment}
        motionPreset="slideInBottom"
        // size={'sm'}
    >
        {PopupBox()}
    </Modal>
  )
}
