import React, { useState } from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import InstaLoanEmiCalculator from '../../components/InstaLoanEmiCalculator'
import BulletLoanEmiCalculator from '../../components/BulletLoanEmiCalculator'
import PersonalLoanEmiCalculator from '../../components/PersonalLoanEmiCalculator'

export default function InvestorEmiCalculatorSidebar(props) {

    const tabNames = [
        {
            id: 1,
            name: 'Insta Loan',
        },
        {
            id: 2,
            name: 'Bullet Loan',
        },
        {
            id: 3,
            name: 'Personal Loan',
        },
    ]

    const [activeTab, setActiveTab] = useState(0)
    const [instaLoanDetails, setInstaLoanDetails] = useState({
        loanAmount: [0, 7000],
        roi: [0, 25],
        tenure: [0, 10],
    })
    const [bulletLoanDetails, setBulletLoanDetails] = useState({
        loanAmount: [0, 30000],
        roi: [0, 18],
        tenure: [0, 240],
    })
    const [personalLoanDetails, setPersonalLoanDetails] = useState({
        loanAmount: [0, 40000],
        roi: [0, 24],
        tenure: [0, 9],
    })

    const handleTabChange = (index) => {
        setActiveTab(index);
    }

    const handleChangeInstaLoan = (e, type) => {
        const updatedDetails = {...instaLoanDetails, [type]: e,}
        setInstaLoanDetails(updatedDetails)
    }
    
    const handleChangeBulletLoan = (e, type) => {
        const updatedDetails = {...bulletLoanDetails, [type]: e,}
        setBulletLoanDetails(updatedDetails)
    }
    
    const handleChangePersonalLoan = (e, type) => {
        const updatedDetails = {...personalLoanDetails, [type]: e,}
        setPersonalLoanDetails(updatedDetails)
    }

    const handleLoanCalculator = () => {
        if (activeTab === 0) {
          let totalPayableInterest = (((instaLoanDetails.roi[1]) / 365) * parseInt(instaLoanDetails.loanAmount[1]) * parseInt(instaLoanDetails.tenure[1])) / 100;
          let totalAmt = totalPayableInterest + parseInt(instaLoanDetails.loanAmount[1])
          return {totalInterest : totalPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2)};
        }
        if (activeTab === 1) {
          let totalPayableInterest = (((parseInt(bulletLoanDetails.roi[1]) / 365) * parseInt(bulletLoanDetails.loanAmount[1]))/100) * parseInt(bulletLoanDetails.tenure[1]);
          let totalAmt = totalPayableInterest + parseInt(bulletLoanDetails.loanAmount[1])
          return {totalInterest : totalPayableInterest.toFixed(2), totalAmount: totalAmt.toFixed(2)};
        }
        if (activeTab === 2) {
          var interest = parseInt(personalLoanDetails.roi[1]) / 1200;
          var emi = Math.round(parseInt(personalLoanDetails.loanAmount[1]) * interest / (1 - (Math.pow(1 / (1 + interest), parseInt(personalLoanDetails.tenure[1])))) * 100) / 100;
          var totalPayable = Math.round((emi * parseInt(personalLoanDetails.tenure[1])) * 100) / 100;
          var interestPayable = Math.round((totalPayable * 1 - parseInt(personalLoanDetails.loanAmount[1]) * 1) * 100) / 100;
          return {emi : emi, totalPayable : totalPayable, interestPayable : interestPayable, interestRate : interest };
        }
    }
    
    const emiCalculator = handleLoanCalculator()

  const PopupBox = () => (
    <Box>
        {}
      <DrawerOverlay />
      <DrawerContent className='invest_sidebar_content'>
        <Box className="actions_group">
          <IconButton onClick={props.handleCalculatorSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header' pb={4} pt={4}>
            <Box className='emi_calculator_label'>
                <Heading as="h5" className='font-poppins'>Investment Return Calculator</Heading>
            </Box>
        </DrawerHeader>
        <DrawerBody>
        <Box className='emi_block bg_shadow bg_white'>
            <Tabs className='tabs_container' index={activeTab} onChange={handleTabChange}>
                <TabList className='tabs_list'>
                    {tabNames?.map((item, index) => {
                        return (
                            <Tab key={index}>{item.name}</Tab>
                        )
                    })}
                </TabList>
                <TabPanels>
                    {tabNames.map((item, index) => {
                    return (
                        <TabPanel key={index} className='tab_panel'>
                        {item.name === 'Insta Loan' && 
                            <InstaLoanEmiCalculator handleChangeInstaLoan={handleChangeInstaLoan} emiCalculator={emiCalculator}
                            instaLoanDetails={instaLoanDetails}
                            />
                        }
                        {item.name === 'Bullet Loan' &&
                            <BulletLoanEmiCalculator handleChangeBulletLoan={handleChangeBulletLoan} emiCalculator={emiCalculator}
                            bulletLoanDetails={bulletLoanDetails} 
                            />
                        }
                        {item.name === 'Personal Loan' &&
                            <PersonalLoanEmiCalculator handleChangePersonalLoan={handleChangePersonalLoan} emiCalculator={emiCalculator}
                            personalLoanDetails={personalLoanDetails} 
                            />
                        }
                        </TabPanel>
                    )
                    })}
                </TabPanels>
            </Tabs>
        </Box>
        </DrawerBody>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
        isOpen={props.openCalculatorSidebar}
        placement="right"
        onClose={props.handleCalculatorSidebar}
        size={'md'}
    >
    {PopupBox()}
  </Drawer>
  )
}

