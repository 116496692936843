export const GET_CURRENT_USER_DETAILS_REQUEST = 'GET_CURRENT_USER_DETAILS_REQUEST'
export const GET_CURRENT_USER_DETAILS_FAILURE = 'GET_CURRENT_USER_DETAILS_FAILURE'
export const GET_CURRENT_USER_DETAILS_SUCCESS = 'GET_CURRENT_USER_DETAILS_SUCCESS'

export const GET_DROPDOWN_DATA_REQUEST = 'GET_DROPDOWN_DATA_REQUEST'
export const GET_DROPDOWN_DATA_FAILURE = 'GET_DROPDOWN_DATA_FAILURE'
export const GET_DROPDOWN_DATA_SUCCESS = 'GET_DROPDOWN_DATA_SUCCESS'

export const CLEANUP_TOAST = 'CLEANUP_TOAST'

export const CLEANUP_ESIGN_DATA = 'CLEANUP_ESIGN_DATA'