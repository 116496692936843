import { Box, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import EquifaxIcon from '../../../../../assets/images/equifax.svg'

export default function CreditScore(props) {

  const getStatus = (score) => {
    if (score >= 1 && score <= 299) {
      return { className: 'poor', text: 'Poor' };
    } else if (score >= 300 && score <= 550) {
      return { className: 'poor', text: 'Poor' };
    } else if (score >= 551 && score <= 650) {
      return { className: 'average', text: 'Average' };
    } else if (score >= 651 && score <= 750) {
      return { className: 'fair', text: 'Fair' };
    } else if (score >= 751 && score <= 900) {
      return { className: 'excellent', text: 'Excellent' };
    } else {
      return { className: '', text: '' };
    }
  };

  const { className, text } = getStatus(props.score);

  return (
    <Box mt={4} className='credit_score bg_shadow'>
      <Box mb={4} className='d-flex align-items-center justify-content-between score_flex'>
        <Heading as="h2" className='font-poppins card_heading w-auto'>Credit Score</Heading>
        <Heading as="p" className='font-poppins'>Powered By<Image src={EquifaxIcon} /></Heading>
      </Box>
        <Box className="scoreMeter">
            <Box className={`meterArrow value-${props.score}`} style={{ transform: `rotate(${props.calculateRotation}deg)` }}></Box>
            <Box className="meterScore">
                <Heading as="h2" className='font-poppins score'>{props.score} </Heading>
                <Heading as="h2" className={`font-poppins scoreBand ${className}`}>{text}</Heading>
            </Box>
        </Box>
    </Box>
  )
}
