import React, { useState } from 'react'
import {Box, Heading, Grid, GridItem, Tab, TabList, TabPanel, TabPanels, Tabs, Image, IconButton} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton';
import { Document, Page } from 'react-pdf';
import moment from 'moment';
import OpenFIleDialog from '../../../../elements/OpenFIleDialog';
import { ViewIcon } from '@chakra-ui/icons';
import { detectFileType } from '../../../../../utilities/DownloadDocuments';

export default function PersonalDetails(props) {

    const data = props?.userData?.data
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [currentFileType, setCurrentFileType] = useState(null)

    const fileType = {
        panCard: detectFileType(data?.document_data?.pan_card),
        aadhaarFront: detectFileType(data?.document_data?.aadhaar_front),
        aadhaarBack: detectFileType(data?.document_data?.aadhaar_back),
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

    const handleNext = () => {
        props.onClick()
    }

  return (
    <Box mt={5}>
        <Grid className="main_grid_container justify-content-between">
            <GridItem colSpan={7}>
                <Box className='summary_heading'>
                    <Heading as="h6" className='font-poppins'>Summary</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Gender</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.personal_data?.display_gender}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Date of Birth</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.dob ? moment(data?.dob).format('DD-MMM-YYYY'): null}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Aadhaar Number</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.aadhar_card_data?.aadhar_number}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>PAN Number</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.pan}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Address</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>
                        {data?.personal_data?.address_houseNumber}
                        {data?.personal_data?.address_landmark}{' '}
                        {data?.personal_data?.address_street}{' '}
                        {data?.personal_data?.address_subdistrict}{', '}
                        {data?.personal_data?.address_district}{', '}
                        {data?.personal_data?.address_state}{', '}
                        {data?.personal_data?.address_pincode}
                        {data?.personal_data?.address_country}
                        {/* {data?.personal_data?.address_houseNumber}{' '}{data?.personal_data?.address_street}{' '}
                        {data?.personal_data?.address_subdistrict}{' '}{data?.personal_data?.address_district}{' '}
                        {data?.personal_data?.address_state}{', '}{data?.personal_data?.address_pincode} */}
                    </Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>House Ownership </Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.personal_data?.display_premise_status}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Education Qualification</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.personal_data?.display_educational_qualification}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Current Company Name</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'>{data?.occupational_data?.company_name}</Heading>
                </Box>
                <Box className='d-flex card_data' mt={2}>
                    <Heading as="h6" className='font-poppins box_title w-55'>Company Sector</Heading>
                    <Heading as="h6" className='font-poppins box_data w-35'> {data?.occupational_data?.display_company_sector} </Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={5}>
                <Tabs className='tabs_container details_tabs'>
                    <TabList className='tabs_list justify-content-center'>
                        <Tab>Aadhar Card</Tab>
                        <Tab>PAN Card</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel className='tab_panel'>
                            {data?.document_data?.aadhaar_front ?
                                <Box className='pdf_read'>
                                    {fileType.aadhaarFront === 'pdf' ?
                                        <Document file={data?.document_data?.aadhaar_front}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        : 
                                        <Image src={data?.document_data?.aadhaar_front} className='docs_image' />
                                    }
                                    {data?.document_data?.aadhaar_front ?
                                        <IconButton onClick={() => handleModal(data?.document_data?.aadhaar_front, fileType.aadhaarFront)} className="icon_button_view" 
                                            icon={<ViewIcon />} /> : null }
                                </Box> : 
                                <Box className='pdf_read no-data'>
                                    <Heading as="h6" className='font-poppins'>No document available</Heading>
                                </Box>
                            }
                            {data?.document_data?.aadhaar_back ?
                                <Box className='pdf_read'>
                                    {fileType.aadhaarBack === 'pdf' ?
                                        <Document file={data?.document_data?.aadhaar_back}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        : 
                                        <Image src={data?.document_data?.aadhaar_back} className='docs_image' />
                                    }
                                    {data?.document_data?.aadhaar_back ?
                                    <IconButton onClick={() => handleModal(data?.document_data?.aadhaar_back, fileType.aadhaarBack)} className="icon_button_view" 
                                        icon={<ViewIcon />} /> : null }
                                </Box> : 
                                <Box className='pdf_read no-data'>
                                    <Heading as="h6" className='font-poppins'>No document available</Heading>
                                </Box>
                            }
                        </TabPanel>
                        <TabPanel className='tab_panel'>
                            {data?.document_data?.pan_card ?
                                <Box className='pdf_read'>
                                    {fileType.panCard === 'pdf' ?
                                        <Document file={data?.document_data?.pan_card}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        : 
                                        <Image src={data?.document_data?.pan_card} className='docs_image' />
                                    }
                                    {data?.document_data?.pan_card ?
                                    <IconButton onClick={() => handleModal(data?.document_data?.pan_card, fileType.panCard)} className="icon_button_view" 
                                        icon={<ViewIcon />} /> : null }
                                </Box> : 
                                <Box className='pdf_read no-data'>
                                    <Heading as="h6" className='font-poppins'>No document available</Heading>
                                </Box>
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </GridItem>
        </Grid>
        <Box className="kyc_actions btn_gap" mb={4} mt={4}>
            <CustomButton size="md" type="submit" title="Next" className="btn_theme font-poppins" onClick={handleNext} />
        </Box>
        <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
    </Box>
  )
}
