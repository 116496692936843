import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  getInvestorDashboardData: {
    data: null,
    loading: false,
    error: null,
  },
  getInvestorOpportunityData: {
    data: null,
    loading: false,
    error: null,
  },
  getQuickInvestment: {
    data: null,
    loading: false,
    error: null,
  },
  getInvestorPortfolioData: {
    data: null,
    loading: false,
    error: null,
  },
  getInvestorWalletData:{
    data:null,
    loading:false,
    error:null
  },
  getWalletTransaction:{
    data:null,
    loading:false,
    error:null
  },
  getInvestmentDetails:{
    data:null,
    loading:false,
    error:null
  },
  getInvestorLoanListStatus:{
    data:null,
    loading:false,
    error:null
  },
  getInvestInLoanDetails:{
    data:null,
    loading:false,
    error:null
  },
  getWalletPassbook:{
    data:null,
    loading:false,
    error:null
  },
  withdrawWalletAmount:{
    data:null,
    loading:false,
    error:null
  },
  postNomineeDetails:{
    data:null,
    loading:false,
    error:null
  },
  changePaymentMode:{
    data:null,
    loading:false,
    error:null
  }
}


const investorReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_REQUEST:
      return {
        ...state,
        getInvestorDashboardData: {
          ...state.getInvestorDashboardData,
          data: null,
          loading: true,
          error: null,
        },
      }

    case actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        getInvestorDashboardData: {
          ...state.getInvestorDashboardData,
          data: action.payload,
          loading: false,
          error: null,
        },
      }

    case actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        getInvestorDashboardData: {
          ...state.getInvestorDashboardData,
          data: null,
          loading: false,
          error: action.payload,
        },
    }
    case actionTypes.GET_INVESTMENT_OPPORTUNITIES_REQUEST:
      return {
        ...state,
        getInvestorOpportunityData: {
          ...state.getInvestorOpportunityData,
          data: null,
          loading: true,
          error: null,
        }
    }
    case actionTypes.GET_INVESTMENT_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        getInvestorOpportunityData: {
          ...state.getInvestorOpportunityData,
          data: action.payload,
          loading: false,
          error: null,
        }
    }
    case actionTypes.GET_INVESTMENT_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        getInvestorOpportunityData: {
          ...state.getInvestorOpportunityData,
          data:null,
          loading: false,
          error: action.payload,
        }
    }
    case actionTypes.GET_QUICK_INVESTMENT_REQUEST:
      return {
        ...state,
        getQuickInvestment: {
          ...state.getQuickInvestment,
          data: null,
          loading: true,
          error: null,
        }
    }
    case actionTypes.GET_QUICK_INVESTMENT_SUCCESS:
      return {
        ...state,
        getQuickInvestment: {
          ...state.getQuickInvestment,
          data: action.payload,
          loading: false,
          error: null,
        }
    }
    case actionTypes.GET_QUICK_INVESTMENT_FAILURE:
      return {
        ...state,
        getQuickInvestment: {
          ...state.getQuickInvestment,
          data:null,
          loading: false,
          error: action.payload,
        }
    }
    case actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_REQUEST:
    return{
      ...state,
      getInvestorPortfolioData: {
        ...state.getInvestorPortfolioData,
        data:null,
        loading:true,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_SUCCESS:
    return{
      ...state,
      getInvestorPortfolioData: {
        ...state.getInvestorPortfolioData,
        data:action.payload,
        loading:false,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_FAILURE:
    return{
      ...state,
      getInvestorPortfolioData: {
        ...state.getInvestorPortfolioData,
        data:null,
        loading:false,
        error:action.payload
      }
    }          
    case actionTypes.GET_INVESTOR_WALLET_REQUEST:
    return{
      ...state,
      getInvestorWalletData:{
        ...state.getInvestorWalletData,
        data:null,
        loading:true,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_WALLET_SUCCESS:
    return{
      ...state,
      getInvestorWalletData:{
        ...state.getInvestorWalletData,
        data:action.payload,
        loading:false,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_WALLET_FAILURE:
    return{
      ...state,
      getInvestorWalletData:{
        ...state.getInvestorWalletData,
        data:null,
        loading:false,
        error:action.payload
      }
    }
    case actionTypes.GET_WALLET_TRANSACTION_REQUEST:
    return{
      ...state,
      getWalletTransaction:{
        ...state.getWalletTransaction,
        data:null,
        loading:true,
        error:null
      }
    }
    case actionTypes.GET_WALLET_TRANSACTION_SUCCESS:
    return{
      ...state,
      getWalletTransaction:{
        ...state.getWalletTransaction,
        data:action.payload,
        loading:false,
        error:null
      }
    }
    case actionTypes.GET_WALLET_TRANSACTION_FAILURE:
    return{
      ...state,
      getWalletTransaction:{
        ...state.getWalletTransaction,
        data:null,
        loading:false,
        error:action.payload
      }
    }
    case actionTypes.GET_INVESTMENT_DETAILS_REQUEST:
    return{
      ...state,
      getInvestmentDetails:{
        ...state.getInvestmentDetails,
        data:null,
        loading:true,
        error:null
      }
    }
    case actionTypes.GET_INVESTMENT_DETAILS_SUCCESS:
    return{
      ...state,
      getInvestmentDetails:{
        ...state.getInvestmentDetails,
        data:action.payload,
        loading:false,
        error:null
      }
    }
    case actionTypes.GET_INVESTMENT_DETAILS_FAILURE:
    return{
      ...state,
      getInvestmentDetails:{
        ...state.getInvestmentDetails,
        data:null,
        loading:false,
        error:action.payload
      }
    }
    case actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_REQUEST:
    return{
      ...state,
      getInvestorLoanListStatus:{
        ...state.getInvestorLoanListStatus,
        data:null,
        loading:true,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_SUCCESS:
    return{
      ...state,
      getInvestorLoanListStatus:{
        ...state.getInvestorLoanListStatus,
        data:action.payload,
        loading:false,
        error:null
      }
    }
    case actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_FAILURE:
    return{
      ...state,
      getInvestorLoanListStatus:{
        ...state.getInvestorLoanListStatus,
        data:null,
        loading:false,
        error:action.payload
      }
    }
    case actionTypes.GET_INVEST_IN_LOAN_REQUEST:
      return{
        ...state,
        getInvestInLoanDetails:{
          ...state.getInvestInLoanDetails,
          data:null,
          loading:true,
          error:null
        },
    }
    case actionTypes.GET_INVEST_IN_LOAN_SUCCESS:
      return{
        ...state,
        getInvestInLoanDetails:{
          ...state.getInvestInLoanDetails,
          data:action.payload,
          loading:false,
          error:null
        },
    }
    case actionTypes.GET_INVEST_IN_LOAN_FAILURE:
      return{
        ...state,
        getInvestInLoanDetails:{
          ...state.getInvestInLoanDetails,
          data:null,
          loading:false,
          error:action.payload
        },
    }
    case actionTypes.GET_WALLET_PASSBOOK_REQUEST:
      return{
        ...state,
        getWalletPassbook:{
          ...state.getWalletPassbook,
          data:null,
          loading:true,
          error:null
        },
    }
    case actionTypes.GET_WALLET_PASSBOOK_SUCCESS:
      return{
        ...state,
        getWalletPassbook:{
          ...state.getWalletPassbook,
          data:action.payload,
          loading:false,
          error:null
        },
    }
    case actionTypes.GET_WALLET_PASSBOOK_FAILURE:
      return{
        ...state,
        getWalletPassbook:{
          ...state.getWalletPassbook,
          data:null,
          loading:false,
          error:action.payload
        },
    }
    case actionTypes.WITHDRAW_WALLET_AMOUNT_REQUEST:
      return{
        ...state,
        withdrawWalletAmount:{
          ...state.withdrawWalletAmount,
          data:null,
          loading:true,
          error:null
        },
    }
    case actionTypes.WITHDRAW_WALLET_AMOUNT_SUCCESS:
      return{
        ...state,
        withdrawWalletAmount:{
          ...state.withdrawWalletAmount,
          data:action.payload,
          loading:false,
          error:null
        },
    }
    case actionTypes.WITHDRAW_WALLET_AMOUNT_FAILURE:
      return{
        ...state,
        withdrawWalletAmount:{
          ...state.withdrawWalletAmount,
          data:null,
          loading:false,
          error:action.payload
        },
    }
    case actionTypes.POST_NOMINEE_DETAILS_REQUEST:
      return{
        ...state,
        postNomineeDetails:{
          ...state.postNomineeDetails,
          data:null,
          loading:true,
          error:null
        },
      }
    case actionTypes.POST_NOMINEE_DETAILS_SUCCESS:
      return{
        ...state,
        postNomineeDetails:{
          ...state.postNomineeDetails,
          data:action.payload,
          loading:false,
          error:null
        },
      }
    case actionTypes.POST_NOMINEE_DETAILS_FAILURE:
      return{
        ...state,
        postNomineeDetails:{
          ...state.postNomineeDetails,
          data:null,
          loading:false,
          error:action.payload
        },
      }
    case actionTypes.POST_PAYMENT_MODE_REQUEST:
      return{
        ...state,
        changePaymentMode:{
          ...state.changePaymentMode,
          data:null,
          loading:true,
          error:null
        },
      }
    case actionTypes.POST_PAYMENT_MODE_SUCCESS:
      return{
        ...state,
        changePaymentMode:{
          ...state.changePaymentMode,
          data:action.payload,
          loading:false,
          error:null
        },
      }
    case actionTypes.POST_PAYMENT_MODE_FAILURE:
      return{
        ...state,
        changePaymentMode:{
          ...state.changePaymentMode,
          data:null,
          loading:false,
          error:action.payload
        },
      }
    case actionTypes.CLEANUP_TOAST:
      return {
        ...state,
        getQuickInvestment: {
          data: null,
          loading: false,
          error: null,
        },
        withdrawWalletAmount:{
          data:null,
          loading:false,
          error:null
        },
        getWalletPassbook:{
          data:null,
          loading:false,
          error:null
        },
        postNomineeDetails:{
          data:null,
          loading:false,
          error:null
        },
        changePaymentMode:{
          data:null,
          loading:false,
          error:null
        }
      }
    default:
      return state
  }

}


export default investorReducer