import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Image, Tooltip} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import {useHistory, Link} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, setItem } from '../../../../utilities/authUtils'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import { Stepper, Step } from 'react-form-stepper';
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import CustomButton from '../../../elements/customButton'
import Loader from '../../../elements/loader'
import ImportantDialog from '../../../elements/ImportantDialog'
import CustomToast from '../../../elements/customToast'
import { DIGI_LOCKER_SDK_ESIGN, ENACH_ENV, SDK_ENV } from '../../../../constants/Constant'
// import { downloadDocuments } from '../../../../utilities/DownloadDocuments'
import LoanEligibleDialog from './components/LoanEligibleDialog'
import { downloadDocuments } from '../../../../utilities/DownloadDocuments'

const LoanStatus = (props) => {

    const connectorStyleConfig = {
        completedColor: '#0FA958',
        activeColor: '#BA1F33',
        disabledColor: '#BA1F33',
        size: '1.5px',
        style: 'dashed',
    };

    const history = useHistory()
    const { addToast } = CustomToast()
    const borrowerState = useSelector((state) => state.borrowerState)
    const masterState = useSelector((state) => state.masterState)
    const [loading, setLoading] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [type, setType] = useState(null)
    const userDetails = borrowerState?.getUserDashboardDetails?.data?.data
    const userData = masterState?.getCurrentUserDetails?.data?.data
    const loanListStatusData = borrowerState?.getLoanListStatus?.data?.data
    const loanJourneyData = borrowerState?.trackLoanJourney?.data?.data

    const digioSdkInstantiation = async () => {
        setLoading(true)
        try {
          const options = {
            environment: SDK_ENV,
            callback: async function (response) {
              if (response.hasOwnProperty("error_code")) {
                setLoading(false)
                addToast({ message: response?.message, status: 'error' })
                props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
              } else {
                setLoading(false)
                addToast({ message: response?.message, status: 'success' })
                props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
              }
            },
            is_iframe: true,
            Is_redirection_approach: false,
            theme: {
              primaryColor: "#000",
              secondaryColor: "#000000"
            }
          };
          var digio = new window.Digio(options);
          digio.init();
          digio.submit(eSignResponse?.doc_id, userDetails?.user?.email, eSignResponse?.token);
        } catch (error) {
            setLoading(false)
          console.error("Error in 'https://api.digio.in/user/auth/init' \:", error);
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = DIGI_LOCKER_SDK_ESIGN;
        script.async = true;
        document.body.appendChild(script);
        props.actions.getUserDashboardDetailsAction()
        props.actions.getCurrentUserDetailsAction()
        props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
    }, []);

    useEffect(() => {
        if(loanListStatusData?.[0]?.id) {
            props.actions.trackLoanJourneyAction({loanId: loanListStatusData?.[0]?.id})
        }
    }, [loanListStatusData]);

    useEffect(() => {
        if (borrowerState?.createESignRequest?.data?.success === true) {
            digioSdkInstantiation()
            props.actions.cleanEsignRequestData()
        }
    }, [borrowerState?.createESignRequest?.data])

    useEffect(() => {
        if (borrowerState?.acceptLoanEligibility?.data?.success === true) {
            addToast({message: borrowerState?.acceptLoanEligibility?.data?.message, status: 'success'})
            props?.actions?.cleanUpToast()
            setOpenConfirmation(false)
            props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
        } else if (borrowerState?.acceptLoanEligibility?.data?.success === false){
            addToast({message: borrowerState?.acceptLoanEligibility?.data?.message, status: 'error'})
            props?.actions?.cleanUpToast()
          }
    }, [borrowerState?.acceptLoanEligibility?.data])

    useEffect(() => {
        if (borrowerState?.withdrawnLoanEligibility?.data?.success === true) {
            addToast({message: borrowerState?.withdrawnLoanEligibility?.data?.message, status: 'success'})
            props?.actions?.cleanUpToast()
            setOpenConfirmation(false)
            props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
        } else if (borrowerState?.withdrawnLoanEligibility?.data?.success === false){
            addToast({message: borrowerState?.withdrawnLoanEligibility?.data?.message, status: 'error'})
            props?.actions?.cleanUpToast()
          }
    }, [borrowerState?.withdrawnLoanEligibility?.data])

    const handleApply = () => {
        history.push(routesNames.BORROWERAPPLYLOAN)
    }

    const handleESign = (loanId) => {
        let data = {
            loan_number : loanId
        }
        props.actions.createESignAction(data)
    }

    const handleENach = async (docID) => {
        setLoading(true)
        try {
          const options = {
            environment: ENACH_ENV,
            callback: async function (response) {
              if (response.hasOwnProperty("error_code")) {
                setLoading(false)
                addToast({ message: response?.message, status: 'error' })
              } else {
                setLoading(false)
                addToast({ message: response?.message, status: 'success' })
                props.actions.getLoanListStatusAction({status: '0,1,2,3,4'})
              }
            },
            is_iframe: true,
            Is_redirection_approach: false,
            theme: {
              primaryColor: "#000",
              secondaryColor: "#000000"
            }
          };
          var digio = new window.Digio(options);
          digio.init();
          digio.submit(docID, userDetails?.user?.email);
        } catch (error) {
          setLoading(false)
          console.error("Error in digioSdkInstantiation:", error);
        }
    }

    const handleConfirmation = (type) => {
        setType(type)
        setOpenConfirmation(!openConfirmation)
    }

    const handleApprove = (data) => {
        props.actions.acceptLoanEligibilityAction({loan_number: data})
    }

    const handleWithdrawn = (data) => {
        props.actions.withdrawnLoanEligibilityAction({loan_number: data})
    }

    const handleDownloadAgreement = (url) => {
        downloadDocuments(url, 'agreement')
    }

    const handleProfile = () => {
        history.push(routesNames.BORROWERPROFILE)
    }
    
    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

    const isKYCPending = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 0)
    const isKYCUnderReview = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 1)
    const eSignResponse = borrowerState?.createESignRequest?.data
    const isLoanApplied = (loanListStatusData?.[0]?.loan_status === '1' || loanListStatusData?.[0]?.loan_status === '2' || loanListStatusData?.[0]?.loan_status === '3' || loanListStatusData?.[0]?.loan_status === '4')
    const ROI = loanListStatusData?.[0]?.product_id === 3 ? '0.2% Per Day' : (loanListStatusData?.[0]?.eligible_loan_interest + '%')
    const checkENachReq = (loanListStatusData?.[0]?.enach_request && loanListStatusData?.[0]?.enach_request.status === 0)
    const activeSteps = loanJourneyData?.filter((data) => data.status_level === 'green')
    const totalFunded = (loanListStatusData?.[0]?.committed_loan_amount / loanListStatusData?.[0]?.eligible_loan_amt) * 100
    const isLoanRejected = borrowerState?.getLoanListStatus?.data?.is_loan_applicable
    const isDocumentCreated = loanListStatusData?.[0]?.loan_status === '4'
    console.log(loanListStatusData, 'loanListStatusData')
  return (
    <>
        {(masterState?.getCurrentUserDetails?.loading || borrowerState?.getLoanListStatus?.loading || borrowerState?.getUserDashboardDetails?.loading || borrowerState?.createESignRequest?.loading || loading || borrowerState?.acceptLoanEligibility?.loading || borrowerState?.withdrawnLoanEligibility?.loading ||borrowerState?.trackLoanJourney?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={8}>
                <Box className='d-flex align-items-center justify-content-between'>
                    <Heading as="h2" className='font-poppins page_heading'>Loan Applications</Heading>
                    <ImportantDialog />
                </Box>
                </GridItem>
                <GridItem colSpan={4}>
                    <NavBar data={userData} handleProfile={handleProfile} />
                </GridItem>
            </Grid>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={7}>
                    {isKYCUnderReview ?
                        <Box mt={4} className='kyc_verify'>
                            <Heading as="h3" className='font-poppins'>
                                <Image src={IconkYC} />
                                Your documents under review
                                <Link to={routesNames.BORROWERKYC}>Tap to check your documents</Link>
                            </Heading> 
                        </Box> : isKYCPending ?
                        <Box mt={4} className='kyc_verify'>
                            <Heading as="h3" className='font-poppins'>
                                <Image src={IconkYC} />
                                Your ALPHAMONEY account needs Verification,
                                <Link to={routesNames.BORROWERKYC}>Tap to start your KYC</Link>
                            </Heading>
                        </Box> : null
                    }
                </GridItem>
            </Grid>
            {loanListStatusData?.length > 0 ?
                <>
                    <Box mt={5}>
                        <Heading as="h2" className='font-poppins block_heading' mb={3}>Your Loan Application Status</Heading>
                        <Box className='dashboard_box bg_shadow'>
                            <Stepper activeStep={activeSteps?.length} connectorStateColors={true} connectorStyleConfig={connectorStyleConfig}>
                                {loanJourneyData?.map((step, index) => {
                                    // const completedArr = ['Received', 'Done', 'Checked', 'Pledged', 'In Process'].includes(step.state_status);
                                    return(
                                        <Step key={index} className="custom-stepper" children={step.status_level === 'green' ? <CheckCircleIcon /> : index + 1}
                                            label={
                                                <>
                                                    {step.state_label}
                                                    <br />
                                                    <Box pt={2} className='d-flex align-items-center col-gap-5 justify-content-center'>
                                                        <Heading as="p" className={step.status_level === 'green' ? 'completed_status status font-poppins' : 'status font-poppins'}>
                                                            {step.state_status ? step.state_status : 'Pending'}
                                                        </Heading>
                                                        {step.status_id === 10 ?
                                                            <Tooltip label={!(loanListStatusData?.[0]?.esign_request) ? 'Your Loan Application is 100% Funded Please E-Sign the loan Agreement to get Funds in Your Bank Account' : 'Your Loan Agreement E-Sign Is Successfully Completed. Please Wait for the Investors To E-Sign'} 
                                                                hasArrow fontSize='xs' placement='right-end'>
                                                                <InfoOutlineIcon />
                                                            </Tooltip> :
                                                        step.status_id === 11 ?
                                                            <Tooltip label={'Loan agreement is successfully e-signed by the investor. Please complete the e-NACH process to receive funds in your bank account.'} 
                                                                hasArrow fontSize='xs' placement='right-end'>
                                                                <InfoOutlineIcon />
                                                            </Tooltip> :
                                                        step.status_id === 13 ?
                                                            <Tooltip label={'The e-NACH process is successful. Funds will be credited to your account shortly.'} 
                                                                hasArrow fontSize='xs' placement='right-end'>
                                                                <InfoOutlineIcon />
                                                            </Tooltip> : null
                                                        }
                                                    </Box>
                                                    {(step.status_id === 7 && step.state_name === 'funding_in_process') ?
                                                        <Heading mt={2} as="p" className={totalFunded === 100 ? 'completed_status status font-poppins' : 'status font-poppins'}>
                                                            {`(${'₹'}${Number(loanListStatusData?.[0]?.committed_loan_amount)})${' '}${totalFunded.toFixed(0)}${'%'}${' '}Funded`}
                                                        </Heading> :
                                                    (!step.status_id && step.state_name === 'funding_in_process' && loanListStatusData?.[0]?.loan_status === '1' && loanListStatusData?.[0]?.emi_amount) ?
                                                        <Heading mt={2} as="p" className={totalFunded === 100 ? 'completed_status status font-poppins' : 'status font-poppins'}>
                                                            {`(${'₹'}${0})${' '}${totalFunded.toFixed(0)}${'%'}${' '}Funded`}
                                                        </Heading> :
                                                        null
                                                    }
                                                    {/* {(loanListStatusData?.[0]?.loan_status === '3' && step.status_id === 10 && !(loanListStatusData?.[0]?.esign_request)) ? */}
                                                    {(loanListStatusData?.[0]?.loan_status === '3' && step.status_id === 10) ?
                                                        <Box mt={2}>
                                                            <CustomButton size="md" type="submit" title="E-Sign" className="btn_green font-poppins" onClick={() => handleESign(loanListStatusData?.[0]?.loan_number)} />
                                                        </Box> : 
                                                    (checkENachReq && step.status_id === 11) ?
                                                        <Box mt={2}>
                                                            <CustomButton size="md" type="submit" title="E-Nach" className="btn_green font-poppins" onClick={() => handleENach(loanListStatusData?.[0]?.enach_request?.mandate_id)} />
                                                        </Box> : null
                                                    }
                                                </>
                                            }
                                        />
                                    )
                                })}
                            </Stepper>
                        </Box>    
                    </Box>
                    <Grid mt={5} className="main_grid_container">
                        <GridItem colSpan={4}>
                            <Box className='loan_card_heading'>
                                <Heading as="h6" className='font-poppins'>Applied Loan Detail</Heading>
                            </Box>
                            {isLoanApplied ?
                            //// when you accept loan coming from underwriter
                                <Box mt={3}>
                                   
                                    <Box className='eligible_card'>
                                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan No.</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.loan_number}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.products?.name}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>₹{loanListStatusData?.[0]?.eligible_loan_amt}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.display_loan_tenure}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Rate Of Interest</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{ROI}</Heading>
                                        </Box>
                                    </Box>
                                    {!(loanListStatusData?.[0]?.esign_request) ?
                                        <Box className='withdraw_text d-flex justify-content-end align-items-center col-gap-10' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Ticket is live for funding</Heading>
                                            <Tooltip label='Investors have 3 days to contribute funds. If 80% of the loan applications are funded within this period, borrowers can E-sign the agreement & E-Nach and receive funds in their bank account. If the funding threshold is not met within 3 days, the application will expire. However, if 100% of the loan is funded before the 3-day mark, borrowers can E-sign the agreement & E-Nach and receive funds immediately.' 
                                                hasArrow fontSize='xs' placement='right-end'>
                                                <InfoOutlineIcon />
                                            </Tooltip>
                                        </Box> : null
                                    }
                                    {isDocumentCreated ?
                                        <Box mt={3}>
                                            <CustomButton size="md" type="submit" title="Download Agreement" className="btn_theme font-poppins w-100" onClick={() => handleDownloadAgreement(loanListStatusData?.[0]?.esign_request?.eSign_doc_file_url)} />
                                        </Box> : null
                                    }
                                </Box> :
                                //// when you applied for a loan
                                <Box mt={3} className='applied_card bg_shadow'>
                                      <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan No.</Heading>
                                        <Heading as="h6" className='font-poppins f-600 highlight'>
                                            {loanListStatusData?.[0]?.loan_number}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                        <Heading as="h6" className='font-poppins f-600 highlight'>
                                            {loanListStatusData?.[0]?.products?.name}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>₹{loanListStatusData?.[0]?.loan_amount}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>
                                            {loanListStatusData?.[0]?.display_loan_tenure}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.loan_purpose}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data_borrower justify-content-between align-items-center' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Rate Of Interest</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.expected_interest_rate}%</Heading>
                                    </Box>
                                </Box>
                            }
                        </GridItem>
                    </Grid>
                    {loanListStatusData?.[0]?.eligible_loan_amt && loanListStatusData?.[0]?.eligible_loan_interest && loanListStatusData?.[0]?.eligible_loan_tenure && loanListStatusData?.[0]?.loan_status === '0' ?
                        <Box mt={5}>
                            <Box className='loan_card_heading'>
                                <Heading as="h6" className='font-poppins'>Eligible Loan Detail</Heading>
                            </Box>
                            <Grid className="main_grid_container">
                                <GridItem colSpan={4}>
                                    <Box mt={3} className='eligible_card'>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.products?.name}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>₹{loanListStatusData?.[0]?.eligible_loan_amt}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.display_loan_tenure}</Heading>
                                        </Box>
                                        <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                            <Heading as="h6" className='font-poppins'>Rate Of Intefrest </Heading>
                                            <Heading as="h6" className='font-poppins f-600'>{loanListStatusData?.[0]?.eligible_loan_interest}%</Heading>
                                        </Box>
                                    </Box>
                                    <Box className='withdraw_text' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Auto Withdraw In 3 days</Heading>
                                    </Box>
                                    <Box className="d-flex justify-content-end apply_btn col-gap-15" mt={2}>
                                        <CustomButton size="md" type="submit" title="Withdrawn" className="btn_theme_transparent font-poppins" onClick={() => handleConfirmation('withdrawn')} />
                                        <CustomButton size="md" type="submit" title="Apply" className="btn_theme font-poppins" onClick={() => handleConfirmation('apply')} />
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box> : null 
                    }
                </> :
            // isLoanRejected === 0 ?
            //     <Box mt={4} className='check_loan_reject'>
            //       <Image src={RejectedIcon} />
            //       <Heading as="h3" className='font-poppins'>
            //         You're not eligible for a loan at this time. Please try again in 90 days.
            //       </Heading> 
            //     </Box> :
                <Box mt={6} className='no_loans'>
                    <Heading as="h2" className='font-poppins block_heading' mb={3}>You have not applied for any loan.</Heading>
                    <CustomButton size="md" type="submit" title="Apply Loan" className="btn_theme font-poppins" onClick={handleApply} />
                </Box>
            }
        </Box>
        <LoanEligibleDialog openConfirmation={openConfirmation} handleConfirmation={handleConfirmation} type={type}
            handleApprove={handleApprove} handleWithdrawn={handleWithdrawn} loanListStatusData={loanListStatusData}
        />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(LoanStatus)