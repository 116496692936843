import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  getCPDashboardDetails: {
    data: null,
    loading: false,
    error: null,
  },
  getCPUsers: {
    data: null,
    loading: false,
    error: null,
  },
  getCPPayout: {
    data: null,
    loading: false,
    error: null,
  },
  getCPUserDetailsById: {
    data: null,
    loading: false,
    error: null,
  },
  createCPDigiLocker: {
    data: null,
    loading: false,
    error: null,
  },
  getdownloadCPAadhaarData: {
    data: null,
    loading: false,
    error: null,
  },
  saveCPBankDetails: {
    data: null,
    loading: false,
    error: null,
  },
  generateCPOrder: {
    data: null,
    loading: false,
    error: null,
  },
  getCPPaymentStatus: {
    data: null,
    loading: false,
    error: null,
  },
  updateCPOrderStatus: {
    data: null,
    loading: false,
    error: null,
  },
  createLoan: {
    data: null,
    loading: false,
    error: null,
  },
  cpLoanList: {
    data: null,
    loading: false,
    error: null,
  },
  createCPESignRequest: {
    data: null,
    loading: false,
    error: null,
  },
  getInvoice: {
    data: null,
    loading: false,
    error: null,
  },
  acceptCPLoanEligibility: {
    data: null,
    loading: false,
    error: null,
  },
  withdrawnCPLoanEligibility: {
    data: null,
    loading: false,
    error: null,
  },
}

const cpReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CP_DASHBOARD_DETAILS_REQUEST:
      return {
        ...state,
        getCPDashboardDetails: {
          ...state.getCPDashboardDetails,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        getCPDashboardDetails: {
          ...state.getCPDashboardDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        getCPDashboardDetails: {
          ...state.getCPDashboardDetails,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_USERS_REQUEST:
      return {
        ...state,
        getCPUsers: {
          ...state.getCPUsers,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_USERS_SUCCESS:
      return {
        ...state,
        getCPUsers: {
          ...state.getCPUsers,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_USERS_FAILURE:
      return {
        ...state,
        getCPUsers: {
          ...state.getCPUsers,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_PAYOUT_REQUEST:
      return {
        ...state,
        getCPPayout: {
          ...state.getCPPayout,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_PAYOUT_SUCCESS:
      return {
        ...state,
        getCPPayout: {
          ...state.getCPPayout,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_PAYOUT_FAILURE:
      return {
        ...state,
        getCPPayout: {
          ...state.getCPPayout,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_USER_BY_ID_REQUEST:
      return {
        ...state,
        getCPUserDetailsById: {
          ...state.getCPUserDetailsById,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_USER_BY_ID_SUCCESS:
      return {
        ...state,
        getCPUserDetailsById: {
          ...state.getCPUserDetailsById,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_USER_BY_ID_FAILURE:
      return {
        ...state,
        getCPUserDetailsById: {
          ...state.getCPUserDetailsById,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CREATE_CP_DIGILOCKER_REQUEST:
      return {
        ...state,
        createCPDigiLocker: {
          ...state.createCPDigiLocker,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CREATE_CP_DIGILOCKER_SUCCESS:
      return {
        ...state,
        createCPDigiLocker: {
          ...state.createCPDigiLocker,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CREATE_CP_DIGILOCKER_FAILURE:
      return {
        ...state,
        createCPDigiLocker: {
          ...state.createCPDigiLocker,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_REQUEST:
      return {
        ...state,
        getdownloadCPAadhaarData: {
          ...state.getdownloadCPAadhaarData,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_SUCCESS:
      return {
        ...state,
        getdownloadCPAadhaarData: {
          ...state.getdownloadCPAadhaarData,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_FAILURE:
      return {
        ...state,
        getdownloadCPAadhaarData: {
          ...state.getdownloadCPAadhaarData,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.SAVE_CP_BANK_DETAILS_REQUEST:
      return {
        ...state,
        saveCPBankDetails: {
          ...state.saveCPBankDetails,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.SAVE_CP_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        saveCPBankDetails: {
          ...state.saveCPBankDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.SAVE_CP_BANK_DETAILS_FAILURE:
      return {
        ...state,
        saveCPBankDetails: {
          ...state.saveCPBankDetails,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GENERATE_CP_ORDER_REQUEST:
      return {
        ...state,
        generateCPOrder: {
          ...state.generateCPOrder,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GENERATE_CP_ORDER_SUCCESS:
      return {
        ...state,
        generateCPOrder: {
          ...state.generateCPOrder,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GENERATE_CP_ORDER_FAILURE:
      return {
        ...state,
        generateCPOrder: {
          ...state.generateCPOrder,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        getCPPaymentStatus: {
          ...state.getCPPaymentStatus,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        getCPPaymentStatus: {
          ...state.getCPPaymentStatus,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        getCPPaymentStatus: {
          ...state.getCPPaymentStatus,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.UPDATE_CP_ORDER_STATUS_REQUEST:
      return {
        ...state,
        updateCPOrderStatus: {
          ...state.updateCPOrderStatus,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.UPDATE_CP_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        updateCPOrderStatus: {
          ...state.updateCPOrderStatus,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.UPDATE_CP_ORDER_STATUS_FAILURE:
      return {
        ...state,
        updateCPOrderStatus: {
          ...state.updateCPOrderStatus,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CREATE_LOAN_REQUEST:
      return {
        ...state,
        createLoan: {
          ...state.createLoan,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CREATE_LOAN_SUCCESS:
      return {
        ...state,
        createLoan: {
          ...state.createLoan,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CREATE_LOAN_FAILURE:
      return {
        ...state,
        createLoan: {
          ...state.createLoan,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CP_LOAN_LIST_REQUEST:
      return {
        ...state,
        cpLoanList: {
          ...state.cpLoanList,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CP_LOAN_LIST_SUCCESS:
      return {
        ...state,
        cpLoanList: {
          ...state.cpLoanList,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CP_LOAN_LIST_FAILURE:
      return {
        ...state,
        cpLoanList: {
          ...state.cpLoanList,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CREATE_CP_ESIGN_REQUEST:
      return {
        ...state,
        createCPESignRequest: {
          ...state.createCPESignRequest,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CREATE_CP_ESIGN_SUCCESS:
      return {
        ...state,
        createCPESignRequest: {
          ...state.createCPESignRequest,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CREATE_CP_ESIGN_FAILURE:
      return {
        ...state,
        createCPESignRequest: {
          ...state.createCPESignRequest,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_INVOICE_REQUEST:
      return {
        ...state,
        getInvoice: {
          ...state.getInvoice,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_INVOICE_SUCCESS:
      return {
        ...state,
        getInvoice: {
          ...state.getInvoice,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_INVOICE_FAILURE:
      return {
        ...state,
        getInvoice: {
          ...state.getInvoice,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_REQUEST:
      return {
        ...state,
        acceptCPLoanEligibility: {
          ...state.acceptCPLoanEligibility,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        acceptCPLoanEligibility: {
          ...state.acceptCPLoanEligibility,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_FAILURE:
      return {
        ...state,
        acceptCPLoanEligibility: {
          ...state.acceptCPLoanEligibility,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_REQUEST:
      return {
        ...state,
        withdrawnCPLoanEligibility: {
          ...state.withdrawnCPLoanEligibility,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        withdrawnCPLoanEligibility: {
          ...state.withdrawnCPLoanEligibility,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_FAILURE:
      return {
        ...state,
        withdrawnCPLoanEligibility: {
          ...state.withdrawnCPLoanEligibility,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CLEANUP_ESIGN_DATA:
      return {
        createCPESignRequest: {
          data: null,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CLEANUP_TOAST:
      return {
        ...state,
        createCPDigiLocker: {
          data: null,
          loading: false,
          error: null,
        },
        saveCPBankDetails: {
          data: null,
          loading: false,
          error: null,
        },
        generateCPOrder: {
          data: null,
          loading: false,
          error: null,
        },
        updateCPOrderStatus: {
          data: null,
          loading: false,
          error: null,
        },
        createLoan: {
          data: null,
          loading: false,
          error: null,
        },
        withdrawnCPLoanEligibility: {
          data: null,
          loading: false,
          error: null,
        },
        acceptCPLoanEligibility: {
          data: null,
          loading: false,
          error: null,
        }
      }
    default:
      return state
  }
}

export default cpReducers
