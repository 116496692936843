import React from 'react'
import ReactPaginate from 'react-paginate';

export default function Pagination(props) {
  return (
    <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        pageCount={props.pageCount}
        onPageChange={props.handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
    />
  )
}
