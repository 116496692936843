import React from 'react'
import {Box, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,} from '@chakra-ui/react'
import CustomButton from './customButton'

export default function TermsConditionsDialog(props) {

    const PopupBox = () => (
        <Box className="popup_container">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="modal_title font-poppins">Terms & Conditions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {props.type === '3' ?
                        <Box className='modal_info_ploicy font-poppins'>
                            <Heading className='font-poppins' as="h1">Lender Registration Agreement</Heading>
                            <p>
                            These terms and conditions create a contract between you and Creditech (the "Agreement"). Please read the Agreement carefully. The Lender hereby agrees to the terms & conditions mentioned below:
                            <br/><br/>
                            A. Creditech is an NBFC- peer to peer (“<b>P2P</b>”) Platform which acts as an intermediary providing the services of loan facilitation (including but not limited to profile verification, risk assessment, physical verification, legal documentation, loan monitoring and collections) via online medium or otherwise, and has created a marketplace where borrowers’ requirements are listed and investors fund those loans and has been duly recognized by Reserve Bank of India (RBI) for undertaking the said business.
                            <br/>
                            <br/>
                            B. A Lender who creates an account with Creditech at [ <a href="https://www.alphamoney.in/"><b> www.alphamoney.in </b></a>] can find a suitable borrower through the services of Creditech on such terms and conditions as may be provided in this Agreement.
                            <br/>
                            <br/>
                            C. The Lender who intends to offer loan through Creditech
                            needs to create an account with Creditech by giving basic information including name, screen name, contact nos. date of birth, nationality, email, place of residence, chosen password, gender, marital status, employment and such other information sought for.<br />
                            Further, the Lender needs to provide additional personal details, viz, Father’s name, Gross income whether employed or self-employed, PAN and such other information sought for.<br />
                            <br/>
                            D. On successful activation of online registration, the Lender agrees to have executed this Agreement for availing Creditech’s services. NOW THEREFORE, in consideration of the mutual promises, covenants and conditions hereinafter set forth, the receipt and sufficiency of which is hereby acknowledged, the parties hereto agree as follows:
                            <br/><br />
                            <br/><span style={{fontSize: '15px'}}><b> 1. DEFINITION AND INTERPRETATION:</b></span><br/>
                            1.1 In this Agreement, unless the context otherwise requires:
                            <br/>
                            <b>“Act” </b>means the Reserve Bank of India Act, 1934.<br/>
                            <b>“Borrower” </b>means a borrower whose verification is completed by Creditech.<br/>
                            <b>“Lender” </b>means a lender whose verification is completed by Creditech.<br/>
                            <b>“Listing(s)”</b> is a loan request by a Borrower on the Creditech P2P Platform.<br/>
                            <b>“Non-bank financial company - Peer to Peer Lending Platform” </b>(“NBFC-P2P”) means a non-banking institution which carries on the business of a Peer to Peer Platform.<br/>
                            <b>“Privacy Policy” </b>refers to the privacy policy published on the P2P Platform/ website.<br/>
                            <b>“RBI Directions” </b>means the Non-Banking Financial Company – Peer to Peer Lending Platform (Reserve Bank) Directions,
                            2017.  <br/><b>“Risk Grade”</b> means the risk category assigned to the Eligible Borrower by Creditech’s in-house underwriting team.<br/>
                            <b>“Terms of Use”</b> means the terms and conditions published on the P2P Platform/ website.<br/>
                            1.2 References to Clauses, Recitals are to clauses and recitals, to this Agreement.<br/>
                            1.3 Any reference to a statutory provision shall include such provision as is from time to time modified or re-enacted or consolidated so far as such modification or re-enactment or consolidation applies or is capable of applying to any transactions entered into hereunder.<br/>
                            1.4 The plural includes the singular and references to any gender include references to all other genders.<br/>
                            1.5 Headings in this Agreement are for convenience of reference only and shall not be used to construe or interpret this Agreement. <br/>
                            1.6 Lists of examples
                            shall be non-exhaustive and words such as "including" and "in particular" shall not be construed as limiting a wider class of things. <br/>
                            <br/><span style={{fontSize: '15px'}}><b>2. COMMENCEMENT</b></span><br/>
                            This Agreement shall come into effect from the date of execution of this Agreement.<br/>
                            <br/><span style={{fontSize: '15px'}}><b>3. SCOPE OF SERVICE</b></span><br/>
                            3.1 The Parties agree that the Lender shall register with Creditech in accordance with the policy issued by Creditech as amended from time to time in accordance with the provisions of law and guidelines issued under the Act.<br />
                            3.2 The Parties agree that the Lender has associated with Creditech to avail its services for the purpose of earning returns by investing in high return loans availed by credit-worthy borrowers through peer-to-peer lending.<br/>
                            3.3 The Parties agree that it shall be upon the sole discretion of Creditech, whether or not, to register a Lender on its P2P Platform in accordance with its policy and registration / verification process, and Creditech’s decision in this respect shall be final and binding and the Lender shall not raise any contention / dispute in respect thereof.<br/>
                            3.4 All investments made under this Agreement shall be made by the Lender by transferring the committed funds into the Creditech – Lenders funding escrow account in accordance with the fund transfer mechanism contained in Clause 9 of the RBI Directions.<br/>
                            <br/><span style={{fontSize: '15px'}}><b>4. REGISTRATION</b></span><br/>
                            <b>4.1</b> Lender agrees to register with Creditech for providing loans to eligible listed Borrowers. Post online registration, Lenders shall be allowed to create listings (“<b>Listings</b>”) on Creditech P2P Platform and can also view the other listed lender/ borrower listings. Lender's listing will be displayed and can make offers or accept requests only on successful verification. The verification process has been more particularly described in Clause 5 of this Agreement. Those Lenders whose online registration is completed should submit the documents sought for verification by Creditech during the visit of Creditech personnel to the lender or upload online as the case may be and the same shall be completed within 3-5 working days from the date of registration on the P2P Platform.<br/>
                            <b>4.2</b> Lender hereby agrees to receive SMS transaction alerts posted by Creditech on any new registrations, loan closures, repayments, offers on loans, request from
                            borrowers etc. Registered Lender is availing the services on its own will and there will be no financial obligation of Creditech in case of legal disputes. The Lenders are obliged to notify Creditech, of any change in mobile phone number or any other contact information provided on the P2P Platform pursuant to this Agreement or such other agreements entered for the purpose of lending and borrowing. The registered members are allowed to opt out from receiving SMS transaction alerts by notifying Creditech in writing.<br/>
                            <b>4.3</b> Lender hereby allows Creditech to access, collect and display any public information available in the domain of Facebook /LinkedIn or any such social networking sites, on their P2P Platform. The registered Lenders can however restrict either access, collection or display of information by notifying the same to Creditech in writing.<br/>
                            <br/><span style={{fontSize: '15px'}}><b>5. VERIFICATION AND AUTHORIZATION TO OBTAIN CREDIT REPORT</b></span><br/>
                            5.1 On successful completion of execution of this Agreement, the Lenders are subject to verification. Verification process includes physical verification to check the correctness of data provided for online registration by the Lender with proofs. Lenders should provide the following list of documents at the time of physical verification by the Creditech team.<br/>
                            (a) Identify Proof: Aadhaar card, PAN card, passport, driving license, ration card or such other document which are issued by government agency. (b) Address Proofs: Aadhaar card, Passport, driving license, ration card or such other documents which are issued by any government agency. (c) Additional Proofs: Contact details and such other information / documents as required by Creditech. The exhaustive list can be seen on the website and also will be communicated through email.<br/>
                            5.2 Lender hereby authorises Creditech to obtain credit report from Credit Information Bureau (India) Limited or such other organisation which provide such facilities not only for the first time on registration but at any time during his association with Creditech. The credit report shall be used for any purpose that is authorized by applicable law.<br />
                            5.3 Creditech has the right to reject any Lender application if any information provided by Lender is inaccurate or incorrect.<br/> 
                            5.4 In case of any changes occur in the personal information of the Lender provided after posting a Listing, Lender must either <br />(a) Promptly notify Creditech of the change, or<br/>
                            (b) withdraw the Listing.<br/>
                            <br />In the event of not notifying, Creditech at its sole discretion may take necessary action including removing the Listing.<br/>
                            5.5 The Lender hereby agrees that documents sought by Creditech at the time of registration or anytime thereafter become part of the official record of Creditech
                            and will not be returned. However on closure of the user account, Creditech will ensure that the same is removed or destroyed. <br/>
                            5.6 The Lender shall provide all the details pertaining to the nominee such as the relationship with the nominee(s) / legal heir(s) with the Lender, their address etc. In the event of the death of the Lender, the ownership of his account will be transferred to such a nominee.<br />
                            <br /><span style={{fontSize: '15px'}}><b>6. LISTING</b></span><br />
                            6.1 Upon successful registration and verification of the Lender, the Parties understand and agree that the Lender shall be entitled to review various Listings, make offers against any of the Listings and receive requests from the Borrower to fund their Listings through the P2P Platform.<br/>
                            6.2 Any offer made by the Lender against a particular Listing shall remain valid till:<br/>
                            (a) The entire Listing by the Eligible Borrower gets funded or;<br/>
                            (b) The Borrower withdraws the Listing before the expiry of 3 days from the date on which the said Listing was made (“Listing Period”) or a period of few additional days from the expiry of the Listing Period (“Additional Listing Period”) at the discretion of the caompany, whichever is earlier, or;<br /> (c) The Lender’s offer, against a particular Listing, is removed by Creditech, in accordance with Creditech’s in-built intelligence system, that matches the most favourable offer against that particular Listing, or;<br />
                            (d) In case the Listing of the Borrower is withdrawn / cancelled / suspended / terminated by Creditech.<br />
                            6.3 The offer once committed by the Lender to any particular Listing can be modified by writing an email to Creditech.<br/>
                            6.4 The Lender hereby agrees that if he
                            fails to abide by the commitment towards disbursement of loan, he shall be delisted from the P2P Platform and shall not be allowed to carry out any transaction through Creditech in the future.
                            <br/>
                            <br/>
                            <span style={{fontSize: '15px'}}><b>7. VIRTUAL WALLET</b></span> <br />
                            The Lender may opt for a virtual wallet where he may park his money for the purpose of investing his funds. Money may be added in the virtual wallet by depositing funds in the Creditech – Lenders funding escrow account. The virtual wallet may also be used;<br />
                            (a) to park the amount repaid by the borrower for it to be re-invested in any other Listings on the P2P Platform, or;<br />
                            (b) to transfer the repayment amount in his own bank account from the virtual wallet.<br />
                            <br />
                            <span style={{fontSize: '15px'}}><b>8. TRANSACTION DETAILS </b></span><br />
                            8.1 On depositing money in the virtual wallet, the Lender may commit his funds to several Listings and start the lending process.<br/> 
                            8.2 The Lender shall invest a minimum of INR5000.00 – per Listing and may commit funds to single or multiple Listing(s) to diversify risk. The Lender shall always be subjected to the following prudential norms as per RBI Directions;<br />
                            (a) The aggregate exposure of a lender to all borrowers at any point of time, across all P2P platforms, shall be subject to a cap of INR 50,00,000/- provided that such investments of the lenders on P2P platforms are consistent with their net-worth. The lender investing more than INR 10,00,000 across P2P platforms shall produce a certificate to P2P platforms from a practicing chartered accountant certifying minimum net-worth of INR 50,00,000.<br />
                            (b) The exposure of a single lender to the same borrower, across all P2Ps, shall not exceed INR 50,000/-.<br />
                            (c) The maturity of the loans shall not exceed 36 months.<br />
                            8.3 In order to invest in a Listing, the Lender may browse through all the Listings on the market place and view their interest rates, terms, Risk Grades and the purpose of the Listing. The Lender may compare all Listings and select those that fit his requirements. The Lender shall enter the amount of investment against a Listing and upon the Listing being fully funded, it shall be frozen and the amount committed by the Lender shall be reserved for that Listing.<br />
                        
                            8.4 Immediately on freezing the Listing, Creditech shall liaison between the Lender and the Borrower to execute a loan agreement (“Loan Agreement”) and other necessary documents that are required to enable the transaction. After completing the legal formalities / documentation between the Lender and the Borrower, the Lender shall transfer funds through any of the following modes from his bank account via NEFT, RTGS or any other mode of payment as may be specified to the Creditech – Lenders funding escrow account account. The Lender may pre-fund the Creditech – Lenders funding escrow account. Funds in the said Creditech – Lenders funding escrow account are non-interest bearing. The loan proceeds shall be disbursed by Creditech within a period of 5-15 working days into the Borrower’s bank account.<br />
                            8.5 The income earned by the Lender shall be subject to tax as per the laws prevalent in India, for the time being in force. The Lender agrees to abide by and comply with any requirements specified under the said laws and acknowledges that Creditech shall provide any information sought by the authorities pertaining to any transaction undertaken on the P2P Platform upon any such requisition received by Creditech from such authorities.<br />
                            8.6 Without prejudice to the terms of this Agreement, the Loan Agreement shall provide further details regarding the terms and conditions on which such Loan shall be disbursed and the repayment thereof by the Borrower.<br />
                            <br />
                            <br />
                            <span style={{fontSize: '15px'}}><b>9. NO PRINCIPAL PROTECTION</b></span><br />
                            The Lender agrees, acknowledges and understands the risks associated with the transactions to be undertaken through Creditech and that there is no guarantee of return and that there exists a likelihood of loss of entire principal in case of default by a Borrower.
                            <br />
                            <br />
                            <span style={{fontSize: '15px'}}><b>10. FEE AND CHARGES</b></span> <br />
                            Creditech shall charge a loan servicing fee, documentation fee, account swap fee and other charges, as the case may be, as specified on the P2P Platform or Loan Agreement. The same may be revised from time to time and the latest fee schedule shall be available on the P2P Platform. <br/>
                            <br /><span style={{fontSize: '15px'}}><b>11. COLLECTION AND REPORTING OF DELINQUENT LOANS</b></span><br />
                            Creditech would put its best efforts as per law to recover and / or collect due amount from the Borrower. In case a Borrower misses an instalment or repayment date, Creditech will contact the Borrower over phone and follow-up on the repayment. Late fees shall be charged accordingly. In case, the borrower is not reachable over phone, he shall be contacted through e-mail and/or letter. If the Borrower still does not pay within 60 days, post loan default, legal notice will be sent to the Borrower. After a period of 60 days, Creditech or the Lender either directly or indirectly will exercise all the remedies legally available under the laws applicable from time to time. In addition, the loan may be referred to collection agencies to recover all the dues. At this stage, the Borrower shall be classified as a defaulter. In case any legal proceedings are initiated by Creditech on behalf of the Lender at its own discretion, all costs incurred directly or indirectly for the purpose of recovery of the dues shall be borne by the Lender.<br />
                            <br /><span style={{fontSize: '15px'}}><b>12. OBLIGATIONS </b></span><br />
                            12.1 Company Obligations:<br />
                            (a) After freezing the Listing either in the Listing Period or additional Listing Period, Creditech shall liaison between the eligible Lenders and eligible Borrowers to execute the Loan Agreement and other required legal documents which will bind both the Parties till the clearance of all the payments.<br />
                            (b) Creditech shall disclose to the Lender, details about the Borrower(s) including personal identity, required amount, interest rate sought, credit score and details about all the terms and conditions of the loan, including likely return, fees and taxes.<br />
                            (c) Creditech agrees and acknowledges to keep the credit information (relating to Borrower transactions on the platform) maintained by it, updated regularly on a monthly basis or at such shorter intervals as may be mutually agreed upon between Creditech and the credit information companies and take all such steps which may be necessary to ensure that the credit information furnished by it is up to date, accurate and complete.<br />
                            (d) Creditech shall ensure that any information relating to the Lender, received by it, is not disclosed to any third party, without the consent of the Lender.<br />
                            (e) Creditech shall immediately notify RBI in the event of any breach of security and leakage of confidential customer related information.<br />
                            12.2 Lender Obligations:<br />
                            (a) To provide accurate and true information that is sought by Creditech for validating documents for the purpose of registration, verification, disbursal, collection, repayment, recoveries, and all other acts specified under this Agreement.<br />
                            (b) To provide certified true copies of the documents sought for in respect of the aforesaid purposes.<br />
                            (c) To comply with the Terms of Use for the website, Privacy Policy and as well any other rules or policies on the website or any terms and conditions
                            set forth under this Agreement or any other agreement, any of which may be amended from time to time by Creditech at its sole discretion.<br />
                            (d) To fund
                            the committed amount to the Borrower once the Listing is frozen without any failure.<br />
                            (e) To cooperate with Creditech with the information / documents sought from time-to-time for the purposes mentioned in the Agreement.<br />
                            (f) To provide a certificate to Creditech certifying that his aggregate exposure to all borrowers at any point of time, across all NBFC-P2Ps does not exceed INR 10,00,000/- or such other amount as may be prescribed from time to time under the RBI Directions or such other law, rules, regulations or guidelines that may be issued in this regard, from time to time.<br />
                            (g) To provide a certificate to Creditech certifying that his exposure towards the same Borrower at any point of time, across all NBFC-P2Ps does not exceed INR 50,000/- or such other amount as may be prescribed from time to time under the RBI Directions or such other law, rules, regulations or guidelines that may be issued in this regard, from time to time.<br />
                            <br /><span style={{fontSize: '15px'}}><b>13. REPRESENTATIONS AND WARRANTIES</b></span><br />
                            13.1 Creditech and the Lender represent and warrant that they have not withheld or misrepresented any material information which could have an adverse effect on any act, transaction or any other aspect contemplated under this Agreement. Each Party to the Agreement makes the following representations and warranties with respect to itself, and confirms that they are, true, correct and valid:<br />
                            (a) It has full power and authority to enter into, deliver and perform the terms and provisions of this Agreement and, in particular, to exercise its rights, perform the obligations expressed to be assumed by and make the representations and warranties made by it hereunder;<br /> (b) Its obligations under this Agreement are
                        
                            legal and valid, binding on it and enforceable against it in accordance with the terms hereof. <br />
                            13.2 Lender represents and warrants that:<br /> (a) all information provided by him is accurate and true to the best of his knowledge.<br />
                            (b) Lender holds a bank account in his own name and will use this account for the purpose of this Agreement and other documents related thereto.<br />
                            13.3 Creditech represents and warrants that: <br />
                            (a) It shall act as an intermediary providing an online marketplace or platform to the participants involved in peer to peer lending and shall not raise deposits as defined by or under Section 45I(bb) of the Act or the Companies Act, 2013;<br />
                            (b) Not lend on its own;<br />
                            (c) Not provide or arrange any credit enhancement or credit guarantee;<br />
                            (d) Not facilitate or permit any secured lending linked to its platform; i.e. only clean loans will be permitted;<br />
                            (e) Not hold, on its own balance sheet, funds received from lenders for lending, or funds received from borrowers for servicing loans etc.;<br /> (f) Not hold, on its own balance sheet, funds received from lenders for lending, or funds received from borrowers for servicing loans etc.; (g) Not cross sell any product except for loan specific insurance products;<br />
                            (h) Not permit international flow of funds;<br />
                            (i) Ensure adherence to legal requirements applicable to the participants as prescribed under relevant laws; and<br />
                            (j) Store and process all data relating to its activities and participants on hardware located within India.<br />
                            <br/><span style={{fontSize: '15px'}}><b>14. RISK ACKNOWLEDGMENT</b></span><br />
                            14.1 In addition to other terms and conditions laid down in this Agreement, the Lender acknowledges and confirms that he has carefully considered the risks involved in lending money through Creditech and that the Lender’s participation in any loan is entirely at the Lender’s own risk.<br /> 14.2 The Lender hereby understands that he risks losing any funds which have been loaned, in circumstances where a borrower in whose Listing he has participated, defaults on its repayments, and Creditech shall have no liability to the Lender for any such loss.<br />
                            14.3 The Lender acknowledges that by engaging in peer-to-peer lending, he at no time shall be investing money in Creditech.<br />
                            <br/><span style={{fontSize: '15px'}}><b>15. NO GUARANTEE</b></span><br />
                            Creditech does not guarantee the following:<br />
                            (a) that the Lender’s money shall be invested in a Listing as a result of him making an offer to an Borrower;<br />
                            (b) that Lender will receive any rate of return, or any minimum amount of principal or interest on any principal or interest at all; and (c) full or partial collection of repayment of loan from the Borrowers.<br />
                            <br/><span style={{fontSize: '15px'}}><b>16. TERMINATION</b></span><br />
                            16.1 This Agreement may be terminated:<br />
                            (a) By mutual written agreement of the Parties;<br />
                            (b) If any laws or regulations or any amendments thereto makes the transaction or the Agreement illegal, or is otherwise prohibited by law or by an
                            order, decree or judgement of any court or governmental body having competent jurisdiction;<br /> 16.2 Termination by Creditech:<br />
                            (a) Creditech may, at its sole discretion, with or without cause, terminate this Agreement and registration with it, at any time, by giving notice to the Lender.<br />
                            (b) In case of non-compliance of the Lender’s obligations mentioned under Clause 12.2 of this Agreement, involvement of the Lender in any prohibited or illegal purpose or the Lender’s failure to abide by the terms and conditions of this Agreement.<br />
                            (c) Creditech may, by giving notice to the Lender, terminate this Agreement if there has been a material misrepresentation or breach of a representation and warranty or breach of covenant on the part of the Lender.<br />
                            (d) Creditech may, by giving notice to the Lender, terminate this Agreement if any event or circumstance occurs which, in the opinion of Creditech, could have a material adverse effect on the fulfilment of the obligations of the Lender under this Agreement.<br />
                            (e) In the event of any failure to fund the loan after commitment, the Lender shall not be eligible to browse any further Listings and his access to the P2P Platform shall be restricted.<br />
                            16.3 Termination by Lender:<br />
                            A Lender is entitled to terminate this Agreement, at any time, after giving a notice to Creditech, provided that; <br />(a) There is no Loan Agreement in force;<br />
                            (b) No outstanding amount in Lender’s Account;<br />
                            (c) No funds are allocated for lending to any Borrower.<br />
                            If the Lender wishes to terminate this Agreement / close his account with Creditech, and the Lender is party to a subsisting Loan Agreement, or the Lender has funds that are allocated towards any Listing(s), the Lender shall delay the termination of this Agreement until the loans are repaid or the committed funds are discharged.<br />
                            <br/><span style={{fontSize: '15px'}}><b>17. RIGHT TO MODIFY TERMS</b></span><br />
                            17.1 For the reasons specified in Clause 17.2, Creditech reserves the right to modify the terms of this Agreement and / or its Terms of Use and / or its Privacy Policy and / or any other agreement involving the Lender. Such changes shall not apply retrospectively.<br />
                            17.2 Creditech will be at liberty to modify the terms, from time to time, without consent of the Lender, due to any of the following reasons:<br /> (a) To make the terms clearer;<br />
                            (b) To make changes to its products and services, to introduce new products or services or to withdraw products or services that Creditech no longer offers;<br />
                        
                            (c) To change its existing services owing to the changes and innovation in technology, systems, processes, business procedures and / or policies;<br /> (d) To adapt to changes in law, regulation, industry guidance or a decision of a competent court or any regulation formed by the RBI from time to time. <br />17.3 Modifications stated in this Clause shall be communicated to the Lender and also made available on the P2P Platform.<br/>
                            <br/><span style={{fontSize: '15px'}}><b>18. INDEMNIFICATION</b></span><br />
                            Each Party shall, at all times, at present and thereafter, defend, indemnify and hold harmless the other Party, its employees, officers, directors, agents, representatives from and against any and all liabilities, suits, claims, actions, proceedings, losses, damages, judgments and costs, of any nature whatsoever (including Attorney’s fees) caused due to non-compliance of the terms and conditions of this Agreement, breach of representation and warranties, beach of covenants, or non-compliance of any applicable laws and regulations, breach of the confidentiality obligations and infringement of third-party intellectual property rights, damage to any third party’s property or body. This clause shall survive the expiry or termination of this Agreement.<br />
                            <br/><span style={{fontSize: '15px'}}><b>19. FORCE MAJEURE</b></span><br />
                            19.1 No Party shall be liable to the other if, and to the extent, that the performance or delay in performance of any of its obligations under this Agreement is prevented, restricted, delayed or interfered with, due to circumstances beyond the reasonable control of such Party, including but not limited to, government legislations, fires, floods, explosions, epidemics, accidents, acts of God, wars, riots, strikes, lockouts, or other concerted acts of workmen, acts of Government and / or shortages of materials, hacking, unanticipated technological or natural interference or intrusion, loss or damage to satellites, loss of satellite linkage or any other data communications linkage, loss of connectivity or any other irresistible force or compulsion.<br />
                            19.2 The Party claiming an event of force majeure shall promptly notify the other Party in writing and provide full particulars of the cause or event and the date of first occurrence thereof, as soon as possible after the event and also keep the other Party informed of any further developments. The Party so affected shall use its best efforts to remove the cause of non-performance, and shall resume performance hereunder with the utmost responsibility when such cause is removed.<br />
                            <br/><span style={{fontSize: '15px'}}><b>20. GOVERNING LAW AND JURISDICTION</b></span><br />
                            This Agreement shall be governed by and construed in accordance with the laws of India and, courts of [Delhi], India shall have exclusive jurisdiction over all matters arising pursuant to this Agreement.<br />
                            <br/><span style={{fontSize: '15px'}}><b>21. NOTICES</b></span><br />
                            All notices under this Agreement shall be written in English and shall be sent by hand or by courier or by facsimile or by electronic mail to the applicable Party at the address / contact details indicated as in the description of Parties or to such other address as any Party shall designate by written notice to the other Party and shall be deemed to be given only when delivered by personal delivery or by courier or by electronic mail at the above mentioned address.<br />
                            <br/><span style={{fontSize: '15px'}}><b>22. AMENDMENT AND ASSIGNMENT</b></span><br />
                            Any amendments to this Agreement shall be legally valid and binding on each of the Parties, if made in writing and signed by each of the Parties. No Party shall be entitled to transfer or assign any rights or obligations under this Agreement to third parties without informing the other Party and obtaining the prior written approval of such other Party.<br />
                            <br/><span style={{fontSize: '15px'}}><b>23. WAIVER</b></span><br />
                            The failure of any Party to insist, in one or more instances, upon strict performance of the obligations of this Agreement, or to exercise any rights contained herein, shall not be construed as waiver, or relinquishment for the future, of such obligation or right, which shall remain and continue in full force and effect. No waiver of any breach of any provision of this Agreement shall be effective or binding unless made in writing and signed by the Party purporting to give the same and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived.<br />
                            <br/><span style={{fontSize: '15px'}}><b>24. SEVERABILITY</b></span><br />
                            A provision contained in this Agreement is enforceable independently of each of the others and its validity will not in any way be affected by the invalidity or unenforceability of any other provision hereof. If any provision of this Agreement is held to be illegal, invalid or unenforceable under any applicable law from time to time: <br />(a) such provision will be fully severable from this Agreement; <br />(b) this Agreement will be construed and enforced as if such illegal, invalid or unenforceable provision had never comprised a part hereof; and <br />(c) the remaining provisions of this Agreement will remain in full force and effect and will not be affected by the illegal, invalid or unenforceable provision or by its severance here from.<br />
                            <br/><span style={{fontSize: '15px'}}><b>25. ENTIRE AGREEMENT</b></span><br />
                            This Agreement contains the whole agreement and understanding between the Parties with regard to the matters dealt with in this Agreement and unless provided otherwise supersedes any prior agreement, understanding, arrangement or promises, whether written or oral or whether subsisting or expired, relating to the subject matter of this Agreement The Parties expressly acknowledge that, in relation to the subject matter of this Agreement, each of them assumes no obligations of any kind whatsoever other than as expressly set forth in this Agreement.<br />
                            <br/><span style={{fontSize: '15px'}}><b>26. COUNTERPARTS</b></span><br />
                            This Agreement may be executed simultaneously in more than one counterpart each of which shall be deemed to be an original but all of which shall constitute the same agreement.<br />
                        </p>
                        </Box> :
                        <Box className='modal_info_ploicy font-poppins'>
                            <Heading className='font-poppins' as="h1">Borrower Registration Agreement</Heading>
                            <p>
                                These terms and conditions create a contract between you and Creditech (the "Agreement"). Please read the Agreement carefully. The borrower hereby agrees
                                to the terms and conditions mentioned below:
                                <br/>
                                A. Creditech is an NBFC- peer to peer(“P2P”) Platform which acts as an intermediary providing the services of loan facilitation (including but not limited to profile verification, risk assessment,
                                physical verification, legal documentation, loan monitoring and collections) via online medium or otherwise, and has
                                created a marketplace where borrowers, requirements are listed and investors fund those loans and has been duly recognized by Reserve Bank of India
                                (RBI) for undertaking the said business.
                                
                                <br/>
                                B.{' '} A Borrower who creates an account with Creditech at <span className='contentofheading'><a href="https://www.alphamoney.in">[www.alphamoney.in]</a></span> can find a suitable lender through the services of Creditech on such terms and
                                <span className='bregterm'>
                                conditions as may be provided in this Agreement.
                                </span>
                                <br/>
                                C.{' '} The Borrower who intends to avail loan through Creditech
                                <br/>
                                <span className='bregterm'>
                                needs to create an account with Creditech by giving basic information including name, screen name, contact nos. date of birth, nationality, email, place of 
                                </span>
                                <br/>
                                <span className='bregterm'>
                                residence, chosen password, gender, marital status, employment and such other information sought for. 
                                </span>
                                <br/>
                                <span className='bregterm'>
                                Further, the Borrower needs to provide additional personal details, viz, Father’s name, Gross income whether employed or self-employed, PAN and such
                                </span>
                                <br/>
                                <span className='bregterm'>
                                other information sought for.
                                </span>
                                <br/>
                                D. On successful activation of online registration, the Borrower agrees to have executed this Agreement for availing Creditech’s services.
                                <br/>
                                <span className='contentofheading'> 1. DEFINITION AND INTERPRETATION:</span> 
                                <br/>
                                1.1 In this Agreement, unless the context otherwise requires:
                                <br/>
                                
                                <p className=' bregterm1'>
                                <span className='contentofheading'>"Act"</span>means the Reserve Bank of India Act, 1934.
                                <br/>
                                <span className='contentofheading'>“Borrower”</span> means a borrower whose verification is completed by Creditech.
                                <br/>
                                <span className='contentofheading'>“Lender</span> ” means a lender whose verification is completed by Creditech.
                                <br/>
                                <span className='contentofheading'>“Listing(s)”</span> is a loan request by a Borrower on the Creditech P2P Platform.
                                <br/>
                                <span className='contentofheading'>“Non-bank financial company - Peer to Peer Lending Platform” </span> (“NBFC-P2P”) means a non-banking institution which carries on the business of a Peer to Peer Platform.
                                <br/>
                                <span className='contentofheading'>“Privacy Policy”</span> refers to the privacy policy published on the P2P Platform/ website.
                                <br/>
                                <span className='contentofheading'>“RBI Directions”</span> means the Non-Banking Financial Company – Peer to Peer Lending Platform (Reserve Bank) Directions, 2017.
                                <br/>
                                <span className='contentofheading'>“Risk Grade”</span> means the risk category assigned to the Borrower by Creditech’s in-house underwriting team.
                                <br/>
                                <span className='contentofheading'>“Terms of Use”</span> means the terms and conditions published on the P2P Platform/ website.
                                <br/>
                                </p>
                                1.2 References to Clauses, Recitals are to clauses and recitals, to this Agreement.
                                <br/>
                                1.3 Any reference to a statutory provision shall include such provision as is from time to time modified or re-enacted or consolidated so far as such
                                <br/>
                                modification or re-enactment or consolidation applies or is capable of applying to any transactions entered into hereunder.
                                <br/> 1.4 The plural includes the singular
                                <br/> and references to any gender include references to all other genders.
                                <br/>
                                1.5 Headings in this Agreement are for convenience of reference only and shall not be used to construe or interpret this Agreement.
                                <br/>
                                1.6 Lists of examples shall be non-exhaustive and words such as “including” and “in particular” shall not be construed as limiting a wider class of things.
                                <br/>
                                <span className='contentofheading'>2. COMMENCEMENT</span> 
                                <br/>
                                This Agreement shall come into effect from the date of execution of this Agreement.
                                <br/>
                                <span className='contentofheading'></span>
                                3. SCOPE OF SERVICE
                                <br/>
                                3.1 The Parties agree that the Borrower shall register with Creditech in accordance with the policy issued by Creditech as amended from time to time in accordance with the provisions of law and guidelines issued under the Act.
                                <br/>
                                3.2 The Parties agree that the Borrower has associated with Creditech to avail its services for the purpose of earning returns by investing in high return loans availed by credit-worthy borrowers through peer-to-peer lending.
                                <br/>
                                3.3 The Parties agree that it shall be upon the sole discretion of Creditech, whether or not, to register a Borrower on its P2P Platform in accordance with its policy and registration / verification process, and Creditech’s decision in this respect shall be final and binding and the Borrower shall not raise any contention / dispute in respect thereof.
                                <br/>
                                3.4 All repayments made under this Agreement shall be made by the Borrower by transferring the funds into the Creditech – Borrower's escrow account in accordance with the fund transfer mechanism contained in Clause 9 of the RBI Directions.
                                <br/>
                                <span className='contentofheading'>4. REGISTRATION</span> 
                                <br/>
                                4.1 Borrower agrees to register with Creditech for availing loan to eligible listed Lenders. Upon successful registration, the Borrower can apply for a personal or business loan by stating the amount required and the tenure of the loan. The Borrower shall also be required to provide necessary personal and financial information and upload the required documents. Those Borrowers whose online registration is completed should submit the documents sought for verification by Creditech during the visit of Creditech personnel to the borrower or upload online as the case may be and the same shall be completed within 3-5 working days from the date of registration on the P2P Platform.
                                <br/>
                                4.2 Borrower hereby agrees to receive SMS transaction alerts posted by Creditech on any new registrations, loan closures, repayments, offers on loans etc. Registered Borrower is availing the services on its own will and there will be no financial obligation of Creditech in case of legal disputes. The Borrowers are obliged to notify Creditech, of any change in mobile phone number or any other contact information provided on the P2P Platform pursuant to this Agreement or such other agreements entered for the purpose of lending and borrowing. The registered members are allowed to opt out from receiving SMS transaction alerts by notifying Creditech in writing.
                                <br/>
                                4.3 Upon approval of the loan application, the Borrower shall be permitted to list his loan requirement on the P2P Platform and the same shall continue to be listed during the Listing Period.
                                <br/>
                                4.4 Borrower hereby allows Creditech to access, collect and display any public information available in the domain of Facebook /LinkedIn or any such social networking sites, on their P2P Platform. The registered Borrower can however restrict either access, collection or display of information by notifying the same to Creditech in writing.
                                <br/>
                                <span className='contentofheading'>5. VERIFICATION AND AUTHORISATION TO OBTAIN CREDIT REPORT</span> 
                                <br/>
                                5.1 On successful registration and execution of this Agreement, the Borrowers are subject to verification. Verification process includes physical verification to check on the correctness of data provided for online registration by the borrower with the proofs. Borrower should provide the following list of documents at the time of verification by the Creditech team.
                                <br/>
                                (a) Identify Proof: Aadhaar card, PAN card, passport, driving license, ration card or such other document which are issued by any government agency. (b) Address Proofs: Aadhaar card, Passport, driving license, ration card or such other documents which are issued by any government agency. (c) Additional Proofs: Employment contract, last 3 (three) months’salary slips, details of existing loans whether secured or unsecured, six months’
                                <br/>
                                bank statements and such other information / documents as required by Creditech. The exhaustive list can be seen on the website and also will be communicated through email.
                                <br/>
                                5.2 Borrower hereby authorises Creditech to obtain credit report from Credit Information Bureau (India) Limited or such other organisation which provides such facilities not only for the first time on registration but at any time during his/her association with Creditech. The credit report shall be used for any purpose that is authorized by applicable law in connection with extension of credit transaction involving Borrower, including but not limited to : (a) To know the financial strength;
                                <br/>
                                (b) To understand the credit worthiness;
                                <br/>
                                (c) To make an informed lending decisions in an objective manner;
                                <br/>
                                (d) To evaluate the loan amount eligibility;
                                <br/>
                                (e) To enable quick loan approvals;
                                <br/>
                                (f) To regularly monitor the credit history; and
                                <br/>
                                (g) To assign ratings.
                                <br/>
                                5.3 Creditech has the right to reject any Borrower application if any information provided by Borrower is inaccurate or incorrect. 5.4 Information on the above credit report will be displayed on Creditech’s P2P Platform along with Listing which will be accessible by the Lender. 5.5 In case of any changes in the personal information of the Borrower provided after posting a listing, Borrower must either (a) Promptly notify Creditech of the change, or (b) withdraw the Listing.
                                <br/>
                                In the event of not notifying, Creditech at its sole discretion may take necessary action including removing the Listing.
                                <br/>
                                5.6 The Borrower hereby agrees that documents sought by Creditech at the time of registration or anytime thereafter become part of the official record of Creditech and will not be returned. However on closure of the user account, Creditech will ensure that the same is removed or destroyed.
                                <br/>
                                
                                <span className='contentofheading'>6.LISTINGS 6.1 Upon successful verification of the Borrower and once the loan application has been approved, subject to the agreement of the Borrower of the terms</span> 
                                <br/>
                                contained herein, the Listing shall be displayed on the P2P Platform and accordingly, he may receive offers or make requests for the funding of his
                                loan.
                                <br/>
                                6.2 The Listing shall comprise of the following credentials to be filled by the Borrower: (a) Purpose of borrowing;
                                <br/>
                                (b) Amount required;
                                <br/>
                                (c) Tenure of loan;
                                <br/>
                                (d) Affordable Monthly EMI; and
                                <br/>
                                (e) Any other information such as but not limited to personal details, education and employment details, financial details, etc. 6.3 The above information shall be made public to all visitors on the P2P Platform. Once Creditech completes verification of the details and documents, the Listing shall be active. Then interested Lenders may commit to fund all or a portion of the loan with specific requirements covering interest, term of repayment etc. Alternatively, the Borrower may make a request to the Lenders to avail/request a loan. The Listing will be frozen once the Listing receives commitments equal to or exceeding the minimum amount required for the loan, unless the same is withdrawn prior to expiration of the Listing Period or additional Listing Period as the case may be. The Borrower shall not accept or reject individual offers for partial loan amounts. The Borrower may accept or reject offers only for the entire loan amount. 6.4 The loan application or the Listing, may be rejected on account of several factors like the Borrower’s ability to pay back, financial track records, CIBIL
                                <br/>
                                score, employment status, the ratio of your requested loan amount to the Borrower’s net income and expenses. Further, the loan application or Listing may be rejected in case of any previous failure to meet financial commitments, inconsistencies between the information provided and that recovered from background checks and credit history issues including prior defaults or insolvencies. Based on the aforesaid parameters, it shall be at the sole discretion of Creditech, whether or not to approve a loan application or Listing, and Creditech's decision in this respect shall be final and binding and the Borrower shall not raise any contention / dispute in respect thereof.
                                <br/>
                                6.5 Listing shall be open for a period of 3 days (“Listing Period”). However, the Borrower can opt to close or withdraw the loan transaction before the expiry of the Listing Period. When sufficient commitments are not received for a Listing during the period mentioned above, Listing may be extended for a few additional days only for one time (“Additional Listing Period”) at the discretion of the company.
                                <br/>
                                6.6 Listing once posted (like loan amount, purpose, why lend to me, other information etc.) cannot be modified; only certain sections such as photos/ videos may be modified. However, the same may be withdrawn subject to meeting the conditions as set forth in Clause 6.8 and new Listing may be posted subject to Clause 6.10.
                                <br/>
                                6.7 Any person who visits the P2P Platform shall be able to view any Listing which shows the loan amount, interest rate willing to pay, loan tenure, purpose of borrowing, and other details such as city of residence, education qualification, marital status, assets owned etc., and other information provided to Creditech. However, credit reports from third party agencies will only be viewable by the Lenders.
                                <br/>
                                6.8 The Borrower may withdraw his Listing after 3 days prior to the expiration of the Listing Period or Additional Listing Period, as the case may be or prior to the disbursement of loan. After the expiration of Listing Period or Additional Listing Period, as the case may be, the Borrower shall not have any
                                
                                <br/>
                                right to withdraw his Listing, and if a loan is made to the Borrower, he shall not have any right to rescind the loan. In the event that the Borrower
                                <br/>
                                does not accept the loan, Creditech may block his application for all future loans through the P2P Platform. 6.9 No Borrower shall make additional Listing during the subsistence of an already existing Listing.
                                <br/>
                                6.10 If a Borrower withdraws his Listing during the Listing Period or Additional Listing Period as the case may be, he may post a new Listing: (a) Within 90 (ninety) continuous days containing the updated information: In such case, the Borrower need not go through the verification process. However, any change in address, employment, contact details etc. will require the Borrower to go through the verification process again. (b) After 90 (ninety) continuous days containing the updated information: In such case, the Borrower shall be subjected to the verification process
                                <br/>
                                again. Creditech reserves the right, in its sole discretion, to limit the number of re-listing of loans a Borrower can post or attempt to post on its P2P Platform.
                                <br/>
                                In the event of expiry of the Listing Period or Additional Listing Period of the Listing, and if such Listing has not been funded sufficiently, the Borrower may post a new Listing.
                                <br/>
                                6.11 The Borrower may post a Listing for a minimum amount of Rs. 10,000 (Rupees ten thousand) and a maximum amount of Rs. 1,00,000 (Rupees one lakh). The loan amount shall be in multiples of Rs. 10,000 and the maximum loan amount for any Borrower shall be determined by Creditech. In limited instances, the maximum loan amount may differ due to constraints based on other variables. Creditech may modify the aforesaid limits from time to time at its discretion. The Borrower acknowledges that he shall not avail more than one loan through Creditech.
                                <br/>
                                <span className='contentofheading'>7. MATCHING OF LENDERS, COMMITMENTS, LOAN FUNDING AND LOAN TENURE</span> 
                                <br/>
                                7.1 On posting a Listing, the Borrower may receive offers from one or many Lenders.
                                <br/>
                                7.2 Creditech will publish the maximum loan amount eligibility for each Borrower depending on certain parameters including but not limited to his income
                                <br/>
                                and existing debt.
                                <br/>
                                7.3 Creditech shall begin the disbursal process on the occurrence of the below mentioned incidents, whereby, Creditech will liaison between the Lender and the Borrower to execute the requisite documents required to enable the transaction. On completion of the requisite legal formalities / documentation between the Borrower and the Lender including but not limited to signed Electronic Clearing System (“ECS”) form, post-dated cheques, National Automated Clearing House Mandate, executed Loan Agreements, etc., loan proceeds are disbursed within a period 3-5 working days from the lender’s escrow account to the Borrower’s designated bank account:
                                <br/>
                                7.3.1 The Listing receives commitments upto a maximum of 100% before expiry of the Listing period and Additional Listing Period. 7.3.2 The Listing receives commitments equal to or exceeding the minimum amount required for the disbursement (75% of the listed amount) before expiry
                                of the Listing period and Additional Listing Period.
                                <br/>
                                7.3.3 Prior Acceptance of the borrower at the time of loan application to disburse the loan if the listing receives commitments less than the minimum amount required for the disbursement (75% of the listed amount) and more than minimum amount required for the cancellation of listing (50% of the listed amount) at the expiry of Listing Period or Additional Listing Period.
                                <br/>
                                7.4 Creditech accepts the best offers available against the Listing based on the lowest interest rate quoted by the Lenders based on Creditech’s built-in system intelligence.
                                <br/>
                                7.5 If the Borrower fails to abide by his commitment towards acceptance of the loan, his name will be delisted and he shall not be allowed to carry out any transaction on the P2P Platform in future.
                                <br/>
                                7.6 A Listing may expire if the listing receives commitments equal to or less than the minimum amount required for the cancellation of listing (50% of the listed amount) at the expiry of Listing Period or Additional Listing Period.
                                <br/>
                                7.7 Creditech may delay depositing the funds of a loan if it foresees and/or discovers any discrepancy in the accuracy of the information provided by the Borrower or Lenders.
                                <br/>
                                7.8 Creditech reserves the right to verify the accuracy of all information provided by Borrowers, in connection with the Listing and loans through any of the available modes including by making a tele-call before, during or after the posting of a Listing, or before or after the funding of a loan. In case of any suspected fraud or inaccuracies in the information provided to it, Creditech reserves its right to cancel the funding at any point of time before disbursal. In the event a new Listing is found under the Borrower’s account on the P2P Platform prior to the funding of an existing Listing, Creditech may cancel the funding.
                                <br/>
                                7.9 Borrowers can borrow loans for a maximum of 36 months. However, Creditech reserves the right to amend the tenure of loans from time to time. The loan tenure may be amended at any time before expiry of the Listing Period and until the loan amount has not been fully funded.
                                <br/>
                                <br/> 
                                <span className='contentofheading'>8. VIRTUAL WALLET</span>
                                <br/>The Borrower may opt for a virtual wallet where he may park his money for the purpose of repayment of the loan amount to any Lender. Money may be added in the virtual wallet by depositing funds in the Creditech – Borrower's escrow account.
                                <br/>
                                <br/>
                                <span className='contentofheading'>9. FEE AND CHARGES</span> 
                                Creditech shall charge a registration fee, loan processing fee, collection fee, late payment fee and other charges, as the case may be, as specified on the P2P Platform or Loan Agreement. The same may be revised from time to time and the latest fee schedule shall be available on the P2P Platform. 
                                
                                <br/>
                                <span>10. LOAN REPAYMENTS</span> 
                                <br/>
                                10.1 Loans repayments for each loan type may vary and may be revised from time to time. The latest repayment structures shall be available on the P2P
                                Platform at any point in time.
                                <br/>
                                10.2 The loan repayment for a bullet loan shall be a single frequency payment including the principal amount and accrued interest for the number of days the loan has been availed for.
                                <br/>
                                10.3 The loan repayment for a personal loan shall be through equated monthly instalments (“EMI”) and the same shall be devised using Principal Reducing Balance Method.
                                <br/>
                                10.3.1 An EMI is a fixed payment amount made by a borrower to a lender at a specified date each calendar month. EMIs are used to pay off both interest and principal each month so that over a period of time, the loan is paid off in full.
                                <br/>
                                10.3.2 The EMI schedule shall entail the following terms: The EMI repayment date which shall be the same day in the next month as the day on which the loan has been disbursed.
                                
                                <br/>
                                10.3.3 Every month EMI amount may or may not be auto-deducted from the Borrower’s bank account to the repayment escrow account. The Borrower may make repayments to the escrow account via direct debit, post-dated cheques, NEFT, RTGS, IMPS, UPI, demand draft or any other mode of payment as mentioned in the loan agreement.
                                <br/>
                                10.4 Late fees
                                <br/>
                                (a) In case of delay in payment of equated monthly instalment or bullet repayment, the loan availed by the Borrower shall be considered delinquent and failed
                                <br/>
                                payment and late fees may be assessed. In the event of delay in payments, a late fee shall be levied based on the following criteria: (i) The Borrower shall pay all EMIs and bullet repayment on the repayment date as mentioned in the loan agreement and as made available on the Borrower’s dashboard on the P2P Platform. In case the due EMI or repayment is not paid, a late payment penalty will be charged to the Borrower by Creditech at 36% p.a. accrued on a daily basis.
                                <br/>
                                (b) Each month, the monthly loan payment may or may not automatically be withdrawn from the Borrower’s bank account. If the Borrower receives an email from Creditech that his payment has failed, it implies that his account has insufficient funds or that the bank account on file at Creditech is out of date or incorrect.
                                <br/>
                                (c) In either case, the Borrower will need to pay a failed payment fee and can fix the problem with no further penalty except by making a manual payment within 15 (fifteen) days past the due date.
                                <br/>
                                (d) In the event the Borrower’s bank account information is out of date, the Borrower shall add a new bank account first. In case there is any default in the payment, Creditech shall follow the delinquency schedule. The interest shall continue to accrue at all time, on the outstanding amount of the loan. (e) In the event Creditech ties up with any bank in the near future, the Borrower agrees to open a bank account with the Creditech designated bank through which all the loan transactions will be processed. In such a case, the Borrower agrees to make loan payments through ECS by maintaining an EMI Account. Till that period, Borrower agrees to provide post-dated cheques for the EMI amount for the total repayment amount at the time of loan disbursement.
                                <br/>
                                (f) The above conditions are subject to change at the sole discretion of Creditech and any such changes shall be available on the P2P Platform. These changes shall be effective for on-going loans as well as new loans.
                                <br/>
                                10.5 The Borrower may prepay the loan subject to the payment of the foreclosure charges:
                                <br/>
                                (a) Only on the EMI due date and full outstanding amount.
                                <br/>
                                (b) The above conditions are subject to change at the sole discretion of Creditech and any such changes shall be made available on the P2P Platform. These
                                <br/>
                                changes shall be effective for all on-going loans as well as new loans. 10.6 Interest
                                <br/>
                                (a) Personalized interest rates are suggested to the Borrower as per his Risk Grade by an automated credit appraisal system that uses the personal and financial information provided by the Borrower to risk-assess and understand the strength of his credit profile. The interest rate thus assigned may range from 12% to 18% p.a.. Accordingly, the interest rate on the loan availed by the Borrower shall be communicated through the loan agreement.
                                <br/>
                                (b) The rate of interest applicable to the loan is compounded monthly and rests on the outstanding balance, namely the balance of loan and unpaid interest and costs, charges and expenses outstanding at the end of the month.
                                <br/>
                                10.7 Upon repayment of the entire loan amount, interest thereon and any other fee and charges due to the lender or Creditech, the Borrower and the co-applicant / guarantor, shall stand discharged of all their obligations under this Agreement. Accordingly, once the total loan amount has been repaid in full along with all the dues, a loan closure certificate will be issued to the Borrower.
                                <br/>
                                10.8 The Borrower hereby agrees and acknowledges that in the event there is a co-applicant or a co-guarantor along with the Borrower in respect of the loan availed through Creditech, under the Loan Agreement, executed between the Borrower and Lender:
                                <br/>
                                (a) The co-applicant or the co-guarantor, as the case may be, shall be severally liable for repayment of the loan amount, interest and other fees and charges payable to the lender or Creditech by the Borrower; and
                                <br/>
                                (b) Creditech shall be entitled and at liberty to initiate legal proceedings against such co-applicant / co-guarantor on its behalf or on behalf of the lenders, as the case may be.
                                <br/>
                                <span className='contentofheading'>11. COLLECTION AND REPORTING OF DELINQUENT LOANS</span> 
                                <br/>
                                11.1 Creditech shall proactively start communicating with the Borrower 5 (five) days prior to a repayment being due to ensure repayments are made on time. 11.2 In the event, the cheque is dishonoured or the EMI remains unpaid for 5 (five) days after the repayment is due, Creditech shall make an attempt to contact the delinquent Borrower via email (first written notice), SMS or phone.
                                <br/>
                                11.3 If the ECS or cheque is dishonoured then Creditech shall attempt to contact the borrower to inform and collect the EMI amount and charges, as
                                <br/>
                                applicable, proactively.
                                <br/>
                                11.4 If the EMI is not received within 5-30 days after the repayment due date, further attempts will be made by the collections team to contact the Borrower and arrange for immediate repayment. Notice of adverse credit may also be reported to credit bureaus at this point. 11.5 Creditech shall issue to the Borrower a ‘Due Letter’ on completion of 45 (forty-five) days from the date the repayment is due and shall issue a ‘Recall Letter’ on completion of 75 (seventy-five) days from the date repayment is due.
                                <br/>
                                11.6 If no attempt at resolution is made in the next 30-90 days, the case is then handed to Creditech’s concerned team. However, at this point the aim is still to retrieve repayment, and the collections team will continue to work with the delinquent Borrower to reach an amicable solution. 11.7 If no attempt at reparation is made post 90 (ninety) days from when the repayment is due, the loan will move into a ‘Non-Performing Asset (“NPA”)’state, and the case will handed over to an outsourced recovery team. If applicable, Creditech will also begin legal proceedings on behalf of the lenders in accordance with the terms of this Agreement. At this stage, the Borrower shall be classified as a defaulter. 11.8 To secure the interest of the Lender, or if Creditech deems it so necessary, it may initiate legal proceedings any time against the Borrower for the purpose of repayment without necessarily adhering to the process stated in the extant clause. 11.9 The Parties agree that the process of collection and reporting of delinquent loans may be changed / altered / modified any time by Creditech without prior notice to the Borrower.
                                <br/>
                                <span className='contentofheading'>12. OBLIGATIONS</span> 
                                <br/>
                                12.1 Company Obligations:
                                
                                <br/>
                                (a) After freezing the Listing either in the Listing period or Additional Listing Period, Creditech shall liaison between the Lenders and the Borrower to execute the required legal documents which will bind both the parties till the clearance of all the payments.
                                <br/>
                                (b) Creditech shall be responsible for the actions of its service providers including recovery agents and the confidentiality of information pertaining to the Borrower that is available with the service providers.
                                <br/>
                                (c) Creditech shall become a member of all Credit Information Companies (“CICs”) and submit data (including historical data) to them. (d) Creditech shall disclose to the Borrower, details about the lender(s) including proposed amount, interest rate offered but excluding personal identity and contact details of the lender(s).
                                <br/>
                                (e) Creditech agrees and acknowledges to:
                                <br/>
                                (i) Keep the credit information (relating to Borrower transactions on the platform) maintained by it, updated regularly on a monthly basis or at such shorter intervals as may be mutually agreed upon between Creditech and the CICs; and
                                <br/>
                                (ii) Take all such steps which may be necessary to ensure that the credit information furnished by it is up to date, accurate and complete;
                                <br/>
                                (f) Creditech agrees and acknowledges to:
                                <br/>
                                (i) Undertake due diligence of the participants i.e. the Borrowers and Lenders;
                                <br/>
                                (ii) Undertake credit assessment and risk profiling of the Borrowers and disclose the same to their prospective lenders; (iii) Require and obtain prior and explicit consent of the participant to access its credit information;
                                <br/>
                                (iv) Undertake documentation of loan agreements and other related documents;
                                <br/>
                                (v) Provide assistance in disbursement and repayments of loan amount; and
                                <br/>
                                (vi) Render services for recovery of loans originated on the platform.
                                <br/>
                                (g) Creditech shall ensure that the staff are adequately trained to deal with the Borrower in an appropriate manner and shall not resort to harassment viz;
                                <br/>
                                persistently bothering the borrowers at odd hours, use of coercion for recovery of loans, etc.
                                <br/>
                                (h) Creditech shall ensure that any information relating to the Borrower, received by it, is not disclosed to any third party, without the consent of the Borrower. (i) Creditech provides for a Board approved policy to address grievances / complaints of the Borrower. Complaints shall be handled / disposed of by Creditech within such time and in such manner as provided for in its Board approved policy, but in any case not beyond a period of one month from the date of receipt of the grievance / complaint.
                                <br/>
                                (j) Creditech shall ensure that the technology driving the P2P Platform is scalable to handle growth in its business. Adequate safeguards shall be built in its IT systems to ensure that it is protected against unauthorized access, alteration, destruction, disclosure or dissemination of records and data. (k) Creditech provides for a Board approved business continuity plan in place for safekeeping of information and documents and servicing of loans for full tenure in case of closure of the platform.
                                <br/>
                                (l) Creditech shall immediately notify RBI in the event of any breach of security and leakage of confidential customer related information. In these eventualities, Creditech would be liable to the Borrower for any damages.
                                <br/>
                                <br/>
                                12.2 Borrower Obligations:
                                <br/>
                                (a) To provide accurate and true information that is sought by Creditech for validating documents for the purpose of registration, verification, listing, disbursal,
                                <br/>
                                collection, repayment, recoveries, and all other acts specified under this Agreement.
                                <br/>
                                (b) To provide certified true copies of the documents sought for in respect of the aforesaid purposes.
                                <br/>
                                (c) To comply with the Terms of Use for the P2P Platform, Privacy Policy and any other rules or policies on the P2P Platform or any terms and conditions set forth under this Agreement or any other agreement, any of which may be amended from time to time by Creditech at its sole discretion. (d) To repay the entire loan amount along with interest due thereon and any other fee and charges payable to the lenders or Creditech within the time specified under this Agreement or any other agreement, without any default or delay.
                                <br/>
                                (e) To cooperate with Creditech with the information / documents sought from time-to-time for the purposes mentioned in the Agreement. (f) To provide a certificate to Creditech certifying that the aggregate loans taken by him at any point of time (including the one sought to be availed through Creditech), across all NBFC-P2Ps does not exceed Rs. 10,00,000 (Rupees [Ten Lakhs only]) or such other amount as may be prescribed from time to time under the RBI Directions or such other law, rules, regulations or guidelines that may be issued in this regard, from time to time. (g) The Borrower agrees that, in connection with any Listing, lender commitments, loans or other transactions involving or potentially involving Creditech, he shall not: (i) Make any false, misleading or deceptive statement(s) or omit any material fact;
                                <br/>
                                (ii) Misrepresent his identity, or describe, present or portray himself as a person other than himself;
                                <br/>
                                (iii) Give to or receive from, or offer or agree to give to or receive from, any lender or other person connected to Creditech any fee,
                                <br/>
                                bonus, additional interest, kickback or thing of value of any kind, including in exchange for such person’s commitment, recommendation, or offer or agreement to recommend or make a commitment with respect to the Listing;
                                <br/>
                                (iv) Represent himself to any person as a director, officer or employee of Creditech;
                                <br/>
                                (v) Post any content on the website of Creditech, which may be abusive, harmful or defamatory to Creditech and / or to other members on the site, whether textual or pictorial; and
                                <br/>
                                (vi) To either accept or reject the loan offer prior to expiration of Listing period or Additional Listing Period, as the case may
                                <br/>
                                be.
                                <br/><span className='contentofheading'> 13. REPRESENTATIONS AND WARRANTIES</span>
                                <br/>
                                13.1 Creditech and the Borrower represent and warrant that they have not withheld or misrepresented any material information which could have an adverse effect on any act, transaction or any other aspect contemplated under this Agreement. Each Party to the Agreement makes the following representations and warranties with respect to itself, and confirms that they are, true, correct and valid:
                                <br/>
                                (a) It has full power and authority to enter into, deliver and perform the terms and provisions of this Agreement and, in particular, to exercise its rights, perform the obligations expressed to be assumed by and make the representations and warranties made by it hereunder;
                                <br/>
                                (b) Its obligations under this Agreement are legal and valid, binding on it and enforceable against it in accordance with the terms hereof; and (c) The parties to the Agreement warrant and represent to have the legal competence and capacity to execute and perform this Agreement. 13.2 Creditech
                                
                                <br/>
                                represents and warrants that:
                                <br/>
                                (a) It shall act as an intermediary providing an online marketplace or platform to the participants involved in peer to peer lending and shall not raise deposits as
                                <br/>
                                defined by or under Section 45I(bb) of the Act or the Companies Act, 2013; (b) Not lend on its own;
                                <br/>
                                (c) Not provide or arrange any credit enhancement or credit guarantee;
                                <br/>
                                (d) Not facilitate or permit any secured lending linked to its platform; i.e. only clean loans will be permitted;
                                <br/>
                                (e) Not hold, on its own balance sheet, funds received from lenders for lending, or funds received from borrowers for servicing loans etc.; (f) Not cross sell any product except for loan specific insurance products;
                                <br/>
                                (g) Not permit international flow of funds;
                                <br/>
                                (h) Ensure adherence to legal requirements applicable to the participants as prescribed under relevant laws; and
                                <br/>
                                (i) Store and process all data relating to its activities and participants on hardware located within India.
                                <br/>
                                <span className='contentofheading'></span> 14. RESTRICTIONS ON USE
                                <br/>
                                14.1 Borrower shall represent him as himself and as no other third party.
                                <br/>
                                14.2 Borrower is not authorized or permitted to use or avail the services of Creditech to make requests for someone other than himself. 14.3 All the
                                <br/>
                                transactions carried out by the Borrower shall be solely by and for himself and from his bank account.
                                <br/> 
                                <span className='contentofheading'>15. NO GUARANTEE </span> 
                                <br/> Creditech does not guarantee that the Listing will be matched with any lender commitments, or that the Borrower will receive a loan as a result of posting a Listing.
                                <br/>
                                <span className='contentofheading'> 16. TERMINATION</span>
                                <br/>
                                16.1 This Agreement may be terminated:
                                <br/>
                                (a) By mutual written agreement of the Parties;
                                <br/>
                                (b) If any laws or regulations or any amendments thereto makes the transaction or the Agreement illegal, or is otherwise prohibited by law or by an
                                <br/>
                                order, decree or judgement of any court or governmental body having competent jurisdiction; 16.2 Termination by Creditech:
                                <br/>
                                (a) Creditech may, at its sole discretion, with or without cause, terminate this Agreement and registration with it, at any time, by giving notice to the Borrower. (b) In case of non-compliance of the Borrower’s obligations mentioned under Clause 12.2 of this Agreement, involvement of the Borrower in any prohibited or illegal purpose or the Borrower’s failure to abide by the terms and conditions of this Agreement or the terms and conditions of Creditech or any law of the land, or upon such determination in connection with registration / loan offer, Creditech may, at its sole discretion, immediately and without notice, take one or more of the following acts:
                                <br/>
                                (i) terminate or suspend the Listing for requested loan on its P2P Platform; (ii) terminate this Agreement and registration with Creditech; and
                                <br/>
                                (iii) Upon termination of this Agreement and registration with Creditech, all loans disbursed and originated from Creditech will still hold
                                <br/>
                                good.
                                <br/>
                                (c) Creditech may, by giving notice to the Borrower, terminate this Agreement if there has been a material misrepresentation or breach of a representation and warranty or breach of covenant on the part of the Borrower.
                                <br/>
                                (d) Creditech may, by giving notice to the Borrower, terminate this Agreement if any event or circumstance occurs which, in the opinion of Creditech, could have a material adverse effect on the fulfilment of the obligations of the Borrower under this Agreement.
                                <br/>
                                (e) In the event of any failure to fund the loan after commitment, the Borrower shall not be eligible to browse any further Listings and his access to the P2P Platform shall be restricted.
                                <br/>
                                16.3 Termination by Borrower:
                                <br/>
                                A Borrower is entitled to terminate this Agreement, at any time, after giving a notice to Creditech, provided that:
                                <br/>
                                (a) No outstanding amount/ dues in Borrower’s Account; (b) No active Listing on the P2P Platform.
                                <br/>
                                A Borrower may terminate this Agreement if the Borrower has an active Listing, provided that the Borrower, subject to the terms and condition
                                <br/>
                                contained in this Agreement, withdraws the Listing from the P2P Platform. 16.4 Consequences of Termination
                                <br/>
                                Upon termination of this Agreement and registration with Creditech, any Listing which is not funded shall be cancelled and shall be removed from the P2P Platform forthwith. The Borrower’s obligations specified in this Agreement shall subsist for the loans funded prior to the effective date of termination of this Agreement and registration with Creditech.
                                <br/>
                                <span className='contentofheading'> 17. RIGHT TO MODIFY TERMS</span>
                                
                                <br/>
                                17.1 For the reasons specified in Clause 17.2, Creditech reserves the right to modify the terms of this Agreement and / or its Terms of Use and / or its Privacy Policy and / or any other agreement involving the Borrower. Such changes shall not apply retrospectively.
                                <br/>
                                17.2 Creditech will be at liberty to modify the terms, from time to time, without consent of the Borrower, due to any of the following reasons:
                                <br/>
                                (a) To make the terms clearer;
                                <br/>
                                (b) To make changes to its products and services, to introduce new products or services or to withdraw products or services that Creditech no longer offers; (c) To change its existing services owing to the changes and innovation in technology, systems, processes, business procedures and / or policies; (d) To adapt to changes in law, regulation, industry guidance or a decision of a competent court or any regulation formed by the RBI from time to time. 17.3 Modifications stated in this clause shall be communicated to the Borrower and also made available on the P2P Platform.
                                <br/>
                                <span className='ontentofheading'>18. INDEMNIFICATION</span> 
                                <br/>
                                Each Party shall, at all times, at present and thereafter, defend, indemnify and hold harmless the other Party, its employees, officers, directors, agents, representatives from and against any and all liabilities, suits, claims, actions, proceedings, losses, damages, judgments and costs, of any
                                
                                <br/>
                                nature whatsoever (including Attorney’s fees) caused due to non-compliance of the terms and conditions of this Agreement, breach of representation and warranties, beach of covenants, or non-compliance of any applicable laws and regulations, breach of the confidentiality obligations and infringement of third-party intellectual property rights, damage to any third party’s property or body. This clause shall survive the
                                <br/>
                                expiry or termination of this Agreement.
                                <br/>
                                <span className='contentofheading'>19. FORCE MAJEURE</span> 
                                <br/>
                                19.1 No Party shall be liable to the other if, and to the extent, that the performance or delay in performance of any of its obligations under this Agreement is prevented, restricted, delayed or interfered with, due to circumstances beyond the reasonable control of such Party, including but not limited to, government legislations, fires, floods, explosions, epidemics, accidents, acts of God, wars, riots, strikes, lockouts, or other concerted acts of workmen, acts of Government and / or shortages of materials, hacking, unanticipated technological or natural interference or intrusion, loss or damage to satellites, loss of satellite linkage or any other data communications linkage, loss of connectivity or any other irresistible force or compulsion.
                                <br/>
                                19.2 The Party claiming an event of force majeure shall promptly notify the other Party in writing and provide full particulars of the cause or event and the date of first occurrence thereof, as soon as possible after the event and also keep the other Party informed of any further developments. The Party so affected shall use its best efforts to remove the cause of non-performance, and shall resume performance hereunder with the utmost responsibility when such cause is removed.
                                <br/>
                                <span className='contentofheading'>20. GOVERNING LAW AND JURISDICTION</span> 
                                <br/>
                                This Agreement shall be governed by and construed in accordance with the laws of India and, courts of [Delhi], India shall have exclusive jurisdiction over all matters arising pursuant to this Agreement.
                                <br/>
                                <span className='contentofheading'>21. NOTICES</span> 
                                <br/>
                                All notices under this Agreement shall be written in English and shall be sent by hand or by courier or by facsimile or by electronic mail to the applicable Party at the address / contact details indicated as in the description of Parties or to such other address as any Party shall designate by written notice to the other Party and shall be deemed to be given only when delivered by personal delivery or by courier or by electronic mail at the above mentioned address.
                                <br/>
                                <span className='contentofheading'> 22. AMENDMENT AND ASSIGNMENT</span>
                                
                                <br/>
                                Any amendments to this Agreement shall be legally valid and binding on each of the Parties, if made in writing and signed by each of the Parties. No Party shall be entitled to transfer or assign any rights or obligations under this Agreement to third parties without informing the other Party and obtaining the prior written approval of such other Party.
                                <br/>
                                <span className='contentofheading'>23. WAIVER</span> 
                                <span className='contentofheading'></span> <br/>
                                The failure of any Party to insist, in one or more instances, upon strict performance of the obligations of this Agreement, or to exercise any rights contained herein, shall not be construed as waiver, or relinquishment for the future, of such obligation or right, which shall remain and continue in full force and effect. No waiver of any breach of any provision of this Agreement shall be effective or binding unless made in writing and signed by the Party purporting to give the same and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived.
                                <br/>
                                <span className='contentofheading'>24. SEVERABILITY</span> 
                                <br/>
                                A provision contained in this Agreement is enforceable independently of each of the others and its validity will not in any way be affected by the invalidity or unenforceability of any other provision hereof. If any provision of this Agreement is held to be illegal, invalid or unenforceable under any applicable law from time to time: (a) such provision will be fully severable from this Agreement; (b) this Agreement will be construed and enforced as if such illegal, invalid or unenforceable provision had never comprised a part hereof; and (c) the remaining provisions of this Agreement will remain in full force and effect and will not be affected by the illegal, invalid or unenforceable provision or by its severance here from.
                                <br/>
                                <span className='contentofheading'>25. ENTIRE AGREEMENT</span> 
                                <br/>
                                This Agreement contains the whole agreement and understanding between the Parties with regard to the matters dealt with in this Agreement and unless provided otherwise supersedes any prior agreement, understanding, arrangement or promises, whether written or oral or whether subsisting or expired, relating to the subject matter of this Agreement The Parties expressly acknowledge that, in relation to the subject matter of this Agreement, each of them assumes no obligations of any kind whatsoever other than as expressly set forth in this Agreement.
                                <br/>
                                <span className='contentofheading'>26. COUNTERPARTS</span> 
                                <br/>
                                This Agreement may be executed simultaneously in more than one counterpart each of which shall be deemed to be an original but all of which shall constitute the same instrument.
                            </p>
                        </Box>
                    }
                </ModalBody>
                <ModalFooter>
                    <CustomButton size="md" type="submit" title="Cancel" className="btn_theme" onClick={props.handleClickTerms} />
                </ModalFooter>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleClickTerms}
        isOpen={props.openTerms}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
