import { Box, Heading, IconButton, Image, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import YesBankIcon from '../../../../../assets/images/yesbank.svg'
import { InfoOutlineIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import CustomButton from '../../../../elements/customButton'
import PassbookIcon from '../../../../../assets/images/passbook.svg'

export default function WalletBalancePassbook(props) {
    const isCompleted = props?.isKycCompleted
    
 return (
    <Box className='chart_box bg_shadow'>
        <Heading as="h2" className='font-poppins card_heading text_white'>Wallet Balance</Heading>
        {/* {(props?.walletData?.payment_mode === 0 || props?.walletData?.payment_mode === 1) ?
            <Box className='wallet_card_heading'>
                <Heading as="h3" mb={2} className='font-poppins'>You want to receive repayments in?</Heading>
                <Box className='d-flex align-items-center justify-content-between btn_wallet_payment_action'>
                    <CustomButton className={props?.walletData?.payment_mode === 0 ? "btn_wallet_payment_active" : "btn_wallet_payment"} title="Escrow Account" onClick={() => props.handleChangePaymentMode('yes')} />
                    <CustomButton className={props?.walletData?.payment_mode === 1 ? "btn_wallet_payment_active" : "btn_wallet_payment"} title="Bank Account" onClick={() => props.handleChangePaymentMode('no')} />
                </Box>
            </Box>
            :
            <Box className='wallet_card_heading d-flex align-items-center justify-content-between'>
                <Heading as="h3" className='font-poppins'>Would you like to reinvest money?</Heading>
                <Box className='wallet_selection_action d-flex align-items-center col-gap-10'>
                    <Tooltip label="Helps you to gain compounded interest"
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                    </Tooltip>
                    <CustomButton size="md" type="submit" title="Yes" className="btn_confirm font-poppins" onClick={() => props.handleChangePaymentMode('yes')}
                        isDisabled={isCompleted ? false : true}
                    />
                    <CustomButton size="md" type="submit" title="No" className="btn_canceled font-poppins" onClick={() => props.handleChangePaymentMode('no')}
                        isDisabled={isCompleted ? false : true}
                    />
                </Box>
            </Box>
        } */}
        <Box mt={2} className='alpha_score bg_shadow' pt={1}>
            {/* {props?.walletData?.payment_mode === 0 ?
                <Box className='payment_mode_text'>
                    <Text className='font-poppins'>You will receive your repayment in your Escrow Account</Text>
                </Box> :
            props?.walletData?.payment_mode === 1 ?
                <Box className='payment_mode_text'>
                    <Text className='font-poppins'>You will receive your repayment in your Bank Account</Text>
                </Box> :
                <Box className='d-flex align-items-center justify-content-between wallet_flex'>
                    <Heading as="h2" className='font-poppins'>Wallet Balance</Heading>
                    <Heading as="p" className='font-poppins'>Powered By<Image src={YesBankIcon} /></Heading>
                </Box>
            } */}
            <Box className='d-flex align-items-center justify-content-end wallet_flex'>
                {/* <Heading as="h2" className='font-poppins'>Wallet Balance</Heading> */}
                <Heading as="p" className='font-poppins'>Powered By<Image src={YesBankIcon} /></Heading>
            </Box>
            <Box className='price_bg'>
                <Box className='price_box d-flex align-items-center col-gap-10'>
                    <Heading as="h2" className='font-poppins'>₹ {props.viewAmount ? (props?.walletData?.effective_balance ? props?.walletData?.effective_balance : '0') : '******'}</Heading>
                    <IconButton onClick={props.handleViewAmount}>
                       {!props.viewAmount ? <ViewIcon /> : <ViewOffIcon />}
                    </IconButton>
                </Box>
                <Box className='d-flex align-items-center justify-content-end price_actions'>
                    <CustomButton size="md" type="submit" title="Download Passbook" className="btn_white_solid font-poppins"
                        leftIcon={<Image src={PassbookIcon} />} onClick={props.handlePassbookDialog}
                        isDisabled={isCompleted ? false : true}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
  )
}
