import { Box } from '@chakra-ui/react'
import React from 'react'
import Lottie from "lottie-react";
import LoaderAnimation from "../../assets/lottiFiles/loader.json";

export default function Loader() {
  return (
    <div className="preloader">
      <Box className='loader-box'>
        <Lottie animationData={LoaderAnimation} loop={true} />
      </Box>
        {/* <div className="lds-circle"><div /></div> */}
      </div>
  )
}
