export const GET_USER_DASHBOARD_DETAILS_REQUEST = 'GET_USER_DASHBOARD_DETAILS_REQUEST'
export const GET_USER_DASHBOARD_DETAILS_FAILURE = 'GET_USER_DASHBOARD_DETAILS_FAILURE'
export const GET_USER_DASHBOARD_DETAILS_SUCCESS = 'GET_USER_DASHBOARD_DETAILS_SUCCESS'

export const GET_BORROWER_DETAILS_REQUEST = 'GET_BORROWER_DETAILS_REQUEST'
export const GET_BORROWER_DETAILS_FAILURE = 'GET_BORROWER_DETAILS_FAILURE'
export const GET_BORROWER_DETAILS_SUCCESS = 'GET_BORROWER_DETAILS_SUCCESS'

export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST'
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE'
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS'

export const APPLY_LOAN_REQUEST = 'APPLY_LOAN_REQUEST'
export const APPLY_LOAN_FAILURE = 'APPLY_LOAN_FAILURE'
export const APPLY_LOAN_SUCCESS = 'APPLY_LOAN_SUCCESS'

export const GET_LOAN_LIST_DETAILS_REQUEST = 'GET_LOAN_LIST_DETAILS_REQUEST'
export const GET_LOAN_LIST_DETAILS_FAILURE = 'GET_LOAN_LIST_DETAILS_FAILURE'
export const GET_LOAN_LIST_DETAILS_SUCCESS = 'GET_LOAN_LIST_DETAILS_SUCCESS'

export const GET_AMMORTIZATION_SCHEDULE_REQUEST = 'GET_AMMORTIZATION_SCHEDULE_REQUEST'
export const GET_AMMORTIZATION_SCHEDULE_FAILURE = 'GET_AMMORTIZATION_SCHEDULE_FAILURE'
export const GET_AMMORTIZATION_SCHEDULE_SUCCESS = 'GET_AMMORTIZATION_SCHEDULE_SUCCESS'

export const GET_LOAN_PAYMENT_TRANSACTION_REQUEST = 'GET_LOAN_PAYMENT_TRANSACTION_REQUEST'
export const GET_LOAN_PAYMENT_TRANSACTION_FAILURE = 'GET_LOAN_PAYMENT_TRANSACTION_FAILURE'
export const GET_LOAN_PAYMENT_TRANSACTION_SUCCESS = 'GET_LOAN_PAYMENT_TRANSACTION_SUCCESS'

export const GET_LOAN_LIST_STATUS_REQUEST = 'GET_LOAN_LIST_STATUS_REQUEST'
export const GET_LOAN_LIST_STATUS_FAILURE = 'GET_LOAN_LIST_STATUS_FAILURE'
export const GET_LOAN_LIST_STATUS_SUCCESS = 'GET_LOAN_LIST_STATUS_SUCCESS'

export const GET_BORROWER_CREDIT_DATA_REQUEST = 'GET_BORROWER_CREDIT_DATA_REQUEST'
export const GET_BORROWER_CREDIT_DATA_FAILURE = 'GET_BORROWER_CREDIT_DATA_FAILURE'
export const GET_BORROWER_CREDIT_DATA_SUCCESS = 'GET_BORROWER_CREDIT_DATA_SUCCESS'

export const CREATE_ESIGN_REQUEST = 'CREATE_ESIGN_REQUEST'
export const CREATE_ESIGN_FAILURE = 'CREATE_ESIGN_FAILURE'
export const CREATE_ESIGN_SUCCESS = 'CREATE_ESIGN_SUCCESS'

export const ACCEPT_LOAN_ELIGIBILITY_REQUEST = 'ACCEPT_LOAN_ELIGIBILITY_REQUEST'
export const ACCEPT_LOAN_ELIGIBILITY_FAILURE = 'ACCEPT_LOAN_ELIGIBILITY_FAILURE'
export const ACCEPT_LOAN_ELIGIBILITY_SUCCESS = 'ACCEPT_LOAN_ELIGIBILITY_SUCCESS'

export const WITHDRAWN_LOAN_ELIGIBILITY_REQUEST = 'WITHDRAWN_LOAN_ELIGIBILITY_REQUEST'
export const WITHDRAWN_LOAN_ELIGIBILITY_FAILURE = 'WITHDRAWN_LOAN_ELIGIBILITY_FAILURE'
export const WITHDRAWN_LOAN_ELIGIBILITY_SUCCESS = 'WITHDRAWN_LOAN_ELIGIBILITY_SUCCESS'

export const GET_TRACK_LOAN_JOURNEY_REQUEST = 'GET_TRACK_LOAN_JOURNEY_REQUEST'
export const GET_TRACK_LOAN_JOURNEY_FAILURE = 'GET_TRACK_LOAN_JOURNEY_FAILURE'
export const GET_TRACK_LOAN_JOURNEY_SUCCESS = 'GET_TRACK_LOAN_JOURNEY_SUCCESS'