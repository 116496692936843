import { Box, Heading, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../redux/actions';
import LockIcon from '../../../assets/images/lock.svg'
import SuccessPin from './SuccessPin';

const ReEnterPin = (props) => {

    const [reEnterMpin, seReEnterMpin] = useState(['', '', '', ''])
    const [successPin, setSuccessPin] = useState(false)
    const reEnterMpinRef = useRef([])

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
            const newMpin = [...reEnterMpin]
            newMpin[index] = value
            seReEnterMpin(newMpin)
            if (index < 3) {
                reEnterMpinRef.current[index + 1].focus()
            } else if (index === 3) {
                setSuccessPin(true)
                props.handleCloseReEnter()
                seReEnterMpin(['', '', '', ''])
            }
        } else if (value === '') {
            const newMpin = [...reEnterMpin]
            newMpin[index] = value
            seReEnterMpin(newMpin)
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !reEnterMpin[index] && index > 0) {
            reEnterMpinRef.current[index - 1].focus()
        }
    }

    const handleCloseRenterPin = () => {
        props.handleCloseReEnter()
        seReEnterMpin(['', '', '', ''])
    }

    const handleCloseSuccessPin = () => {
        setSuccessPin(false)
    }

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent className='delete_modal_content'>
                <ModalCloseButton />
                <ModalBody className='delete_modal_body' pb={5} pt={6}>
                    <Box className='delete_body'>
                        <Heading as="h4" className='font-poppins'>Re-enter ALPHAMONEY PIN</Heading>
                        <Box className='pin_section' mt={10} mb={12}>
                            <Box className='pin_input_block'>
                                {reEnterMpin.map((value, index) => (
                                    <Input
                                        key={index}
                                        ref={(el) => (reEnterMpinRef.current[index] = el)}
                                        value={value}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        textAlign="center"
                                        type="password"
                                        maxLength={1}
                                        className='font-poppins input_pin'
                                    />
                                ))}
                            </Box>
                            <Text className='font-poppins'>Please Enter ALPHAMONEY Pin here</Text>
                        </Box>
                        <Heading as="h3" className='font-poppins'>This keep your account Secure <Image src={LockIcon} /> </Heading>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <>
        <Modal
            isCentered
            onClose={handleCloseRenterPin}
            isOpen={props.openReEnter}
            motionPreset="slideInBottom"
            // size={'xl'}
        >
        {PopupBox()}
        </Modal>
        <SuccessPin successPin={successPin} handleCloseSuccessPin={handleCloseSuccessPin} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(ReEnterPin)