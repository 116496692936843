import React from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Heading, IconButton, Input, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import Lottie from "lottie-react";
import LoadingSuccessAnimation from "../../../../../assets/lottiFiles/loadingSuccess.json";

export default function WithdrawFundsDialog(props) {

    const PopupBox = () => (
        <Box className='wallet_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-start col-gap-15 preference_header'>
                    <IconButton onClick={props.handleCloseWithdrawFundsDialog}><ArrowBackIcon /></IconButton>
                    <Heading as="h3" className="font-poppins">Withdraw Funds</Heading>
                </Box>
                {props?.isWithdrawn === true ?
                    <>
                        <Box className='lottie-width'>
                            <Lottie animationData={LoadingSuccessAnimation} loop={false} />
                        </Box>
                        <CustomButton size="md" type="submit" title="Go to Dashboard" className="font-poppins btn_theme w-100" onClick={props.handleGoToDashboard} />
                    </> :
                    <ModalBody className='wallet_body'>
                        <Heading as="h5" className='font-poppins'>Please Select Or Enter amount to Withdraw</Heading>
                        <Box className='add_funds_box' mt={1}>
                            <Box className='d-flex align-items-center justify-content-between total_balance'>
                                <Heading as="h6" className="font-poppins">Total Available Balance to Withdraw</Heading>
                                <Text className="font-poppins">₹ {props?.walletData?.effective_balance}</Text>
                            </Box>
                            <Box pt={4} pb={3}>
                                <Box className='amount_input_container'>
                                    <Box className='amount_input_box_withdraw' mb={1}>
                                        <Input
                                            type="number"
                                            size="md"
                                            name="withdraw_amount"
                                            placeholder={"₹ 0.00"}
                                            onChange={props.handleChange}
                                            className="amount_input color-dark font-poppins"
                                            value={props?.inputValues?.withdraw_amount ?? ''}
                                        />
                                    </Box>
                                    <Box className="widthdraw_check" pt={1} pb={6}>
                                        <Checkbox onChange={(e) => props.handleWithdrawAll(e, props?.walletData?.effective_balance)}> Withdraw all Money </Checkbox>
                                    </Box>
                                </Box> 
                            </Box>
                            <CustomButton size="md" type="submit" title="Continue" className="font-poppins btn_theme w-100" onClick={props.handleWithdrawAmount} />
                        </Box>
                    </ModalBody>
                }
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseWithdrawFundsDialog}
        isOpen={props.openWithdrawFunds}
        motionPreset="slideInBottom"
        size={'sm'}
    >
    {PopupBox()}
    </Modal>
  )
}
