import React, { useState } from 'react'
import {Box, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text,} from '@chakra-ui/react'
import AnnounceIcon from '../../assets/images/announce.svg'
import CustomButton from './customButton'

export default function ImportantDialog(props) {

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }

    const PopupBox = () => (
        <Box className="announce_popup">
            <ModalOverlay />
            <ModalContent>
                <Heading as="h3" className="font-poppins">Important Announcement</Heading>
                <ModalCloseButton />
                <ModalBody>
                    {props.data === '3' ?
                        <Box className='announce_content' mt={5}>
                            <Heading as="h4" className="font-poppins">Registration Fee</Heading>
                            <Text className="font-poppins">One time Registration fee of INR 100+GST would be applicable to all the users for the KYC verification purposes.</Text>
                        </Box> :
                        <Box className='announce_content' mt={5}>
                            <Heading as="h4" className="font-poppins">Platform Fee</Heading>
                            <Text className="font-poppins">A Platform fee of 150+GST will be additionally applicable for credit assessment of each loan.</Text>
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <>
        <Modal
            isCentered
            onClose={handleOpen}
            isOpen={open}
            motionPreset="slideInBottom"
        >
        {PopupBox()}
        </Modal>
        <CustomButton size="md" type="submit" title="Important" className="btn_theme_announce font-poppins" onClick={handleOpen}
            rightIcon={<Image src={AnnounceIcon} />}
        />
    </>
  )
}
