import React, { useEffect, useRef, useState } from 'react'
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Grid, GridItem, Heading, Image, Tooltip} from '@chakra-ui/react'
import { useLocation, useHistory } from 'react-router-dom'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ActionCreators } from '../../../../redux/actions';
import NavBar from '../../../elements/NavBar'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import CustomCard from '../../../elements/CustomCard'
import Loader from '../../../elements/loader'
import WalletBalance from './components/WalletBalance'
import { ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import InvestorStackedChart from './components/InvestorStackedChart'
import PortfolioDetails from './components/PortfolioDetails'
import UpcomingRepayments from './components/UpcomingRepayments';
import AddFundsDialog from '../Wallet/Components/AddFundsDialog';
import WithdrawFundsDialog from '../Wallet/Components/WithdrawFundsDialog';
import PaymentDialog from '../../../modules/PaymentModule/PaymentDialogInvestor';
import CustomToast from '../../../elements/customToast';
import { uploadNetworthCertificate } from '../../../../services/masterServices';
import moment from 'moment';

const amountData = [
  {
    id: 1,
    name: '10,00,000',
  },
  {
    id: 2,
    name: '1,00,000',
  },
  {
    id: 3,
    name: '75,000',
  },
  {
    id: 4,
    name: '50,000',
  },
  {
    id: 5,
    name: '25,000',
  },
  {
    id: 6,
    name: '10,000',
  },
]

const InvestorPortfolio = (props) =>{

  const MAXAMOUNT = 5000000
  const history = useHistory()
  const location = useLocation()
  const { addToast } = CustomToast()
  const certificateRef = useRef()
  const investorState = useSelector(state =>state.investorState)
  const masterState = useSelector((state) => state.masterState)
  const [viewAmount, setViewAmount] = useState(false)
  const [inputValues, setInputValues] = useState({})
  const [openAddFunds, setOpenAddFunds] = useState(false)
  const [openWithdrawFunds, setOpenWithdrawFunds] = useState(false)
  const [amount, setAmount] = useState(null)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [payableAmount, setPayableAmount] = useState(null)
  const [certificateData, setCertificateData] = useState(null)
  const [acknowledgeConsent, setAcknowledgeConsent] = useState(false)
  const [certificateConsent, setCertificateConsent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isWithdrawn, setIsWithdrawn] = useState(false)

  const userDetails = investorState?.getInvestorDashboardData?.data?.data
  const userData = masterState?.getCurrentUserDetails?.data?.data
  const portfolioData = investorState?.getInvestorPortfolioData?.data?.data
  const walletData = investorState?.getInvestorWalletData?.data?.data
  // const isKYCPending = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 0)
  // const isKYCUnderReview = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 1)
  const isKYCCompleted = (userDetails?.user?.iskyc === 1 && userDetails?.user?.form_completed_status === 1)
   
  useEffect(()=>{
   props.actions.getInvestorDetailedAction()
   props.actions.getCurrentUserDetailsAction()
   props.actions.getInvestmentPortfolioAction()
   props.actions.getInvestmentWalletAction()
  },[])

  const expectedVSReceived = portfolioData?.expectedvsreceived && Object.keys(portfolioData?.expectedvsreceived).map((key) => {
    return {
      month: moment(key, "YYYY-MM").format("MMM YYYY"),
      data: portfolioData?.expectedvsreceived[key]
    }
  })

  useEffect(() => {
    if(investorState?.withdrawWalletAmount?.data?.success === true) {
        addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'success' })
        setIsWithdrawn(investorState?.withdrawWalletAmount?.data?.success)
        props.actions.getCurrentUserDetailsAction()
        props?.actions?.cleanUpToast()
      } else if(investorState?.withdrawWalletAmount?.data?.success === false) {
        addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'warning' })
        props?.actions?.cleanUpToast()
      }
  }, [investorState?.withdrawWalletAmount])

  useEffect(() => {
    if (investorState?.changePaymentMode?.data?.success === true) {
      addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'success' })
      props?.actions?.cleanUpToast()
    } else if (investorState?.changePaymentMode?.data?.success === false) {
      addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'error' })
      props?.actions?.cleanUpToast()
    }
  }, [investorState?.changePaymentMode?.data])

  const barChartOptions = {
    layout: {
      padding: {
        right: 15
      }
   },
    legend: {
      display: true,
      onClick: function(e, legendItem) {
        e.preventDefault();
        e.stopPropagation();
      },
      position: 'bottom',
      labels: {
        fontFamily: "Poppins",
        fontColor: '#000',
        fontSize: 12,
        fontStyle: 'normal',
        usePointStyle: true,
        padding: 30,
      },
    },
    tooltips: {
      position: 'nearest',
      backgroundColor: 'rgba(255,255,255,1)',
      titleFontColor: '#000',
      bodyFontColor: '#000',
      titleFontSize: 14,
      titleFontFamily: "Poppins",
      bodyFontFamily: "Poppins",
      bodyFontSize: 14,
      bodySpacing: 4,
      xPadding: 12,
      yPadding: 12,
      borderColor: '#D3D2D5',
      borderWidth: 1,
      displayColors: false,
      callbacks: {
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index]; // Display the label as the title
        },
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const formattedValue = value.toLocaleString('en-US');
          return `(${'₹ ' + formattedValue + ''})`;
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
   },
   scales: {
    yAxes: [{
      ticks: {
        fontColor: "#000",
        fontSize: 12,
        fontFamily: "Poppins",
        // stepSize: 1,
        beginAtZero: true,
        callback: function(value, index, values) {
          return value.toLocaleString() + '';
        }
      }
    }],
    xAxes: [{
      ticks: {
        fontColor: "#000",
        fontSize: 12,
        fontFamily: "Poppins",
      }
    }],
 }
  };

  const barChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "#C2D6D3",
        label: "Total Principal Received",
        borderRadius: 5,
      },
      {
        label: 'Principal Expected',
        data: [],
        backgroundColor: '#357066',
        borderRadius: 5,
      },
    ],
  };

  expectedVSReceived?.forEach(entry => {
    barChartData.labels?.push(entry.month)
    barChartData.datasets[0].data.push(entry.data.received);
    barChartData.datasets[1].data.push(entry.data.expected);
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  }

  const handleCheck = (e, type) => {
    if (type === 'acknowledge') {
      setAcknowledgeConsent(e.target.checked)
    }
    if (type === 'certificate') {
      setCertificateConsent(e.target.checked)
    }
  }

  const handleViewAmount = () => {
    setViewAmount(!viewAmount)
  }

  const handleAddFundsDialog = () => {
    setOpenAddFunds(!openAddFunds)
  }

  const handleOpenWithdrawFundsDialog = () => {
    setOpenWithdrawFunds(true)
  }

  const handleCloseWithdrawFundsDialog = () => {
    setOpenWithdrawFunds(false)
    setInputValues({...inputValues, withdraw_amount: ''})
    setIsWithdrawn(false)
  }

  const handleWithdrawAll = (e, amount) => {
    if (e.target.checked) {
        setInputValues({...inputValues, withdraw_amount: amount})
    } else {
        setInputValues({...inputValues, withdraw_amount: ''})
    }
  }

  const handleWithdrawAmount = () => {
    if (!inputValues?.withdraw_amount) {
        addToast({message: 'Minumum amount should be ₹1', status: 'error'})
        return
    }
    if (Number(inputValues?.withdraw_amount) > Number(walletData?.effective_balance)) {
        addToast({message: 'Insufficient Funds', status: 'error'})
        return
    }
    props.actions.withdrawWalletAmountAction({amount: inputValues?.withdraw_amount})
  }

  const handleSelectAmount = (item) => {
    const selectedAmount = amountData?.find((elem) => elem.id === item.id)
    setAmount(selectedAmount)
    setInputValues({ ...inputValues, lending_amount: selectedAmount.name });
  }

  const handleSaveDocumnent = () => {
    if (!certificateData) {
      addToast({ message: 'Please Upload Certificate', status: 'error' })
      return
    }
    const formData = new FormData()
    formData.append('networth_certificate', certificateData)
    setIsLoading(true)
    uploadNetworthCertificate(formData).then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true) {
        addToast({message: 'Document Uploaded Successfully', status: 'success'})
        props.actions.getCurrentUserDetailsAction()
      } else if (res?.data?.success === false) {
        addToast({message: res?.data?.message, status: 'error'})
      }
    }).catch((error) => {
      addToast({message: error, status: 'error'})
      setIsLoading(false)
    })
  }

  const handleOpenPaymentDialog = (amount) => {
    if (!inputValues?.lending_amount || inputValues?.lending_amount <= 99) {
        addToast({message: 'Minimum Amount is 100', status: 'error'})
        return
    }
    if (+(inputValues?.lending_amount) > MAXAMOUNT) {
        addToast({message: 'Can not Add Funds more than 50 Lakhs', status: 'error'})
        return
    }
    setPayableAmount(amount)
    setOpenPaymentModal(true)
  }

  const handleClosePaymentDialog = () => {
    setPayableAmount(null)
    setOpenPaymentModal(false)
  }

  const showOpenFileDialog = () => {
    certificateRef.current.click()
  }

  const onChangeFile = (e) => {
    setCertificateData(e.target.files[0])
  }

  const handleChangePaymentMode = (type) => {
    if (type === 'yes') {
      props.actions.postChangePaymentModeAction({payment_mode : 0})
    } else if (type === 'no') {
      props.actions.postChangePaymentModeAction({payment_mode : 1})
    }
  }

  const handleGoToDashboard = () => {
    setInputValues({...inputValues, withdraw_amount: ''})
    setIsWithdrawn(false)
    history.push(routesNames.INVESTERDASHBOARD)
  }

  const handleProfile = () => {
    history.push(routesNames.INVESTORPROFILE)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  return(
    <Box>
      {(investorState?.getInvestorDashboardData?.loading || masterState?.getCurrentUserDetails?.loading || isLoading || investorState?.withdrawWalletAmount?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Grid className="main_grid_container">
        <GridItem colSpan={8}>
          <Box className='dashboard_box bg_shadow'>
            <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='black.500' />}>
              <BreadcrumbItem className='breadcrumb_item'>
                <BreadcrumbLink href={routesNames.INVESTERDASHBOARD}>Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem className='breadcrumb_item' isCurrentPage>
                <BreadcrumbLink href='#'>Portfolio</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Box mt={4} className='portfolio_name'>
                <Heading as="h2" className='font-poppins'>Hi {userData?.[0]?.first_name}, <span>here’s what’s happening in your</span> portfolio</Heading>
            </Box>
            <Box mt={4} className='investor_detail_cards'>
              <CustomCard className="cardOne" title="Principal Outstanding"
                value={'₹' + portfolioData?.principal_expected_till_date.toFixed(2)} isShowIcon
                label="The principal outstanding on all the active investments till date (includes delayed repayments as well)"
              />
              <CustomCard className="cardTwo" title="Total Principal Received"
                value={'₹' + portfolioData?.total_principal_recieved_till_date.toFixed(2)} isShowIcon
                label="Total principal received across all repayments made till date (includes delayed principal as well)"
              />
              <CustomCard className="cardThree" title="Total Interest Received"
                value={'₹' + portfolioData?.total_interest_recieved_till_date.toFixed(2)} isShowIcon
                label="Total interest received across all repayments made till date (includes delayed interest as well)"
              />
              <CustomCard className="cardFour" title="Principal Expected"
                value={'₹' + portfolioData?.principal_expected_till_date.toFixed(2)} isShowIcon
                label="Total principal expected across all repayments due in the active investments across the tenure (does not include delayed principal)"
              />
              <CustomCard className="cardThree" title="Interest Expected"
                value={'₹' + portfolioData?.interest_expected_till_date.toFixed(2)} isShowIcon
                label="Total interest expected across all repayments due in the active investments across the tenure (does not include delayed interest)"
              />
            </Box>
            <InvestorStackedChart data={barChartData} options={barChartOptions} />
            <PortfolioDetails portfolioData={portfolioData} />
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          <NavBar data={userData} handleProfile={handleProfile} />
          <Box className="cardOne card_box_invest" mt={4}>
            <Box className='d-flex align-items-center col-gap-10 justify-content-end'>
              <Heading as="h2" className='font-poppins'>Net Returns Received</Heading>
                <Tooltip label='Net returns (%) on the investments for which repayments have been successfully completed till date' hasArrow fontSize='xs' placement='right' bg='gray.600'>
                  <InfoOutlineIcon />
                </Tooltip>
            </Box>
            <Heading as="h3" className='font-poppins'>{portfolioData?.return_recieved_from_all_closed_investments ? portfolioData?.return_recieved_from_all_closed_investments.toFixed(2) + '%' : '0%'}</Heading>
          </Box>
          <Box className="cardTwo card_box_invest" mt={3}>
            <Box className='d-flex align-items-center col-gap-10 justify-content-end'>
              <Heading as="h2" className='font-poppins'>Average return expected</Heading>
                <Tooltip label='Net returns (%) receivable on all the investments which have been made till date (this includes active investments as well as closed investments)' hasArrow fontSize='xs' placement='right' bg='gray.600'>
                  <InfoOutlineIcon />
                </Tooltip>
            </Box>
            <Heading as="h3" className='font-poppins'>{portfolioData?.average_return_expected_from_all_investments ? portfolioData?.average_return_expected_from_all_investments.toFixed(2) + '%' : '0%'}</Heading>
          </Box>
          <WalletBalance walletData={walletData} viewAmount={viewAmount} handleViewAmount={handleViewAmount} location={location?.pathname}
            handleAddFundsDialog={handleAddFundsDialog} handleWithdrawFundsDialog={handleOpenWithdrawFundsDialog}
            handleChangePaymentMode={handleChangePaymentMode} isKYCCompleted={isKYCCompleted}
          />
          <UpcomingRepayments portfolioData={portfolioData} />
        </GridItem>
      </Grid>

      <AddFundsDialog data={amountData} openAddFunds={openAddFunds} handleAddFundsDialog={handleAddFundsDialog}
        handleSelectAmount={handleSelectAmount} amount={amount} handleOpenPaymentDialog={handleOpenPaymentDialog}
        certificateRef={certificateRef} handleChange={handleChange} inputValues={inputValues} onChangeFile={onChangeFile}
        showOpenFileDialog={showOpenFileDialog} handleCheck={handleCheck} acknowledgeConsent={acknowledgeConsent}
        certificateConsent={certificateConsent} handleSaveDocumnent={handleSaveDocumnent} certificateData={certificateData}
        userData={userData}
      />
      <WithdrawFundsDialog handleChange={handleChange} inputValues={inputValues} openWithdrawFunds={openWithdrawFunds}
        handleCloseWithdrawFundsDialog={handleCloseWithdrawFundsDialog} walletData={walletData} handleWithdrawAmount={handleWithdrawAmount}
        handleWithdrawAll={handleWithdrawAll} isWithdrawn={isWithdrawn} handleGoToDashboard={handleGoToDashboard}
      />
      <PaymentDialog title="Add Funds" openPayModal={openPaymentModal} payableAmount={payableAmount}
        handleClosePaymentDialog={handleClosePaymentDialog} userData={userData}
      />
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(InvestorPortfolio)

