export const GET_SUMMARY_REPORT_REQUEST = 'GET_SUMMARY_REPORT_REQUEST'
export const GET_SUMMARY_REPORT_FAILURE = 'GET_SUMMARY_REPORT_FAILURE'
export const GET_SUMMARY_REPORT_SUCCESS = 'GET_SUMMARY_REPORT_SUCCESS'

export const GET_LENDER_REPORT_REQUEST = 'GET_LENDER_REPORT_REQUEST'
export const GET_LENDER_REPORT_FAILURE = 'GET_LENDER_REPORT_FAILURE'
export const GET_LENDER_REPORT_SUCCESS = 'GET_LENDER_REPORT_SUCCESS'

export const GET_BORROWER_REPORT_REQUEST = 'GET_BORROWER_REPORT_REQUEST'
export const GET_BORROWER_REPORT_FAILURE = 'GET_BORROWER_REPORT_FAILURE'
export const GET_BORROWER_REPORT_SUCCESS = 'GET_BORROWER_REPORT_SUCCESS'

export const GET_BORROWER_LOAN_REPORT_REQUEST = 'GET_BORROWER_LOAN_REPORT_REQUEST'
export const GET_BORROWER_LOAN_REPORT_FAILURE = 'GET_BORROWER_LOAN_REPORT_FAILURE'
export const GET_BORROWER_LOAN_REPORT_SUCCESS = 'GET_BORROWER_LOAN_REPORT_SUCCESS'

export const GET_BORROWER_REPAYMENT_REPORT_REQUEST = 'GET_BORROWER_REPAYMENT_REPORT_REQUEST'
export const GET_BORROWER_REPAYMENT_REPORT_FAILURE = 'GET_BORROWER_REPAYMENT_REPORT_FAILURE'
export const GET_BORROWER_REPAYMENT_REPORT_SUCCESS = 'GET_BORROWER_REPAYMENT_REPORT_SUCCESS'