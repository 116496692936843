import React from 'react'
import {Box, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import moment from 'moment';

export default function TransactionRecord(props) {

    console.log(props?.disbursalData?.loanContract[0]?.disbursal, 'props?.disbursalData')

    const data = props?.disbursalData?.loanContract[0]?.disbursal
    const filteredData = data?.filter(item => item.transaction_type === '1' || item.transaction_type === '4' || item.transaction_type === '5')

    return (
    <Box className='transaction_table'>
        <Box className='main_table' mt={4}>
            <Heading as="h4" className='font-poppins'>Disbursal Transaction</Heading>
            <Box mt={2} className='custom_table_wrapper'>
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Transaction ID</Th>
                                <Th>Transaction Type</Th>
                                <Th>Benificiary Name</Th>
                                <Th>Disbursal Amount</Th>
                                <Th>CGST(9%)</Th>
                                <Th>SGST(9%)</Th>
                                <Th>Disbursal Date</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filteredData?.map((elem, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text className="font-poppins table_text">{elem?.disbursal_txnId}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{elem?.transaction_type === '1' ? 'Disbursement' : elem?.transaction_type === '4' ? 'Processing Fee' : elem?.transaction_type === '5' ? 'Facilitation Fee' : ' '}</Text>
                                        </Td>
                                        <Td>
                                            {/* <Text className="font-poppins table_text">{elem?.benificiar_name}</Text> */}
                                            <Text className="font-poppins table_text">N/A</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {elem?.disbursal_amount.toFixed(2)}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {elem?.cgst_amount ? elem?.cgst_amount  : '0'}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {elem?.sgst_amount ? elem?.sgst_amount : '0'}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{elem?.disbursal_date ? moment(elem.disbursal_date).format("DD-MMM-YYYY") : ''}</Text>
                                        </Td>
                                        <Td>
                                            <Text className={elem?.status === 1 ? "font-poppins table_text complete_text" : "font-poppins table_text pending_text"}>
                                                {elem?.status_txt}
                                            </Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box> 
        </Box>
        <Box className='main_table' mt={5}>
            <Heading as="h4" className='font-poppins'>Payment Transaction</Heading>
            <Box mt={2} className='custom_table_wrapper'>
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Transaction ID</Th>
                                <Th>Transaction Amount</Th>
                                <Th>Interest</Th>
                                <Th>Penal Interest</Th>
                                <Th>Payment Mode</Th>
                                <Th>Transaction Date</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props?.transactionData?.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.payment_txnId}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {item?.transaction_amount}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {item?.interest_paid}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹ {item?.penel_interest}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.payment_mode}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.transaction_date ? moment(item.transaction_date).format("DD-MMM-YYYY") : ''}</Text>
                                        </Td>
                                        <Td>
                                            <Text className={item?.transaction_status_txt === 'Pending' ? "font-poppins table_text pending_text" : "font-poppins table_text complete_text"}>
                                                {item?.transaction_status_txt}
                                            </Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box> 
        </Box>
    </Box>
  )
}
