/* eslint-disable no-unused-vars */
import * as cpServices from '../../services/cpServices'
import * as actionTypes from './actionsTypes'

 //actions for get cp dashboard data
 const getCPDashboardDetailsRequest = () => {
  return {
    type: actionTypes.GET_CP_DASHBOARD_DETAILS_REQUEST,
  }
}

const getCPDashboardDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_DASHBOARD_DETAILS_SUCCESS,
    payload: data,
  }
}

const getCPDashboardDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_CP_DASHBOARD_DETAILS_FAILURE,
    payload: error,
  }
}

//getCPDashboardDetails action
export const getCPDashboardDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPDashboardDetailsRequest())
    try {
      const responseData = await cpServices.getCPDashboardDetails(payload)
      if (responseData?.status === 200) {
        dispatch(getCPDashboardDetailsSuccess(responseData?.data))
      } else {
        dispatch(getCPDashboardDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPDashboardDetailsFailure(error?.response?.data))
    }
  }

//actions for get cp users
const getCPUsersRequest = () => {
  return {
    type: actionTypes.GET_CP_USERS_REQUEST,
  }
}

const getCPUsersSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_USERS_SUCCESS,
    payload: data,
  }
}

const getCPUsersFailure = (error) => {
  return {
    type: actionTypes.GET_CP_USERS_FAILURE,
    payload: error,
  }
}

//getCPUsers action
export const getCPUsersAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPUsersRequest())
    try {
      const responseData = await cpServices.getCPUsers(payload)
      if (responseData?.status === 200) {
        dispatch(getCPUsersSuccess(responseData?.data))
      } else {
        dispatch(getCPUsersFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPUsersFailure(error?.response?.data))
    }
  }

  //actions for get cp payouts
const getCPPayoutRequest = () => {
  return {
    type: actionTypes.GET_CP_PAYOUT_REQUEST,
  }
}

const getCPPayoutSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_PAYOUT_SUCCESS,
    payload: data,
  }
}

const getCPPayoutFailure = (error) => {
  return {
    type: actionTypes.GET_CP_PAYOUT_FAILURE,
    payload: error,
  }
}

//getCPPayout action
export const getCPPayoutAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPPayoutRequest())
    try {
      const responseData = await cpServices.getCPPayout(payload)
      if (responseData?.status === 200) {
        dispatch(getCPPayoutSuccess(responseData?.data))
      } else {
        dispatch(getCPPayoutFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPPayoutFailure(error?.response?.data))
    }
  }

  //actions for get cp user details by id
  const getCPUserDetailsByIdRequest = () => {
    return {
      type: actionTypes.GET_CP_USER_BY_ID_REQUEST,
    }
  }
  
  const getCPUserDetailsByIdSuccess = (data) => {
    return {
      type: actionTypes.GET_CP_USER_BY_ID_SUCCESS,
      payload: data,
    }
  }
  
  const getCPUserDetailsByIdFailure = (error) => {
    return {
      type: actionTypes.GET_CP_USER_BY_ID_FAILURE,
      payload: error,
    }
  }
  
  //getCPUserDetailsById action
export const getCPUserDetailsByIdAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPUserDetailsByIdRequest())
    try {
      const responseData = await cpServices.getCPUserDetailById(payload)
      if (responseData?.status === 200) {
        dispatch(getCPUserDetailsByIdSuccess(responseData?.data))
      } else {
        dispatch(getCPUserDetailsByIdFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPUserDetailsByIdFailure(error?.response?.data))
    }
  }

 //actions for create digi locker
 const createCPDigiLockerRequest = () => {
  return {
    type: actionTypes.CREATE_CP_DIGILOCKER_REQUEST,
  }
}

const createCPDigiLockerSuccess = (data) => {
  return {
    type: actionTypes.CREATE_CP_DIGILOCKER_SUCCESS,
    payload: data,
  }
}

const createCPDigiLockerFailure = (error) => {
  return {
    type: actionTypes.CREATE_CP_DIGILOCKER_FAILURE,
    payload: error,
  }
}

//createDigiLocker action
export const createCPDigiLockerAction = (payload) =>
  async(dispatch) => {
    dispatch(createCPDigiLockerRequest())
    try {
      const responseData = await cpServices.createCPDigiLocker(payload)
      if (responseData?.status === 200) {
        dispatch(createCPDigiLockerSuccess(responseData?.data))
      } else {
        dispatch(createCPDigiLockerFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createCPDigiLockerFailure(error?.response?.data))
    }
  }

//actions for download aadhar data
const getDownloadCPAadharDataRequest = () => {
  return {
    type: actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_REQUEST,
  }
}

const getDownloadCPAadharDataSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_SUCCESS,
    payload: data,
  }
}

const getDownloadCPAadharDataFailure = (error) => {
  return {
    type: actionTypes.GET_CP_DOWNLOAD_AADHAR_DATA_FAILURE,
    payload: error,
  }
}

//getDownloadCPAadharData action
export const getDownloadCPAadharDataAction = (payload, userByIdData) =>
  async(dispatch) => {
    dispatch(getDownloadCPAadharDataRequest())
    try {
      const responseData = await cpServices.getdownloadCPAadhaarData(payload)
      if (responseData?.status === 200) {
        dispatch(getDownloadCPAadharDataSuccess(responseData?.data))
        dispatch(getCPUserDetailsByIdAction(userByIdData))
      } else {
        dispatch(getDownloadCPAadharDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getDownloadCPAadharDataFailure(error?.response?.data))
    }
  }

//actions for save bak details
const saveCPBankDetailsRequest = () => {
  return {
    type: actionTypes.SAVE_CP_BANK_DETAILS_REQUEST,
  }
}

const saveCPBankDetailsSuccess = (data) => {
  return {
    type: actionTypes.SAVE_CP_BANK_DETAILS_SUCCESS,
    payload: data,
  }
}

const saveCPBankDetailsFailure = (error) => {
  return {
    type: actionTypes.SAVE_CP_BANK_DETAILS_FAILURE,
    payload: error,
  }
}

//saveCPBankDetails action
export const saveCPBankDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(saveCPBankDetailsRequest())
    try {
      const responseData = await cpServices.saveCPBankDetails(payload)
      if (responseData?.status === 200) {
        dispatch(saveCPBankDetailsSuccess(responseData?.data))
      } else {
        dispatch(saveCPBankDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(saveCPBankDetailsFailure(error?.response?.data))
    }
  }

//actions for generate order
const generateCPOrderRequest = () => {
  return {
    type: actionTypes.GENERATE_CP_ORDER_REQUEST,
  }
}

const generateCPOrderSuccess = (data) => {
  return {
    type: actionTypes.GENERATE_CP_ORDER_SUCCESS,
    payload: data,
  }
}

const generateCPOrderFailure = (error) => {
  return {
    type: actionTypes.GENERATE_CP_ORDER_FAILURE,
    payload: error,
  }
}

//generateCPOrder action
export const generateCPOrderAction = (payload) =>
  async(dispatch) => {
    dispatch(generateCPOrderRequest())
    try {
      const responseData = await cpServices.generateCPOrder(payload)
      if (responseData?.status === 200) {
        dispatch(generateCPOrderSuccess(responseData?.data))
      } else {
        dispatch(generateCPOrderFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(generateCPOrderFailure(error?.response?.data))
    }
  }

  //actions for get payment status
 const getCPPaymentStatusRequest = () => {
  return {
    type: actionTypes.GET_CP_PAYMENT_STATUS_REQUEST,
  }
}

const getCPPaymentStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_PAYMENT_STATUS_SUCCESS,
    payload: data,
  }
}

const getCPPaymentStatusFailure = (error) => {
  return {
    type: actionTypes.GET_CP_PAYMENT_STATUS_FAILURE,
    payload: error,
  }
}

//getCPPaymentStatus action
export const getCPPaymentStatusAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPPaymentStatusRequest())
    try {
      const responseData = await cpServices.getCPPaymentStatus(payload)
      if (responseData?.status === 200) {
        dispatch(getCPPaymentStatusSuccess(responseData?.data))
      } else {
        dispatch(getCPPaymentStatusFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPPaymentStatusFailure(error?.response?.data))
    }
  }

//actions for update order status
 const updateCPOrderStatusRequest = () => {
  return {
    type: actionTypes.UPDATE_CP_ORDER_STATUS_REQUEST,
  }
}

const updateCPOrderStatusSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_CP_ORDER_STATUS_SUCCESS,
    payload: data,
  }
}

const updateCPOrderStatusFailure = (error) => {
  return {
    type: actionTypes.UPDATE_CP_ORDER_STATUS_FAILURE,
    payload: error,
  }
}

//updateCPOrderStatus action
export const updateCPOrderStatusAction = (payload) =>
  async(dispatch) => {
    dispatch(updateCPOrderStatusRequest())
    try {
      const responseData = await cpServices.updateCPOrderStatus(payload)
      if (responseData?.status === 200) {
        dispatch(updateCPOrderStatusSuccess(responseData?.data))
      } else {
        dispatch(updateCPOrderStatusFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(updateCPOrderStatusFailure(error?.response?.data))
    }
  }

//actions for post create loan cp
const createLoanRequest = () => {
  return {
    type: actionTypes.CREATE_LOAN_REQUEST,
  }
}

const createLoanSuccess = (data) => {
  return {
    type: actionTypes.CREATE_LOAN_SUCCESS,
    payload: data,
  }
}

const createLoanFailure = (error) => {
  return {
    type: actionTypes.CREATE_LOAN_FAILURE,
    payload: error,
  }
}

//createLoan action
export const createLoanAction = (payload) =>
  async(dispatch) => {
    dispatch(createLoanRequest())
    try {
      const responseData = await cpServices.createLoan(payload)
      if (responseData?.status === 200) {
        dispatch(createLoanSuccess(responseData?.data))
      } else {
        dispatch(createLoanFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createLoanFailure(error?.response?.data))
    }
  }

//actions for get cp loan list
const cpLoanListRequest = () => {
  return {
    type: actionTypes.CP_LOAN_LIST_REQUEST,
  }
}

const cpLoanListSuccess = (data) => {
  return {
    type: actionTypes.CP_LOAN_LIST_SUCCESS,
    payload: data,
  }
}

const cpLoanListFailure = (error) => {
  return {
    type: actionTypes.CP_LOAN_LIST_FAILURE,
    payload: error,
  }
}

//cpLoanList action
export const cpLoanListAction = (payload) =>
  async(dispatch) => {
    dispatch(cpLoanListRequest())
    try {
      const responseData = await cpServices.cpLoanList(payload)
      if (responseData?.status === 200) {
        dispatch(cpLoanListSuccess(responseData?.data))
      } else {
        dispatch(cpLoanListFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(cpLoanListFailure(error?.response?.data))
    }
  }

//actions for create cp esign request
const createCPESignRequest = () => {
  return {
    type: actionTypes.CREATE_CP_ESIGN_REQUEST,
  }
}

const createCPESignSuccess = (data) => {
  return {
    type: actionTypes.CREATE_CP_ESIGN_SUCCESS,
    payload: data,
  }
}

const createCPESignFailure = (error) => {
  return {
    type: actionTypes.CREATE_CP_ESIGN_FAILURE,
    payload: error,
  }
}

//createCPESign action
export const createCPESignAction = (payload) =>
  async(dispatch) => {
    dispatch(createCPESignRequest())
    try {
      const responseData = await cpServices.createCPESignRequest(payload)
      if (responseData?.status === 200) {
        dispatch(createCPESignSuccess(responseData?.data))
      } else {
        dispatch(createCPESignFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createCPESignFailure(error?.response?.data))
    }
  }

//actions for get invoice
const getInvoiceRequest = () => {
  return {
    type: actionTypes.GET_INVOICE_REQUEST,
  }
}

const getInvoiceSuccess = (data) => {
  return {
    type: actionTypes.GET_INVOICE_SUCCESS,
    payload: data,
  }
}

const getInvoiceFailure = (error) => {
  return {
    type: actionTypes.GET_INVOICE_FAILURE,
    payload: error,
  }
}

//getInvoice action
export const getInvoiceAction = (payload) =>
  async(dispatch) => {
    dispatch(getInvoiceRequest())
    try {
      const responseData = await cpServices.getInvoice(payload)
      if (responseData?.status === 200) {
        dispatch(getInvoiceSuccess(responseData?.data))
      } else {
        dispatch(getInvoiceFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getInvoiceFailure(error?.response?.data))
    }
  }

  //actions for acceprt loan eligibility
const acceptCPLoanEligibilityRequest = () => {
  return {
    type: actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_REQUEST,
  }
}

const acceptCPLoanEligibilitySuccess = (data) => {
  return {
    type: actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_SUCCESS,
    payload: data,
  }
}

const acceptCPLoanEligibilityFailure = (error) => {
  return {
    type: actionTypes.ACCEPT_CP_LOAN_ELIGIBILITY_FAILURE,
    payload: error,
  }
}

//acceptCPLoanEligibility action
export const acceptCPLoanEligibilityAction = (payload) =>
  async(dispatch) => {
    dispatch(acceptCPLoanEligibilityRequest())
    try {
      const responseData = await cpServices.acceptCPLoanEligibility(payload)
      if (responseData?.status === 200) {
        dispatch(acceptCPLoanEligibilitySuccess(responseData?.data))
      } else {
        dispatch(acceptCPLoanEligibilityFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(acceptCPLoanEligibilityFailure(error?.response?.data))
    }
  }

//actions for withdrawn loan eligibility
const withdrawnCPLoanEligibilityRequest = () => {
  return {
    type: actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_REQUEST,
  }
}

const withdrawnCPLoanEligibilitySuccess = (data) => {
  return {
    type: actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_SUCCESS,
    payload: data,
  }
}

const withdrawnCPLoanEligibilityFailure = (error) => {
  return {
    type: actionTypes.WITHDRAWN_CP_LOAN_ELIGIBILITY_FAILURE,
    payload: error,
  }
}

//withdrawnCPLoanEligibility action
export const withdrawnCPLoanEligibilityAction = (payload) =>
  async(dispatch) => {
    dispatch(withdrawnCPLoanEligibilityRequest())
    try {
      const responseData = await cpServices.withdrawnCPLoanEligibility(payload)
      if (responseData?.status === 200) {
        dispatch(withdrawnCPLoanEligibilitySuccess(responseData?.data))
      } else {
        dispatch(withdrawnCPLoanEligibilityFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(withdrawnCPLoanEligibilityFailure(error?.response?.data))
    }
  }

  // cleanUp esign  data
const cleanUp = () => {
  return {
    type: actionTypes.CLEANUP_ESIGN_DATA,
    payload: undefined,
  }
}
export const cleanEsignRequestData = () =>
  async(dispatch) => {
    dispatch(cleanUp())
}