import React from 'react'
import { Box, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment'

export default function LoanEligibleDialog(props) {

    const modalType = props.type
    const data = props?.loanListStatusData?.[0]

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="confirm_title font-poppins">
                    {modalType === 'apply' ? 'Accept This Loan Application' : 'Are You Sure Want To Withdawn this Loan Application?'}
                </ModalHeader>
                <ModalBody>
                    {modalType === 'apply' ?
                        <Box mb={7} className='accept_data'>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Id</Heading>
                                <Text className='font-poppins'>{data?.loan_number}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                <Text className='font-poppins'>{data?.products?.name}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                <Text className='font-poppins'>₹{data?.eligible_loan_amt}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                <Text className='font-poppins'>{data?.loan_type === '2' ? data?.eligible_loan_tenure + ' Days' : data?.eligible_loan_tenure + ' Months'}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                <Text className='font-poppins'>{data?.eligible_loan_interest}%</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>{data?.loan_type === '2' ? 'Repayment Amount' : 'Emi Amount'}</Heading>
                                <Text className='font-poppins'>₹{data?.eligible_loan_emi}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Date</Heading>
                                <Text className='font-poppins'>{moment(data?.created_date).format('DD-MMM-YYYY')}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Alpha Score</Heading>
                                <Text className='font-poppins'>{data?.alpha_rating ? data?.alpha_rating : 'N/A'}</Text>
                            </Box>
                            <Box className='loan_data_box d-flex align-items-center justify-content-between text-right'>
                                <Box>
                                    <Heading as="h6" className='font-poppins'>Loan Processing Fees</Heading>
                                    <Heading as="h5" className='font-poppins note_text'>(with 18% GST)</Heading>
                                </Box>
                                <Box>
                                    <Text className='font-poppins'>₹{data?.loan_processing_fee_with_gst}</Text>
                                    <Heading as="h5" className='font-poppins note_text'>(including GST of ₹{data?.loan_processing_fee_amount_gst?.toFixed(2)})</Heading>
                                </Box>
                            </Box>
                            <Box className='fees_note'>
                                <Text className='font-poppins'>
                                    A loan processing fee of {data?.loan_processing_fee}% + GST and platform facilitation fee of INR {data?.facilitation_fee} + GST will be deducted from the disbursed amount.
                                </Text>
                            </Box>
                        </Box> : null
                    }
                    <Box mb={5} className='d-flex align-items-center justify-content-center col-gap-15'>
                        {modalType === 'apply' ?
                            <CustomButton size="md" type="submit" title="Apply" className="btn_confirm font-poppins" onClick={() => props.handleApprove(data?.loan_number)} /> :
                            <CustomButton size="md" type="submit" title="Withdrawn" className="btn_confirm font-poppins" onClick={() => props.handleWithdrawn(data?.loan_number)} />
                        }
                        <CustomButton size="md" type="submit" title="Cancel" className="btn_cancel font-poppins" onClick={props.handleConfirmation} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleConfirmation}
        isOpen={props.openConfirmation}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
