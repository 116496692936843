import axios from "axios"
import apiInstance from "../config/api/axios"
import { EQUIFAXURL } from "../constants/Constant"
import { getItem } from "../utilities/authUtils"

export const getUserDashboardDetails = async(payload) => {
  const response = await apiInstance.get('/user-dashboard-details', payload)
  return response
}

export const getBorrowerDetails = async(payload) => {
  const response = await apiInstance.get('/v2/getBorrowerDetails', payload)
  return response
}

export const getProductList = async(payload) => {
  const response = await apiInstance.get('/getProductList', payload)
  return response
}

export const applyLoan = async(payload) => {
  const response = await apiInstance.post('/v2/create-loan', payload)
  return response
}

export const getLoanList = async(payload) => {
  const response = await apiInstance.get(`/lmsloanlist?status=${payload.status}`, payload)
    return response
}

export const getAmmortizationSchedule = async(payload) => {
  const response = await apiInstance.post('getLoanContractAmmortizationSchedule', payload)
  return response
}

export const getLoanPaymentTransaction = async(payload) => {
  const response = await apiInstance.post('loanContractPaymentTransaction', payload)
  return response
}

export const getLoanListStatus = async(payload) => {
  const response = await apiInstance.get(`borrower/loanlist?statuses=${payload.status}`, payload)
  return response
}

export const getBorrowerCreditData = async(payload) => {
  // const token = getItem('header_token')
  // const config = {
  //   headers: {
  //     accept: 'application/json',
  //     Authorization: `Bearer ${token}`,
  //   },
  // }
  // const response = await axios.get(`${EQUIFAXURL}${'equifax/getEquifaxDataByYearlyUserId/'}${payload.id}`, config)
  const response = await apiInstance.get(`/v2/equifax/getEquifaxDataByYearlyUserId?userId=${payload.id}`, payload)
  return response
}

export const createESignRequest = async(payload) => {
  const response = await apiInstance.post('/borrower/create-esign-request', payload)
  return response
}

export const acceptLoanEligibility = async(payload) => {
  const response = await apiInstance.post('/borrower/accept-eligible-loan', payload)
  return response
}

export const withdrawnLoanEligibility = async(payload) => {
  const response = await apiInstance.post('/borrower/withdraw-loan', payload)
  return response
}

export const trackLoanJourney = async(payload) => {
  const response = await apiInstance.get(`/v2/track-loan-journey?loan_id=${payload.loanId}`, payload)
  return response
}