import React from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Flex, Heading, IconButton, Image, Input, Modal, ModalBody, ModalContent, ModalOverlay, Tooltip } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import WaitIcon from '../../../../../assets/images/wait.svg'

export default function AddFundsDialog(props) {
    
    const maxAmount = 1000000
    const walletAmount = props?.userData?.[0]?.wallet_balance
    const totalPledgedAmount = props?.userData?.[0]?.total_pledged_amount

    let amount = props?.inputValues?.lending_amount
    amount = amount?.replace(/,/g, '')
    let lendingAmount = parseFloat(amount) || 0

    const sumAmount = lendingAmount + totalPledgedAmount + parseFloat(walletAmount)
    const isAmountExceeded = sumAmount >= maxAmount;
    const isDocumentPending = props?.userData?.[0]?.document_approval?.is_approved === 0 && props?.userData?.[0]?.document_approval?.document_url;
    const isDocumentApproved = props?.userData?.[0]?.document_approval?.is_approved === 1;

    const PopupBox = () => (
        <Box className='wallet_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-start col-gap-15 preference_header'>
                    <IconButton onClick={props.handleAddFundsDialog}><ArrowBackIcon /></IconButton>
                    <Heading as="h3" className="font-poppins">Add Funds</Heading>
                </Box>
                <ModalBody className='wallet_body'>
                    <Heading as="h5" className='font-poppins'>Please Select Or Enter amount to add</Heading>
                    <Box className='add_funds_box' mt={1}>
                        <Box pt={2} pb={3}>
                            <Box className='amount_input_container'>
                                <Box className='amount_input_box' mb={1}>
                                    <Input
                                        type="text"
                                        size="md"
                                        name="lending_amount"
                                        placeholder={"₹ 0.00"}
                                        onChange={props.handleChange}
                                        className="amount_input color-dark font-poppins"
                                        value={props?.inputValues?.lending_amount ?? ''}
                                    />
                                </Box>
                                <Heading as="h5" className='font-poppins'>Lending Amount</Heading>
                            </Box> 
                        </Box>
                        <Box className='amount_select_box' pt={5} pb={2}>
                            {props?.data?.map((item, index) => {
                                return (
                                <Box key={index} onClick={() => props.handleSelectAmount(item)}
                                    className={props?.amount?.id === item.id ? 'amount_card amount_card_active' : 'amount_card amount_card_normal'} >
                                    <Heading as="h6" className="font-poppins">₹ {item.name}</Heading>
                                </Box>
                                )
                            })}
                        </Box>
                        <Box>
                            <Flex mt={3} mb={3} className="column_gap declaration_note funds_info">
                                <Checkbox onChange={(e) => props?.handleCheck(e, 'acknowledge')} isChecked={props?.acknowledgeConsent} />
                                <Heading as="p" className="font-poppins">I hereby acknowledge that my total
                                    <Tooltip label='I hereby acknowledge that my total accumulated investment across all P2P platforms is capped at INR 50,00,000 by RBI and if I wish to invest more than INR 10,00,000 , a certificate from a practising Chartered Accountant certifying minimum net-worth of INR 50,00,000 will be required.' hasArrow fontSize='xs' placement='right-end'>
                                        Read More
                                    </Tooltip>
                                </Heading>
                            </Flex>
                                {isAmountExceeded ?
                                    <>
                                        {isDocumentPending ?
                                            <Box mt={3} mb={3} className='pending_upload d-flex align-items-center col-gap-10'>
                                                <Image src={WaitIcon} />
                                                <Heading as="p" className="font-poppins">Your net worth certificate is under review and will take 24 hours. Meanwhile, you can add up to 10 lakh to your wallet & Start Investment</Heading>
                                            </Box> :
                                        isDocumentApproved ?
                                            null :
                                            <Flex mt={3} mb={3} className="column_gap declaration_note">
                                                <Checkbox onChange={(e) => props?.handleCheck(e, 'certificate')} isChecked={props?.certificateConsent} />
                                                <Heading as="p" className="font-poppins">I confirm that my networth upto ₹ 50 Lakhs (or more), and I will upload CA certified networth certificate 
                                                    <span className={props?.certificateConsent ? 'certificate_info_active' : 'certificate_info'} 
                                                        onClick={props?.certificateConsent ? () => props.showOpenFileDialog() : null}>
                                                        {props?.certificateData ? props?.certificateData?.name : '(Upload Certificate)' }
                                                    </span>
                                                </Heading>
                                                <Input
                                                    size="md"
                                                    name="upload"
                                                    type="file"
                                                    accept="image/*,.pdf"
                                                    onChange={(e) => props.onChangeFile(e)}
                                                    className="d-none"
                                                    ref={props.certificateRef}
                                                />
                                            </Flex>
                                        }
                                    </> : null
                                }
                        </Box>
                        {isAmountExceeded ?
                            <>
                                {isDocumentPending ?
                                    <CustomButton size="md" type="submit" title="Continue" className="font-poppins btn_theme w-100"
                                    isDisabled={true} /> :
                                isDocumentApproved ?
                                <CustomButton size="md" type="submit" title="Continue" className="font-poppins btn_theme w-100"
                                    onClick={() => props.handleOpenPaymentDialog(props?.inputValues?.lending_amount)} isDisabled={!props?.acknowledgeConsent}
                                /> :
                                <CustomButton size="md" type="submit" title="Upload Document" className="font-poppins btn_theme w-100"
                                    onClick={() => props.handleSaveDocumnent()} isDisabled={!(props?.acknowledgeConsent && props?.certificateConsent)}
                                /> 
                                }
                            </> :
                            <CustomButton size="md" type="submit" title="Continue" className="font-poppins btn_theme w-100"
                                onClick={() => props.handleOpenPaymentDialog(props?.inputValues?.lending_amount)} isDisabled={!props?.acknowledgeConsent}
                            />
                        }
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleAddFundsDialog}
        isOpen={props.openAddFunds}
        motionPreset="slideInBottom"
        size={'sm'}
    >
    {PopupBox()}
    </Modal>
  )
}
