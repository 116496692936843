export function downloadDocuments(url, fileName) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));
}

export function detectFileType (file) {
    if (!file) return 'image'; // Default to image if file is undefined or null
    const fileExtensions = ['.jpeg', '.jpg', '.png', '.pdf'];
    const extension = fileExtensions.find((ext) => file.includes(ext))
    if (extension === '.pdf') {
        return 'pdf';
    } else {
        return 'image';
    }
}

export function downloadDocumentReport (fileUrl) {
    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()
  }