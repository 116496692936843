import React from 'react'
import {Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import moment from 'moment';

export default function AmortizationSchedule(props) {

  return (
    <Box mt={4} className='custom_table_wrapper'>
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Transaction ID</Th>
                        <Th>Opening Principles Amount</Th>
                        <Th>EMI Amount</Th>
                        <Th>Principal Amount</Th>
                        <Th>Interest Amount</Th>
                        <Th>Principal Outstanding</Th>
                        <Th>EMI Date</Th>
                        <Th>EMI Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.ammortizationData?.map((item, index) => {
                        return (
                            <Tr key={index}>
                                <Td>
                                    <Text className="font-poppins table_text">{item?.amortization_txnID}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item?.openingPrincipal.toFixed(2)}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item?.emiAmount}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item?.principalAmount.toFixed(2)}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item?.interestAmount}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item?.principalOutstanding.toFixed(2)}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item?.emi_date ? moment(item.emi_date).format("DD-MMM-YYYY") : ''}</Text>
                                </Td>
                                <Td>
                                    <Text className={`font-poppins table_text ${item?.status === '2' ? 'paid' : 'not-paid'}`}>{item?.status === '0' ? 'Not Paid' : item?.status === '2' ? 'Paid' : 'Partial Paid'}</Text>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    </Box> 
  )
}
