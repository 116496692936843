import React, {useState} from 'react'
import { Box, Image, Heading, Link, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
// import { Link } from 'react-router-dom'
import ReferIcon from '../../../../assets/images/refer.svg'
import TermsIcon from '../../../../assets/images/termsCondition.svg'
import FaqsIcon from '../../../../assets/images/faq.svg'
import HelpIcon from '../../../../assets/images/helps.svg'
import AboutIcon from '../../../../assets/images/aboutUs.svg'
import SettingIcon from '../../../../assets/images/settings.svg'
import LogoutIcon from '../../../../assets/images/logout.svg'
import PinIcon from '../../../../assets/images/pin.svg'
import DeleteIcon from '../../../../assets/images/delete.svg'
import { logoutUser } from '../../../../services/masterServices';
import * as routesNames from '../../../../constants/routes'
import {useHistory} from 'react-router-dom'
import { removeAll } from '../../../../utilities/authUtils';
import Loader from '../../../elements/loader';
import CustomToast from '../../../elements/customToast';

export default function ProfileSidebar(props) {

    const {addToast} = CustomToast()
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    const handleLogout = () => {
        setIsLoading(true)
        logoutUser().then((res) => {
            setIsLoading(false)
            if (res?.data?.success === true && res?.data?.status === true) {
              addToast({ message: res?.data?.message, status: 'success' })
              removeAll()
              history.push(routesNames.LOGIN)
            } else if (res?.data?.status === false) {
              addToast({message: res?.data?.message, status: 'error'})
            }
          }).catch((error) => {
            addToast({message: 'Something Went Wrong', status: 'error'})
            setIsLoading(false)
        })
    }

  return (
    <>
        {isLoading &&
            <div>
                <Loader />
            </div>
        }
        <Box mt={5} className='profile_sidebar sticky_block bg_shadow'>
            <Box className="profile_menu_items">
                <Link><Image src={ReferIcon} />Refer and Earn</Link>
            </Box>
            <Box className="profile_menu_items">
                <Link href='https://www.alphamoney.in/terms-of-use' isExternal><Image src={TermsIcon} />T&C</Link>
            </Box> 
            <Box className="profile_menu_items">
                <Link href='https://www.alphamoney.in/FAQ' isExternal><Image src={FaqsIcon} />FAQs</Link>
            </Box>        
            <Box className="profile_menu_items">
                <Link href='https://www.alphamoney.in/contactus' isExternal><Image src={HelpIcon} />Help</Link>
            </Box> 
            <Box className="profile_menu_items">
                <Link href='https://www.alphamoney.in/about-us' isExternal><Image src={AboutIcon} />About Us</Link>
            </Box> 
            <Box className="profile_menu_items">
                <Box className="has_dropdown" onClick={handleOpenMenu}>
                    <Image src={SettingIcon} />Settings
                    {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Box>
                {isOpen ?
                    <Box className="group_dropdown">
                        <Box className="profile_menu_items">
                            <Box className='set_pin_block' onClick={props.handleOpenSetPin}>
                                <Image src={PinIcon} />
                                <Box className='pin_change'>
                                    <Heading as="h6" className='font-poppins'>ALPHAMONEY PIN</Heading>
                                    <Text className="font-poppins">Change PIN</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="profile_menu_items">
                            <Link onClick={props.handleOpenDeleteAccount}><Image src={DeleteIcon} />Delete My Account</Link>
                        </Box>  
                    </Box> : null
                }
            </Box> 
            <Box className="profile_menu_items">
                <Box className="logout_link" onClick={handleLogout}><Image src={LogoutIcon} />Logout</Box>
            </Box>
            <Box className="app_info">
                <Heading as="p" className="font-poppins">version 1.0</Heading>
                <Heading as="h4" className="font-poppins">Made With <span>♥️</span> In India</Heading>
            </Box>
        </Box>
    </>
  )
}
