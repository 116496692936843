import React, {useState} from 'react'
import { Box, Button, IconButton, Menu, MenuButton, Image, MenuList, MenuItem, Heading, Select } from '@chakra-ui/react'
import BellIcon from '../../assets/images/bellIcon.svg'
import TermsIcon from '../../assets/images/termsCondition.svg'
import FaqsIcon from '../../assets/images/faq.svg'
import HelpIcon from '../../assets/images/helps.svg'
import LogoutIcon from '../../assets/images/logout.svg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import UserIcon from "../../assets/images/userIcon.svg";
import CustomToast from './customToast'
import Loader from './loader'
import * as routesNames from '../../constants/routes'
import {useHistory} from 'react-router-dom'
import { removeAll } from '../../utilities/authUtils'
import { logoutUser } from '../../services/masterServices';

export default function NavBar(props) {

  const {addToast} = CustomToast()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = () => {
    // props.onClick()
    setIsLoading(true)
    logoutUser().then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true && res?.data?.status === true) {
        addToast({ message: res?.data?.message, status: 'success' })
        removeAll()
        history.push(routesNames.LOGIN)
      } else if (res?.data?.status === false) {
        addToast({message: res?.data?.message, status: 'error'})
      }
    }).catch((error) => {
      addToast({message: 'Something Went Wrong', status: 'error'})
      setIsLoading(false)
    })
  }

  return (
  <>
    {isLoading &&
      <div>
        <Loader />
      </div>
    }
    <Box className="nav_flex bg_shadow">
      <Box className="notifications">
        <IconButton className='bell_icon'>
          <Image src={BellIcon} />
        </IconButton>
        <Select placeholder="En" variant="filled" className="font-poppins">
          <option>En</option>
          <option>Fr</option>
        </Select>
      </Box>
      {props.isAdmin ?
        <Menu>
          <Box className="profile_menu">
            {props?.isSales || props?.isCP ?
              <Heading as="h5" className='font-poppins'>{props?.data?.full_name}</Heading> :
              <Heading as="h5" className='font-poppins'>{props?.data?.first_name}{' '}{props?.data?.middle_name}</Heading>
            }
            <MenuButton as={Button} leftIcon={<Image src={props?.data?.photo ? props?.data?.photo : UserIcon} className="profile_image" />}>
                <ChevronDownIcon />
            </MenuButton>
          </Box>
          <MenuList className="dropdown_list">
            <Box className='profile_info'>
              <Image src={props?.data?.photo ? props?.data?.photo : UserIcon} className="profile_image" />
              <Box className='user_profile'>
                {props?.isSales || props?.isCP ?
                  <Heading as="h3" className='font-poppins'>{props?.data?.full_name}</Heading> :
                  <Heading as="h3" className='font-poppins'>{props?.data?.first_name}{' '}{props?.data?.middle_name}</Heading>
                }
                <Heading as="h5" className='font-poppins'>{props?.data?.mobile}</Heading>
                <Heading as="h5" className='font-poppins'>{props?.data?.email}</Heading>
              </Box>
            </Box>
            <MenuItem as='a' href='https://www.alphamoney.in/terms-of-use' target="_blank" icon={<Image src={TermsIcon} />} className="font-poppins">T&C</MenuItem>
            <MenuItem as='a' href='https://www.alphamoney.in/FAQ' target="_blank" icon={<Image src={FaqsIcon} />} className="font-poppins">FAQs</MenuItem>
            <MenuItem as='a' href='https://www.alphamoney.in/contactus' target="_blank" icon={<Image src={HelpIcon} />} className="font-poppins">Help</MenuItem>
            <MenuItem icon={<Image src={LogoutIcon} />} className="font-poppins" onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu> :
        <Menu>
        <Box className="profile_menu">
          <Heading as="h5" className='font-poppins'>{props?.data?.[0]?.first_name}</Heading>
          <MenuButton as={Button} leftIcon={<Image src={props?.data?.[0]?.photo ? props?.data?.[0]?.photo : UserIcon} className="profile_image" />}>
              <ChevronDownIcon />
          </MenuButton>
          </Box>
          <MenuList className="dropdown_list">
            <Box className='profile_info' onClick={props.handleProfile}>
              <Image src={props?.data?.[0]?.photo ? props?.data?.[0]?.photo : UserIcon} className="profile_image" />
              <Box className='user_profile'>
                <Heading as="h3" className='font-poppins'>{props?.data?.[0]?.first_name}{' '}{props?.data?.[0]?.last_name}</Heading>
                <Heading as="h5" className='font-poppins'>{props?.data?.[0]?.mobile}</Heading>
                <Heading as="h5" className='font-poppins'>{props?.data?.[0]?.email}</Heading>
              </Box>
            </Box>
            <MenuItem as='a' href='https://www.alphamoney.in/terms-of-use' target="_blank" icon={<Image src={TermsIcon} />} className="font-poppins">T&C</MenuItem>
            <MenuItem as='a' href='https://www.alphamoney.in/FAQ' target="_blank" icon={<Image src={FaqsIcon} />} className="font-poppins">FAQs</MenuItem>
            <MenuItem as='a' href='https://www.alphamoney.in/contactus' target="_blank" icon={<Image src={HelpIcon} />} className="font-poppins">Help</MenuItem>
            <MenuItem icon={<Image src={LogoutIcon} />} className="font-poppins" onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      }
    </Box>
  </>
  )
}
