import axios from 'axios'
import apiInstance from '../config/api/axios'
import { IFSCURL } from '../constants/Constant'

export const getCurrentUserDetails = async(payload) => {
  const response = await apiInstance.get('/common/get-current-user-detail', payload)
  
  return response
}

export const verifyPanCard = async(payload) => {
  const response = await apiInstance.post('/verifyPancard', payload)
  return response
}

export const searchPinCode = async(payload) => {
  const response = await apiInstance.get(`/common/search-pincode?pincode=${payload.pincode}`, payload)
  return response
}

export const getIFSCCode = async(data) => {
  const response = await axios.get(IFSCURL + data)
  return response
}

export const verifyBankAccount = async(payload) => {
  const response = await apiInstance.post('/bankVerify', payload)
  return response
}

export const getDropdownData = async(payload) => {
  const response = await apiInstance.get(`/v2/common/get-dropdown?type=${payload.type}&platform=${payload.platform}`, payload)
  return response
}

export const getCreditScore = async(payload) => {
  const response = await apiInstance.post('/admin/credit-score', payload)
  return response
}

export const uploadProfilePhoto = async(payload) => {
  const response = await apiInstance.post('/common/update-profile-pic', payload)
  return response
}

export const uploadNetworthCertificate = async(payload) => {
  const response = await apiInstance.post('/uploadNetworthCertificate', payload)
  return response
}

export const uploadCKYCDocument = async(payload) => {
  const response = await apiInstance.post('/v2/ckyc/uploadZip', payload)
  return response
}

export const sendH2HFile = async(payload) => {
  const response = await apiInstance.post('/common/sendH2hFile', payload)
  return response
}

export const logoutUser = async(payload) => {
  const response = await apiInstance.post('/logout', payload)
  return response
}

export const getVersion = async(payload) => {
  const response = await apiInstance.get('/v2/common/get-version', payload)
  return response
}