import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import Pagination from '../../../elements/Pagination'
import NoData from '../../../elements/NoData'
import moment from 'moment'

export default function RepaymentDetailsTable(props) {

    const reportFile = props?.fileUrl
    const reportItems = props?.reportData
    const reportLength = props?.fileLength?.userslength
    const pageCount = Math.ceil(reportLength / props.PER_PAGE);

  return (
    <>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        {reportItems?.length > 0 ?
            <Box mt={2}>
                <Box className='d-flex justify-content-end'>
                    <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
                </Box>
                <Box mt={3} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No</Th>
                                    <Th>Borrower Name</Th>
                                    <Th>Borrower Mobile</Th>
                                    <Th>Loan Contract Number</Th>
                                    <Th>Loan Type</Th>
                                    <Th>Loan Amount</Th>
                                    <Th>Loan Status</Th>
                                    <Th>Disbursal Date</Th>
                                    <Th>Maturity Date</Th>
                                    <Th>Repayment due today</Th>
                                    <Th>Repayment due in this month (date)</Th>
                                    <Th>Repayment/EMI due in this month (Rs.)</Th>
                                    <Th>Repayments delayed till date</Th>
                                    <Th>Repayment delayed till date (total amount)</Th>
                                    <Th>Actual EMI Payment Date</Th>
                                    <Th>Status of current month due EMI</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {reportItems?.map((item, index) => {
                                    const serialNumber = index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['borrower_name']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['borrower_mobile']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['loan_contract_number']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['loan_type']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['loan_amount']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['loan_status']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['disbursal_date']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['maturity_date']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['repayment_due_today']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['repayment_due_this_month']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['repayment_emi_due_this_month']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['repayments_delayed_till_date']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['repayment_delayed_till_date(total amount)']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['emi_date']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['status_of_current_month_emi']}</Text>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={props.handlePageClick} />
                </Box>
            </Box> :
            <NoData title="No Record Found" />
        }
    </>
  )
}
