import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Heading, Box } from '@chakra-ui/layout';
  
  function InvestorPieChart(props) {
    return (
      <Box className='investor_chart' mt={4}>
        <Heading as="h2" className='font-poppins card_heading'>Invested amount VS Return Received </Heading>
        <Box mt={6}>
          <Bar options={props.options} data={props.data} />
        </Box>
      </Box>
    );
  }

export default InvestorPieChart;
