import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import AppLayout from '../../../config/default/default'
import NotFound from '../NotFound'
import CommonDashboard from '../AdminOperations/CommonDashboard'
import LoanApplication from '../AdminOperations/Underwritter/LoanApplication'
import UnderwritingProcess from '../AdminOperations/Underwritter/UnderwritingProcess'
import Borrowers from '../AdminOperations/PreSanction/Borrowers'
import Investors from '../AdminOperations/PreSanction/Investors'
import LoanAgreements from '../AdminOperations/PostSanction/LoanAgreements'
import InternalReport from '../AdminOperations/InternalReport/InternalReport'
import VerifyBorrower from '../AdminOperations/PreSanction/VerifyBorrower'
import VerifyInvestor from '../AdminOperations/PreSanction/VerifyInvestor'
import VerifyAgreement from '../AdminOperations/PostSanction/VerifyAgreement'
import OneShotPayment from '../AdminOperations/BureauReport/OneShotPayment'
import MultipleEmiPayment from '../AdminOperations/BureauReport/MultipleEmiPayment'
import CKYCReport from '../AdminOperations/BureauReport/CKYCReport'
import OwnProducts from '../AdminOperations/Product/OwnProducts'
import ViewApplicationSummary from '../AdminOperations/Underwritter/ViewApplicationSummary'
// import CurrentCollection from '../AdminOperations/PostCollectiont/components/CurrentCollection'
import Collections from '../AdminOperations/PostCollectiont/Collections'
import KycDetails_Collection from '../AdminOperations/PostCollectiont/components/KycDetails_Collection.js'
import Collection_Loan_Details from '../AdminOperations/PostCollectiont/components/Collection_Loan_Details.js.js'
import Upcoming_Collection from '../AdminOperations/PostCollectiont/components/Upcoming_Collection.js'
import ViewAgreements from '../AdminOperations/PostSanction/ViewAgreements.js'
export default function AdminRoutes() {
  return (
    <AppLayout>
        <Switch>
            <Route exact path={routesNames.ADMINDASHBOARD} component={CommonDashboard} />
            <Route exact path={routesNames.ADMINPRODUCT} component={OwnProducts} />
            <Route exact path={routesNames.UNDERWRITERLOANAPPLICATION} component={LoanApplication} />
            <Route exact path={routesNames.UNDERWRITERPROCESS} component={UnderwritingProcess} />
            <Route exact path={routesNames.UNDERWRITERVIEWSUMMARY} component={ViewApplicationSummary} />
            <Route exact path={routesNames.PRESANCTIONBORROWERS} component={Borrowers} />
            <Route exact path={routesNames.PRESANCTIONBORROWERSVERIFY} component={VerifyBorrower} />
            <Route exact path={routesNames.PRESANCTIONINVESTORS} component={Investors} />
            <Route exact path={routesNames.PRESANCTIONINVESTORVERIFY} component={VerifyInvestor} />
            <Route exact path={routesNames.POSTSANCTIONLOANAGREEMENTS} component={LoanAgreements} />
            <Route exact path={routesNames.POSTSANCTIONLOANAGREEMENTSVERIFY} component={VerifyAgreement} />
            <Route exact path={routesNames.POSTSANCTIONLOANAGREEMENTSVIEW} component={ViewAgreements} />
            <Route exact path={routesNames.INTERNALREPORT} component={InternalReport} />
            <Route exact path={routesNames.BUREAUREPORT} component={OneShotPayment} />
            <Route exact path={routesNames.DCRREPORT} component={MultipleEmiPayment} />
            <Route exact path={routesNames.CKYCREPORT} component={CKYCReport} />
            <Route exact path={routesNames.ADMINUSERCOLLECTION} component={Collections} />
            <Route exact path={routesNames.ADMINUSERCOLLECTION_KYC_Details} component={KycDetails_Collection} />
            <Route exact path={routesNames.ADMINUSERCOLLECTION_LOAN_DETAILS} component={Collection_Loan_Details} />
            <Route exact path={routesNames.ADMINUSERCOLLECTION_UPCOMING_Details} component={Upcoming_Collection} />
            {/* <Route exact path={routesNames.ADMINUSERCOLLECTION_Missing_Details} component={Missing_Collection} /> */}
            <Route component={NotFound} />
        </Switch>
    </AppLayout>
  )
}
