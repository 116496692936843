import { Box, Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../elements/customButton'
import Lottie from "lottie-react";
import KarzaIcon from '../../../../assets/images/karza.png'
import completeKYCAnimation from "../../../../assets/lottiFiles/kycComplete.json";
import completedKYCAnimation from "../../../../assets/lottiFiles/kycCompleted.json";

export default function CompleteKYCDialog(props) {

    const PopupBox = () => (
        <Box className='kyc_complete_popup'>
            <ModalOverlay />
            {props.isInvestor ?
                <ModalContent>
                    <Heading className="kyc_modal_title font-poppins">
                        {props.isKYCCompleted ? 'Empower Financial Futures. Invest and Earn Up to 36% with Our Lending Platform.' : 'Unlock Financial Opportunities'}
                    </Heading>
                    <Heading className="kyc_modal_title font-poppins">
                        {props.isKYCCompleted ? '' : 'Complete KYC Now!'}
                    </Heading>
                    <Heading as="p" className='font-poppins kyc_modal_description'>
                        {props.isKYCCompleted ? 'Earn up to 36% with our innovative lending platform. Benefit from an intuitive dashboard & maximising your returns. Join us today and be part of the future of finance.' : 'Enjoy hassle-free Lending with personalised Investment options and user-friendly dashboard. Submit your KYC documents today for instant approval.'}
                    </Heading>
                    <ModalBody>
                        <Box className='kyc_image'>
                            <Lottie animationData={props.isKYCCompleted ? completedKYCAnimation : completeKYCAnimation} loop={true} />
                        </Box>
                        <Box className='kyc_modal_actions'>
                            <CustomButton size="md" type="submit" title="DO IT LATER" className="btn_theme_transparent" onClick={props.handleCloseKYCModal} />
                            <CustomButton size="md" type="submit" title={props.isKYCCompleted ? "INVEST NOW" : "START NOW"} className="btn_theme" onClick={props.handleStart} />
                        </Box>
                        {!props.isKYCCompleted &&
                            <Box mt={8} className='d-flex col-gap-10 align-items-center justify-content-center'>
                                <Heading className="kyc_modal_description font-poppins">Our KYC Partner</Heading>
                                <Image src={KarzaIcon} className='modal_footer_icon' />
                            </Box>
                        }
                    </ModalBody>
                </ModalContent> : 
                <ModalContent>
                    <Heading className="kyc_modal_title font-poppins">Unlock Financial Opportunities.</Heading>
                    <Heading className="kyc_modal_title font-poppins">
                        {props.isKYCCompleted ? 'Apply for Your Loan Today' : 'Complete KYC Now!'}
                    </Heading>
                    <Heading as="p" className='font-poppins kyc_modal_description'>
                        {props.isKYCCompleted ? 'Experience seamless borrowing with tailored loan options and an intuitive dashboard. Submit your Loan Application today for quick approval.' : 'Enjoy hassle-free borrowing with personalised loan options and user-friendly dashboard. Submit your KYC documents today for instant approval.'}
                    </Heading>
                    <ModalBody>
                        <Box className='kyc_image'>
                            <Lottie animationData={props.isKYCCompleted ? completedKYCAnimation : completeKYCAnimation} loop={true} />
                        </Box>
                        <Box className='kyc_modal_actions'>
                            <CustomButton size="md" type="submit" title="DO IT LATER" className="btn_theme_transparent" onClick={props.handleCloseKYCModal} />
                            <CustomButton size="md" type="submit" title={props.isKYCCompleted ? "APPLY LOAN NOW" : "START NOW"} className="btn_theme" onClick={props.handleStart} />
                        </Box>
                        {!props.isKYCCompleted &&
                            <Box mt={8} className='d-flex col-gap-10 align-items-center justify-content-center'>
                                <Heading className="kyc_modal_description font-poppins">Our KYC Partner</Heading>
                                <Image src={KarzaIcon} className='modal_footer_icon' />
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            }
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseKYCModal}
        isOpen={props.openCommpleteKYCModal}
        motionPreset="slideInBottom"
        size={'xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
