import React from 'react'
import {Box, Heading, Grid, GridItem, Input} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton';

export default function Summary(props) {

    const data = props?.userData?.data
    const userDetails = data?.summaryDetails && Object.keys(data?.summaryDetails).map((key) => ({
        id: key,
        name: data?.summaryDetails[key]
    }));

    const handleNext = () => {
        props.onClick()
    }

  return (
    <Box mt={5}>
        <Grid className="main_grid_container justify-content-between">
            <GridItem colSpan={7}>
                <Box className='d-flex justify-content-between summary_heading'>
                    <Heading as="h6" className='font-poppins'>Summary</Heading>
                    <Heading as="h6" className='font-poppins'>Weightage</Heading>
                </Box>
                {userDetails?.map((item, index) => {
                    return (
                        <Box key={index} className='d-flex card_data' mt={2}>
                            <Heading as="h6" className='font-poppins box_title w-55'>{item.name.label}</Heading>
                            <Heading as="h6" className='font-poppins box_data w-35'>{item.name.value}</Heading>
                            <Heading as="h6" className='font-poppins box_data w-11 justify-content-center'>
                                {item.name.weightage}/{item.name.max_weightage}
                            </Heading>
                        </Box>
                    )
                })}
                {data?.loanDetails?.alpha_rating &&
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Alpha Score </Heading>
                        <Heading as="h6" className='font-poppins box_data w-35'>{data?.loanDetails?.alpha_rating}</Heading>
                        <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                    </Box>
                }
                {data?.occupational_data?.net_monthly_income_underwriter &&
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Net Monthly Income</Heading>
                        <Heading as="h6" className='font-poppins box_data w-35'>₹ {Number(data?.occupational_data?.net_monthly_income_underwriter)?.toLocaleString('en-US')}</Heading>
                        <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                    </Box>
                }
                {(data?.occupational_data?.total_monthly_obligation && data?.occupational_data?.total_monthly_obligation > 0) ?

                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Current Obligation </Heading>
                        <Heading as="h6" className='font-poppins box_data w-35'>₹ {data?.occupational_data?.total_monthly_obligation?.toLocaleString('en-US')}</Heading>
                        <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                    </Box> :
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Current Obligation </Heading>
                        <Heading as="h6" className='font-poppins box_data w-35'>₹ 0</Heading>
                        <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                    </Box>
                }
                {data?.loanDetails?.repaying_capacity &&
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Repaying Capacity </Heading>
                        <Heading as="h6" className='font-poppins box_data w-35'>{data?.loanDetails?.repaying_capacity}</Heading>
                        <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                    </Box>
                }
                {(data?.occupational_data?.total_monthly_obligation && data?.occupational_data?.net_monthly_income_underwriter) ?
                    <Box mt={8} className='form_inputs'>
                        <Heading as="h5" className='font-poppins'>Finalise Alternative ROI <span>(optional)</span></Heading>
                        <Box className='d-flex align-items-center col-gap-15'>
                            <Input size="md" name="borrower_roi"  type="number" placeholder="Please Enter ROI" className="roi_input_box font-poppins"
                                onChange={props.handleChange} value={props.inputValues?.borrower_roi ?? ''}
                            />
                            <CustomButton size="md" type="submit" title="Apply" className="btn_theme font-poppins" onClick={props.handleApplyROI} />
                        </Box>
                    </Box> : null
                }
            </GridItem>
            <GridItem colSpan={5} className="applied_loan">
                <Box className='summary_heading'>
                    <Heading as="h6" className='font-poppins'>Applied Loan Detail</Heading>
                </Box>
                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                    <Heading as="h6" className='font-poppins f-600'>{data?.loanDetails?.display_loan_type}</Heading>
                </Box>
                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                    <Heading as="h6" className='font-poppins f-600'>₹ {data?.loanDetails?.loan_amount}</Heading>
                </Box>
                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                    <Heading as="h6" className='font-poppins f-600'>{data?.loanDetails?.display_tenure}</Heading>
                </Box>
                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                    <Heading as="h6" className='font-poppins f-600'>{data?.loanDetails?.loan_purpose}</Heading>
                </Box>
                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Requested ROI</Heading>
                    <Heading as="h6" className='font-poppins f-600'>{data?.loanDetails?.expected_interest_rate}%</Heading>
                </Box>
                {data?.finalOfferCards?.length > 0 ?
                    <Box mt={6}>
                        <Box className='eligible_heading' mb={2}>
                            <Heading as="h6" className='font-poppins'>Eligible Loan Detail</Heading>
                        </Box>
                        {data?.finalOfferCards?.map((item, index) => {
                            return (
                            <Box key={index} mb={4} onClick={() => props.handleSelectApplyLoanCard(item)}
                                className={props.selectedCard === item ? 'eligible_card bg_shadow select_card selected_card' : 'eligible_card select_card bg_shadow'}>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{item?.loan_type}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>₹ {item?.loan_amount}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{item?.display_tenure}</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>Rate Of Interest</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>{item?.interest_rate}%</Heading>
                                </Box>
                                <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                                    <Heading as="h6" className='font-poppins'>{item?.loan_type === 'Personal Loan' ? 'EMI' : 'Repayment Amount'}</Heading>
                                    <Heading as="h6" className='font-poppins f-600'>₹ {item?.emi}</Heading>
                                </Box>
                            </Box>
                            )
                        })}
                    </Box> : data?.is_not_eligible === 1 ?
                    <Box mt={6}>
                        <Box className='eligible_heading' mb={2}>
                            <Heading as="h6" className='font-poppins'>Eligible Loan Detail</Heading>
                        </Box>
                        <Box className='d-flex not_eligible justify-content-between align-items-center' mt={2}>
                            <Heading as="h6" className='font-poppins'>Not Eligible for Any Loan</Heading>
                        </Box>
                    </Box> : null
                }
            </GridItem>
        </Grid>
        <Box className="kyc_actions btn_gap" mb={4} mt={4}>
            {!(data?.occupational_data?.total_monthly_obligation && data?.occupational_data?.net_monthly_income_underwriter) ?
            <CustomButton size="md" type="submit" title="Next" className="btn_theme font-poppins" onClick={handleNext} /> : null }
        </Box>
    </Box>
  )
}
