import { Box, Heading} from '@chakra-ui/react'
import React from 'react'

export default function WalletCard(props) {
    
  return (
     <Box className='wallet_box bg_shadow'>
        <Heading as="h2" className='font-poppins card_heading text_white'>Wallet Overview</Heading>
        <Box mt={2}>
          <Box className='wallet_box_data d-flex align-items-center justify-content-between'>
              <Heading as="h4" className='font-poppins'>Available Balance To Withdraw </Heading>
              <Heading as="h5" className='font-poppins'>₹{props?.walletData?.effective_balance}</Heading>
          </Box>
          <Box className='wallet_box_data d-flex align-items-center justify-content-between'>
              <Heading as="h4" className='font-poppins'>Total Escrow balance</Heading>
              <Heading as="h5" className='font-poppins'>₹{props?.walletData?.total_wallet_balance_as_on_date}</Heading>
          </Box>
          <Box className='wallet_box_data d-flex align-items-center justify-content-between'>
              <Heading as="h4" className='font-poppins'>Pledged amount as on date</Heading>
              <Heading as="h5" className='font-poppins'>₹{props?.walletData?.total_pledged_as_on_date}</Heading>
          </Box>
          <Box className='wallet_box_data d-flex align-items-center justify-content-between'>
              <Heading as="h4" className='font-poppins'>Total recharged till now</Heading>
              <Heading as="h5" className='font-poppins'>₹{props?.walletData?.total_recharged_as_on_date}</Heading>
          </Box>
          <Box className='wallet_box_data d-flex align-items-center justify-content-between'>
              <Heading as="h4" className='font-poppins'>Total withdrawals till now</Heading>
              <Heading as="h5" className='font-poppins'>₹{props?.walletData?.total_withdrawals_as_on_date}</Heading>
          </Box>
         </Box>
     </Box>
   )
 }