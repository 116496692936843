/* eslint-disable no-unused-vars */
import * as authServices from '../../services/authServices'
import * as authUtils from '../../utilities/authUtils'
import * as actionTypes from './actionsTypes'

//actions for get mobile otp
const getMobileOTPRequest = () => {
  return {
    type: actionTypes.GET_MOBILE_OTP_REQUEST,
  }
}

const getMobileOTPSuccess = (data) => {
  return {
    type: actionTypes.GET_MOBILE_OTP_SUCCESS,
    payload: data,
  }
}

const getMobileOTPFailure = (error) => {
  return {
    type: actionTypes.GET_MOBILE_OTP_FAILURE,
    payload: error,
  }
}

//getMobileOTP action
export const getMobileOTPAction = (payload) =>
  async(dispatch) => {
    dispatch(getMobileOTPRequest())
    try {
      const responseData = await authServices.getMobileOTP(payload)
      if (responseData?.status === 200) {
        dispatch(getMobileOTPSuccess(responseData?.data))
        authUtils.setItem('req_id', responseData?.data?.request_id)
        authUtils.setItem('number', payload?.mobile)
      } else {
        dispatch(getMobileOTPFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getMobileOTPFailure(error?.response?.data))
    }
  }

  //actions for verify mobile otp
const verifyMobileOTPRequest = () => {
  return {
    type: actionTypes.VERIFY_MOBILE_OTP_REQUEST,
  }
}

const verifyMobileOTPSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_MOBILE_OTP_SUCCESS,
    payload: data,
  }
}

const verifyMobileOTPFailure = (error) => {
  return {
    type: actionTypes.VERIFY_MOBILE_OTP_FAILURE,
    payload: error,
  }
}

//verifyMobileOTP action
export const verifymobileOTPAction = (payload) =>
  async(dispatch) => {
    dispatch(verifyMobileOTPRequest())
    try {
      const responseData = await authServices.verifyMobileOTP(payload)
      if (responseData?.status === 200) {
        dispatch(verifyMobileOTPSuccess(responseData?.data))
      } else {
        dispatch(verifyMobileOTPFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(verifyMobileOTPFailure(error?.response?.data))
    }
  }

// actions for create new user 
const createNewUserRequest = () => {
  return {
    type: actionTypes.CREATE_NEW_USER_REQUEST,
  }
}

const createNewUserSuccess = (data) => {
  return {
    type: actionTypes.CREATE_NEW_USER_SUCCESS,
    payload: data,
  }
}

const createNewUserFailure = (error) => {
  return {
    type: actionTypes.CREATE_NEW_USER_FAILURE,
    payload: error,
  }
}

//createNewUser action
export const createNewUserAction = (payload) =>
  async(dispatch) => {
    dispatch(createNewUserRequest())
    try {
      const responseData = await authServices.createNewUser(payload)
      if (responseData?.status === 200) {
        dispatch(createNewUserSuccess(responseData?.data))
      } else {
        dispatch(createNewUserFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createNewUserFailure(error?.response?.data))
    }
  }

  //actions for get cp otp
const getCPOTPRequest = () => {
  return {
    type: actionTypes.GET_CP_OTP_REQUEST,
  }
}

const getCPOTPSuccess = (data) => {
  return {
    type: actionTypes.GET_CP_OTP_SUCCESS,
    payload: data,
  }
}

const getCPOTPFailure = (error) => {
  return {
    type: actionTypes.GET_CP_OTP_FAILURE,
    payload: error,
  }
}

//getCPOTP action
export const getCPOTPAction = (payload) =>
  async(dispatch) => {
    dispatch(getCPOTPRequest())
    try {
      const responseData = await authServices.getCPOTP(payload)
      if (responseData?.status === 200) {
        dispatch(getCPOTPSuccess(responseData?.data))
      } else {
        dispatch(getCPOTPFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCPOTPFailure(error?.response?.data))
    }
  }

   //actions for verify cp otp
const verifyCPOTPRequest = () => {
  return {
    type: actionTypes.VERIFY_CP_OTP_REQUEST,
  }
}

const verifyCPOTPSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_CP_OTP_SUCCESS,
    payload: data,
  }
}

const verifyCPOTPFailure = (error) => {
  return {
    type: actionTypes.VERIFY_CP_OTP_FAILURE,
    payload: error,
  }
}

//verifyCPOTP action
export const verifyCPOTPAction = (payload) =>
  async(dispatch) => {
    dispatch(verifyCPOTPRequest())
    try {
      const responseData = await authServices.verifyCPOTP(payload)
      if (responseData?.status === 200) {
        dispatch(verifyCPOTPSuccess(responseData?.data))
      } else {
        dispatch(verifyCPOTPFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(verifyCPOTPFailure(error?.response?.data))
    }
  }

//actions for get sales otp
const getSalesOTPRequest = () => {
  return {
    type: actionTypes.GET_SALES_OTP_REQUEST,
  }
}

const getSalesOTPSuccess = (data) => {
  return {
    type: actionTypes.GET_SALES_OTP_SUCCESS,
    payload: data,
  }
}

const getSalesOTPFailure = (error) => {
  return {
    type: actionTypes.GET_SALES_OTP_FAILURE,
    payload: error,
  }
}

//getSalesOTP action
export const getSalesOTPAction = (payload) =>
  async(dispatch) => {
    dispatch(getSalesOTPRequest())
    try {
      const responseData = await authServices.getSalesOTP(payload)
      if (responseData?.status === 200) {
        dispatch(getSalesOTPSuccess(responseData?.data))
      } else {
        dispatch(getSalesOTPFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getSalesOTPFailure(error?.response?.data))
    }
  }

  //actions for verify sales otp
  const verifySalesOTPRequest = () => {
    return {
      type: actionTypes.VERIFY_SALES_OTP_REQUEST,
    }
  }
  
  const verifySalesOTPSuccess = (data) => {
    return {
      type: actionTypes.VERIFY_SALES_OTP_SUCCESS,
      payload: data,
    }
  }
  
  const verifySalesOTPFailure = (error) => {
    return {
      type: actionTypes.VERIFY_SALES_OTP_FAILURE,
      payload: error,
    }
  }
  
  //verifySalesOTP action
  export const verifySalesOTPAction = (payload) =>
    async(dispatch) => {
      dispatch(verifySalesOTPRequest())
      try {
        const responseData = await authServices.verifySalesOTP(payload)
        if (responseData?.status === 200) {
          dispatch(verifySalesOTPSuccess(responseData?.data))
        } else {
          dispatch(verifySalesOTPFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(verifySalesOTPFailure(error?.response?.data))
      }
    }