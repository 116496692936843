import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import AppLayout from '../../../config/default/default'
import NotFound from '../NotFound'
import SaleDashboard from '../sales/Dashboard/SaleDashboard'

export default function SalesRoutes() {
  return (
    <AppLayout>
        <Switch>
            <Route exact path={routesNames.SALESDASHBOARD} component={SaleDashboard} />
            <Route component={NotFound} />
        </Switch>
    </AppLayout>
  )
}
