import { Box, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Tooltip } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../../elements/customButton'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import moment from 'moment'
import CustomToast from '../../../../elements/customToast'

export default function PaymentModal(props) {

    const { addToast } = CustomToast()
    const totalOutstandingAmount = props?.data?.loanContract[0]?.total_outstanding_dues
    const repaymentDate = props?.data?.maturity_date && moment(props?.data?.maturity_date).format("DD/MM/YYYY")
    const interestAmount = props?.data?.loanContract[0]?.accured_interset_till_date
    const partPaymentOptions = props?.data?.part_payment && Object.keys(props?.data?.part_payment).map((key) => ({
        id: key,
        name: key + " - " +props?.data?.part_payment[key]
    }));
    const partPayValue = props?.inputValues?.part_amount?.replace(/^\d+ - /, '')

    const handlePaymentModal = () => {
        if (!props?.inputValues?.part_amount) {
            addToast({message: 'Please Choose Part Payment', status: 'error'})
            return
        }
        props.handleOpenLoanPaymentDialog(partPayValue)
    }

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="payment_title font-poppins">Part Payment</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box className='d-flex align-items-center justify-content-between col-gap-15' mb={4}>
                        <Box className='form_inputs'>
                            <Heading as="h5" className='font-poppins'>Repayment Amount</Heading>
                            <Input size="md" type="text" className="input_box font-poppins" disabled 
                                value={`₹ ${totalOutstandingAmount}`}
                            />
                        </Box>
                        <Box className='form_inputs'>
                            <Heading as="h5" className='font-poppins'>Interest Amount</Heading>
                            <Input size="md" type="text" className="input_box font-poppins" disabled value={`₹ ${interestAmount}`} />
                        </Box>
                    </Box>
                    <Box className='form_inputs' mb={4}>
                        <Heading as="h5" className='font-poppins'>Payment Due Date</Heading>
                        <Input size="md" type="text" className="input_box font-poppins" disabled value={`${repaymentDate}`} />
                    </Box>
                    <Box className='form_inputs position-relative' mb={5}>
                        <Heading as="h5" className='font-poppins'>Total Outstanding Amount</Heading>
                                <Box className='payment_input'>
                                    <Select placeholder="Please Choose Part Payment" variant="filled" className="font-poppins input_box_select"
                                        name="part_amount" onChange={props.handleChange} value={props.inputValues?.part_amount ?? ''}>
                                        {partPaymentOptions?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>{item.name}</option>
                                            )
                                        })}
                                    </Select>
                                </Box>
                        <Tooltip label='If you are making the payment through UPI method, Please do not exceed the daily limit. You can still make the payment through NEFT/IMPS/RTGS.' 
                            hasArrow fontSize='xs' placement='right-end'>
                            <InfoOutlineIcon className='info_icon_amount' />
                        </Tooltip>
                    </Box>
                    <Box className='d-flex align-items-center justify-content-end col-gap-15' mb={4}>
                        <CustomButton size="md" type="submit" title="Cancel" className="btn_theme_transparent" onClick={props.handleClosePartPayDialog} />
                        <CustomButton size="md" type="submit" title="Pay Now" className="btn_theme" onClick={handlePaymentModal} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleClosePartPayDialog}
        isOpen={props.openPayment}
        motionPreset="slideInBottom"
        size={'xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
