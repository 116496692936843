import React from 'react'
import { Box, Heading, Container, Image } from '@chakra-ui/react'
import NotFoundImage from '../../assets/images/404.svg'
import CustomButton from '../elements/customButton'
import { removeAll } from '../../utilities/authUtils'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../constants/routes'

export default function NotFound() {

  const history = useHistory()

  const handleClick = () => {
    removeAll()
    history.push(routesNames.LOGIN)
  }

  return (
    <Container maxW='container.lg'>
        <Box className='not_found_container'>
            <Box className='not_found'>
                <Image src={NotFoundImage} />
                <Heading as="h1" className='font-poppins'>404</Heading>
                <Heading as="p" className='font-poppins'>Page Not Found !</Heading>
                <CustomButton size="md" type="submit" onClick={handleClick} title="Go Back to Login" className="font-poppins btn_theme" />
            </Box>
        </Box>
    </Container>
  )
}
