import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  postPersonalDetails: {
    data: null,
    loading: false,
    error: null,
  },
  getPiiData: {
    data: null,
    loading: false,
    error: null,
  },
  createDigiLocker: {
    data: null,
    loading: false,
    error: null,
  },
  getdownloadAadhaarData: {
    data: null,
    loading: false,
    error: null,
  },
  saveBankDetails: {
    data: null,
    loading: false,
    error: null,
  },
  saveDocs: {
    data: null,
    loading: false,
    error: null,
  },
  getPaymentMethods: {
    data: null,
    loading: false,
    error: null,
  },
  generateOrder: {
    data: null,
    loading: false,
    error: null,
  },
  getPaymentStatus: {
    data: null,
    loading: false,
    error: null,
  },
  updateOrderStatus: {
    data: null,
    loading: false,
    error: null,
  },
}

const kycReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.POST_PERSONAL_DETAILS_REQUEST:
      return {
        ...state,
        postPersonalDetails: {
          ...state.postPersonalDetails,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.POST_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        postPersonalDetails: {
          ...state.postPersonalDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.POST_PERSONAL_DETAILS_FAILURE:
      return {
        ...state,
        postPersonalDetails: {
          ...state.postPersonalDetails,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_PII_DATA_REQUEST:
      return {
        ...state,
        getPiiData: {
          ...state.getPiiData,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_PII_DATA_SUCCESS:
      return {
        ...state,
        getPiiData: {
          ...state.getPiiData,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_PII_DATA_FAILURE:
      return {
        ...state,
        getPiiData: {
          ...state.getPiiData,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CREATE_DIGI_LOCKER_REQUEST:
      return {
        ...state,
        createDigiLocker: {
          ...state.createDigiLocker,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CREATE_DIGI_LOCKER_SUCCESS:
      return {
        ...state,
        createDigiLocker: {
          ...state.createDigiLocker,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CREATE_DIGI_LOCKER_FAILURE:
      return {
        ...state,
        createDigiLocker: {
          ...state.createDigiLocker,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_DOWNLOAD_AADHAR_DATA_REQUEST:
      return {
        ...state,
        getdownloadAadhaarData: {
          ...state.getdownloadAadhaarData,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_DOWNLOAD_AADHAR_DATA_SUCCESS:
      return {
        ...state,
        getdownloadAadhaarData: {
          ...state.getdownloadAadhaarData,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_DOWNLOAD_AADHAR_DATA_FAILURE:
      return {
        ...state,
        getdownloadAadhaarData: {
          ...state.getdownloadAadhaarData,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.SAVE_BANK_DETAILS_REQUEST:
      return {
        ...state,
        saveBankDetails: {
          ...state.saveBankDetails,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.SAVE_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        saveBankDetails: {
          ...state.saveBankDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.SAVE_BANK_DETAILS_FAILURE:
      return {
        ...state,
        saveBankDetails: {
          ...state.saveBankDetails,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        getPaymentMethods: {
          ...state.getPaymentMethods,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        getPaymentMethods: {
          ...state.getPaymentMethods,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        getPaymentMethods: {
          ...state.getPaymentMethods,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GENERATE_ORDER_REQUEST:
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GENERATE_ORDER_SUCCESS:
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GENERATE_ORDER_FAILURE:
      return {
        ...state,
        generateOrder: {
          ...state.generateOrder,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        getPaymentStatus: {
          ...state.getPaymentStatus,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        getPaymentStatus: {
          ...state.getPaymentStatus,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        getPaymentStatus: {
          ...state.getPaymentStatus,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.UPDATE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        updateOrderStatus: {
          ...state.updateOrderStatus,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        updateOrderStatus: {
          ...state.updateOrderStatus,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.UPDATE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        updateOrderStatus: {
          ...state.updateOrderStatus,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.SAVE_DOCS_REQUEST:
      return {
        ...state,
        saveDocs: {
          ...state.saveDocs,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.SAVE_DOCS_SUCCESS:
      return {
        ...state,
        saveDocs: {
          ...state.saveDocs,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.SAVE_DOCS_FAILURE:
      return {
        ...state,
        saveDocs: {
          ...state.saveDocs,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CLEANUP_TOAST:
      return {
        ...state,
        postPersonalDetails: {
          data: null,
          loading: false,
          error: null,
        },
        saveBankDetails: {
          data: null,
          loading: false,
          error: null,
        },
        saveDocs: {
          data: null,
          loading: false,
          error: null,
        },
        generateOrder: {
          data: null,
          loading: false,
          error: null,
        },
        updateOrderStatus: {
          data: null,
          loading: false,
          error: null,
        },
        createDigiLocker: {
          data: null,
          loading: false,
          error: null,
        },
        getPiiData: {
          data: null,
          loading: false,
          error: null,
        },
      }
    default:
      return state
  }
}

export default kycReducers
