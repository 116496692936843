import React, { useState } from 'react'
import {Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import ApprovedInvestors from './components/ApprovedInvestors'
import NewInvestors from './components/NewInvestors'
import PendingInvestors from './components/PendingInvestors'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, setItem } from '../../../../utilities/authUtils'
import { useSelector } from 'react-redux'

const tabNames = [
    {
        id: 1,
        name: 'New Investors',
    },
    {
        id: 2,
        name: 'Approved Investors',
    },
    {
        id: 3,
        name: 'Pending Investors',
    },
]

export default function Investors() {

    const history = useHistory()
    const adminState = useSelector((state) => state.adminState)
    const [selectedTab, setSelectedTab] = useState(tabNames[0].name)
    const [inputValues, setInputValues] = useState({})
    const userData = adminState?.getUserByToken?.data?.data
    const userType = userData?.usertype

    const handleTabChange = (index) => {
        setSelectedTab(tabNames[index].name);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    const handleVerify = (id) => {
        setItem('investorId', id)
        history.push(routesNames.PRESANCTIONINVESTORVERIFY)
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
                <Heading as="h2" className='font-poppins page_heading'>Pre Sanction Investors List</Heading>
            </GridItem>
            <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin />
            </GridItem>
        </Grid>
        <Box mt={5}>
            <Tabs className='tabs_container' onChange={handleTabChange}>
                <TabList className='tabs_list'>
                    {tabNames?.map((item, index) => {
                        return (
                            <Tab key={index}>{item.name}</Tab>
                        )
                    })}
                </TabList>
                <TabPanels>
                    {tabNames.map((item, index) => {
                        return (
                            <TabPanel key={index} className='tab_panel'>
                                {item.name === 'New Investors' && <NewInvestors tabSelected={selectedTab} handleChange={handleChange} inputValues={inputValues} userType={userType} handleVerify={handleVerify} />}
                                {item.name === 'Approved Investors' && <ApprovedInvestors tabSelected={selectedTab} handleChange={handleChange} inputValues={inputValues} />}
                                {item.name === 'Pending Investors' && <PendingInvestors tabSelected={selectedTab} handleChange={handleChange} inputValues={inputValues} />}
                            </TabPanel>
                        )
                    })}
                </TabPanels>
            </Tabs>
        </Box>
    </Box>
  )
}
