import { ArrowBackIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, Heading, IconButton, Input, Modal, ModalBody, ModalContent, ModalOverlay, Spacer, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../../elements/customButton'
import Lottie from 'lottie-react'
import NoLoanTicketsAnimation from '../../../../../assets/lottiFiles/noLoanTickets.json'

export default function LendingPreferenceDialog(props) {

    const PopupBox = () => (
        <Box className='preference_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-start col-gap-15 preference_header'>
                    <IconButton onClick={props.handlePreferenceDialog}><ArrowBackIcon /></IconButton>
                    <Heading as="h3" className="font-poppins">Select Your Lending Preference</Heading>
                </Box>
                <ModalBody className='lending_body'>
                    {!props?.openNoLoanTickets ?
                        <>
                            <Box className='lending_amount lending_card_bg lending_card_radius'>
                                <Box className='amount_input_container'>
                                    <Heading as="h5" className='font-poppins'>Lending Amount</Heading>
                                    <Box className='amount_input_box'>
                                        <Input
                                            type="number"
                                            size="md"
                                            name="lended_amount"
                                            placeholder="₹ 0.00"
                                            onChange={props.handleChange}
                                            className="amount_input font-poppins"
                                            value={props.inputValues?.lended_amount ?? ''}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='lending_period lending_card_bg lending_card_radius' mt={3}>
                                <Heading as="p" className="font-poppins">Select Lending Period</Heading>
                                <Box className='period_select_box'>
                                    {props?.lendingPeriod?.map((item, index) => {
                                        return (
                                        <Box key={index} onClick={() => props.handleSelectPeriod(item.key)}
                                            className={props.period === item.key ? 'period_card period_card_active' : 'period_card period_card_normal'} >
                                            <Heading as="h6" className="font-poppins">{item.value}</Heading>
                                        </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                            <Box className='accordion_box lending_card_bg lending_card_radius' mt={3}>
                                <Accordion allowToggle>
                                    <AccordionItem className='accordion_card_item'>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Risk Category 
                                                <Tooltip label='Indicate your risk tolerance level for potential borrowers.' hasArrow fontSize='xs' placement='right-end'>
                                                    <InfoOutlineIcon className='info_icon' />
                                                </Tooltip>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pt={3} pb={0} className='accordion_panel_data'>
                                            <Box className='accordion_panel_inner'>
                                                {props?.riskAppetite?.map((elem) => {
                                                    return (
                                                        <Box className="risk_item" key={elem.key}>
                                                            <Checkbox key={elem.key} isChecked={props.riskCheckData?.some((val) => val === elem?.key)}
                                                                onChange={(e) => props.handleSelect(e, elem.key, 'risk')}>
                                                                {elem?.value}
                                                            </Checkbox>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem className='accordion_card_item'>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Borrower Type
                                                <Tooltip label='Select the type of borrowers you prefer to lend to.' hasArrow fontSize='xs' placement='right-end'>
                                                    <InfoOutlineIcon className='info_icon' />
                                                </Tooltip>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pt={3} pb={0} className='accordion_panel_data'>
                                            <Box className='accordion_panel_inner'>
                                                {props?.borrowerType?.map((elem) => {
                                                    return (
                                                        <Box className="risk_item" key={elem.key}>
                                                            <Checkbox key={elem.key} isChecked={props.borrowerCheckData?.some((val) => val === elem?.key)}
                                                                onChange={(e) => props.handleSelect(e, elem.key, 'borrower')}>
                                                                {elem?.value}
                                                            </Checkbox>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem className='accordion_card_item'>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Monthly Income Range
                                                <Tooltip label='Define the income range of borrowers you are comfortable lending to.' hasArrow fontSize='xs' placement='right-end'>
                                                    <InfoOutlineIcon className='info_icon' />
                                                </Tooltip>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pt={3} pb={0} className='accordion_panel_data'>
                                            <Box className='accordion_panel_inner'>
                                                {props?.monthIncomeRange?.map((elem) => {
                                                    return (
                                                        <Box className="risk_item" key={elem.key}>
                                                            <Checkbox key={elem.key} isChecked={props.incomeCheckData?.some((val) => val === elem?.key)}
                                                                onChange={(e) => props.handleSelect(e, elem.key, 'income')}>
                                                                {elem?.value}
                                                            </Checkbox>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem className='accordion_card_item'>
                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                                Lending Rate Range
                                                <Tooltip label='Set the range of interest rates you are willing to accept for loans. e.g 12% to 20%' hasArrow fontSize='xs' placement='right-end'>
                                                    <InfoOutlineIcon className='info_icon' />
                                                </Tooltip>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pt={3} pb={0} className='accordion_panel_data'>
                                            <Box className=''>
                                                <Flex alignItems={'center'} gap={1} className='rate_flex' mb={3}>
                                                    <Input
                                                        type="number"
                                                        size="md"
                                                        name="min_interest"
                                                        placeholder="Minimum 12% Interest Rate"
                                                        onChange={props.handleChange}
                                                        className="input_box font-poppins"
                                                        value={props.inputValues?.min_interest ?? ''}
                                                    />
                                                    <Spacer />
                                                    <Input
                                                        type="number"
                                                        size="md"
                                                        name="max_interest"
                                                        placeholder="Maximum 36% Interest Rate"
                                                        onChange={props.handleChange}
                                                        className="input_box font-poppins"
                                                        value={props.inputValues?.max_interest ?? ''}
                                                    />
                                                </Flex>
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <Box mt={3}>
                                    <CustomButton size="md" type="submit" title="Start" className="font-poppins btn_theme w-100"
                                        onClick={props.handleStart}
                                    />
                                </Box>
                            </Box>
                        </> :
                        <Box className='no_loan_tickets_box'>
                            <Lottie animationData={NoLoanTicketsAnimation} loop={false} />
                            <Box className='no_loan_tickets'>
                                <Heading as="h2" className="font-poppins">Oops!</Heading>
                                <Text className="font-poppins">No Matching Loan Tickets Found</Text>
                            </Box>
                            <Box mt={10}>
                                <CustomButton size="md" type="submit" title="Change Investment Preferences" className="font-poppins btn_theme w-100"
                                    onClick={props.handleChangePreference}
                                />
                            </Box>
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handlePreferenceDialog}
        isOpen={props.openPreferenceModal}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
