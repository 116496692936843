import React from "react";
import {Box, Image, Heading} from '@chakra-ui/react'
import {ActionCreators} from '.././../../redux/actions'
import {Link, useHistory} from 'react-router-dom'
import { connect } from "react-redux";
import * as routesNames from '../../../constants/routes'
import RBIlogo from "../../../assets/images/rbi_logo.svg";
import RBIlogoBlack from "../../../assets/images/rbi_black.svg";
import alphaLogo2 from "../../../assets/images/Asset_logo_3.svg";
import alphaLogo from "../../../assets/images/Asset_logo_2.svg";
import CustomButton from "../../elements/customButton.js";
import { bindActionCreators } from "redux";
import { setItem } from "../../../utilities/authUtils.js";

const Register = (props) => {

  const history = useHistory()

  const handleClick = (type) => {
    if (type === 'borrow') {
      setItem("usertype", 2);
      history.push(routesNames.BORROWERSIGNUP);
    }
    if (type === 'investor') {
      setItem("usertype", 3);
      history.push(routesNames.LENDERSIGNUP);
    }
  }

  return (
    <Box className="parent_container">
      <Box className="updateprofileContainerLogin main_container">       
        <Box className="auth_left_panel hide-xs">
          <Link to={routesNames.LOGIN}>
              <Image className="" src={alphaLogo} />
          </Link>
          <Box className="about_alpha">
            <Heading as="h4">Welcome to <span style={{color:'#E61E25'}}>ALPHA</span>MONEY!</Heading>
            <Heading as="p">
              Our Peer To Peer lending platform connects borrowers with investors for quick personal loans and great returns. Join us now for financial freedom.
            </Heading>
          </Box>
          <Box className="rbi">
            <Image className="" src={RBIlogo} />
          </Box>
        </Box> 
        <Box className="auth_right_panel">
          <Box className="form_container">
            <Box className="form_heading">
              <Box className="mobile_logo hide-md visible-xs">
                <Link to={routesNames.LOGIN}>
                  <Image alt="logo" src={alphaLogo2} />
                </Link>
              </Box>
              <Heading as="h1">Just a couple of clicks and we <span>Start.</span></Heading>
              <Heading as="p">What are you looking to do?</Heading>
            </Box>
            <Box className="login-left-container">
              <Box className="formWraper">
                <Box className="submit_actions" mb={5}>
                  <CustomButton size="md" type="submit" title="Apply for loan" onClick={() => handleClick('borrow')} className="btn_theme_transparent font-poppins" />
                </Box>
                <Box className="submit_actions" mb={7}>
                  <CustomButton size="md" type="submit" title="Sign up as investor" onClick={() => handleClick('investor')} className="btn_theme_transparent font-poppins" />
                </Box>
                <Box className="register-channel">
                  <Heading className="font-poppins" as="h6"> Already a member? {' '}
                    <Link to={routesNames.LOGIN} className="font-poppins"> Login </Link>
                </Heading>
                </Box>
              </Box>
            </Box>
            <Box className="mobile_rbi_logo hide-md visible-xs">
              <Image alt="rbi" src={RBIlogoBlack} />
            </Box>
          </Box>
        </Box>
      </Box>
  </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Register)