import React, { useEffect } from 'react'
import { Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import LendersPayout from '../components/LendersPayout'
import BorrowerPayout from '../components/BorrowerPayout'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import Loader from '../../../elements/loader'

const tabNames = [
    {
      id: 1,
      name: 'Lenders',
    },
    {
      id: 2,
      name: 'Borrowers',
    },
  ]

const CPPayouts = (props) => {

    const cpState = useSelector((state) => state.cpState)
    const adminState = useSelector((state) => state.adminState)
    const payoutData = cpState?.getCPPayout?.data?.data
    const userData = adminState?.getUserByToken?.data?.data
    const invoiceData = cpState?.getInvoice?.data?.data

    console.log(cpState?.getCPPayout?.data?.data, 'cpState?.getCPPayout?.data')

    useEffect(() => {
      props.actions.getCPPayoutAction()
      props.actions.getUserByTokenAction()
      props.actions.getInvoiceAction()
    }, [])

  return (
    <>
      {(cpState?.getCPDashboardDetails?.loading || adminState?.getUserByToken?.loading || cpState?.getInvoice?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Grid className="main_grid_container">
        <GridItem colSpan={8}>
          <Box className='dashboard_box bg_shadow'>
            <Heading as="h2" className='font-poppins page_heading'>My Payouts</Heading>
            <Box mt={8}>
              <Tabs className='tabs_container'>
                  <TabList className='tabs_list'>
                    {tabNames.map((item, index) => {
                      return (
                        <Tab key={index}>{item.name}</Tab>
                      )
                    })}
                  </TabList>
                  <TabPanels>
                    {tabNames.map((item, index) => {
                      return (
                        <TabPanel key={index} className='tab_panel'>
                          {item.name === 'Lenders' && <LendersPayout payoutData={payoutData} />}
                          {item.name === 'Borrowers' && <BorrowerPayout payoutData={payoutData} />}
                        </TabPanel>
                      )
                    })}
                  </TabPanels>
                </Tabs>
            </Box>
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          <NavBar data={userData} isAdmin isCP />
          <Box className='chart_box bg_shadow' mt={4}>
              <Box className='loan_card_heading'>
                <Heading as="h6" className='font-poppins'>Payout Summary</Heading>
              </Box>
              <Box mt={3}>
                <Box className='eligible_card'>
                  <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Investment Fee Payout</Heading>
                    <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.investment_fee_payout}</Heading>
                  </Box>
                  <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Processing Fee Payout</Heading>
                    <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.processing_fee_payout}</Heading>
                  </Box>
                  <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                    <Heading as="h6" className='font-poppins'>Interest Margin Payout</Heading>
                    <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.total_loan_margin}</Heading>
                  </Box>
                    {/* <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Total Registered Users</Heading>
                        <Heading as="h6" className='font-poppins f-600'>{payoutData?.payout_summary?.total_registered_users}</Heading>
                    </Box>
                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Total Investment Amount</Heading>
                        <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.total_investment_amount}</Heading>
                    </Box>
                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Total Transaction Payout</Heading>
                        <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.total_transaction_payout}</Heading>
                    </Box>
                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Registration Payout</Heading>
                        <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.registration_payout}</Heading>
                    </Box>
                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Total Loan Amount</Heading>
                        <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.total_loan_amount}</Heading>
                    </Box>
                    <Box className='d-flex applied_data justify-content-between align-items-center' mt={2}>
                        <Heading as="h6" className='font-poppins'>Total Loans Payout</Heading>
                        <Heading as="h6" className='font-poppins f-600'>₹{payoutData?.payout_summary?.total_loan_repayment_payout}</Heading>
                    </Box> */}
                </Box>
              </Box>
          </Box>
        </GridItem>
      </Grid>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(CPPayouts)