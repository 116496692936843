import React from 'react'
import {Box, Heading} from '@chakra-ui/react'

export default function NoData(props) {

  return (
    <Box className='no-data-found'>
       <Heading as="h4" className='font-poppins'>{props.title}</Heading>
    </Box>
  )
}
