import React from "react";
import {
    Box,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import CustomButton from "../../elements/customButton";
import { CheckCircleIcon } from "@chakra-ui/icons";

export default function InstitutionalPan({
    inputValues,
    userData,
    handleChange,
    panData,
    handleVerifyPan,
}) {
    console.log("userDatauserData", userData);
    return (
        <>
            { (userData?.employment_type == 4 || userData?.employment_type == 5) && (
                    <Box mb={3}>
                        <InputGroup>
                            <Input
                                type="text"
                                size="md"
                                name="pan"
                                placeholder="Enter Company PAN Number"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.pan ?? userData?.pan ?? ""}
                                isReadOnly={userData?.pan || panData}
                            />
                            {userData?.is_pan_verified === 1 || panData ? (
                                <InputRightElement
                                    pointerEvents="none"
                                    children={<CheckCircleIcon className="verify_icon" />}
                                />
                            ) : (
                                <CustomButton
                                    size="md"
                                    type="submit"
                                    title="Verify"
                                    className="font-poppins btn_link"
                                    onClick={() => handleVerifyPan()}
                                />
                            )}
                        </InputGroup>
                        <InputGroup mt={4}>
                            <Input
                                type="text"
                                size="md"
                                name="legal_name"
                                placeholder="Enter Your Authorized Name"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white "
                                value={inputValues?.legal_name ?? userData?.legal_name ?? ""}
                            // isReadOnly={userData?.authorized_name || panData}
                            />
                        </InputGroup>
                        <Box className="verified_data" mt={4}>
                            <Heading as="p" className="font-poppins">
                                <b>Company Name:</b> {inputValues?.first_name}
                            </Heading>
                            <Heading as="p" className="font-poppins">
                                <b>DD/MM/YYYY:</b> {inputValues?.dob}
                            </Heading>
                            {/* <Heading as="p" className='font-poppins'><b>Authorised Signatory Name:</b> {inputValues?.authorized_name}</Heading> */}
                        </Box>
                    </Box>
                )}

            {userData?.employment_type == 7 && (
                <Box mb={3}>
                    <InputGroup>
                        <Input
                            type="text"
                            size="md"
                            name="pan"
                            placeholder="Enter Firm  Number"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.pan ?? userData?.pan ?? ""}
                            isReadOnly={userData?.pan || panData}
                        />
                        {userData?.is_pan_verified === 1 || panData ? (
                            <InputRightElement
                                pointerEvents="none"
                                children={<CheckCircleIcon className="verify_icon" />}
                            />
                        ) : (
                            <CustomButton
                                size="md"
                                type="submit"
                                title="Verify"
                                className="font-poppins btn_link"
                                onClick={() => handleVerifyPan()}
                            />
                        )}
                    </InputGroup>
                    <InputGroup mt={4}>
                        <Input
                            type="text"
                            size="md"
                            name="legal_name"
                            placeholder="Enter Your Authorized Name"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white "
                            value={inputValues?.legal_name ?? userData?.legal_name ?? ""}
                        // isReadOnly={userData?.authorized_name || panData}
                        />
                    </InputGroup>
                    <Box className="verified_data" mt={4}>
                        <Heading as="p" className="font-poppins">
                            <b>Firm Name:</b> {inputValues?.first_name}
                        </Heading>
                        <Heading as="p" className="font-poppins">
                            <b>DD/MM/YYYY:</b> {inputValues?.dob}
                        </Heading>
                        {/* <Heading as="p" className='font-poppins'><b>Authorised Signatory Name:</b> {inputValues?.authorized_name}</Heading> */}
                    </Box>
                </Box>
            )}
            
        </>
    );
}
