import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment';
import Pagination from '../../../../elements/Pagination';
import NoData from '../../../../elements/NoData';

export default function ApprovedApplication(props) {

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(props?.filterApproved?.length / PER_PAGE);
  
    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

  return (
    <Box mt={1}>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        <>
            {props?.filterApproved?.length > 0 ?
                <Box mt={2} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No.</Th>
                                    <Th>Name</Th>
                                    <Th>Loan App. No.</Th>
                                    <Th>Phone No.</Th>
                                    <Th>Application Date</Th>
                                    <Th>Loan Amount</Th>
                                    <Th>Loan Status</Th>
                                    <Th>Tenure</Th>
                                    <Th>Loan Type</Th>
                                    <Th>Approved By</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {props?.filterApproved?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                    const serialNumber = offset + index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.first_name}{' '}{item?.user_data?.last_name}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item.loan_number}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.mobile}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.approved_date ? moment(item?.approved_date).format('DD-MMM-YYYY'): null}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹ {item?.loan_amount}</Text>
                                            </Td>
                                            <Td>
                                            <Text className="font-poppins table_text">
                                                {item.loan_status && item.loan_status == 1 && "Ready For Funding"}
                                                {item.loan_status && item.loan_status == 2 && "In Funding"}
                                                {item.loan_status && item.loan_status == 3 && " Funding Awating E-sign"}
                                                {item.loan_status && item.loan_status == 4 && "Ready For Disbursal"}
                                                {item.loan_status && item.loan_status == 5 && "Funds Disbursed"}
                                            </Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.tenure}{' '}{item?.loan_type === '2' ? 'Days': 'Months'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.loan_type === '2' ? 'Bullet Loan': 'Personal Loan'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.apprved_by?.email}</Text>
                                            </Td>
                                            <Td>
                                                <CustomButton size="md" type="submit" title="View" className="btn_table_green font-poppins"
                                                    onClick={() => props.handleView(item?.user_data?.id, item?.loan_number)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                </Box> :
                <NoData title="No Record Found" />
            }
        </>
    </Box>
  )
}
