import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
    getSummaryReport: {
        data: null,
        loading: false,
        error: null,
    },
    getLenderReport: {
        data: null,
        loading: false,
        error: null,
    },
    getBorrowerReport: {
        data: null,
        loading: false,
        error: null,
    },
    getBorrowerLoanReport: {
        data: null,
        loading: false,
        error: null,
    },
    getBorrowerRepaymentReport: {
        data: null,
        loading: false,
        error: null,
    },
  }

  const salesReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.GET_SUMMARY_REPORT_REQUEST:
            return {
            ...state,
            getSummaryReport: {
                ...state.getSummaryReport,
                    data: null,
                    loading: true,
                    error: null,
                },
            }
        case actionTypes.GET_SUMMARY_REPORT_SUCCESS:
            return {
            ...state,
            getSummaryReport: {
                ...state.getSummaryReport,
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            }
        case actionTypes.GET_SUMMARY_REPORT_FAILURE:
            return {
            ...state,
            getSummaryReport: {
                ...state.getSummaryReport,
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            }
        case actionTypes.GET_LENDER_REPORT_REQUEST:
            return {
            ...state,
            getLenderReport: {
                ...state.getLenderReport,
                    data: null,
                    loading: true,
                    error: null,
                },
            }
        case actionTypes.GET_LENDER_REPORT_SUCCESS:
            return {
            ...state,
            getLenderReport: {
                ...state.getLenderReport,
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            }
        case actionTypes.GET_LENDER_REPORT_FAILURE:
            return {
            ...state,
            getLenderReport: {
                ...state.getLenderReport,
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            }
        case actionTypes.GET_BORROWER_REPORT_REQUEST:
            return {
            ...state,
            getBorrowerReport: {
                ...state.getBorrowerReport,
                    data: null,
                    loading: true,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_REPORT_SUCCESS:
            return {
            ...state,
            getBorrowerReport: {
                ...state.getBorrowerReport,
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_REPORT_FAILURE:
            return {
            ...state,
            getBorrowerReport: {
                ...state.getBorrowerReport,
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            }
        case actionTypes.GET_BORROWER_LOAN_REPORT_REQUEST:
            return {
            ...state,
            getBorrowerLoanReport: {
                ...state.getBorrowerLoanReport,
                    data: null,
                    loading: true,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_LOAN_REPORT_SUCCESS:
            return {
            ...state,
            getBorrowerLoanReport: {
                ...state.getBorrowerLoanReport,
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_LOAN_REPORT_FAILURE:
            return {
            ...state,
            getBorrowerLoanReport: {
                ...state.getBorrowerLoanReport,
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            }
        case actionTypes.GET_BORROWER_REPAYMENT_REPORT_REQUEST:
            return {
            ...state,
            getBorrowerRepaymentReport: {
                ...state.getBorrowerRepaymentReport,
                    data: null,
                    loading: true,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_REPAYMENT_REPORT_SUCCESS:
            return {
            ...state,
            getBorrowerRepaymentReport: {
                ...state.getBorrowerRepaymentReport,
                    data: action.payload,
                    loading: false,
                    error: null,
                },
            }
        case actionTypes.GET_BORROWER_REPAYMENT_REPORT_FAILURE:
            return {
            ...state,
            getBorrowerRepaymentReport: {
                ...state.getBorrowerRepaymentReport,
                    data: null,
                    loading: false,
                    error: action.payload,
                },
            }
      default:
        return state
    }
  }
  
  export default salesReducers