import React from "react"
import { Box, Heading, Grid, GridItem, Text, Spacer, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Progress, IconButton } from "@chakra-ui/react"
import CustomButton from '../../../../elements/customButton'
import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons"
import InvestmentCalender from "./InvestmentCalender"
import moment from "moment"

const PledgedCard = (props) => {

    const repaymentClassMap = {
        A1: 'group-1',
        A2: 'group-1',
        A3: 'group-2',
        A4: 'group-2',
        A5: 'group-3',
        A6: 'group-3',
        A7: 'group-4',
        A8: 'group-4',
        A9: 'group-5',
        A10: 'group-5',
    };

    return (
        <Box mt={3}>
            {props.pledgeInvestData?.map((item, index) => {
                const borrowerId = item?.borrower_data?.[0]?.id
                const repayText = item?.loan_data?.alpha_rating
                const repaymentClass = repaymentClassMap[repayText] || '';
                return (
                    <Box mb={5} className={`${repaymentClass} card_invest`} key={index}>
                        <Box className="d-flex align-items-center justify-content-between w-100">
                            <Box className="col-padding-small width-30">
                                <Box className="d-flex align-items-center justify-content-between">
                                    <Box className="invest_lona_info">
                                        <Text className='font-poppins'>Loan Application ID</Text>
                                        <Heading as="h4" className='font-poppins'>{item?.loan_data?.loan_number}</Heading>
                                    </Box>
                                    <Box className="invest_lona_info">
                                        <Text className='font-poppins'>Alpha Score</Text>
                                        <Heading as="h4" className='font-poppins text-center'>{item?.loan_data?.alpha_rating ? item?.loan_data?.alpha_rating : 'N/A'}</Heading>
                                    </Box>
                                </Box>
                                <Box className="d-flex align-items-center justify-content-between" mt={6}>
                                    <Box className="invest_lona_info d-flex align-items-center col-gap-5">
                                        {/* <Text className={`${repaymentClass} font-poppins`}>Repayment Behaviours</Text> */}
                                        <Text className='font-poppins repay_text'>Repayment Behaviours</Text>
                                        <Popover placement='bottom'>
                                            <PopoverTrigger>
                                                <InfoOutlineIcon onClick={() => props.handleViewHistoryCalender(borrowerId)} />
                                            </PopoverTrigger>
                                            <PopoverContent className="popover_content_card">
                                                <PopoverArrow />
                                                <PopoverBody className="popover_body_card">
                                                    <InvestmentCalender data={props?.creditData} />
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        {/* <Box className="d-flex align-items-center col-gap-10">
                                            <Popover placement='bottom'>
                                                <PopoverTrigger>
                                                    <InfoOutlineIcon onClick={() => props.handleViewHistoryCalender(borrowerId)} />
                                                </PopoverTrigger>
                                                <PopoverContent className="popover_content_card">
                                                    <PopoverArrow />
                                                    <PopoverBody className="popover_body_card">
                                                        <InvestmentCalender data={props?.creditData} />
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                            <Heading as="h5" className='font-poppins text-center'><span>Repayments on time {item?.repayment_behaviour?.on_time_count}/{item?.repayment_behaviour?.total_countemi}</span> {item?.repayment_behaviour?.percentage ? '(' + item?.repayment_behaviour?.percentage?.toFixed(2) + '%)' : "(N/A)"}</Heading>
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="col-padding-small width-45 vertical_border">
                                <Grid className="main_grid_container align-items-center">
                                    <GridItem colSpan={3}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Type</Text>
                                            <Heading as="h6" className='font-poppins loan_type_red'>
                                                {item.loan_data?.loan_name}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={4}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Application Date</Text>
                                            <Heading as="h6" className='font-poppins'>{moment(item.created_at).format('DD-MM-YYYY')}</Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={5}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Application Expiry Date</Text>
                                            <Heading as="h6" className='font-poppins'>{item.loan_data.due_date ? moment(item.loan_data.due_date).format('DD-MM-YYYY') : 'N/A'}</Heading>
                                        </Box>
                                    </GridItem>
                                </Grid>
                                <Grid className="main_grid_container align-items-center" mt={6}>
                                    <GridItem colSpan={3}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Amount</Text>
                                            <Heading as="h6" className='font-poppins'>₹{item?.loan_data?.loan_amount}</Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={4}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Interest Rate p.a </Text>
                                            <Heading as="h6" className='font-poppins'>
                                                {`${item.loan_data?.interest_rate} %`}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={5}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Tenure</Text>
                                            <Heading as="h6" className='font-poppins'>{item.loan_data?.tenure_duration}</Heading>
                                        </Box>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box className="col-padding-small width-25">
                                <Box className="pledged_amount d-flex align-items-center justify-content-between">
                                    <Heading as="h5" className="font-poppins">Your Pledged Amount</Heading>
                                    <Heading as="h6" className="font-poppins">₹ {item.pledged_amount}</Heading>
                                </Box>
                                <Box mt={3}>
                                <Progress className='custom_progress' bg='#00000014' size='sm' value={item?.loan_data?.committed_loan_amount ? parseFloat(item?.loan_data?.committed_loan_amount) : 0} max={item?.loan_data?.loan_amount} />
                                    <Flex alignItems='center' mt={1}>
                                        <Box className='progress_amount'>
                                            <Heading as="h6" className="font-poppins">₹ {item?.loan_data?.committed_loan_amount ? item?.loan_data?.committed_loan_amount : 0}</Heading>
                                            <Heading as="p" className="font-poppins">Already funded</Heading>
                                        </Box>
                                        <Spacer />
                                        <Box className='progress_amount'>
                                            <Heading as="h6" className="font-poppins">₹ {item?.loan_data?.remaining_loan_amount}</Heading>
                                            <Heading as="p" className="font-poppins">Remaining</Heading>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box mt={3}>
                                    {item.loan_data.loan_status === '4' ?
                                        <CustomButton size="md" type="submit" title="Download Agreement" className="btn_theme font-poppins w-100" onClick={() => props.handleDownloadAgreement(item?.loan_data?.esign_request?.eSign_doc_file_url)} />:
                                        <CustomButton size="md" type="submit" title="E-Sign" className="btn_green font-poppins w-100" isDisabled={!item.loan_data.esign_request} onClick={() => props.handleESign(item)} />
                                    }
                                </Box>
                                <Box mt={2} className="d-flex align-items-center justify-content-between col-gap-10 pledge-card-action">
                                    <Box className="pledged_amount_status d-flex align-items-center col-gap-10">
                                        <Heading as="h5" className="font-poppins">Status</Heading>
                                        <Heading as="h6" className={item?.loan_data?.loan_status === '2' ? "font-poppins in_process" : ((item.loan_data.loan_status === '3' && !item.loan_data.esign_request) || (item.loan_data.loan_status === '3' && item.loan_data.esign_request)) ? "font-poppins in_waiting" : "font-poppins in_transfer"}>
                                            {item?.loan_data?.loan_status === '2' ? 'Funding in Process' : (item.loan_data.loan_status === '3' && !item.loan_data.esign_request) ? 'Waiting for Borrower’s  E-Sign' : (item.loan_data.loan_status === '3' && item.loan_data.esign_request) ? 'Waiting for Your E-Sign' : item.loan_data.loan_status === '4' ? 'Funds Transfer In Process' : ''}
                                        </Heading>
                                    </Box>
                                    <IconButton onClick={() => props.handleOpenPledgedSidebar(item)}><ChevronRightIcon /></IconButton>
                                </Box>
                            </Box>
                        </Box>
                        {item.loan_data?.loan_type === '3' &&
                            <Box className='charges_box_invest'>
                                <Heading as="h3" className='font-poppins'>{item.loan_data?.loan_type === '3' ? 'Zero Charges' : null}</Heading>
                            </Box>
                        }
                    </Box>
                )
            })}
        </Box>
    )
}

export default PledgedCard