import apiInstance from '../config/api/axios'

export const generateLogIntent = async(payload) => {
  const response = await apiInstance.post('/upi/generateLogIntent', payload)
  return response
}

export const checkTransactionStatus = async(payload) => {
  const response = await apiInstance.post('/upi/checkTxnStatus', payload)
  return response
}

export const checkNetBankingStatus = async(payload) => {
  const response = await apiInstance.post('/createOnlineTxn', payload)
  return response
}
