import * as salesServices from '../../services/salesServices'
import * as actionTypes from './actionsTypes'
import { getAuthHeaders } from '../../utilities/authUtils'
//actions for get summary report
const getSummaryReportRequest = () => {
    return {
      type: actionTypes.GET_SUMMARY_REPORT_REQUEST,
    }
  }
  
  const getSummaryReportSuccess = (data) => {
    return {
      type: actionTypes.GET_SUMMARY_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getSummaryReportFailure = (error) => {
    return {
      type: actionTypes.GET_SUMMARY_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getSummaryReport action
  export const getSummaryReportAction = (payload) =>
    async(dispatch) => {
      dispatch(getSummaryReportRequest())
      try {
        // const headers = getAuthHeaders();
        const responseData = await salesServices.getSummaryReport(payload)
        if (responseData?.status === 200) {
          dispatch(getSummaryReportSuccess(responseData?.data))
        } else {
          dispatch(getSummaryReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getSummaryReportFailure(error?.response?.data))
    }
}

//actions for get lender report
const getLenderReportRequest = () => {
    return {
      type: actionTypes.GET_LENDER_REPORT_REQUEST,
    }
  }
  
  const getLenderReportSuccess = (data) => {
    return {
      type: actionTypes.GET_LENDER_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getLenderReportFailure = (error) => {
    return {
      type: actionTypes.GET_LENDER_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getLenderReport action
  export const getLenderReportAction = (payload, query) =>
    async(dispatch) => {
      dispatch(getLenderReportRequest())
      try {
        // const headers = getAuthHeaders();
        const responseData = await salesServices.getLenderReport(payload, query)
        if (responseData?.status === 200) {
          dispatch(getLenderReportSuccess(responseData?.data))
        } else {
          dispatch(getLenderReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getLenderReportFailure(error?.response?.data))
    }
}

//actions for get borrower report
const getBorrowerReportRequest = () => {
    return {
      type: actionTypes.GET_BORROWER_REPORT_REQUEST,
    }
  }
  
  const getBorrowerReportSuccess = (data) => {
    return {
      type: actionTypes.GET_BORROWER_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getBorrowerReportFailure = (error) => {
    return {
      type: actionTypes.GET_BORROWER_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getBorrowerReport action
  export const getBorrowerReportAction = (payload, query) =>
  async (dispatch) => {
    dispatch(getBorrowerReportRequest());
    try {
      // const headers = getAuthHeaders();
      const responseData = await salesServices.getBorrowerReport(payload, query);
      if (responseData?.status === 200) {
        dispatch(getBorrowerReportSuccess(responseData?.data));
      } else {
        dispatch(getBorrowerReportFailure(responseData.errors));
      }
    } catch (error) {
      dispatch(getBorrowerReportFailure(error?.response?.data));
    }
}

//actions for get borrower loan details report
const getBorrowerLoanReportRequest = () => {
    return {
      type: actionTypes.GET_BORROWER_LOAN_REPORT_REQUEST,
    }
  }
  
  const getBorrowerLoanReportSuccess = (data) => {
    return {
      type: actionTypes.GET_BORROWER_LOAN_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getBorrowerLoanReportFailure = (error) => {
    return {
      type: actionTypes.GET_BORROWER_LOAN_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getBorrowerLoanReport action
  export const getBorrowerLoanReportAction = (payload, query) =>
    async(dispatch) => {
      dispatch(getBorrowerLoanReportRequest())
      try {
        // const headers = getAuthHeaders();
        const responseData = await salesServices.getBorrowerLoanReport(payload, query)
        if (responseData?.status === 200) {
          dispatch(getBorrowerLoanReportSuccess(responseData?.data))
        } else {
          dispatch(getBorrowerLoanReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getBorrowerLoanReportFailure(error?.response?.data))
    }
}

//actions for get borrower loan repayment report
const getBorrowerRepaymentReportRequest = () => {
    return {
      type: actionTypes.GET_BORROWER_REPAYMENT_REPORT_REQUEST,
    }
  }
  
  const getBorrowerRepaymentReportSuccess = (data) => {
    return {
      type: actionTypes.GET_BORROWER_REPAYMENT_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getBorrowerRepaymentReportFailure = (error) => {
    return {
      type: actionTypes.GET_BORROWER_REPAYMENT_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getBorrowerRepaymentReport action
  export const getBorrowerRepaymentReportAction = (payload, query) =>
    async(dispatch) => {
      dispatch(getBorrowerRepaymentReportRequest())
      try {
        // const headers = getAuthHeaders();
        const responseData = await salesServices.getBorrowerRepaymentReport(payload, query)
        if (responseData?.status === 200) {
          dispatch(getBorrowerRepaymentReportSuccess(responseData?.data))
        } else {
          dispatch(getBorrowerRepaymentReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getBorrowerRepaymentReportFailure(error?.response?.data))
    }
}