import React, { useEffect, useState } from 'react'
import { Box, Heading, Grid, GridItem } from "@chakra-ui/react"
import NavBar from "../../../elements/NavBar"
import { useHistory } from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { getItem, removeAll, setItem } from '../../../../utilities/authUtils'
import InvestmentFilters from '../components/InvestmentFilters'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import decode from 'jwt-decode';
import Loader from '../../../elements/loader'
import PledgedCard from './components/PledgedCard'
import PledgedSidebar from './components/PledgedSidebar'
import options from '../../../../assets/JsonData/investFilterData.json'
import ImportantDialog from '../../../elements/ImportantDialog'
import NoData from '../../../elements/NoData'
import { DIGI_LOCKER_SDK_ESIGN ,SDK_ENV} from '../../../../constants/Constant'
import CustomToast from '../../../elements/customToast'
import { downloadDocuments } from '../../../../utilities/DownloadDocuments'

const PledgedInvestment = (props) => {

    const history = useHistory()
    const { addToast } = CustomToast()
    const investorState = useSelector(state => state.investorState)
    const masterState = useSelector((state) => state.masterState)
    const borrowerState = useSelector((state) => state.borrowerState)
    const [filterData, setFilterData] = useState([])
    const [openSorting, setOpenSorting] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [sortingData, setSortingData] = useState('')
    const [filterInvestPledgeList, setFilterInvestPledgeList] = useState([])
    const [openPledgedSidebar, setOpenPledgedSidebar] = useState(false)
    const [sidebarData, setSidebarData] = useState(null)
    const [investUserData, setInvestUserData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sortOrder, setSortOrder] = useState('desc')
    const [inputValues, setInputValues] = useState({})

    const userData = masterState?.getCurrentUserDetails?.data?.data
    const investDetails = investorState?.getInvestmentDetails?.data?.data
    const creditData = borrowerState?.getBorrowerCreditData?.data?.data

    useEffect(()=>{
      const script = document.createElement("script");
      script.src = DIGI_LOCKER_SDK_ESIGN;
      script.async = true;
      document.body.appendChild(script);
      props.actions.getInvestmentDetailsAction({status: '0'})
      props.actions.getCurrentUserDetailsAction()
      const data = getItem('id_token')
      let decodeToken = decode(data)
      setInvestUserData(decodeToken)
    },[])

    console.log(filterInvestPledgeList, 'nnn')

    useEffect(() => {
      setFilterInvestPledgeList(investorState?.getInvestmentDetails?.data?.data)
  }, [investorState?.getInvestmentDetails])

  const handleESign = async (item) => {
    setLoading(true)
    try {
      const options = {
        environment: SDK_ENV,
        callback: async function (response) {
          if (response.hasOwnProperty("error_code")) {
            setLoading(false)
            addToast({ message: response?.message, status: 'error' })
          } else {
            setLoading(false)
            addToast({ message: response?.message, status: 'success' })
            props.actions.getInvestmentDetailsAction({status: '0'})
          }
        },
        is_iframe: true,
        Is_redirection_approach: false,
        theme: {
          primaryColor: "#000",
          secondaryColor: "#000000"
        }
      };
      var digio = new window.Digio(options);
      digio.init();
      digio.submit(item.loan_data.esign_request.doc_id, investUserData.email, item.loan_data.esign_request.token);
    } catch (error) {
      setLoading(false)
      console.error("Error in digioSdkInstantiation:", error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  }

  const handleDownloadAgreement = (url) => {
    downloadDocuments(url, 'agreement')
  }

    const handleDropdown = (type) => {
      if (type === 'sort') {
          setOpenSorting(!openSorting)
          setOpenFilter(false)
      } else if (type === 'filter') {
          setOpenFilter(!openFilter)
          setOpenSorting(false)
      } else {
          setOpenFilter(!openFilter)
          setOpenSorting(!openSorting)
      }
    }

    const handleSearchName = (e) => {
      const {value} = e.target
      if (value !== '') {
        const result = investDetails?.filter((item) => {
          return item?.loan_data?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.loan_data?.alpha_score?.toLowerCase().includes(value.toLowerCase())
        })
        setFilterInvestPledgeList(result)
      } else {
          setFilterInvestPledgeList(investDetails)
      }
    }

    const handleSelectSorting = (item) => {
      setSortingData(item)
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        let sortedData = [...filterInvestPledgeList].sort((a, b) => {
            let valueA, valueB;
            if (item === 'Amount') {
                valueA = a.loan_data.loan_amount || 0;
                valueB = b.loan_data.loan_amount || 0;
            } else if (item === 'Tenure') {
                valueA = a.loan_data.tenure || 0;
                valueB = b.loan_data.tenure || 0;
            } else if (item === 'Risk Rating') {
              valueA = a.risk_category || 0;
              valueB = b.risk_category || 0;
            } else if (item === '% Funded') {
                valueA = a.pledged_amount || 0;
                valueB = b.pledged_amount || 0;
            } else if (item === 'Loan Type') {
              valueA = a.loan_data.product_id || 0;
              valueB = b.loan_data.product_id || 0;
          }
            // Apply sorting order
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
        setFilterInvestPledgeList(sortedData)
    }

    const handleSelectFilter = (e, val) => {
      if (e.target.checked) {
          setFilterData([...filterData, val]);
      } else {
          setFilterData(filterData.filter((item) => item.id !== val.id));
      }
    }

    const handleApplyFilters = () => {
      const selectedLoanTypes = filterData.filter((filter) => options.filterOptions.loanType.some((type) => type.id === filter.id))
      const selectedRiskCategories = filterData.filter((filter) => options.filterOptions.riskType.some((type) => type.id === filter.id))
    
      let updatedList = investDetails.filter((loan) => {
        const loanTypeMatch = selectedLoanTypes.length === 0 || selectedLoanTypes.some((filter) => loan.loan_data.product_id === filter.id)
        const riskCategoryMatch = selectedRiskCategories.length === 0 || selectedRiskCategories.some((filter) => loan.risk_category === filter.name)
        let daysMatch = true
        if (inputValues.days) {
          daysMatch = loan.loan_data.tenure === parseInt(inputValues.days)
        }
        let monthsMatch = true
        if (inputValues.months) {
          monthsMatch = loan.loan_data.tenure === parseInt(inputValues.months)
        }
        return loanTypeMatch && riskCategoryMatch && daysMatch && monthsMatch
      })
      setFilterInvestPledgeList(updatedList)
    }


    const handleOpenPledgedSidebar = (item) => {
      setOpenPledgedSidebar(true)
      setSidebarData(item)
    }

    const handleClosePledgedSidebar = () => {
        setOpenPledgedSidebar(false)
        setSidebarData(null)
    }

    const handleViewHistoryCalender = (borrowerId) => {
      // props.actions.getBorrowerCreditDataAction({id: '1175'})
      props.actions.getBorrowerCreditDataAction({id: borrowerId})
    }

    console.log(investDetails, 'investDetails')

    const handleReset = () => {
        setFilterData([])
        setInputValues({})
        setFilterInvestPledgeList(investDetails)
    }

    const handleClose = (type) => {
      if (type === 'sort') {
          setOpenSorting(false)

      }
      if (type === 'filter') {
          setOpenFilter(false)
      }
    }

    const handleProfile = () => {
      history.push(routesNames.INVESTORPROFILE)
    }
    
    // const handleLogout = () => {
    //   removeAll()
    //   history.push(routesNames.LOGIN)
    // }

    return (
      <Box>
        {(investorState?.getInvestmentDetails?.loading || masterState?.getCurrentUserDetails?.loading || loading ||
          borrowerState?.getBorrowerCreditData?.loading) &&
          <div>
          <Loader />
          </div>
        }
        <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
              <Box className='d-flex align-items-center justify-content-between'>
                <Heading as="h2" className='font-poppins page_heading'>Pledged Investment</Heading>
                <ImportantDialog data={userData?.[0]?.usertype} />
              </Box>
            </GridItem>
            <GridItem colSpan={4}>
              <NavBar data={userData} handleProfile={handleProfile} />
            </GridItem>
          </Grid>
            <Grid className="main_grid_container">
              <GridItem colSpan={12}>
                <Box mt={5} className='d-flex align-items-center justify-content-end col-gap-15'>
                  <InvestmentFilters sortOptions={options.sortOptions} filterOptions={options.filterOptions} filterData={filterData}
                    handleDropdown={handleDropdown} handleClose={handleClose} handleSelectFilter={handleSelectFilter}
                    handleReset={handleReset} openFilter={openFilter} openSorting={openSorting} sortOrder={sortOrder}
                    sortingData={sortingData} handleSelectSorting={handleSelectSorting} handleSearchName={handleSearchName}
                    handleApplyFilters={handleApplyFilters} handleChange={handleChange} inputValues={inputValues}
                  />
                </Box>
                {filterInvestPledgeList?.length > 0 ?
                  <PledgedCard pledgeInvestData={filterInvestPledgeList} handleOpenPledgedSidebar={handleOpenPledgedSidebar}
                    handleESign={handleESign} handleDownloadAgreement={handleDownloadAgreement}
                    handleViewHistoryCalender={handleViewHistoryCalender} creditData={creditData}
                  /> :
                  <NoData title="No Record Found" />
                }
              </GridItem>
            </Grid>
        </Box>
        <PledgedSidebar handleCloseSidebar={handleClosePledgedSidebar} openSidebar={openPledgedSidebar}
          sidebarData={sidebarData} handleESign={handleESign} handleDownloadAgreement={handleDownloadAgreement}
        />
      </Box>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(PledgedInvestment)