import React from 'react'
import {Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,} from '@chakra-ui/react'
import CustomButton from './customButton'

export default function PrivacyPoliciesDialog(props) {

    const PopupBox = () => (
        <Box className="popup_container">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="modal_title font-poppins">Privacy Policy</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Box className='modal_info_ploicy font-poppins'>
                    <p>
                    <span className="contentofheading"> Creditech Solutions Private Limited</span> (&ldquo; <span className="contentofheading">Alphamoney</span>&rdquo; or
                    &ldquo;<span className="contentofheading">we</span>&rdquo; or &ldquo;<span className="contentofheading">our</span>&rdquo; or &ldquo;<span className="contentofheading">us</span>&rdquo;)  is a
                    company registered under the Companies Act, 2013 and having its
                    registered office at G-14, Ground Floor, Between G &amp;amp; C
                    Block, Anand Niketan, Opposite West end, New Delhi- 110021
                    India. We own and operate{" "}
                    <a className='footercontentlinks' data-fr-linked="true" href="//www.alphamoney.in">
                        www.alphamoney.in
                    </a>{" "}
                    and any other mobile application, if any
                    (&ldquo;Platform&rdquo;) and are committed to protect the
                    privacy and security of your Personal Information (defined
                    below). We value your privacy and advise you to carefully read
                    this Privacy Policy (&ldquo;Privacy Policy&rdquo;), so that you
                    are aware of how, where and why we are using your information.
                    We are committed to protect your Personal Information and keep
                    it confidential. We make commercially reasonable efforts to
                    protect your Personal Information from unauthorized access or
                    disclosure. You agree that you have acceded to the terms of the
                    Privacy Policy. In the event you do not wish to be bound by
                    these terms, you have a choice, which is free from any form of
                    coercion or undue influence, to not access the Platform. This
                    Privacy Policy also applies to services provided by Alphamoney
                    through the Platform or other third-party sites.
                    </p>
                    <p style={{fontWeight: '550', fontSize: '17px',marginTop:"-6px"}}>1. Consent</p>
                    <p className="headingparagraph">
                    We request you to carefully read this Privacy Policy and terms
                    of use available under a different tab before sharing Personal
                    Information with us. By visiting and/or accessing the Platform
                    (or searching for any of the pages on our Platform), you
                    explicitly consent and agree to the Privacy Policy laid out
                    herein and by providing us your Personal Information yourself or
                    by making use of the services provided through the Platform, you
                    hereby acknowledge for the collection, receipt, storage, use,
                    processing, disclosure and transfer of your Personal Information
                    in accordance with the provisions of this Privacy Policy. No
                    liability pertaining to the authenticity/ genuineness/
                    misrepresentation/ fraud/ negligence, etc. of the information
                    disclosed shall lie on Alphamoney nor will Alphamoney in any way
                    be responsible to verify any information obtained from you.
                    </p>
                    <p className="contentofheading1">2. Information we may process</p>
                    <p className="headingparagraph">
                    2.1. Personal Information: It includes your name, mailing
                    address, phone number, email address, date of birth, credit card
                    or debit card or other payment details, pan number, tan number,
                    income tax returns, credit score, bank account number, passport
                    details, driving license details, reasons for seeking finance,
                    income sources and financial information and all details that
                    may be requested by Alphamoney from time to time.
                    </p>
                    <p >
                    <span className="contentofheading"> 2.2. </span>Non-Personal Information: Information (viz. type of
                    internet browser and operating system used, domain name of the
                    Platform from which you came, number of visits, average time
                    spent on the site, pages viewed etc.) may be picked up
                    automatically and without being explicitly provided by you, but
                    during your interaction with the Platform.
                    </p>
                    <p><span className="contentofheading"> 3. </span> Collection and retention of Information</p>
                    <p className="headingparagraph">
                    We collect Personal Information to provide better experience and
                    services to all our visitors. We collect the following
                    information of visitors:
                    </p>
                    <p>
                    <span className="contentofheading"> a. </span> When browsing on the Platform, the visitor is not required to
                    provide any information unless and until you choose to make an className="headingparagraph"
                    account.
                    </p>
                    <p>
                    <span className="contentofheading"> b. </span> Even if you are not signed up, you may provide us information
                    while communicating to us to seek information or help, or
                    subscribing to our bulletins or newsletter to receive an update
                    of our services.
                    </p>
                    <p>
                    <span className="contentofheading"> c. </span> When you create your account, you provide your
                    &lsquo;Personal Information&rsquo; including name, address,
                    mailing address, telephone number, email address, employment
                    related details, pan card and other financial and personal
                    information. We also collect and store content you create, and
                    content or document you upload.
                    </p>
                    <p>
                    <span className="contentofheading"> d. </span> We also collect information about the browser, applications,
                    and devices you use.
                    </p>
                    <p>
                    <span className="contentofheading"> e. </span> We use analytic and reporting technologies to record
                    Non-personal Information such as internet domain and host names,
                    internet protocol (IP) addresses, browser software, operating
                    system types, clickstream patterns, and the dates and times that
                    the Platform and Alphamoney services are accessed.
                    </p>
                    <p>
                    <span className="contentofheading"> f. </span> Depending on the nature of your inquiry or activities on the
                    Platform, which is a platform permitting prospective borrowers
                    and lenders to enter into loan transactions with one another
                    through Alphamoney as well as carrying out risk assessment and
                    support services to prospective borrowers and lenders.
                    </p>
                    <p>
                    <span className="contentofheading"> g. </span> We will collect information through credit bureaus and other
                    partners to determine your credit worthiness, assess risks
                    related to your potential loan and help determine the lenders to
                    commit to your loan.
                    </p>
                    <p>
                    <span className="contentofheading"> h. </span> When you use our services, we can also collect information
                    about your location, which can be determined by, among others,
                    through GPS. The types of location data we collect depend in
                    part on your device and accounts settings.
                    </p>
                    <p>
                    <span className="contentofheading"> i. </span> We also contract with several online partners to help manage,
                    monitor and optimize our Platform and Alphamoney services and to
                    help us measure the effectiveness of our advertising,
                    communications and how visitors use the Platform. We may use web
                    beacons and cookies for the same.
                    </p>
                    <p className="contentofheading1">4. Use of Information</p>
                    <p className="headingparagraph">We use your Personal Information (intended purposes):</p>
                    <p >
                    <span className="contentofheading"> a. </span> To provide our services, credit model and service related
                    purpose such as responding to your queries, carrying out the
                    transactions you have requested for, enabling you to use the
                    Platform and provide you information about our services and
                    resolving glitches on the Platform including addressing any
                    technical problem.
                    </p>
                    <p>
                    <span className="contentofheading"> b. </span> To maintain and improve our services such as navigating the
                    Platform, connecting to the potential lenders and carrying out a
                    transaction on the Platform.
                    </p>
                    <p>
                    <span className="contentofheading"> c. </span> For analytics and measurement to understand the
                    borrower&rsquo;s creditworthiness, credit profiling, credit
                    pattern, credit score, past defaults, and other credit-related
                    and financial information.
                    </p>
                    <p>
                    <span className="contentofheading"> d. </span> For enabling payments and fund transfers with other financial
                    institution.
                    </p>
                    <p>
                    <span className="contentofheading"> e. </span> For generating lender and borrower profile/ account on the
                    Platform.
                    </p>
                    <p>
                    <span className="contentofheading"> f. </span> To improve our Platform&rsquo;s usability and to evaluate the
                    success of marketing campaigns and other activities.
                    </p>
                    <p>
                    <span className="contentofheading"> g. </span> To communicate or interact with you through e-mail address
                    and contact number.
                    </p>
                    <p></p>
                    <p><span className="contentofheading"> h. </span> Improve the safety and reliability of our services.</p>
                    <p><span className="contentofheading"> i. </span> To prevent fraud and misuse.</p>
                    <p>
                    <span className="contentofheading"> j. </span> To protect our interest, the interest of our lenders and
                    other participants.
                    </p>
                    <p>
                    <span className="contentofheading"> k. </span> For detection of the non-disclosed required information,
                    running or closed loans, unpaid liabilities and legal cases.
                    </p>
                    <p>
                    <span className="contentofheading"> l. </span> For enforcing our rights and /or lender&rsquo;s rights
                    against a borrower.
                    </p>
                    <p></p>
                    <p>
                    <span className="contentofheading"> m. </span> For internal business purpose, such as data analysis,
                    research, developing new features, enhancing and improving the
                    experience, identifying usage trends, etc.
                    </p>
                    <p>
                    <span className="contentofheading"> n. </span> To use and share your non- personal information with third
                    parties with whom we have contractual relationship. For example,
                    we may provide you with a link where you may share your content
                    with another person.
                    </p>
                    <p>
                    <span className="contentofheading"> o. </span> To track your activity on our Platform and personalize and
                    improve your experience.
                    </p>
                    <p>
                    <span className="contentofheading"> p. </span> To further share your information with marketing agencies and
                    other businesses if we in our sole discretion understand that
                    your information may be used to market the Platform or a product
                    or service.
                    </p>
                    <p>
                    <span className="contentofheading"> q. </span> For using cookies and beacons, tags and scripts to analyse
                    trends, administer the Platform and track the visitor movement
                    around the Platform, including gathering demographic information
                    about our visitor base. Cookies are also used for statistical
                    data, such as pages frequently visited, what is downloaded and
                    address of sites visited immediately after coming to our
                    site.&nbsp;
                    </p>
                    <p><span className="contentofheading"> r. </span> For any other purpose with your consent.</p>
                    <br />
                    <p className="contentofheading1">5. Disclosure of Information</p>
                    <p className="headingparagraph">
                    Alphamoney reserves the right to disclose your Information for
                    the purposes stated in this Privacy Policy and to the persons
                    stated below.
                    </p>
                    <p>
                    <span className="contentofheading"> a. </span> Disclosure to successors: We may disclose your information to
                    any successor-in- interest of ours, such as a company that
                    acquires us. In the event Alphamoney goes through a business
                    transition, such as a merger or acquisition by or with another
                    company, or sale of all or a portion of our assets, your
                    Information will
                    </p>
                    <p>
                    likely be among the assets transferred. You will be notified via
                    email or by a notice on our Platform of any such change in
                    ownership or control of your information.
                    </p>
                    <p>
                    <span className="contentofheading"> b. </span> Disclosure to lenders: when your loan originates through our
                    Platform, we may provide the lender of your loan with your
                    personal and credit information. Information is only released to
                    the lenders with adequate protections to safeguard your Personal
                    Information.
                    </p>
                    <p></p>
                    <p>
                    <span className="contentofheading"> c. </span> Disclosure to third party service providers and online
                    partners: We may contract with various third parties who help us
                    provide, maintain and improve the Platform and the Alphamoney
                    services. We also contract with several online partners to help
                    manage, monitor and optimize our Platform and the Alphamoney
                    services and to help us measure the effectiveness of our
                    advertising, communications and how visitors use the Platform.
                    We will use commercially reasonable efforts to prevent such
                    third parties from disclosing your information, except for the
                    purpose of providing services in question. We cannot guarantee
                    that such third parties will not disclose your information. In
                    no circumstances, Alphamoney shall disclose data in relation to
                    your financial status, income statements, earnings, source of
                    earnings, personal identification numbers, passwords, code
                    numbers, bank account numbers, credit card numbers, expiry dates
                    relationship numbers, CVV numbers, encryptions, cookies and
                    other such electronic details.
                    </p>
                    <p>
                    <span className="contentofheading"> d. </span> Disclosure of non-personal Information: We will disclose
                    non-personal Information, in aggregate form, to potential
                    strategic partners, advertisers, investors, customers, and
                    others. You may opt-out of the sharing of this information by
                    mailing the grievance redressal officer. However, it cannot be
                    easily used to identify you personally.
                    </p>
                    <p>
                    <span className="contentofheading"> e. </span> Disclosure pursuant to legal requests and regulatory
                    requirements: We may also share information to comply with, or
                    to allow lenders or potential lenders to comply with, any
                    applicable law, regulation, legal process or governmental
                    request; or for the purposes of limiting fraud; or in connection
                    with an audit or the sale of Alphamoney to a third party.
                    </p>
                    <p className="contentofheading1">6. Security and protection of Information</p>
                    <p className="headingparagraph">
                    <span className="contentofheading"> 6.1 </span> We have implemented appropriate physical, electronic,
                    procedural and managerial procedures to safeguard your
                    information against loss, unauthorized access, misuse or
                    modification.
                    </p>
                    <p>
                    <span className="contentofheading"> 6.2 </span> We require any third parties processing your information to
                    implement the same levels of protection with respect to your
                    data. We strive to use commercially acceptable means for
                    protecting your information. However, we are not insurers of the
                    security of your Personal Information. Accordingly, we assume no
                    liability for any disclosure of data due to errors in
                    transmission, unauthorized third party access or other acts of
                    third parties, or acts or omissions beyond our reasonable
                    control.
                    </p>
                    <p>
                    <span className="contentofheading"> 6.3 </span> We store data in password-protected servers with limited
                    access to the employees and other personnel consultants on a
                    need to know basis.
                    </p>
                    <p></p>
                    <p>
                    <span className="contentofheading"> 6.4 </span> In order to make every effort to ensure that the
                    visitor&rsquo;s experience at the Platform is secure, Alphamoney
                    uses encryption technology to protect the visitor against the
                    loss, misuse or alteration of the Personal Information. Your
                    payment information such as your credit card/ debit card number,
                    credit card/ debit card expiry date, your cvv number, etc. are
                    protected through the use of encryption technology.
                    </p>
                    <p>
                    <span className="contentofheading"> 6.5 </span> Access to your online account on the Platform is password
                    protected, you are solely responsible for maintaining the
                    confidentiality of that password. To ensure the safety of your
                    Personal Information, you are advised against sharing your user
                    name and password with anyone.
                    </p>
                    <p>
                    <span className="contentofheading"> 6.6 </span> Alphamoney stores and process all data relating to its
                    activities and participants on hardware located within India.
                    </p>
                    <p className="contentofheading1">7. Cookies</p>
                    <p className="headingparagraph">
                    <span className="contentofheading"> 7.1 </span> Cookies are alphanumeric identifiers with small amount of
                    data that is commonly
                    </p>
                    <p >
                    used as an anonymous unique identifier. These are sent to your
                    browser from the Platform that you visit and are stored on your
                    computer&rsquo;s hard drive. Please note, a cookie in no way
                    gives us access to your computer. Our Platform uses these
                    cookies to collect information and to improve our services. You
                    can accept the cookies to be placed on your browser by clicking
                    on the &lsquo;Allow Cookies&rsquo; or &lsquo;I Agree&rsquo;
                    button. You can find out more about which cookies we are using
                    or switch them off in settings. If you select &lsquo;Allow
                    Cookies&rsquo; or &lsquo;I Agree&rsquo; as mentioned above, at
                    any time you also have the option to change/control your cookies
                    through your browser settings.
                    </p>
                    <p>
                    <span className="contentofheading"> 7.2 </span> Some cookies and other technologies may serve to recall
                    personal information previously indicated by you. Most web
                    browsers are set to accept cookies by default. If you prefer,
                    you can usually choose to set your browser to remove cookies and
                    to reject cookies. If you choose to remove cookies or reject
                    cookies, this could affect certain features of the Platform.
                    </p>
                    <p>
                    <span className="contentofheading"> 7.3 </span> Other platforms may place their own cookies or other files
                    on your computer, collect data or solicit personal information
                    from you, for which we shall not be held responsible or liable.
                    We encourage you to read the privacy policies of all external
                    sites.
                    </p>
                    <p className="contentofheading1">8. Review and update</p>
                    <p className="headingparagraph">
                    <span className="contentofheading"> 8.1 </span> You can access the Personal Information you have provided to
                    us by logging into
                    </p>
                    <p >
                    your account. You can reach out to us at{" "}
                    <a className='footercontentlinks' data-fr-linked="true" href="mailto:admin@alphamoney.in">
                        admin@alphamoney.in
                    </a>{" "}
                    to update your
                    </p>
                    <p>
                    password, email, address, phone number or bank account
                    information at any time. We will respond to your request within
                    a reasonable time.
                    </p>
                    <p>
                    <span className="contentofheading"> 8.2 </span> We will retain your information for a minimum of 7 (seven)
                    years and as long as needed to provide you services, comply with
                    our legal obligation, resolve disputes, conduct analysis,
                    audits, or to enforce our agreements or as otherwise required by
                    law.
                    </p>
                    <p className="contentofheading1">9. Third-party links</p>
                    <p className="headingparagraph">
                    The Platform may contain certain advertisements/hyperlinks to
                    other platforms, applications, content or resources. However, we
                    do not have any control over such platforms, their privacy
                    practices or the content of the links, and therefore we have no
                    responsibility or liability for the manner, in which these
                    organizations that operate such platforms/links may collect, use
                    or disclose, secure and otherwise treat your Personal
                    Information. These links to third party platforms are provided
                    only for your convenience, and as such, you may access them at
                    your own risk. We shall in no way be responsible or liable for
                    any loss or damage, which you may incur in dealing with third
                    party platforms/links that are linked to our Platform.
                    </p>
                    <p className="contentofheading1">10. Opt Out</p>
                    <p className="headingparagraph">
                    In case you have provided us with your e-mail or any other
                    contact details on our Platform or otherwise, we may send you
                    notifications regarding certain promotions, or regarding the
                    services being availed by you, etc. However, in case you wish to
                    stop receiving them, we provide you with an option to opt-out of
                    receiving non-essential (promotional, marketing-related)
                    communications from us on behalf of our partners, and from us in
                    general by emailing us at{" "}
                    <a className='footercontentlinks' data-fr-linked="true" href="mailto:info@alphamoney.in">
                        info@alphamoney.in
                    </a>
                    .
                    </p>
                    <p className="contentofheading1">11. Indemnity</p>
                    <p className="headingparagraph">
                    You undertake to indemnify and keep Alphamoney and /or its
                    directors, employees, investors /lenders, agents, etc. harmless,
                    and any other third party relying on the information provided by
                    you in the event you are in breach of this policy.
                    </p>
                    <p className="contentofheading1">12. Severability</p>
                    <p className="headingparagraph">
                    If at any time during the subsistence of this Privacy Policy,
                    any provision, condition, term or undertaking is or becomes
                    illegal, void, invalid, prohibited or unenforceable in any
                    respect, the same shall be ineffective to the extent of such
                    illegality or unenforceability without invalidating in any
                    manner whatsoever the remaining provisions hereof
                    </p>
                    <p className="contentofheading1">13. Amendment</p>
                    <p className="headingparagraph">
                    We reserve the right to amend or modify this Privacy Policy at
                    any time, as and when the need arises. We request you to
                    regularly check this Privacy Policy from time to time to keep
                    yourself updated about changes made. Your continued use of the
                    services provided by Alphamoney after any changes, shall be
                    deemed to be your acceptance of such changes.
                    </p>
                    <p className="contentofheading1">14. Grievance Redressal</p>
                    <p className="headingparagraph">
                    In compliance with Information Technology Act, 2000 and the
                    rules made thereunder, the grievance redressal officer of
                    Alphamoney for the purpose of this Policy shall be:
                    </p>
                    <p><span className="contentofheading"> a. </span> Grievance Redressal Officer Name: Palak Sharma</p>
                    <p>
                    <span className="contentofheading"> b. </span> Email Address:{" "}
                    <a className='footercontentlinks' data-fr-linked="true" href="mailto:support@alphamoney.in">
                        support@alphamoney.in
                    </a>
                    </p>
                    <p><span className="contentofheading"> c. </span> Contact number: +91-9625782073</p>
                    <p>
                    In case you have any questions, comments or concerns about this
                    Privacy Policy or
                    </p>
                    <p>
                    wish to exercise any of the above mentioned rights, you can
                    email us at
                    </p>
                    <p>
                    <a className='footercontentlinks' data-fr-linked="true" href="mailto:info@alphamoney.in">
                        info@alphamoney.in
                    </a>
                    </p>
                </Box>
                </ModalBody>
                <ModalFooter>
                    <CustomButton size="md" type="submit" title="Cancel" className="btn_theme" onClick={props.handleClickPolicy} />
                </ModalFooter>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleClickPolicy}
        isOpen={props.openPolicy}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
