import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Input, InputGroup, InputLeftElement, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import NoData from '../../../elements/NoData'
import Pagination from '../../../elements/Pagination'
import CustomButton from '../../../elements/customButton'
import { setItem } from '../../../../utilities/authUtils'
import { SearchIcon } from '@chakra-ui/icons'

const CPBorrowers = (props) => {

    const history = useHistory()
    const cpState = useSelector((state) => state.cpState)
    const adminState = useSelector((state) => state.adminState)
    const cpUserData = cpState?.getCPUsers?.data?.data
    const [currentPage, setCurrentPage] = useState(0)
    const [filterCPUserData, setFilterCPUserData] = useState([])
    const filterBorrowerData = cpUserData?.filter((item) => item?.usertype === '2')
    const userData = adminState?.getUserByToken?.data?.data
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(filterCPUserData?.length / PER_PAGE);

    useEffect(() => {
      props.actions.getCPUsersAction()
      props.actions.getUserByTokenAction()
    }, []);

    useEffect(() => {
      setFilterCPUserData(filterBorrowerData)
    },[cpState?.getCPUsers])

    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

    const handleSearch = (e) => {
      const {value} = e.target
      if (value !== '') {
        const result = filterBorrowerData.filter((item) => {
          return item?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.first_name?.toLowerCase().includes(value.toLowerCase()) || item?.last_name?.toLowerCase().includes(value.toLowerCase()) || item?.email?.toLowerCase().includes(value.toLowerCase()) || item?.display_employment_type?.toLowerCase().includes(value.toLowerCase()) || item?.personal_data?.address_district?.toLowerCase().includes(value.toLowerCase())
        })
        setFilterCPUserData(result)
      } else {
        setFilterCPUserData(filterBorrowerData)
      }
    }

    // this method not working properly as of now so use it above only
    // const handleSearch =(e) =>{
    //   const { value } = e.target;
    //    if (value !== '') { 
    //     const result = cpUserData?.filter((item) => {     
    //       const statusMapping = {     
    //       0: 'Incomplete',
    //       1: 'Pending with Pre-sanction',
    //       2: 'Documents Incomplete',
    //       3: 'Loan Application created',
    //       4: 'Pending with Underwriter',
    //       5: 'Loan Acceptance Pending',
    //       6: 'Profile Approved',
    //     }; 
    //    const loan_StatsuMappinng = { 
    //     0: 'Pending with Pre-sanction', 
    //     1: 'Pending with Pre-sanction', 
    //     2: 'In Funding', 
    //     3: 'Funded Awaiting E-sign', 
    //     4: 'Ready For Disbursal', 
    //     5: 'Active Loan', 
    //     6: 'Cancelled', 
    //     7: 'Rejected', 
    //     8: 'Closed', 
    //     9: 'Withdrawn', 
    //     10: 'Approved', 
    //     11: 'Expired', 
    //     12: 'Closing Underprocess',}
    //    const status = Number(item['loan_type']);       
    //    const loanType = statusMapping[status]?.toLowerCase() || '-'; 
    //    const loanSTATUS = Number(item['loan_status'])
    //    const loanStatus =loan_StatsuMappinng[loanSTATUS]?.toLowerCase() || '-'       
    //     return item['Loan Number']?.toLowerCase().includes(value.toLowerCase()) || 
    //     item?.mobile?.toLowerCase().includes(value.toLowerCase()) || 
    //     item?.display_employment_type ?.toLowerCase().includes(value.toLowerCase()) ||
    //     item?.last_name ?.toLowerCase().includes(value.toLowerCase())|| 
    //      item?.middle_name ?.toLowerCase().includes(value.toLowerCase()) || (item?.first_name)?.toLowerCase().includes(value.toLowerCase()) || item['email']?.toLowerCase().includes(value.toLowerCase()) || 
    //     item['loan_contract_number']?.toLowerCase().includes(value.toLowerCase()) ||item['loan_status']?.toLowerCase().includes(value.toLowerCase())
    //     || loanType.includes(value.toLowerCase()) ||loanStatus.includes(value.toLowerCase()); 
    //    })
    //    setFilterCPUserData(result);
    //    }
    //    else {
    //     setFilterCPUserData(filterBorrowerData)  
    //   }
    // }

    const handleApplyLoan = (score, phone, fName, lName) => {
      setItem('score', score)
      setItem('borrower_phone', phone)
      setItem('name', `${fName}${' '}${lName}`)
      history.push(routesNames.CPAPPLYLOAN)
    }

  return (
    <>
      {(cpState?.getCPUsers?.loading || adminState?.getUserByToken?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className='font-poppins page_heading'>My Borrowers</Heading>
          </GridItem>
          <GridItem colSpan={4}>
            <NavBar data={userData} isAdmin isCP />
          </GridItem>
        </Grid>
        <Box mt={6} className='d-flex align-items-center justify-content-end'>
          <InputGroup className='search_input'>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              type="text"
              size="md"
              name="search"
              placeholder="Search"
              onChange={(e) => handleSearch(e)}
              className="input_box font-poppins"
            />
          </InputGroup>
        </Box>
        {filterCPUserData?.length > 0 ?
          <Box mt={2} className='custom_table_wrapper'>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>S.No.</Th>
                    <Th>Name</Th>
                    <Th>Email Id</Th>
                    <Th>Phone Number</Th>
                    <Th>City</Th>
                    <Th>Borrower Type</Th>
                    <Th>Stage	</Th>
                    <Th>Current Status</Th>
                    <Th>Credit Score</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filterCPUserData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                    const serialNumber = offset + index + 1;
                    return (
                      <Tr key={index}>
                        <Td>
                          <Text className="font-poppins table_text">{serialNumber}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.first_name}{' '}{item?.last_name}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.email}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.mobile}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.personal_data?.address_district}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.display_employment_type}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">
                            {((item?.userStage === 0 || item?.userStage === 1) && (item?.form_completed_status === 0 || item?.form_completed_status === 1)) ? 'Registration' :
                              (item?.userStage === 2 && item?.account_approval_status === 1) ? 'Approved' : ''
                            }
                          </Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">
                            {(item.userStage === 0 || item.userStage === 1) && item.form_completed_status === 0 && 'Incomplete'}
                            {item.userStage === 1 && (item.document_data && item.document_data.doc_approval_status === 0 && (item.document_data.doc_checked_by === 3 || item.document_data.doc_checked_by == null)) && 'Pending with Pre-sanction'}
                            {item.userStage === 1 && (item.document_data && item.document_data.doc_approval_status === 1 && item.document_data.doc_checked_by === 3) && 'Documents Incomplete'}
                            {item.userStage === 2 && item.account_approval_status === 1 && item.loan_data && item.loan_data.loan_status > 0 && item.loan_data.loan_status !== 7 && 'Loan Application created'}
                            {item.userStage === 2 && item.account_approval_status === 0 && item.document_data && item.document_data.doc_approval_status === 2 && item.document_data.doc_checked_by === 3 && 'Pending with Underwriter'}
                            {item.userStage === 2 && item.account_approval_status === 1 && item.document_data && item.loan_data && item.loan_data.loan_status === 0 && !item.loan_data.eligible_loan_amt && 'Pending with Underwriter'}
                            {item.userStage === 2 && item.account_approval_status === 1 && item.document_data && item.loan_data && item.loan_data.loan_status === 0  && item.loan_data.eligible_loan_amt  && 'Loan Acceptance Pending'}
                            {item.userStage === 2 && item.account_approval_status === 1 && item.document_data && item.loan_data &&  item.loan_data.loan_status === 7  && 'Profile Approved, Loan Rejected'}
                          </Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.credit_score_data?.credit_score}</Text>
                        </Td>
                        <Td>
                          {item?.can_loan_apply === 1 && item?.userStage === 2 && item?.account_approval_status === 1 ?
                            <CustomButton size="md" type="submit" title="Apply Loan" className="btn_table_green font-poppins" onClick={() => handleApplyLoan(item?.credit_score_data?.credit_score, item?.mobile, item?.first_name, item?.last_name)} /> : null
                          }
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
          </Box> :
          <NoData title="No Record Found" />
        }
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(CPBorrowers)