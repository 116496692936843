import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, Input, InputGroup, InputLeftElement} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import NoData from '../../../elements/NoData'
import Pagination from '../../../elements/Pagination'
import { SearchIcon } from '@chakra-ui/icons'
import moment from 'moment'
import { DIGI_LOCKER_SDK_ESIGN, ENACH_ENV ,SDK_ENV} from '../../../../constants/Constant'
import CustomButton from '../../../elements/customButton'
import CustomToast from '../../../elements/customToast'
import LoanConfirmDialog from '../components/LoanConfirmDialog'

const LoanList = (props) => {

    const { addToast } = CustomToast()
    const cpState = useSelector((state) => state.cpState)
    const adminState = useSelector((state) => state.adminState)
    const loanListData = cpState?.cpLoanList?.data?.data || []
    const [currentPage, setCurrentPage] = useState(0)
    const [filterLoanListData, setFilterLoanListData] = useState([])
    const [userEmail, setUserEmail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [type, setType] = useState(null)
    const [loanData, setLoanData] = useState(null)
    const userData = adminState?.getUserByToken?.data?.data
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(loanListData?.length / PER_PAGE);
    const eSignResponse = cpState?.createCPESignRequest?.data

    const digioSdkInstantiation = async () => {
      setLoading(true)
      try {
        const options = {
          environment: SDK_ENV,
          callback: async function (response) {
            if (response.hasOwnProperty("error_code")) {
              setLoading(false)
              addToast({ message: response?.message, status: 'error' })
            } else {
              setLoading(false)
              addToast({ message: response?.message, status: 'success' })
              props.actions.cpLoanListAction({status: '0,1,2,3,4,5'})
            }
          },
          is_iframe: true,
          Is_redirection_approach: false,
          theme: {
            primaryColor: "#000",
            secondaryColor: "#000000"
          }
        };
        var digio = new window.Digio(options);
        digio.init();
        digio.submit(eSignResponse?.doc_id, userEmail, eSignResponse?.token);
      } catch (error) {
          setLoading(false)
          console.error("Error in digioSdkInstantiation:", error);
      }
    };

    useEffect(() => {
      const script = document.createElement("script");
      script.src = DIGI_LOCKER_SDK_ESIGN;
      script.async = true;
      document.body.appendChild(script);
      props.actions.getUserByTokenAction()
      props.actions.cpLoanListAction({status: '0,1,2,3,4,5'})
    }, []);

    useEffect(() => {
      setFilterLoanListData(cpState?.cpLoanList?.data?.data || [])
    }, [cpState?.cpLoanList])

    useEffect(() => {
      if (cpState?.createCPESignRequest?.data?.success === true) {
        digioSdkInstantiation()
        props.actions.cleanEsignRequestData()
      }
    }, [cpState?.createCPESignRequest?.data])

    useEffect(() => {
      if (cpState?.acceptCPLoanEligibility?.data?.success === true) {
          addToast({message: cpState?.acceptCPLoanEligibility?.data?.message, status: 'success'})
          props?.actions?.cleanUpToast()
          setOpenConfirmation(false)
          props.actions.cpLoanListAction({status: '0,1,2,3,4,5'})
      } else if (cpState?.acceptCPLoanEligibility?.data?.success === false){
          addToast({message: cpState?.acceptCPLoanEligibility?.data?.message, status: 'error'})
          props?.actions?.cleanUpToast()
        }
    }, [cpState?.acceptCPLoanEligibility?.data])

    useEffect(() => {
      if (cpState?.withdrawnCPLoanEligibility?.data?.success === true) {
          addToast({message: cpState?.withdrawnCPLoanEligibility?.data?.message, status: 'success'})
          props?.actions?.cleanUpToast()
          setOpenConfirmation(false)
          props.actions.cpLoanListAction({status: '0,1,2,3,4,5'})
      } else if (cpState?.withdrawnCPLoanEligibility?.data?.success === false){
          addToast({message: cpState?.withdrawnCPLoanEligibility?.data?.message, status: 'error'})
          props?.actions?.cleanUpToast()
        }
    }, [cpState?.withdrawnCPLoanEligibility?.data])

    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

    const handleSearch = (e) => {
      const {value} = e.target
      if (value !== '') {
        const result = loanListData.filter((item) => {
          return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.mobile?.toLowerCase().includes(value.toLowerCase()) || item?.user_data?.first_name?.toLowerCase().includes(value.toLowerCase())
        })
        setFilterLoanListData(result)
      } else {
        setFilterLoanListData(loanListData)
      }
    }

    const getLastNonEmptyStatusIdJourney = (journeyData) => {
      if (!journeyData || journeyData.length === 0) {
        return {}; // or return null, undefined, or any default value that makes sense for your use case
      }
      const filteredJourneys = journeyData.filter(item => item.status_id !== '');
      return filteredJourneys[filteredJourneys.length - 1];
    }

    const handleConfirmation = (type, item) => {
      setLoanData(item)
      setType(type)
      setOpenConfirmation(!openConfirmation)
    }

    const handleApprove = (loanNumber, mobile) => {
      let obj = {
        loan_number: loanNumber,
        mobile: mobile
      }
      props.actions.acceptCPLoanEligibilityAction(obj)
    }

    const handleWithdrawn = (loanNumber, mobile) => {
      let obj = {
        loan_number: loanNumber,
        mobile: mobile
      }
      props.actions.withdrawnCPLoanEligibilityAction(obj)
    }

    const handleESign = (loanId, email) => {
      setUserEmail(email)
      let data = {
        loan_number : loanId
      }
      props.actions.createCPESignAction(data)
    }

    const handleENach = async (docID, email) => {
      setLoading(true)
      try {
        const options = {
          environment: ENACH_ENV,
          callback: async function (response) {
            if (response.hasOwnProperty("error_code")) {
              setLoading(false)
              addToast({ message: response?.message, status: 'error' })
            } else {
              setLoading(false)
              addToast({ message: response?.message, status: 'success' })
              props.actions.cpLoanListAction({status: '0,1,2,3,4,5,6,7,8,9,10,11,12'})
            }
          },
          is_iframe: true,
          Is_redirection_approach: false,
          theme: {
            primaryColor: "#000",
            secondaryColor: "#000000"
          }
        };
        var digio = new window.Digio(options);
        digio.init();
        digio.submit(docID, email);
      } catch (error) {
        setLoading(false)
        console.error("Error in digioSdkInstantiation:", error);
      }
    }

  return (
    <>
      {(cpState?.cpLoanList?.loading || adminState?.getUserByToken?.loading || loading || cpState?.createCPESignRequest?.loading || cpState?.acceptLoanEligibility?.loading || cpState?.withdrawnLoanEligibility?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className='font-poppins page_heading'>Loan List</Heading>
          </GridItem>
          <GridItem colSpan={4}>
            <NavBar data={userData} isAdmin isCP />
          </GridItem>
        </Grid>
        <Box mt={6} className='d-flex align-items-center justify-content-end'>
          <InputGroup className='search_input'>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              type="text"
              size="md"
              name="search"
              placeholder="Search"
              onChange={(e) => handleSearch(e)}
              className="input_box font-poppins"
            />
          </InputGroup>
        </Box>
        {filterLoanListData?.length > 0 ?
          <Box mt={2} className='custom_table_wrapper'>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>S.No.</Th>
                    <Th>Name</Th>
                    <Th>Mobile</Th>
                    <Th>Loan Application ID</Th>
                    <Th>Loan Amount</Th>
                    <Th>Approved Loan Amount</Th>
                    <Th>Tenure</Th>
                    <Th>Maturity Date</Th>
                    <Th>Disbursed Date</Th>
                    <Th>EMI Amount</Th>
                    <Th>Penalty Amount</Th>
                    <Th>Total Outstanding Amount</Th>
                    <Th>Last Paid Date</Th>
                    <Th>Interest Rate</Th>
                    <Th>Current Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filterLoanListData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                    const serialNumber = offset + index + 1;
                    const lastJourney = getLastNonEmptyStatusIdJourney(item?.loan_journey_data);
                    const checkENachReq = (item?.enach_request && item?.enach_request?.status === 0)
                    return (
                      <Tr key={index}>
                        <Td>
                          <Text className="font-poppins table_text">{serialNumber}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.user_data?.first_name}{' '}{item?.user_data?.last_name}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.user_data?.mobile}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.loan_number}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">₹{item?.loan_amount}</Text>
                        </Td>
                        <Td>
                          {item?.eligible_loan_amt && item?.eligible_loan_interest && item?.eligible_loan_tenure && item?.loan_status === '0' ?
                            <Box className="d-flex justify-content-end apply_btn col-gap-15">
                              <CustomButton size="md" type="submit" title="Withdrawn" className="btn_theme_transparent font-poppins" onClick={() => handleConfirmation('withdrawn', item)} />
                              <CustomButton size="md" type="submit" title="Apply" className="btn_theme font-poppins" onClick={() => handleConfirmation('apply', item)} />
                            </Box> : null
                          }
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.display_loan_tenure}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.maturity_date && moment(item.maturity_date).format("DD-MM-YYYY")}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.disbursed_date && moment(item.disbursed_date).format("DD-MM-YYYY")}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">₹{item?.eligible_loan_emi}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">₹{item?.loan_contract?.penel_interset_till_date}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">₹{item?.loan_contract?.total_outstanding_dues}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">{item?.last_emi_date && moment(item.last_emi_date).format("DD-MM-YYYY")}</Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text"> {item?.eligible_loan_interest + '% Per Annum'} </Text>
                        </Td>
                        <Td>
                          <Text className="font-poppins table_text">
                            {lastJourney?.state_label}{': '}{lastJourney?.state_status}
                          </Text>
                            {(item?.loan_status === '3' && lastJourney?.status_id === 10 && !(item?.esign_request)) ?
                                <Box mt={1} className='d-flex justify-content-center'>
                                    <CustomButton size="md" type="submit" title="E-Sign" className="btn_green font-poppins" onClick={() => handleESign(item?.loan_number, item?.user_data?.email)} />
                                </Box> : 
                            (checkENachReq && lastJourney?.status_id === 11) ?
                                <Box mt={1} className='d-flex justify-content-center'>
                                    <CustomButton size="md" type="submit" title="E-Nach" className="btn_green font-poppins" onClick={() => handleENach(item?.enach_request?.mandate_id, item?.user_data?.email)} />
                                </Box> : null
                            }
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
          </Box> :
          <NoData title="No Record Found" />
        }
      </Box>
      <LoanConfirmDialog openConfirmation={openConfirmation} handleConfirmation={handleConfirmation} type={type}
        handleApprove={handleApprove} handleWithdrawn={handleWithdrawn} loanData={loanData}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(LoanList)