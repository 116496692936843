import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Input, InputGroup, InputRightElement} from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { ActionCreators } from '../../../redux/actions'
import CustomToast from '../../elements/customToast'
import Loader from '../../elements/loader'
import { getIFSCCode, verifyBankAccount } from '../../../services/masterServices'

const BankDetails = (props) => {

  const {addToast} = CustomToast()
  const masterState = useSelector((state) => state.masterState)
  const kycState = useSelector((state) => state.kycState)
  const [inputValues, setInputValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [IFSCData, setIFSCData] = useState(null)
  const [accountData, setAccountData] = useState(null)
  const [disableSatus, setDisableSatus] = useState(null)
  useEffect(() => {
    if (kycState?.saveBankDetails?.data?.success === true){
      addToast({message: kycState?.saveBankDetails?.data?.message, status: 'success'})
      props?.actions?.cleanUpToast()
      props.actions.getCurrentUserDetailsAction()
      props.onClick()
    } else if (kycState?.saveBankDetails?.data?.success === false){
      addToast({message: kycState?.saveBankDetails?.data?.message, status: 'error'})
    }
  }, [kycState?.saveBankDetails])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ifsc') {
      const truncatedIFSC = value.slice(0, 11);
      setInputValues({ ...inputValues, [name]: truncatedIFSC.toUpperCase()});
      if(truncatedIFSC?.length == 11 ){
        getIFSCCode(truncatedIFSC).then((res) => {
          setIsLoading(false)
          if (res?.status === 200 && res?.statusText === 'OK') {
            setIFSCData(res?.data)
          } else if (res?.status !== 200) {
            addToast({message: "Not Found", status: 'error'})
          }
        }).catch((error) => {
          addToast({message: 'Not Found', status: 'error'})
          setIsLoading(false)
      })
      }
    } else {
     setInputValues({ ...inputValues, [name]: value });
    }
  }
  const handleVerifyAccount = () => {
    if (!inputValues?.ifsc || !IFSCData?.BANK || !IFSCData?.BRANCH) {
      addToast({message: 'Please Verify IFSC Code First', status: 'error'})
      return
    }
    if (!inputValues?.account) {
      addToast({message: 'Please Enter Account Number', status: 'error'})
      return
    }
    let accountData = {
      account_holder_name: '',
      account_number: inputValues?.account,
      ifsc_code: inputValues?.ifsc,
      consent: 'Y',
      // bank_name: IFSCData?.BANK,
      // bank_branch: IFSCData?.BRANCH
    }
    setIsLoading(true)
    verifyBankAccount(accountData).then((res) => {
      setDisableSatus(res?.data)
      setIsLoading(false)
        if (res?.data?.status === true && res?.data?.success === true) {
          setAccountData(res?.data?.data)
          props.actions.getCurrentUserDetailsAction()
        } else if (res?.data?.status === false) {
          addToast({message: res?.data?.message, status: 'error'})
        }
      }).catch((error) => {
        addToast({message: 'Something went wrong', status: 'error'})
        setIsLoading(false)
    })
  }

  const handleNext = () => {
    if (!props?.userData?.personal_data) {
      addToast({message: 'Please Complete Personal Details First', status: 'error'})
      return
    }
    if (!props.activeBankDetails) {
      if (!inputValues?.ifsc || !IFSCData?.BANK || !IFSCData?.BRANCH  ) {
        addToast({message: 'Please Verify IFSC Code First', status: 'error'})
        return
      }
      if (!inputValues?.account || !accountData?.account_holder_name) {
        addToast({message: 'Please Verify Bank Account Number', status: 'error'})
        return
      }
    }
    if(props.activeBankDetails) {
      props.onClick()
    } else if (!props.activeBankDetails) {
      let bankData = {
        bank_name: IFSCData?.BANK,
        bank_branch: IFSCData?.BRANCH,
        bank_city: IFSCData?.CITY,
        ifsc_code: inputValues?.ifsc,
        account_type: '0',
        account_number: inputValues?.account,
        account_holder_name: accountData?.account_holder_name,
        isVerified: accountData?.isVerified,
        bankResponse: accountData?.bankResponse,
      }
      props.actions.saveBankDetailsAction(bankData)
    }
  }
  return (
    <>
      {(isLoading || kycState?.saveBankDetails?.loading || masterState?.getCurrentUserDetails?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className='main_form_container'>
          <Heading as="h1" className="font-poppins text_heading">Fill your Bank Details</Heading>
          <Grid className="grid_container">
            <GridItem>
              <Box mb={7} className='form_kyc'>
                <Box mt={7} className='pan_box'>
                  <Heading as="h1" className="font-poppins label_heading" mb={3}>Bank IFSC Code</Heading>
                  <Box mb={3}>
                    <InputGroup>
                      <Input
                        type="text"
                        size="md"
                        name="ifsc"
                        placeholder="Enter Bank IFSC Code"
                        onChange={handleChange}
                        className="input_box font-poppins bg_white"
                        disabled={(disableSatus?.status|| props.activeBankDetails)  ? true :false }
                        value={inputValues?.ifsc ?? props?.userData?.bank_data?.ifsc_code ?? ''}
                      />
                      { props?.userData?.bank_data ||  IFSCData?.BANK ?
                      <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} /> :""
                    }
                    </InputGroup>
                    {props?.userData?.bank_data ? 
                    <Box className='verified_data'>
                      <Heading as="p" className='font-poppins'><b>Bank Name:</b> {props?.userData?.bank_data?.bank_name}
                      </Heading>
                      <Heading as="p" className='font-poppins'><b>Branch Name:</b> {props?.userData?.bank_data?.bank_branch}{', '}{props?.userData?.bank_data?.bank_city}</Heading>
                    </Box> : 
                    <Box className='verified_data'>
                      <Heading as="p" className='font-poppins'><b>Bank Name:</b> {IFSCData?.BANK}</Heading>
                      <Heading as="p" className='font-poppins'><b>Branch Name:</b> {IFSCData?.BRANCH}{IFSCData?.CITY && `, ${IFSCData?.CITY}`}</Heading>
                    </Box>
                    }
                  </Box>
                </Box>
                <Box mt={7} className='pan_box'>
                  <Heading as="h1" className="font-poppins label_heading" mb={3}>Bank Account Number</Heading>
                  <Box mb={3}>
                    <InputGroup>
                      <Input
                        type="text"
                        size="md"
                        name="account"
                        placeholder="Enter Bank Account Number"
                        onChange={handleChange}
                        className="input_box font-poppins bg_white"
                        disabled={(disableSatus?.status || props.activeBankDetails )  ? true :false}
                        value={inputValues?.account ?? props?.userData?.bank_data?.account_number ?? ''}
                      />
                      {props?.userData?.bank_data || accountData?.account_holder_name ? 
                        <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} /> :
                        <CustomButton size="md" type="submit" title="Verify" className="font-poppins btn_link" onClick={handleVerifyAccount} />
                      }
                    </InputGroup>
                    <Box className='verified_data'>
                      {props?.userData?.bank_data ? 
                        <Heading as="p" className='font-poppins'><b>Name of User:</b> {props?.userData?.bank_data?.account_holder_name}
                        </Heading> : 
                        <Heading as="p" className='font-poppins'><b>Name of User:</b> {accountData?.account_holder_name}
                        </Heading> 
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Box className="kyc_actions">
            <CustomButton size="md" type="submit" title="Next" className="btn_green font-poppins" onClick={handleNext} />
          </Box>
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(BankDetails)