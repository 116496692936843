export const GET_USER_BY_TOKEN_REQUEST = 'GET_USER_BY_TOKEN_REQUEST'
export const GET_USER_BY_TOKEN_FAILURE = 'GET_USER_BY_TOKEN_FAILURE'
export const GET_USER_BY_TOKEN_SUCCESS = 'GET_USER_BY_TOKEN_SUCCESS'

export const GET_OPERATION_DATA_REQUEST = 'GET_OPERATION_DATA_REQUEST'
export const GET_OPERATION_DATA_FAILURE = 'GET_OPERATION_DATA_FAILURE'
export const GET_OPERATION_DATA_SUCCESS = 'GET_OPERATION_DATA_SUCCESS'

export const GET_APPROVED_BOROWWER_LOAN_DATA_REQUEST = 'GET_APPROVED_BOROWWER_LOAN_DATA_REQUEST'
export const GET_APPROVED_BOROWWER_LOAN_DATA_FAILURE = 'GET_APPROVED_BOROWWER_LOAN_DATA_FAILURE'
export const GET_APPROVED_BOROWWER_LOAN_DATA_SUCCESS = 'GET_APPROVED_BOROWWER_LOAN_DATA_SUCCESS'

export const GET_USER_DETAILS_BY_ID_REQUEST = 'GET_USER_DETAILS_BY_ID_REQUEST'
export const GET_USER_DETAILS_BY_ID_FAILURE = 'GET_USER_DETAILS_BY_ID_FAILURE'
export const GET_USER_DETAILS_BY_ID_SUCCESS = 'GET_USER_DETAILS_BY_ID_SUCCESS'

export const POST_BORROWER_EXPENSES_REQUEST = 'POST_BORROWER_EXPENSES_REQUEST'
export const POST_BORROWER_EXPENSES_FAILURE = 'POST_BORROWER_EXPENSES_FAILURE'
export const POST_BORROWER_EXPENSES_SUCCESS = 'POST_BORROWER_EXPENSES_SUCCESS'

export const POST_REJECT_BORROWER_REQUEST = 'POST_REJECT_BORROWER_REQUEST'
export const POST_REJECT_BORROWER_FAILURE = 'POST_REJECT_BORROWER_FAILURE'
export const POST_REJECT_BORROWER_SUCCESS = 'POST_REJECT_BORROWER_SUCCESS'

export const POST_APPROVE_BORROWER_REQUEST = 'POST_APPROVE_BORROWER_REQUEST'
export const POST_APPROVE_BORROWER_FAILURE = 'POST_APPROVE_BORROWER_FAILURE'
export const POST_APPROVE_BORROWER_SUCCESS = 'POST_APPROVE_BORROWER_SUCCESS'

export const GET_NEW_ACCOUNT_REQUEST = 'GET_NEW_ACCOUNT_REQUEST'
export const GET_NEW_ACCOUNT_FAILURE = 'GET_NEW_ACCOUNT_FAILURE'
export const GET_NEW_ACCOUNT_SUCCESS = 'GET_NEW_ACCOUNT_SUCCESS'

export const GET_ESIGNED_LOAN_LIST_REQUEST = 'GET_ESIGNED_LOAN_LIST_REQUEST'
export const GET_ESIGNED_LOAN_LIST_FAILURE = 'GET_ESIGNED_LOAN_LIST_FAILURE'
export const GET_ESIGNED_LOAN_LIST_SUCCESS = 'GET_ESIGNED_LOAN_LIST_SUCCESS'

export const VERIFY_AGREEMENT_REQUEST = 'VERIFY_AGREEMENT_REQUEST'
export const VERIFY_AGREEMENT_FAILURE = 'VERIFY_AGREEMENT_FAILURE'
export const VERIFY_AGREEMENT_SUCCESS = 'VERIFY_AGREEMENT_SUCCESS'

export const APPROVE_USER_REQUEST = 'APPROVE_USER_REQUEST'
export const APPROVE_USER_FAILURE = 'APPROVE_USER_FAILURE'
export const APPROVE_USER_SUCCESS = 'APPROVE_USER_SUCCESS'

export const REJECT_LOAN_CONTRACT_REQUEST = 'REJECT_LOAN_CONTRACT_REQUEST'
export const REJECT_LOAN_CONTRACT_FAILURE = 'REJECT_LOAN_CONTRACT_FAILURE'
export const REJECT_LOAN_CONTRACT_SUCCESS = 'REJECT_LOAN_CONTRACT_SUCCESS'

export const CREATE_LOAN_CONTRACT_REQUEST = 'CREATE_LOAN_CONTRACT_REQUEST'
export const CREATE_LOAN_CONTRACT_FAILURE = 'CREATE_LOAN_CONTRACT_FAILURE'
export const CREATE_LOAN_CONTRACT_SUCCESS = 'CREATE_LOAN_CONTRACT_SUCCESS'

export const GET_CURRENT_COLLECTION_REQUEST ="GET_CURRENT_COLLECTION_REQUEST"
export const GET_CURRENT_COLLECTION_SUCCESS ="GET_CURRENT_COLLECTION_SUCCESS"
export const GET_CURRENT_COLLECTION_FAILURE ="GET_CURRENT_COLLECTION_FAILURE"

export const GET_CURRENT_COLLECTION_Loanid_REQUEST ="GET_CURRENT_COLLECTION_Loanid_REQUEST"
export const GET_CURRENT_COLLECTION_Loanid_SUCCESS ="GET_CURRENT_COLLECTION_Loanid_SUCCESS"
export const GET_CURRENT_COLLECTION_Loanid_FAILURE ="GET_CURRENT_COLLECTION_Loanid_FAILURE"

export const GET_COllection_LoanidDetils_Request ="GET_COllection_LoanidDetils_Request"
export const GET_COllection_LoanidDetils_SUCCESS ="GET_COllection_LoanidDetils_SUCCESS"
export const GET_COllection_LoanidDetils_FAILURE ="GET_COllection_LoanidDetils_FAILURE"

export const GET_Reiniate_Details_Request ="GET_Reiniate_Details_Request"
export const GET_Reiniate_Details_SUCCESS ="GET_Reiniate_Details_SUCCESS"
export const GET_Reiniate_Details_FAILURE ="GET_Reiniate_Details_FAILURE"

export const GET_CLOSED_LOAN_REQUEST ="GET_CLOSED_LOAN_REQUEST"
export const GET_CLOSED_LOAN_SUCCESS ="GET_CLOSED_LOAN_SUCCESS"
export const GET_CLOSED_LOAN_FAILURE ="GET_CLOSED_LOAN_FAILURE"

export const GET_CANCELEENACHMANDATE_REQUEST ="GET_CANCELEENACHMANDATE_REQUEST"
export const GET_CANCELEENACHMANDATE_SUCCES ="GET_CANCELEENACHMANDATE_SUCCES"
export const GET_CANCELEENACHMANDATE_FAILURE ="GET_CANCELEENACHMANDATE_FAILURE"