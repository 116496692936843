import React, { useEffect, useState } from 'react'
import {Box, Flex, Grid, GridItem, Heading, Input, Select, Spacer, Text} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import { connect, useSelector } from 'react-redux'
import CustomButton from '../../../elements/customButton'
import moment from 'moment'
import CustomToast from '../../../elements/customToast'
import { downloadDocumentReport } from '../../../../utilities/DownloadDocuments'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import Loader from '../../../elements/loader'
import NoData from '../../../elements/NoData'
import CheckSummaryTable from '../components/CheckSummaryTable'
import LenderDetailsTable from '../components/LenderDetailsTable'
import BorrowerDetailsTable from '../components/BorrowerDetailsTable'
import LoanDetailsTable from '../components/LoanDetailsTable'
import RepaymentDetailsTable from '../components/RepaymentDetailsTable'
import { sendH2HFile } from '../../../../services/masterServices'

const reportType = [
  {
    id: 1,
    name: 'Check Summary',
  },
  {
    id: 2,
    name: 'Lender Details',
  },
  {
    id: 3,
    name: 'Borrower Details',
  },
  {
    id: 4,
    name: 'Active Loan Details',
  },
  {
    id: 5,
    name: 'Other Loan Details',
  },
  {
    id: 6,
    name: 'Repayment Details',
  }
]

const SaleDashboard = (props) => {

  const history = useHistory()
  const { addToast } = CustomToast()
  const adminState = useSelector((state) => state.adminState)
  const salesState = useSelector((state) => state.salesState)
  const [inputValues, setInputValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [filterLenderReport, setFilterLenderReport] = useState([])
  const [filterBorrowerReport, setFilterBorrowerReport] = useState([])
  const [filterBorrowerLoanReport, setFilterBorrowerLoanReport] = useState([])
  const [filterBorrowerRepaymentReport, setFilterBorrowerRepaymentReport] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const userData = adminState?.getUserByToken?.data?.data
  const summaryReportData = salesState?.getSummaryReport?.data
  const lenderReportData = salesState?.getLenderReport?.data
  const borrowerReportData = salesState?.getBorrowerReport?.data
  const borrowerLoanReportData = salesState?.getBorrowerLoanReport?.data
  const borrowerRepaymentReportData = salesState?.getBorrowerRepaymentReport?.data
  const PER_PAGE = 20
  let data = {
    start_date: inputValues?.from_date,
    end_date : inputValues?.to_date,
  }

  useEffect(() => {
    props?.actions?.getUserByTokenAction()
    const today = moment(new Date()).format('DD-MM-YYYY')
    setInputValues({
      from_date: today,
      to_date: today,
    });
  }, [])

  useEffect(() => {
    if (salesState?.getLenderReport?.data?.success === true) {
      setFilterLenderReport(lenderReportData?.records)
    }
  }, [salesState?.getLenderReport])

  useEffect(() => {
    if (salesState?.getBorrowerReport?.data?.success === true) {
      setFilterBorrowerReport(borrowerReportData?.records)
    }
  }, [salesState?.getBorrowerReport])

  useEffect(() => {
    if (salesState?.getBorrowerLoanReport?.data?.success === true) {
      setFilterBorrowerLoanReport(borrowerLoanReportData?.records)
    }
  }, [salesState?.getBorrowerLoanReport])

  useEffect(() => {
    if (salesState?.getBorrowerRepaymentReport?.data?.success === true) {
      setFilterBorrowerRepaymentReport(borrowerRepaymentReportData?.records)
    }
  }, [salesState?.getBorrowerRepaymentReport])

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    let formattedValue = value;
    if (type === "date") {
      formattedValue = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
    setInputValues({ ...inputValues, [name]: formattedValue });
  }

  const handleGenerateReport = () => {
    if (!inputValues?.report_type) {
      addToast({message: 'Please Select Report', status: 'error'})
      return
    }
    if(inputValues?.report_type === "Check Summary") {
      props.actions.getSummaryReportAction(data)
    }
    if(inputValues?.report_type === "Lender Details") {
      const query = {
        page: currentPage,
        limit: PER_PAGE
      }
      props.actions.getLenderReportAction(data, query)
    }
    if(inputValues?.report_type === "Borrower Details") {
      const query = {
        page: currentPage,
        limit: PER_PAGE
      }
      props.actions.getBorrowerReportAction(data, query)
    }
    if(inputValues?.report_type === "Active Loan Details") {
      let obj = {
        ...data,
        stauses: '5',
      }
      const query = {
        page: currentPage,
        limit: PER_PAGE
      }
      props.actions.getBorrowerLoanReportAction(obj, query)
    }
    if(inputValues?.report_type === "Other Loan Details") {
      let obj = {
        ...data,
        stauses: '1,2,3,4,8,7,12',
      }
      const query = {
        page: currentPage,
        limit: PER_PAGE
      }
      props.actions.getBorrowerLoanReportAction(obj, query)
    }
    if(inputValues?.report_type === "Repayment Details") {
      const query = {
        page: currentPage,
        limit: PER_PAGE
      }
      props.actions.getBorrowerRepaymentReportAction(data, query)
    }
  }

  const handleDownloadReport = async (reportFile) => {
    try {
      downloadDocumentReport(reportFile)
    } catch (error) {
      console.error("Error downloading the report:", error)
    }
  }

  const handleH2HService = () => {
    setIsLoading(true)
    sendH2HFile().then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true && res?.data?.status === true) {
        addToast({message: res?.data?.message, status: 'success'})
      } else {
        addToast({message: res?.data?.message, status: 'error'})
      }
    }).catch((error) => {
      addToast({message: 'Something Went Wrong', status: 'error'})
      setIsLoading(false)
  })
  }

  const handleSearchLenders = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = lenderReportData?.records?.filter((item) => {
        return item['email']?.toLowerCase().includes(value.toLowerCase()) || item['mobile']?.toLowerCase().includes(value.toLowerCase()) || item['name']?.toLowerCase().includes(value.toLowerCase()) || item['current_status']?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterLenderReport(result)
    } else {
      setFilterLenderReport(lenderReportData?.records)
    }
  }


  const handleSearchBorrower = (e) => {
    const {value} = e.target;
    let datawithLoan_number ={
      start_date: inputValues?.from_date,
      end_date : inputValues?.to_date,
      loan_contract:value
    }
    let query = {
      page: 1,
      limit:20
    }
    if(value.length == 18){
      props.actions.getBorrowerReportAction(datawithLoan_number, query)
    }
  }


  const handleSearchBorrowerLoan = (e) => {
   
    const {value} = e.target
    if (value !== '') {
      const result = borrowerLoanReportData?.records?.filter((item) => {
        return item['borrower_name']?.toLowerCase().includes(value.toLowerCase()) || item['loan_contract_number']?.toLowerCase().includes(value.toLowerCase()) || item['lender_name']?.toLowerCase().includes(value.toLowerCase()) || item['borrower_mobile']?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterBorrowerLoanReport(result)
    } else {
      setFilterBorrowerLoanReport(borrowerLoanReportData?.records)
    }
  }

  const handleSearchBorrowerRepayment = (e) => {
    const {value} = e.target
    if (value !== '') {
      const result = borrowerRepaymentReportData?.records?.filter((item) => {
        return item['borrower_name']?.toLowerCase().includes(value.toLowerCase()) || item['borrower_mobile']?.toLowerCase().includes(value.toLowerCase()) || item['loan_contract_number']?.toLowerCase().includes(value.toLowerCase()) || item['loan_type']?.toLowerCase().includes(value.toLowerCase())
      })
      setFilterBorrowerRepaymentReport(result)
    } else {
      setFilterBorrowerRepaymentReport(borrowerRepaymentReportData?.records)
    }
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    let query = {
      page: selected + 1,
      limit: PER_PAGE
    }
    if(inputValues?.report_type === "Lender Details") {
      props.actions.getLenderReportAction(data, query)
    }
    if (inputValues?.report_type === "Borrower Details") {
      props.actions.getBorrowerReportAction(data, query)
    }
    if(inputValues?.report_type === "Active Loan Details") {
      let obj = {
        ...data,
        stauses: '5',
      }
      props.actions.getBorrowerLoanReportAction(obj, query)
    }
    if(inputValues?.report_type === "Other Loan Details") {
      let obj = {
        ...data,
        stauses: '1,2,3,4,8,7,12',
      }
      props.actions.getBorrowerLoanReportAction(obj, query)
    }
    if(inputValues?.report_type === "Repayment Details") {
      props.actions.getBorrowerRepaymentReportAction(data, query)
    }
  }

  return (
    <>
      {(adminState?.getUserByToken?.loading || salesState?.getSummaryReport?.loading || salesState?.getLenderReport?.loading || salesState?.getBorrowerReport?.loading || salesState?.getBorrowerLoanReport?.loading || salesState?.getBorrowerRepaymentReport?.loading || isLoading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className='font-poppins page_heading'>Dashboard</Heading>
          </GridItem>
          <GridItem colSpan={4}>
            <NavBar data={userData} isAdmin isSales />
          </GridItem>
        </Grid>
        <Box mt={6} className='d-flex justify-content-end'>
          <CustomButton size="md" type="submit" title="H2H Service" className="font-poppins btn_theme" onClick={handleH2HService} />
        </Box>
        <Grid className="main_grid_container" mt={7} gap={14}>
          <GridItem colSpan={6}>
            <Box className='select_report_type'>
              <Text className="font-poppins">Select Report Type</Text>
              <Select placeholder="Select Report" variant="filled" className="select_report font-poppins"
                name="report_type" onChange={handleChange} value={inputValues?.report_type ?? ''}>
                {reportType?.map((item, index) => {
                  return (
                    <option key={index} value={item.name}>{item.name}</option>
                  )
                })}
              </Select>
            </Box>
            <Box mt={3}>
              <CustomButton size="md" type="submit" title="Generate Report" className="font-poppins btn_theme" onClick={handleGenerateReport} />
            </Box>
          </GridItem>
          <GridItem colSpan={6}>
            <Flex alignItems='center' justifyContent='space-between' gap={2} className='custom_select'>
              <Box className='select_report_type'>
                <Text className="font-poppins">From Date</Text>
                <Input size="md" name="from_date" type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                  onChange={handleChange}value={moment(inputValues?.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') }
                />
              </Box>
              <Spacer />
              <Box className='select_report_type'>
                <Text className="font-poppins">To Date</Text>
                <Input size="md" name="to_date" type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                  onChange={handleChange} value={moment(inputValues?.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') }
                />
              </Box>
            </Flex>
          </GridItem>
        </Grid>
        {inputValues?.report_type === 'Check Summary' ?
          <CheckSummaryTable
            reportData={summaryReportData}
            handleDownloadReport={handleDownloadReport}
          /> :
        inputValues?.report_type === 'Lender Details' ?
          <LenderDetailsTable
            fileUrl={lenderReportData?.data?.file_url}
            reportData={filterLenderReport}
            fileLength={lenderReportData}
            handleDownloadReport={handleDownloadReport}
            handleSearch={handleSearchLenders}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            PER_PAGE={PER_PAGE}
          /> :
        inputValues?.report_type === 'Borrower Details' ?
          <BorrowerDetailsTable
            fileUrl={borrowerReportData?.data?.file_url}
            reportData={filterBorrowerReport}
            fileLength={borrowerReportData}
            handleSearch={handleSearchBorrower}
            handleDownloadReport={handleDownloadReport}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            PER_PAGE={PER_PAGE}
          /> :
        inputValues?.report_type === 'Active Loan Details' ?
          <LoanDetailsTable
            fileUrl={borrowerLoanReportData?.data?.file_url}
            reportData={filterBorrowerLoanReport}
            fileLength={borrowerLoanReportData}
            handleSearch={handleSearchBorrowerLoan}
            handleDownloadReport={handleDownloadReport}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            PER_PAGE={PER_PAGE}
          /> :
        inputValues?.report_type === 'Other Loan Details' ?
          <LoanDetailsTable
            fileUrl={borrowerLoanReportData?.data?.file_url}
            reportData={filterBorrowerLoanReport}
            fileLength={borrowerLoanReportData}
            handleSearch={handleSearchBorrowerLoan}
            handleDownloadReport={handleDownloadReport}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            PER_PAGE={PER_PAGE}
          /> :
        inputValues?.report_type === 'Repayment Details' ?
          <RepaymentDetailsTable
            fileUrl={borrowerLoanReportData?.data?.file_url}
            reportData={filterBorrowerRepaymentReport}
            fileLength={borrowerRepaymentReportData}
            handleDownloadReport={handleDownloadReport}
            handleSearch={handleSearchBorrowerRepayment}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            PER_PAGE={PER_PAGE}
          /> :
          <NoData title="No Record Found" />
        }
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(SaleDashboard)