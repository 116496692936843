import { Box, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay} from '@chakra-ui/react'
import React from 'react'
import LockIcon from '../../../assets/images/lock.svg'
import Lottie from "lottie-react";
import SuccessPinFile from '../../../assets/lottiFiles/successPin.json'
import CustomButton from '../../elements/customButton';

const SuccessPin = (props) => {

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent className='delete_modal_content'>
                <ModalCloseButton />
                <ModalBody className='delete_modal_body' pb={5} pt={6}>
                    <Box className='delete_body'>
                        <Heading as="h4" className='font-poppins'>ALPHAMONEY PIN is set successfully</Heading>
                        <Heading as="h3" mt={3} className='font-poppins'>Remember this 4 digit pin For Future transaction</Heading>
                        <Box className='pin_section' mt={10} mb={12}>
                            <Lottie animationData={SuccessPinFile} loop={false} />
                        </Box>
                        <Heading as="h3" className='font-poppins'>This keep your account Secure <Image src={LockIcon} /> </Heading>
                        <Box mt={5} className='w-100'>
                            <CustomButton size="md" type="submit" title="Continue" className="btn_theme w-100 font-poppins" />
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <>
        <Modal
            isCentered
            onClose={props.handleCloseSuccessPin}
            isOpen={props.successPin}
            motionPreset="slideInBottom"
            // size={'xl'}
        >
        {PopupBox()}
        </Modal>
    </>
  )
}
export default SuccessPin