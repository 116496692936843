import React, { useState } from 'react'
import {Box, GridItem, Heading, IconButton, Image, Input, Text} from '@chakra-ui/react'
import ApproveIcon from '../../../../../assets/images/approve.svg'
import RejectIcon from '../../../../../assets/images/reject.svg'
import ApproveWhiteIcon from '../../../../../assets/images/approve-white.svg'
import RejectWhiteIcon from '../../../../../assets/images/reject-white.svg'
import DigioVerifyIcon from '../../../../../assets/images/digioApprove.svg'

export default function BorrowerDocumentCard({item, index, inputValues, handleSelectDocument, handleChecked, handleChange, type, userData}) {

    const [isChecked, setIsChecked] = useState(null)

    return (
        <GridItem key={index} colSpan={6}>
            <Box className={`${type === item?.upload_field_name ? 'verify_card_details_active' : 'verify_card_details'} ${isChecked === 'approve' ? 'approved' : isChecked === 'reject' ? 'rejected' : ''}`}>
                <Box className='d-flex align-items-center justify-content-between document_header'>
                    <Box className='document_name'>
                        <Box className='document_status' />
                        <Heading as="h4" className='font-poppins'>{item?.title}</Heading>
                    </Box>
                    <Box className='d-flex align-items-center col-gap-15 document_details_action'>
                        <IconButton className={isChecked === 'approve' ? 
                            'approve_details_btn_active' : 'approve_details_btn'} 
                            onClick={() => {
                                setIsChecked('approve')
                                handleChecked(item?.upload_field_name, 'approve')}
                            }
                            >
                            <Image src={isChecked === 'approve' ? ApproveWhiteIcon : ApproveIcon} />
                        </IconButton>
                        <IconButton className={isChecked === 'reject' ?
                            'reject_details_btn_active' : 'reject_details_btn'} 
                            onClick={() => {
                                setIsChecked('reject')
                                handleChecked(item?.upload_field_name, 'reject')}
                            }
                            >
                            <Image src={isChecked === 'reject' ? RejectWhiteIcon : RejectIcon} />
                        </IconButton>
                    </Box>
                </Box>
                {item.title === 'PAN Card' ?
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>PAN Number:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.pancard_data?.pan}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>DOB/DOI:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.dob}</Heading>
                                </Box>
                            </Box>
                            <Box className='verify_document_data' mt={3}>
                                <Text className='font-poppins'>Name:</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.first_name}{' '}{userData?.data?.middle_name}{' '}{userData?.data?.last_name}</Heading>
                            </Box>
                            <Box className='verify_document_data' mt={3}>
                                <Text className='font-poppins'>Father’s Name:</Text>
                                <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.father_name}</Heading>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="pan_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.pan_comment ?? ''}
                            />
                        </Box>
                    </Box> :
                    <Box className='verify_document_details'>
                        <Box className='verify-form-data' onClick={() => handleSelectDocument(item?.upload_field_name)}>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap'>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Aadhaar Number:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.aadhar_card_data?.aadhar_number}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>DOB/DOI:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.dob}</Heading>
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Name:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.first_name}{' '}{userData?.data?.middle_name}{' '}{userData?.data?.last_name}</Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Father’s Name:</Text>
                                    <Heading as="h6" className='font-poppins'>{userData?.data?.personal_data?.father_name}</Heading>
                                </Box>
                            </Box>
                            <Box className='d-flex align-items-center col-gap-10 flex-wrap' mt={3}>
                                <Box className='verify_document_data width-48'>
                                    <Text className='font-poppins'>Address:</Text>
                                    <Heading as="h6" className='font-poppins'>
                                        {userData?.data?.personal_data?.address_houseNumber}
                                        {userData?.data?.personal_data?.address_landmark}
                                        {userData?.data?.personal_data?.address_street}{' '}
                                        {userData?.data?.personal_data?.address_subdistrict}{', '}
                                        {userData?.data?.personal_data?.address_district}{', '}
                                        {userData?.data?.personal_data?.address_state}{', '}
                                        {userData?.data?.personal_data?.address_pincode}
                                        {userData?.data?.personal_data?.address_country}
                                    </Heading>
                                </Box>
                                <Box className='verify_document_data width-48'>
                                    <Image src={userData?.data?.digilocker_request?.status === 'approved' ? DigioVerifyIcon : ''} />
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={6} className='comment_box'>
                            <Input type="text" size="md" name="aadhar_comment" placeholder="Comment (Optional)"
                                className="font-poppins" onChange={handleChange} value={inputValues?.aadhar_comment ?? ''}
                            />
                        </Box>
                    </Box>
                }
            </Box>
        </GridItem>
    )
}
