import React, { useEffect, useState } from 'react'
import { Box, Heading, Grid, GridItem, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text, IconButton } from "@chakra-ui/react"
import NavBar from "../../../elements/NavBar"
import { useHistory } from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import Pagination from '../../../elements/Pagination'
import InvestmentFilters from '../components/InvestmentFilters'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { downloadDocuments } from '../../../../utilities/DownloadDocuments'
import NoData from '../../../elements/NoData'
import options from '../../../../assets/JsonData/investFilterData.json'
import ImportantDialog from '../../../elements/ImportantDialog'
import ActiveInvestmentSidebar from './components/ActiveInvestmentSidebar'

const ActiveInvestments = (props) => {

    const history = useHistory()
    const investorState = useSelector(state => state.investorState)
    const masterState = useSelector((state) => state.masterState)
    const [filterData, setFilterData] = useState([])
    const [openSorting, setOpenSorting] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [sortingData, setSortingData] = useState('')
    const [currentPage, setCurrentPage] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false)
    const [sidebarData, setSidebarData] = useState(null)
    const [filterInvestActiveList, setFilterInvestActiveList] = useState([])
    const [sortOrder, setSortOrder] = useState('desc')
    const [inputValues, setInputValues] = useState({})

    const userData = masterState?.getCurrentUserDetails?.data?.data
    const investDetails = investorState?.getInvestmentDetails?.data?.data
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(investDetails?.length / PER_PAGE);

    useEffect(()=>{
     props.actions.getCurrentUserDetailsAction()
     props.actions.getInvestmentDetailsAction({status: '1'})
    },[])

    useEffect(() => {
        setFilterInvestActiveList(investorState?.getInvestmentDetails?.data?.data)
    }, [investorState?.getInvestmentDetails])

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValues({ ...inputValues, [name]: value });
    }

    const handleDropdown = (type) => {
      if (type === 'sort') {
          setOpenSorting(!openSorting)
          setOpenFilter(false)
      } else if (type === 'filter') {
          setOpenFilter(!openFilter)
          setOpenSorting(false)
      } else {
          setOpenFilter(!openFilter)
          setOpenSorting(!openSorting)
      }
    }

    const handleSearchName = (e) => {
        const {value} = e.target
        if (value !== '') {
          const result = investDetails?.filter((item) => {
            return item?.loan_data?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.borrowerDetailsData?.alpha_score?.toLowerCase().includes(value.toLowerCase())
          })
          setFilterInvestActiveList(result)
        } else {
            setFilterInvestActiveList(investDetails)
        }
      }

    const handleOpenSidebar = (item) => {
        setSidebarData(item)
        setOpenSidebar(true)
    }

    const handleCloseSidebar = () => {
        setSidebarData(null)
        setOpenSidebar(false)
    }

    const handleViewAgreement = (url) => {
        downloadDocuments(url, 'agreement')
    }

    const handleSelectSorting = (item) => {
      setSortingData(item)
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        let sortedData = [...filterInvestActiveList].sort((a, b) => {
            let valueA, valueB;
            if (item === 'Amount') {
                valueA = a.loan_data.loan_amount || 0;
                valueB = b.loan_data.loan_amount || 0;
            } else if (item === 'Tenure') {
                valueA = a.loan_data.tenure || 0;
                valueB = b.loan_data.tenure || 0;
            } else if (item === 'Risk Rating') {
              valueA = a.risk_category || 0;
              valueB = b.risk_category || 0;
            } else if (item === '% Funded') {
                valueA = a.pledged_amount || 0;
                valueB = b.pledged_amount || 0;
            } else if (item === 'Loan Type') {
              valueA = a.loan_data.product_id || 0;
              valueB = b.loan_data.product_id || 0;
          }
            // Apply sorting order
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
        setFilterInvestActiveList(sortedData)
    }

    const handleSelectFilter = (e, val) => {
      if (e.target.checked) {
          setFilterData([...filterData, val]);
      } else {
          setFilterData(filterData.filter((item) => item.id !== val.id));
      }
    }

    const handleApplyFilters = () => {
      const selectedLoanTypes = filterData.filter((filter) => options.filterOptions.loanType.some((type) => type.id === filter.id))
      const selectedRiskCategories = filterData.filter((filter) => options.filterOptions.riskType.some((type) => type.id === filter.id))
    
      let updatedList = investDetails.filter((loan) => {
        const loanTypeMatch = selectedLoanTypes.length === 0 || selectedLoanTypes.some((filter) => loan.loan_data.product_id === filter.id)
        const riskCategoryMatch = selectedRiskCategories.length === 0 || selectedRiskCategories.some((filter) => loan.risk_category === filter.name)
        let daysMatch = true
        if (inputValues.days) {
          daysMatch = loan.loan_data.tenure === parseInt(inputValues.days)
        }
        let monthsMatch = true
        if (inputValues.months) {
          monthsMatch = loan.loan_data.tenure === parseInt(inputValues.months)
        }
        return loanTypeMatch && riskCategoryMatch && daysMatch && monthsMatch
      })
      setFilterInvestActiveList(updatedList)
    }

    const handleReset = () => {
        setFilterData([])
        setInputValues({})
        setFilterInvestActiveList(investDetails)
    }

    const handleClose = (type) => {
      if (type === 'sort') {
          setOpenSorting(false)

      }
      if (type === 'filter') {
          setOpenFilter(false)
      }
    }

    const handleProfile = () => {
      history.push(routesNames.INVESTORPROFILE)
    }
    
    // const handleLogout = () => {
    //   removeAll()
    //   history.push(routesNames.LOGIN)
    // }

    return (
      <Box>
        {(investorState?.getInvestmentDetails?.loading || masterState?.getCurrentUserDetails?.loading) &&
          <div>
          <Loader />
          </div>
        }
        <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
              <Box className='d-flex align-items-center justify-content-between'>
                <Heading as="h2" className='font-poppins page_heading'>Active Investments</Heading>
                <ImportantDialog data={userData?.[0]?.usertype} />
              </Box>
            </GridItem>
            <GridItem colSpan={4}>
              <NavBar data={userData} handleProfile={handleProfile} />
            </GridItem>
          </Grid>
            <Grid className="main_grid_container">
              <GridItem colSpan={12}>
                <Box mt={5} className='d-flex align-items-center justify-content-end col-gap-15'>
                  <InvestmentFilters sortOptions={options.sortOptions} filterOptions={options.filterOptions} filterData={filterData}
                    handleDropdown={handleDropdown} handleClose={handleClose} handleSelectFilter={handleSelectFilter}
                    handleReset={handleReset} openFilter={openFilter} openSorting={openSorting} sortOrder={sortOrder}
                    sortingData={sortingData} handleSelectSorting={handleSelectSorting} handleSearchName={handleSearchName}
                    handleApplyFilters={handleApplyFilters} handleChange={handleChange} inputValues={inputValues}
                  />
                </Box>
                <>
                    {filterInvestActiveList?.length > 0 ?
                        <Box mt={3} className='custom_table_wrapper'>
                            <TableContainer>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                          <Th>Loan Application ID</Th>
                                          <Th>Loan Type</Th>
                                          <Th>Invested Amount</Th>
                                          <Th>Alpha Score</Th>
                                          <Th>Tenure</Th>
                                          <Th>Interest Rate p.a. </Th>
                                          <Th>Total Return</Th>
                                          <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                      {filterInvestActiveList?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                        return (
                                          <Tr key={index}>
                                            <Td>
                                              <Text className="font-poppins table_text">{item?.loan_data?.loan_number}</Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text text_red">
                                                {item.loan_data.loan_name}
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text">₹{item?.pledged_amount.toLocaleString('en-US')}</Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text">{item?.loan_data?.alpha_rating}</Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text">
                                                {item.loan_data.tenure_duration}
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text">
                                                {`${item.loan_data.interest_rate}%`}
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text className="font-poppins table_text">
                                                {/* ₹{item.loan_data.product_id === 1 ? ((item.pledged_amount/item.loan_data.loan_amount) * item.loan_data.emi_amount).toFixed(2) : (item.loan_data.emi_amount - item.pledged_amount).toFixed(2)} */}
                                                ₹{item?.payout_distribution?.total_outstanding_dues}
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Box className='actions_td action_table'>
                                                <IconButton onClick={() => handleOpenSidebar(item)}><ChevronRightIcon /></IconButton>
                                              </Box>
                                            </Td>
                                          </Tr>
                                        )
                                      })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                        </Box> :
                        <NoData title="No Record Found" />
                    }
                </>
              </GridItem>
            </Grid>
        </Box>
        <ActiveInvestmentSidebar openSidebar={openSidebar} handleOpenSidebar={handleOpenSidebar} handleCloseSidebar={handleCloseSidebar}
            sidebarData={sidebarData} handleViewAgreement={handleViewAgreement}
        />
      </Box>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(ActiveInvestments)