import React from 'react'
import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text } from "@chakra-ui/react"
import Pagination from '../../../../elements/Pagination';
import moment from 'moment';

export default function WalletTransactionTable(props) {
  return (
    <Box mt={3} className='custom_table_wrapper'>
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>S.No.</Th>
                        <Th>Date</Th>
                        <Th>Transaction ID</Th>
                        <Th>Transaction Ref. Number</Th>
                        <Th>Transaction Type</Th>
                        <Th>Balance</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {props.walletTransactionData?.slice(props.offset, props.offset + props.PER_PAGE)?.map((item, index) => {
                        const serialNumber = props.offset + index + 1;
                        return (
                            <Tr key={index}>
                                <Td>
                                    <Text className="font-poppins table_text">{serialNumber}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text text_red">{item.created_at && moment(item.created_at).format("DD/MM/YYYY")}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.transaction_number}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.transaction_reference_number ? item.transaction_reference_number : 'N/A'}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.transaction_type.replaceAll('_', ' ')}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">₹ {item.amount}</Text>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
        <Pagination pageCount={props.pageCount} handlePageClick={props.handlePageClick} />
    </Box> 
  )
}
