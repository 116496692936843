import React, { useEffect } from 'react'
import { Box, Grid, GridItem, Heading } from '@chakra-ui/react'
import NavBar from '../../elements/NavBar'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { ActionCreators } from '../../../redux/actions'
import Loader from '../../elements/loader'
import { useHistory } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import { removeAll, setItem } from '../../../utilities/authUtils'
import PreSanctionChart from './components/PreSanctionChart'
import UnderwriterChart from './components/UnderwriterChart'
import PostSanctionChart from './components/PostSanctionChart'

const CommonDashboard = (props) => {
  const history = useHistory()
  const adminState = useSelector((state) => state.adminState)
  const chartData = adminState?.getOperationData?.data?.data
  const userData = adminState?.getUserByToken?.data?.data
  const pre_admin =userData?.legal_name
  setItem("presanction_name",pre_admin)
  useEffect(() => {
    props.actions.getOperationDataAction()
    props.actions.getUserByTokenAction()
  }, [])

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  const createChartData = (data) => ({
    labels: [
      `Approved (${data?.approved})`,
      `New (${data?.user_requests || data?.loan_applications || data?.loan_contract})`,
      `Rejected (${data?.rejected})`,
      `Pending (${data?.pending})`
    ],
    datasets: [
      {
        data: [data?.approved, data?.user_requests || data?.loan_applications || data?.loan_contract, data?.rejected, data?.pending],
        backgroundColor: ['#97B1AB', '#357066', '#1A3732', '#344B47'],
        hoverBackgroundColor: ['#97B1AB', '#357066', '#1A3732', '#344B47'],
      }
    ]
  })

  const chartOptions = (unit) => ({
    layout: { padding: { right: 15 } },
    legend: {
      display: true,
      onClick: (e) => e.preventDefault(),
      position: 'right',
      labels: {
        fontFamily: "Poppins",
        fontColor: '#000',
        fontSize: 12,
        fontStyle: 'normal',
        usePointStyle: true,
        padding: 30,
      },
    },
    maintainAspectRatio: true,
    cutoutPercentage: 70,
    tooltips: {
      position: 'nearest',
      backgroundColor: 'rgba(255,255,255,1)',
      titleFontColor: '#000',
      bodyFontColor: '#000',
      titleFontSize: 14,
      titleFontFamily: "Poppins",
      bodyFontFamily: "Poppins",
      bodyFontSize: 14,
      bodySpacing: 4,
      xPadding: 12,
      yPadding: 12,
      borderColor: '#D3D2D5',
      borderWidth: 1,
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
        label: (tooltipItem, data) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `(${value.toLocaleString('en-US')} ${unit})`;
        }
      }
    },
    elements: { arc: { borderWidth: 0 } },
  })

  return (
    <>
      {(adminState?.getOperationData?.loading || adminState?.getUserByToken?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className='font-poppins page_heading'>Dashboard</Heading>
          </GridItem>
          <GridItem colSpan={4}>
            <NavBar data={userData} isAdmin />
          </GridItem>
        </Grid>
        <Box mt={4}>
          <Grid className="main_grid_container">
            <GridItem colSpan={4}>
              <PreSanctionChart 
                data={createChartData(chartData?.pre_sanction)} 
                options={chartOptions('Users')} 
                title="Pre Sanction" 
                pending={chartData?.pre_sanction?.pending} 
              />
            </GridItem>
            <GridItem colSpan={4}>
              <UnderwriterChart 
                data={createChartData(chartData?.underwriter)} 
                options={chartOptions('Applications')} 
                title="Underwriter" 
                pending={chartData?.underwriter?.pending} 
              />
            </GridItem>
            <GridItem colSpan={4}>
              <PostSanctionChart 
                data={createChartData(chartData?.post_sanction)} 
                options={chartOptions('Contracts')} 
                title="Post Sanction" 
                pending={chartData?.post_sanction?.pending} 
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(CommonDashboard)
