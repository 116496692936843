import React, { useEffect, useState } from 'react'
import {Box, Heading, Grid, GridItem, Image, Tab, TabList, TabPanel, TabPanels, Tabs, IconButton} from '@chakra-ui/react'
import { ViewIcon } from '@chakra-ui/icons';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Document, Page } from 'react-pdf';
import { ActionCreators } from '../../../../redux/actions'
import {useHistory} from 'react-router-dom'
import UserIcon from "../../../../assets/images/userIcon.svg";
import { getItem } from '../../../../utilities/authUtils'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import NavBar from '../../../elements/NavBar'
import Loader from '../../../elements/loader'
import OpenFIleDialog from '../../../elements/OpenFIleDialog'
import { detectFileType } from '../../../../utilities/DownloadDocuments';

const ViewApplicationSummary = (props) => {

    const history = useHistory()
    const USERID = getItem('userID')
    const LOANNUMBER = getItem('loanNumber')
    const adminState = useSelector((state) => state.adminState)
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [currentFileType, setCurrentFileType] = useState(null)
    const userDataProfile = adminState?.getUserByToken?.data?.data
    const userDetails = adminState?.getUserDetailsById?.data?.data

    let userByIdData = {
        user_id: USERID,
        loan_number: LOANNUMBER
    }

    useEffect(() => {
        props.actions.getUserByTokenAction()
        props.actions.getUserDetailsByIdAction(userByIdData)
    }, [])

    const userData = userDetails?.summaryDetails && Object.keys(userDetails?.summaryDetails).map((key) => ({
        id: key,
        name: userDetails?.summaryDetails[key]
    }));

    const fileType = {
        panCard: detectFileType(userDetails?.document_data?.pan_card),
        aadhaarFront: detectFileType(userDetails?.document_data?.aadhaar_front),
        aadhaarBack: detectFileType(userDetails?.document_data?.aadhaar_back),
        salarySlip: detectFileType(userDetails?.document_data?.salary_slip_1),
        bankStatement: detectFileType(userDetails?.document_data?.bank_statement_1),
        itr: detectFileType(userDetails?.document_data?.itr_form16),
        creditReport: detectFileType(userDetails?.credit_score_data?.credit_report_s3),
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <>
        {(adminState?.getUserDetailsById?.loading || adminState?.getUserByToken?.loading) &&
            <div>
                <Loader />
            </div>
            }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={8}>
                    <Heading as="h2" className='font-poppins page_heading'>Loan Applications Details</Heading>
                </GridItem>
                <GridItem colSpan={4}>
                    <NavBar data={userDataProfile} isAdmin />
                </GridItem>
            </Grid>
            <Box className='steps_box process_box' mt={5}>
                <Box mt={5}>
                    <Box className='profile_info'>
                        <Image src={userDetails?.photo ? userDetails?.photo : UserIcon} className="user_image" />
                        <Box className='user_profile'>
                            <Heading as="h3" className='font-poppins'>{userDetails?.first_name}{' '}{userDetails?.last_name}</Heading>
                            <Heading as="h5" className='font-poppins'>{userDetails?.mobile}</Heading>
                            <Heading as="h5" className='font-poppins'>{userDetails?.email}</Heading>
                        </Box>
                    </Box>
                </Box>
                <Box mt={5}>
                    <Grid className="main_grid_container justify-content-between">
                        <GridItem colSpan={7}>
                            <Box className='d-flex justify-content-between summary_heading'>
                                <Heading as="h6" className='font-poppins'>Summary</Heading>
                                <Heading as="h6" className='font-poppins'>Weightage</Heading>
                            </Box>
                            {userData?.map((item, index) => {
                                return (
                                    <Box key={index} className='d-flex card_data' mt={2}>
                                        <Heading as="h6" className='font-poppins box_title w-55'>{item.name.label}</Heading>
                                        <Heading as="h6" className='font-poppins box_data w-35'>{item.name.value}</Heading>
                                        <Heading as="h6" className='font-poppins box_data w-11 justify-content-center'>
                                            {item.name.weightage}/{item.name.max_weightage}
                                        </Heading>
                                    </Box>
                                )
                            })}
                            <Box className='d-flex card_data' mt={2}>
                                <Heading as="h6" className='font-poppins box_title w-55'>Alpha Score </Heading>
                                <Heading as="h6" className='font-poppins box_data w-35'>{userDetails?.loanDetails?.alpha_rating}</Heading>
                                <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                            </Box>
                            <Box className='d-flex card_data' mt={2}>
                                <Heading as="h6" className='font-poppins box_title w-55'>Net Monthly Income</Heading>
                                <Heading as="h6" className='font-poppins box_data w-35'>₹ {Number(userDetails?.occupational_data?.net_monthly_income_underwriter)?.toLocaleString('en-US')}</Heading>
                                <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                            </Box>
                            <Box className='d-flex card_data' mt={2}>
                                <Heading as="h6" className='font-poppins box_title w-55'>Current Obligation </Heading>
                                <Heading as="h6" className='font-poppins box_data w-35'>₹ {userDetails?.occupational_data?.total_monthly_obligation?.toLocaleString('en-US')}</Heading>
                                <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                            </Box>
                            <Box className='d-flex card_data' mt={2}>
                                <Heading as="h6" className='font-poppins box_title w-55'>Repaying Capacity </Heading>
                                <Heading as="h6" className='font-poppins box_data w-35'>{userDetails?.loanDetails?.repaying_capacity}</Heading>
                                <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={5} className="applied_loan">
                            <Tabs className='tabs_container details_tabs'>
                                <TabList className='tabs_list doc_tab_list'>
                                    <Tab>Aadhar Card</Tab>
                                    <Tab>PAN Card</Tab>
                                    <Tab>Salary Slip</Tab>
                                    <Tab>Bank Statement</Tab>
                                    <Tab>ITR</Tab>
                                    <Tab>Credit Report</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.document_data?.aadhaar_front ?
                                            <Box className='pdf_read'>
                                                {fileType.aadhaarFront === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.aadhaar_front}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.aadhaar_front} className='docs_image' />
                                                }
                                                {userDetails?.document_data?.aadhaar_front ?
                                                    <IconButton onClick={() => handleModal(userDetails?.document_data?.aadhaar_front, fileType.aadhaarFront)} className="icon_button_view" 
                                                        icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                        {userDetails?.document_data?.aadhaar_back ?
                                            <Box className='pdf_read'>
                                                {fileType.aadhaarBack === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.aadhaar_back}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.aadhaar_back} className='docs_image' />
                                                }
                                                {userDetails?.document_data?.aadhaar_back ?
                                                <IconButton onClick={() => handleModal(userDetails?.document_data?.aadhaar_back, fileType.aadhaarBack)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.document_data?.pan_card ?
                                            <Box className='pdf_read'>
                                                {fileType.panCard === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.pan_card}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.pan_card} className='docs_image' />
                                                }
                                                {userDetails?.document_data?.pan_card ?
                                                <IconButton onClick={() => handleModal(userDetails?.document_data?.pan_card, fileType.panCard)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.document_data?.salary_slip_1 ?
                                            <Box className='pdf_read'>
                                                {fileType.salarySlip === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.salary_slip_1}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.salary_slip_1} />
                                                }
                                                {userDetails?.document_data?.salary_slip_1 ?
                                                <IconButton onClick={() => handleModal(userDetails?.document_data?.salary_slip_1, fileType.salarySlip)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.document_data?.bank_statement_1 ?
                                            <Box className='pdf_read'>
                                                {fileType.bankStatement === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.bank_statement_1}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.bank_statement_1} />
                                                }
                                                {userDetails?.document_data?.bank_statement_1 ?
                                                <IconButton onClick={() => handleModal(userDetails?.document_data?.bank_statement_1, fileType.bankStatement)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.document_data?.itr_form16 ?
                                            <Box className='pdf_read'>
                                                {fileType.itr === 'pdf' ?
                                                    <Document file={userDetails?.document_data?.itr_form16}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.document_data?.itr_form16} />
                                                }
                                                {userDetails?.document_data?.itr_form16 ?
                                                <IconButton onClick={() => handleModal(userDetails?.document_data?.itr_form16, fileType.itr)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                    <TabPanel className='tab_panel'>
                                        {userDetails?.credit_score_data?.credit_report_s3 ?
                                            <Box className='pdf_read'>
                                                {fileType?.creditReport === 'pdf' ?
                                                    <Document file={userDetails?.credit_score_data?.credit_report_s3}>
                                                        <Page pageNumber={1} />
                                                    </Document>
                                                    : 
                                                    <Image src={userDetails?.credit_score_data?.credit_report_s3} className='docs_image' />
                                                }
                                                {userDetails?.credit_score_data?.credit_report_s3 ?
                                                    <IconButton onClick={() => handleModal(userDetails?.credit_score_data?.credit_report_s3, fileType?.creditReport)}
                                                        className="icon_button_view"  icon={<ViewIcon />}
                                                    /> : null
                                                }
                                            </Box> : 
                                            <Box className='pdf_read no-data'>
                                                <Heading as="h6" className='font-poppins'>No document available</Heading>
                                            </Box>
                                        }
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
        </Box>
        <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
})
  
export default connect(null, mapDispatchToProps)(ViewApplicationSummary)