import React, { useEffect, useState } from 'react'
import { Box, Image, Stack } from '@chakra-ui/react'
import Logo from "../../assets/images/app_logo.svg";
import DashboardIcon from '../../assets/images/dashboardBorrower.svg';
import DashboardWhiteIcon from '../../assets/images/dashboardBorrowerWhite.svg';
import LoanIcon from '../../assets/images/loanAppBorrower.svg';
import LoanWhiteIcon from '../../assets/images/loanAppBorrowerWhite.svg';
import LoanStatusIcon from '../../assets/images/loanStatus.svg';
import LoanStatusWhiteIcon from '../../assets/images/loanStatusWhite.svg';
import ActiveLoanIcon from '../../assets/images/activeLoanBorrower.svg';
import ActiveLoanWhiteIcon from '../../assets/images/activeLoanBorrowerWhite.svg';
import ClosedLoanIcon from '../../assets/images/closedLoansBorrower.svg';
import ClosedLoanWhiteIcon from '../../assets/images/closedLoansBorrowerWhite.svg';
import * as routesNames from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom'

const menus = [
  {
      id: 1,
      menuName: 'Dashboard',
      link: routesNames.BORROWERDASHBOARD,
      menuIcon: DashboardIcon,
      menuIconActive: DashboardWhiteIcon,
  },
  {
      id: 2,
      menuName: 'Apply Loan',
      link: routesNames.BORROWERAPPLYLOAN,
      menuIcon: LoanIcon,
      menuIconActive: LoanWhiteIcon,
      // subMenu: [
      //   {
      //     id: 21,
      //     menuName: 'Loan Status',
      //     link: routesNames.BORROWERLOANSTATUS,
      //     menuIcon: LoanStatusIcon,
      //     menuIconActive: LoanStatusWhiteIcon,
      //   }
      // ]
  },
  {
    id: 3,
    menuName: 'Loan Status',
    link: routesNames.BORROWERLOANSTATUS,
    menuIcon: LoanStatusIcon,
    menuIconActive: LoanStatusWhiteIcon,
  },
  {
      id: 4,
      menuName: 'Active Loan',
      link: routesNames.BORROWERACTIVELOAN,
      menuIcon: ActiveLoanIcon,
      menuIconActive: ActiveLoanWhiteIcon,
  },
  {
      id: 5,
      menuName: 'Closed Loans',
      link: routesNames.BORROWERCLOSEDLOAN,
      menuIcon: ClosedLoanIcon,
      menuIconActive: ClosedLoanWhiteIcon,
  },
  {
    id: 6,
    menuName: 'Expired Loans',
    link: routesNames.BORROWEREXPIREDLOAN,
    menuIcon: ClosedLoanIcon,
    menuIconActive: ClosedLoanWhiteIcon,
  },
  {
    id: 7,
    menuName: 'Withdrawn Loans',
    link: routesNames.BORROWERWITHDRAWNLOAN,
    menuIcon: ClosedLoanIcon,
    menuIconActive: ClosedLoanWhiteIcon,
  },
]

export default function SidebarBorrower() {

  const location = useLocation();
  const [menuId, setMenuId] = useState(null)  

  useEffect(() => {
    const menuItem = menus.find((item) => item.link === location.pathname);
    console.log(menuItem, 'menuItem')
    if (menuItem) {
        setMenuId(menuItem.id);
    }
  }, [location.pathname]);
  
  const handleMenu = (item) => {
      setMenuId(item)
  }

  return (
    <Box className='sidebar_wrapper'>
      <Link to={routesNames.BORROWERDASHBOARD}>
       <Image src={Logo} alt='logo' className='app_logo' />
      </Link>
      <Stack className="menu_box">
        {menus?.map((item) => {
          return (
              <Link key={item.id} to={item.link} onClick={() => handleMenu(item.id)}
                className={item.id === menuId ? 'active_menu_item menu_item font-poppins' : 'menu_item font-poppins'} >
                <Image src={item.id === menuId ? item.menuIconActive : item.menuIcon} className="icon_size" />
                  {item.menuName}
              </Link>
            )
        })}
      </Stack>
       {/* {item?.subMenu?.map((elem) => {
                return (
                <Link key={elem.id} to={elem.link} onClick={() => handleMenu(elem.id)}
                  className={elem.id === menuId ? 'active_menu_item menu_item font-poppins' : 'menu_item font-poppins'} >
                  <Image src={elem.id === menuId ? elem.menuIconActive : elem.menuIcon} className="icon_size" />
                    {elem.menuName}
                </Link>
                )
              })} */}
    </Box>
  )
}
