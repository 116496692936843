export const GET_CP_DASHBOARD_DETAILS_REQUEST = 'GET_CP_DASHBOARD_DETAILS_REQUEST'
export const GET_CP_DASHBOARD_DETAILS_FAILURE = 'GET_CP_DASHBOARD_DETAILS_FAILURE'
export const GET_CP_DASHBOARD_DETAILS_SUCCESS = 'GET_CP_DASHBOARD_DETAILS_SUCCESS'

export const GET_CP_USERS_REQUEST = 'GET_CP_USERS_REQUEST'
export const GET_CP_USERS_FAILURE = 'GET_CP_USERS_FAILURE'
export const GET_CP_USERS_SUCCESS = 'GET_CP_USERS_SUCCESS'

export const GET_CP_PAYOUT_REQUEST = 'GET_CP_PAYOUT_REQUEST'
export const GET_CP_PAYOUT_FAILURE = 'GET_CP_PAYOUT_FAILURE'
export const GET_CP_PAYOUT_SUCCESS = 'GET_CP_PAYOUT_SUCCESS'

export const GET_CP_USER_BY_ID_REQUEST = 'GET_CP_USER_BY_ID_REQUEST'
export const GET_CP_USER_BY_ID_FAILURE = 'GET_CP_USER_BY_ID_FAILURE'
export const GET_CP_USER_BY_ID_SUCCESS = 'GET_CP_USER_BY_ID_SUCCESS'

export const CREATE_CP_DIGILOCKER_REQUEST = 'CREATE_CP_DIGILOCKER_REQUEST'
export const CREATE_CP_DIGILOCKER_FAILURE = 'CREATE_CP_DIGILOCKER_FAILURE'
export const CREATE_CP_DIGILOCKER_SUCCESS = 'CREATE_CP_DIGILOCKER_SUCCESS'

export const GET_CP_DOWNLOAD_AADHAR_DATA_REQUEST = 'GET_CP_DOWNLOAD_AADHAR_DATA_REQUEST'
export const GET_CP_DOWNLOAD_AADHAR_DATA_FAILURE = 'GET_CP_DOWNLOAD_AADHAR_DATA_FAILURE'
export const GET_CP_DOWNLOAD_AADHAR_DATA_SUCCESS = 'GET_CP_DOWNLOAD_AADHAR_DATA_SUCCESS'

export const SAVE_CP_BANK_DETAILS_REQUEST = 'SAVE_CP_BANK_DETAILS_REQUEST'
export const SAVE_CP_BANK_DETAILS_FAILURE = 'SAVE_CP_BANK_DETAILS_FAILURE'
export const SAVE_CP_BANK_DETAILS_SUCCESS = 'SAVE_CP_BANK_DETAILS_SUCCESS'

export const GENERATE_CP_ORDER_REQUEST = 'GENERATE_CP_ORDER_REQUEST'
export const GENERATE_CP_ORDER_FAILURE = 'GENERATE_CP_ORDER_FAILURE'
export const GENERATE_CP_ORDER_SUCCESS = 'GENERATE_CP_ORDER_SUCCESS'

export const GET_CP_PAYMENT_STATUS_REQUEST = 'GET_CP_PAYMENT_STATUS_REQUEST'
export const GET_CP_PAYMENT_STATUS_FAILURE = 'GET_CP_PAYMENT_STATUS_FAILURE'
export const GET_CP_PAYMENT_STATUS_SUCCESS = 'GET_CP_PAYMENT_STATUS_SUCCESS'

export const UPDATE_CP_ORDER_STATUS_REQUEST = 'UPDATE_CP_ORDER_STATUS_REQUEST'
export const UPDATE_CP_ORDER_STATUS_FAILURE = 'UPDATE_CP_ORDER_STATUS_FAILURE'
export const UPDATE_CP_ORDER_STATUS_SUCCESS = 'UPDATE_CP_ORDER_STATUS_SUCCESS'

export const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST'
export const CREATE_LOAN_FAILURE = 'CREATE_LOAN_FAILURE'
export const CREATE_LOAN_SUCCESS = 'CREATE_LOAN_SUCCESS'

export const CP_LOAN_LIST_REQUEST = 'CP_LOAN_LIST_REQUEST'
export const CP_LOAN_LIST_FAILURE = 'CP_LOAN_LIST_FAILURE'
export const CP_LOAN_LIST_SUCCESS = 'CP_LOAN_LIST_SUCCESS'

export const CREATE_CP_ESIGN_REQUEST = 'CREATE_CP_ESIGN_REQUEST'
export const CREATE_CP_ESIGN_FAILURE = 'CREATE_CP_ESIGN_FAILURE'
export const CREATE_CP_ESIGN_SUCCESS = 'CREATE_CP_ESIGN_SUCCESS'

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST'
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'

export const ACCEPT_CP_LOAN_ELIGIBILITY_REQUEST = 'ACCEPT_CP_LOAN_ELIGIBILITY_REQUEST'
export const ACCEPT_CP_LOAN_ELIGIBILITY_FAILURE = 'ACCEPT_CP_LOAN_ELIGIBILITY_FAILURE'
export const ACCEPT_CP_LOAN_ELIGIBILITY_SUCCESS = 'ACCEPT_CP_LOAN_ELIGIBILITY_SUCCESS'

export const WITHDRAWN_CP_LOAN_ELIGIBILITY_REQUEST = 'WITHDRAWN_CP_LOAN_ELIGIBILITY_REQUEST'
export const WITHDRAWN_CP_LOAN_ELIGIBILITY_FAILURE = 'WITHDRAWN_CP_LOAN_ELIGIBILITY_FAILURE'
export const WITHDRAWN_CP_LOAN_ELIGIBILITY_SUCCESS = 'WITHDRAWN_CP_LOAN_ELIGIBILITY_SUCCESS'