export const GET_INVESTOR_DASHBOARD_DETAILS_REQUEST = 'GET_INVESTOR_DASHBOARD_DETAILS_REQUEST'
export const GET_INVESTOR_DASHBOARD_DETAILS_FAILURE = 'GET_INVESTOR_DASHBOARD_DETAILS_FAILURE'
export const GET_INVESTOR_DASHBOARD_DETAILS_SUCCESS = 'GET_INVESTOR_DASHBOARD_DETAILS_SUCCESS'

export const GET_INVESTMENT_OPPORTUNITIES_REQUEST = 'GET_INVESTMENT_OPPORTUNITIES_REQUEST'
export const GET_INVESTMENT_OPPORTUNITIES_SUCCESS = 'GET_INVESTMENT_OPPORTUNITIES_SUCCESS'
export const GET_INVESTMENT_OPPORTUNITIES_FAILURE = 'GET_INVESTMENT_OPPORTUNITIES_FAILURE'

export const GET_QUICK_INVESTMENT_REQUEST = 'GET_QUICK_INVESTMENT_REQUEST'
export const GET_QUICK_INVESTMENT_SUCCESS = 'GET_QUICK_INVESTMENT_SUCCESS'
export const GET_QUICK_INVESTMENT_FAILURE = 'GET_QUICK_INVESTMENT_FAILURE'

export const GET_INVESTOR_PORTFOLIO_DETAILS_REQUEST = 'GET_INVESTOR_PORTFOLIO_DETAILS_REQUEST'
export const GET_INVESTOR_PORTFOLIO_DETAILS_SUCCESS = 'GET_INVESTOR_PORTFOLIO_DETAILS_SUCCESS'
export const GET_INVESTOR_PORTFOLIO_DETAILS_FAILURE = 'GET_INVESTOR_PORTFOLIO_DETAILS_FAILURE'

export const GET_WALLET_TRANSACTION_REQUEST = 'GET_WALLET_TRANSACTION_REQUEST'
export const GET_WALLET_TRANSACTION_SUCCESS = 'GET_WALLET_TRANSACTION_SUCCESS'
export const GET_WALLET_TRANSACTION_FAILURE = 'GET_WALLET_TRANSACTION_FAILURE'

export const GET_INVESTOR_WALLET_REQUEST = 'GET_INVESTOR_WALLET_REQUEST'
export const GET_INVESTOR_WALLET_SUCCESS = 'GET_INVESTOR_WALLET_SUCCESS'
export const GET_INVESTOR_WALLET_FAILURE = 'GET_INVESTOR_WALLET_FAILURE'

export const GET_INVESTMENT_DETAILS_REQUEST = 'GET_INVESTMENT_DETAILS_REQUEST'
export const GET_INVESTMENT_DETAILS_SUCCESS = 'GET_INVESTMENT_DETAILS_SUCCESS'
export const GET_INVESTMENT_DETAILS_FAILURE = 'GET_INVESTMENT_DETAILS_FAILURE'

export const GET_INVESTOR_LOAN_LIST_STATUS_REQUEST = 'GET_INVESTOR_LOAN_LIST_STATUS_REQUEST'
export const GET_INVESTOR_LOAN_LIST_STATUS_SUCCESS = 'GET_INVESTOR_LOAN_LIST_STATUS_SUCCESS'
export const GET_INVESTOR_LOAN_LIST_STATUS_FAILURE = 'GET_INVESTOR_LOAN_LIST_STATUS_FAILURE'

export const GET_INVEST_IN_LOAN_REQUEST = 'GET_INVEST_IN_LOAN_REQUEST'
export const GET_INVEST_IN_LOAN_SUCCESS = 'GET_INVEST_IN_LOAN_SUCCESS'
export const GET_INVEST_IN_LOAN_FAILURE = 'GET_INVEST_IN_LOAN_FAILURE'

export const GET_WALLET_PASSBOOK_REQUEST = 'GET_WALLET_PASSBOOK_REQUEST'
export const GET_WALLET_PASSBOOK_SUCCESS = 'GET_WALLET_PASSBOOK_SUCCESS'
export const GET_WALLET_PASSBOOK_FAILURE = 'GET_WALLET_PASSBOOK_FAILURE'

export const WITHDRAW_WALLET_AMOUNT_REQUEST = 'WITHDRAW_WALLET_AMOUNT_REQUEST'
export const WITHDRAW_WALLET_AMOUNT_SUCCESS = 'WITHDRAW_WALLET_AMOUNT_SUCCESS'
export const WITHDRAW_WALLET_AMOUNT_FAILURE = 'WITHDRAW_WALLET_AMOUNT_FAILURE'

export const POST_NOMINEE_DETAILS_REQUEST = 'POST_NOMINEE_DETAILS_REQUEST'
export const POST_NOMINEE_DETAILS_SUCCESS = 'POST_NOMINEE_DETAILS_SUCCESS'
export const POST_NOMINEE_DETAILS_FAILURE = 'POST_NOMINEE_DETAILS_FAILURE'

export const POST_PAYMENT_MODE_REQUEST = 'POST_PAYMENT_MODE_REQUEST'
export const POST_PAYMENT_MODE_SUCCESS = 'POST_PAYMENT_MODE_SUCCESS'
export const POST_PAYMENT_MODE_FAILURE = 'POST_PAYMENT_MODE_FAILURE'