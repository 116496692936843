import React from 'react'
import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Textarea } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'

export default function ApproveDialog(props) {

    const modalType = props.type

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="confirm_title font-poppins">
                    Are Your Sure want Submit to this KYC Document
                </ModalHeader>
                <ModalBody>
                    <Box mb={5} className='d-flex align-items-center justify-content-center col-gap-15'>
                        <CustomButton size="md" type="submit" title="Go Back" className="btn_cancel font-poppins" onClick={props.handleConfirmation} />
                        <CustomButton size="md" type="submit" title="Submit" className="btn_confirm font-poppins" onClick={props.handleSubmit} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleConfirmation}
        isOpen={props.openConfirmation}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
