import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Tabs,
  Image,
  Input,
  Text,
  TableContainer,
  Table,
  Th,
  Tbody,
  Tr,
  Td,
  Thead,
  Button
} from "@chakra-ui/react";
import {
  getItem,
  setItem,
} from "../../../../../utilities/authUtils";
import { useHistory } from "react-router-dom";
import * as routesNames from "../../../../../constants/routes";
import CustomButton from "../../../../elements/customButton";
import Loader from "../../../../elements/loader";
import { connect, useSelector } from "react-redux";
import { ActionCreators } from "../../../../../redux/actions";
import { bindActionCreators } from "redux";
import { ViewIcon } from "@chakra-ui/icons";
import CustomToast from "../../../../elements/customToast";
// import KycDetails_Collection from './components/BorrowerDocumentCard';
import moment from "moment";
import Pagination from "../../../../elements/Pagination";
import NoData from "../../../../elements/NoData";
import { detectFileType } from "../../../../../utilities/DownloadDocuments";
import OpenFIleDialog from "../../../../elements/OpenFIleDialog";

const Collection_Loan_Details = (props) => {
  const history = useHistory();
  const [currentFileType, setCurrentFileType] = useState(null);
  const USERID = getItem("loan_ID");
  const boRRowerID = getItem("boRRower_ID");
  const loandetailsType = getItem("loandetails_type");
  const adminState = useSelector((state) => state.adminState);
  // const newBorrowers = adminState?.getCurrentCollection?.data?.data
  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [CKYCData, setCKYCData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterLoanListData, setFilterLoanListData] = useState([]);
  const loan_list_tableData =adminState?.getCurrentCollection_loan_list?.data?.data
  const PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(filterLoanListData?.length / PER_PAGE);
  let pass_ckyc_data = filterData[0]?.ckyc_data? filterData[0]?.ckyc_data?.ckyc_number:"Not Avaliable";
  setItem("ckyc_number",pass_ckyc_data )
  const filteredUserData = userData?.checklist_master
    ?.filter(
      (item) =>
        item.version === 2 &&
        (item.title === "PAN Card" || item.title === "Aadhaar Card")
    )
    .slice(0, 2);
  let userByIdData = {
    type: loandetailsType,
    loan_id: USERID,
  };
  let userByIdData_loan={
    loan_id: USERID,
    borrower_id:boRRowerID
  }
  useEffect(() => {
    props?.actions?.getCurrentCollectionLoanidAction(
      { type: "loan_id" },
      userByIdData
    );
    props?.actions?.getCOllection_LoanidDetilsAction(userByIdData_loan)
  }, []);
  useEffect(() => {
    if (adminState?.getCurrentCollection_loan?.data?.success === true) {
      setFilterData(adminState?.getCurrentCollection_loan?.data?.data);
    }
  }, [adminState?.getCurrentCollection_loan]);

  useEffect(() => {
    if (adminState?.getCurrentCollection_loan_list?.data?.success === true) {
      setFilterLoanListData(adminState?.getCurrentCollection_loan_list?.data?.data);
    }
  }, [adminState?.getCurrentCollection_loan_list]);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const showOpenFileDialog = () => {
    history.push(routesNames.ADMINUSERCOLLECTION_KYC_Details);
  };

  const fileType = {
    photo: detectFileType(filterLoanListData?.user_data?.photo),
  };

  const handleModal = (data, fileType) => {
    setOpenModal(!openModal);
    setModalData(data);
    setCurrentFileType(fileType);
  };
  // const handleLogout = () => {
  //     removeAll()
  //     history.push(routesNames.LOGIN)
  // }

  const handleSearch = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const result = loan_list_tableData.filter((item) => {
        return (
          item?.mobile?.toLowerCase().includes(value.toLowerCase()) ||
          item?.first_name?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilterLoanListData(result);
    } else {
      setFilterLoanListData(loan_list_tableData);
    }
  };
  return (
    <>
      {(adminState?.getUserDetailsById?.loading ||
        adminState?.getUserByToken?.loading ||
        adminState?.approveUser?.loading ||
        isLoading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box className="dashboard_box bg_shadow">
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Heading as="h2" className="font-poppins page_heading">
              Loan Details
            </Heading>
          </GridItem>
          {/* <GridItem colSpan={4}>
                    <NavBar data={userDataProfile} isAdmin  />
                </GridItem> */}
        </Grid>
        <Box mt={5} className="bg_shadow">
          <Grid className="main_grid_container">
            <GridItem colSpan={7} className="verify_container ">
              <Box className="d-flex align-items-center justify-content-between">
                <Box className="change_profile">
                  <Box className="change_profile_container">
                    <Image
                      src={filterLoanListData?.user_data?.photo}
                      className="change_profile_image"
                    />
                    <IconButton
                      onClick={() =>
                        handleModal(filterLoanListData?.user_data?.photo, fileType.photo)
                      }
                      className="icon_button_view_photo"
                      icon={<ViewIcon />}
                    />
                  </Box>
                  <Box className="user_change_profile">
                    <Heading as="h3" className="font-poppins">
                      {filterLoanListData?.user_data?.first_name}{" "}
                      {filterLoanListData?.user_data?.last_name}
                    </Heading>
                    <Heading as="h5" className="font-poppins">
                      Borrower ID:{" "}
                      <span>{filterLoanListData?.user_data?.id}</span>
                    </Heading>
                    {/* <Text className="font-poppins digio_active">
                      {filterData[0]?.loan_number}
                    </Text> */}
                  </Box>
                </Box>
                <Box className="user_profile_details">
                  <Heading as="h6" className="font-poppins">{filterLoanListData?.user_data?.email}</Heading>
                  <Text className="font-poppins">{filterLoanListData?.user_data?.mobile}</Text>
                  <CustomButton
                    size="sm"
                    type="submit"
                    title="View KYC Details"
                    className="collection_btn_green font-poppins"
                    // style={{ backgroundColor: "linear-gradient(256.51deg, #135346 0%, #23967F 99.21%)" }}
                    onClick={() => showOpenFileDialog()}
                  />
                  {/* <Input
                                    size="md"
                                    name="upload"
                                    type="file"
                                    accept=".zip"
                                    onChange={(e) => onChangeFile(e)}
                                    className="d-none"
                                    ref={CKYCRef}
                                /> */}
                </Box>
              </Box>
              {CKYCData?.data && (
                <Grid className="main_grid_container" mt={5}>
                  <GridItem colSpan={6}>
                    <Box className="verify_card_details">
                      <Box className="d-flex align-items-center justify-content-between document_header">
                        <Box className="document_name">
                          <Box className="document_status" />
                          <Heading as="h4" className="font-poppins">
                            CKYC
                          </Heading>
                        </Box>
                      </Box>
                      <Box className="verify_document_details">
                        <Box className="verify-form-data">
                          <Box className="d-flex align-items-center col-gap-10 flex-wrap">
                            <Box className="verify_document_data width-48">
                              <Text className="font-poppins">CKYC Number:</Text>
                              <Heading as="h6" className="font-poppins">
                                {CKYCData?.data?.ckycNumber}
                              </Heading>
                            </Box>
                            <Box className="verify_document_data width-48">
                              <Text className="font-poppins">
                                Father’s Name:
                              </Text>
                              <Heading as="h6" className="font-poppins">
                                {CKYCData?.data?.fatherName}
                              </Heading>
                            </Box>
                          </Box>
                          <Box
                            className="d-flex align-items-center col-gap-10 flex-wrap"
                            mt={3}
                          >
                            <Box className="verify_document_data width-48">
                              <Text className="font-poppins">Id Proof:</Text>
                              <Heading as="h6" className="font-poppins">
                                {CKYCData?.data?.idProofNumber}
                              </Heading>
                            </Box>
                            <Box className="verify_document_data width-48">
                              <Text className="font-poppins">
                                Phone Number:
                              </Text>
                              <Heading as="h6" className="font-poppins">
                                {CKYCData?.data?.phoneNumber}
                              </Heading>
                            </Box>
                          </Box>
                          <Box className="verify_document_data" mt={3}>
                            <Text className="font-poppins">Address:</Text>
                            <Heading as="h6" className="font-poppins">
                              {CKYCData?.data?.address1}
                            </Heading>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                </Grid>
              )}
            </GridItem>
          </Grid>
          <Box mt={5}>
            <Grid className="main_grid_container justify-content-between">
              <GridItem colSpan={7} ml={4}>
                <Box className="d-flex justify-content-between collection_heading">
                  <Heading as="h6" className="font-poppins">
                    Collection Details
                  </Heading>
                  {/* <Heading as="h6" className='font-poppins'>Weightage</Heading> */}
                </Box>

                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Collection Status{" "}
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.display_status ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Number 
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                   {filterData[0]?.loan_number ?? "NA"}
                  </Heading>
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    UMRN 
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.UMRN ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Amortisation Number
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.amortization_txnID ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Contract{" "}
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.loan_contract_number ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Disbursed Date
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.disbursed_date ?? "NA"}
                  </Heading>
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan EMI Date{" "}
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.emi_date ?? "NA"}
                  </Heading>
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Presentation Date
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.presentation_date ?? "NA"}
                  </Heading>
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Debit Date
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.emi_debit_date ?? "NA"}
                  </Heading>
                </Box>
              </GridItem>
              <GridItem colSpan={5} className="applied_loan">
                <Box className="d-flex justify-content-between  collection_heading">
                  <Heading as="h6" className="font-poppins">
                    {" "}
                    Loan Details
                  </Heading>
                  {/* <Heading as="h6" className='font-poppins'>Weightage</Heading> */}
                </Box>

                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Type{" "}
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.loan_type ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Amount
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {" "}
                    {filterData[0]?.loan_amount ?? "NA"}{" "}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan Purpose
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {filterData[0]?.loan_purpose ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Loan tenure
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {filterData[0]?.tenure ?? "NA"}
                  </Heading>
                  {/* <Heading as="p" className='font-poppins box_data w-11 justify-content-center'></Heading> */}
                </Box>
                <Box className="d-flex card_data" mt={2}>
                  <Heading as="h6" className="font-poppins box_title w-55">
                    Rate Of Interest
                  </Heading>
                  <Heading as="h6" className="font-poppins box_data w-35">
                    {filterData[0]?.interest_rate ?? "NA"}
                  </Heading>
                </Box>
              </GridItem>
            </Grid>
            <Grid
              className="main_grid_container justify-content-between"
              mt={5}
              ml={4}
            >
              <GridItem colSpan={7}>
                <Box className="d-flex justify-content-between collection_heading">
                  <Heading as="h6" className="font-poppins">
                    Loan Table Details
                  </Heading>
                  {/* <Heading as="h6" className='font-poppins'>Weightage</Heading> */}
                </Box>
                {filterLoanListData?.borrower_emi?.length > 0 ? (
                  <Box
                    mt={2}
                    className="custom_table_wrapper custom_table_wrapper2"
                   
                  >
                    <TableContainer className="scoll-hidden" >
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>S.No.</Th>
                            <Th>EMI</Th>
                            <Th>Interest</Th>
                            <Th>Principal </Th>
                            <Th>Balance</Th>
                            <Th>Emi Date</Th>
                            <Th> Debit Date</Th>
                            <Th>Status</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {filterLoanListData?.borrower_emi
                            ?.slice(offset, offset + PER_PAGE)
                            ?.map((item, index) => {
                              const serialNumber = offset + index + 1;
                              return (
                                <Tr key={index}>
                                  <Td>
                                    <Text className="font-poppins table_text">
                                      {serialNumber}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text className="font-poppins table_text">
                                         {item?.emiAmount ? `₹ ${item.emiAmount}` : "Not Assigned"}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text className="font-poppins table_text">
                                    {item?.interestAmount ? `₹ ${item.interestAmount}` : "Not Assigned"}
                                    </Text>
                                  </Td>
                                  <Td>
                                      <Text className="font-poppins table_text">
                                         {item?.principalAmount ? `₹ ${item.principalAmount}` : "Not Assigned"}

                                    </Text>
                                  </Td>
                                  <Td>
                                  <Text className="font-poppins table_text">
                                         {item?.principalOutstanding ? `₹ ${item.principalOutstanding}` : "Not Assigned"}

                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text className="font-poppins table_text">
                                      {item?.emi_date
                                        ? moment(
                                            item?.emi_date
                                          ).format("DD-MMM-YYYY")
                                        : "Not Available"}
                                    </Text>
                                  </Td>
                             
                              <Td>
                              <Text className="font-poppins table_text">
                                {item?.emi_debit_date
                                  ? moment(
                                      item?.emi_debit_date
                                    ).format("DD-MMM-YYYY")
                                  : "Not Available"}
                              </Text>
                            </Td>
                            {/* penel_interset */}
                                  {/* <Td>
                                    <Button className={` font-poppins  ${ item?.penel_interset ? "btn_table_red-collection " : item?.display_status=='in-process'  ? "btn_table_orange-collection":"btn_table_upcoming-collection"}` }>
                                      {item?.display_status}
                                    </Button>
                                  </Td> */}
                                  <Td>
                                  <Text size="md" className='font-poppins gray_text' >
                                                          {item?.display_status}
                                                      </Text>
                                  </Td>
                                 
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                     className="pagecls"
                    />
                  </Box>
                ) : (
                  <NoData title="No Record Found" />
                )}
              </GridItem>
              <GridItem colSpan={5} className="applied_loan">
                {/* <Tabs className='tabs_container details_tabs'>
                             
                            </Tabs> */}
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </Box>
      <OpenFIleDialog
        handleModal={handleModal}
        openModal={openModal}
        fileType={currentFileType}
        modalData={modalData}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(Collection_Loan_Details);
