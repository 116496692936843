import React, { useEffect, useRef, useState } from 'react'
import { Box, Heading, Grid, GridItem, Image, Tooltip, Input } from "@chakra-ui/react"
import { connect, useSelector } from 'react-redux'
import * as routesNames from '../../../../constants/routes'
import NavBar from "../../../elements/NavBar"
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import WalletBalancePassbook from './Components/WalletBalancePassbook'
import WalletCard from './Components/WalletCard'
import Loader from '../../../elements/loader'
import CustomButton from '../../../elements/customButton'
import WithDrawIcon from '../../../../assets/images/withdraw.svg'
import AddFundsIcon from '../../../../assets/images/add-funds.svg'
import WalletTransactionTable from './Components/WalletTransactionTable'
import PaymentDialogInvestor from '../../../modules/PaymentModule/PaymentDialogInvestor'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import AddFundsDialog from './Components/AddFundsDialog'
import CustomToast from '../../../elements/customToast'
import WithdrawFundsDialog from './Components/WithdrawFundsDialog'
import DownloadPassBook from './Components/DownloadPassBook'
import ImportantDialog from '../../../elements/ImportantDialog'
import WaitIcon from '../../../../assets/images/wait.svg'
import RejectedIcon from '../../../../assets/images/decline.svg'
import { uploadNetworthCertificate } from '../../../../services/masterServices'
import moment from 'moment'
import { getItem } from '../../../../utilities/authUtils'

  const periodData = [
    {
      id: 1,
      name: '10,00,000',
    },
    {
      id: 2,
      name: '1,00,000',
    },
    {
      id: 3,
      name: '75,000',
    },
    {
      id: 4,
      name: '50,000',
    },
    {
        id: 5,
        name: '25,000',
    },
    {
        id: 6,
        name: '10,000',
    },
  ]

  const timePeriod = [
    {
        id: 1,
        name: '3 Months',
    },
    {
        id: 2,
        name: '6 Months',
    },
    {
        id: 3,
        name: '1 Year',
    },
    {
        id: 4,
        name: 'Custom',
    }
  ]

const Wallet = (props) =>{

    const MAXAMOUNT = 5000000
    const history = useHistory()
    const certificateRef = useRef()
    const { addToast } = CustomToast()
    const masterState = useSelector((state) => state.masterState)
    const investorState = useSelector((state) =>state.investorState)
    const [currentPage, setCurrentPage] = useState(0);
    const [inputValues, setInputValues] = useState({});
    const [viewAmount, setViewAmount] = useState(false)
    const [openAddFunds, setOpenAddFunds] = useState(false)
    const [openWithdrawFunds, setOpenWithdrawFunds] = useState(false)
    const [amount, setAmount] = useState(null)
    const [openPaymentModal, setOpenPaymentModal] = useState(false)
    const [payableAmount, setPayableAmount] = useState(null)
    const [openPassbookModal, setOpenPassbookModal] = useState(false)
    const [certificateData, setCertificateData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [acknowledgeConsent, setAcknowledgeConsent] = useState(false)
    const [certificateConsent, setCertificateConsent] = useState(false)
    const [isWithdrawn, setIsWithdrawn] = useState(false)

    const userData = masterState?.getCurrentUserDetails?.data?.data
    const walletData = investorState?.getInvestorWalletData?.data?.data
    const walletTransactionData = investorState?.getWalletTransaction?.data?.data
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(walletTransactionData?.length / PER_PAGE);
    const isDocumentPending = userData?.[0]?.document_approval?.is_approved === 0 && userData?.[0]?.document_approval?.document_url;
    const isDocumentRejected = userData?.[0]?.document_approval?.is_approved === 2;
    const isKycCompleted = userData?.[0]?.account_approval_status === 1

    useEffect(()=>{
        props.actions.getCurrentUserDetailsAction()
        props.actions.getInvestmentWalletAction()
        props.actions.getWalletTransactionAction()
    },[])

    useEffect(() => {
        if(investorState?.getWalletPassbook?.data?.success === true) {
            addToast({ message: investorState?.getWalletPassbook?.data?.message, status: 'success' })
            window.open(investorState?.getWalletPassbook?.data?.pdf)
            props?.actions?.cleanUpToast()
          } else if(investorState?.getWalletPassbook?.data?.success === false) {
            addToast({ message: investorState?.getWalletPassbook?.data?.message, status: 'warning' })
            props?.actions?.cleanUpToast()
          }
    }, [investorState?.getWalletPassbook])

    useEffect(() => {
        if(investorState?.withdrawWalletAmount?.data?.success === true) {
            addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'success' })
            setIsWithdrawn(investorState?.withdrawWalletAmount?.data?.success)
            props.actions.getCurrentUserDetailsAction()
            props?.actions?.cleanUpToast()
          } else if(investorState?.withdrawWalletAmount?.data?.success === false) {
            addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'warning' })
            props?.actions?.cleanUpToast()
          }
    }, [investorState?.withdrawWalletAmount])

    useEffect(() => {
        if (investorState?.changePaymentMode?.data?.success === true) {
          addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'success' })
          props.actions.getCurrentUserDetailsAction()
          props?.actions?.cleanUpToast()
        } else if (investorState?.changePaymentMode?.data?.success === false) {
          addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'error' })
          props?.actions?.cleanUpToast()
        }
    }, [investorState?.changePaymentMode?.data])

    useEffect(() => {
        return () => {
          props?.actions?.cleanUpToast()
        }
      }, [])

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
        setAmount(null)
        if (name === 'time_period') {
            const today = moment(new Date()).format('YYYY-MM-DD')
            let start_date
            let time_period
            switch (value) {
              case '3 Months':
                start_date = moment().subtract(3, 'months').format('YYYY-MM-DD')
                time_period = value
                break;
              case '6 Months':
                start_date = moment().subtract(6, 'months').format('YYYY-MM-DD')
                time_period = value
                break;
              case '1 Year':
                start_date = moment().subtract(1, 'year').format('YYYY-MM-DD')
                time_period = value
                break;
              case 'Custom':
                start_date = today
                time_period = value
                break;
              default:
                start_date = ''
                time_period = ''
            }
            if (value) {
                setInputValues({...inputValues, from_date: start_date, to_date: today, time_period})
            }
        }
    }

    const handleCheck = (e, type) => {
        if (type === 'acknowledge') {
          setAcknowledgeConsent(e.target.checked)
        }
        if (type === 'certificate') {
          setCertificateConsent(e.target.checked)
        }
      }

    const handleViewAmount = () => {
        setViewAmount(!viewAmount)
    }

    const handleAddFundsDialog = () => {
        setOpenAddFunds(!openAddFunds)
    }

    const handleOpenWithdrawFundsDialog = () => {
        setOpenWithdrawFunds(true)
    }

    const handleCloseWithdrawFundsDialog = () => {
        setOpenWithdrawFunds(false)
        setInputValues({...inputValues, withdraw_amount: ''})
        setIsWithdrawn(false)
    }

    const handleWithdrawAll = (e, amount) => {
        if (e.target.checked) {
            setInputValues({...inputValues, withdraw_amount: amount})
        } else {
            setInputValues({...inputValues, withdraw_amount: ''})
        }
    }

    const handleWithdrawAmount = () => {
        if (!inputValues?.withdraw_amount) {
            addToast({message: 'Minumum amount should be ₹1', status: 'error'})
            return
        }
        if (Number(inputValues?.withdraw_amount) > Number(walletData?.effective_balance)) {
            addToast({message: 'Insufficient Funds', status: 'error'})
            return
        }
        props.actions.withdrawWalletAmountAction({amount: inputValues?.withdraw_amount})
    }

    const handlePassbookDialog = () => {
        setOpenPassbookModal(!openPassbookModal)
    }

    const handleDownloadPassbook = () => {
        if (!inputValues?.time_period) {
            addToast({message: 'Please Select Time Period', status: 'error'})
            return
        }
        let data = {
            start_date: inputValues?.from_date,
            end_date: inputValues?.to_date
        }
        props.actions.getWalletPassbookAction(data)
    }

    const handleSelectAmount = (item) => {
        const selectedAmount = periodData?.find((elem) => elem.id === item.id)
        setAmount(selectedAmount)
        setInputValues({ ...inputValues, lending_amount: selectedAmount.name });
    }

    const showOpenFileDialog = () => {
        certificateRef?.current?.click()
    }
    
    const onChangeFile = (e) => {
        setCertificateData(e.target.files[0])
    }

    const handleSaveDocumnent = () => {
        if (!certificateData) {
          addToast({ message: 'Please Upload Certificate', status: 'error' })
          return
        }
        const formData = new FormData()
        formData.append('networth_certificate', certificateData)
        setIsLoading(true)
        uploadNetworthCertificate(formData).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true) {
            addToast({message: 'Document Uploaded Successfully', status: 'success'})
            props.actions.getCurrentUserDetailsAction()
          } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
          }
        }).catch((error) => {
          addToast({message: error, status: 'error'})
          setIsLoading(false)
        })
      }

    const onChangeUploadAgain = (e) => {
        const formData = new FormData()
        formData.append('networth_certificate', e.target.files[0])
        setIsLoading(true)
        uploadNetworthCertificate(formData).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true) {
            addToast({message: 'Document Uploaded Successfully', status: 'success'})
            props.actions.getCurrentUserDetailsAction()
          } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
          }
        }).catch((error) => {
          addToast({message: error, status: 'error'})
          setIsLoading(false)
        })
    }

    const handleChangePaymentMode = (type) => {
        if (type === 'yes') {
          props.actions.postChangePaymentModeAction({payment_mode : 0})
        } else if (type === 'no') {
          props.actions.postChangePaymentModeAction({payment_mode : 1})
        }
    }

    const handleOpenPaymentDialog = (amount) => {
        if (!inputValues?.lending_amount || inputValues?.lending_amount <= 99) {
            addToast({message: 'Minimum Amount is 100', status: 'error'})
            return
        }
        if (+(inputValues?.lending_amount) > MAXAMOUNT) {
            addToast({message: 'Can not Add Funds more than 50 Lakhs', status: 'error'})
            return
        }
        setPayableAmount(amount)
        setOpenPaymentModal(true)
    }

    const handleClosePaymentDialog = () => {
        setPayableAmount(null)
        setOpenPaymentModal(false)
    }

    const handleGoToDashboard = () => {
        history.push(routesNames.INVESTERDASHBOARD)
    }

    // const handleLogout = () =>{
    //     history.push(routesNames.LOGIN)
    // }

    const handleProfile = () => {
        history.push(routesNames.INVESTORPROFILE)
    }
    
    return (
        <Box>
            {(masterState?.getCurrentUserDetails?.loading || investorState?.getInvestorWalletData?.loading || investorState?.getWalletTransaction?.loading || isLoading || investorState?.getWalletPassbook?.loading || investorState?.withdrawWalletAmount?.loading || investorState?.changePaymentMode?.loading) &&
                <div>
                <Loader />
                </div>
            }
             <Box className='dashboard_box bg_shadow'>
                <Grid className="main_grid_container align-items-center">
                    <GridItem colSpan={8}>
                    <Box className='d-flex align-items-center justify-content-between'>
                        <Heading as="h2" className='font-poppins page_heading'>Wallet</Heading>
                        <ImportantDialog data={userData?.[0]?.usertype} />
                    </Box>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <NavBar data={userData} handleProfile={handleProfile} />
                    </GridItem>
                </Grid>
                {isDocumentPending ?
                    <Box mt={3} mb={3} className='pending_upload'>
                        <Box className='d-flex align-items-center col-gap-10'>
                            <Image src={WaitIcon} />
                            <Heading as="p" className="font-poppins">Your net worth certificate is under review and will take 24 hours. Meanwhile, you can add up to 10 lakh to your wallet & Start Investment</Heading>
                        </Box>
                    </Box> : 
                isDocumentRejected ?
                    <Box mt={3} mb={3} className='rejected_upload'>
                        <Box className='d-flex align-items-center col-gap-10'>
                            <Image src={RejectedIcon} />
                            <Heading as="p" className="font-poppins">Your Net Worth CA Certificate has been rejected because you uploaded the incorrect document.
                                <span className="upload_again" onClick={() => showOpenFileDialog()}>
                                    (Upload Again)
                                </span>
                            </Heading>
                            <Input
                                size="md"
                                name="upload"
                                type="file"
                                accept="image/*,.pdf"
                                onChange={(e) => onChangeUploadAgain(e)}
                                className="d-none"
                                ref={certificateRef}
                            />
                        </Box>
                    </Box> : null 
                }
                <Grid className="main_grid_container" mt={4}>
                    <GridItem colSpan={4}>
                        <WalletBalancePassbook walletData={walletData} viewAmount={viewAmount}
                            handleViewAmount={handleViewAmount} handlePassbookDialog={handlePassbookDialog}
                            handleChangePaymentMode={handleChangePaymentMode} isKycCompleted={isKycCompleted}
                        />
                    </GridItem>
                    <GridItem colSpan={5}>
                        <WalletCard walletData={walletData} />
                    </GridItem>
                    <GridItem colSpan={3}>
                        <Box className='wallet_fund_action bg_shadow'>
                            <Heading as="h2" className='font-poppins card_heading'>Add Funds To Wallet</Heading>
                            <CustomButton size="md" type="submit" title="ADD FUNDS" className="btn_wallet"
                                leftIcon={<Image src={AddFundsIcon} />} onClick={handleAddFundsDialog}
                                isDisabled={isKycCompleted ? false : true}
                            />
                        </Box>
                        <Box className='wallet_fund_action bg_shadow' mt={4}>
                            <Heading as="h2" className='font-poppins card_heading'>Withdraw Funds</Heading>
                            <CustomButton size="md" type="submit" title="WITHDRAW" className="btn_wallet"
                                leftIcon={<Image src={WithDrawIcon} />} onClick={handleOpenWithdrawFundsDialog}
                                isDisabled={isKycCompleted ? false : true}
                            />
                        </Box>
                    </GridItem>
                </Grid>
                <Box className='wallet_table' mt={6}>
                    <Heading as="h2" className='font-poppins'>Wallet Transaction History
                        <Tooltip label='The transaction history contain pending as well as processed transactions. The transaction pertaining to disbursals and wall withdrawals might take upto 24 hours to process and the data in the wall summery will be updated based on real-time statistics and not on the transaction.' 
                            hasArrow fontSize='xs' placement='bottom-start'>
                            <InfoOutlineIcon />
                        </Tooltip>
                    </Heading>
                    <WalletTransactionTable offset={offset} PER_PAGE={PER_PAGE} pageCount={pageCount} handlePageClick={handlePageClick}
                        walletTransactionData={walletTransactionData}
                    />
                </Box>
            </Box>
            <AddFundsDialog data={periodData} openAddFunds={openAddFunds} handleAddFundsDialog={handleAddFundsDialog}
                handleSelectAmount={handleSelectAmount} amount={amount} handleOpenPaymentDialog={handleOpenPaymentDialog}
                handleChange={handleChange} inputValues={inputValues} certificateRef={certificateRef} onChangeFile={onChangeFile}
                showOpenFileDialog={showOpenFileDialog} acknowledgeConsent={acknowledgeConsent} certificateConsent={certificateConsent}
                handleCheck={handleCheck} handleSaveDocumnent={handleSaveDocumnent} certificateData={certificateData} userData={userData}
            />
            <WithdrawFundsDialog handleChange={handleChange} inputValues={inputValues} openWithdrawFunds={openWithdrawFunds}
                handleCloseWithdrawFundsDialog={handleCloseWithdrawFundsDialog} walletData={walletData} handleWithdrawAmount={handleWithdrawAmount}
                handleWithdrawAll={handleWithdrawAll} isWithdrawn={isWithdrawn} handleGoToDashboard={handleGoToDashboard}
            />
            <DownloadPassBook handleChange={handleChange} inputValues={inputValues} openPassbookModal={openPassbookModal}
                handlePassbookDialog={handlePassbookDialog} timePeriod={timePeriod} handleDownloadPassbook={handleDownloadPassbook} 
            />
            <PaymentDialogInvestor title="Add Funds" openPayModal={openPaymentModal} handleClosePaymentDialog={handleClosePaymentDialog}
                payableAmount={payableAmount} userData={userData} handleAddFundsDialog={handleAddFundsDialog}
            />
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(Wallet)