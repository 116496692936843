import React from 'react'
import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Textarea } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'

export default function LoanConfirmDialog(props) {

    const modalType = props.type

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            {modalType === 'finops' ?
                <ModalContent>
                    <ModalHeader className="confirm_title font-poppins">
                        Coming Soon
                    </ModalHeader>
                </ModalContent> :
                <ModalContent>
                    <ModalHeader className="confirm_title font-poppins">
                        {modalType === 'approve' ? 'Submit This Application' : modalType === 'reject' ? 'Are You Sure Want To Reject this Loan Application' : 'Are You Sure Want To Assign this Loan Application to FinOps'}
                    </ModalHeader>
                    <ModalBody>
                        {modalType === 'approve' ? null :
                            <Box mb={5}>
                                <Textarea
                                    type="text"
                                    size="md"
                                    name="reason"
                                    placeholder="Comment"
                                    onChange={props.handleChange}
                                    className="input_box font-poppins"
                                    value={props.inputValues?.reason}
                                />
                            </Box> }
                        <Box mb={5} className='d-flex align-items-center justify-content-center col-gap-15'>
                            {modalType === 'approve' ?
                                <CustomButton size="md" type="submit" title="Yes" className="btn_confirm font-poppins" onClick={() => props.handleApprove()} /> :
                            modalType === 'reject' ?
                                <CustomButton size="md" type="submit" title="Reject" className="btn_confirm font-poppins" onClick={() => props.handleReject()} /> :
                                <CustomButton size="md" type="submit" title="Assign" className="btn_confirm font-poppins" onClick={() => props.handleFinops()} />
                            }
                            <CustomButton size="md" type="submit" title={modalType === 'approve' ? "No" : 'Cancel'} className="btn_cancel font-poppins" onClick={props.handleConfirmation} />
                        </Box>
                    </ModalBody>
                </ModalContent>
            }
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleConfirmation}
        isOpen={props.openConfirmation}
        motionPreset="slideInBottom"
        // size={'xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
