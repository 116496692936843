import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem} from '@chakra-ui/react'
import SidebarBorrower from '../../components/elements/SidebarBorrower'
import { getItem } from '../../utilities/authUtils';
import decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import * as routesNames from '../../constants/routes'
import SidebarAdmin from '../../components/elements/SidebarAdmin';
import SidebarSales from '../../components/elements/SidebarSales';
import SidebarInvester from '../../components/elements/SidebarInvester';
import SidebarCP from '../../components/elements/SidebarCP';

const excludedPaths = [
  routesNames.BORROWERKYC,
  routesNames.INVESTERKYC,
]

const validBorrowerPaths = [
  routesNames.BORROWERDASHBOARD,
  routesNames.BORROWERAPPLYLOAN,
  routesNames.BORROWERLOANSTATUS,
  routesNames.BORROWERACTIVELOAN,
  routesNames.BORROWERCLOSEDLOAN,
  routesNames.BORROWEREXPIREDLOAN,
  routesNames.BORROWERWITHDRAWNLOAN,
  routesNames.BORROWERPROFILE,
]

const validInvestorPaths = [
  routesNames.INVESTERDASHBOARD,
  routesNames.INVESTERPORTFOLIO,
  routesNames.MANUALINVESTMENT,
  routesNames.PLEDGEDINVESTMENT,
  routesNames.ACTIVEINVESTMENT,
  routesNames.CLOSEDINVESTMENT,
  routesNames.WALLET,
  routesNames.INVESTORPROFILE,
]

const validAdminPaths = [
  routesNames.ADMINDASHBOARD,
  routesNames.ADMINPRODUCT,
  routesNames.UNDERWRITERLOANAPPLICATION,
  routesNames.UNDERWRITERVIEWSUMMARY,
  routesNames.UNDERWRITERPROCESS,
  routesNames.PRESANCTIONBORROWERS,
  routesNames.PRESANCTIONBORROWERSVERIFY,
  routesNames.PRESANCTIONINVESTORS,
  routesNames.PRESANCTIONINVESTORVERIFY,
  routesNames.POSTSANCTIONLOANAGREEMENTS,
  routesNames.POSTSANCTIONLOANAGREEMENTSVERIFY,
  routesNames.POSTSANCTIONLOANAGREEMENTSVIEW,
  routesNames.INTERNALREPORT,
  routesNames.BUREAUREPORT,
  routesNames.DCRREPORT,
  routesNames.CKYCREPORT,
  routesNames.ADMINUSERCOLLECTION,
  routesNames.ADMINUSERCOLLECTION_LOAN_DETAILS,
  routesNames.ADMINUSERCOLLECTION_KYC_Details,
  routesNames.ADMINUSERCOLLECTION_UPCOMING_Details,
]

const validSalesPaths = [
  routesNames.SALESDASHBOARD,
]

const validCPPaths = [
  routesNames.CPDASHBOARD,
  routesNames.CPBORROWERS,
  routesNames.CPAPPLYLOAN,
  routesNames.CPLOANLIST,
  routesNames.CPINVESTORS,
  routesNames.CPPAYOUTS,
]

const AppLayout = (props) => {

  const location = useLocation();
  const authState = useSelector((state) => state.authState)
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const idToken = getItem('id_token');
    if (idToken) {
      const decodedToken = decode(idToken);
      setUserRole(decodedToken);
    } else {
      setUserRole(null);
    }
  }, [authState?.verifyMobileOTP, authState?.verifySalesOTP, authState?.createNewUser, authState?.verifyCPOTP]);

  const renderSidebar = () => {
    if (!userRole) return null
    if (userRole.usertype === 2 && !validBorrowerPaths.includes(location.pathname)) {
      return null
    }
    if (userRole.usertype === 3 && !validInvestorPaths.includes(location.pathname)) {
      return null;
    }
    if ((userRole.role === '5' || userRole.role === '4' || userRole.role === '6' || userRole.role === '7') && !validAdminPaths.includes(location.pathname)) {
      return null
    }
    if (userRole?.role === 'Sales' && !validSalesPaths.includes(location.pathname)) {
      return null
    }
    if (userRole?.role === 'channel_partner' && !validCPPaths.includes(location.pathname)) {
      return null
    }
    if (userRole.usertype === 2) {
      return <SidebarBorrower />
    }
    if (userRole.usertype === 3) {
      return <SidebarInvester />
    }
    if (userRole.role === '1' || userRole.role === '5' || userRole.role === '4' || userRole.role === '6' || userRole.role === '7') {
      return <SidebarAdmin />
    }
    if (userRole.role === 'Sales') {
      return <SidebarSales />
    }
    if (userRole.role === 'channel_partner') {
      return <SidebarCP />
    }
  };

  const parentGridClassName =
    (userRole && userRole?.usertype === 2 && !validBorrowerPaths.includes(location.pathname)) ||
    (userRole && userRole?.usertype === 3 && !validInvestorPaths.includes(location.pathname)) ||
    (userRole && (userRole.role === '1' || userRole.role === '5' || userRole.role === '4' || userRole.role === '6' || userRole.role === '7') && !validAdminPaths.includes(location.pathname)) ||
    (userRole && userRole?.role === 'Sales' && !validSalesPaths.includes(location.pathname)) ||
    (userRole && userRole?.role === 'channel_partner' && !validCPPaths.includes(location.pathname))
      ? null
      : 'parent_grid'

  return (
    <>
      {!excludedPaths.includes(location?.pathname) ?
        <Box className='main_app'>
          <Grid className={parentGridClassName}>
            <GridItem className="sidebar_grid">
              {renderSidebar()}
            </GridItem>
            <GridItem className="children_grid">
              <Box className='children_box'>
                {props.children}
              </Box>
            </GridItem>
          </Grid>
        </Box> : 
        null
      }
   </>
  )
}

export default AppLayout
