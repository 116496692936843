import { Box, Flex, Grid, GridItem, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import QRCode from "qrcode.react";
import UpiIcon from '../../../assets/images/UPILogo.svg'
import NetbankingIcon from '../../../assets/images/PaymentMode.svg'
import GpayIcon from'../../../assets/images/gpay.svg';
import PhonepeIcon from'../../../assets/images/phonepay.svg';
import QRIcon from '../../../assets/images/Paymentupihide.svg'
import CustomButton from '../../elements/customButton';
import moment from 'moment';
import { checkNetBankingStatus, checkTransactionStatus, generateLogIntent } from '../../../services/paymentServices';
import 'moment-duration-format';
import Loader from '../../elements/loader';
import CustomToast from '../../elements/customToast';
import { connect, useSelector } from 'react-redux';
import { ViewIcon } from '@chakra-ui/icons';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../redux/actions';
import PaymentSuccess from './PaymentSuccess';
import { Realtime } from 'ably';
import { v4 as uuidv4 } from 'uuid';
import { APIPATH } from '../../../constants/Constant';

const PaymentDialogBorrower = (props) => {

    const { addToast } = CustomToast()
    const kycState = useSelector((state) => state.kycState)
    const currentDate = moment().format('DD MMMM YYYY')
    const currentDateTime = moment()
    const newDateTime = currentDateTime.clone().add(2, 'minutes')
    const formattedDateTime = newDateTime.format('hh:mm A')
    const [logIntentData, setLogIntentData] = useState(undefined)
    const [minutes, setMinutes] = useState(2)
    const [seconds, setSeconds] = useState(0)
    const [selectedTab, setSelectedTab] = useState(0)
    const [paymentCheckInterval, setPaymentCheckInterval] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [openSuccessPayment, setOpenSuccessPayment] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const [clientId, setClientId] = useState('');
    const unmaskedData = kycState?.getPiiData?.data?.data
    const partPaymentKey = props?.partPayKey
    const qrValue = `upi://pay?pa=${logIntentData?.vpa}&pn=Alphamoney&tr=${logIntentData?.txn_id}&tn=Pay To Creditech &am=${logIntentData?.txn_amt}&cu=INR`

    useEffect(() => {
        setClientId(uuidv4())
    }, [])

    useEffect(() => {
        if(logIntentData) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1)
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval)
                        setLogIntentData(undefined)
                        clearInterval(paymentCheckInterval)
                        setPaymentCheckInterval(null)
                    } else {
                        setSeconds(59)
                        setMinutes(minutes - 1)
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval)
            };
        }
    }, [logIntentData, seconds, minutes])

    useEffect(() => {
        if (selectedTab === 1) {
            let obj = {
                loan_contract_number: props?.loanNumber,
                percentage: partPaymentKey ? +(partPaymentKey?.split(" -")[0]) : 100,
            }
            setIsLoading(true)
            checkNetBankingStatus(obj).then((statusRes) => {
                console.log(statusRes, 'statusRes')
                setIsLoading(false)
                if (statusRes?.data?.success === true && statusRes?.data?.status === true) {
                    addToast({message: statusRes?.data?.message, status: 'success'})
                } else {
                    addToast({message: statusRes?.data?.message, status: 'error'})
                }
            }).catch((error) => {
                console.log(error, 'errr')
                setIsLoading(false)
            })
        }
    }, [selectedTab])

    const handleTabsChange = (index) => {
        setSelectedTab(index)
    }

    const handleLogIntent = () => {
        let data = {
            txn_type: 3,
            loan_contract_id: props?.loanNumber,
        }
        setIsLoading(true)
        generateLogIntent(data).then((res) => {
            setIsLoading(false)
            if (res?.data?.success === true) {
                setLogIntentData(res?.data?.data)
                setMinutes(2)
                setSeconds(0)
                handleTransactionUpdates(res?.data?.data?.txn_id)
                // const intervalId = setInterval(() => {
                //     checkTransactionStatus({txnId: res?.data?.data?.txn_id}).then((statusRes) => {
                //         console.log(statusRes, 'statusRes')
                //         if (statusRes?.data?.success === true && statusRes?.data?.status === true) {
                //             addToast({message:statusRes?.data.message, status: 'success'})
                //             clearInterval(intervalId) // Clear interval when payment is successful
                //             handleClose()
                //             if (partPaymentKey) {
                //                 props.handleClosePartPayDialog()
                //             }
                //             setIsFailed(false)
                //             setOpenSuccessPayment(true)
                //         } else {
                //             addToast({message:statusRes?.data.message, status: 'error'})
                //             clearInterval(intervalId)
                //         }
                //     }).catch((error) => {
                //         if (error?.response?.status !== 404) {
                //             addToast({message: error?.response?.data?.message, status: 'error'})
                //             clearInterval(intervalId)
                //             handleClose()
                //             setIsFailed(true)
                //             setOpenSuccessPayment(true)
                //         }
                //         console.log(error, 'errr')
                //     })
                // }, 5000)
                // setPaymentCheckInterval(intervalId)
            } else if (res?.data?.success === false) {
                addToast({message: res?.data?.message, status: 'error'})
            }
          }).catch((error) => {
                addToast({message: 'Getting Invalid Response From Bank', status: 'error'})
                setIsLoading(false)
        })
    }

    const handleTransactionUpdates = (txnId) => {
        const ably = new Realtime({
            // authUrl: 'https://stageapi.alphamoney.in/api/ably/token',
            authUrl: `${APIPATH}${'/ably/token'}`,
            authMethod: 'GET',
            authParams: clientId,
        });
        const channel = ably.channels.get('upi-status-'+txnId);
        
        channel.subscribe('update', (message) => {
            const statusRes = message.data;
            console.log(statusRes, 'statusRes')
            if (statusRes?.data?.status === true) {
                addToast({message:statusRes?.data.message, status: 'success'})
                handleClose()
                if (partPaymentKey) {
                    props.handleClosePartPayDialog()
                }
                setIsFailed(false)
                setOpenSuccessPayment(true)
            } else {
                addToast({message:statusRes?.data.message, status: 'error'})
                handleClose()
                setIsFailed(true)
                setOpenSuccessPayment(true)
            }
        });
    }

    const handleShowDetails = () => {
        const data = {
            accessed_data: 'escrow_account_id',
            accessed_user: props?.userData?.[0]?.id,
        }
        props.actions.getPiiDataAction(data)
    }

    const handleClose = () => {
        props.handleCloseLoanPaymentDialog()
        setLogIntentData(undefined)
        setMinutes(0)
        setSeconds(0)
        setSelectedTab(0)
        if (paymentCheckInterval) {
            clearInterval(paymentCheckInterval)
            setPaymentCheckInterval(null)
        }
    }

    const handleClosePaymentSuccessDialog = () => {
        setOpenSuccessPayment(false)
        clearInterval(paymentCheckInterval)
        setPaymentCheckInterval(null)
        setIsFailed(false)
    }

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent className='repayment_content'>
                <ModalHeader className="repayment_title font-poppins">{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody className='repayment_body'>
                    <Box>
                        <Tabs className='repayment_tabs_container' orientation='vertical' onChange={handleTabsChange}>
                            <Grid className="repayment_grid_container">
                                <GridItem colSpan={6} className='repayment_tab_block'>
                                    <Heading as='h5' className='font-poppins'>Select a Payment method</Heading>
                                    <TabList className='repayment_tabs_list'>
                                        <Tab>
                                            <Box className='repayment_tab'>
                                                <Image src={UpiIcon} />
                                                <Box>
                                                    <Heading as='h6' className='font-poppins'>UPI</Heading>
                                                    <Heading as='p' className='font-poppins'>Payment using  G-Pay, Phone Pay and other UPI apps</Heading>
                                                </Box>
                                            </Box>
                                        </Tab>
                                        <Tab>
                                            <Box className='repayment_tab'>
                                                <Image src={NetbankingIcon} />
                                                <Box>
                                                    <Heading as='h6' className='font-poppins'>NetBanking</Heading>
                                                    <Heading as='p' className='font-poppins'>Pay with Internet Banking Account</Heading>
                                                </Box>
                                            </Box>
                                        </Tab>
                                    </TabList>
                                </GridItem>
                                <GridItem colSpan={6} className='repayment_content_block'>
                                    <TabPanels>
                                        <TabPanel className='repayment_tab_panel'>
                                            <Box className='payment_via'>
                                                <Heading as='h5' className='font-poppins'>Pay using UPI</Heading>
                                                <Heading as='p' className='font-poppins'>Amount ₹{props.payableAmount}</Heading>
                                            </Box>
                                            <Box className='qr_block bg_shadow' mt={4}>
                                                <Image className='upi_logo' src={UpiIcon} />
                                                <Heading as='h5' className='font-poppins'>Scan to Pay</Heading>
                                                {logIntentData?.txn_id ?
                                                    <QRCode value={qrValue} className="qrCode"/> :
                                                    <Box className='position-relative qr_icon'>
                                                        <Image src={QRIcon} />
                                                        <CustomButton size="md" type="submit" title="Show QR" className="btn_black" onClick={handleLogIntent} />
                                                    </Box>
                                                }
                                                <Heading as='p' className='font-poppins'>Scan and Pay using any UPI app</Heading>
                                                <Flex gap={5}>
                                                    <Image src={GpayIcon} />
                                                    <Image src={UpiIcon} />
                                                    <Image src={PhonepeIcon} />
                                                </Flex>
                                            </Box>
                                            <Flex className='timer_block bg_shadow' mt={4}>
                                                <Box className='timer_block_data'>
                                                    <Heading as='h5' className='font-poppins'>Finish before</Heading>
                                                    <Heading as='p' className='font-poppins'>{currentDate}, {formattedDateTime} (IST)</Heading>
                                                </Box>
                                                <Spacer />
                                                <Box className='timer_block_data timer_flex'>
                                                    <Heading as='h6' className='font-poppins'>{Math.floor(minutes / 10)}</Heading>
                                                    <Heading as='h6' className='font-poppins'>{(minutes) % 10}</Heading>
                                                    <Heading as='p' className='font-poppins'>:</Heading>
                                                    <Heading as='h6' className='font-poppins'>{Math.floor(seconds / 10)}</Heading>
                                                    <Heading as='h6' className='font-poppins'>{(seconds) % 10}</Heading>
                                                </Box>
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel className='repayment_tab_panel'>
                                            <Box className='payment_via'>
                                                <Heading as='h5' className='font-poppins'>Pay using Netbanking</Heading>
                                                <Heading as='p' className='font-poppins'>Amount ₹{props.payableAmount}</Heading>
                                            </Box>
                                            <Box className='net_banking bg_shadow' mt={4}>
                                                <Heading as="p" className="font-poppins">Please go to your netbanking portal and add the brlow mentioned beneficiary and make an IMPS/NEFT/RTGS transaction towards it. We are working on bringing payment gateway for more efficie nt transactions and TAT reduction to seconds.</Heading>
                                                <Box className='account_details' mt={2} mb={2}>
                                                    <Heading as="h2" className="font-poppins">ALPHAMONEY Bank Details</Heading>
                                                    <Flex mt={2}>
                                                        <Heading as="h3" className="font-poppins">Account Number</Heading>
                                                        <Spacer />
                                                        <Heading as="h3" className="font-poppins">
                                                            {unmaskedData?.escrow_account_id ? unmaskedData?.escrow_account_id : props?.userData?.[0]?.escrow_account_id}
                                                            {!unmaskedData?.escrow_account_id &&
                                                                <ViewIcon className='escrow_icon' onClick={handleShowDetails} />
                                                            }
                                                        </Heading>
                                                    </Flex>
                                                    <Flex mt={2}>
                                                        <Heading as="h3" className="font-poppins">ISFC Code</Heading>
                                                        <Spacer />
                                                        <Heading as="h3" className="font-poppins">YESB0CMSNOC</Heading>
                                                    </Flex>
                                                    <Flex mt={2}>
                                                        <Heading as="h3" className="font-poppins">Bank Name</Heading>
                                                        <Spacer />
                                                        <Heading as="h3" className="font-poppins">Yes Bank Ltd</Heading>
                                                    </Flex>
                                                </Box>
                                                <Heading as="p" className="font-poppins">IMPS: It might takedd upto 5-10 minutes for the money to reflect in your wallet depending on banking hours and bank holidays. NEFT/ RTGS: It might take upto 24 hours for the money to reflect in your wallet on banking hours and bank holidays.</Heading>
                                            </Box>
                                        </TabPanel>
                                    </TabPanels>
                                </GridItem>
                            </Grid>
                        </Tabs>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )  
  return (
    <>
        {(isLoading || kycState?.getPiiData?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Modal
            isCentered
            onClose={handleClose}
            isOpen={props.openPayModal}
            motionPreset="slideInBottom"
            size={'5xl'}
        >
        {PopupBox()}
        </Modal>
        <PaymentSuccess openSuccessPayment={openSuccessPayment} handleClosePaymentSuccessDialog={handleClosePaymentSuccessDialog}
            isFailed={isFailed}
        />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(PaymentDialogBorrower)