import * as investorService from '../../services/InvestorServices'
import * as actionTypes from './actionsTypes'
import { getCurrentUserDetailsAction } from './masterActions'


// actions for get investor details
const getInvestorDetailedRequest = () => {
  return {
    type: actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_REQUEST
  }
}

const getInvestorDashboardDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_SUCCESS,
    payload: data
  }
}

const getInvestorDashboardDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_INVESTOR_DASHBOARD_DETAILS_FAILURE,
    payload: error
  }
}

export const getInvestorDetailedAction = (payload) => async (dispatch) => {
  dispatch(getInvestorDetailedRequest())
  try {
    const responseData = await investorService.getUserInvestorDetails(payload)
    if (responseData.status === 200) {
      dispatch(getInvestorDashboardDetailsSuccess(responseData?.data))
    }
    else {
      dispatch(getInvestorDashboardDetailsFailure(responseData?.errors))
    }
  }
  catch (error) {
    dispatch(getInvestorDashboardDetailsFailure(error?.response?.data))
  }
}

// actions for get investment opportunities
const getInvestorOpportunitiesRequest = () => {
  return {
    type: actionTypes.GET_INVESTMENT_OPPORTUNITIES_REQUEST
  }
}

const getInvestorOpportunitiesSuccess = (data) => {
  return {
    type: actionTypes.GET_INVESTMENT_OPPORTUNITIES_SUCCESS,
    payload: data
  }
}

const getInvestorOpportunitiesFailure = (error) => {
  return {
    type: actionTypes.GET_INVESTMENT_OPPORTUNITIES_FAILURE,
    payload: error
  }
}

export const getInvestorOpportunitiesAction = (payload) => async (dispatch) => {
  dispatch(getInvestorOpportunitiesRequest())
  try {
    const responseData = await investorService.getInvestmentOpportunities(payload)
    if (responseData.status === 200) {
      dispatch(getInvestorOpportunitiesSuccess(responseData?.data))
    }
    else {
      dispatch(getInvestorOpportunitiesFailure(responseData?.errors))
    }
  }
  catch (error) {
    dispatch(getInvestorOpportunitiesFailure(error?.response?.data))
  }
}

// actions for get quick investment opportunities
const getQuickInvestmentRequest = () => {
  return {
    type: actionTypes.GET_QUICK_INVESTMENT_REQUEST
  }
}

const getQuickInvestmentSuccess = (data) => {
  return {
    type: actionTypes.GET_QUICK_INVESTMENT_SUCCESS,
    payload: data
  }
}

const getQuickInvestmentFailure = (error) => {
  return {
    type: actionTypes.GET_QUICK_INVESTMENT_FAILURE,
    payload: error
  }
}

export const getQuickInvestmentAction = (payload) => async (dispatch) => {
  dispatch(getQuickInvestmentRequest())
  try {
    const responseData = await investorService.getQuickInvestment(payload)
    if (responseData.status === 200) {
      dispatch(getQuickInvestmentSuccess(responseData?.data))
    }
    else {
      dispatch(getQuickInvestmentFailure(responseData?.errors))
    }
  }
  catch (error) {
    dispatch(getQuickInvestmentFailure(error?.response?.data))
  }
}


// actions for get portofolio
const getInvestmentPortfolioRequest = () => {
  return {
    type: actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_REQUEST
  }
}

const getInvestmentPortfolioSuccess = (data) => {
  return {
    type: actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_SUCCESS,
    payload: data
  }
}

const getInvestmentPortfolioFailure = (error) => {
  return {
    type: actionTypes.GET_INVESTOR_PORTFOLIO_DETAILS_FAILURE,
    payload: error
  }

}

export const getInvestmentPortfolioAction = (payload) => async (dispatch) => {
  dispatch(getInvestmentPortfolioRequest())
  try {
    const responseData = await investorService.getInvestmentPortfolio(payload)
    if (responseData.status === 200) {
      dispatch(getInvestmentPortfolioSuccess(responseData?.data))
    }
    else {
      dispatch(getInvestmentPortfolioFailure(responseData?.errors))
    }
  }
  catch (error) {
    dispatch(getInvestmentPortfolioFailure(error?.response?.data))
  }
}

// actions for get wallet balance Wallet balance

const getInvestmentWalletRequest = () => {
  return{
    type: actionTypes.GET_INVESTOR_WALLET_REQUEST
  }
}

const getInvestmentWalletSuccess = (data) => {
  return{
    type: actionTypes.GET_INVESTOR_WALLET_SUCCESS,
    payload:data
  }
}

const getInvestmentWalletFailure = (error) => {
return{
  type: actionTypes.GET_INVESTOR_WALLET_FAILURE,
  paylad:error
}
}

export const getInvestmentWalletAction = (payload) => async(dispatch) =>{
  dispatch(getInvestmentWalletRequest())
  try{
    const responseData = await investorService.getwalletbalance(payload)
    if(responseData?.status === 200){
    dispatch(getInvestmentWalletSuccess(responseData?.data))
    }
    else{
    dispatch(getInvestmentWalletFailure(responseData?.errors))
    }
  }
  catch(error){
    dispatch(getInvestmentWalletFailure(error?.response?.data))
  }
}


// actions for get wallet balance Wallet transaction

const getWalletTransactionRequest = () => {
  return{
    type: actionTypes.GET_WALLET_TRANSACTION_REQUEST
  }
}

const getWalletTransactionSuccess = (data) => {
  return{
    type: actionTypes.GET_WALLET_TRANSACTION_SUCCESS,
    payload:data
  }
}

const getWalletTransactionFailure = (error) => {
return{
  type: actionTypes.GET_WALLET_TRANSACTION_FAILURE,
  paylad:error
}
}

export const getWalletTransactionAction = (payload) => async(dispatch) =>{
  dispatch(getWalletTransactionRequest())
  try{
    const responseData = await investorService.getWalletTransaction(payload)
    if(responseData?.status === 200){
    dispatch(getWalletTransactionSuccess(responseData?.data))
    }
    else{
    dispatch(getWalletTransactionFailure(responseData?.errors))
    }
  }
  catch(error){
    dispatch(getWalletTransactionFailure(error?.response?.data))
  }
}

// actions for get investement details

const getInvestmentDetailsRequest = () => {
  return{
    type: actionTypes.GET_INVESTMENT_DETAILS_REQUEST
  }
}

const getInvestmentDetailsSuccess = (data) => {
  return{
    type: actionTypes.GET_INVESTMENT_DETAILS_SUCCESS,
    payload:data
  }
}

const getInvestmentDetailsFailure = (error) => {
return{
  type: actionTypes.GET_INVESTMENT_DETAILS_FAILURE,
  paylad:error
}
}

export const getInvestmentDetailsAction = (payload) => async(dispatch) =>{
  dispatch(getInvestmentDetailsRequest())
  try{
    const responseData = await investorService.getInvestmentDetails(payload)
    if(responseData?.status === 200){
    dispatch(getInvestmentDetailsSuccess(responseData?.data))
    }
    else{
    dispatch(getInvestmentDetailsFailure(responseData?.errors))
    }
  }
  catch(error){
    dispatch(getInvestmentDetailsFailure(error?.response?.data))
  }
}

// actions for get investor loan list status

const getInvestorLoanListStatusRequest = () => {
  return{
    type: actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_REQUEST
  }
}

const getInvestorLoanListStatusSuccess = (data) => {
  return{
    type: actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_SUCCESS,
    payload:data
  }
}

const getInvestorLoanListStatusFailure = (error) => {
return{
  type: actionTypes.GET_INVESTOR_LOAN_LIST_STATUS_FAILURE,
  paylad:error
}
}

export const getInvestorLoanListStatusAction = (payload) => async(dispatch) =>{
  dispatch(getInvestorLoanListStatusRequest())
  try{
    const responseData = await investorService.getInvestorLoanListStatus(payload)
    if(responseData?.status === 200){
    dispatch(getInvestorLoanListStatusSuccess(responseData?.data))
    }
    else{
    dispatch(getInvestorLoanListStatusFailure(responseData?.errors))
    }
  }
  catch(error){
    dispatch(getInvestorLoanListStatusFailure(error?.response?.data))
  }
}

/// get invest in loan actions
  const getInvestInLoanRequest = () =>{
    return{
      type: actionTypes.GET_INVEST_IN_LOAN_REQUEST
    }
  }

  const getInvestInLoanSuccess = (data) =>{
    return{
      type: actionTypes.GET_INVEST_IN_LOAN_SUCCESS,
      payload:data
    }
  }
  const getInvestInLoanFailure = (error) =>{
    return{
      type: actionTypes.GET_INVEST_IN_LOAN_FAILURE,
      payload:error
    }
  }

  export const investInLoanAction = (payload, data) => async(dispatch) => {
    dispatch(getInvestInLoanRequest())
    try{
      const responseData = await investorService.InvestInLoan(payload)
        if(responseData?.status === 200){
          dispatch(getInvestInLoanSuccess(responseData?.data))
          if (data.type === 'lending_preference') {
            dispatch(getQuickInvestmentAction(data))
          }
        }
      else{
          dispatch(getInvestInLoanFailure(responseData?.errors))
        }
      }
    catch (error){
      dispatch(getInvestInLoanFailure(error?.response?.data))
    }
  }

  /// get wallet download passbook actions
  const getWalletPassbookRequest = () =>{
    return{
      type: actionTypes.GET_WALLET_PASSBOOK_REQUEST
    }
  }

  const getWalletPassbookSuccess = (data) =>{
    return{
      type: actionTypes.GET_WALLET_PASSBOOK_SUCCESS,
      payload:data
    }
  }
  const getWalletPassbookFailure = (error) =>{
    return{
      type: actionTypes.GET_WALLET_PASSBOOK_FAILURE,
      payload:error
    }
  }

  export const getWalletPassbookAction = (payload) => async(dispatch) => {
    dispatch(getWalletPassbookRequest())
    try{
      const responseData = await investorService.getWalletPassbook(payload)
        if(responseData?.status === 200){
          dispatch(getWalletPassbookSuccess(responseData?.data))
        }
      else{
          dispatch(getWalletPassbookFailure(responseData?.errors))
        }
      }
    catch (error){
      dispatch(getWalletPassbookFailure(error?.response?.data))
    }
  }

/// get wallet withdraw amount
const withdrawWalletAmountRequest = () =>{
  return{
    type: actionTypes.WITHDRAW_WALLET_AMOUNT_REQUEST
  }
}

const withdrawWalletAmountSuccess = (data) =>{
  return{
    type: actionTypes.WITHDRAW_WALLET_AMOUNT_SUCCESS,
    payload:data
  }
}
const withdrawWalletAmountFailure = (error) =>{
  return{
    type: actionTypes.WITHDRAW_WALLET_AMOUNT_FAILURE,
    payload:error
  }
}

export const withdrawWalletAmountAction = (payload) => async(dispatch) => {
  dispatch(withdrawWalletAmountRequest())
  try{
    const responseData = await investorService.withdrawWalletAmount(payload)
      if(responseData?.status === 200){
        dispatch(withdrawWalletAmountSuccess(responseData?.data))
        dispatch(getInvestmentWalletAction())
        dispatch(getWalletTransactionAction())
      }
    else{
        dispatch(withdrawWalletAmountFailure(responseData?.errors))
      }
    }
  catch (error){
    dispatch(withdrawWalletAmountFailure(error?.response?.data))
  }
}

/// post nominee details
const postNomineeDetailsRequest = () =>{
  return{
    type: actionTypes.POST_NOMINEE_DETAILS_REQUEST
  }
}

const postNomineeDetailsSuccess = (data) =>{
  return{
    type: actionTypes.POST_NOMINEE_DETAILS_SUCCESS,
    payload:data
  }
}
const postNomineeDetailsFailure = (error) =>{
  return{
    type: actionTypes.POST_NOMINEE_DETAILS_FAILURE,
    payload:error
  }
}

export const postNomineeDetailsAction = (payload) => async(dispatch) => {
  dispatch(postNomineeDetailsRequest())
  try{
    const responseData = await investorService.postNomineeDetails(payload)
      if(responseData?.status === 200){
        dispatch(postNomineeDetailsSuccess(responseData?.data))
        dispatch(getCurrentUserDetailsAction())
      }
    else{
        dispatch(postNomineeDetailsFailure(responseData?.errors))
      }
    }
  catch (error){
    dispatch(postNomineeDetailsFailure(error?.response?.data))
  }
}

/// post payment mode change
const postChangePaymentModeRequest = () =>{
  return{
    type: actionTypes.POST_PAYMENT_MODE_REQUEST
  }
}

const postChangePaymentModeSuccess = (data) =>{
  return{
    type: actionTypes.POST_PAYMENT_MODE_SUCCESS,
    payload:data
  }
}
const postChangePaymentModeFailure = (error) =>{
  return{
    type: actionTypes.POST_PAYMENT_MODE_FAILURE,
    payload:error
  }
}

export const postChangePaymentModeAction = (payload) => async(dispatch) => {
  dispatch(postChangePaymentModeRequest())
  try{
    const responseData = await investorService.changePaymentMode(payload)
      if(responseData?.status === 200){
        dispatch(postChangePaymentModeSuccess(responseData?.data))
        dispatch(getInvestmentWalletAction())
      }
    else{
        dispatch(postChangePaymentModeFailure(responseData?.errors))
      }
    }
  catch (error){
    dispatch(postChangePaymentModeFailure(error?.response?.data))
  }
}