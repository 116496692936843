export const GET_MOBILE_OTP_REQUEST = 'GET_MOBILE_OTP_REQUEST'
export const GET_MOBILE_OTP_FAILURE = 'GET_MOBILE_OTP_FAILURE'
export const GET_MOBILE_OTP_SUCCESS = 'GET_MOBILE_OTP_SUCCESS'

export const VERIFY_MOBILE_OTP_REQUEST = 'VERIFY_MOBILE_OTP_REQUEST'
export const VERIFY_MOBILE_OTP_FAILURE = 'VERIFY_MOBILE_OTP_FAILURE'
export const VERIFY_MOBILE_OTP_SUCCESS = 'VERIFY_MOBILE_OTP_SUCCESS'

export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST'
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE'
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS'

export const GET_CP_OTP_REQUEST = 'GET_CP_OTP_REQUEST'
export const GET_CP_OTP_FAILURE = 'GET_CP_OTP_FAILURE'
export const GET_CP_OTP_SUCCESS = 'GET_CP_OTP_SUCCESS'

export const VERIFY_CP_OTP_REQUEST = 'VERIFY_CP_OTP_REQUEST'
export const VERIFY_CP_OTP_FAILURE = 'VERIFY_CP_OTP_FAILURE'
export const VERIFY_CP_OTP_SUCCESS = 'VERIFY_CP_OTP_SUCCESS'

export const GET_SALES_OTP_REQUEST = 'GET_SALES_OTP_REQUEST'
export const GET_SALES_OTP_FAILURE = 'GET_SALES_OTP_FAILURE'
export const GET_SALES_OTP_SUCCESS = 'GET_SALES_OTP_SUCCESS'

export const VERIFY_SALES_OTP_REQUEST = 'VERIFY_SALES_OTP_REQUEST'
export const VERIFY_SALES_OTP_FAILURE = 'VERIFY_SALES_OTP_FAILURE'
export const VERIFY_SALES_OTP_SUCCESS = 'VERIFY_SALES_OTP_SUCCESS'
