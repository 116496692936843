import React from 'react'
import { Box, Heading, Input } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'

export default function ProfilePersonalDetails(props) {

  const personal_details = props?.unmaskedData?.personal_details
  const address = `${props?.userData?.[0]?.personal_data?.address_houseNumber ? props?.userData?.[0]?.personal_data?.address_houseNumber : ''}${' '}${props?.userData?.[0]?.personal_data?.address_subdistrict ? props?.userData?.[0]?.personal_data?.address_subdistrict : ''}${' '}${props?.userData?.[0]?.personal_data?.address_state ? props?.userData?.[0]?.personal_data?.address_state : ''}${' '}${props?.userData?.[0]?.personal_data?.address_pincode ? props?.userData?.[0]?.personal_data?.address_pincode : ''}`
  const unmaskedAddress = `${personal_details?.personal_data?.address_houseNumber}${' '}${personal_details?.personal_data?.address_subdistrict}${' '}${personal_details?.personal_data?.address_state}${' '}${personal_details?.personal_data?.address_pincode}`

  return (
    <Box mt={7}>
        <Box className='details_container'>
            <Box mb={3} className='d-flex justify-content-end'>
                <CustomButton size="md" type="submit" title="Show Details" className="btn_theme font-poppins" onClick={() => props.handleShowDetails('personal_details')} />
            </Box>
            <>
                <Box mb={4} className='details_box masked_view'>
                    <Heading as="h5" className='font-poppins'>Phone Number</Heading>
                    <Input size="md" type="text" className="font-poppins" isReadOnly
                        value={personal_details?.mobile ? personal_details?.mobile : props?.userData?.[0]?.mobile ? props?.userData?.[0]?.mobile : 'N/A'}
                    />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>Email ID</Heading>
                    <Input size="md" type="text" className="font-poppins" isReadOnly
                        value={personal_details?.email ? personal_details?.email : props?.userData?.[0]?.email ? props?.userData?.[0]?.email : 'N/A'} 
                    />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>Address</Heading>
                    <Input size="md" type="text" className="font-poppins" value={personal_details?.personal_data ? unmaskedAddress : address.trim().length > 0 ? address : 'N/A'} isReadOnly />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>PAN Card</Heading>
                    <Input size="md" type="text" className="font-poppins" isReadOnly
                        value={personal_details?.pancard_data?.pan ? personal_details?.pancard_data?.pan : props?.userData?.[0]?.pan ? props?.userData?.[0]?.pan : 'N/A'} 
                    />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>CKYC Number</Heading>
                    <Input size="md" type="text" className="font-poppins" value={props?.userData?.[0]?.ckyc_detail?.ckyc_number ? props?.userData?.[0]?.ckyc_detail?.ckyc_number : 'N/A'} isReadOnly />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>Gender</Heading>
                    <Input size="md" type="text" className="font-poppins" value={props?.userData?.[0]?.personal_data?.gender === 'F' ? 'Female' : props?.userData?.[0]?.personal_data?.gender === 'M' ? 'Male' : 'N/A'} isReadOnly />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>Date of Birth</Heading>
                    <Input size="md" type="text" className="font-poppins" isReadOnly
                        value={personal_details?.dob ? personal_details?.dob : props?.userData?.[0]?.dob ? props?.userData?.[0]?.dob : 'N/A'} 
                    />
                </Box>
                <Box mb={4} className='details_box'>
                    <Heading as="h5" className='font-poppins'>Father’s Name</Heading>
                    <Input size="md" type="text" className="font-poppins" isReadOnly
                        value={personal_details?.personal_data?.father_name ? personal_details?.personal_data?.father_name : props?.userData?.[0]?.personal_data?.father_name ? props?.userData?.[0]?.personal_data?.father_name : 'N/A'} 
                    />
                </Box>
            </>
        </Box>
    </Box>
  )
}
