import apiInstance from '../config/api/axios'

export const getCPDashboardDetails = async(payload) => {
  const response = await apiInstance.get('/get-cp-dashboard-details', payload)
  return response
}

export const getCPUsers = async(payload) => {
  const response = await apiInstance.get('/get-cp-users', payload)
  return response
}

export const getCPPayout = async(payload) => {
  const response = await apiInstance.get('/get-cp-payout', payload)
  return response
}

export const validateCPUser = async(payload) => {
  const response = await apiInstance.post('/check-cp-user', payload)
  return response
}

export const getCPUserDetailById = async(payload) => {
  const response = await apiInstance.get(`cp/get-user-details-by-id?user_id=${payload.user_id}`, payload)
  return response
}

export const verifyCPPanCard = async(payload) => {
  const response = await apiInstance.post('/cp/verifyPancard', payload)
  return response
}

export const createCPDigiLocker = async(payload) => {
  const response = await apiInstance.post('/cp/create-digilocker-request', payload)
  return response
}

export const getdownloadCPAadhaarData = async(payload) => {
  const response = await apiInstance.post('/cp/download-aadhaar-data', payload)
  return response
}

export const verifyCPBankAccount = async(payload) => {
  const response = await apiInstance.post('/cp/bankVerify', payload)
  return response
}

export const saveCPBankDetails = async(payload) => {
  const response = await apiInstance.post('/cp/save-bank-detail', payload)
  return response
}

export const generateCPOrder = async(payload) => {
  const response = await apiInstance.post('/cp/generate-order', payload)
  return response
}

export const getCPPaymentStatus = async(payload) => {
  const response = await apiInstance.post('/cp/check-payment-status', payload)
  return response
}

export const updateCPOrderStatus = async(payload) => {
  const response = await apiInstance.put('/cp/update-order-status', payload)
  return response
}

export const createLoan = async(payload) => {
  const response = await apiInstance.post('/cp/create-loan', payload)
  return response
}

export const cpLoanList = async(payload) => {
  const response = await apiInstance.get(`/cp/loanlist?statuses=${payload.status}`, payload)
  return response
}

export const createCPESignRequest = async(payload) => {
  const response = await apiInstance.post('/cp/borrower/create-esign-request', payload)
  return response
}

export const getInvoice = async(payload) => {
  const response = await apiInstance.get('/get-invoice', payload)
  return response
}

export const acceptCPLoanEligibility = async(payload) => {
  const response = await apiInstance.post('/cp/accept-eligible-loan', payload)
  return response
}

export const withdrawnCPLoanEligibility = async(payload) => {
  const response = await apiInstance.post('/cp/withdraw-loan', payload)
  return response
}