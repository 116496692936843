import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  getMobileOTP: {
    data: null,
    loading: false,
    error: null,
  },
  verifyMobileOTP: {
    data: null,
    loading: false,
    error: null,
  },
  createNewUser: {
    data: null,
    loading: false,
    error: null,
  },
  getCPOTP: {
    data: null,
    loading: false,
    error: null,
  },
  verifyCPOTP: {
    data: null,
    loading: false,
    error: null,
  },
  getSalesOTP: {
    data: null,
    loading: false,
    error: null,
  },
  verifySalesOTP: {
    data: null,
    loading: false,
    error: null,
  },
}

const authReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_MOBILE_OTP_REQUEST:
      return {
        ...state,
        getMobileOTP: {
          ...state.getMobileOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        getMobileOTP: {
          ...state.getMobileOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_MOBILE_OTP_FAILURE:
      return {
        ...state,
        getMobileOTP: {
          ...state.getMobileOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.VERIFY_MOBILE_OTP_REQUEST:
      return {
        ...state,
        verifyMobileOTP: {
          ...state.verifyMobileOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.VERIFY_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        verifyMobileOTP: {
          ...state.verifyMobileOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.VERIFY_MOBILE_OTP_FAILURE:
      return {
        ...state,
        verifyMobileOTP: {
          ...state.verifyMobileOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        createNewUser: {
          ...state.createNewUser,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createNewUser: {
          ...state.createNewUser,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.CREATE_NEW_USER_FAILURE:
      return {
        ...state,
        createNewUser: {
          ...state.createNewUser,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_CP_OTP_REQUEST:
      return {
        ...state,
        getCPOTP: {
          ...state.getCPOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CP_OTP_SUCCESS:
      return {
        ...state,
        getCPOTP: {
          ...state.getCPOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CP_OTP_FAILURE:
      return {
        ...state,
        getCPOTP: {
          ...state.getCPOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.VERIFY_CP_OTP_REQUEST:
      return {
        ...state,
        verifyCPOTP: {
          ...state.verifyCPOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.VERIFY_CP_OTP_SUCCESS:
      return {
        ...state,
        verifyCPOTP: {
          ...state.verifyCPOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.VERIFY_CP_OTP_FAILURE:
      return {
        ...state,
        verifyCPOTP: {
          ...state.verifyCPOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_SALES_OTP_REQUEST:
      return {
        ...state,
        getSalesOTP: {
          ...state.getSalesOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_SALES_OTP_SUCCESS:
      return {
        ...state,
        getSalesOTP: {
          ...state.getSalesOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_SALES_OTP_FAILURE:
      return {
        ...state,
        getSalesOTP: {
          ...state.getSalesOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.VERIFY_SALES_OTP_REQUEST:
      return {
        ...state,
        verifySalesOTP: {
          ...state.verifySalesOTP,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.VERIFY_SALES_OTP_SUCCESS:
      return {
        ...state,
        verifySalesOTP: {
          ...state.verifySalesOTP,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.VERIFY_SALES_OTP_FAILURE:
      return {
        ...state,
        verifySalesOTP: {
          ...state.verifySalesOTP,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CLEANUP_TOAST:
      return {
        ...state,
        getMobileOTP: {
          data: null,
          loading: false,
          error: null,
        },
        verifyMobileOTP: {
          data: null,
          loading: false,
          error: null,
        },
        createNewUser: {
          data: null,
          loading: false,
          error: null,
        },
        getCPOTP: {
          data: null,
          loading: false,
          error: null,
        },
        verifyCPOTP: {
          data: null,
          loading: false,
          error: null,
        },
        getSalesOTP: {
          data: null,
          loading: false,
          error: null,
        },
        verifySalesOTP: {
          data: null,
          loading: false,
          error: null,
        }
      }
    default:
      return state
  }
}

export default authReducers
