import React, { useEffect, useRef, useState } from 'react'
import {Box, Grid, GridItem, Heading, Image, Input, Select, Text, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { ActionCreators } from '../../../../redux/actions'
import CustomToast from '../../../elements/customToast'
import Loader from '../../../elements/loader'
import AddIcon from '../../../../assets/images/addFile.svg'
import EquifaxIcon from '../../../../assets/images/equifax.svg'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import { getItem, removeItem } from '../../../../utilities/authUtils'
import * as routesNames from '../../../../constants/routes'

const OnBoardApplyLoan = (props) => {

  const CREDITSCORE = getItem('score')
  const BORROWERNUMBER = getItem('borrower_phone')
  const BORROWERNAME = getItem('name')
  const history = useHistory()
  const {addToast} = CustomToast()
  const adminState = useSelector((state) => state.adminState)
  const masterState = useSelector((state) => state.masterState)
  const borrowerState = useSelector((state) => state.borrowerState)
  const cpState = useSelector((state) => state.cpState)
  const bankRef = useRef()
  const incomeRef = useRef()
  const itrRef = useRef()
  const cpAssesmentRef = useRef()
  const pdcRef = useRef()
  const [inputValues, setInputValues] = useState({})
  const [bankStatement, setBankStatement] = useState(null)
  const [incomeStatement, setIncomeStatement] = useState(null)
  const [itrStatement, setItrStatement] = useState(null)
  const [cpAssesment, setCpAssesment] = useState(null)
  const [pdcData, setPdcData] = useState(null)
  const [expectedROI, setExpectedROI] = useState({roi: [0, 24.5]});
  const [score, setScore] = useState(0)
  const [selectedLoanType, setSelectedLoanType] = useState(null);
  const [loanTenure, setLoanTenure] = useState([0, 0])
  const [loanRange, setLoanRange] = useState(null)
  const userData = adminState?.getUserByToken?.data?.data
  const dropdownData = masterState?.getDropdownData?.data?.data
  const productData = borrowerState?.getProductList?.data?.data
  const loanTenureData = selectedLoanType?.max_tenure_limit && JSON.parse(selectedLoanType?.max_tenure_limit)

    useEffect(() => {
        setTimeout(() => {
            if(CREDITSCORE){
                setScore(CREDITSCORE);
            } else{
                setScore(0);
            }
        }, 2000)
    }, [])

    useEffect(() => {
        props.actions.getUserByTokenAction()
        props.actions.getDropdownDataAction({ type: 'loan_purpose|company_sector|work_exp_level|premise_status|educational_qualification' })
        props.actions.getProductListAction()
    }, []);

    useEffect(() => {
        if (cpState?.createLoan?.data?.success === true){
            addToast({message: cpState?.createLoan?.data?.message, status: 'success'})
            props?.actions?.cleanUpToast()
            setInputValues({})
            setBankStatement(null)
            setIncomeStatement(null)
            setItrStatement(null)
            setCpAssesment(null)
            // setBranchAssesment(null)
            setSelectedLoanType(null)
            removeItem('borrower_phone')
            history.push(routesNames.CPBORROWERS)
        } else if (cpState?.createLoan?.data?.success === false){
            addToast({message: cpState?.createLoan?.data?.message, status: 'error'})
            props?.actions?.cleanUpToast()
        }
    }, [cpState?.createLoan?.data])

  const calculateRotation = () => {
    if (score === 0 || (score >= 1 && score <= 299)) {
      return 0;
    }
    const minScore = 300;
    const maxScore = 900;
    const rotationRange = 225;
    const min = (score - minScore)
    const max = (maxScore - minScore);
    const percentage = min / max
    const rotation = percentage * rotationRange;
    return rotation;
  }
  const rotationNumber = calculateRotation()

  const getStatus = (score) => {
    if (score >= 1 && score <= 299) {
      return { className: 'poor', text: 'Poor' };
    } else if (score >= 300 && score <= 550) {
      return { className: 'poor', text: 'Poor' };
    } else if (score >= 551 && score <= 650) {
      return { className: 'average', text: 'Average' };
    } else if (score >= 651 && score <= 750) {
      return { className: 'fair', text: 'Fair' };
    } else if (score >= 751 && score <= 900) {
      return { className: 'excellent', text: 'Excellent' };
    } else {
      return { className: '', text: '' };
    }
  }
  const { className, text } = getStatus(score)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    if (name === 'loan_amount') {
      const loanAmount = parseInt(value, 10);
      const loanRange = loanTenureData?.find((range) => loanAmount >= parseInt(range.min_amount) && loanAmount <= parseInt(range.max_amount));
      setLoanRange({
        min_tenure: loanRange ? parseInt(loanRange.min_tenure) : 0,
        max_tenure: loanRange ? parseInt(loanRange.max_tenure) : 0,
      });
      // Reset loan tenure when loan amount changes
      setLoanTenure([loanRange ? parseInt(loanRange.min_tenure) : 0, loanRange ? parseInt(loanRange.max_tenure) : 0]);
    } else if (name === 'loan_type') {
        const data = productData.find((item) => item.product_id === Number(value))        
        setSelectedLoanType(data);
        setInputValues({});
        setLoanTenure([0, 0]);
        setLoanRange(null);
        setBankStatement(null);
        setIncomeStatement(null);
        setItrStatement(null);
        setCpAssesment(null);
        // setBranchAssesment(null);
    }
  }

  const showOpenFileDialog = (type) => {
    if (type === 'bank_statement') {
      bankRef.current.click()
      return
    }
    if (type === 'income_proof') {
      incomeRef.current.click()
      return
    }
    if (type === 'itr_proof') {
      itrRef.current.click()
      return
    }
    if (type === 'cp_assesment') {
      cpAssesmentRef.current.click()
      return
    }
    if (type === 'pdc') {
      pdcRef.current.click()
      return
    }
  }

  const onChangeFile = (e, type) => {
    if (type === 'bank_statement') {
        setBankStatement(e.target.files[0])
        if (e.target.files[0].type !== 'application/pdf') {
          addToast({message: 'Bank Statement must be a file of type: pdf. ', status: 'error'});
          return
        }
    }
    if (type === 'income_proof') {
        setIncomeStatement(e.target.files[0])
        if (e.target.files[0].type !== 'application/pdf') {
          addToast({message: 'Income Proof must be a file of type: pdf. ', status: 'error'});
          return
        }
    }
    if (type === 'itr_proof') {
        setItrStatement(e.target.files[0])
        if (e.target.files[0].type !== 'application/pdf') {
          addToast({message: 'ITR Proof must be a file of type: pdf. ', status: 'error'});
          return
        }
    }
    if (type === 'cp_assesment') {
        setCpAssesment(e.target.files[0])
        if (e.target.files[0].type !== 'application/pdf') {
          addToast({message: 'CP Credit Assessment must be a file of type: pdf. ', status: 'error'});
          return
        }
    }
    if (type === 'pdc') {
        setPdcData(e.target.files[0])
    }
  }

  const handleExpectedROI = (e, type) => {
    const updatedDetails = {...expectedROI, [type]: e,}
    setExpectedROI(updatedDetails)
  }

  const handleLoanTenure = (e) => {
    setLoanTenure(e)
  }

  const handleApplyLoan = () => {
    if (!selectedLoanType?.product_id) {
      addToast({message: 'Please Select Loan Type', status: 'error'})
      return
    }
    if (!inputValues?.loan_amount) {
      addToast({message: 'Please Enter Loan Amount', status: 'error'})
      return
    }
    if (!loanTenureData && !inputValues?.loan_tenure) {
      addToast({message: 'Please Enter Loan Tenure', status: 'error'})
      return
    }
    if (!inputValues?.loan_purpose) {
      addToast({message: 'Please Select Loan Purpose', status: 'error'})
      return
    }
    if (!inputValues?.current_company_name) {
      addToast({message: 'Please Enter Current Company Name', status: 'error'})
      return
    }
    if (!inputValues?.company_sector) {
      addToast({message: 'Please Select Company Sector', status: 'error'})
      return
    }
    if (!inputValues?.work_experience) {
      addToast({message: 'Please Select Total Work Experience', status: 'error'})
      return
    }
    if (!inputValues?.monthly_income) {
      addToast({message: 'Please Enter Monthly Income', status: 'error'})
      return
    }
    if (!inputValues?.premise_status) {
      addToast({message: 'Please Enter House Ownership', status: 'error'})
      return
    }
    if (!inputValues?.educational_qualification) {
      addToast({message: 'Please Enter Education Qualification', status: 'error'})
      return
    }
    if (selectedLoanType?.name !== 'Insta Loan') {
        if (!bankStatement) {
            addToast({message: 'Please Upload Bank Statement', status: 'error'})
            return
        }
          if (!incomeStatement) {
            addToast({message: 'Please Upload Income Proof / Salary Slip', status: 'error'})
            return
        }
    }
    let formData = new FormData();
    formData.append("loan_type", selectedLoanType?.product_id);
    formData.append("loan_amount", inputValues?.loan_amount);
    formData.append("tenure", !loanTenureData ? inputValues?.loan_tenure : loanTenure[1]);
    formData.append("loan_purpose", inputValues?.loan_purpose);
    formData.append("premise_status", inputValues?.premise_status ?? userData?.personal_data?.premise_status);
    formData.append("educational_qualification", inputValues?.educational_qualification ?? userData?.personal_data?.educational_qualification);
    formData.append("company_name", inputValues?.current_company_name);
    formData.append("company_sector", inputValues?.company_sector);
    formData.append("work_exp_level", inputValues?.work_experience);
    formData.append("net_monthly_income", inputValues?.monthly_income);
    formData.append("disbursal_consent", 'Y');
    formData.append('bank_statement_1', bankStatement ?? null)
    formData.append('itr_form16', itrStatement ?? null)
    formData.append('salary_slip_1', incomeStatement ?? null)
    formData.append('cp_credit_assessment', cpAssesment ?? null)
    formData.append('post_dated_cheque', pdcData ?? null)
    formData.append('mobile', BORROWERNUMBER ?? null)
    formData.append('expected_roi', expectedROI?.roi[1] ?? null)
    props.actions.createLoanAction(formData)
  }

  return (
    <>
        {(adminState?.getUserByToken?.loading || masterState?.getDropdownData?.loading || borrowerState?.getProductList?.loading || cpState?.createLoan?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={8}>
                    <Heading as="h2" className='font-poppins page_heading'>Loan Application</Heading>
                </GridItem>
                <GridItem colSpan={4}>
                    <NavBar data={userData} isAdmin isCP />
                </GridItem>
            </Grid>
            <Box className='main_Kyc_form_container'>
                <Grid className="main_grid_container">
                    <GridItem colSpan={7}>
                        <Box mb={3} className='form_kyc_cp'>
                            <Box>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Select Loan Type</Heading>
                                <Select placeholder="Please Select Loan Type" variant="filled" className="font-poppins input_box_select"
                                    name="loan_type" onChange={handleChange} value={selectedLoanType?.product_id ?? ''}>
                                    {productData?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.product_id}>{item.name}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box mt={4}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Loan Amount</Heading>
                                <Input size="md" name="loan_amount"  type="text" placeholder="Please Enter Loan Amount" className="input_box font-poppins"
                                    onChange={handleChange} value={inputValues?.loan_amount ?? ''}
                                />
                            </Box>
                            {loanTenureData?.length > 0 ?
                                <Box mt={4} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Loan Tenure<span>(Months)</span></Heading>
                                    <Box className='d-flex align-items-center col-gap-25'>
                                        <RangeSlider value={loanTenure} min={loanRange?.min_tenure} max={loanRange?.max_tenure} step={1}
                                            onChange={(e) => handleLoanTenure(e)} >
                                            <RangeSliderTrack className='range_slider_bg'>
                                                <RangeSliderFilledTrack bg='#BA1F33' />
                                            </RangeSliderTrack>
                                            <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                                            <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                                        </RangeSlider>
                                        <Box className='tenure_block'>
                                            <Heading as="h5" className='font-poppins'>{loanTenure[1]} Months</Heading>
                                        </Box>
                                    </Box>
                                </Box> : 
                                <Box mt={4}>
                                    <Heading as="h1" className="font-poppins label_heading" mb={1}>Loan Tenure</Heading>
                                    <Input size="md" name="loan_tenure"  type="text" placeholder="Please Enter Loan Tenure in Days" className="input_box font-poppins"
                                        onChange={handleChange} value={inputValues?.loan_tenure ?? ''}
                                    />
                                </Box>
                            }
                            <Box mt={4} className='form_inputs expected_roi'>
                                <Heading as="h5" className='font-poppins label_heading'>Expected ROI<span>(Range 12% to 36%)</span></Heading>
                                <Box className='d-flex align-items-center col-gap-25'>
                                    <RangeSlider value={expectedROI?.roi} min={12} max={36} step={0.1}
                                        onChange={(e) => handleExpectedROI(e, 'roi')} >
                                        <RangeSliderTrack className='range_slider_bg'>
                                            <RangeSliderFilledTrack bg='#BA1F33' />
                                        </RangeSliderTrack>
                                        <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                                        <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                                    </RangeSlider>
                                    <Box className='loan_block roi_data'>
                                        <Heading as="h5" className='font-poppins'>{expectedROI?.roi[1]}%</Heading>
                                    </Box>
                                </Box>
                            </Box> 
                            <Box mt={4}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Loan Purpose</Heading>
                                <Select placeholder="Please Select Loan Purpose" variant="filled" className="font-poppins input_box_select"
                                    name="loan_purpose" onChange={handleChange} value={inputValues?.loan_purpose ?? ''}>
                                    {dropdownData?.loan_purpose?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.value}>{item.value}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Grid className="main_grid_container" mt={4}>
                                <GridItem colSpan={6}>
                                    <Heading as="h1" className="font-poppins label_heading" mb={1}>House Ownership</Heading>
                                    <Select placeholder="Please Select House Ownership" variant="filled" className="font-poppins input_box_select"
                                        name="premise_status" onChange={handleChange} value={inputValues?.premise_status ?? userData?.personal_data?.premise_status ?? ''}>
                                        {dropdownData?.premise_status?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </Select>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Heading as="h1" className="font-poppins label_heading" mb={1}>Education Qualification</Heading>
                                    <Select placeholder="Please Select Education Qualification" variant="filled" className="font-poppins input_box_select"
                                        name="educational_qualification" onChange={handleChange} value={inputValues?.educational_qualification ?? userData?.personal_data?.educational_qualification ?? ''}>
                                        {dropdownData?.educational_qualification?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </Select>
                                </GridItem>
                            </Grid>
                            <Grid className="main_grid_container" mt={4}>
                                <GridItem colSpan={6}>
                                    <Heading as="h1" className="font-poppins label_heading" mb={1}>Current Company Name</Heading>
                                    <Input size="md" name="current_company_name"  type="text" placeholder="Your Current Company Name" className="input_box font-poppins"
                                        onChange={handleChange} value={inputValues?.current_company_name ?? ''}
                                    />
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Heading as="h1" className="font-poppins label_heading" mb={1}>Company Sector</Heading>
                                    <Select placeholder="Please Select Company Sector" variant="filled" className="font-poppins input_box_select"
                                        name="company_sector" onChange={handleChange} value={inputValues?.company_sector ?? ''}>
                                        {dropdownData?.company_sector?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </Select>
                                </GridItem>
                            </Grid>
                            <Box mt={4}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Total Work Experience</Heading>
                                <Select placeholder="Please Select Your Total Work Experience Range" variant="filled" className="font-poppins input_box_select"
                                    name="work_experience" onChange={handleChange} value={inputValues?.work_experience ?? ''}>
                                    {dropdownData?.work_exp_level?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.key}>{item.value}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box mt={4}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Net Monthly Income</Heading>
                                <Input size="md" name="monthly_income"  type="text" placeholder="Please Enter Your Net Monthly Income" className="input_box font-poppins"
                                    onChange={handleChange} value={inputValues?.monthly_income ?? ''}
                                />
                            </Box>
                            {selectedLoanType?.name === 'Insta Loan' ? null :
                                <Box mt={6} className='borrow_credit_score'>
                                    <Heading as="h1" className="font-poppins">Upload Documents</Heading>
                                    <Grid className="main_grid_container documents_grid_wrapper" mt={4}>
                                        <GridItem colSpan={6}>
                                            <Box className='form_inputs_kyc_cp'>
                                                <Heading as="h5" className='font-poppins'>Bank Statement</Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => showOpenFileDialog('bank_statement')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(e) => onChangeFile(e, 'bank_statement')}
                                                        className="d-none"
                                                        ref={bankRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{bankStatement?.name}</Text>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <Box className='form_inputs_kyc_cp'>
                                                <Heading as="h5" className='font-poppins'>Income Proof</Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => showOpenFileDialog('income_proof')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(e) => onChangeFile(e, 'income_proof')}
                                                        className="d-none"
                                                        ref={incomeRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{incomeStatement?.name}</Text>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <Box className='form_inputs_kyc_cp'>
                                                <Heading as="h5" className='font-poppins'>ITR <span>(Optional)</span></Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => showOpenFileDialog('itr_proof')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(e) => onChangeFile(e, 'itr_proof')}
                                                        className="d-none"
                                                        ref={itrRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{itrStatement?.name}</Text>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <Box className='form_inputs_kyc_cp'>
                                                <Heading as="h5" className='font-poppins'>CP Credit Assessment <span>(Optional)</span></Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => showOpenFileDialog('cp_assesment')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(e) => onChangeFile(e, 'cp_assesment')}
                                                        className="d-none"
                                                        ref={cpAssesmentRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{cpAssesment?.name}</Text>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                        <GridItem colSpan={6}>
                                            <Box className='form_inputs_kyc_cp'>
                                                <Heading as="h5" className='font-poppins'>Post Dated Cheque <span>(Optional)</span></Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => showOpenFileDialog('pdc')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => onChangeFile(e, 'pdc')}
                                                        className="d-none"
                                                        ref={pdcRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{pdcRef?.name}</Text>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                    </GridItem>
                    <GridItem colSpan={1}></GridItem>
                    <GridItem colSpan={4}>
                        <Box className='borrow_credit_score'>
                            <Heading as="h1" mb={4} className='font-poppins'>Borrower Name : {BORROWERNAME}</Heading>
                            <Heading as="h1" className='font-poppins'>Borrower’s Credit Score</Heading>
                            <Box className='credit_score bg_shadow' mt={3}>
                                <Box mb={4} className='d-flex align-items-center justify-content-between score_flex'>
                                    <Heading as="h2" className='font-poppins w-auto'>Credit Score</Heading>
                                    <Heading as="p" className='font-poppins'>Powered By<Image src={EquifaxIcon} /></Heading>
                                </Box>
                            <Box className="scoreMeter">
                                <Box className={`meterArrow value-${score}`} style={{ transform: `rotate(${rotationNumber}deg)` }}></Box>
                                    <Box className="meterScore">
                                        <Heading as="h2" className='font-poppins score'>{score} </Heading>
                                        <Heading as="h2" className={`font-poppins scoreBand ${className}`}>{text}</Heading>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </GridItem>
                </Grid>
                <Box className="kyc_actions">
                    <CustomButton size="md" type="submit" title="Submit Loan Application" className="btn_theme font-poppins" onClick={handleApplyLoan} />
                </Box>
            </Box>
        </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(OnBoardApplyLoan)