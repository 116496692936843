import React from 'react'
import {Box, Heading, Image, Input, Select, SlideFade, Text, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import CircleIcon from '../../../../../assets/images/cardCircle.svg'
import AddIcon from '../../../../../assets/images/addFile.svg'

export default function LoanCard(props) {

    const userData = props?.userData?.[0]
    const data = props.dropdownData
    const loanPurposeOptions = data?.loan_purpose
    const premisesOptions = data?.premise_status
    const educationOptions = data?.educational_qualification
    const companySectorOptions = data?.company_sector
    const workExperienceOptions = data?.work_exp_level

  return (
    <Box>
        {props?.productData?.map((item, index) => {
            return (
                <Box key={index}>
                    <Box className={props.productId === item.product_id ? 'card_block bg_shadow' : 'card_block'} mb={5}>
                        <Box className='card_top'>
                            <Box className='card_top_header'>
                                <Heading as="h3" className='font-poppins'>{item.name}{item.product_id === 3 && <span>Zero Charges</span>}</Heading>
                                <Heading as="p" className='font-poppins'>{item.product_subtitle}</Heading>
                            </Box>
                            <Box className='card_top_header'>
                                <Heading as="h5" className='font-poppins'>{item.payment_frequency === 0 ? 'Monthly Repayment' : 'One Time Repayment'}</Heading>
                            </Box>
                        </Box>
                        <Box className='card_bottom'>
                            <Box className='card_bottom_header'>
                                <Heading as="h6" className='font-poppins'>Amount Range</Heading>
                                <Heading as="h1" className='font-poppins'>{`${'₹'}${item.min_loan_amount}${' - '}${'₹'}${item.max_loan_amount}`}</Heading>
                            </Box>
                            <Box className='card_bottom_header'>
                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                {/* {/* <Heading as="h1" className='font-poppins'>{(item.product_id === 1 ? `${item.min_tenure}${' - '}${item.max_tenure}${' Months'}` : `${item.min_days}${' - '}${item.max_days}${' Days'}`}</Heading> */}
                                <Heading as="h1" className='font-poppins'>{(item.min_tenure  && item.max_tenure) ? `${item.min_tenure}${' - '}${item.max_tenure}${' Months'}` : `${item.min_days}${' - '}${item.max_days}${' Days'}`}</Heading>
                            </Box>
                            <CustomButton size="md" type="submit" title={props.productId === item.product_id ? "Close" : 'Apply Now'}
                                className="btn_theme font-poppins" onClick={() => props.handleLoans(item.product_id)}
                                isDisabled={props?.isLoanApplied || props?.isKYCUnderReview || props?.isKYCPending}
                                // isDisabled={props?.isLoanApplied || props?.isKYCUnderReview || props?.isKYCPending || props?.isLoanRejected === 0}
                            />
                        </Box>
                        <Box className='card_image'>
                            <Image src={CircleIcon} />
                        </Box>
                    </Box>
                    {props.productId === item.product_id &&
                        <SlideFade in={props.productId === item.product_id} offsetY="500px">
                            <Box className='form_block' mb={5}>
                                <Box mb={3} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Loan Type</Heading>
                                    <Input size="md" name="loan_type" type="text" className="input_box font-poppins" value={item.name} isReadOnly />
                                </Box>
                                <Box mb={3} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Loan Amount</Heading>
                                    <Input size="md" name="loan_amount"  type="text" placeholder="Please Enter Loan Amount" className="input_box font-poppins"
                                        onChange={props.handleChange} value={props.inputValues?.loan_amount ?? ''}
                                    />
                                </Box> 
                                {item.payment_frequency === 0 ?
                                    <Box mb={3} className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>Loan Tenure<span>(Months)</span></Heading>
                                        <Box className='d-flex align-items-center col-gap-25'>
                                            <RangeSlider value={props?.loanTenure} min={props?.loanRange?.min_tenure} max={props?.loanRange?.max_tenure} step={1}
                                                onChange={(e) => props.handleLoanTenure(e)} >
                                                <RangeSliderTrack className='range_slider_bg'>
                                                    <RangeSliderFilledTrack bg='#BA1F33' />
                                                </RangeSliderTrack>
                                                <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                                                <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                                            </RangeSlider>
                                            <Box className='tenure_block'>
                                                <Heading as="h5" className='font-poppins'>{props?.loanTenure[1]} Months</Heading>
                                            </Box>
                                        </Box>
                                    </Box> :
                                    <Box mb={3} className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>Loan Tenure</Heading>
                                        <Input size="md" name="loan_tenure"  type="text" placeholder="Please Enter Loan Tenure in Days" className="input_box font-poppins"
                                            onChange={props.handleChange} value={props.inputValues?.loan_tenure ?? ''}
                                        />
                                    </Box> 
                                }
                                {item.product_id === 3 ? null :
                                    <Box mb={3} className='form_inputs expected_roi'>
                                        <Heading as="h5" className='font-poppins'>Expected ROI<span>(Range 12% to 36%)</span></Heading>
                                        <Box className='d-flex align-items-center col-gap-25'>
                                            <RangeSlider value={props?.expectedROI?.roi} min={12} max={36} step={0.1}
                                                onChange={(e) => props.handleExpectedROI(e, 'roi')} >
                                                <RangeSliderTrack className='range_slider_bg'>
                                                    <RangeSliderFilledTrack bg='#BA1F33' />
                                                </RangeSliderTrack>
                                                <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                                                <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                                            </RangeSlider>
                                            <Box className='loan_block roi_data'>
                                                <Heading as="h5" className='font-poppins'>{props?.expectedROI?.roi[1]}%</Heading>
                                            </Box>
                                        </Box>
                                    </Box> 
                                }
                                <Box mb={3} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Loan Purpose</Heading>
                                    <Select placeholder="Please Select Loan Purpose" variant="filled" className="font-poppins input_box_select"
                                        name="loan_purpose" onChange={props.handleChange} value={props.inputValues?.loan_purpose ?? ''}>
                                        {loanPurposeOptions?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>{item.value}</option>
                                            )
                                        })}
                                    </Select>
                                </Box>
                                <Box mb={3} className='d-flex align-items-center justify-content-between col-gap-15'>
                                    <Box className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>House Ownership</Heading>
                                        <Select placeholder="Please Select House Ownership" variant="filled" className="font-poppins input_box_select"
                                            name="premise_status" onChange={props.handleChange} value={props.inputValues?.premise_status ?? userData?.personal_data?.premise_status ?? ''}>
                                            {premisesOptions?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                    <Box className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>Education Qualification</Heading>
                                        <Select placeholder="Please Select Education Qualification" variant="filled" className="font-poppins input_box_select"
                                            name="educational_qualification" onChange={props.handleChange} value={props.inputValues?.educational_qualification ?? userData?.personal_data?.educational_qualification ?? ''}>
                                            {educationOptions?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box mb={3} className='d-flex align-items-center justify-content-between col-gap-15'>
                                    <Box className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>Current Company Name</Heading>
                                        <Input size="md" name="current_company_name"  type="text" placeholder="Your Current Company Name" className="input_box font-poppins"
                                            onChange={props.handleChange} value={props.inputValues?.current_company_name ?? ''}
                                        />
                                    </Box>
                                    <Box className='form_inputs'>
                                        <Heading as="h5" className='font-poppins'>Company Sector</Heading>
                                        <Select placeholder="Please Select Company Sector" variant="filled" className="font-poppins input_box_select"
                                            name="company_sector" onChange={props.handleChange} value={props.inputValues?.company_sector ?? ''}>
                                            {companySectorOptions?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.key}>{item.value}</option>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box mb={3} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Total Work Experience <span>(Years)</span></Heading>
                                    <Select placeholder="Please Select Your Total Work Experience Range" variant="filled" className="font-poppins input_box_select"
                                        name="work_experience" onChange={props.handleChange} value={props.inputValues?.work_experience ?? ''}>
                                        {workExperienceOptions?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.value}</option>
                                            )
                                        })}
                                    </Select>
                                </Box> 
                                <Box mb={3} className='form_inputs'>
                                    <Heading as="h5" className='font-poppins'>Net Monthly Income</Heading>
                                    <Input size="md" name="monthly_income"  type="text" placeholder="Please Enter Your Net Monthly Income" className="input_box font-poppins"
                                        onChange={props.handleChange} value={props.inputValues?.monthly_income ?? ''}
                                    />
                                </Box>
                                {item.product_id === 3 ? null :
                                    <Box mb={4} className='d-flex justify-content-start col-gap-15'>
                                        <Box className='form_inputs_upload form_inputs'>
                                            <Heading as="h5" className='font-poppins'>Bank Statement<span>(3 Months)</span></Heading>
                                            <Box>
                                                <CustomButton
                                                    className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                    title="Browse"
                                                    onClick={() => props.showOpenFileDialog('bank_statement')}
                                                    leftIcon={<Image src={AddIcon} />}
                                                />
                                                <Input
                                                    size="md"
                                                    name="upload"
                                                    type="file"
                                                    accept=".pdf"
                                                    onChange={(e) => props.onChangeFile(e, 'bank_statement')}
                                                    className="d-none"
                                                    ref={props.bankRef}
                                                />
                                                <Text className='font-poppins note file_name'>{props?.bankStatement?.name}</Text>
                                            </Box>
                                        </Box>
                                        {userData?.employment_type === '1' &&
                                            <Box className='form_inputs_upload form_inputs'>
                                                <Heading as="h5" className='font-poppins'>Income Proof</Heading>
                                                <Box>
                                                    <CustomButton
                                                        className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                        title="Browse"
                                                        onClick={() => props.showOpenFileDialog('income_proof')}
                                                        leftIcon={<Image src={AddIcon} />}
                                                    />
                                                    <Input
                                                        size="md"
                                                        name="upload"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(e) => props.onChangeFile(e, 'income_proof')}
                                                        className="d-none"
                                                        ref={props.incomeRef}
                                                    />
                                                    <Text className='font-poppins note file_name'>{props?.incomeStatement?.name}</Text>
                                                </Box>
                                            </Box>
                                        }
                                        <Box className='form_inputs_upload form_inputs'>
                                            <Heading as="h5" className='font-poppins'>ITR <span>(Optional)</span></Heading>
                                            <Box>
                                                <CustomButton
                                                    className="btn_transparent_red font-poppins col-gap-10 w-100"
                                                    title="Browse"
                                                    onClick={() => props.showOpenFileDialog('itr_proof')}
                                                    leftIcon={<Image src={AddIcon} />}
                                                />
                                                <Input
                                                    size="md"
                                                    name="upload"
                                                    type="file"
                                                    accept=".pdf"
                                                    onChange={(e) => props.onChangeFile(e, 'itr_proof')}
                                                    className="d-none"
                                                    ref={props.itrRef}
                                                />
                                                <Text className='font-poppins note file_name'>{props?.itrStatement?.name}</Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                <Box className='d-flex justify-content-end apply_btn'>
                                    <CustomButton size="md" type="submit" title="Apply" className="btn_theme font-poppins"
                                        onClick={() => props.handleApplyLoan(item.product_id)}
                                    />
                                </Box>
                            </Box>
                        </SlideFade>
                    }
                </Box>
            )
        })}
    </Box>
  )
}
