import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import AppLayout from '../../../config/default/default'
import UpdateProfile from '../../modules/KYCModule/UpdateProfile'
import NotFound from '../NotFound'
import InvesterDashboard from '../invest/Dashboard/InvesterDashboard'
import InvestorPortfolio from '../invest/Dashboard/InvestorPortfolio'
import ManualInvestment from '../invest/InvestmentOpportunities/ManualInvestment'
import PledgedInvestment from '../invest/InvestmentOpportunities/PledgedInvestment'
import ActiveInvestments from '../invest/AllInvestments/ActiveInvestments'
import ClosedInvestments from '../invest/AllInvestments/ClosedInvestments'
import InvestorProfile from '../invest/InvestorsProfile/InvestorProfile'
import Wallet from '../invest/Wallet/Wallet'

export default function InvestorRoutes() {
  return (
    <>
    <Route exact path={routesNames.INVESTERKYC} component={UpdateProfile}/>
    <AppLayout>
        <Switch>
            <Route exact path={routesNames.INVESTERDASHBOARD} component={InvesterDashboard}/>
            <Route exact path={routesNames.INVESTERPORTFOLIO} component={InvestorPortfolio}/>
            <Route exact path={routesNames.MANUALINVESTMENT} component={ManualInvestment}/>
            <Route exact path={routesNames.PLEDGEDINVESTMENT} component={PledgedInvestment}/>
            <Route exact path={routesNames.ACTIVEINVESTMENT} component={ActiveInvestments}/>
            <Route exact path={routesNames.CLOSEDINVESTMENT} component={ClosedInvestments} />
            <Route exact path={routesNames.INVESTORPROFILE} component={InvestorProfile} />
            <Route exact path={routesNames.WALLET} component={Wallet}/>
            <Route component={NotFound} />
        </Switch>
    </AppLayout>
    </>
  )
}
