import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import NoData from '../../../elements/NoData'
import Pagination from '../../../elements/Pagination'

const CPInvestors = (props) => {

    const history = useHistory()
    const cpState = useSelector((state) => state.cpState)
    const adminState = useSelector((state) => state.adminState)
    const cpUserData = cpState?.getCPUsers?.data?.data
    const filterCPUserData = cpUserData?.filter((item) => item?.usertype === '3')
    const [currentPage, setCurrentPage] = useState(0)
    const userData = adminState?.getUserByToken?.data?.data

    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(filterCPUserData?.length / PER_PAGE);

    useEffect(() => {
        props.actions.getCPUsersAction()
        props.actions.getUserByTokenAction()
    }, []);

    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <>
        {(cpState?.getCPUsers?.loading || adminState?.getUserByToken?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
              <Heading as="h2" className='font-poppins page_heading'>My Investors</Heading>
            </GridItem>
            <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin isCP />
            </GridItem>
          </Grid>
          {filterCPUserData?.length > 0 ?
            <Box mt={5} className='custom_table_wrapper'>
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>S.No.</Th>
                                <Th>Name</Th>
                                <Th>Email Id</Th>
                                <Th>Phone Number</Th>
                                <Th>City</Th>
                                <Th>Escrow Balance</Th>
                                <Th>Disbursed Amount</Th>
                                <Th>Investor Type</Th>
                                <Th>Stage</Th>
                                <Th>Current Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {filterCPUserData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                const serialNumber = offset + index + 1;
                                return (
                                    <Tr key={index}>
                                        <Td>
                                            <Text className="font-poppins table_text">{serialNumber}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.first_name}{' '}{item?.last_name}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.email}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.mobile}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.personal_data?.address_district}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">₹{item?.wallet_data?.total_wallet_balance_as_on_date}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text"></Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">{item?.display_employment_type}</Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">
                                              {(item?.userStage === 0 || item?.userStage === 1) ? 'Registration' :
                                                (item?.userStage === 2 && item?.account_approval_status === 1) ? 'Approved' : ''
                                              }
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text className="font-poppins table_text">
                                                {(item.userStage === 1 || item.userStage === 0)&& item.form_completed_status === 0 && 'Incomplete'}
                                                {item.userStage === 1 && (item.document_data && item.document_data.doc_approval_status === 0 && (item.document_data.doc_checked_by === 3 || item.document_data.doc_checked_by == null || item.document_data.doc_checked_by === 1)) && 'Pending with Pre-sanction'}
                                                {item.userStage === 2 && item.account_approval_status === 1 && 'Profile approved'}
                                                {item.userStage === 1 && (item.document_data && item.document_data.doc_approval_status === 1 && item.document_data.doc_checked_by === 1) && 'Documents Incomplete'}
                                            </Text>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
            </Box> :
            <NoData title="No Record Found" />
          }
        </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(CPInvestors)