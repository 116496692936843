import React from 'react'
import { Route, Switch } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import AppLayout from '../../../config/default/default'
import NotFound from '../NotFound'
import CPDashboard from '../CPPartner/Dashboard/CPDashboard'
import CPBorrowers from '../CPPartner/Borrowers/CPBorrowers'
import CPInvestors from '../CPPartner/Investors/CPInvestors'
import CPPayouts from '../CPPartner/Payouts/CPPayouts'
import OnBoardApplyLoan from '../CPPartner/CPLoanApplication/OnBoardApplyLoan'
import LoanList from '../CPPartner/CPLoanApplication/LoanList'

export default function CPRoutes() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={routesNames.CPDASHBOARD} component={CPDashboard} />
        <Route exact path={routesNames.CPBORROWERS} component={CPBorrowers} />
        <Route exact path={routesNames.CPAPPLYLOAN} component={OnBoardApplyLoan} />
        <Route exact path={routesNames.CPLOANLIST} component={LoanList} />
        <Route exact path={routesNames.CPINVESTORS} component={CPInvestors} />
        <Route exact path={routesNames.CPPAYOUTS} component={CPPayouts} />
        <Route component={NotFound} />
      </Switch>
    </AppLayout>
  )
}
