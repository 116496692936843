import React from 'react'
import { Box, Heading, Select } from '@chakra-ui/react'
import { Doughnut } from 'react-chartjs-2';

export default function LoanChart(props) {
  return (
    <Box className='chart_box bg_shadow'>
        <Box className='loan_select'>
            <Heading as="h2" className='font-poppins card_heading'>Your Loan </Heading>
            <Select placeholder="Select Period" variant="filled" className="input_box_select font-poppins">
                <option>3 Months</option>
                <option>6 Months</option>
                <option>1 Year</option>
            </Select>
        </Box>
        <Box className='chart_box_inner'>
            <Doughnut data={props.data} options={props.options} width={240} />
            <Box className='chart_label'>
                <Heading as="h5" className='font-poppins'>{props?.chartData?.total_loan}</Heading>
                <Heading as="h6" className='font-poppins'>Total Loan</Heading>
            </Box>
        </Box>
        <Box className='loan_info'>
            <Box className='loan_info_box'>
                <Heading as="h5" className='font-poppins'>Personal Loan</Heading>
                <Heading as="h6" className='font-poppins'>{props?.chartData?.personal_loan}</Heading>
            </Box>
            <Box className='loan_info_box'>
                <Heading as="h5" className='font-poppins'>Insta Loan</Heading>
                <Heading as="h6" className='font-poppins'>{props?.chartData?.insta_loan}</Heading>
            </Box>
            <Box className='loan_info_box'>
                <Heading as="h5" className='font-poppins'>Bullet Loan</Heading>
                <Heading as="h6" className='font-poppins'>{props?.chartData?.bullet_loan}</Heading>
            </Box>
        </Box>
    </Box>
  )
}
