import React from 'react';
import { Box, Heading, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/react';

const InstaLoanEmiCalculator = (props) => {
 return (
    <Box mt={4}>
      <Box mb={6}>
        <Box className='loan_block'>
          <Heading as="h2" className='font-poppins block_heading'>Loan Amount</Heading>
          <Heading as="h5" className='font-poppins'>₹ {props.instaLoanDetails.loanAmount[1].toLocaleString('en-US')}</Heading>
        </Box>
        <Box mt={3}>
          <RangeSlider value={props.instaLoanDetails.loanAmount} min={5000} max={10000} step={1000}
            onChange={(e) => props.handleChangeInstaLoan(e, 'loanAmount')}
          >
            <RangeSliderTrack className='range_slider_bg'>
              <RangeSliderFilledTrack bg='#BA1F33' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
            <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
          </RangeSlider>
        </Box>
      </Box>
      <Box mb={6}>
        <Box className='loan_block'>
          <Heading as="h2" className='font-poppins block_heading'>ROI (Per Annum)</Heading>
          <Heading as="h5" className='font-poppins fixed-return-roi'>{props.instaLoanDetails.roi[1]}%</Heading>
        </Box>
        <Box mt={3}>
          <RangeSlider value={props.instaLoanDetails.roi} min={0} max={25} step={1}
            // onChange={(e) => handleChangeInstaLoan(e, 'roi')} 
          >
            <RangeSliderTrack className='range_slider_bg'>
              <RangeSliderFilledTrack bg='#BA1F33' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
            <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
          </RangeSlider>
        </Box>
      </Box>
      <Box mb={5}>
        <Box className='loan_block'>
          <Heading as="h2" className='font-poppins block_heading'>Loan Tenure</Heading>
          <Heading as="h5" className='font-poppins'>{props.instaLoanDetails.tenure[1]} Days</Heading>
        </Box>
        <Box mt={3}>
          <RangeSlider value={props.instaLoanDetails.tenure} min={7} max={60} step={1}
            onChange={(e) => props.handleChangeInstaLoan(e, 'tenure')}
          >
            <RangeSliderTrack className='range_slider_bg'>
              <RangeSliderFilledTrack bg='#BA1F33' />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
            <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
          </RangeSlider>
        </Box>
      </Box>
      <Box>
        <Box mb={4} className='emi_data'>
          <Heading as="h5" className='font-poppins'>Principal Amount</Heading>
          <Heading as="h4" className='font-poppins'>₹ {props.instaLoanDetails.loanAmount[1].toLocaleString('en-US')}</Heading>
        </Box>
        <Box mb={4} className='emi_data'>
          <Heading as="h5" className='font-poppins'>Total Interest</Heading>
          <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.totalInterest}</Heading>
        </Box>
        <Box className='emi_data'>
          <Heading as="h5" className='font-poppins'>Total Return Amount</Heading>
          <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.totalAmount}</Heading>
        </Box>
      </Box>
    </Box>
 );
};

export default InstaLoanEmiCalculator;
