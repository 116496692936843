import { Box, Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../elements/customButton'
import Lottie from "lottie-react";
import KarzaIcon from '../../../../assets/images/karza.png'
import rejectKYCAnimation from "../../../../assets/lottiFiles/kycReject.json";

export default function RejectKYCDialog(props) {

    const PopupBox = () => (
        <Box className='kyc_complete_popup reject_kyc_popup'>
            <ModalOverlay />
                <ModalContent>
                    <Heading className="kyc_modal_title font-poppins">
                        KYC Verification Failed
                    </Heading>
                    <Heading as="p" className='font-poppins kyc_modal_description'>
                        {props?.isInvestor ?
                            'Your KYC verification has failed. To start investment, please complete the KYC process again by uploading your documents.' :
                            'Your KYC verification has failed. To apply for a loan, please complete the KYC process again by uploading your documents.'
                        }
                    </Heading>
                    <ModalBody className='reject_kyc_body'>
                        <Box className='reject_kyc_reason'>
                            <Heading as="h1" mt={3} mb={3} className="font-poppins">Why your KYC Verification Is failed</Heading>
                            <Box>
                                {props?.rejectData?.v2_pan_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Pan Card: {props?.rejectData?.v2_pan_reject_reason}</Text>}
                                {props?.rejectData?.v2_aadhar_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Aadhar Card: {props?.rejectData?.v2_aadhar_reject_reason}</Text>}
                                {props?.employmentType === '4' || props?.employmentType === '5' ?
                                    <>
                                        {props?.rejectData?.gst_registration_certificate_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Gst Registration Certificate: {props?.rejectData?.gst_registration_certificate_reject_reason}</Text>}
                                        {props?.employmentType === '4' ?
                                            <>
                                                {props?.rejectData?.rbi_licence_copy_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />RBI Licence copy: {props?.rejectData?.rbi_licence_copy_reject_reason}</Text>}
                                            </> :
                                            <>
                                                {props?.rejectData?.additional_address_proof && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Address Proof: {props?.rejectData?.additional_address_proof}</Text>}
                                            </>
                                        }
                                        {props?.rejectData?.board_resolution_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Board resolution: {props?.rejectData?.board_resolution_reject_reason}</Text>}
                                        {props?.rejectData?.cob_certificate_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Certificate of commencement of the business: {props?.rejectData?.cob_certificate_reject_reason}</Text>}
                                        {props?.rejectData?.mem_art_association_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Memorandum and Articles of Association: {props?.rejectData?.mem_art_association_reject_reason}</Text>}
                                        {props?.rejectData?.v2_bank_statement_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Bank Statement: {props?.rejectData?.v2_bank_statement_reject_reason}</Text>}
                                        {props?.rejectData?.incorp_certificate_reject_reason && <Text className='font-poppins reject_reason_text'><span className='reason_marker' />Certification of Incorporation: {props?.rejectData?.incorp_certificate_reject_reason}</Text>}
                                    </> : null
                                }
                            </Box>
                        </Box>
                        <Box className='kyc_image'>
                            <Lottie animationData={rejectKYCAnimation} loop={true} />
                        </Box>
                        <Box className='kyc_modal_actions'>
                            <Heading mb={3} className="fee_text_kyc font-poppins">You do not need to pay the fee again.</Heading>
                            <CustomButton size="md" type="submit" title="DO IT LATER" className="btn_theme_transparent" onClick={props.handleCloseRejectKYCModal} />
                            <CustomButton size="md" type="submit" title="START NOW" className="btn_theme" onClick={props.handleStart} />
                        </Box>
                        {!props.isKYCCompleted &&
                            <Box mt={8} className='d-flex col-gap-10 align-items-center justify-content-center'>
                                <Heading className="kyc_modal_description font-poppins">Our KYC Partner</Heading>
                                <Image src={KarzaIcon} className='modal_footer_icon' />
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseRejectKYCModal}
        isOpen={props.isKYCReject}
        motionPreset="slideInBottom"
        size={'xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
