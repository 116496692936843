import React from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Flex, Heading, IconButton, Input, Modal, ModalBody, ModalContent, ModalOverlay, Select, Spacer, Text } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment'

export default function DownloadPassBook(props) {

    const PopupBox = () => (
        <Box className='wallet_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-start col-gap-15 preference_header'>
                    <IconButton onClick={props.handlePassbookDialog}><ArrowBackIcon /></IconButton>
                    <Heading as="h3" className="font-poppins">Download Passbook</Heading>
                </Box>
                <ModalBody className='wallet_body'>
                    <Heading as="h5" className='font-poppins'>Select the time period</Heading>
                    <Box className='add_funds_box' mt={1}>
                        <Box className='passbook_inputs' mb={4}>
                            <Select placeholder="Select Period" variant="filled" className="select_period font-poppins"
                                onChange={props.handleChange} name="time_period">
                                {props?.timePeriod?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.name}>{item?.name}</option>
                                    )
                                })}
                            </Select>
                        </Box>
                        {props?.inputValues?.time_period === 'Custom' &&
                            <Flex alignItems='center' justifyContent='space-between' gap={1} className='custom_select' mb={8}>
                                <Box className='passbook_inputs'>
                                    <Text className="font-poppins">From Date</Text>
                                    <Input size="md" name="from_date" type="date" placeholder="DD/MM/YYYY" className="calender_input_box font-poppins"
                                        onChange={props.handleChange} value={props.inputValues?.from_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                                    />
                                </Box>
                                <Spacer />
                                <Box className='passbook_inputs'>
                                    <Text className="font-poppins">To Date</Text>
                                    <Input size="md" name="to_date"  type="date" placeholder="DD/MM/YYYY" className="calender_input_box font-poppins"
                                        onChange={props.handleChange} value={props.inputValues?.to_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                                    />
                                </Box>
                            </Flex>
                        }
                        <CustomButton size="md" type="submit" title="Download" className="font-poppins btn_theme w-100" onClick={props.handleDownloadPassbook} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handlePassbookDialog}
        isOpen={props.openPassbookModal}
        motionPreset="slideInBottom"
        size={'md'}
    >
    {PopupBox()}
    </Modal>
  )
}
