import React, {useEffect, useRef, useState} from 'react'
import {Box, Heading, Grid, GridItem, Tab, TabList, TabPanel, TabPanels, Tabs, Input, Image, IconButton} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton';
import { Document, Page } from 'react-pdf';
import { ViewIcon } from '@chakra-ui/icons';
import OpenFIleDialog from '../../../../elements/OpenFIleDialog';
import CustomToast from '../../../../elements/customToast';
import { getItem, setItem } from '../../../../../utilities/authUtils';
import { detectFileType } from '../../../../../utilities/DownloadDocuments';
import DownloadIcon from '../../../../../assets/images/download.png'
import { analayzeBankStatement } from '../../../../../services/adminServices';
import Loader from '../../../../elements/loader';

export default function FinancialDetails(props) {

    const MONTHLYINCOME = getItem('monthlyIncome')
    const bankRef = useRef()
    const {addToast} = CustomToast()
    const data = props?.userData?.data
    const [inputValues, setInputValues] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [currentFileType, setCurrentFileType] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setInputValues({...inputValues, income: MONTHLYINCOME})
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    const fileType = {
        salarySlip: detectFileType(data?.document_data?.salary_slip_1),
        bankStatement: detectFileType(data?.document_data?.bank_statement_1),
        itr: detectFileType(data?.document_data?.itr_form16),
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

    const showOpenFileDialog = () => {
        bankRef.current.click()
    }
    
    const onChangeFile = (e) => {
        if (e.target.files[0].type !== 'application/pdf') {
            addToast({message: 'Bank Statement must be a file of type pdf. ', status: 'error'});
            return
        }
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        setIsLoading(true)
        analayzeBankStatement(formData).then((res) => {
            setIsLoading(false)
            if (res?.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'bank_statement.xlsx')
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                addToast({message: 'File Downloaded Successfully', status: 'success'})
            } else {
                addToast({message: 'Something Went Wrong', status: 'error'})
            }
        }).catch((error) => {
            addToast({message: error, status: 'error'})
            setIsLoading(false)
        })
    }

    const handleNext = () => {
        if (!inputValues?.income) {
            addToast({message: 'Please Enter Monthly Income', status: 'error'});
            return
        }
        setItem('monthlyIncome', inputValues?.income)
        props.onClick()
    }

  return (
    <>
        {isLoading &&
            <div>
                <Loader />
            </div>
        }
        <Box mt={5}>
            <Grid className="main_grid_container justify-content-between">
                <GridItem colSpan={7}>
                    <Box className='summary_heading'>
                        <Heading as="h6" className='font-poppins'>Summary</Heading>
                    </Box>
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-55'>Net Monthly Income</Heading>
                        <Input
                            type="number"
                            size="md"
                            id="income"
                            name="income"
                            placeholder="Please Enter Borrowers Monthly Income"
                            onChange={handleChange}
                            className="input_box font-poppins"
                            value={inputValues?.income ?? ''}
                        />
                    </Box>
                    <Box mt={6}>
                        <Box className='summary_heading'>
                            <Heading as="h6" className='font-poppins'>Bank Statement Analayzer</Heading>
                            <Box className='upload_container upload_analyzer' mt={2}>
                                <CustomButton
                                    className="btn_theme font-poppins"
                                    title='Browse'
                                    onClick={() => showOpenFileDialog()}
                                />
                                <Input
                                    size="md"
                                    name="upload"
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) => onChangeFile(e)}
                                    className="d-none"
                                    ref={bankRef}
                                />
                                <Heading mt={4} as="p" className="font-poppins note">
                                    Supported File .png, .jpg Upto 5 MB
                                </Heading>
                            </Box>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem colSpan={5}>
                    <Tabs className='tabs_container details_tabs'>
                        <TabList className='tabs_list justify-content-center'>
                            <Tab>Salary Slip</Tab>
                            <Tab>Bank Statement</Tab>
                            <Tab>ITR</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel className='tab_panel'>
                                {data?.document_data?.salary_slip_1 ?
                                    <Box className='pdf_read'>
                                        {fileType.salarySlip === 'pdf' ?
                                            <Document file={data?.document_data?.salary_slip_1}>
                                                <Page pageNumber={1} />
                                            </Document>
                                            : 
                                            <Image src={data?.document_data?.salary_slip_1} />
                                        }
                                        {data?.document_data?.salary_slip_1 ?
                                        <Box>
                                            <IconButton onClick={() => handleModal(data?.document_data?.salary_slip_1, fileType.salarySlip)} className="icon_button_view" 
                                                icon={<ViewIcon />}
                                            />
                                            <a target='_blank' href={data?.document_data?.salary_slip_1}>
                                                <IconButton className="icon_button_download" icon={<Image src={DownloadIcon} className="download-link" />} />
                                            </a>
                                        </Box>    
                                        : null }
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                            <TabPanel className='tab_panel'>
                                {data?.document_data?.bank_statement_1 ?
                                    <Box className='pdf_read'>
                                        {fileType.bankStatement === 'pdf' ?
                                            <Document file={data?.document_data?.bank_statement_1}>
                                                <Page pageNumber={1} />
                                            </Document>
                                            : 
                                            <Image src={data?.document_data?.bank_statement_1} />
                                        }
                                        {data?.document_data?.bank_statement_1 ?
                                        <Box>
                                            <IconButton onClick={() => handleModal(data?.document_data?.bank_statement_1, fileType.bankStatement)} className="icon_button_view" 
                                                icon={<ViewIcon />}
                                            />
                                            <a target='_blank' href={data?.document_data?.bank_statement_1}>
                                                <IconButton className="icon_button_download" icon={<Image src={DownloadIcon} className="download-link" />} />
                                            </a>
                                        </Box> : null }
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                            <TabPanel className='tab_panel'>
                                {data?.document_data?.itr_form16 ?
                                    <Box className='pdf_read'>
                                        {fileType.itr === 'pdf' ?
                                            <Document file={data?.document_data?.itr_form16}>
                                                <Page pageNumber={1} />
                                            </Document>
                                            : 
                                            <Image src={data?.document_data?.itr_form16} />
                                        }
                                        {data?.document_data?.itr_form16 ?
                                        <Box>
                                            <IconButton onClick={() => handleModal(data?.document_data?.itr_form16, fileType.itr)} className="icon_button_view" 
                                                icon={<ViewIcon />}
                                            />
                                            <a target='_blank' href={data?.document_data?.itr_form16}>
                                                <IconButton className="icon_button_download" icon={<Image src={DownloadIcon} className="download-link" />} />
                                            </a>
                                        </Box> : null }
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
            <Box className="kyc_actions btn_gap " mb={4} mt={4}>
                <CustomButton size="md" type="submit" title="Next" className="btn_theme font-poppins" onClick={handleNext} />
            </Box>
            <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
        </Box>
    </>
  )
}
