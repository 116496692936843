import React from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text, Progress, Flex, Spacer, Link, Tooltip, Input, Checkbox, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import UserIcon from "../../../../../assets/images/userIcon.svg";
import moment from 'moment'

export default function InvestNowSidebar(props) {

    const data = props?.sidebarData
    console.log(data, 'data')

  const PopupBox = () => (
    <Box>
        {}
      <DrawerOverlay />
      <DrawerContent className='invest_sidebar_content'>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header' pb={0} pt={1}>
            <Box className='profile_info'>
                <Image src={data?.loan_data?.user_data?.photo ? data?.loan_data?.user_data?.photo : UserIcon} className="profile_image" />
                <Box className='user_profile'>
                    <Heading as="h3" className='font-poppins'>{data?.borrowerDetailsData?.full_name}</Heading>
                    <Heading as="h5" className='font-poppins'>{data?.loan_data?.loan_number}</Heading>
                </Box>
            </Box>
        </DrawerHeader>
        <DrawerBody>
            <Box className='lending_amount lending_card_bg lending_card_radius'>
                <Box className='preference_header center_text' mb={2}>
                    <Heading as="h3" className="font-poppins">Loan Amount</Heading>
                </Box>
                <Heading as="h1" className="font-poppins">₹ {data?.loan_data?.loan_amount}</Heading>
                <Progress className='custom_progress' bg='#00000014' size='sm' value={data?.loan_data?.committed_loan_amount ? parseFloat(data?.loan_data?.committed_loan_amount) : 0} max={data?.loan_data?.loan_amount} />
                <Flex alignItems='center' mt={1}>
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.committed_loan_amount ? data?.loan_data?.committed_loan_amount : 0}</Heading>
                        <Heading as="p" className="font-poppins">Already funded</Heading>
                    </Box>
                    <Spacer />
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.remaining_loan_amount}</Heading>
                        <Heading as="p" className="font-poppins">Remaining</Heading>
                    </Box>
                </Flex>
            </Box>
            <Box className='accordion_box lending_card_bg lending_card_radius' mt={1}>
                <Accordion allowToggle>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Loan Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                <Text className='font-poppins loan_type'>
                                    {data?.loan_data?.loan_name}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                <Text className='font-poppins'>
                                    {`${data?.loan_data?.interest_rate}%`}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.tenure_duration}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                <Text className='font-poppins'>₹ {data?.loan_data?.loan_amount}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Expiry Date</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.due_date ? moment(data?.loan_data?.due_date).format("DD-MMM-YY") : "N/A"}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Application Date</Heading>
                                <Text className='font-poppins'>{moment(data?.created_at).format('DD-MM-YYYY')}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Borrower Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Full Name</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.full_name}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>City Name</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.city}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>CKYC Number</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.ckyc_detail?.ckyc_number}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.loan_purpose}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>House Ownership</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.personal_data?.display_premise_status}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Age</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.age}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Employment Type</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.employment_type}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Risk category & ALPHA Score </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Equifax Score</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.equifax_score}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.alpha_rating}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
            <Box className='loan_pledge_amount' mt={3}>
                <Heading as="h6" className='font-poppins'>You have Pledged <span> ₹ {data?.pledged_amount} </span> in this Loan</Heading>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={2} mt={0}>
            <Box className="pledged_amount_status d-flex align-items-center col-gap-10">
                <Heading as="h5" className="font-poppins">Status</Heading>
                <Heading as="h6" className={data?.loan_data?.loan_status === '2' ? "font-poppins in_process" : ((data?.loan_data.loan_status === '3' && !data?.loan_data.esign_request) || (data?.loan_data.loan_status === '3' && data?.loan_data.esign_request)) ? "font-poppins in_waiting" : "font-poppins in_transfer"}>
                    {data?.loan_data?.loan_status === '2' ? 'Funding in Process' : (data?.loan_data.loan_status === '3' && !data?.loan_data.esign_request) ? 'Waiting for Borrower’s  E-Sign' : (data?.loan_data.loan_status === '3' && data?.loan_data.esign_request) ? 'Waiting for Your E-Sign' : data?.loan_data.loan_status === '4' ? 'Funds Transfer In Process' : ''}
                </Heading>
            </Box>
            <Box className='full-width' mt={2}>
                {data?.loan_data?.loan_status === '4' ?
                    <CustomButton size="md" type="submit" title="Download Agreement" className="btn_theme font-poppins w-100" onClick={() => props.handleDownloadAgreement(data?.loan_data?.esign_request?.eSign_doc_file_url)} />:
                    <CustomButton size="md" type="submit" title="E-Sign" className="btn_green font-poppins w-100" isDisabled={!data?.loan_data?.esign_request} onClick={() => props.handleESign(data)} />
                }
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
        isOpen={props.openSidebar}
        placement="right"
        onClose={props.handleCloseSidebar}
        size={'md'}
    >
    {PopupBox()}
  </Drawer>
  )
}

