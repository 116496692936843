import React, { useEffect, useState } from 'react';
import { Box, Stack, Heading, Image, Flex, Spacer } from '@chakra-ui/react';
import alphaLogo from "../../../assets/images/Asset_logo_3.svg";
import CustomButton from '../../elements/customButton';
import PersonalDetails from './PersonalDetails';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {Link, useHistory, useLocation} from 'react-router-dom'
import BankDetails from './BankDetails';
import UploadDocuments from './UploadDocument';
import FeePayment from './FeePayment';
import * as routesNames from '../../../constants/routes'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../redux/actions';
import Loader from '../../elements/loader';

const UpdateProfile = (props) => {

  const history = useHistory()
  const location = useLocation()
  const masterState = useSelector((state) => state.masterState)
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [userData, setUserData] = useState(null);
  const [activePersonalDetails, setActivePersonalDetails] = useState(false);
  const [activeBankDetails, setActiveBankDetails] = useState(false);
  const [activeDocuments, setActiveDocuments] = useState(false);
  const [activeFeeDetails, setActiveFeeDetails] = useState(false);
  const isReject = userData?.document_data?.doc_approval_status === 1
  
  useEffect(() => {
    props?.actions?.getCurrentUserDetailsAction()
  }, [])

  useEffect(() => {
    if (masterState?.getCurrentUserDetails?.data?.success === true){
      setUserData(masterState?.getCurrentUserDetails?.data?.data?.[0])
      // setActivePersonalDetails(masterState?.getCurrentUserDetails?.data?.data?.[0]?.personal_data?.status==1 ? true : false)
      setActivePersonalDetails(masterState?.getCurrentUserDetails?.data?.data?.[0]?.personal_data?.status === 1 ? true : false)
      setActiveBankDetails(masterState?.getCurrentUserDetails?.data?.data?.[0]?.bank_data ? true : false)
      setActiveDocuments(masterState?.getCurrentUserDetails?.data?.data?.[0]?.document_data ? true : false)
      setActiveFeeDetails(masterState?.getCurrentUserDetails?.data?.data?.[0]?.fee_data?.transaction_status === '2'? true : false)
    }
  }, [masterState?.getCurrentUserDetails])

  const handleClick = () => {
    if(location.pathname === "/borrower-complete-kyc"){
      history.push(routesNames.BORROWERDASHBOARD);
    }if(location.pathname === "/investor-kyc"){
      history.push(routesNames.INVESTERDASHBOARD);
    } 
  }
  
  const nextStep = () => {
    setStep(step + 1);
  };

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const markStepAsCompleted = (stepNumber) => {
    if (!completedSteps.includes(stepNumber)) {
      setCompletedSteps([...completedSteps, stepNumber]);
    }
  };

  const handleSubmit = () => {
    console.log('Form submitted!');
  }

  return (
    <Box>
      {(masterState?.getCurrentUserDetails?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Flex className='stepper_header'>
        <Link>
            <Image className="" src={alphaLogo} />
        </Link>
        <Spacer />
        <CustomButton size="md" type="submit" title="Back to Dasboard" className="font-poppins btn_theme" onClick={()=>handleClick()} />
      </Flex>
      <Box className='steps_box'>
        <Stack direction="row" className='steps_align'>
          <StepLabel active={step === 1} completed={completedSteps.includes(1) || activePersonalDetails} onClick={() => goToStep(1)}>
            {completedSteps.includes(1) || activePersonalDetails ? <CheckCircleIcon className='icon_size' /> : <span>01</span>} Personal Details
          </StepLabel>
          <StepLabel active={step === 2} completed={completedSteps.includes(2) || activeBankDetails} onClick={() => goToStep(2)}>
            {completedSteps.includes(2) || activeBankDetails ? <CheckCircleIcon className='icon_size' /> : <span>02</span>} Bank Details
          </StepLabel>
          <StepLabel active={step === 3} completed={completedSteps.includes(3) || activeDocuments} onClick={() => goToStep(3)}>
            {completedSteps.includes(3) || activeDocuments ? <CheckCircleIcon className='icon_size' /> : <span>03</span>} Documents
          </StepLabel>
          <StepLabel active={step === 4} completed={completedSteps.includes(4) || activeFeeDetails} onClick={() => goToStep(4)}>
            {completedSteps.includes(4) || activeFeeDetails ? <CheckCircleIcon className='icon_size' /> : <span>04</span>} Fee Payment
          </StepLabel>
        </Stack>
        {(userData?.form_completed_status === 0 || isReject) ?
          <>
            {step === 1 && (
              <Box>
                <PersonalDetails onClick={() => { markStepAsCompleted(1); nextStep();}} userData={userData} />
              </Box>
            )}
            {step === 2 && (
              <Box>
                <BankDetails onClick={() => { markStepAsCompleted(2); nextStep(); }} userData={userData} activeBankDetails={activeBankDetails} />
              </Box>
            )}
            {step === 3 && (
              <Box>
                <UploadDocuments onClick={() => { markStepAsCompleted(3); nextStep(); }} userData={userData} />
              </Box>
            )}
            {step === 4 && (
              <Box>
                <FeePayment onClick={() => { markStepAsCompleted(4); handleSubmit(); }} feeData={userData} />
              </Box>
            )}
          </> :
          <Box className='thanks_container'>
            <Heading as="h1" className="font-poppins">Thank You!</Heading>
            <Heading as="p" className="font-poppins">Our team is verifying your documents. The process can take upto 48 hours. You will be notified over email once the process is complete.</Heading>
            <CustomButton size="md" type="submit" onClick={() => handleClick()} title="Back to Dasboard" className="font-poppins btn_theme" />
          </Box>
        }
      </Box>
    </Box>
  );
};

const StepLabel = ({ children, active, completed, onClick }) => {
  return (
    <Box className='step_label'>
      <Heading as="h3" className={completed ? 'tab_heading font-poppins completed' : 
      (active ? 'tab_heading font-poppins visited' : 'tab_heading font-poppins default')} onClick={onClick}>
        {children}
      </Heading>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(UpdateProfile)
