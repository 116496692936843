import React, { useEffect, useState } from 'react'
import { Box, Image, Stack } from '@chakra-ui/react'
import Logo from "../../assets/images/app_logo.svg";
import DashboardIcon from '../../assets/images/dashboardBorrower.svg';
import DashboardWhiteIcon from '../../assets/images/dashboardBorrowerWhite.svg';
import LoanIcon from '../../assets/images/loanAppBorrower.svg';
import LoanWhiteIcon from '../../assets/images/loanAppBorrowerWhite.svg';
import PledgeIcon from '../../assets/images/pledge.svg';
import PledgeWhiteIcon from '../../assets/images/pledgeWhite.svg';
import ActiveLoanIcon from '../../assets/images/activeLoanBorrower.svg';
import ActiveLoanWhiteIcon from '../../assets/images/activeLoanBorrowerWhite.svg';
import ClosedLoanIcon from '../../assets/images/closedLoansBorrower.svg';
import ClosedLoanWhiteIcon from '../../assets/images/closedLoansBorrowerWhite.svg';
import WalletIcon from '../../assets/images/wallet.svg';
import WalletWhiteIcon from '../../assets/images/walletWhite.svg';
import * as routesNames from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom'

const sideBarOptions = [
  {
    id: 1,
    menuName: 'Dashboard',
    link: routesNames.INVESTERDASHBOARD,
    menuIcon: DashboardIcon,
    menuIconActive: DashboardWhiteIcon,
  },
  {
    id: 2,
    menuName: 'Manual Investment',
    link: routesNames.MANUALINVESTMENT,
    menuIcon: LoanIcon,
    menuIconActive: LoanWhiteIcon,
  },
  {
    id: 3,
    menuName: 'Pledged Investment',
    link: routesNames.PLEDGEDINVESTMENT,
    menuIcon: PledgeIcon,
    menuIconActive: PledgeWhiteIcon,
  },
  {
    id: 4,
    menuName: 'Active Investments',
    link: routesNames.ACTIVEINVESTMENT,
    menuIcon: ActiveLoanIcon,
    menuIconActive: ActiveLoanWhiteIcon,
  },
  {
    id: 5,
    menuName: 'Closed Investments',
    link: routesNames.CLOSEDINVESTMENT,
    menuIcon: ClosedLoanIcon,
    menuIconActive: ClosedLoanWhiteIcon,
  },
  {
    id: 6,
    menuName: 'Wallet',
    link: routesNames.WALLET,
    menuIcon: WalletIcon,
    menuIconActive: WalletWhiteIcon,
  }
]


function SidebarInvester() {

  const location = useLocation();
  const [menuId, setMenuId] = useState(null)

  
  useEffect(() => {
    const menuItem = sideBarOptions.find((item) => item.link === location.pathname);
    if (menuItem) {
      setMenuId(menuItem.id);
    }
  }, [location.pathname]);

  const handleMenu = (item) => {
    setMenuId(item)
  }

  return (
    <div>
      <Box className='sidebar_wrapper' >
        <Link to={routesNames.INVESTERDASHBOARD}>
          <Image src={Logo} alt='logo' className='app_logo' />
        </Link>
        <Stack className="menu_box">
          {sideBarOptions?.map((item) => {
            return (
              <Link key={item.id} to={item.link} onClick={() => handleMenu(item.id)}
                className={item.id === menuId ? 'active_menu_item menu_item font-poppins' : 'menu_item font-poppins'} >
                <Image src={item.id === menuId ? item.menuIconActive : item.menuIcon} className="icon_size" />
                {item.menuName}
              </Link>
            )
          })}
        </Stack>
      </Box>

    </div>
  )
}

export default SidebarInvester