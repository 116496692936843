export const GET_REGISTRATION_REPORT_REQUEST = 'GET_REGISTRATION_REPORT_REQUEST'
export const GET_REGISTRATION_REPORT_FAILURE = 'GET_REGISTRATION_REPORT_FAILURE'
export const GET_REGISTRATION_REPORT_SUCCESS = 'GET_REGISTRATION_REPORT_SUCCESS'

export const GET_DCR_REPORT_REQUEST = 'GET_DCR_REPORT_REQUEST'
export const GET_DCR_REPORT_FAILURE = 'GET_DCR_REPORT_FAILURE'
export const GET_DCR_REPORT_SUCCESS = 'GET_DCR_REPORT_SUCCESS'

export const ACTIVE_ONE_SHOT_PAYMENT_REQUEST = 'ACTIVE_ONE_SHOT_PAYMENT_REQUEST'
export const ACTIVE_ONE_SHOT_PAYMENT_FAILURE = 'ACTIVE_ONE_SHOT_PAYMENT_FAILURE'
export const ACTIVE_ONE_SHOT_PAYMENT_SUCCESS = 'ACTIVE_ONE_SHOT_PAYMENT_SUCCESS'

export const CLOSED_ONE_SHOT_PAYMENT_REQUEST = 'CLOSED_ONE_SHOT_PAYMENT_REQUEST'
export const CLOSED_ONE_SHOT_PAYMENT_FAILURE = 'CLOSED_ONE_SHOT_PAYMENT_FAILURE'
export const CLOSED_ONE_SHOT_PAYMENT_SUCCESS = 'CLOSED_ONE_SHOT_PAYMENT_SUCCESS'

export const ACTIVE_MULTIPLE_EMI_PAYMENT_REQUEST = 'ACTIVE_MULTIPLE_EMI_PAYMENT_REQUEST'
export const ACTIVE_MULTIPLE_EMI_PAYMENT_FAILURE = 'ACTIVE_MULTIPLE_EMI_PAYMENT_FAILURE'
export const ACTIVE_MULTIPLE_EMI_PAYMENT_SUCCESS = 'ACTIVE_MULTIPLE_EMI_PAYMENT_SUCCESS'

export const CLOSED_MULTIPLE_EMI_PAYMENT_REQUEST = 'CLOSED_MULTIPLE_EMI_PAYMENT_REQUEST'
export const CLOSED_MULTIPLE_EMI_PAYMENT_FAILURE = 'CLOSED_MULTIPLE_EMI_PAYMENT_FAILURE'
export const CLOSED_MULTIPLE_EMI_PAYMENT_SUCCESS = 'CLOSED_MULTIPLE_EMI_PAYMENT_SUCCESS'

export const GET_CKYC_REPORT_REQUEST = 'GET_CKYC_REPORT_REQUEST'
export const GET_CKYC_REPORT_FAILURE = 'GET_CKYC_REPORT_FAILURE'
export const GET_CKYC_REPORT_SUCCESS = 'GET_CKYC_REPORT_SUCCESS'