import React, { useEffect, useState } from "react";
import {Box, Image, Heading, Flex, Input, Checkbox} from '@chakra-ui/react'
import {ActionCreators} from '../../../redux/actions/index.js'
import {Link, useHistory} from 'react-router-dom'
import { connect, useSelector } from "react-redux";
import RBIlogo from "../../../assets/images/rbi_logo.svg";
import RBIlogoBlack from "../../../assets/images/rbi_black.svg";
import alphaLogo2 from "../../../assets/images/Asset_logo_3.svg";
import alphaLogo from "../../../assets/images/Asset_logo_2.svg";
import CustomButton from "../../elements/customButton.js";
import decode from 'jwt-decode';
import { bindActionCreators } from "redux";
import CustomToast from "../../elements/customToast.js";
import { setItem } from "../../../utilities/authUtils.js";
import PrivacyPolicies from "../../elements/PrivacyPoliciesDialog.js";
import TermsConditionsCP from "../../elements/TermsConditionsCPDialog.js";
import PrivacyPoliciesDialog from "../../elements/PrivacyPoliciesDialog.js";
import TermsConditionsCPDialog from "../../elements/TermsConditionsCPDialog.js";

  const CPLogin = (props) => {

    const history = useHistory()
    const {addToast} = CustomToast()
    const authState = useSelector((state) => state.authState)
    const [inputValues, setInputValues] = useState({})
    const [otpStatus, setOtpStatus] = useState(null)
    const [timerCount, setTimerCount] = useState(0)
    const [viaEmail, setViaEmail] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [openTerms, setOpenTerms] = useState(false)
    const [openPolicy, setOpenPolicy] = useState(false)

    useEffect(() => {
      if (authState?.getCPOTP?.data?.success === true){
        setOtpStatus(authState?.getCPOTP?.data?.status)
        addToast({message: authState?.getCPOTP?.data?.message, status: 'success'})
        setTimerCount(120);
      } else if (authState?.getCPOTP?.data?.success === false){
        addToast({message: authState?.getCPOTP?.data?.message, status: 'error'})
      }
    }, [authState?.getCPOTP])

    useEffect(() => {
      if (authState?.verifyCPOTP?.data?.success === true){
          addToast({message: authState?.verifyCPOTP?.data?.message, status: 'success'})
          setItem("id_token", authState?.verifyCPOTP?.data?.data?.token);
          setItem("header_token", authState?.verifyCPOTP?.data?.data?.header_token);
          const userData = authState?.verifyCPOTP?.data?.data?.token;
          let decodeData = decode(userData)
          console.log(decodeData, 'decodeData')
          if (decodeData.role == 'channel_partner') {
            history.push("/channel-partner/dashboard");
        }
      } else if (authState?.verifyCPOTP?.data?.success === false){
        addToast({message: authState?.verifyCPOTP?.data?.message, status: 'error'})
      }
    }, [authState?.verifyCPOTP])

    useEffect(() => {
      let timer;
      if (timerCount > 0) {
        timer = setTimeout(() => setTimerCount(timerCount - 1), 1000);
      }
      return () => clearTimeout(timer);
    }, [timerCount]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'mobile_number') {
        const truncatedValue = value.slice(0, 10);
        setInputValues({ ...inputValues, [name]: truncatedValue });
      } else if (name === 'otp') {
        const truncatedOTP = value.slice(0, 6);
        setInputValues({ ...inputValues, [name]: truncatedOTP });
      } else {
        setInputValues({ ...inputValues, [name]: value });
      }
    }

    const handleLoginVia = () => {
      setViaEmail(!viaEmail)
    }

    const handleCheck = (e) => {
      setIsChecked(e.target.checked)
    }

    const handleClickTerms = () => {
      setOpenTerms(!openTerms)
    }
  
    const handleClickPolicy = () => {
      setOpenPolicy(!openPolicy)
    }

    const handleOTP = () => {
      if (!viaEmail) {
        if (inputValues?.mobile_number?.length !== 10) {
          addToast({message: 'Mobile Number is Required', status: 'error'})
          return
        }
      }
      if (viaEmail) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues?.email || !emailPattern.test(inputValues.email)) {
          addToast({message: 'Please Enter a Valid Email', status: 'error'})
          return
        }
      }
      
      if (inputValues?.mobile_number?.length === 10 || inputValues?.email) {
        props.actions.getCPOTPAction({username: inputValues.mobile_number ?? inputValues.email})
      }
    }

    const handleResendOTP = () => {
      props.actions.getCPOTPAction({username: inputValues.mobile_number ?? inputValues.email})
      setTimerCount(120);
    }

    const handleLogin = () => {
      if (!viaEmail) {
        if (inputValues?.mobile_number?.length !== 10) {
          addToast({message: 'Please Enter Mobile Number', status: 'error'})
          return
        }
      } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues?.email || !emailPattern.test(inputValues.email)) {
          addToast({message: 'Please Enter a Valid Email', status: 'error'})
          return
        }
      }
      if (inputValues?.otp?.length !== 6) {
        addToast({message: 'Please Enter OTP', status: 'error'})
        return
      }
      if (!isChecked) {
        addToast({message: 'Please Accept Terms & Conditions', status: 'error'})
        return
      }
      let verifiedData = {
        otp:  inputValues.otp,
        username: inputValues.mobile_number ?? inputValues.email,
      }
      props.actions.verifyCPOTPAction(verifiedData)
    }

    return (
      <Box className="cp_parent_container">
        <Box className="updateprofileContainerLogin main_container">       
          <Box className="auth_left_panel hide-xs">
            <Link to="/login">
                <Image className="" src={alphaLogo} />
            </Link>
            <Box className="about_alpha">
              <Heading as="h4">Welcome to <span style={{color:'#E61E25'}}>ALPHA</span>MONEY!</Heading>
              <Heading as="p">
                Our Peer To Peer lending platform connects borrowers with investors for quick personal loans and great returns. Join us now for financial freedom.
              </Heading>
            </Box>
            <Box className="rbi">
              <Image className="" src={RBIlogo} />
            </Box>
          </Box> 
          <Box className="auth_right_panel">
            <Box className="form_container">
              <Box className="form_heading">
                <Box className="mobile_logo hide-md visible-xs">
                  <Link to="/login">
                    <Image alt="logo" src={alphaLogo2} />
                  </Link>
                </Box>
                <Heading as="h1">Welcome Channel <span>Partner!</span></Heading>
              </Box>
              <Box className="login-left-container">
                <Box className="formWraper">
                  <Box mb={5}>
                    {!viaEmail ?
                      <Input
                        type="number"
                        size="md"
                        id="mobile_number"
                        name="mobile_number"
                        placeholder="Mobile Number"
                        onChange={handleChange}
                        className="input_box font-poppins"
                        value={inputValues?.mobile_number ?? ''}
                      /> :
                      <Input
                        type="email"
                        size="md"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        className="input_box font-poppins"
                        value={inputValues?.email ?? ''}
                      />
                    }
                  </Box>
                  <Box mb={5}>
                    <Input
                      size="md"
                      id="otp"
                      name="otp"
                      type="number"
                      disabled={!otpStatus}
                      placeholder={!viaEmail ? "Mobile OTP" : 'Email OTP'}
                      onChange={handleChange}
                      className="input_box font-poppins"
                      value={inputValues?.otp ?? ''}
                  />
                  </Box>
                  <Box mb={5} className="right_align">
                    {otpStatus ? 
                      <Flex className="right_align resend">
                        <CustomButton size="md" type="submit" title="Resend OTP" className="font-poppins btn_transparent" onClick={handleResendOTP}
                        isDisabled={timerCount === 0 ? false : true} />
                        {timerCount === 0 ? null : <Heading as="p"> {timerCount} Sec </Heading>}
                     </Flex>
                     :
                    <CustomButton size="md" type="submit" title="Get OTP" className="font-poppins btn_transparent" onClick={() => handleOTP()} />
                  }
                  </Box>
                  <Flex mb={5} className="column_gap">
                    <Checkbox onChange={(e) => handleCheck(e)} isChecked={isChecked} />
                    <Heading as="p" className="note font-poppins">I agree to <span onClick={handleClickTerms}>Terms and Conditions</span> and <span onClick={handleClickPolicy}>Privacy Policy</span></Heading>
                  </Flex>
                  <Box className="submit_actions" mb={5}>
                    <CustomButton size="md" type="submit" title="Submit" onClick={() => handleLogin()} className="btn_theme font-poppins" />
                  </Box>
                  <Box className="submit_actions" mb={5}>
                    <CustomButton size="md" type="submit" title={!viaEmail ? "Login Via Email" : 'Login Via Mobile Number'} onClick={() => handleLoginVia()} className="btn_theme_transparent font-poppins" />
                  </Box>
                </Box>
              </Box>
              <Box className="mobile_rbi_logo hide-md visible-xs">
                <Image alt="rbi" src={RBIlogoBlack} />
              </Box>
            </Box>
          </Box>
        </Box>
        <TermsConditionsCPDialog handleClickTerms={handleClickTerms} openTerms={openTerms} />
        <PrivacyPoliciesDialog handleClickPolicy={handleClickPolicy} openPolicy={openPolicy} />
      </Box>
    );
  }
   
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(CPLogin)