import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,} from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import CustomToast from '../../elements/customToast'
import { connect, useSelector } from 'react-redux'
import Loader from '../../elements/loader'
import { bindActionCreators } from 'redux'
import decode from 'jwt-decode';
import { ActionCreators } from '../../../redux/actions'
import { PINE_PG } from '../../../constants/Constant'
import { getItem } from '../../../utilities/authUtils'

const FeePayment = (props) => {

  const {addToast} = CustomToast()
  const kycState = useSelector((state) => state.kycState)
  const masterState = useSelector((state) => state.masterState)
  const [paymentMethod, setPaymentMethod] = useState(null)

  useEffect(() => {
    const script = document.createElement("script");
    script.src = PINE_PG;
    script.async = true;
    document.body.appendChild(script);
    props?.actions?.getPaymentMethodsAction()
  }, [])

  useEffect(() => {
    if (kycState?.getPaymentMethods?.data?.success === true) {
      setPaymentMethod(kycState?.getPaymentMethods?.data?.data?.filter((item) => item.id === 2)[0])
    }
  }, [kycState?.getPaymentMethods])

  useEffect(() => {
    if (kycState?.generateOrder?.data?.success === true){
      openPaymentGateway(kycState?.generateOrder?.data?.response?.token)
      props?.actions?.cleanUpToast()
    }
  }, [kycState?.generateOrder])

  useEffect(() => {
    if (kycState?.getPaymentStatus?.data?.success === true){
      let payData = {
        order_id: kycState?.getPaymentStatus?.data?.response?.merchant_data?.order_id,
        bene_full_name: kycState?.getPaymentStatus?.data?.response?.payment_info_data?.card_holder_name,
        transfer_type: kycState?.getPaymentStatus?.data?.response?.payment_info_data.payment_mode,
        plural_order_id: kycState?.getPaymentStatus?.data?.response?.order_data?.plural_order_id,
        payment_id: kycState?.getPaymentStatus?.data?.response?.payment_info_data?.payment_id,
        payment_method: paymentMethod?.id,
        status: kycState?.getPaymentStatus?.data?.response?.order_data?.order_status === "CHARGED" ? '2' : '1'
        // status: kycState?.getPaymentStatus?.data?.response?.order_data?.order_status === 'FAILED' ? '1' : '2'
      }
      props.actions.updateOrderStatusAction(payData)
    }
  }, [kycState?.getPaymentStatus])

  useEffect(() => {
    if (kycState?.updateOrderStatus?.data?.success === true){
      addToast({message: 'Payment Done Successfully', status: 'success'})
      props.actions.getCurrentUserDetailsAction()
    }
  }, [kycState?.updateOrderStatus])

  const openPaymentGateway = (orderToken) => {
    const decodeData = decode(getItem('id_token'))
    const options = {
        env: "PROD",
        theme: 'black',
        orderToken: orderToken,
        channelId: 'WEB',
        paymentMode: 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI',
        countryCode: "91",
        mobileNumber: decodeData?.mobile, // type = string, default = null
        emailId: decodeData?.email, //default null
        showSavedCardsFeature: false, // type = boolean, default = true
        successHandler: async function(response) {
          checkpaymentStatus(response)
        },
        failedHandler: async function(response) {
          if (response.error_code !== "4010"){
            checkpaymentStatus(response)
          } else {
            addToast({ message: 'Please try again', status: 'error' });
          }  
        }
      };
      const plural = new window.Plural(options);
      plural.open(options);
  };

  const checkpaymentStatus = (resp) => {
    if(resp.payment_id) {
      let data = {
        plural_order_id: resp.plural_order_id,
        payment_id: resp.payment_id,
        payment_method: paymentMethod?.id,
        type: 1
      };
      props.actions.getPaymentStatusAction(data);
    }
  };

  const handlePay = () => {
    if (!props?.feeData?.personal_data) {
      addToast({message: 'Please Complete Personal Details First', status: 'error'})
      return
    }
    if (!props?.feeData?.bank_data) {
      addToast({message: 'Please Complete Bank Details First', status: 'error'})
      return
    }
    if (!props?.feeData?.document_data) {
      addToast({message: 'Please Upload Documents First', status: 'error'})
      return
    }
    let orderData = {
      amount: props?.feeData?.total_fee_amount * 100,
      currency: "INR",
      desc: "Registration Fees",
      product_code: "1",
      payment_method: paymentMethod?.id,
      type: "1"
    };
    props.actions.generateOrderAction(orderData);
  }

  return (
    <>
      {(masterState?.getCurrentUserDetails?.loading || kycState?.generateOrder?.loading || kycState?.getPaymentStatus?.loading
      || kycState?.updateOrderStatus?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className='main_form_container'>
        <Grid className="grid_container">
          <GridItem>
            <Heading as="h1" className="font-poppins text_heading">Fee Payment</Heading>
            <Heading as="h1" className="font-poppins text_subheading">To verify your details and to perform a credit underwriting, we charge a nominal registration amount. Please make this payment in order to proceed further and finish your registration process.</Heading>
            <Box mb={7} className='form_kyc'>
              <Box mt={7} className='table_wrapper'>
                <TableContainer>
                  <Table variant='simple'>
                    <Tbody>
                      <Tr>
                        <Td>Registration Fee
                          {props?.feeData?.registration_fee === 0 &&
                            <span className='save_fee'>Save 100</span>
                          }
                        </Td>
                        <Td>
                          {props?.feeData?.registration_fee === 0 &&
                            <span className='wave_fee'>INR 100</span>
                          }
                          INR {props?.feeData?.registration_fee}</Td>
                      </Tr>
                      <Tr>
                        <Td>SGST (9%)</Td>
                        <Td>INR {props?.feeData?.sgst_fee}</Td>
                      </Tr>
                      <Tr>
                        <Td>CGST (9%)</Td>
                        <Td>INR {props?.feeData?.cgst_fee}</Td>
                      </Tr>
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>Total Amount to be paid</Th>
                        <Th>INR {props?.feeData?.total_fee_amount}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </Box>
              {props?.feeData?.registration_fee === 0 &&
                <Text className="fee_note font-poppins">Congratulations! Your KYC fee has been waived.</Text>
              }
            </Box>
          </GridItem>
        </Grid>
        {props?.feeData?.registration_fee === 0 ?
            <Box className="kyc_actions">
              <CustomButton size="md" type="submit" title="Next" className="btn_green font-poppins" onClick={handlePay} />
            </Box>
          :
          <Box className="kyc_actions">
            {props?.feeData?.fee_data?.transaction_status === '2' ?
              <CustomButton size="md" type="submit" title={'Paid ₹ ' + props?.feeData?.total_fee_amount} className="btn_green font-poppins" isDisabled={true} /> :
              <CustomButton size="md" type="submit" title={'Pay Now ₹ ' + props?.feeData?.total_fee_amount} className="btn_green font-poppins" onClick={handlePay} />
            }
          </Box>
        }
      </Box>
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(FeePayment)