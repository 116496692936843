import React from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text, Progress, Flex, Spacer, Link, Tooltip, Input, Checkbox, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import UserIcon from "../../../../../assets/images/userIcon.svg";
import moment from 'moment';

export default function ActiveInvestmentSidebar(props) {

    const data = props?.sidebarData

  const PopupBox = () => (
    <Box>
      <DrawerOverlay />
      <DrawerContent className='invest_sidebar_content'>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header' pb={0} pt={1}>
            <Box className='profile_info'>
                <Image src={data?.loan_data?.user_data?.photo ? data?.loan_data?.user_data?.photo : UserIcon} className="profile_image" />
                <Box className='user_profile'>
                <Heading as="h3" className='font-poppins'>{data?.borrowerDetailsData?.full_name}</Heading>
                <Heading as="h5" className='font-poppins'>{data?.loan_data?.loan_number}</Heading>
                </Box>
            </Box>
        </DrawerHeader>
        <DrawerBody>
            <Box className='lending_amount lending_card_bg lending_card_radius'>
                <Box className='preference_header center_text' mb={2}>
                    <Heading as="h3" className="font-poppins">Total Expected Return</Heading>
                </Box>
                <Heading as="h1" className="font-poppins">
                    ₹{data?.payout_distribution?.total_outstanding_dues}
                    {/* ₹{data?.loan_data?.product_id === 1 ? ((data?.pledged_amount/data?.loan_data?.loan_amount) * data?.loan_data?.emi_amount).toFixed(2) : (data?.loan_data?.emi_amount - data?.pledged_amount).toFixed(2)} */}
                </Heading>
                <Progress className='custom_progress' bg='#00000014' size='sm' value={data?.loan_data?.committed_loan_amount ? parseFloat(data?.loan_data?.committed_loan_amount) : 0} max={data?.loan_data?.loan_amount} />
                <Flex alignItems='center' mt={1}>
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.committed_loan_amount ? data?.loan_data?.committed_loan_amount : 0}</Heading>
                        <Heading as="p" className="font-poppins">Payout Received</Heading>
                    </Box>
                    <Spacer />
                    <Box className='progress_amount'>
                        <Heading as="h6" className="font-poppins">₹ {data?.loan_data?.remaining_loan_amount}</Heading>
                        <Heading as="p" className="font-poppins">Remaining</Heading>
                    </Box>
                </Flex>
            </Box>
            <Box className='accordion_box lending_card_bg lending_card_radius' mt={1}>
                <Accordion allowToggle>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Loan Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                <Text className='font-poppins loan_type'>
                                    {data?.loan_data?.loan_name}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Invested Amount</Heading>
                                <Text className='font-poppins'>₹ {data?.pledged_amount?.toLocaleString('en-US')}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.tenure_duration}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                <Text className='font-poppins'>
                                    {`${data?.loan_data?.interest_rate}%`}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.alpha_rating}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Disbursement Date</Heading>
                                <Text className='font-poppins'>
                                    {(data?.loan_contract && data?.loan_contract?.[0]?.pledged_amount ? moment(data?.loan_contract?.[0]?.funding_date).format('DD-MM-YYYY') : 'N/A')}
                                </Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>{data?.loan_data?.product_id === 1 ? 'Next Payout Date' : 'Payout Date'}</Heading>
                                <Text className='font-poppins'>
                                    {data?.loan_data?.product_id === 1 && data?.loan_contract ? moment(data?.loan_contract?.[0]?.emi_date).format('DD-MM-YYYY') : moment(data?.loan_contract?.[0]?.maturity_date).format('DD-MM-YYYY')}
                                </Text>
                            </Box>
                            {data?.loan_data?.product_id === 1 ?
                                <>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Monthly Expected Payout</Heading>
                                        <Text className='font-poppins'>₹{(data?.payout_distribution?.outstanding_amount + data?.payout_distribution?.accured_interest_on_emi).toFixed(2)}</Text>
                                    </Box>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Penal Charges</Heading>
                                        <Text className='font-poppins'>₹{data?.payout_distribution?.penel_interest_till_date}</Text>
                                    </Box>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Total Return</Heading>
                                        <Text className='font-poppins'>₹{data?.payout_distribution?.total_outstanding_dues}</Text>
                                    </Box>
                                </> :
                                <>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Principal with Interest (Till Date)</Heading>
                                        <Text className='font-poppins'>
                                            ₹{(data?.loan_contract?.[0]?.outstanding_amount + data?.loan_contract?.[0]?.accured_interset_till_date)?.toFixed(2)}
                                        </Text>
                                    </Box>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Penal Charges</Heading>
                                        <Text className='font-poppins'>₹{data?.loan_contract?.[0]?.penel_interset_till_date}</Text>
                                    </Box>
                                    <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                        <Heading as="h6" className='font-poppins'>Total Return</Heading>
                                        <Text className='font-poppins'>₹{data?.loan_contract?.[0]?.total_outstanding_dues}</Text>
                                    </Box>
                                </>
                            }
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'> Borrower Details </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Full Name</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.full_name}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>City Name</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.city}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>CKYC Number</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.ckyc_detail?.ckyc_number}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.loan_purpose}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>House Ownership</Heading>
                                <Text className='font-poppins'>{data?.loan_data?.personal_data?.display_premise_status}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Age</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.age} Years</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Employment Type</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.employment_type}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem className='accordion_card_item'>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>  Risk category & ALPHA Score </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Equifax Score</Heading>
                                <Text className='font-poppins loan_type'>{data?.borrowerDetailsData?.equifax_score}</Text>
                            </Box>
                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                <Text className='font-poppins'>{data?.borrowerDetailsData?.alpha_rating}</Text>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={2} mt={0}>
            <Box className='full-width'>
                <CustomButton size="md" type="submit" title="View Agreement" className="btn_theme font-poppins" onClick={() => props.handleViewAgreement(data?.loan_document_data?.eSign_doc_file)} />
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
      isOpen={props.openSidebar}
      placement="right"
      onClose={props.handleCloseSidebar}
      size={'md'}
    >
      {PopupBox()}
    </Drawer>
  )
}

