import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { Doughnut } from 'react-chartjs-2';

export default function PostSanctionChart(props) {
  return (
    <Box className='chart_box bg_shadow'>
        <Box className='admin_chart_box'>
            <Heading as="h2" className='font-poppins'>{props.title}</Heading>
            <Heading as="h3" className='font-poppins'>Pending Contracts ({props.pending})</Heading>
        </Box>
        <Box className='chart_box_inner admin_chart' mt={6}>
            <Doughnut data={props.data} options={props.options} width={270} />
        </Box>
    </Box>
  )
}
