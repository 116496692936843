import React, { useEffect, useState } from "react";
import {Box, Image, Heading, Flex, Input, Select, Checkbox} from '@chakra-ui/react'
import {ActionCreators} from '.././../../redux/actions'
import {Link, useHistory} from 'react-router-dom'
import { connect, useSelector } from "react-redux";
import RBIlogo from "../../../assets/images/rbi_logo.svg";
import RBIlogoBlack from "../../../assets/images/rbi_black.svg";
import alphaLogo2 from "../../../assets/images/Asset_logo_3.svg";
import alphaLogo from "../../../assets/images/Asset_logo_2.svg";
import CustomButton from "../../elements/customButton.js";
import decode from 'jwt-decode';
import * as routesNames from '../../../constants/routes'
import { bindActionCreators } from "redux";
import CustomToast from "../../elements/customToast.js";
import { setItem, removeItem, getItem } from "../../../utilities/authUtils.js";
import PrivacyPoliciesDialog from "../../elements/PrivacyPoliciesDialog.js";
import TermsConditionsDialog from "../../elements/TermsConditionsDialog.js";

const Signup = (props) => {

  const history = useHistory()
  const {addToast} = CustomToast()
  const authState = useSelector((state) => state.authState)
  const masterState = useSelector((state) => state.masterState)
  const [inputValues, setInputValues] = useState({})
  const [privacyCheck, setPrivacyChecked] = useState(false)
  const [riskCheck, setRiskCheck] = useState(false)
  const [cpData, setCPData] = useState(null)
  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)
  const dropdownData = masterState?.getDropdownData?.data?.data

  useEffect(() => {
    let data = getItem('cp_token')
    if (data) {
      let decodeData = decode(data);
      console.log(decodeData, 'ggg')
      setCPData(decodeData)
      setInputValues({...inputValues, cp_referral_code: decodeData?.email})
    }
    props.actions.getDropdownDataAction({ type: 'employment_type_lender|employment_type_borrower' , platform:'web'})
  }, [])

  useEffect(() => {
    if (authState?.createNewUser?.data?.success === true){
      addToast({message: authState?.createNewUser?.data?.message, status: 'success'})
      if(authState?.createNewUser?.data?.header_token){
        let date = new Date();
        let milliseconds = date.getTime();
        setItem("id_token", authState?.createNewUser?.data?.token);
        setItem("header_token", authState?.createNewUser?.data?.header_token);
        setItem("logtime", milliseconds);
        removeItem('req_id');
        removeItem('number');
        removeItem('usertype')
        removeItem('cp_token')
        const userData = authState?.createNewUser?.data?.token;
        let decodeData = decode(userData)
        console.log(decodeData, 'userData')
        if (decodeData?.usertype == '2') {
          history.push(routesNames.BORROWERDASHBOARD);
        }
        if (decodeData?.usertype == '3') {
          history.push(routesNames.INVESTERDASHBOARD);
        }
      }
    } else if (authState?.createNewUser?.data?.success === false){
      addToast({message: authState?.createNewUser?.data?.message, status: 'error'})
    }
  }, [authState?.createNewUser])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({...inputValues, [name]: value});
  }

  const handleClickTerms = () => {
    setOpenTerms(!openTerms)
  }

  const handleClickPolicy = () => {
    setOpenPolicy(!openPolicy)
  }

  const handleCheck = (e, type) => {
    if (type === 'privacy') {
      setPrivacyChecked(e.target.checked)
    }
    if (type === 'risks') {
      setRiskCheck(e.target.checked)
    }
  }

  console.log(inputValues, 'inputValues')

  const handleContinue = () => {
    if (!inputValues?.employment_type) {
      addToast({message: 'Please select employment type', status: 'error'})
      return
    }
    if (!privacyCheck) {
      addToast({message: 'Please accept terms of use!', status: 'error'})
      return
    }
    if (!riskCheck) {
      addToast({message: 'Please accept all risk associated!', status: 'error'})
      return
    }
    let userData = {
      cp_id: cpData?.user_id ?? "",
      cp_referral_code: inputValues.cp_referral_code ?? '',
      // employment_type: Number(inputValues.employment_type),
      employment_type: inputValues.employment_type,
      isMobile: 0,
      mobile: getItem('number'),
      request_id: getItem('req_id'),
      user_device_id: 'NA',
      platform: 'web',
      usertype: getItem('usertype')
    }
    // console.log(userData, 'userData')
    props.actions.createNewUserAction(userData)
  }

  return (
    <Box className="parent_container">
      <Box className="updateprofileContainerLogin main_container">       
        <Box className="auth_left_panel hide-xs">
          <Link to={routesNames.LOGIN}>
              <Image className="" src={alphaLogo} />
          </Link>
          <Box className="about_alpha">
            <Heading as="h4">Welcome to <span style={{color:'#E61E25'}}>ALPHA</span>MONEY!</Heading>
            <Heading as="p">
              Our Peer To Peer lending platform connects borrowers with investors for quick personal loans and great returns. Join us now for financial freedom.
            </Heading>
          </Box>
          <Box className="rbi">
            <Image className="" src={RBIlogo} />
          </Box>
        </Box> 
        <Box className="auth_right_panel">
          <Box className="form_container">
            <Box className="form_heading">
              <Box className="mobile_logo hide-md visible-xs">
                <Link to={routesNames.LOGIN}>
                  <Image alt="logo" src={alphaLogo2} />
                </Link>
              </Box>
              <Heading as="h1">Just a couple of clicks and we <span>Start.</span></Heading>
              <Heading as="p">What is Your Employment Type</Heading>
            </Box>
            <Box className="login-left-container">
              <Box className="formWraper">
                <Box mb={5}>
                  <Select onChange={(e) => handleChange(e)} placeholder={getItem("usertype") === '2' ? "Select Type of Employment" : "Select Type of Investor"}
                    name="employment_type" variant="filled" className="input_box_select font-poppins">
                    {getItem("usertype") === '2' ?
                      <>
                      {dropdownData?.employment_type_borrower?.map((item) => {
                        return (
                          <option key={item.key} value={item.key}>{item.value}</option>
                        )
                      })}
                      </> :
                      <>
                      {dropdownData?.employment_type_lender?.map((item) => {
                        return (
                          <option key={item.key} value={item.key}>{item.value}</option>
                        )
                      })}
                      </>
                    }
                  </Select>
                </Box>
                
                <Box className="formWraper">
                <Box mb={4}>
                  <Input
                    size="md"
                    name="cp_referral_code"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.cp_referral_code ?? ''}
                    readOnly={true}
                    // readOnly={cpData?.email}
                    placeholder="Referral Code"
                    className="input_box font-poppins"
                    variant="filled"
                />
                </Box>
                </Box>
                <Heading as="p" className="note font-poppins"> * These fields are mandatory </Heading>
                {getItem("usertype") === '3' ?
                  <Box mb={5} mt={3}>
                    <Flex className="column_gap">
                      <Checkbox onChange={(e) => handleCheck(e, 'privacy')} isChecked={privacyCheck} />
                      <Heading as="p" className="note font-poppins">I have read and agreed to the <span onClick={handleClickTerms}>Terms of Use</span> and <span onClick={handleClickPolicy}>Privacy and Security Policy</span></Heading>
                    </Flex>
                    <Flex mt={5} className="column_gap">
                      <Checkbox onChange={(e) => handleCheck(e, 'risks')} isChecked={riskCheck} />
                      <Heading as="p" className="note font-poppins">I have understood all the risks associated with the investment transactions such as the likelihood of loss of the entire principal and accrued interest in case of default by a borrower. I am aware that Creditech Solutions Pvt Ltd does not assure return of principal or payment of interest.</Heading>
                    </Flex>
                  </Box> :
                  <Box mb={5} mt={3}>
                    <Flex className="column_gap">
                      <Checkbox onChange={(e) => handleCheck(e, 'privacy')} isChecked={privacyCheck} />
                      <Heading as="p" className="note font-poppins">I agree to <span onClick={handleClickTerms}>Terms and Conditions</span> and <span onClick={handleClickPolicy}>Privacy Policy</span> I authorize CrediTech Solutions Private Limited to access my credit report from Credit bureaus and store it to process my Loan Application.</Heading>
                    </Flex>
                    <Flex mt={5} className="column_gap">
                      <Checkbox onChange={(e) => handleCheck(e, 'risks')} isChecked={riskCheck} />
                      <Heading as="p" className="note font-poppins">I hereby give my consent to CrediTech Solutions Private Limited to collect, store & verify my information from Credit bureaus and KYC details for the processing of my loan application.</Heading>
                    </Flex>
                  </Box>
                }
                <Box className="submit_actions">
                  <CustomButton size="md" type="submit" title="Continue" onClick={() => handleContinue()}
                    className="btn_theme font-poppins" />
                </Box>
              </Box> 
            </Box>
            <Box className="mobile_rbi_logo hide-md visible-xs">
              <Image alt="rbi" src={RBIlogoBlack} />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ComingSoon openComingSoon={openComingSoon} handleClose={handleClose} data={comingSoonBorrowData} /> */}
      <TermsConditionsDialog type={getItem("usertype")} handleClickTerms={handleClickTerms} openTerms={openTerms} />
      <PrivacyPoliciesDialog handleClickPolicy={handleClickPolicy} openPolicy={openPolicy} />
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Signup)