import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
  getCurrentUserDetails: {
    data: null,
    loading: false,
    error: null,
  },
  getDropdownData: {
    data: null,
    loading: false,
    error: null,
  },
}

const masterReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENT_USER_DETAILS_REQUEST:
      return {
        ...state,
        getCurrentUserDetails: {
          ...state.getCurrentUserDetails,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_CURRENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getCurrentUserDetails: {
          ...state.getCurrentUserDetails,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_CURRENT_USER_DETAILS_FAILURE:
      return {
        ...state,
        getCurrentUserDetails: {
          ...state.getCurrentUserDetails,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.GET_DROPDOWN_DATA_REQUEST:
      return {
        ...state,
        getDropdownData: {
          ...state.getDropdownData,
          data: null,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        getDropdownData: {
          ...state.getDropdownData,
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
        getDropdownData: {
          ...state.getDropdownData,
          data: null,
          loading: false,
          error: action.payload,
        },
      }
    case actionTypes.CLEANUP_TOAST:
      return {
        ...state,
        getCurrentUserDetails: {
          data: null,
          loading: false,
          error: null,
        },
        getDropdownData: {
          data: null,
          loading: false,
          error: null,
        }
      }
    default:
      return state
  }
}

export default masterReducers
