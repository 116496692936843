import React from 'react'
import { Box, Heading, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/react'

export default function BulletLoanEmiCalculator(props) {

  return (
    <Box mt={4}>
        <Box mb={6}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>Loan Amount</Heading>
                <Heading as="h5" className='font-poppins'>₹ {props.bulletLoanDetails.loanAmount[1].toLocaleString('en-US')}</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.bulletLoanDetails.loanAmount} min={props?.isBulletLoan?.min_loan_amount} max={props?.isBulletLoan?.max_loan_amount} step={1000}
                    onChange={(e) => props.handleChangeBulletLoan(e, 'loanAmount')}
                >
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box mb={6}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>ROI (Per Annum)</Heading>
                <Heading as="h5" className='font-poppins'>{props.bulletLoanDetails.roi[1]}%</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.bulletLoanDetails.roi} min={props?.isBulletLoan?.min_interest} max={props?.isBulletLoan?.max_interest} step={1}
                    onChange={(e) => props.handleChangeBulletLoan(e, 'roi')} 
                >
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box mb={5}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>Loan Tenure</Heading>
                <Heading as="h5" className='font-poppins'>{props.bulletLoanDetails.tenure[1]} Days</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.bulletLoanDetails.tenure} min={props?.isBulletLoan?.min_days} max={props?.isBulletLoan?.max_days} step={1}
                    onChange={(e) => props.handleChangeBulletLoan(e, 'tenure')}
                >
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box>
            <Box mb={4} className='emi_data'>
                <Heading as="h5" className='font-poppins'>Principal Amount</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.bulletLoanDetails?.loanAmount[1].toLocaleString('en-US')}</Heading>
            </Box>
            <Box mb={4} className='emi_data'>
                <Heading as="h5" className='font-poppins'>Total Interest</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.totalInterest}</Heading>
            </Box>
            <Box className='emi_data'>
                <Heading as="h5" className='font-poppins'>Repayment Amount</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.totalAmount}</Heading>
            </Box>
        </Box>
    </Box>
  )
}
