import { Box, Flex, Heading, Image, Table, Tbody, Tr, Th, Td, TableContainer, Thead } from '@chakra-ui/react'
import React from 'react'
import EquifaxIcon from '../../../../../assets/images/equifax.svg'
import OnTimeIcon from '../../../../../assets/images/onTime.svg'
import DelayedIcon from '../../../../../assets/images/delayed.svg'
import WriteOffIcon from '../../../../../assets/images/writeOff.svg'
import NpaIcon from '../../../../../assets/images/npa.svg'

const monthsData = [
  {
    id: 1,
    name: 'Jan'
  },
  {
    id: 2,
    name: 'Feb'
  },
  {
    id: 3,
    name: 'Mar'
  },
  {
    id: 4,
    name: 'Apr'
  },
  {
    id: 5,
    name: 'May'
  },
  {
    id: 6,
    name: 'Jun'
  },
  {
    id: 7,
    name: 'Jul'
  },
  {
    id: 8,
    name: 'Aug'
  },
  {
    id: 9,
    name: 'Sep'
  },
  {
    id: 10,
    name: 'Oct'
  },
  {
    id: 11,
    name: 'Nov'
  },
  {
    id: 12,
    name: 'Dec'
  }
]

export default function LoanCalender(props) {

  const data = props?.creditData
  const finalData = data?.yearlySummaries?.slice(0,3)

  return (
    <Box mt={5} className='loan_calender_card bg_shadow'>
        <Box className='score_flex justify-content-end'>
            <Heading as="p" className='font-poppins'>Powered By<Image src={EquifaxIcon} /></Heading>
        </Box>
        <Flex className='calender_heading'>
            <Heading as="h5" className='font-poppins'>Your Payment History (Last 3 years)</Heading>
            <Heading as="h6" className='font-poppins'>({data?.repaymentBehaviour?.onTimePercentage?.toLocaleString('en-US')}{'%'}) Repayment On Time</Heading>
        </Flex>
        <Flex className='calender_data'>
            <Heading as="p" className='font-poppins'><span>{data?.repaymentBehaviour?.totalCountEMI}</span> Total Payment</Heading>
            <Heading as="p" className='font-poppins'><span>{data?.repaymentBehaviour?.delayCount}</span> Delayed Payment</Heading>
            <Heading as="p" className='font-poppins'><span>{data?.repaymentBehaviour?.onTimeCount}</span> Repayment done on time</Heading>
            <Heading as="p" className='font-poppins'><span>{data?.repaymentBehaviour?.numberOF_WOF}</span> Write Off</Heading>
            <Heading as="p" className='font-poppins'><span>{data?.repaymentBehaviour?.npaCount}</span> NPA</Heading>
        </Flex>
        <TableContainer className='calender_table_container'>
          <Table variant="simple" className='calender_table'>
            <Thead>
              <Tr>
                <Th />
                {monthsData?.map((item, index) => {
                  return (
                    <Th key={index}>{item.name}</Th>
                  )
                })}
              </Tr>
            </Thead>
            <Tbody>
              {finalData?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{item.year}</Td>
                    {item?.months?.map((elem, index) => {
                      return (
                        <Td key={index}>
                          {elem?.paymentStatusSummary === 'NPA' ?
                            <Image src={NpaIcon} className='table_icon' /> :
                            elem?.paymentStatusSummary === 'Delay' ? 
                            <Image src={DelayedIcon} className='table_icon' /> :
                            elem?.paymentStatusSummary === 'WOF' ? 
                            <Image src={WriteOffIcon} className='table_icon' /> :
                            elem?.paymentStatusSummary === 'ON_TIME' ? 
                            <Image src={OnTimeIcon} className='table_icon' /> :
                            <Box className='round_input' />
                          }
                        </Td>
                      )
                    })}
                    {Array.from({ length: 12 - item?.months?.length }, (_, idx) => (
                      <Td key={monthsData?.length + idx}><Box className='round_input' /></Td>
                    ))}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex className='calender_legends'>
            <Heading as="p" className='font-poppins'><Image src={OnTimeIcon} className='table_icon' />On Time</Heading>
            <Heading as="p" className='font-poppins'><Image src={DelayedIcon} className='table_icon' />Delayed</Heading>
            <Heading as="p" className='font-poppins'><Image src={WriteOffIcon} className='table_icon' /> Write Off</Heading>
            <Heading as="p" className='font-poppins'><Image src={NpaIcon} className='table_icon' /> NPA</Heading>
        </Flex>
    </Box>
  )
}
