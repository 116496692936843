export function getItem(key) {
  // return JSON.parse(sessionStorage.getItem(key))
  return sessionStorage.getItem(key)
}

export function setItem(key, value) {
  // return sessionStorage.setItem(key, JSON.stringify(value))
  return sessionStorage.setItem(key, value)
}

export function removeItem(key) {
  return sessionStorage.removeItem(key)
}
export function removeAll() {
  return sessionStorage.clear()
}
export function urlToString(val) {
  return val?.split('-')?.join(' ')
}
// export const getAuthHeaders = () => {
//   const token = sessionStorage.getItem("header_token");
//   return {
//       Authorization: `Bearer ${token}`
//   };
// };