import * as authActions from './authActions'
import * as kycActions from './kycActions'
import * as masterActions from './masterActions'
import * as adminActions from './adminActions'
import * as borrowerActions from './borrowerActions'
import * as investorActions from './investorActions'
import * as cpActions from './cpActions'
import * as reportsActions from './reportsActions'
import * as salesActions from './salesActions'
import * as paymentActions from './paymentActions'
export const ActionCreators = Object.assign(
  authActions,
  kycActions,
  masterActions,
  adminActions,
  borrowerActions,
  investorActions,
  cpActions,
  reportsActions,
  salesActions,
  paymentActions,
)

