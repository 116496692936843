export const preventInvalidKey = (e) => {
    const invalidKeys = ['e', 'E', '+', '-', '.'];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
}

export const validateMobileNumber = (value, addToast) => {
    const truncatedValue = value.slice(0, 10);
    const validMobileRegex = /^[6-9]\d{0,9}$/;
    if (truncatedValue === "") {
      return truncatedValue;
    }
    // Check if the value has more than 0 characters and doesn't start with 6-9
    if (truncatedValue.length > 0 && !validMobileRegex.test(truncatedValue)) {
      addToast({ message: 'Please Enter a Valid Mobile Number starting with 6-9', status: 'error' })
      return null;
    }
    return truncatedValue
}

export const validateAlphaNumeric = (value, addToast) => {
  const validAlphaNumeric = /^[a-zA-Z\s]*$/;
  if (value === "") {
    return value;
  }
  if (value.length > 0 && !validAlphaNumeric.test(value)) {
    addToast({ message: 'Please Enter Alpha Numeric Characters', status: 'error' })
    return null;
  }
  return value
}