/* eslint-disable no-unused-vars */
import * as masterServicers from '../../services/masterServices'
import * as actionTypes from './actionsTypes'

 //actions for get current user details
 const getCurrentUserDetailsRequest = () => {
  return {
    type: actionTypes.GET_CURRENT_USER_DETAILS_REQUEST,
  }
}

const getCurrentUserDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_CURRENT_USER_DETAILS_SUCCESS,
    payload: data,
  }
}

const getCurrentUserDetailsFailure = (error) => {
  return {
    type: actionTypes.GET_CURRENT_USER_DETAILS_FAILURE,
    payload: error,
  }
}

//getCurrentUserDetails action
export const getCurrentUserDetailsAction = (payload) =>
  async(dispatch) => {
    dispatch(getCurrentUserDetailsRequest())
    try {
      const responseData = await masterServicers.getCurrentUserDetails(payload)
      if (responseData?.status === 200) {
        dispatch(getCurrentUserDetailsSuccess(responseData?.data))
      } else {
        dispatch(getCurrentUserDetailsFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCurrentUserDetailsFailure(error?.response?.data))
    }
  }

//actions for get dropdown data
 const getDropdownDataRequest = () => {
  return {
    type: actionTypes.GET_DROPDOWN_DATA_REQUEST,
  }
}

const getDropdownDataSuccess = (data) => {
  return {
    type: actionTypes.GET_DROPDOWN_DATA_SUCCESS,
    payload: data,
  }
}

const getDropdownDataFailure = (error) => {
  return {
    type: actionTypes.GET_DROPDOWN_DATA_FAILURE,
    payload: error,
  }
}

//getDropdownData action
export const getDropdownDataAction = (payload) =>
  async(dispatch) => {
    console.log('payloadpayload',payload)
    dispatch(getDropdownDataRequest())
    try {
      const responseData = await masterServicers.getDropdownData(payload)
      if (responseData?.status === 200) {
        dispatch(getDropdownDataSuccess(responseData?.data))
      } else {
        dispatch(getDropdownDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getDropdownDataFailure(error?.response?.data))
    }
  }

// cleanUp toast
const cleanUp = () => {
  return {
    type: actionTypes.CLEANUP_TOAST,
  }
}
export const cleanUpToast = () =>
  async(dispatch) => {
    dispatch(cleanUp())
  }

