import { ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text, Flex, Heading, IconButton, Input, Modal, ModalBody, ModalContent, ModalOverlay, Progress, Spacer, Tooltip, Image, Checkbox } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../../elements/customButton'
import UserIcon from "../../../../../assets/images/userIcon.svg";
import moment from 'moment'

export default function QuickInvestDialog(props) {

    const item = props?.opportunityData?.[props?.currentIndex]

    const PopupBox = () => (
        <Box className='preference_popup'>
            <ModalOverlay />
            <ModalContent>
                <Box className='d-flex align-items-center justify-content-between preference_header'>
                    <Box className='d-flex align-items-center col-gap-15'>
                        <IconButton onClick={props.handleCloseQuickInvestDialog}><ArrowBackIcon /></IconButton>
                        <Heading as="h3" className="font-poppins">Quick Invest Opportunity</Heading>
                    </Box>
                    <Text className="font-poppins">{props?.currentIndex + 1}/{props?.opportunityData?.length} Loan Applications</Text>
                </Box>
                <ModalBody className='lending_body' pb={0}>
                    <Box className='quick_invest_container'>
                        <Box className='profile_info'>
                            <Image src={item?.user_data ? item?.user_data?.photo : UserIcon} className="profile_image" />
                            <Box className='user_profile'>
                                <Heading as="h3" className='font-poppins'>{item?.borrowerDetailsData?.full_name}</Heading>
                                <Heading as="h5" className='font-poppins'>{item?.loan_number}</Heading>
                            </Box>
                        </Box>
                        <Box className='lending_amount lending_card_bg lending_card_radius'>
                            <Box className='preference_header center_text' mb={2}>
                                <Heading as="h3" className="font-poppins">Loan Amount</Heading>
                            </Box>
                            <Heading as="h1" className="font-poppins">₹ {item?.loan_amount}</Heading>
                            <Progress className='custom_progress' bg='#00000014' size='sm' value={item?.committed_loan_amount ? parseFloat(item?.committed_loan_amount) : 0} max={item?.loan_amount} />
                            <Flex alignItems='center' mt={1}>
                                <Box className='progress_amount'>
                                    <Heading as="h6" className="font-poppins">₹ {item?.committed_loan_amount ? item?.committed_loan_amount : 0}</Heading>
                                    <Heading as="p" className="font-poppins">Already funded</Heading>
                                </Box>
                                <Spacer />
                                <Box className='progress_amount'>
                                    <Heading as="h6" className="font-poppins">₹ {item?.remaining_loan_amount}</Heading>
                                    <Heading as="p" className="font-poppins">Remaining</Heading>
                                </Box>
                            </Flex>
                        </Box>
                        <Box className='accordion_box lending_card_bg lending_card_radius' mt={1}>
                            <Accordion allowToggle>
                                <AccordionItem className='accordion_card_item'>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'> Loan Details </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                                        <Box className='accordion_panel_inner'>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                                <Text className='font-poppins loan_type'>
                                                    {item?.products?.name}
                                                </Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Interest Rate p.a.</Heading>
                                                <Text className='font-poppins'>
                                                    {`${item?.products?.investor_return}%`}
                                                    {/* {item?.product_id === 3 ? item?.payout_return + "%" : item?.interest_rate + "%"} */}
                                                </Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Tenure</Heading>
                                                <Text className='font-poppins'>
                                                    {/* {item?.product_id === 1 ? item?.tenure + ' Months' : item?.tenure + ' Days'} */}
                                                    {item?.tenure_duration}
                                                </Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                                <Text className='font-poppins'>₹ {item?.loan_amount}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Loan Application Expiry Date</Heading>
                                                <Text className='font-poppins'>
                                                    {item?.due_date ? moment(item?.due_date).format("DD-MM-YYYY") : "N/A"}
                                                </Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Loan Application Date</Heading>
                                                <Text className='font-poppins'>
                                                    {item?.created_date ? moment(item?.created_date).format("DD-MM-YYYY") : 'N/A'}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem className='accordion_card_item'>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'> Borrower Details </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                                        <Box className='accordion_panel_inner'>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Full Name</Heading>
                                                <Text className='font-poppins loan_type'>
                                                    {item?.borrowerDetailsData?.full_name}
                                                </Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>City Name</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.city}</Text>
                                            </Box>
                                            {/* <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>CKYC Number</Heading>
                                                <Text className='font-poppins'>{'N/A'}</Text>
                                            </Box> */}
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                                <Text className='font-poppins'>{item?.loan_purpose}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>House Ownership</Heading>
                                                <Text className='font-poppins'>{item?.personal_data?.display_premise_status}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Age</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.age}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Employment Type</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.employment_type}</Text>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem className='accordion_card_item'>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'> Repayment Behaviours </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                                        <Box className='accordion_panel_inner'>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Payment History</Heading>
                                                <Text className='font-poppins text_green'>{(item?.borrowerDetailsData?.percentage)}% Repayment On Time</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Total Payments </Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.total_closed_loans}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Repayment On Time</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.pay_on_time_loans}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Delayed Payment </Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.delayed_loans}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>Pending</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.pending_loans}</Text>
                                            </Box>
                                            <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                                <Heading as="h6" className='font-poppins'>NPA</Heading>
                                                <Text className='font-poppins'>{item?.borrowerDetailsData?.npa}</Text>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem className='accordion_card_item'>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'> Risk category & ALPHA Score </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pt={3} pb={0} mt={2} className='panel_box_collapse'>
                                        <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                            <Heading as="h6" className='font-poppins'>Equifax Score</Heading>
                                            <Text className='font-poppins loan_type'>{item?.borrowerDetailsData?.equifax_score}</Text>
                                        </Box>
                                        <Box className='invest_data_box d-flex align-items-center justify-content-between'>
                                            <Heading as="h6" className='font-poppins'>ALPHA Score</Heading>
                                            <Text className='font-poppins'>{item?.borrowerDetailsData?.alpha_score}</Text>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                        <Box mt={1} className='lending_amount_add lending_card_bg lending_card_radius'>
                            <Box className='invest_amount_lender'>
                                <Heading as="p" className='font-poppins'>Manually Enter Amount </Heading>
                                <Box className="d-flex align-items-center col-gap-10 invest_amount_container">
                                    <Input
                                        type="number"
                                        size="md"
                                        name="invest_amount"
                                        placeholder="₹ 0.00"
                                        onChange={props.handleChange}
                                        className="input_box_invest font-poppins"
                                        value={props?.inputValues?.invest_amount ?? ''}
                                    />
                                    {!(+(props?.walletData?.effective_balance) < +(props?.inputValues?.invest_amount)) ?
                                        <Tooltip label='The minimum investment amount is ₹1000 and can be in multiples thereof.' 
                                            hasArrow fontSize='xs' placement='right-end'>
                                            <InfoOutlineIcon />
                                        </Tooltip> : 
                                        <Tooltip label='Low Wallet Balance Add Money To Invest in Loan Application' 
                                            hasArrow fontSize='xs' placement='right-end'>
                                            <InfoOutlineIcon className='low_balance' />
                                        </Tooltip>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Flex mt={3} mb={3} className="column_gap declaration_note funds_info">
                            <Checkbox onChange={(e) => props?.handleCheck(e, 'quick_acknowledge')} isChecked={props?.quickAcknowledgeConsent} />
                            <Heading as="p" className="font-poppins">I hereby acknowledge that my total
                                <Tooltip label='I hereby acknowledge that my total accumulated investment across all P2P platforms is capped at INR 50,00,000 by RBI and if I wish to invest more than INR 10,00,000 , a certificate from a practising Chartered Accountant certifying minimum net-worth of INR 50,00,000 will be required.' hasArrow fontSize='xs' placement='right-end'>
                                    Read More
                                </Tooltip>
                            </Heading>
                        </Flex>
                        <Box className='d-flex align-items-center justify-content-between col-gap-15' mt={2}>
                            <Box className='full-width'>
                            {!(+(props?.walletData?.effective_balance) < +(props?.inputValues?.invest_amount)) ? 
                                <CustomButton size="md" type="submit" title="Invest" className="btn_theme font-poppins" onClick={props.handleInvestAmount} isDisabled={!props?.quickAcknowledgeConsent} />:
                                <CustomButton size="md" type="submit" title="Add Funds" className="btn_theme font-poppins" onClick={props.handleAddFundsDialog} /> 
                            }
                            </Box>
                        </Box>
                        <Box className='next_card card_button'>
                            <IconButton onClick={props.handleNext}
                                isDisabled={props?.currentIndex === props?.opportunityData?.length - 1}
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </Box>
                        <Box className='prev_card card_button'>
                            <IconButton onClick={props.handlePrev}
                                isDisabled={props?.currentIndex === 0}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className='total_invest_count' pt={2}>
                        <Text className='font-poppins'>You Have Invested 0 Out Of <span>{props?.inputValues?.lended_amount}</span></Text>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        // onClose={props.handleCloseQuickInvestDialog}
        isOpen={props.openQuickInvestModal}
        motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
