export const POST_PERSONAL_DETAILS_REQUEST = 'POST_PERSONAL_DETAILS_REQUEST'
export const POST_PERSONAL_DETAILS_FAILURE = 'POST_PERSONAL_DETAILS_FAILURE'
export const POST_PERSONAL_DETAILS_SUCCESS = 'POST_PERSONAL_DETAILS_SUCCESS'

export const GET_PII_DATA_REQUEST = 'GET_PII_DATA_REQUEST'
export const GET_PII_DATA_FAILURE = 'GET_PII_DATA_FAILURE'
export const GET_PII_DATA_SUCCESS = 'GET_PII_DATA_SUCCESS'

export const CREATE_DIGI_LOCKER_REQUEST = 'CREATE_DIGI_LOCKER_REQUEST'
export const CREATE_DIGI_LOCKER_FAILURE = 'CREATE_DIGI_LOCKER_FAILURE'
export const CREATE_DIGI_LOCKER_SUCCESS = 'CREATE_DIGI_LOCKER_SUCCESS'

export const GET_DOWNLOAD_AADHAR_DATA_REQUEST = 'GET_DOWNLOAD_AADHAR_DATA_REQUEST'
export const GET_DOWNLOAD_AADHAR_DATA_FAILURE = 'GET_DOWNLOAD_AADHAR_DATA_FAILURE'
export const GET_DOWNLOAD_AADHAR_DATA_SUCCESS = 'GET_DOWNLOAD_AADHAR_DATA_SUCCESS'

export const SAVE_BANK_DETAILS_REQUEST = 'SAVE_BANK_DETAILS_REQUEST'
export const SAVE_BANK_DETAILS_FAILURE = 'SAVE_BANK_DETAILS_FAILURE'
export const SAVE_BANK_DETAILS_SUCCESS = 'SAVE_BANK_DETAILS_SUCCESS'

export const SAVE_DOCS_REQUEST = 'SAVE_DOCS_REQUEST'
export const SAVE_DOCS_FAILURE = 'SAVE_DOCS_FAILURE'
export const SAVE_DOCS_SUCCESS = 'SAVE_DOCS_SUCCESS'

export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST'
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'

export const GENERATE_ORDER_REQUEST = 'GENERATE_ORDER_REQUEST'
export const GENERATE_ORDER_FAILURE = 'GENERATE_ORDER_FAILURE'
export const GENERATE_ORDER_SUCCESS = 'GENERATE_ORDER_SUCCESS'

export const GET_PAYMENT_STATUS_REQUEST = 'GET_PAYMENT_STATUS_REQUEST'
export const GET_PAYMENT_STATUS_FAILURE = 'GET_PAYMENT_STATUS_FAILURE'
export const GET_PAYMENT_STATUS_SUCCESS = 'GET_PAYMENT_STATUS_SUCCESS'

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST'
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE'
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS'