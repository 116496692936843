import React from 'react'
import {Box, Button, Heading, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, Text, Checkbox, Flex, Spacer, Divider} from '@chakra-ui/react'
import SortIcon from '../../../../assets/images/sort.svg'
import FilterIcon from '../../../../assets/images/filter.svg'
import SortDescIcon from '../../../../assets/images/sort-descending.svg'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import CustomButton from '../../../elements/customButton'

export default function InvestmentFilters(props) {

  return (
    <Box className='d-flex align-items-center justify-content-end col-gap-15'>
        <InputGroup className='search_input'>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearchName(e)}
                className="input_box font-poppins"
            />
        </InputGroup>
        <Box className='position-relative'>
            <Menu>
                <MenuButton className="btn_table_action font-poppins" as={Button} onClick={() => props.handleDropdown('sort')}
                    rightIcon={<Image src={SortIcon} />}>
                    Sort By
                </MenuButton>
                {props.openSorting &&
                    <Box className='filter_list filter_dropdown'>
                        <Flex alignItems='center' py={3} px={3}>
                            <Heading as="h2" className='font-poppins'>Sort</Heading>
                            <Spacer />
                            <CloseIcon className="close-filter" onClick={() => props.handleClose('sort')} />
                        </Flex>
                        <Divider />
                        <Box>
                            {props.sortOptions?.map((item) => {
                                return (
                                    <Flex className={item.name === props.sortingData ? "sorting_item active_sorting_item" : "sorting_item"} key={item.id}>
                                        <Image src={item.name === props.sortingData && props?.sortOrder === 'asc' ? SortDescIcon : SortIcon} />
                                        <Text onClick={() => props.handleSelectSorting(item.name)}>{item.name}</Text>
                                    </Flex>
                                )
                            })}
                        </Box>
                    </Box>
                }
            </Menu>
        </Box>
        <Box className='position-relative'>
            <Menu>
                <MenuButton className="btn_table_action font-poppins" onClick={() => props.handleDropdown('filter')} as={Button} 
                    rightIcon={<Image src={FilterIcon} />}>
                    Filter
                </MenuButton>
                {props.openFilter &&
                    <Box className='filter_list filter_dropdown invest_filter_dropdown'>
                        <Flex alignItems='center' py={3} px={3}>
                            <Heading as="h2" className='font-poppins'>Select Filter</Heading>
                            <Spacer />
                            <CloseIcon className="close-filter" onClick={() => props.handleClose('filter')} />
                        </Flex>
                        <Divider />
                        <Heading as="h4" className='font-poppins'>Loan Type</Heading>
                        <Box className='checkbox_group invest_checkbox_group'>
                            {props.filterOptions?.loanType?.map((item) => {
                                return (
                                    <Box className="checkbox_item" key={item.id}>
                                        <Checkbox key={item.id} isChecked={props.filterData?.some((val) => val?.id === item?.id)}
                                            onChange={(e) => props.handleSelectFilter(e, item)}>
                                            {item?.name}
                                        </Checkbox>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Heading as="h4" className='font-poppins'>Risk Category</Heading>
                        <Box className='checkbox_group risk_items_group invest_checkbox_group'>
                            {props.filterOptions?.riskType?.map((item) => {
                                return (
                                    <Box className="checkbox_item" key={item.id}>
                                        <Checkbox key={item.id} isChecked={props.filterData?.some((val) => val?.id === item?.id)}
                                            onChange={(e) => props.handleSelectFilter(e, item)}>
                                            {item?.name}
                                        </Checkbox>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Heading as="h4" className='font-poppins'>Loan Tenure</Heading>
                        <Box className='checkbox_group risk_items_group invest_checkbox_group'>
                            <Box className='d-flex align-items-center col-gap-10 filter_input_box'>
                                <Input
                                    type="number"
                                    size="md"
                                    name="days"
                                    placeholder="Enter Days"
                                    onChange={props.handleChange}
                                    className="filter_input font-poppins"
                                    value={props.inputValues?.days ?? ''}
                                />
                                <Text>Or</Text>
                                <Input
                                    type="number"
                                    size="md"
                                    name="months"
                                    placeholder="Enter Months"
                                    onChange={props.handleChange}
                                    className="filter_input font-poppins"
                                    value={props.inputValues?.months ?? ''}
                                />
                            </Box>
                        </Box>
                        <Box className='d-flex align-items-center justify-content-end col-gap-15' mt={4} mb={4} pl={3} pr={3}>
                            <CustomButton size="md" type="submit" title="Reset" className="btn_theme_transparent" onClick={props.handleReset} />
                            <CustomButton size="md" type="submit" title="Apply" className="btn_theme" onClick={props.handleApplyFilters} />
                        </Box>
                    </Box>
                }
            </Menu>
        </Box>
    </Box>
  )
}
