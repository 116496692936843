import React, { useState } from 'react'
import {Box, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,} from '@chakra-ui/react'
import CustomButton from './customButton'
import { Document, Page } from 'react-pdf';

export default function OpenFIleDialog(props) {

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const PopupBox = () => (
        <Box className="popup_container">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="modal_title font-poppins">Uploaded Document</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box className='pdf_read_modal'>
                        {props?.fileType === 'pdf' ?
                            <Document file={props?.modalData} onLoadSuccess={onDocumentLoadSuccess}>
                                {/* <Page pageNumber={1} /> */}
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                            : 
                            <Image src={props?.modalData} />
                        }
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <CustomButton size="md" type="submit" title="Cancel" className="btn_theme" onClick={props.handleModal} />
                </ModalFooter>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
    isCentered
    onClose={props.handleModal}
    isOpen={props.openModal}
    motionPreset="slideInBottom"
    >
    {PopupBox()}
    </Modal>
  )
}
