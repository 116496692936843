import React, { useEffect, useState } from 'react'
import {Box, Heading, Grid, GridItem, Tab, TabList, TabPanel, TabPanels, Tabs, Image, IconButton, Input} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton';
import { Document, Page } from 'react-pdf';
import OpenFIleDialog from '../../../../elements/OpenFIleDialog';
import { ViewIcon } from '@chakra-ui/icons';
import CustomToast from '../../../../elements/customToast';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../../../redux/actions';
import { connect, useSelector } from 'react-redux';
import { getItem, removeItem } from '../../../../../utilities/authUtils';
import Loader from '../../../../elements/loader';
import PaymentCalender from './PaymentCalender'
import { detectFileType } from '../../../../../utilities/DownloadDocuments';
import DownloadIcon from '../../../../../assets/images/download.png'

const CreditReport = (props) => {

    const USERID = getItem('userID')
    const LOANNUMBER = getItem('loanNumber')
    const MONTHLYINCOME = getItem('monthlyIncome')
    const {addToast} = CustomToast()
    const data = props?.userData?.data
    const adminState = useSelector((state) => state.adminState)
    const borrowerState = useSelector((state) => state.borrowerState)
    const [inputValues, setInputValues] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [currentFileType, setCurrentFileType] = useState(null)
    const creditData = borrowerState?.getBorrowerCreditData?.data?.data

    useEffect(() => {
        props.actions.getBorrowerCreditDataAction({id: USERID})
        // props.actions.getBorrowerCreditDataAction({id: '1175'})
    }, [])

    useEffect(() => {
        if (adminState?.postBorrowerExpenses?.data?.success === true){
            addToast({message: 'Submitted Successfully', status: 'success'});
            props.onClick()
            removeItem('monthlyIncome')
        } else if (adminState?.postBorrowerExpenses?.data?.success === false){
            addToast({message: adminState?.postBorrowerExpenses?.data?.message, status: 'error'});
        }
    }, [adminState?.postBorrowerExpenses])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    const fileType = {
        creditReport: detectFileType(data?.credit_score_data?.credit_report_s3),
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

    const handleNext = () => {
        if (!inputValues?.obligation) {
            addToast({message: 'Please Enter Current Obligation', status: 'error'});
            return
        }
        let expenseData = {
            loan_id: data?.loanDetails?.id,
            total_monthly_obligation: inputValues?.obligation,
            average_monthly_salary: MONTHLYINCOME,
            action: 'repayment_capacity',
        }
        let userByIdData = {
            user_id: USERID,
            loan_number: LOANNUMBER
        }
        props.actions.postBorrowerExpensesAction(expenseData, userByIdData)
    }

  return (
    <>
        {(adminState?.postBorrowerExpenses?.loading || borrowerState?.getBorrowerCreditData?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box mt={5}>
            <Grid className="main_grid_container justify-content-between">
                <GridItem colSpan={7}>
                    <Box className='summary_heading'>
                        <Heading as="h6" className='font-poppins'>Summary</Heading>
                    </Box>
                    <PaymentCalender creditData={creditData} />
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-35'>Net Monthly Income</Heading>
                        <Heading as="h6" className='font-poppins box_data w-65'>₹ {parseInt(MONTHLYINCOME).toLocaleString('en-US')}</Heading>
                    </Box>
                    <Box className='d-flex card_data' mt={2}>
                        <Heading as="h6" className='font-poppins box_title w-35'>Current Obligation</Heading>
                        <Input
                            type="number"
                            size="md"
                            id="obligation"
                            name="obligation"
                            placeholder="Please Enter Borrowers Current Obligation"
                            onChange={handleChange}
                            className="input_box font-poppins w-65"
                            value={inputValues?.obligation ?? ''}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={5}>
                    <Tabs className='tabs_container details_tabs'>
                        <TabList className='tabs_list justify-content-center'>
                            <Tab>Credit Report</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel className='tab_panel'>
                                {data?.credit_score_data?.credit_report_s3 ?
                                    <Box className='pdf_read'>
                                        {fileType?.creditReport === 'pdf' ?
                                            <Document file={data?.credit_score_data?.credit_report_s3}>
                                                <Page pageNumber={1} />
                                            </Document>
                                            : 
                                            <Image src={data?.credit_score_data?.credit_report_s3} className='docs_image' />
                                        }
                                        {data?.credit_score_data?.credit_report_s3 ?
                                        <Box>
                                            <IconButton onClick={() => handleModal(data?.credit_score_data?.credit_report_s3, fileType?.creditReport)}
                                                className="icon_button_view"  icon={<ViewIcon />}
                                            />
                                            <a target='_blank' href={data?.credit_score_data?.credit_report_s3}>
                                                <IconButton className="icon_button_download" icon={<Image src={DownloadIcon} className="download-link" />} />
                                            </a>
                                        </Box> : null
                                        }
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
            <Box className="kyc_actions btn_gap" mb={4} mt={4}>
                <CustomButton size="md" type="submit" title="Previous" className="btn_theme_transparent font-poppins" onClick={props.handlePrevious} />
                <CustomButton size="md" type="submit" title="Next" className="btn_theme font-poppins" onClick={handleNext} />
            </Box>
            <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
        </Box>
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(CreditReport)