import React from 'react'
import {Box, Heading, Tooltip} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'

export default function CustomCard(props) {
  return (
    <Box className={`${props.className} card_box`}>
      <Box className='d-flex align-items-center justify-content-between'>
        <Heading as="h2" className='font-poppins'>{props.title}</Heading>
        {props.isShowIcon ?
          <Tooltip label={props.label} hasArrow fontSize='xs' placement='right' bg='gray.600'>
              <InfoOutlineIcon />
          </Tooltip> : null
        }
      </Box>
        <Heading as="h3" className='font-poppins'>{props.value}</Heading>
    </Box>
  )
}
