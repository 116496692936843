import React from "react"
import { Box, Heading, Grid, GridItem, Text, Spacer, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Progress } from "@chakra-ui/react"
import CustomButton from '../../../../elements/customButton'
import { InfoOutlineIcon } from "@chakra-ui/icons"
import InvestmentCalender from "./InvestmentCalender"
import moment from "moment"

const ManualInvestCard = (props) => {
    const isCompleted = props?.isKycCompleted
    const repaymentClassMap = {
        A1: 'group-1',
        A2: 'group-1',
        A3: 'group-2',
        A4: 'group-2',
        A5: 'group-3',
        A6: 'group-3',
        A7: 'group-4',
        A8: 'group-4',
        A9: 'group-5',
        A10: 'group-5',
    };

    return (
        <Box mt={3}>
            {props.manualInvestData?.map((item, index) => {
                const borrowerId = item?.borrower_id
                const repayText = item?.alpha_rating
                const repaymentClass = repaymentClassMap[repayText] || '';
                return (
                    <Box mb={5} className={`${repaymentClass} card_invest`} key={index}>
                        <Box className="d-flex align-items-center justify-content-between w-100">
                            <Box className="col-padding width-30">
                                <Box className="d-flex align-items-center justify-content-between">
                                    <Box className="invest_lona_info">
                                        <Text className='font-poppins'>Loan Application ID</Text>
                                        <Heading as="h4" className='font-poppins'>{item.loan_number}</Heading>
                                    </Box>
                                    <Box className="invest_lona_info">
                                        <Text className='font-poppins'>Alpha Score</Text>
                                        <Heading as="h4" className='font-poppins text-center'>{item.alpha_rating}</Heading>
                                    </Box>
                                </Box>
                                <Box className="d-flex align-items-center justify-content-between" mt={6}>
                                    <Box className="invest_lona_info d-flex align-items-center col-gap-5">
                                        {/* <Text className={`${repaymentClass} font-poppins`}>Repayment Behaviours</Text> */}
                                        <Text className='font-poppins repay_text'>Repayment Behaviours</Text>
                                        <Popover placement='bottom'>
                                            <PopoverTrigger>
                                                <InfoOutlineIcon onClick={() => props.handleViewHistoryCalender(borrowerId)} />
                                            </PopoverTrigger>
                                            <PopoverContent className="popover_content_card">
                                                <PopoverArrow />
                                                <PopoverBody className="popover_body_card">
                                                    <InvestmentCalender data={props?.creditData} />
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        {/* <Box className="d-flex align-items-center col-gap-5">
                                            <Popover placement='bottom'>
                                                <PopoverTrigger>
                                                    <InfoOutlineIcon onClick={() => props.handleViewHistoryCalender(borrowerId)} />
                                                </PopoverTrigger>
                                                <PopoverContent className="popover_content_card">
                                                    <PopoverArrow />
                                                    <PopoverBody className="popover_body_card">
                                                        <InvestmentCalender data={props?.creditData} />
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                            <Heading as="h5" className='font-poppins text-center'><span>Repayments on time {item?.repayment_behaviour?.on_time_count}/{item?.repayment_behaviour?.total_countemi}</span> {item?.repayment_behaviour?.percentage ? '(' + item?.repayment_behaviour?.percentage?.toFixed(2) + '%)' : "(N/A)"}</Heading>
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="col-padding width-45 vertical_border">
                                <Grid className="main_grid_container align-items-center">
                                    <GridItem colSpan={3}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Type</Text>
                                            <Heading as="h6" className='font-poppins loan_type_red'>
                                                {item.products.name}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={4}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Application Date</Text>
                                            <Heading as="h6" className='font-poppins'>
                                                {item.created_date ? moment(item.created_date).format("DD-MM-YYYY") : 'N/A'}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={5}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Application Expiry Date</Text>
                                            <Heading as="h6" className='font-poppins'>
                                                {item.due_date ? moment(item.due_date).format("DD-MM-YYYY") : "N/A"}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                </Grid>
                                <Grid className="main_grid_container align-items-center" mt={6}>
                                    <GridItem colSpan={3}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Loan Amount</Text>
                                            <Heading as="h6" className='font-poppins'>₹ {item.loan_amount}</Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={4}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Interest Rate p.a </Text>
                                            <Heading as="h6" className='font-poppins'>
                                                {`${item?.products?.investor_return}%`}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                    <GridItem colSpan={5}>
                                        <Box className="invest_lona_info">
                                            <Text className='font-poppins'>Tenure</Text>
                                            <Heading as="h6" className='font-poppins'>
                                                {item?.tenure_duration}
                                            </Heading>
                                        </Box>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box className="col-padding width-25">
                                <Box>
                                    <Progress className='custom_progress' bg='#00000014' size='sm' value={item?.committed_loan_amount ? parseFloat(item?.committed_loan_amount) : 0} max={item?.loan_amount} />
                                    <Flex alignItems='center' mt={1}>
                                        <Box className='progress_amount'>
                                            <Heading as="h6" className="font-poppins">₹ {item?.committed_loan_amount ? item?.committed_loan_amount : 0}</Heading>
                                            <Heading as="p" className="font-poppins">Already funded</Heading>
                                        </Box>
                                        <Spacer />
                                        <Box className='progress_amount'>
                                            <Heading as="h6" className="font-poppins">₹ {item?.remaining_loan_amount}</Heading>
                                            <Heading as="p" className="font-poppins">Remaining</Heading>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box mt={6}>
                                    <CustomButton size="md" type="submit" title="View & Invest" className="btn_theme font-poppins w-100" onClick={() => props.handleOpenInvestSidebar(item)} isDisabled={isCompleted ? false : true} />
                                </Box>
                            </Box>
                        </Box>
                        {item.loan_type === '3' &&
                            <Box className='charges_box_invest'>
                                <Heading as="h3" className='font-poppins'>{item.loan_type === '3' ? 'Zero Charges' : null}</Heading>
                            </Box>
                        }
                    </Box>
                )
            })}
        </Box>
    )
}

export default ManualInvestCard