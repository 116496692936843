import apiInstance from '../config/api/axios'

export const getRegistrationReport = async(payload) => {
  const response = await apiInstance.post('/getRegReport', payload)
  return response
}

export const getDCRReport = async(payload) => {
  const response = await apiInstance.post('/getDCRReport', payload)
  return response
}

export const activeOneShotPayment = async(payload) => {
  const response = await apiInstance.post('/activeOneShotPayment', payload)
  return response
}

export const closedOneShotPayment = async(payload) => {
  const response = await apiInstance.post('/closeOneShotPayment', payload)
  return response
}

export const activeMultipleEmiPayment = async(payload) => {
  const response = await apiInstance.post('/multipleEmiPaymentActiveLoan', payload)
  return response
}

export const closedMultipleEmiPayment = async(payload) => {
  const response = await apiInstance.post('/multipleEmiPaymentCloseLoan', payload)
  return response
}

export const getCKYCReport = async(payload) => {
  const response = await apiInstance.post('/ckycdocuments', payload)
  return response
}