import React from 'react'
import { Box, Heading, Input } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'

export default function ProfileBankDetails(props) {

    const bank_details = props?.unmaskedData?.bank_details

  return (
    <Box mt={7}>
        <Box className='details_container'>
            <Box mb={3} className='d-flex justify-content-end'>
                <CustomButton size="md" type="submit" title="Show Details" className="btn_theme font-poppins" onClick={() => props.handleShowDetails('bank_details')} />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Account Holder Name</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.account_holder_name ? bank_details?.account_holder_name : props?.userData?.[0]?.bank_data?.account_holder_name ? props?.userData?.[0]?.bank_data?.account_holder_name : 'N/A'}
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank IfSC</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.ifsc_code ? bank_details?.ifsc_code : props?.userData?.[0]?.bank_data?.ifsc_code ? props?.userData?.[0]?.bank_data?.ifsc_code : 'N/A'}
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank Name</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.bank_name ? bank_details?.bank_name : props?.userData?.[0]?.bank_data?.bank_name ? props?.userData?.[0]?.bank_data?.bank_name : 'N/A'}
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank Branch</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.bank_branch ? bank_details?.bank_branch : props?.userData?.[0]?.bank_data?.bank_branch ? props?.userData?.[0]?.bank_data?.bank_branch : 'N/A'}
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank City</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.bank_city ? bank_details?.bank_city : props?.userData?.[0]?.bank_data?.bank_city ? props?.userData?.[0]?.bank_data?.bank_city : 'N/A'}
                />
            </Box>
            {/* <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Account Type</Heading>
                <Input size="md" type="text" className="font-poppins" value={props?.userData?.[0]?.bank_data?.account_type ?? 'N/A'} isReadOnly />
            </Box> */}
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Account Number</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={bank_details?.account_number ? bank_details?.account_number : props?.userData?.[0]?.bank_data?.account_number ? props?.userData?.[0]?.bank_data?.account_number : 'N/A'}
                />
            </Box>
        </Box>
    </Box>
  )
}
