import React, { useEffect, useState } from 'react'
import {Box, Flex, Grid, GridItem, Heading, Input, Select, Spacer, Text, Tab, TabList, TabPanel, TabPanels, Tabs, Divider} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import CustomButton from '../../../elements/customButton'
import Loader from '../../../elements/loader'
import moment from 'moment'
import CustomToast from '../../../elements/customToast'
import MultipleEmiActiveLoans from './components/MultipleEmiActiveLoans'
import MultipleEmiClosedLoans from './components/MultipleEmiClosedLoans'
import { downloadDocumentReport } from '../../../../utilities/DownloadDocuments'
import apiInstance from '../../../../config/api/axios'
import { APIPATH } from '../../../../constants/Constant'

const reportType = [
  {
    id: 1,
    name: 'CIBIL/NB99550001',
    value: 'NB99550001',
    member_short_name: 'creditsol',
  },
  {
    id: 2,
    name: 'CRIF/ NBF0004305',
    value: 'NBF0004305',
    member_short_name: 'CREDITECHSOL',
  },
  {
    id: 3,
    name: 'EXPERIAN/NBFCSP5663',
    value: 'NBFCSP5663',
    member_short_name: 'CSPL',
  },
  {
    id: 4,
    name: 'EQUIFAX/007FP12294',
    value: '007FP12294',
    member_short_name: 'WJYP',
  }
]

const tabNames = [
  {
      id: 1,
      name: 'Active Loans',
  },
  {
      id: 2,
      name: 'Closed Loans',
  },
]

const MultipleEmiPayment = (props) => {

    const history = useHistory()
    const { addToast } = CustomToast()
    const adminState = useSelector((state) => state.adminState)
    const reportState = useSelector((state) => state.reportState)
    const [selectedTab, setSelectedTab] = useState(tabNames[0].name)
    const [inputValues, setInputValues] = useState({})
    const userData = adminState?.getUserByToken?.data?.data
    const activeMulipleEmiReport = reportState?.getActiveMultipleEmiPayment?.data
    const closedMultipleEmiPayment = reportState?.getClosedMultipleEmiPayment?.data
    // const userType = userData?.usertype

    useEffect(() => {
      props?.actions?.getUserByTokenAction()
      const today = moment(new Date()).format('YYYY-MM-DD')
      setInputValues({
        start_date: today,
        end_date: today,
      });
    }, [])

    const handleTabChange = (index) => {
      setSelectedTab(tabNames[index].name);
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'report_type') {
        const selectedReport = reportType.find(report => report.value === value);
        if (selectedReport) {
          setInputValues({...inputValues, [name]: value, member_short_name: selectedReport.member_short_name})
        }
      } else {
        setInputValues({...inputValues, [name]: value})
      }
    }

    const handleGenerateReport = () => {
      if(moment(inputValues.start_date).isSameOrBefore(inputValues.end_date)) {
        apiInstance.post(`${APIPATH}/getDCRByDate`, inputValues,{
          headers: {
                    'Content-Type': 'application/json',
                  },
                  responseType: 'blob',
        } 
        ).then( async(res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'DCRByDate.xlsx');
          link.onload = () => {
            window.URL.revokeObjectURL(url);
          };
          link.click();
        })
      } else {
        addToast({message: 'Please select correct date', status: 'error'})
      }
  
    }

    const handleDownloadReport = async (reportFile) => {
      try {
        downloadDocumentReport(reportFile)
      } catch (error) {
        console.error("Error downloading the report:", error)
      }
    }

  return (
    <>
      {(adminState?.getUserByToken?.loading || reportState?.getActiveMultipleEmiPayment?.loading || reportState?.getClosedMultipleEmiPayment?.loading) &&
        <div>
            <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
              <GridItem colSpan={8}>
                  <Heading as="h2" className='font-poppins page_heading'>Multiple EMI Payment</Heading>
              </GridItem>
              <GridItem colSpan={4}>
                  <NavBar data={userData} isAdmin />
              </GridItem>
          </Grid>
          <Grid className="main_grid_container" mb={4} mt={7} gap={14}>
            <GridItem colSpan={6}>
              {/* <Box className='select_report_type'>
                <Text className="font-poppins">Select Report Type</Text>
                <Select placeholder="Select Report" variant="filled" className="select_report font-poppins"
                  name="report_type" onChange={handleChange} value={inputValues?.report_type ?? ''}>
                  {reportType?.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>{item.name}</option>
                    )
                  })}
                </Select>
              </Box> */}
              <Box mt={3}>
                <CustomButton size="md" type="submit" title="Generate Report" className="font-poppins btn_theme" onClick={handleGenerateReport} />
              </Box>
            </GridItem>
            <GridItem colSpan={6}>
              <Flex alignItems='center' justifyContent='space-between' gap={2} className='custom_select'>
                <Box className='select_report_type'>
                  <Text className="font-poppins">From Date</Text>
                  <Input size="md" name="start_date"  type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                      onChange={handleChange} value={inputValues?.start_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                  />
                </Box>
                <Spacer />
                <Box className='select_report_type'>
                  <Text className="font-poppins">To Date</Text>
                  <Input size="md" name="end_date"  type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                      onChange={handleChange} value={inputValues?.end_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                  />
                </Box>
              </Flex>
              {/* <Box className='select_report_type' mt={3}>
                <Text className="font-poppins">Reporting Date</Text>
                <Input size="md" name="reporting_date"  type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                    onChange={handleChange} value={inputValues?.reporting_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                />
                </Box> */}
            </GridItem>
          </Grid>
          <Divider />
          {/* <Tabs className='tabs_container' onChange={handleTabChange} mt={4}>
            <TabList className='tabs_list'>
              {tabNames?.map((item, index) => {
                return (
                  <Tab key={index}>{item.name}</Tab>
                )
              })}
            </TabList>
            <TabPanels>
              {tabNames.map((item, index) => {
                return (
                  <TabPanel key={index} className='tab_panel'>
                    {item.name === 'Active Loans' && <MultipleEmiActiveLoans reportData={activeMulipleEmiReport} handleDownloadReport={handleDownloadReport} />}
                    {item.name === 'Closed Loans' && <MultipleEmiClosedLoans reportData={closedMultipleEmiPayment} handleDownloadReport={handleDownloadReport} />}
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs> */}
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(MultipleEmiPayment)