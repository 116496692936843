import React, { useEffect, useState } from 'react'
import { Box, Heading, Image } from '@chakra-ui/react'
import Lottie from "lottie-react";
import InvestorReturnAnimation from "../../../../../assets/lottiFiles/investorReturn.json";
import FlowerIcon from '../../../../../assets/images/flower.svg'
import CustomButton from '../../../../elements/customButton';

export default function InvestorReturn(props) {

    const [showAnimation, setShowAnimation] = useState(true)

    useEffect(() => {
        const timer = setInterval(() => {
            setShowAnimation(prevState => !prevState)
        }, 3000)

        return () => clearInterval(timer);
    }, [])

  return (
    <Box className='chart_box bg_shadow' mt={4}>
        <Heading as="h2" className='font-poppins card_heading'>One Click Invest</Heading>
        <Box className='return_card' mt={2}>
            <Box className='return_card_title'>
                <Heading as="h3" className='font-poppins'>Earn 25% Per Annum</Heading>
            </Box>
            <Box className='return_card_body'>
                <Box className='return_card_data'>
                    <Heading as="h6" className='font-poppins'>Investment Amount</Heading>
                    <Heading as="p" className='font-poppins'>Starting from ₹1,000</Heading>
                </Box>
                <Box className='return_card_data'>
                    <Heading as="h6" className='font-poppins'>Period</Heading>
                    <Heading as="p" className='font-poppins'>3 Days to 3 Years</Heading>
                </Box>
                <Box mt={4}>
                    {props.isKYCCompleted ?
                        <CustomButton size="md" type="submit" title="Invest Now" className="font-poppins btn_theme w-100" onClick={props.handlePreferenceDialog} /> :
                        <CustomButton size="md" type="submit" title="Update KYC" className="font-poppins btn_theme w-100" onClick={props.handleUpdateKYC} />
                    }
                </Box>
            </Box>
            <Box className={showAnimation ? 'return_image' : 'return_image_static'}>
                {showAnimation ? 
                    <Lottie animationData={InvestorReturnAnimation} loop={false} /> :
                    <Image src={FlowerIcon} />
                }
            </Box>
        </Box>
    </Box>
  )
}
