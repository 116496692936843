import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import CustomToast from '../../../elements/customToast'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import { bindActionCreators } from 'redux'
import decode from 'jwt-decode';
import { ActionCreators } from '../../../../redux/actions'
import { PINE_PG } from '../../../../constants/Constant'
import { getItem } from '../../../../utilities/authUtils'

const OnBoardFeePayment = (props) => {

  const {addToast} = CustomToast()
  const kycState = useSelector((state) => state.kycState)
  const cpState = useSelector((state) => state.cpState)
  const [paymentMethod, setPaymentMethod] = useState(null)

  useEffect(() => {
    const script = document.createElement("script");
    script.src = PINE_PG;
    script.async = true;
    document.body.appendChild(script);
    props?.actions?.getPaymentMethodsAction()
  }, [])

  useEffect(() => {
    if (kycState?.getPaymentMethods?.data?.success === true) {
      setPaymentMethod(kycState?.getPaymentMethods?.data?.data?.filter((item) => item.id === 2)[0])
    }
  }, [kycState?.getPaymentMethods])

  useEffect(() => {
    if (cpState?.generateCPOrder?.data?.success === true){
      openPaymentGateway(cpState?.generateCPOrder?.data?.response?.token)
    }
  }, [cpState?.generateCPOrder])

  useEffect(() => {
    if (cpState?.getCPPaymentStatus?.data?.success === true){
      let payData = {
        order_id: cpState?.getCPPaymentStatus?.data?.response?.merchant_data?.order_id,
        bene_full_name: cpState?.getCPPaymentStatus?.data?.response?.payment_info_data?.card_holder_name,
        transfer_type: cpState?.getCPPaymentStatus?.data?.response?.payment_info_data.payment_mode,
        plural_order_id: cpState?.getCPPaymentStatus?.data?.response?.order_data?.plural_order_id,
        payment_id: cpState?.getCPPaymentStatus?.data?.response?.payment_info_data?.payment_id,
        payment_method: paymentMethod?.id,
        status: cpState?.getCPPaymentStatus?.data?.response?.order_data?.order_status === 'FAILED' ? '1' : '2',
        mobile: props?.feeData?.mobile,
      }
      props.actions.updateCPOrderStatusAction(payData)
    }
  }, [cpState?.getCPPaymentStatus])

  useEffect(() => {
    if (cpState?.updateCPOrderStatus?.data?.success === true){
      addToast({message: 'Payment Done Successfully', status: 'success'})
      props?.actions?.getCPUserDetailsByIdAction({user_id: props?.feeData?.id})
      props.onClick()
    }
  }, [cpState?.updateCPOrderStatus])

  const openPaymentGateway = (orderToken) => {
    const decodeData = decode(getItem('id_token'))
    const options = {
      theme: 'black',
      orderToken: orderToken,
      channelId: 'WEB',
      paymentMode: 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI',
      countryCode: "91",
      mobileNumber: decodeData?.mobile, // type = string, default = null
      emailId: decodeData?.email, //default null
      showSavedCardsFeature: false, // type = boolean, default = true
      successHandler: async function(response) {
          checkpaymentStatus(response)
      },
      failedHandler: async function(response) {
          if(response.error_code !== "4010"){
            checkpaymentStatus(response)
          }
        }
    };
    const plural = new window.Plural(options);
    plural.open(options);
  };

  const checkpaymentStatus = (resp) => {
    if(resp.payment_id) {
      let data = {
        plural_order_id: resp.plural_order_id,
        payment_id: resp.payment_id,
        payment_method: paymentMethod?.id,
        type: 1,
        mobile: props?.feeData?.mobile,
      };
      props.actions.getCPPaymentStatusAction(data);
    }
  };

  const handlePay = () => {
    if (!props?.feeData?.personal_data) {
      addToast({message: 'Please Complete Personal Details First', status: 'error'})
      return
    }
    if (!props?.feeData?.bank_data) {
      addToast({message: 'Please Complete Bank Details First', status: 'error'})
      return
    }
    if (!props?.feeData?.document_data) {
      addToast({message: 'Please Upload Documents First', status: 'error'})
      return
    }
    let orderData = {
      amount: props?.feeData?.total_fee_amount * 100,
      currency: "INR",
      desc: "Registration Fees",
      product_code: "1",
      payment_method: paymentMethod?.id,
      type: "1",
      mobile: props?.feeData?.mobile,
    };
    props.actions.generateCPOrderAction(orderData);
  }

  const handleNext = () => {
    props.onClick()
  }

  return (
    <>
      {(cpState?.getCPUserDetailsById?.loading || cpState?.generateCPOrder?.loading || cpState?.getCPPaymentStatus?.loading || cpState?.updateCPOrderStatus?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className='main_Kyc_form_container'>
        <Grid className="grid_container">
          <GridItem>
            <Heading as="h1" className="font-poppins text_subheading">To verify your details and to perform a credit underwriting, we charge a nominal registration amount. Please make this payment in order to proceed further and finish your registration process.</Heading>
            <Box mb={3} className='form_kyc_cp'>
              <Box className='table_wrapper'>
                <TableContainer>
                  <Table variant='simple'>
                    <Tbody>
                      <Tr>
                        <Td>Registration Fee</Td>
                        <Td>INR {props?.feeData?.registration_fee}</Td>
                      </Tr>
                      <Tr>
                        <Td>SGST (9%)</Td>
                        <Td>INR {props?.feeData?.sgst_fee}</Td>
                      </Tr>
                      <Tr>
                        <Td>CGST (9%)</Td>
                        <Td>INR {props?.feeData?.cgst_fee}</Td>
                      </Tr>
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>Total Amount to be paid</Th>
                        <Th>INR {props?.feeData?.total_fee_amount}</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </GridItem>
        </Grid>
        <Box className="kyc_actions">
          {props?.feeData?.fee_data?.transaction_status === '2' ?
            // <Box className='d-flex col-gap-15'>
            //   <CustomButton size="md" type="submit" title={'Paid ₹ ' + props?.feeData?.total_fee_amount} className="btn_green font-poppins" isDisabled={true} />
            //   <CustomButton size="md" type="submit" title="Next" className="btn_green font-poppins" onClick={handleNext} />
            // </Box> :
            <CustomButton size="md" type="submit" title={'Paid ₹ ' + props?.feeData?.total_fee_amount} className="btn_green font-poppins" isDisabled={true} /> :
            <CustomButton size="md" type="submit" title={'Pay Now ₹ ' + props?.feeData?.total_fee_amount} className="btn_green font-poppins" onClick={handlePay} />
          }
        </Box>
      </Box>
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(OnBoardFeePayment)