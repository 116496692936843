import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import NavBar from '../../../elements/NavBar'
import { removeAll } from '../../../../utilities/authUtils'
import {useHistory, Link} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import Pagination from '../../../elements/Pagination'
import ClosedLoanSidebar from './components/ClosedLoanSidebar'
import ViewMoreModal from './components/ViewMoreModal'
import { downloadDocuments } from '../../../../utilities/DownloadDocuments'
import moment from 'moment'
import LoanFilters from './components/LoanFilters'
import NoData from '../../../elements/NoData'
import options from '../../../../assets/JsonData/borrowFilterData.json'
import ImportantDialog from '../../../elements/ImportantDialog'

const ClosedLoan = (props) => {

    const history = useHistory()
    const masterState = useSelector((state) => state.masterState)
    const borrowerState = useSelector((state) => state.borrowerState)
    const [currentPage, setCurrentPage] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false)
    const [openViewMore, setOpenViewMore] = useState(false)
    const [sidebarData, setSidebarData] = useState(null)
    const [openFilter, setOpenFilter] = useState(false)
    const [openSorting, setOpenSorting] = useState(false)
    const [filterData, setFilterData] = useState([])
    const [sortingData, setSortingData] = useState('')
    const [filterLoanListData, setFilterLoanListData] = useState ([])
    const [sortOrder, setSortOrder] = useState('desc')

    const userData = masterState?.getCurrentUserDetails?.data?.data
    const loanListData = borrowerState?.getLoanListDetails?.data?.data
    const ammortizationScheduleData = borrowerState?.getAmmortizationSchedule?.data?.data
    const paymentTransactionData = borrowerState?.getLoanPaymentTransaction?.data?.data
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(loanListData?.length / PER_PAGE);

    let loanData = {
        status: '8',
    }
  
    useEffect(() => {
      props.actions.getCurrentUserDetailsAction()
      props.actions.getLoanListDetailsAction(loanData)
    }, []);

    useEffect(() => {
        setFilterLoanListData(borrowerState?.getLoanListDetails?.data?.data)
    }, [borrowerState?.getLoanListDetails])

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    }

    const handleSearchName = (e) => {
        const {value} = e.target
        if (value !== '') {
          const result = loanListData.filter((item) => {
            return item?.loan_number?.toLowerCase().includes(value.toLowerCase()) || item?.loan_name?.toLowerCase().includes(value.toLowerCase())
          })
          setFilterLoanListData(result)
        } else {
            setFilterLoanListData(loanListData)
        }
      }

    const handleOpenSidebar = (item) => {
        const data = loanListData?.find((ele) => ele.loan_number === item)
        setSidebarData(data)
        setOpenSidebar(true)
    }

    const handleCloseSidebar = () => {
        setSidebarData(null)
        setOpenSidebar(false)
    }

    const handleViewMoreDialogOpen = (loanContractId) => {
        setOpenViewMore(true)
        props.actions.getAmmortizationScheduleAction({loan_contract_id: loanContractId})
        props.actions.getLoanPaymentTransactionAction({loan_contract_id: loanContractId})
    }

    const handleViewMoreDialogClose = () => {
        setOpenViewMore(false)
    }

    const handleDownloadAgreement = (url) => {
        downloadDocuments(url, 'agreement')
    }

    const handleDropdown = (type) => {
        if (type === 'sort'){
            setOpenSorting(!openSorting)
            setOpenFilter(false)
        } else if (type === 'filter'){
            setOpenFilter(!openFilter)
            setOpenSorting(false)
        } else {
            setOpenFilter(!openFilter)
            setOpenSorting(!openSorting)
        }
    }
    
    const handleSelectSorting = (item) => {
        setSortingData(item)
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
        setSortOrder(newSortOrder);
        let sortedData = [...filterLoanListData].sort((a, b) => {
            let valueA, valueB;
            if (item === 'Loan Amount') {
                valueA = a.loanContract[0]?.pledged_amount || 0;
                valueB = b.loanContract[0]?.pledged_amount || 0;
            } else if (item === 'Tenure') {
                valueA = a.tenure || 0;
                valueB = b.tenure || 0;
            } else if (item === 'Interest Rate p.a.') {
                valueA = a.interest_rate || 0;
                valueB = b.interest_rate || 0;
            }
            // Apply sorting order
            if (sortOrder === 'asc') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
        setFilterLoanListData(sortedData)
    }

    const handleSelectFilter = (e, val) => {
        if (e.target.checked) {
            setFilterData([...filterData, val]);
        } else {
            setFilterData(filterData.filter((item) => item.id !== val.id));
        }
    }

    const handleApplyFilters = () => {
        let updatedList = loanListData.filter((loan) => {
            return filterData.some((filter) => {
                return loan.loan_name === filter.name;
            })
        })
        setFilterLoanListData(updatedList);
    }

    const handleClose = (type) => {
        if (type === 'sort'){
            setOpenSorting(false)
        }
         if (type === 'filter'){
            setOpenFilter(false)
        }
    }

    const handleReset = () => {
        setFilterData([])
        setFilterLoanListData(loanListData)
    }
  
    const handleProfile = () => {
      history.push(routesNames.BORROWERPROFILE)
    }
  
    // const handleLogout = () => {
    //   removeAll()
    //   history.push(routesNames.LOGIN)
    // }
  
    return (
      <>
        {(masterState?.getCurrentUserDetails?.loading || borrowerState?.getLoanListDetails?.loading) &&
          <div>
              <Loader />
          </div>
        }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
              <GridItem colSpan={8}>
                <Box className='d-flex align-items-center justify-content-between'>
                    <Heading as="h2" className='font-poppins page_heading'>Closed Loan</Heading>
                    <ImportantDialog />
                </Box>
              </GridItem>
              <GridItem colSpan={4}>
                  <NavBar data={userData} handleProfile={handleProfile} />
              </GridItem>
            </Grid>
            <Grid className="main_grid_container">
              <GridItem colSpan={12}>
                <LoanFilters sortOptions={options.sortOptions} filterOptions={options.filterOptions} filterData={filterData}
                    handleDropdown={handleDropdown} handleClose={handleClose} handleSelectFilter={handleSelectFilter}
                    handleReset={handleReset} openFilter={openFilter} openSorting={openSorting} sortingData={sortingData}
                    handleSelectSorting={handleSelectSorting} handleSearchName={handleSearchName} handleApplyFilters={handleApplyFilters}
                    sortOrder={sortOrder}
                />
                <>
                    {filterLoanListData?.length > 0 ?
                        <Box mt={3} className='custom_table_wrapper'>
                            <TableContainer>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Loan Application ID </Th>
                                            <Th>Loan Type</Th>
                                            <Th>Loan Amount</Th>
                                            <Th>Closed Date</Th>
                                            <Th>Repaid Amount</Th>
                                            <Th>Tenure</Th>
                                            <Th>Interest Rate</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {filterLoanListData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                            return (
                                                <Tr key={index}>
                                                    <Td>
                                                        <Text className="font-poppins table_text">{item.loan_number}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className={item.product_id === 3 ? "font-poppins table_text loan_insta" : 
                                                            item.product_id === 1 ? "font-poppins table_text loan_personal" : 
                                                            item.product_id === 2 ? "font-poppins table_text loan_bullet" : 
                                                            "font-poppins table_text loan_bullet"}>
                                                            {item.loan_name}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className="font-poppins table_text">₹ {item?.loanContract?.length > 0 ? item?.loanContract[0]?.pledged_amount?.toFixed(2) : '0'}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className="font-poppins table_text">{item?.closed_date && moment(item?.closed_date).format("DD-MM-YYYY")}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className="font-poppins table_text">₹ {item?.loanContract?.length > 0 ? item?.loanContract[0]?.payment_amount : '0'}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className="font-poppins table_text">{item.tenure_duration}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text className="font-poppins table_text">{item.product_id === 3 ? "0.2% Per Day" : item.interest_rate ? item.interest_rate + "% Per Annum" : ''}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Box className='actions_td action_table'>
                                                            <IconButton onClick={() => handleOpenSidebar(item.loan_number)}><ChevronRightIcon /></IconButton>
                                                        </Box>
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                        </Box> :
                        <NoData title="No Record Found" />
                    }
                </>
              </GridItem>
            </Grid>
        </Box>
        <ClosedLoanSidebar
            openSidebar={openSidebar}
            handleOpenSidebar={handleOpenSidebar}
            handleCloseSidebar={handleCloseSidebar}
            handleViewMoreDialogOpen={handleViewMoreDialogOpen}
            data={sidebarData}
            handleDownloadAgreement={handleDownloadAgreement}
        />
        <ViewMoreModal
            openViewMore={openViewMore}
            handleViewMoreDialogOpen={handleViewMoreDialogOpen}
            handleViewMoreDialogClose={handleViewMoreDialogClose}
            ammortizationScheduleData={ammortizationScheduleData}
            paymentTransactionData={paymentTransactionData}
            disbursalData={sidebarData}
        />
      </>
    )
  }
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(ClosedLoan)
