import { Box, Grid, GridItem, Heading } from '@chakra-ui/react'
import React from 'react'

export default function PortfolioDetails(props) {

    const data = props?.portfolioData

  return (
    <Box className='investor_chart' mt={4}>
        <Heading as="h2" className='font-poppins block_heading'>Portfolio Details</Heading>
        <Grid className="main_grid_container" mt={4} mb={4}>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Amount Invested till date</Heading>
                    <Heading as="h4" className='font-poppins'>{data?.total_amount_invested_till_date ? '₹' + data?.total_amount_invested_till_date?.toFixed(2) : "₹0.00"}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Number of loans invested in till date</Heading>
                    <Heading as="h4" className='font-poppins'>{data?.total_investment_count}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Principal Outstanding as on date</Heading>
                    <Heading as="h4" className='font-poppins'>{'₹' + data?.total_principal_outstanding_till_date?.toFixed(2)}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Interest Received till date</Heading>
                    <Heading as="h4" className='font-poppins'>{'₹' + data?.total_interest_recieved_till_date?.toFixed(2)}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Principal Received till date </Heading>
                    <Heading as="h4" className='font-poppins'>{'₹' + data?.total_principal_recieved_till_date?.toFixed(2)}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Interest Delayed till date </Heading>
                    <Heading as="h4" className='font-poppins'>{data?.total_interest_delayed_till_date? '₹' + data?.total_interest_delayed_till_date?.toFixed(2) : "₹0.00"}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total Principal Delayed till date</Heading>
                    <Heading as="h4" className='font-poppins'>{data?.total_principal_delayed_till_date?.toFixed(2)? '₹' + data?.total_principal_delayed_till_date?.toFixed(2) : "₹0.00"}</Heading>
                </Box>
            </GridItem>
            <GridItem colSpan={6}>
                <Box className='invest_detail_card_data'>
                    <Heading as="h3" className='font-poppins'>Total other income received till date</Heading>
                    <Heading as="h4" className='font-poppins'>{data?.total_other_income_recieved_till_date?.toFixed(2) ? '₹' + data?.total_other_income_recieved_till_date?.toFixed(2) : "₹0.00"}</Heading>
                </Box>
            </GridItem>
        </Grid>
    </Box>
  )
}
