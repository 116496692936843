import * as reportsServices from '../../services/reportsServices'
import * as actionTypes from './actionsTypes'

//actions for get registration report
const getRegistrationReportRequest = () => {
    return {
      type: actionTypes.GET_REGISTRATION_REPORT_REQUEST,
    }
  }
  
  const getRegistrationReportSuccess = (data) => {
    return {
      type: actionTypes.GET_REGISTRATION_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getRegistrationReportFailure = (error) => {
    return {
      type: actionTypes.GET_REGISTRATION_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getRegistrationReport action
  export const getRegistrationReportAction = (payload) =>
    async(dispatch) => {
      dispatch(getRegistrationReportRequest())
      try {
        const responseData = await reportsServices.getRegistrationReport(payload)
        if (responseData?.status === 200) {
          dispatch(getRegistrationReportSuccess(responseData?.data))
        } else {
          dispatch(getRegistrationReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getRegistrationReportFailure(error?.response?.data))
      }
  }

//actions for get DCR report
const getDCRReportRequest = () => {
    return {
      type: actionTypes.GET_DCR_REPORT_REQUEST,
    }
  }
  
  const getDCRReportSuccess = (data) => {
    return {
      type: actionTypes.GET_DCR_REPORT_SUCCESS,
      payload: data,
    }
  }
  
  const getDCRReportFailure = (error) => {
    return {
      type: actionTypes.GET_DCR_REPORT_FAILURE,
      payload: error,
    }
  }
  
  //getDCRReport action
  export const getDCRReportAction = (payload) =>
    async(dispatch) => {
      dispatch(getDCRReportRequest())
      try {
        const responseData = await reportsServices.getDCRReport(payload)
        if (responseData?.status === 200) {
          dispatch(getDCRReportSuccess(responseData?.data))
        } else {
          dispatch(getDCRReportFailure(responseData.errors))
        }
      } catch (error) {
        dispatch(getDCRReportFailure(error?.response?.data))
      }
  }

//actions for get active one shot payment
const getActiveOneShotPaymentRequest = () => {
  return {
    type: actionTypes.ACTIVE_ONE_SHOT_PAYMENT_REQUEST,
  }
}

const getActiveOneShotPaymentSuccess = (data) => {
  return {
    type: actionTypes.ACTIVE_ONE_SHOT_PAYMENT_SUCCESS,
    payload: data,
  }
}

const getActiveOneShotPaymentFailure = (error) => {
  return {
    type: actionTypes.ACTIVE_ONE_SHOT_PAYMENT_FAILURE,
    payload: error,
  }
}

//getActiveOneShotPayment action
export const getActiveOneShotPaymentAction = (payload) =>
  async(dispatch) => {
    dispatch(getActiveOneShotPaymentRequest())
    try {
      const responseData = await reportsServices.activeOneShotPayment(payload)
      if (responseData?.status === 200) {
        dispatch(getActiveOneShotPaymentSuccess(responseData?.data))
      } else {
        dispatch(getActiveOneShotPaymentFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getActiveOneShotPaymentFailure(error?.response?.data))
    }
}

//actions for get closed one shot payment
const getClosedOneShotPaymentRequest = () => {
  return {
    type: actionTypes.CLOSED_ONE_SHOT_PAYMENT_REQUEST,
  }
}

const getClosedOneShotPaymentSuccess = (data) => {
  return {
    type: actionTypes.CLOSED_ONE_SHOT_PAYMENT_SUCCESS,
    payload: data,
  }
}

const getClosedOneShotPaymentFailure = (error) => {
  return {
    type: actionTypes.CLOSED_ONE_SHOT_PAYMENT_FAILURE,
    payload: error,
  }
}

//getClosedOneShotPayment action
export const getClosedOneShotPaymentAction = (payload) =>
  async(dispatch) => {
    dispatch(getClosedOneShotPaymentRequest())
    try {
      const responseData = await reportsServices.closedOneShotPayment(payload)
      if (responseData?.status === 200) {
        dispatch(getClosedOneShotPaymentSuccess(responseData?.data))
      } else {
        dispatch(getClosedOneShotPaymentFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getClosedOneShotPaymentFailure(error?.response?.data))
    }
}

//actions for get active multiple emi payment
const getActiveMultipleEmiPaymentRequest = () => {
  return {
    type: actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_REQUEST,
  }
}

const getActiveMultipleEmiPaymentSuccess = (data) => {
  return {
    type: actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_SUCCESS,
    payload: data,
  }
}

const getActiveMultipleEmiPaymentFailure = (error) => {
  return {
    type: actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_FAILURE,
    payload: error,
  }
}

//getActiveMultipleEmiPayment action
export const getActiveMultipleEmiPaymentAction = (payload) =>
  async(dispatch) => {
    dispatch(getActiveMultipleEmiPaymentRequest())
    try {
      const responseData = await reportsServices.activeMultipleEmiPayment(payload)
      if (responseData?.status === 200) {
        dispatch(getActiveMultipleEmiPaymentSuccess(responseData?.data))
      } else {
        dispatch(getActiveMultipleEmiPaymentFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getActiveMultipleEmiPaymentFailure(error?.response?.data))
    }
}

//actions for get closed multiple emi payment
const getClosedMultipleEmiPaymentRequest = () => {
  return {
    type: actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_REQUEST,
  }
}

const getClosedMultipleEmiPaymentSuccess = (data) => {
  return {
    type: actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_SUCCESS,
    payload: data,
  }
}

const getClosedMultipleEmiPaymentFailure = (error) => {
  return {
    type: actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_FAILURE,
    payload: error,
  }
}

//getClosedMultipleEmiPayment action
export const getClosedMultipleEmiPaymentAction = (payload) =>
  async(dispatch) => {
    dispatch(getClosedMultipleEmiPaymentRequest())
    try {
      const responseData = await reportsServices.closedMultipleEmiPayment(payload)
      if (responseData?.status === 200) {
        dispatch(getClosedMultipleEmiPaymentSuccess(responseData?.data))
      } else {
        dispatch(getClosedMultipleEmiPaymentFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getClosedMultipleEmiPaymentFailure(error?.response?.data))
    }
}

const getCKYCReportRequest = () => {
  return {
    type: actionTypes.GET_CKYC_REPORT_REQUEST,
  }
}

const getCKYCReportSuccess = (data) => {
  return {
    type: actionTypes.GET_CKYC_REPORT_SUCCESS,
    payload: data,
  }
}

const getCKYCReportFailure = (error) => {
  return {
    type: actionTypes.GET_CKYC_REPORT_FAILURE,
    payload: error,
  }
}

//getCKYCReport action
export const getCKYCReportAction = (payload) =>
  async(dispatch) => {
    dispatch(getCKYCReportRequest())
    try {
      const responseData = await reportsServices.getCKYCReport(payload)
      if (responseData?.status === 200) {
        dispatch(getCKYCReportSuccess(responseData?.data))
      } else {
        dispatch(getCKYCReportFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getCKYCReportFailure(error?.response?.data))
    }
}