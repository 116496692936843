import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
    getUserDashboardDetails: {
      data: null,
      loading: false,
      error: null,
    },
    getBorrowerDetails: {
      data: null,
      loading: false,
      error: null,
    },
    getProductList: {
      data: null,
      loading: false,
      error: null,
    },
    applyLoan: {
      data: null,
      loading: false,
      error: null,
    },
    getLoanListDetails: {
      data: null,
      loading: false,
      error: null,
    },
    getAmmortizationSchedule: {
      data: null,
      loading: false,
      error: null,
    },
    getLoanPaymentTransaction: {
      data: null,
      loading: false,
      error: null,
    },
    getLoanListStatus: {
      data: null,
      loading: false,
      error: null,
    },
    getBorrowerCreditData: {
      data: null,
      loading: false,
      error: null,
    },
    createESignRequest: {
      data: null,
      loading: false,
      error: null,
    },
    acceptLoanEligibility: {
      data: null,
      loading: false,
      error: null,
    },
    withdrawnLoanEligibility: {
      data: null,
      loading: false,
      error: null,
    },
    trackLoanJourney: {
      data: null,
      loading: false,
      error: null,
    }
  }

  const borrowerReducers = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case actionTypes.GET_USER_DASHBOARD_DETAILS_REQUEST:
        return {
          ...state,
          getUserDashboardDetails: {
            ...state.getUserDashboardDetails,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_USER_DASHBOARD_DETAILS_SUCCESS:
        return {
          ...state,
          getUserDashboardDetails: {
            ...state.getUserDashboardDetails,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_USER_DASHBOARD_DETAILS_FAILURE:
        return {
          ...state,
          getUserDashboardDetails: {
            ...state.getUserDashboardDetails,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_BORROWER_DETAILS_REQUEST:
        return {
          ...state,
          getBorrowerDetails: {
            ...state.getBorrowerDetails,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_BORROWER_DETAILS_SUCCESS:
        return {
          ...state,
          getBorrowerDetails: {
            ...state.getBorrowerDetails,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_BORROWER_DETAILS_FAILURE:
        return {
          ...state,
          getBorrowerDetails: {
            ...state.getBorrowerDetails,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_PRODUCT_LIST_REQUEST:
        return {
          ...state,
          getProductList: {
            ...state.getProductList,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_PRODUCT_LIST_SUCCESS:
        return {
          ...state,
          getProductList: {
            ...state.getProductList,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_PRODUCT_LIST_FAILURE:
        return {
          ...state,
          getProductList: {
            ...state.getProductList,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.APPLY_LOAN_REQUEST:
        return {
          ...state,
          applyLoan: {
            ...state.applyLoan,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.APPLY_LOAN_SUCCESS:
        return {
          ...state,
          applyLoan: {
            ...state.applyLoan,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.APPLY_LOAN_FAILURE:
        return {
          ...state,
          applyLoan: {
            ...state.applyLoan,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_LOAN_LIST_DETAILS_REQUEST:
        return {
          ...state,
          getLoanListDetails: {
            ...state.getLoanListDetails,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_LIST_DETAILS_SUCCESS:
        return {
          ...state,
          getLoanListDetails: {
            ...state.getLoanListDetails,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_LIST_DETAILS_FAILURE:
        return {
          ...state,
          getLoanListDetails: {
            ...state.getLoanListDetails,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_AMMORTIZATION_SCHEDULE_REQUEST:
        return {
          ...state,
          getAmmortizationSchedule: {
            ...state.getAmmortizationSchedule,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_AMMORTIZATION_SCHEDULE_SUCCESS:
        return {
          ...state,
          getAmmortizationSchedule: {
            ...state.getAmmortizationSchedule,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_AMMORTIZATION_SCHEDULE_FAILURE:
        return {
          ...state,
          getAmmortizationSchedule: {
            ...state.getAmmortizationSchedule,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_LOAN_PAYMENT_TRANSACTION_REQUEST:
        return {
          ...state,
          getLoanPaymentTransaction: {
            ...state.getLoanPaymentTransaction,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_PAYMENT_TRANSACTION_SUCCESS:
        return {
          ...state,
          getLoanPaymentTransaction: {
            ...state.getLoanPaymentTransaction,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_PAYMENT_TRANSACTION_FAILURE:
        return {
          ...state,
          getLoanPaymentTransaction: {
            ...state.getLoanPaymentTransaction,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_LOAN_LIST_STATUS_REQUEST:
        return {
          ...state,
          getLoanListStatus: {
            ...state.getLoanListStatus,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_LIST_STATUS_SUCCESS:
        return {
          ...state,
          getLoanListStatus: {
            ...state.getLoanListStatus,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_LOAN_LIST_STATUS_FAILURE:
        return {
          ...state,
          getLoanListStatus: {
            ...state.getLoanListStatus,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_BORROWER_CREDIT_DATA_REQUEST:
        return {
          ...state,
          getBorrowerCreditData: {
            ...state.getBorrowerCreditData,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_BORROWER_CREDIT_DATA_SUCCESS:
        return {
          ...state,
          getBorrowerCreditData: {
            ...state.getBorrowerCreditData,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_BORROWER_CREDIT_DATA_FAILURE:
        return {
          ...state,
          getBorrowerCreditData: {
            ...state.getBorrowerCreditData,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.CREATE_ESIGN_REQUEST:
        return {
          ...state,
          createESignRequest: {
            ...state.createESignRequest,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.CREATE_ESIGN_SUCCESS:
        return {
          ...state,
          createESignRequest: {
            ...state.createESignRequest,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.CREATE_ESIGN_FAILURE:
        return {
          ...state,
          createESignRequest: {
            ...state.createESignRequest,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.ACCEPT_LOAN_ELIGIBILITY_REQUEST:
        return {
          ...state,
          acceptLoanEligibility: {
            ...state.acceptLoanEligibility,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.ACCEPT_LOAN_ELIGIBILITY_SUCCESS:
        return {
          ...state,
          acceptLoanEligibility: {
            ...state.acceptLoanEligibility,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.ACCEPT_LOAN_ELIGIBILITY_FAILURE:
        return {
          ...state,
          acceptLoanEligibility: {
            ...state.acceptLoanEligibility,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_REQUEST:
        return {
          ...state,
          withdrawnLoanEligibility: {
            ...state.withdrawnLoanEligibility,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_SUCCESS:
        return {
          ...state,
          withdrawnLoanEligibility: {
            ...state.withdrawnLoanEligibility,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.WITHDRAWN_LOAN_ELIGIBILITY_FAILURE:
        return {
          ...state,
          withdrawnLoanEligibility: {
            ...state.withdrawnLoanEligibility,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
        case actionTypes.GET_TRACK_LOAN_JOURNEY_REQUEST:
          return {
            ...state,
            trackLoanJourney: {
              ...state.trackLoanJourney,
              data: null,
              loading: true,
              error: null,
            },
          }
        case actionTypes.GET_TRACK_LOAN_JOURNEY_SUCCESS:
          return {
            ...state,
            trackLoanJourney: {
              ...state.trackLoanJourney,
              data: action.payload,
              loading: false,
              error: null,
            },
          }
        case actionTypes.GET_TRACK_LOAN_JOURNEY_FAILURE:
          return {
            ...state,
            trackLoanJourney: {
              ...state.trackLoanJourney,
              data: null,
              loading: false,
              error: action.payload,
            },
          }
        case actionTypes.CLEANUP_ESIGN_DATA:
          return {
            createESignRequest: {
              data: null,
              loading: false,
              error: null,
            },
          }
        case actionTypes.CLEANUP_TOAST:
          return {
            ...state,
            applyLoan: {
              data: null,
              loading: false,
              error: null,
            },
            withdrawnLoanEligibility: {
              data: null,
              loading: false,
              error: null,
            },
            acceptLoanEligibility: {
              data: null,
              loading: false,
              error: null,
            }
          }
      default:
        return state
    }
  }
  
  export default borrowerReducers