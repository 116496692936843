import { Box, Heading, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../redux/actions';
import LockIcon from '../../../assets/images/lock.svg'
import ReEnterPin from './ReEnterPin';

const SetPin = (props) => {

    const [mpin, setMpin] = useState(['', '', '', ''])
    const [openReEnter, setOpenReEnter] = useState(false)
    const mpinRef = useRef([])

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
            const newMpin = [...mpin]
            newMpin[index] = value
            setMpin(newMpin)
            if (index < 3) {
                mpinRef.current[index + 1].focus()
            } else if (index === 3) {
                setOpenReEnter(true)
                props.handleCloseSetPin()
                setMpin(['', '', '', ''])
            }
        } else if (value === '') {
            const newMpin = [...mpin]
            newMpin[index] = value
            setMpin(newMpin)
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !mpin[index] && index > 0) {
            mpinRef.current[index - 1].focus()
        }
    }

    const handleCloseSetPin = () => {
        props.handleCloseSetPin()
        setMpin(['', '', '', ''])
    }

    const handleCloseReEnter = () => {
        setOpenReEnter(false)
    }

    const PopupBox = () => (
        <Box>
            <ModalOverlay />
            <ModalContent className='delete_modal_content'>
                <ModalCloseButton />
                <ModalBody className='delete_modal_body' pb={5} pt={6}>
                    <Box className='delete_body'>
                        <Heading as="h4" className='font-poppins'>Change ALPHAMONEY PIN</Heading>
                        <Box className='pin_section' mt={10} mb={12}>
                            <Box className='pin_input_block'>
                                {mpin.map((value, index) => (
                                    <Input
                                        key={index}
                                        ref={(el) => (mpinRef.current[index] = el)}
                                        value={value}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        textAlign="center"
                                        type="password"
                                        maxLength={1}
                                        className='font-poppins input_pin'
                                    />
                                ))}
                            </Box>
                            <Text className='font-poppins'>Please Enter ALPHAMONEY Pin here</Text>
                        </Box>
                        <Heading as="h3" className='font-poppins'>This keep your account Secure <Image src={LockIcon} /> </Heading>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <>
        <Modal
            isCentered
            onClose={handleCloseSetPin}
            isOpen={props.openSetPin}
            motionPreset="slideInBottom"
            // size={'xl'}
        >
        {PopupBox()}
        </Modal>
        <ReEnterPin openReEnter={openReEnter} handleCloseReEnter={handleCloseReEnter} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
export default connect(null, mapDispatchToProps)(SetPin)