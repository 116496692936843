import React from 'react'
import { Box, Heading, Select } from '@chakra-ui/react'
import { Doughnut } from 'react-chartjs-2';
import CustomButton from '../../../../elements/customButton';

export default function InvesterLoanChart(props) {
  return (
    <Box className='chart_box bg_shadow' mt={4}>
        <Heading as="h2" className='font-poppins card_heading'>Investment Diversification</Heading>
        <Box className='chart_box_inner'>
          <Doughnut data={props.data} options={props.options} width={240} />
          <Box className='chart_label'>
        </Box>
        <CustomButton size="md" type="submit" title="Portfolio" className="font-poppins btn_theme w-100" onClick={props.handlePortfolio} />
        </Box>
    </Box>
  )
}
