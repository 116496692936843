import React from 'react'
import { Box, Heading, Image, Progress } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CustomButton from '../../../../elements/customButton';
import PayIcon from '../../../../../assets/images/pay.svg'
import moment from 'moment';

function CustomArrow({ onClick, direction }) {

    const icon = direction === "prev" ? <ChevronLeftIcon /> : <ChevronRightIcon />; // Custom arrow icons
    const arrowStyle = {
        cursor: "pointer",
        fontSize: "30px",
        lineHeight: "30px",
      };
  
    return (
      <Box
        className={`arrow-${direction}`}
        onClick={onClick}
        style={arrowStyle}
      >
        {icon}
      </Box>
    );
  }

export default function UpcomingRepayments(props) {

    const upcomingData = props?.portfolioData?.upcoming_repayments

    console.log(upcomingData)

    const productClassMap = {
        'Insta Loan': 'cardThree',
        'Personal Loan': 'cardOne',
        'Bullet Loan': 'cardTwo',
    };

  return (
    <Box className='slider_box bg_shadow position-relative' mt={4}>
        <Heading as="h2" className='font-poppins card_heading'>Upcoming Repayments </Heading>
        <Box mt={3}>
            <Carousel showArrows={true} showIndicators={false} showStatus={false} autoPlay interval={3000} stopOnHover infiniteLoop
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                <CustomArrow onClick={() => onClickHandler()} direction="prev" />
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                <CustomArrow onClick={() => onClickHandler()} direction="next" />
                )
            }
            >
                {upcomingData?.map((item, index) => {
                    const className = productClassMap[item?.display_loan_name] || 'cardFour';
                    return (
                        <Box key={index} className={`loan_card ${className}`}>
                            <Box className='loan_top'>
                                <Box className='loan_heading'>
                                    <Heading as="h4" className='font-poppins'>{item?.display_loan_name}</Heading>
                                </Box>
                                <Box className='loan_number'>
                                    <Heading as="p" className='font-poppins'>Loan Application Number</Heading>
                                    <Heading as="p" className='font-poppins'>{item?.loan_number}</Heading>
                                </Box>
                            </Box>
                            <Box className='loan_amount'>
                                <Heading as="p" className='font-poppins'>Invested Amount</Heading>
                                <Heading as="h4" className='font-poppins'>₹{item?.pledged_amount}</Heading>
                            </Box>
                            <Box className='repay_amount'>
                                <Box className='loan_number'>
                                    <Heading as="p" className='font-poppins' mb={1}>EMI Repayment Date</Heading>
                                    <Heading as="p" className='font-poppins'>{item?.emi_date ? moment(item?.emi_date).format('D MMMM YYYY') : ''}</Heading>
                                </Box>
                            </Box>
                            <Box className='upcoming_payment' mt={3}>
                                <Heading as="p" className='font-poppins' mb={1}>Total Amount Received <span>₹{item?.received_amount}</span></Heading>
                                <Progress className='custom_progress_repay' bg='#fff' size='sm' value={item?.received_amount} max={item?.pledged_amount} />
                            </Box>
                        </Box>
                    )
                })}
            </Carousel>
        </Box>
    </Box>
  )
}
