import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment';
import Pagination from '../../../../elements/Pagination';
import NoData from '../../../../elements/NoData';
import CustomToast from '../../../../elements/customToast';

export default function EnachDetails(props) {
    const [currentPage, setCurrentPage] = useState(0)
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(props?.filterENachData?.length / PER_PAGE);
    const {addToast} = CustomToast()
    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }
  return (
    <Box mt={1}>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        <>
            {props?.filterENachData?.length > 0 ?
                <Box mt={2} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No.</Th>
                                    <Th>Name</Th>
                                    <Th>CRN NO.</Th>
                                    <Th>Loan Number</Th>
                                    <Th>Loan Amount</Th>
                                    <Th>Loan Date</Th>
                                    <Th>UMRN NO.</Th>
                                    <Th>Phone Number</Th>
                                    <Th>Email Id</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {props?.filterENachData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                    const serialNumber = offset + index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.first_name}{' '}{item?.user_data?.last_name}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.enach_request?.loan_id}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.loan_number}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item?.loan_amount}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.created_at ? moment(item?.created_at).format('DD-MMM-YYYY'): 'Not Available'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.enach_request?.UMRN ? item?.enach_request?.UMRN : 'Not Assigned'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.mobile}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.email}</Text>
                                            </Td>
                                            <Td>
                                                {item?.enach_request ?
                                                    <>
                                                        {item?.enach_request?.status === 1 ?
                                                            <Text className="font-poppins table_text success_enach"> Success E-Enach </Text> :
                                                            <Box className='actions_td'>
                                                            <Text className="font-poppins table_text pending_enach"> Pending For E-Enach </Text>
                                                                <br/>
                                                            <CustomButton size="md" type="submit" title="Cancel Mandate" className="btn_table_dark_red-collection font-poppins" onClick={()=>props.cancel_mandate(item?.id,item?.borrower_id)} />
                                                            </Box>
                                                           
                                                        }
                                                    </>
                                                    :
                                                    <Box className='actions_td'>
                                                        <CustomButton size="md" type="submit" title="Generate E-Nach" className="generate_nach_btn font-poppins" onClick={() => props.handleOpenDetails(item)} isDisabled={props?.userType === '5' || props?.userType === '4'} />
                                                    </Box>
                                                }
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                </Box> :
                <NoData title="No Record Found" />
            }
        </>
    </Box>
  )
}
