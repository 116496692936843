import React, { useEffect, useState } from 'react'
import { Box, Image, Stack } from '@chakra-ui/react'
import Logo from "../../assets/images/app_logo.svg";
import ProfileIcon from '../../assets/images/Profile.svg';
import ProfileWhiteIcon from '../../assets/images/ProfileWhite.svg';
import LoanIcon from '../../assets/images/loanApplication.svg';
import LoanWhiteIcon from '../../assets/images/loanApplicationWhite.svg';
import FinOpsIcon from '../../assets/images/finOps.svg';
import FinOpsWhiteIcon from '../../assets/images/finOpsWhite.svg';
import LeadsIcon from '../../assets/images/leads.svg';
import LeadsWhiteIcon from '../../assets/images/leadsWhite.svg';
import DashboardIcon from '../../assets/images/dashboards.svg';
import DashboardWhiteIcon from '../../assets/images/dashboardsWhite.svg';
import * as routesNames from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom'

const commonMenu = [
  {
      id: 1,
      menuName: 'Dashboard',
      link: routesNames.SALESDASHBOARD,
      menuIcon: DashboardIcon,
      menuIconActive: DashboardWhiteIcon,
  },
  // {
  //   id: 2,
  //   menuName: 'Registered Users',
  //   link: routesNames.SALESDASHBOARD,
  //   menuIcon: ProfileIcon,
  //   menuIconActive: ProfileWhiteIcon,
  // },
  // {
  //   id: 3,
  //   menuName: 'Loan Applications',
  //   link: routesNames.SALESDASHBOARD,
  //   menuIcon: LoanIcon,
  //   menuIconActive: LoanWhiteIcon,
  // },
  // {
  //   id: 4,
  //   menuName: 'FinOPs',
  //   link: routesNames.SALESDASHBOARD,
  //   menuIcon: FinOpsIcon,
  //   menuIconActive: FinOpsWhiteIcon,
  // },
  // {
  //   id: 5,
  //   menuName: 'Leads',
  //   link: routesNames.SALESDASHBOARD,
  //   menuIcon: LeadsIcon,
  //   menuIconActive: LeadsWhiteIcon,
  // },
]

export default function SidebarSales() {

  const location = useLocation();
  const [menuId, setMenuId] = useState(null)  
  const [toggleMenu, setToggleMenu] = useState(false)

  useEffect(() => {
    const menuItem = commonMenu.find((item) => item.link === location.pathname);
    if (menuItem) {
      setMenuId(menuItem.id);
    }
  }, [location.pathname]);

  const handleMenu = (item) => {
    if (menuId !== item) {
      setToggleMenu(true)
    } else {
      setToggleMenu(!toggleMenu)
    }
    setMenuId(item)
  }

  return (
    <Box className='sidebar_wrapper admin_sidebar'>
      <Link to={routesNames.SALESDASHBOARD}>
       <Image src={Logo} alt='logo' className='app_logo' />
      </Link>
      <Stack className="menu_box sales_menu_box">
        {commonMenu?.map((item, index) => {
          return (
            <Box className='sales_menu' key={index}>
              <Link to={item.link} key={item.id} onClick={() => handleMenu(item.id)}
                className={item.id === menuId ? 'active_main_menu admin_menu_item font-poppins' : 'admin_menu_item font-poppins'} >
                <Image src={item.id === menuId ? item.menuIconActive : item.menuIcon} className="icon_size" />
                  {item.menuName}
              </Link>
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}
