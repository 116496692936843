export const LOGIN = '/login'
export const CPLOGIN = '/login/channel-partner'
export const CPLOGINTOKEN = '/login/cp'
export const REGISTER = '/register'
export const SIGNUP = '/sign-up"'
export const BORROWERSIGNUP = '/borrower-sign-up'
export const LENDERSIGNUP = '/lender-sign-up'
export const SALESLOGIN = '/sales'

// super-admin Routes

//borrower Routes
export const BORROWERKYC = '/borrower-complete-kyc'
export const BORROWERDASHBOARD = '/borrower/dashboard'
export const BORROWERAPPLYLOAN = '/borrower/apply-loan'
export const BORROWERLOANSTATUS = '/borrower/loan-status'
export const BORROWERACTIVELOAN = '/borrower/active-loan'
export const BORROWERCLOSEDLOAN = '/borrower/closed-loan'
export const BORROWEREXPIREDLOAN = '/borrower/expired-loan'
export const BORROWERWITHDRAWNLOAN = '/borrower/withdrawn-loan'
export const BORROWERPROFILE = '/borrower/profile'

//investor Routes
export const INVESTERDASHBOARD = '/invest/dashboard'
export const INVESTERPORTFOLIO = '/invest/dashboard/portfolio'
export const MANUALINVESTMENT = '/invest/manual-investment'
export const PLEDGEDINVESTMENT = '/invest/pledged-investment'
export const ACTIVEINVESTMENT = '/invest/active-investment'
export const CLOSEDINVESTMENT = '/invest/closed-investment'
export const WALLET = '/invest/wallet'
export const INVESTERKYC = '/investor-kyc'
export const INVESTORPROFILE = '/invest/profile'

//admin Routes
export const ADMINDASHBOARD = '/admin/dashboard'
export const ADMINPRODUCT = '/admin/product'
export const UNDERWRITERLOANAPPLICATION = '/admin/underwriter/loan-application'
export const UNDERWRITERVIEWSUMMARY = '/admin/underwriter/view-summary'
export const UNDERWRITERPROCESS = '/admin/underwriter/process'
export const PRESANCTIONBORROWERS = '/admin/pre-sanction/borrowers'
export const PRESANCTIONBORROWERSVERIFY = '/admin/pre-sanction/borrowers/verify'
export const PRESANCTIONINVESTORS = '/admin/pre-sanction/investors'
export const PRESANCTIONINVESTORVERIFY = '/admin/pre-sanction/investors/verify'
export const POSTSANCTIONLOANAGREEMENTS = '/admin/post-sanction/loan-agreements'
export const POSTSANCTIONLOANAGREEMENTSVERIFY = '/admin/post-sanction/loan-agreements/verify'
export const POSTSANCTIONLOANAGREEMENTSVIEW = '/admin/post-sanction/loan-agreements/view'
export const INTERNALREPORT = '/admin/reports/internal-report'
export const DCRREPORT = '/admin/reports/DCR-report'
export const BUREAUREPORT = '/admin/reports/bureau-report'
export const MULTIPLEEMIPAYMENT = '/admin/reports/multiple-emi-payment'
export const CKYCREPORT = '/admin/reports/ckyc-report'
export const ADMINUSERCOLLECTION = '/admin/collection'
export const ADMINUSERCOLLECTION_LOAN_DETAILS = '/admin/collection/loan-list'
export const ADMINUSERCOLLECTION_KYC_Details = '/admin/collection/kyc-details'
export const ADMINUSERCOLLECTION_UPCOMING_Details = '/admin/collection/upcoming'
//sales Routes
export const SALESDASHBOARD = '/sales/dashboard'

//cp Routes
export const CPDASHBOARD = '/channel-partner/dashboard'
export const CPBORROWERS = '/channel-partner/borrower-list'
export const CPAPPLYLOAN = '/channel-partner/apply-loan'
export const CPLOANLIST = '/channel-partner/loan-list'
export const CPINVESTORS = '/channel-partner/investor-list'
export const CPPAYOUTS = '/channel-partner/payout-summary'
