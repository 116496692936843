import React, { useEffect, useState } from "react";
import {Box, Image, Heading, Flex, Input} from '@chakra-ui/react'
import {ActionCreators} from '.././../../redux/actions'
import {Link, useHistory} from 'react-router-dom'
import { connect, useSelector } from "react-redux";
import * as routesNames from '../../../constants/routes'
import RBIlogo from "../../../assets/images/rbi_logo.svg";
import RBIlogoBlack from "../../../assets/images/rbi_black.svg";
import alphaLogo2 from "../../../assets/images/Asset_logo_3.svg";
import alphaLogo from "../../../assets/images/Asset_logo_2.svg";
import CustomButton from "../../elements/customButton.js";
import { bindActionCreators } from "redux";
import CustomToast from "../../elements/customToast.js";
import { setItem } from "../../../utilities/authUtils.js";
import Loader from "../../elements/loader.js";

  const SalesLogin = (props) => {

    const history = useHistory()
    const {addToast} = CustomToast()
    const authState = useSelector((state) => state.authState)
    const [inputValues, setInputValues] = useState({})
    const [otpStatus, setOtpStatus] = useState(null)
    const [timerCount, setTimerCount] = useState(0)

    useEffect(() => {
      if (authState?.getSalesOTP?.data?.success === true){
        setOtpStatus(authState?.getSalesOTP?.data?.status)
        addToast({message: authState?.getSalesOTP?.data?.message, status: 'success'})
        setTimerCount(120);
      } else if (authState?.getSalesOTP?.data?.success === false){
        addToast({message: authState?.getSalesOTP?.data?.message, status: 'error'})
        setTimerCount(0);
      }
    }, [authState?.getSalesOTP])

    useEffect(() => {
      if (authState?.verifySalesOTP?.data?.success === true){
        addToast({message: authState?.verifySalesOTP?.data?.message, status: 'success'})
        if (authState?.verifySalesOTP?.data?.data?.header_token && authState?.verifySalesOTP?.data?.data?.token) {
          setItem("id_token", authState?.verifySalesOTP?.data?.data?.token);
          setItem("header_token", authState?.verifySalesOTP?.data?.data?.header_token);
          history.push(routesNames.SALESDASHBOARD);
        }
      } else if (authState?.verifySalesOTP?.data?.success === false){
        addToast({message: authState?.verifySalesOTP?.data?.message, status: 'error'})
      }
    }, [authState?.verifySalesOTP])

    useEffect(() => {
      let timer;
      if (timerCount > 0) {
        timer = setTimeout(() => setTimerCount(timerCount - 1), 1000);
      }
      return () => clearTimeout(timer);
    }, [timerCount]);

    useEffect(() => {
      return () => {
        props?.actions?.cleanUpToast()
      }
    }, [])

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'otp') {
        const truncatedOTP = value.slice(0, 6);
        setInputValues({ ...inputValues, [name]: truncatedOTP });
      } else {
        setInputValues({ ...inputValues, [name]: value });
      }
    }

    const handleOTP = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(inputValues?.email)) {
        addToast({message: 'Please Enter a Valid Email', status: 'error'})
      } else if (emailPattern.test(inputValues?.email)) {
        props.actions.getSalesOTPAction({username: inputValues.email})
      }
    }

    const handleResendOTP = () => {
      props.actions.getSalesOTPAction({ username: inputValues.email })
      setTimerCount(120);
    }

    const handleLogin = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(inputValues?.email)) {
        addToast({message: 'Please Enter a Valid Email', status: 'error'})
        return
      }
      if (inputValues?.otp?.length !== 6) {
        addToast({message: 'Please Enter OTP', status: 'error'})
        return
      }
      let verifiedData = {
        otp: inputValues.otp,
        username: inputValues.email,
      }
      props.actions.verifySalesOTPAction(verifiedData)
    }

    return (
      <>
        {(authState?.getSalesOTP?.loading || authState?.verifySalesOTP?.loading) &&
        <div>
          <Loader />
        </div>
        }
        <Box className="parent_container">
          <Box className="updateprofileContainerLogin main_container">       
            <Box className="auth_left_panel hide-xs">
              <Link to={routesNames.SALESLOGIN}>
                  <Image className="" src={alphaLogo} />
              </Link>
              <Box className="about_alpha">
                <Heading as="h4">Welcome to <span style={{color:'#E61E25'}}>ALPHA</span>MONEY!</Heading>
                <Heading as="p">
                  Our Peer To Peer lending platform connects borrowers with investors for quick personal loans and great returns. Join us now for financial freedom.
                </Heading>
              </Box>
              <Box className="rbi">
                <Image className="" src={RBIlogo} />
              </Box>
            </Box> 
            <Box className="auth_right_panel">
              <Box className="form_container">
                <Box className="form_heading">
                  <Box className="mobile_logo hide-md visible-xs">
                    <Link to={routesNames.SALESLOGIN}>
                      <Image alt="logo" src={alphaLogo2} />
                    </Link>
                  </Box>
                  <Heading as="h1">Welcome To <span>Sales</span> Portal!</Heading>
                  <Heading as="p">We will send an OTP to your Email</Heading>
                </Box>
                <Box className="login-left-container">
                  <Box className="formWraper">
                    <Box mb={5}>
                      <Input
                        type="email"
                        size="md"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        className="input_box font-poppins"
                        value={inputValues?.email ?? ''}
                      />
                    </Box>
                    <Box mb={5}>
                      <Input
                        size="md"
                        id="otp"
                        name="otp"
                        type="number"
                        disabled={!otpStatus}
                        placeholder="Email OTP"
                        onChange={handleChange}
                        className="input_box font-poppins"
                        value={inputValues?.otp ?? ''}
                    />
                    </Box>
                    <Box mb={5} className="right_align">
                      {otpStatus ? 
                        <Flex className="right_align resend">
                          <CustomButton size="md" type="submit" title="Resend OTP" className="font-poppins btn_transparent" onClick={handleResendOTP}
                          isDisabled={timerCount === 0 ? false : true} />
                          {timerCount === 0 ? null : <Heading as="p"> {timerCount} Sec </Heading>}
                      </Flex>
                      :
                      <CustomButton size="md" type="submit" title="Get OTP" className="font-poppins btn_transparent" onClick={() => handleOTP()} />
                    }
                    </Box>
                    <Box className="submit_actions" mb={5}>
                      <CustomButton size="md" type="submit" title="Submit" onClick={() => handleLogin()} className="btn_theme font-poppins" />
                    </Box>
                  </Box>
                </Box>
                <Box className="mobile_rbi_logo hide-md visible-xs">
                  <Image alt="rbi" src={RBIlogoBlack} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
   
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(SalesLogin)