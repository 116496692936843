export const APIPATH = process.env.REACT_APP_API_URL
// export const ISCASTLERACTIVE = process.env.REACT_APP_ACTIVE_CASTLER
// export const DIGI_LOCKER_SDK = "https://app.digio.in/sdk/v10/digio.js";
export const DIGI_LOCKER_SDK_ESIGN = process.env.REACT_APP_DIGI_LOCKER_E_SIGN
export const DIGI_LOCKER_SDK = process.env.REACT_APP_DIGI_LOCKER
export const Assets_Url = "https://d3kgk0blw9vqbg.cloudfront.net/"
export const IFSCURL = "https://ifsc.razorpay.com/";
export const EQUIFAXURL = "http://13.234.206.255:8081/api/";
export const PINE_PG = process.env.REACT_APP_PINE_LAB_URL;
export const ENACH_ENV = process.env.REACT_APP_ENACH;
export const SDK_ENV = process.env.REACT_APP_SDK_ENV