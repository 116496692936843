import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, GridItem, Heading, Image } from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import IconkYC from '../../../../assets/images/Iconkyc.svg'
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ActionCreators } from '../../../../redux/actions';
import NavBar from '../../../elements/NavBar'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import InvestorPieChart from './components/InvestorPieChart';
import CustomCard from '../../../elements/CustomCard'
import RevenueStepper from './components/RevenueStepper'
import InvestorBarChart from './components/InvestorBarChart'
import InvestorReturn from './components/InvestorReturn'
import Loader from '../../../elements/loader'
import WalletBalance from './components/WalletBalance'
import LendingPreferenceDialog from './components/LendingPreferenceDialog'
import QuickInvestDialog from './components/QuickInvestDialog'
import AddFundsDialog from '../Wallet/Components/AddFundsDialog'
import WithdrawFundsDialog from '../Wallet/Components/WithdrawFundsDialog'
import PaymentDialogInvestor from '../../../modules/PaymentModule/PaymentDialogInvestor'
import CustomToast from '../../../elements/customToast'
import ImportantDialog from '../../../elements/ImportantDialog'
import moment from 'moment'
import { uploadNetworthCertificate } from '../../../../services/masterServices';
import CompleteKYCDialog from '../../borrow/components/CompleteKYCDialog';
import RejectKYCDialog from '../../borrow/components/RejectKYCDialog';
import NoLoanTicketsDialog from './components/NoLoanTicketsDialog';

const amountData = [
  {
    id: 1,
    name: '10,00,000',
  },
  {
    id: 2,
    name: '1,00,000',
  },
  {
    id: 3,
    name: '75,000',
  },
  {
    id: 4,
    name: '50,000',
  },
  {
    id: 5,
    name: '25,000',
  },
  {
    id: 6,
    name: '10,000',
  },
]

const InvesterDashboard = (props) => {

  const MAXAMOUNT = 5000000
  const history = useHistory()
  const certificateRef = useRef()
  const { addToast } = CustomToast()
  const investorState = useSelector(state => state.investorState)
  const masterState = useSelector((state) => state.masterState)
  const [viewAmount, setViewAmount] = useState(false)
  const [openPreferenceModal, setOpenPreferenceModal] = useState(false)
  const [openQuickInvestModal, setOpenQuickInvestModal] = useState(false)
  const [period, setPeriod] = useState(null)
  const [riskCheckData, setRiskCheckData] = useState([])
  const [borrowerCheckData, setBorrowerCheckData] = useState([])
  const [incomeCheckData, setIncomeCheckData] = useState([])
  const [inputValues, setInputValues] = useState({})
  const [openAddFunds, setOpenAddFunds] = useState(false)
  const [openWithdrawFunds, setOpenWithdrawFunds] = useState(false)
  const [amount, setAmount] = useState(null)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [payableAmount, setPayableAmount] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [openNoLoanTickets, setOpenNoLoanTickets] = useState(false)
  const [quickAcknowledgeConsent, setQuickAcknowledgeConsent] = useState(false)
  const [acknowledgeConsent, setAcknowledgeConsent] = useState(false)
  const [certificateConsent, setCertificateConsent] = useState(false)
  const [certificateData, setCertificateData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isWithdrawn, setIsWithdrawn] = useState(false)
  const [openCommpleteKYCModal, setOpenCommpleteKYCModal] = useState(false)
  const [isKYCReject, setIsKYCReject] = useState(false)

  const userDetails = investorState?.getInvestorDashboardData?.data?.data
  const userData = masterState?.getCurrentUserDetails?.data?.data
  const portfolioData = investorState?.getInvestorPortfolioData?.data?.data
  const walletData = investorState?.getInvestorWalletData?.data?.data
  const dropdownData = masterState?.getDropdownData?.data?.data
  const opportunityData = investorState?.getQuickInvestment?.data?.data
  const isKYCPending = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 0)
  const isKYCUnderReview = (userDetails?.user?.iskyc === 0 && userDetails?.user?.form_completed_status === 1)
  const isKYCCompleted = (userDetails?.user?.iskyc === 1 && userDetails?.user?.form_completed_status === 1)
  const isReject = userData?.[0]?.document_data?.doc_approval_status === 1
  const rejectData = userData?.[0]?.user_document_checked_data?.document &&  JSON.parse(userData?.[0]?.user_document_checked_data?.document)
  const employmentType = userData?.[0]?.employment_type
  const riskAppetite = dropdownData?.risk_appetite
  const borrowerType = dropdownData?.employment_type_borrower
  const monthIncomeRange = dropdownData?.income_range
  const lendingPeriod = dropdownData?.preference_tenure
  const investVSReceived = portfolioData?.investvsreceived && Object.keys(portfolioData?.investvsreceived).map((key) => {
    return {
      month: moment(key, "YYYY-MM").format("MMM YYYY"),
      data: portfolioData?.investvsreceived[key]
    }
  })

  let quickInvestData = {
    pref_invest_amount: inputValues?.lended_amount ?? '',
    pref_tenure: period ?? '',
    pref_risk_appetite: riskCheckData.join(',') ?? '',
    pref_employment_type: borrowerCheckData.join(',') ?? '',
    pref_income_range: incomeCheckData.join(',') ?? '',
    pref_min_interest_rate: inputValues?.min_interest ? Number(inputValues?.min_interest) : '',
    pref_max_interest_rate: inputValues?.max_interest? Number(inputValues?.max_interest) : '',
    type: 'lending_preference'
  }

  useEffect(() => {
    props.actions.getInvestorDetailedAction()
    props.actions.getCurrentUserDetailsAction()
    props.actions.getInvestmentPortfolioAction()
    props.actions.getInvestmentWalletAction()
    props.actions.getDropdownDataAction({ type: 'premise_status|educational_qualification|loan_type|loan_tenure|risk_appetite|employment_type_borrower|income_range|preference_tenure' ,platform:'web'})
  }, [])

  useEffect(() => {
    if (isKYCPending) {
      setOpenCommpleteKYCModal(true)
    } else if (isKYCCompleted) {
      setOpenCommpleteKYCModal(false)
    } else if (isKYCUnderReview) {
      setOpenCommpleteKYCModal(false)
    }
  }, [investorState?.getInvestorDashboardData])

  useEffect(() => {
    if (isReject) {
      setIsKYCReject(true)
    }
  }, [isReject])

  useEffect(() => {
    if(investorState?.getQuickInvestment?.data?.success === true) {
      addToast({ message: investorState?.getQuickInvestment?.data?.message, status: 'success' })
      setOpenQuickInvestModal(true)
      setPeriod(null)
      setRiskCheckData([])
      setBorrowerCheckData([])
      setIncomeCheckData([])
      // setOpenPreferenceModal(false)
      setAmount(null)
      setInputValues({ ...inputValues, invest_amount: 1000 })
    } else if(investorState?.getQuickInvestment?.data?.success === false) {
      addToast({ message: investorState?.getQuickInvestment?.data?.message, status: 'warning' })
      setOpenNoLoanTickets(true)
      setOpenPreferenceModal(false)
      setOpenQuickInvestModal(false)
    }
  }, [investorState?.getQuickInvestment])

  useEffect(() => {
    if(investorState?.getInvestInLoanDetails?.data?.success === true) {
      addToast({ message: investorState?.getInvestInLoanDetails?.data?.message, status: 'success' })
    } else if(investorState?.getInvestInLoanDetails?.data?.success === false) {
      addToast({ message: investorState?.getInvestInLoanDetails?.data?.message, status: 'error' })
    }
  }, [investorState?.getInvestInLoanDetails])

  useEffect(() => {
    if(investorState?.withdrawWalletAmount?.data?.success === true) {
        addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'success' })
        setIsWithdrawn(investorState?.withdrawWalletAmount?.data?.success)
        props.actions.getCurrentUserDetailsAction()
        props?.actions?.cleanUpToast()
      } else if(investorState?.withdrawWalletAmount?.data?.success === false) {
        addToast({ message: investorState?.withdrawWalletAmount?.data?.message, status: 'warning' })
        props?.actions?.cleanUpToast()
      }
  }, [investorState?.withdrawWalletAmount])

  useEffect(() => {
    if (investorState?.changePaymentMode?.data?.success === true) {
      addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'success' })
      props?.actions?.cleanUpToast()
    } else if (investorState?.changePaymentMode?.data?.success === false) {
      addToast({ message: investorState?.changePaymentMode?.data?.message, status: 'error' })
      props?.actions?.cleanUpToast()
    }
  }, [investorState?.changePaymentMode?.data])

  useEffect(() => {
    return () => {
      props?.actions?.cleanUpToast()
    }
  }, [])

  const connectorStyleConfig = {
    completedColor: '#357066',
    activeColor: '#C2D6D3',
    disabledColor: '#C2D6D3',
    size: '1.5px',
    style: 'solid',
  };

  const pieChartData = {
    labels: [
      `Insta Loan (${portfolioData?.investment_percentage_details?.total_fixed_return_percentage?.toFixed(2)}%)`,
      `Personal Loan (${portfolioData?.investment_percentage_details?.total_pl_percentage?.toFixed(2)}%)`,
      `Bullet Loan (${portfolioData?.investment_percentage_details?.total_bl_percentage?.toFixed(2)}%)`
    ],
    datasets: [
      {
        data: [
          portfolioData?.investment_percentage_details?.total_fixed_return_percentage?.toFixed(2),
          portfolioData?.investment_percentage_details?.total_pl_percentage?.toFixed(2),
          portfolioData?.investment_percentage_details?.total_bl_percentage?.toFixed(2),
        ],
        backgroundColor: ['#C2D6D3', '#D6EAE7', '#18342F'],
        hoverBackgroundColor: ['#C2D6D3', '#D6EAE7', '#18342F'],
      }
    ]
  };

  const pieChartOptions = {
    layout: {
      padding: {
        right: 15
      }
    },
    legend: {
      display: true,
      onClick: function (e, legendItem) {
        e.preventDefault();
        e.stopPropagation();
      },
      position: 'right',
      labels: {
        fontFamily: "Poppins",
        fontColor: '#000',
        fontSize: 12,
        fontStyle: 'normal',
        usePointStyle: true,
        padding: 30,
      },
    },
    maintainAspectRatio: true,
    cutoutPercentage: 70,
    tooltips: {
      position: 'nearest',
      backgroundColor: 'rgba(255,255,255,1)',
      titleFontColor: '#000',
      bodyFontColor: '#000',
      titleFontSize: 14,
      titleFontFamily: "Poppins",
      bodyFontFamily: "Poppins",
      bodyFontSize: 14,
      bodySpacing: 4,
      xPadding: 12,
      yPadding: 12,
      borderColor: '#D3D2D5',
      borderWidth: 1,
      displayColors: false,
      // xAlign: 'right',
      callbacks: {
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]; // Display the label as the title
        },
        label: function (tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const formattedValue = value.toLocaleString('en-US');
          return `(${formattedValue + '%'})`;
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const barChartOptions = {
    layout: {
      padding: {
        right: 15
      }
    },
    legend: {
      display: true,
      onClick: function (e, legendItem) {
        e.preventDefault();
        e.stopPropagation();
      },
      position: 'bottom',
      labels: {
        fontFamily: "Poppins",
        fontColor: '#000',
        fontSize: 12,
        fontStyle: 'normal',
        usePointStyle: true,
        padding: 30,
      },
    },
    tooltips: {
      position: 'nearest',
      backgroundColor: 'rgba(255,255,255,1)',
      titleFontColor: '#000',
      bodyFontColor: '#000',
      titleFontSize: 14,
      titleFontFamily: "Poppins",
      bodyFontFamily: "Poppins",
      bodyFontSize: 14,
      bodySpacing: 4,
      xPadding: 12,
      yPadding: 12,
      borderColor: '#D3D2D5',
      borderWidth: 1,
      displayColors: false,
      callbacks: {
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]; // Display the label as the title
        },
        label: function (tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const formattedValue = value.toLocaleString('en-US');
          return `(${'₹ ' + formattedValue + ''})`;
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          fontColor: "#000",
          fontSize: 12,
          fontFamily: "Poppins",
          // stepSize: 1,
          beginAtZero: true,
          callback: function (value, index, values) {
            return value.toLocaleString() + '';
          }
        }
      }],
      xAxes: [{
        ticks: {
          fontColor: "#000",
          fontSize: 12,
          fontFamily: "Poppins",
        }
      }],
    }
  };

  const barChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "#357066",
        label: "Amount Invested",
        borderRadius: 5,
      },
      {
        label: 'Return Received',
        data: [],
        backgroundColor: '#C2D6D3',
        borderRadius: 5,
      }
    ]
  };

  investVSReceived?.forEach(entry => {
    barChartData.labels?.push(entry.month)
    barChartData.datasets[0].data.push(entry.data.invested);
    barChartData.datasets[1].data.push(entry.data.received);
  })

  const monthwiseData = portfolioData?.monthwise_principal_interest_recieved || [];
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
  const currentYear = new Date().getFullYear();
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

  const monthYearExists = (month, year) => monthwiseData.some(item => item.month === month && item.year === year);

  if (!monthYearExists(currentMonth, currentYear)) {
    monthwiseData.push({
      month: currentMonth,
      year: currentYear,
      principal_recieved: 0,
      penel_interset: 0,
      interest_recieved: 0,
    });
  }

  if (!monthYearExists(previousMonth, previousYear)) {
    monthwiseData.push({
      month: previousMonth,
      year: previousYear,
      principal_recieved: 0,
      penel_interset: 0,
      interest_recieved: 0,
    });
  }

  const aggregatedData = monthwiseData.reduce((acc, curr) => {
    const key = `${curr.month}-${curr.year}`;
    if (!acc[key]) {
      acc[key] = { ...curr };
    } else {
      acc[key].principal_recieved += curr.principal_recieved;
      acc[key].penel_interset += curr.penel_interset;
      acc[key].interest_recieved += curr.interest_recieved;
    }
    return acc;
  }, {});

  const processedData = aggregatedData && Object.values(aggregatedData);
  const sortedData = processedData?.sort((a, b) => (b.year - a.year) || (b.month - a.month));
  const slicedData = sortedData?.slice(0, 3);

  const currentData = slicedData?.reverse()?.map((item, index) => {
    return Object.assign(item, {
      month: moment(item.month, 'M').format('MMM'),
      status: index < slicedData.length - 1 ? 'Complete' : 'Active',
    });
  });

  const pastData = sortedData?.slice(3, sortedData.length);
  let totalPastPrincipal = 0;
  let totalPastInterest = 0;
  pastData?.forEach((item) => {
    totalPastInterest += item.interest_recieved;
    totalPastPrincipal += item.principal_recieved;
  });

  const allData = sortedData;
  let totalPrincipal = 0;
  let totalInterest = 0;
  allData?.forEach((item) => {
    totalInterest += item.interest_recieved;
    totalPrincipal += item.principal_recieved;
  });
  

  const stepData = [
    ...(pastData && pastData.length > 0
      ? [{
        month: `Upto-${moment(pastData?.[0]?.month, 'M').format('MMM')}`,
        year: pastData?.[0]?.year,
        interest_recieved: totalPastInterest,
        principal_recieved: totalPastPrincipal,
        status: 'Complete'
      }]
      : []),
    ...currentData,
    {
      month: 'As On Today',
      interest_recieved: totalInterest,
      principal_recieved: totalPrincipal,
      status: 'Pending',
    },
  ];

  const handleCloseKYCModal = () => {
    setOpenCommpleteKYCModal(false)
  }

  const handleCloseRejectKYCModal = () => {
    setIsKYCReject(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  }

  const handleCheck = (e, type) => {
    if (type === 'acknowledge') {
      setAcknowledgeConsent(e.target.checked)
    }
    if (type === 'certificate') {
      setCertificateConsent(e.target.checked)
    }
    if (type === 'quick_acknowledge') {
      setQuickAcknowledgeConsent(e.target.checked)
    }
  }

  const handleViewAmount = () => {
    setViewAmount(!viewAmount)
  }

  const handleAddFundsDialog = () => {
    setOpenAddFunds(!openAddFunds)
  }

  const handleOpenWithdrawFundsDialog = () => {
    setOpenWithdrawFunds(true)
  }

  const handleCloseWithdrawFundsDialog = () => {
    setOpenWithdrawFunds(false)
    setInputValues({...inputValues, withdraw_amount: ''})
    setIsWithdrawn(false)
  }

  const handleWithdrawAll = (e, amount) => {
    if (e.target.checked) {
        setInputValues({...inputValues, withdraw_amount: amount})
    } else {
        setInputValues({...inputValues, withdraw_amount: ''})
    }
  }

  const handleWithdrawAmount = () => {
    if (!inputValues?.withdraw_amount) {
        addToast({message: 'Minumum amount should be ₹1', status: 'error'})
        return
    }
    if (Number(inputValues?.withdraw_amount) > Number(walletData?.effective_balance)) {
        addToast({message: 'Insufficient Funds', status: 'error'})
        return
    }
    props.actions.withdrawWalletAmountAction({amount: inputValues?.withdraw_amount})
  }

  const handleSelectAmount = (item) => {
    const selectedAmount = amountData?.find((elem) => elem.id === item.id)
    setAmount(selectedAmount)
    setInputValues({ ...inputValues, lending_amount: selectedAmount.name });
  }

  const handleOpenPaymentDialog = (amount) => {
    if (!inputValues?.lending_amount || inputValues?.lending_amount <= 99) {
        addToast({message: 'Minimum Amount is 100', status: 'error'})
        return
    }
    if (+(inputValues?.lending_amount) > MAXAMOUNT) {
        addToast({message: 'Can not Add Funds more than 50 Lakhs', status: 'error'})
        return
    }
    setPayableAmount(amount)
    setOpenPaymentModal(true)
  }

  const handleClosePaymentDialog = () => {
    setPayableAmount(null)
    setOpenPaymentModal(false)
  }

  const showOpenFileDialog = () => {
    certificateRef.current.click()
  }

  const onChangeFile = (e) => {
    setCertificateData(e.target.files[0])
  }

  const handleSaveDocumnent = () => {
    if (!certificateData) {
      addToast({ message: 'Please Upload Certificate', status: 'error' })
      return
    }
    const formData = new FormData()
    formData.append('networth_certificate', certificateData)
    setIsLoading(true)
    uploadNetworthCertificate(formData).then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true) {
        addToast({message: 'Document Uploaded Successfully', status: 'success'})
        props.actions.getCurrentUserDetailsAction()
      } else if (res?.data?.success === false) {
        addToast({message: res?.data?.message, status: 'error'})
      }
    }).catch((error) => {
      addToast({message: error, status: 'error'})
      setIsLoading(false)
    })
  }

  const handleOpenPreferenceDialog = () => {
    setOpenPreferenceModal(true)
  }

  const handleClosePreferenceDialog = () => {
    setOpenPreferenceModal(false)
    setPeriod(null)
    setInputValues({})
    setRiskCheckData([])
    setBorrowerCheckData([])
    setIncomeCheckData([])
    setCurrentIndex(0)
    setOpenNoLoanTickets(false)
    setOpenCommpleteKYCModal(false)
  }

  const handleCloseQuickInvestDialog = () => {
    setOpenQuickInvestModal(false)
  }

  const handleChangePreference = () => {
    setOpenNoLoanTickets(false)
    setOpenPreferenceModal(true)
  }

  const handleCloseNoTickets = () => {
    setOpenNoLoanTickets(false)
  }

  const handleSelectPeriod = (id) => {
    setPeriod(id)
  }

  const handleSelect = (e, val, type) => {
    if (type === 'risk') {
      if (e.target.checked) {
        setRiskCheckData([...riskCheckData, val])
      } else {
        setRiskCheckData(riskCheckData.filter((item) => item !== val))
      }
    }
    if (type === 'borrower') {
      if (e.target.checked) {
        setBorrowerCheckData([...borrowerCheckData, val])
      } else {
        setBorrowerCheckData(borrowerCheckData.filter((item) => item !== val))
      }
    }
    if (type === 'income') {
      if (e.target.checked) {
        setIncomeCheckData([...incomeCheckData, val])
      } else {
        setIncomeCheckData(incomeCheckData.filter((item) => item !== val))
      }
    }
  }

  const handleNext = () => {
    if (currentIndex < opportunityData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    // setCurrentIndex(currentIndex + 1 < opportunityData.length ? currentIndex + 1 : 0)
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    // setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : opportunityData.length - 1)
  }

  const handleStart = () => {
    if (!inputValues?.lended_amount) {
      addToast({ message: 'Please Enter Lending Amount', status: 'error' })
      return
    }
    if (!period) {
        addToast({ message: 'Please Select Lending Period', status: 'error' })
        return
      }
    if (riskCheckData?.length === 0) {
      addToast({ message: 'Please Select Risk Category', status: 'error' })
      return
    }
    if (borrowerCheckData?.length === 0) {
      addToast({ message: 'Please Select Borrower Type', status: 'error' })
      return
    }
    if (incomeCheckData?.length === 0) {
      addToast({ message: 'Please Select Monthly Income Range', status: 'error' })
      return
    }
    if (!inputValues?.min_interest || !inputValues?.max_interest) {
      addToast({ message: 'Please Enter Lending Rate Range', status: 'error' })
      return
    }
    if (inputValues?.min_interest < '12') {
      addToast({ message: 'Minimum Interest Rate is 12%', status: 'error' })
      return
    }
    if (inputValues?.max_interest > '36' || inputValues?.max_interest < '12') {
      addToast({ message: 'Maximum Interest Rate is 36%', status: 'error' })
      return
    }
    props.actions.getQuickInvestmentAction(quickInvestData)
  }

  const handleStartClick = () => {
    if (isKYCPending) {
      history.push(routesNames.INVESTERKYC)
    } else if (isKYCCompleted) {
      // history.push(routesNames.INVESTERDASHBOARD)
      handleOpenPreferenceDialog()
      setOpenCommpleteKYCModal(false)
    }
  }

  const handleInvestAmount = () => {
    const remainingData = opportunityData?.[currentIndex]
    if (inputValues?.invest_amount < 1000 || !inputValues?.invest_amount) {
      addToast({ message: 'The Minimum Investment Amount is ₹1000', status: 'error' })
      return
    }
    if (+(remainingData?.remaining_loan_amount) < +(inputValues?.invest_amount)) {
      addToast({ message: 'The Investment Amount should be less than Remaining Amount', status: 'error' })
      return
    }
    const payload = {
      amount: inputValues?.invest_amount,
      investment_charge: opportunityData?.[currentIndex]?.investment_charges,
      loan_id: opportunityData?.[currentIndex]?.id,
      risk_category: opportunityData?.[currentIndex]?.risk_category
    }
    props.actions.investInLoanAction(payload, quickInvestData)
  }

  const handleChangePaymentMode = (type) => {
    if (type === 'yes') {
      props.actions.postChangePaymentModeAction({payment_mode : 0})
    } else if (type === 'no') {
      props.actions.postChangePaymentModeAction({payment_mode : 1})
    }
  }

  const handleStartKYCAgain = () => {
    history.push(routesNames.INVESTERKYC)
  }

  const handlePortfolio = () => {
    history.push(routesNames.INVESTERPORTFOLIO)
  }

  const handleUpdateKYC = () => {
    history.push(routesNames.INVESTERKYC)
  }

  const handleGoToDashboard = () => {
    setOpenWithdrawFunds(false)
    setInputValues({...inputValues, withdraw_amount: ''})
    setIsWithdrawn(false)
  }

  const handleProfile = () => {
    history.push(routesNames.INVESTORPROFILE)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  // console.log(walletData?.payment_mode, 'walletData')

  return (
    <Box>
      {(investorState?.getInvestorDashboardData?.loading || masterState?.getCurrentUserDetails?.loading || investorState?.getQuickInvestment?.loading || investorState?.getInvestInLoanDetails?.loading || isLoading || investorState?.withdrawWalletAmount?.loading || investorState?.changePaymentMode?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Grid className="main_grid_container">
        <GridItem colSpan={8}>
          <Box className='dashboard_box bg_shadow'>
            <Box className='d-flex align-items-center justify-content-between'>
              <Heading as="h2" className='font-poppins page_heading'>Dashboard</Heading>
              {/* <h1>{process.env.REACT_APP_MAPS_API_KEY}</h1> */}
              <ImportantDialog data={userData?.[0]?.usertype} />
            </Box>
            {isKYCUnderReview ?
              <Box mt={4} className='kyc_verify'>
                <Heading as="h3" className='font-poppins'>
                  <Image src={IconkYC} />
                  Your documents under review
                  <Link to={routesNames.INVESTERKYC}>Tap to check your documents</Link>
                </Heading>
              </Box> : isKYCPending ?
                <Box mt={4} className='kyc_verify'>
                  <Heading as="h3" className='font-poppins'>
                    <Image src={IconkYC} />
                    Your ALPHAMONEY account needs Verification,
                    <Link to={routesNames.INVESTERKYC}>Tap to start your KYC</Link>
                  </Heading>
                </Box> : null
            }
            <Box mt={4}>
              <Grid className="main_grid_container">
                <GridItem colSpan={4}>
                  <CustomCard className="cardOne" title="Net Returns Received" value={portfolioData?.return_recieved_from_all_closed_investments ? portfolioData?.return_recieved_from_all_closed_investments.toFixed(2) + '%' : '0%'} />
                </GridItem>
                <GridItem colSpan={4}>
                  <CustomCard className="cardTwo" title="Total Amount Invested Till Date" value={portfolioData?.total_amount_invested_till_date ? '₹' + portfolioData?.total_amount_invested_till_date : '₹0'} />
                </GridItem>
                <GridItem colSpan={4}>
                  <CustomCard className="cardThree" title="Number of Loans invested Till Date" value={portfolioData?.total_investment_count ? portfolioData?.total_investment_count : 0} />
                </GridItem>
              </Grid>
            </Box>
            <InvestorBarChart data={barChartData} options={barChartOptions} />
            <RevenueStepper style={connectorStyleConfig} stepData={stepData} />
          </Box>
        </GridItem>
        <GridItem colSpan={4}>
          <NavBar data={userData} handleProfile={handleProfile} />
          <WalletBalance walletData={walletData} viewAmount={viewAmount} handleViewAmount={handleViewAmount}
            handleAddFundsDialog={handleAddFundsDialog} handleWithdrawFundsDialog={handleOpenWithdrawFundsDialog}
            isKYCCompleted={isKYCCompleted} handleChangePaymentMode={handleChangePaymentMode}
          />
          <InvestorReturn handlePreferenceDialog={handleOpenPreferenceDialog} isKYCCompleted={isKYCCompleted} handleUpdateKYC={handleUpdateKYC} />
          <InvestorPieChart data={pieChartData} options={pieChartOptions} handlePortfolio={handlePortfolio} />
        </GridItem>
      </Grid>

      <LendingPreferenceDialog openPreferenceModal={openPreferenceModal} handlePreferenceDialog={handleClosePreferenceDialog}
        lendingPeriod={lendingPeriod} period={period} handleSelectPeriod={handleSelectPeriod} handleSelect={handleSelect}
        handleChange={handleChange} inputValues={inputValues} handleStart={handleStart} 
        riskAppetite={riskAppetite} borrowerType={borrowerType} monthIncomeRange={monthIncomeRange}
        riskCheckData={riskCheckData} borrowerCheckData={borrowerCheckData} incomeCheckData={incomeCheckData}
        opportunityData={opportunityData}
      />

      <QuickInvestDialog openQuickInvestModal={openQuickInvestModal} handleCloseQuickInvestDialog={handleCloseQuickInvestDialog}
        inputValues={inputValues} handleChange={handleChange} handleInvestAmount={handleInvestAmount}
        handleAddFundsDialog={handleAddFundsDialog} handleNext={handleNext} handlePrev={handlePrev} opportunityData={opportunityData}
        currentIndex={currentIndex} walletData={walletData} quickAcknowledgeConsent={quickAcknowledgeConsent} handleCheck={handleCheck}
      />

      <NoLoanTicketsDialog handleCloseNoTickets={handleCloseNoTickets} openNoLoanTickets={openNoLoanTickets}
        handleChangePreference={handleChangePreference}
      />

      <AddFundsDialog data={amountData} openAddFunds={openAddFunds} handleAddFundsDialog={handleAddFundsDialog}
        handleSelectAmount={handleSelectAmount} amount={amount} handleOpenPaymentDialog={handleOpenPaymentDialog}
        certificateRef={certificateRef} handleChange={handleChange} inputValues={inputValues} onChangeFile={onChangeFile}
        showOpenFileDialog={showOpenFileDialog} handleCheck={handleCheck} acknowledgeConsent={acknowledgeConsent}
        certificateConsent={certificateConsent} handleSaveDocumnent={handleSaveDocumnent} certificateData={certificateData}
        userData={userData}
      />

      <WithdrawFundsDialog handleChange={handleChange} inputValues={inputValues} openWithdrawFunds={openWithdrawFunds}
        handleCloseWithdrawFundsDialog={handleCloseWithdrawFundsDialog} walletData={walletData} handleWithdrawAmount={handleWithdrawAmount}
        handleWithdrawAll={handleWithdrawAll} isWithdrawn={isWithdrawn} handleGoToDashboard={handleGoToDashboard}
      />
      <PaymentDialogInvestor title="Add Funds" openPayModal={openPaymentModal} payableAmount={payableAmount}
        handleClosePaymentDialog={handleClosePaymentDialog} userData={userData} handleAddFundsDialog={handleAddFundsDialog}
      />
      <CompleteKYCDialog
        handleCloseKYCModal={handleCloseKYCModal}
        handleStart={handleStartClick}
        openCommpleteKYCModal={openCommpleteKYCModal} 
        isKYCCompleted={isKYCCompleted}
        isInvestor
      />
      <RejectKYCDialog isKYCReject={isKYCReject} handleCloseRejectKYCModal={handleCloseRejectKYCModal}
        handleStart={handleStartKYCAgain} rejectData={rejectData} isInvestor employmentType={employmentType}
      />
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(InvesterDashboard)

