import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import moment from 'moment';
import Pagination from '../../../../elements/Pagination';
import CustomButton from '../../../../elements/customButton';
import NoData from '../../../../elements/NoData';

export default function RejectedApplication(props) {

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 15;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(props?.filterRejected?.length / PER_PAGE);
  
    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    };

  return (
    <Box mt={1}>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        <>
            {props?.filterRejected?.length > 0 ?
                <Box mt={2} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No.</Th>
                                    <Th>Name</Th>
                                    <Th>Loan App. No.</Th>
                                    <Th>Phone No.</Th>
                                    <Th>Application Date</Th>
                                    <Th>Loan Amount</Th>
                                    <Th>Tenure</Th>
                                    <Th>Loan Type</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {props?.filterRejected?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                    const serialNumber = offset + index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.first_name}{' '}{item?.user_data?.last_name}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.loan_number}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.user_data?.mobile}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.created_at ? moment(item?.created_at).format('DD-MMM-YYYY'): null}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹ {item?.loan_amount}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.tenure}{' '}{item?.loan_type === '2' ? 'Days': 'Months'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item?.loan_type === '2' ? 'Bullet Loan': 'Personal Loan'}</Text>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Text className="font-poppins table_text red">{item?.rejection_reason_comment}</Text>
                                                <CustomButton size="md" type="submit" title="View" className="btn_table_green font-poppins"
                                                    onClick={() => props.handleView(item?.user_data?.id, item?.loan_number)}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                </Box> :
                <NoData title="No Record Found" />
            }
        </>
    </Box>
  )
}
