import React, { useState } from 'react'
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import Pagination from '../../../elements/Pagination'
import NoData from '../../../elements/NoData'

export default function CheckSummaryTable(props) {
    const reportFile = props?.reportData?.data?.file_url;
    const reportItems = props?.reportData?.records;
    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 50;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(reportItems?.length / PER_PAGE);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    return (
        <>
            {reportItems?.length > 0 ? (
                <Box mt={2}>
                    <Box className='d-flex justify-content-end'>
                        <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
                    </Box>
                    <Box mt={3} className='custom_table_wrapper'>
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>S.No</Th>
                                        <Th>Total Registered Borrowers till date</Th>
                                        <Th>Total Registered Lenders till date</Th>
                                        <Th>Total Disbursed Amount</Th>
                                        <Th>Incomplete profiles of Lenders</Th>
                                        <Th>Incomplete profiles Borrower</Th>
                                        <Th>Borrower Profiles pending with Pre-sanction Admin</Th>
                                        <Th>Lenders Profiles pending with Pre-sanction Admin</Th>
                                        <Th>Borrower Profiles Pending with Underwriting Admin</Th>
                                        <Th>Lender Profiles Pending with Underwriting Admin</Th>
                                        <Th>Borrower Profiles with Incomplete documents</Th>
                                        <Th>Lenders Profiles with Incomplete documents</Th>
                                        <Th>Borrower Profiles Approved</Th>
                                        <Th>Lenders Profiles Approved</Th>
                                        <Th>Total Loans disbursed as on date</Th>
                                        <Th>Total Loans created as on date</Th>
                                        <Th>Loans pending with Underwriter</Th>
                                        <Th>Loans pending for Borrower Acceptance</Th>
                                        <Th>Loans rejected by underwriter</Th>
                                        <Th>Loans withdrawn by Borrower</Th>
                                        <Th>Loans expired</Th>
                                        <Th>Loans Approved by underwriter in funding</Th>
                                        <Th>Loans funded awaiting e_sign</Th>
                                        <Th>Loans with E_signing in Progress</Th>
                                        <Th>Loans ready of disbursal</Th>
                                        <Th>Loans disbursed</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {reportItems?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                        console.log('Current item:', item);  // Log each item being processed
                                        const serialNumber = offset + index + 1;
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    <Text className="font-poppins table_text">{serialNumber}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_registered_borrowers_till_date']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_registered_lenders_till_date']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">₹{item['total_disbursed_amount']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_incomplete_lenders_profiles']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['incomplete_profiles_borrower']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['borrower_profiles_pending_with_pre-sanction_admin']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['lenders_profiles_pending_with_pre-sanction_admin']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['borrower_profiles_pending_with_underwriting_admin']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['lender_profiles_pending_with_uderwriting_admin']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['borrower_profiles_with_incomplete_documents']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['lender_profiles_with_incomplete_documents']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['borrower_profiles_approved']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['lender_profiles_approved']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_disbursed_as_on_date']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_created_as_on_date']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_pending_with_underwriter']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_pending_for_borrower_acceptance']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_rejected_by_underwriter']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_withdrawn_by_borrower']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_expired']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['loans_approved_by_underwriter_in_funding']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_funded_awaiting_e-Sign']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_e-signing_in_progress']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_ready_for_disbursal']}</Text>
                                                </Td>
                                                <Td>
                                                    <Text className="font-poppins table_text">{item['total_loans_disbursed']}</Text>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                    </Box>
                </Box>
            ) : (
                <NoData title="No Record Found" />
            )}
        </>
    );
}
