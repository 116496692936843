import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Image, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { Document, Page } from 'react-pdf';
import { getItem, removeAll, removeItem, setItem } from '../../../../utilities/authUtils'
import { ActionCreators } from '../../../../redux/actions'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import Loader from '../../../elements/loader'
import CustomToast from '../../../elements/customToast'
import { ViewIcon } from '@chakra-ui/icons'
import OpenFIleDialog from '../../../elements/OpenFIleDialog'
import CustomButton from '../../../elements/customButton'
import { detectFileType } from '../../../../utilities/DownloadDocuments'
import DownloadIcon from '../../../../assets/images/download.png'

const VerifyAgreement = (props) => {

  const history = useHistory()
  const LOANID = getItem('loanId')
  const {addToast} = CustomToast()
  const adminState = useSelector((state) => state.adminState)
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [currentFileType, setCurrentFileType] = useState(null)
  const userData = adminState?.getUserByToken?.data?.data
  const finalData = adminState?.verifyAgreement?.data?.data

  console.log(finalData, 'finalData')

    useEffect(() => {
        props.actions.verifyAgreementAction({loan_id: Number(LOANID)})
        props.actions.getUserByTokenAction()
    }, [])

    useEffect(() => {
        if (adminState?.rejectLoanContract?.data?.success === true){
            addToast({message: adminState?.rejectLoanContract?.data?.message, status: 'success'})
            removeItem('loanId')
            props?.actions?.cleanUpToast()
            history.push(routesNames.POSTSANCTIONLOANAGREEMENTS)
        } else if (adminState?.rejectLoanContract?.data?.success === false){
            addToast({message: adminState?.rejectLoanContract?.data?.message, status: 'error'})
        }
    }, [adminState?.rejectLoanContract])

    useEffect(() => {
        if (adminState?.createLoanContract?.data?.success === true){
            addToast({message: adminState?.createLoanContract?.data?.message, status: 'success'})
            removeItem('loanId')
            props?.actions?.cleanUpToast()
            history.push(routesNames.POSTSANCTIONLOANAGREEMENTS)
        } else if (adminState?.createLoanContract?.data?.success === false){
            addToast({message: adminState?.createLoanContract?.data?.message, status: 'error'})
        }
    }, [adminState?.createLoanContract])

    const fileType = {
        panCard: detectFileType(finalData?.user_data?.document_data?.pan_card),
        aadhaarFront: detectFileType(finalData?.user_data?.document_data?.aadhaar_front),
        aadhaarBack: detectFileType(finalData?.user_data?.document_data?.aadhaar_back),
        loanAgreement: detectFileType(finalData?.esign_request?.eSign_doc_file)
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

    const handleRejectLoan = (loanId) => {
        props.actions.rejectLoanContractAction({loan_id: loanId})
    }

    const handleApproveLoan = (loanId) => {
        props.actions.createLoanContractAction({loan_id: loanId})
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
  <>
    {(adminState?.verifyAgreement?.loading || adminState?.getUserByToken?.loading || adminState?.rejectLoanContract?.loading || adminState?.createLoanContract?.loading) &&
    <div>
      <Loader />
    </div>
    }
    <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
                <Heading as="h2" className='font-poppins page_heading'>Loan Agreements</Heading>
            </GridItem>
            <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin />
            </GridItem>
        </Grid>
        <Box mt={8}>
            <Grid className="main_grid_container justify-content-between" pb={4}>
                <GridItem colSpan={7} pr={14}>
                    <Tabs className='tabs_container'>
                        <TabList className='tabs_list'>
                            <Tab>Loan Details</Tab>
                            <Tab>Borrower Details</Tab>
                            <Tab>Investor Details</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel className='tab_panel' mt={6}>
                                <Box className='verify_heading' mb={2}>
                                    <Heading as="h6" className='font-poppins'>Loan Details</Heading>
                                </Box>
                                <Box className='eligible_card select_card bg_shadow'>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Type</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>
                                            {finalData?.loan_name}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>₹ {finalData?.loan_amount}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Purpose</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.loan_purpose}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Loan Tenure</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>
                                            {finalData?.tenure_duration}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Rate Of Interest</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.interest_rate}%</Heading>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel className='tab_panel' mt={6}>
                                <Box className='verify_heading' mb={2}>
                                    <Heading as="h6" className='font-poppins'>Borrower Details</Heading>
                                </Box>
                                <Box className='eligible_card select_card bg_shadow'>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Virtual ID</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.user_data?.escrow_account_id}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Name</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>
                                            {finalData?.user_data?.first_name}{' '}
                                            {finalData?.user_data?.middle_name}{' '}
                                            {finalData?.user_data?.last_name}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>PAN</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.user_data?.pan}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Address</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>
                                            {finalData?.user_data?.personal_data?.address_houseNumber}{' '}
                                            {finalData?.user_data?.personal_data?.address_subdistrict}{' '}
                                            {finalData?.user_data?.personal_data?.address_district}{' '}
                                            {finalData?.user_data?.personal_data?.address_state}{' '}
                                            {finalData?.user_data?.personal_data?.address_pincode}{' '}
                                            {finalData?.user_data?.personal_data?.address_country}
                                        </Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Email Id</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.user_data?.email}</Heading>
                                    </Box>
                                    <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                        <Heading as="h6" className='font-poppins'>Phone number</Heading>
                                        <Heading as="h6" className='font-poppins f-600'>{finalData?.user_data?.mobile}</Heading>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel className='tab_panel' mt={6}>
                                <Box className='verify_heading' mb={2}>
                                    <Heading as="h6" className='font-poppins'>Investor Details</Heading>
                                </Box>
                                {finalData?.investment_bookings.map((item, index) => {
                                    return (
                                        <Box className='eligible_card select_card bg_shadow' key={index} >
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>Virtual ID</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>{item?.user_data?.escrow_account_id}</Heading>
                                            </Box>
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>Name</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>
                                                    {item?.user_data?.first_name}{' '}
                                                    {item?.user_data?.middle_name}{' '}
                                                    {item?.user_data?.last_name}
                                                </Heading>
                                            </Box>
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>PAN</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>{item?.user_data?.pan}</Heading>
                                            </Box>
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>Address</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>
                                                    {item?.user_data?.personal_data?.address_houseNumber}{' '}
                                                    {item?.user_data?.personal_data?.address_subdistrict}{' '}
                                                    {item?.user_data?.personal_data?.address_district}{' '}
                                                    {item?.user_data?.personal_data?.address_state}{' '}
                                                    {item?.user_data?.personal_data?.address_pincode}{' '}
                                                    {item?.user_data?.personal_data?.address_country}
                                                </Heading>
                                            </Box>
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>Email Id</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>{item?.user_data?.email}</Heading>
                                            </Box>
                                            <Box className='d-flex applied_data justify-content-between align-items-center text-right-align' mt={2}>
                                                <Heading as="h6" className='font-poppins'>Phone number</Heading>
                                                <Heading as="h6" className='font-poppins f-600'>{item?.user_data?.mobile}</Heading>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Box className="kyc_actions btn_gap" mt={8}>
                        <CustomButton size="md" type="submit" title="Reject Loan" className="btn_theme_transparent font-poppins" onClick={() => handleRejectLoan(finalData.id)} />
                        <CustomButton size="md" type="submit" title="Approve Loan" className="btn_theme font-poppins" onClick={() => handleApproveLoan(finalData.id)} />
                    </Box>
                </GridItem>
                <GridItem colSpan={5}>
                    <Tabs className='tabs_container'>
                        <TabList className='tabs_list justify-content-center'>
                            <Tab>Loan Agreement</Tab>
                            <Tab>User Documents</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel className='tab_panel'>
                                {finalData?.esign_request?.eSign_doc_file ?
                                    <Box className='document_viewer' mt={6}>
                                        <Heading as="h4" className='font-poppins document_name_heading'>Loan Agreement</Heading>
                                        <Box className='pdf_read' mt={0}>
                                            {fileType.loanAgreement === 'pdf' ?
                                                <Document file={finalData?.esign_request?.eSign_doc_file}>
                                                    <Page pageNumber={1} />
                                                </Document>
                                                : 
                                                <Image src={finalData?.esign_request?.eSign_doc_file} />
                                            }
                                            {finalData?.esign_request?.eSign_doc_file ?
                                                <Box>
                                                    <IconButton onClick={() => handleModal(finalData?.esign_request?.eSign_doc_file, fileType.loanAgreement)} className="icon_button_view" 
                                                        icon={<ViewIcon />}
                                                    />
                                                    <a target='_blank' href={finalData?.esign_request?.eSign_doc_file}>
                                                        <IconButton className="icon_button_download" icon={<Image src={DownloadIcon} className="download-link" />} />
                                                    </a>
                                                </Box> : null 
                                            }
                                        </Box>
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                            <TabPanel className='tab_panel'>
                                {finalData?.user_data?.document_data?.pan_card ?
                                    <Box className='document_viewer' mt={6}>
                                        <Heading as="h4" className='font-poppins document_name_heading'>Pan Card</Heading>
                                        <Box className='pdf_read' mt={0}>
                                            {fileType.panCard === 'pdf' ?
                                                <Document file={finalData?.user_data?.document_data?.pan_card}>
                                                    <Page pageNumber={1} />
                                                </Document>
                                                : 
                                                <Image src={finalData?.user_data?.document_data?.pan_card} className='docs_image' />
                                            }
                                            {finalData?.user_data?.document_data?.pan_card ?
                                                <IconButton onClick={() => handleModal(finalData?.user_data?.document_data?.pan_card, fileType.panCard)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                        </Box>
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                                {finalData?.user_data?.document_data?.aadhaar_front ?
                                    <Box className='document_viewer' mt={6}>
                                        <Heading as="h4" className='font-poppins document_name_heading'>Aadhaar Card Front</Heading>
                                        <Box className='pdf_read' mt={0}>
                                            {fileType.aadhaarFront === 'pdf' ?
                                                <Document file={finalData?.user_data?.document_data?.aadhaar_front}>
                                                    <Page pageNumber={1} />
                                                </Document>
                                                : 
                                                <Image src={finalData?.user_data?.document_data?.aadhaar_front} className='docs_image' />
                                            }
                                            {finalData?.user_data?.document_data?.aadhaar_front ?
                                                <IconButton onClick={() => handleModal(finalData?.user_data?.document_data?.aadhaar_front, fileType.aadhaarFront)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                        </Box>
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                                {finalData?.user_data?.document_data?.aadhaar_back ?
                                    <Box className='document_viewer' mt={6}>
                                        <Heading as="h4" className='font-poppins document_name_heading'>Aadhaar Card Back</Heading>
                                        <Box className='pdf_read' mt={0}>
                                            {fileType.aadhaarBack === 'pdf' ?
                                                <Document file={finalData?.user_data?.document_data?.aadhaar_back}>
                                                    <Page pageNumber={1} />
                                                </Document>
                                                : 
                                                <Image src={finalData?.user_data?.document_data?.aadhaar_back} className='docs_image' />
                                            }
                                            {finalData?.user_data?.document_data?.aadhaar_back ?
                                                <IconButton onClick={() => handleModal(finalData?.user_data?.document_data?.aadhaar_back, fileType.aadhaarBack)} className="icon_button_view" 
                                                    icon={<ViewIcon />} /> : null }
                                        </Box>
                                    </Box> : 
                                    <Box className='pdf_read no-data'>
                                        <Heading as="h6" className='font-poppins'>No document available</Heading>
                                    </Box>
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </GridItem>
            </Grid>
        </Box>
        <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
    </Box>
  </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(VerifyAgreement)