import React, { useEffect, useState } from 'react'
import {Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, IconButton, Image, Text} from '@chakra-ui/react'
import CloseIcon from '../../../../../assets/images/close-side.svg'
import CustomButton from '../../../../elements/customButton'
import moment from 'moment'
export default function ActiveLoanSidebar(props) {
    const [disablePaybtn ,setDisblePaybtn] =useState(false)
    const loanContractId = props?.data?.loanContract[0]?.id
    const repaymentDate = props?.data?.maturity_date && moment(props?.data?.maturity_date).format("DD-MM-YYYY")
    const emiStartDate = props?.data?.loanContract[0]?.emi_date && moment(props?.data?.loanContract[0]?.emi_date).format("DD-MM-YYYY")
    const emiEndDate = props?.data?.maturity_date && moment(props?.data?.maturity_date).format("DD-MM-YYYY")
    const interestAmount = (props?.data?.payment_frequency === 0) ? props?.data?.loanContract[0]?.accured_interset_on_emi : props?.data?.loanContract[0]?.accured_interset_till_date
    const emiAmount = props?.data?.loanContract[0]?.payment_amount
    const totalOutstandingAmount = props?.data?.loanContract[0]?.total_outstanding_dues
    const totalEmiPayAmount = props?.data?.loanContract[0]?.penel_interset_till_date > 0 && props?.data?.loanContract[0]?.accured_interset_till_date > 0 ? props?.data?.loanContract[0]?.total_outstanding_dues : props?.data?.loanContract[0]?.outstanding_amount
    const penalInterest = props?.data?.loanContract[0]?.penel_interset_till_date
    const isPartPay = props?.data?.part_payment
    const isRepaymentActive = props?.data?.loanContract[0]?.disbursal?.[0]?.is_repayment_active === 1
    useEffect(()=>{
        if(props?.data?.loanContract[0]?.disbursal?.[0]?.is_repayment_active == 0){
            setDisblePaybtn(true)
        }
    },[props?.data])
  const PopupBox = () => (
    <Box>
      <DrawerOverlay />
      <DrawerContent>
        <Box className="actions_group">
          <IconButton onClick={props.handleCloseSidebar} className="btn_close_sidebar" icon={<Image src={CloseIcon} />} />
        </Box>
        <DrawerHeader className='sidebar_header'>
            <Box className='loan_type_heading d-flex align-items-center'>
                <Heading as="h5" className='font-poppins'>Loan Application ID</Heading>
                <Text className={props?.data?.product_id === 3 ? "font-poppins table_text loan_insta" : 
                    props?.data?.product_id === 1 ? "font-poppins table_text loan_personal" : 
                    props?.data?.product_id === 2 ? "font-poppins table_text loan_bullet" : 
                    "font-poppins table_text"} >
                    {props?.data?.loan_name}
                </Text>
            </Box>
            <Heading as="h4" className='font-poppins'>{props?.data?.loan_number}</Heading>
        </DrawerHeader>
        <DrawerBody>
            <Box className='sidebar_body_top'>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Loan Status </Heading>
                    <Text className='font-poppins'>{props?.data?.loan_status === "Closing Underprocess" ? props?.data?.loan_status : 'Active'}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Loan Amount</Heading>
                    <Text className='font-poppins'>₹ {props?.data?.loanContract[0]?.pledged_amount}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Disbursed Date</Heading>
                    <Text className='font-poppins'>{props?.data?.loanContract[0]?.disbursal[0]?.disbursal_date && moment(props?.data?.loanContract[0]?.disbursal[0]?.disbursal_date).format("DD-MM-YYYY")}</Text>
                </Box>
                {props?.data?.product_id === 1 ?
                    <>
                        <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                            <Heading as="h6" className='font-poppins'>EMI Amount</Heading>
                            <Text className='font-poppins'>₹ {emiAmount}</Text>
                        </Box>
                        {emiStartDate &&
                            <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                                <Heading as="h6" className='font-poppins'>Upcoming EMI Date</Heading>
                                <Text className='font-poppins'>{emiStartDate}</Text>
                            </Box>
                        }
                        <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                            <Heading as="h6" className='font-poppins'>EMI End Date</Heading>
                            <Text className='font-poppins'>{emiEndDate}</Text>
                        </Box>
                    </> :
                    <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                        <Heading as="h6" className='font-poppins'>Repayment Date</Heading>
                        <Text className='font-poppins'>{repaymentDate}</Text>
                    </Box>
                }
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Tenure</Heading>
                    <Text className='font-poppins'>{props?.data?.tenure_duration}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Interest Rate</Heading>
                    <Text className='font-poppins'>{props?.data?.product_id === 3 ? "0.2% Per Day" : props?.data?.interest_rate + " % Per Annum"}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Interest Amount (Till Date)</Heading>
                    <Text className='font-poppins'>₹ {interestAmount}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Penal Interest Amount</Heading>
                    <Text className='font-poppins'>₹ {penalInterest}</Text>
                </Box>
                <Box className='loan_data_box d-flex align-items-center justify-content-between'>
                    <Heading as="h6" className='font-poppins'>Total Outstanding Amount</Heading>
                    <Text className='font-poppins'>₹ {props?.data?.product_id === 1 ? totalEmiPayAmount : totalOutstandingAmount}</Text>
                </Box>
            </Box>
            <Box mt={2} className='total_amount_box d-flex align-items-center justify-content-between'>
                <Heading as="h6" className='font-poppins'>{props?.data?.product_id === 1 ? 'EMI Due Amount' : 'Repayment Amount'}</Heading>
                <Text className='font-poppins f-600'>₹ {props?.data?.product_id === 1 ? totalEmiPayAmount : totalOutstandingAmount} </Text>
            </Box>
        </DrawerBody>
        <Box className='sidebar_footer' mb={3}>   
            {props?.data?.product_id !== 1 ?
                <Box className='d-flex align-items-center justify-content-between col-gap-15'>
                    {isPartPay && props?.data?.product_id === 3 ?
                        <Box className='full-width'>
                            <CustomButton isDisabled ={disablePaybtn}  size="md" type="submit" title="Pay Part Wise" className="btn_theme_transparent font-poppins" onClick={() => props.handleOpenPartPayDialog()} />
                        </Box> : null
                    }
                    {isRepaymentActive ?
                        <Box className='full-width'>
                            <CustomButton size="md" type="submit" isDisabled ={disablePaybtn}  title="Pay Now" className="btn_theme_transparent font-poppins" onClick={() => props.handleOpenLoanPaymentDialog(totalOutstandingAmount)} />
                        </Box> : null
                    }
                </Box> :
                totalEmiPayAmount > 0 ?
                    <Box className='full-width'>
                        <CustomButton size="md" type="submit" isDisabled ={disablePaybtn}  title="Pay EMI" className="btn_theme_transparent font-poppins" onClick={() => props.handleOpenLoanPaymentDialog(totalEmiPayAmount)} />
                    </Box> : null
            }
            <Box mt={2} className='full-width'>
                <CustomButton size="md" type="submit" title="View More" className="btn_theme font-poppins" onClick={() => props.handleViewMoreDialogOpen(loanContractId)} />
            </Box>
            <Box mt={2} className='full-width'>
                <CustomButton size="md" type="submit" title="Download Agreement" className="btn_green font-poppins" onClick={() => props.handleDownloadAgreement(props.data?.esign_agreement)} />
            </Box>
        </Box>
      </DrawerContent>
    </Box>
  )

  return (
    <Drawer
      isOpen={props.openSidebar}
      placement="right"
      onClose={props.handleCloseSidebar}
      size={'md'}
    >
      {PopupBox()}
    </Drawer>
  )
}
