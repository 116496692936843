import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Table,
  TableContainer,
  IconButton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button
} from "@chakra-ui/react";
import CustomButton from "../../../../elements/customButton";
import Pagination from "../../../../elements/Pagination";
import { ChevronRightIcon } from "@chakra-ui/icons";
import moment from "moment";
import Loader from "../../../../elements/loader";
import { bindActionCreators } from "redux";
import { ActionCreators } from "../../../../../redux/actions";
import { connect, useSelector } from "react-redux";
import NoData from "../../../../elements/NoData";
import * as routesNames from "../../../../../constants/routes.js";
import { removeItem, setItem } from "../../../../../utilities/authUtils.js";
import CustomToast from "../../../../elements/customToast.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import {fetchCancelEnachMandate} from '../../../../../services/adminServices.js'

const ClosedLoan = (props) => {
  const adminState = useSelector((state) => state.adminState);
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [disbled_Staus, setdisbled_Staus] = useState();
  const [disablepresanction, setdisablepresanction] = useState(null); // To track which row's button is clicked
  const current_Collection = adminState?.getClosedLoanData?.data;

  const Cancel_Presanction = adminState?.Cancel_Presanction?.data?.message;
//   const PER_PAGE = 10;
//   const offset = currentPage * PER_PAGE;
//   const pageCount = Math.ceil(filterData?.length / PER_PAGE);
const PER_PAGE =filterData?.limit
const offset = currentPage * PER_PAGE;      
  const pageCount = Math.ceil(filterData?.total_count / PER_PAGE);

  
  const {addToast} = CustomToast()
  useEffect(() => {
    if (props.tabSelected === "Closed Loan") {
        let query= {
            page:currentPage,
            statuses:8
        }
      props?.actions?.getBorrowerClosedAction(query);
    }
    removeItem("ckyc_number")
  }, [props.tabSelected]);

  useEffect(() => {
    if (adminState?.getClosedLoanData?.data?.success === true) {
      setFilterData(current_Collection);
    }
  }, [adminState?.getClosedLoanData]);
  useEffect(() => {
    if (adminState?.getCancel_Mandate_Data?.data?.success === true) {
      addToast({message: Cancel_Presanction, status: 'success'})
      window.location.reload()
    }
    else if (adminState?.getCancel_Mandate_Data?.data?.success === false){
      addToast({message: Cancel_Presanction, status: 'error'})
    }

  }, [adminState?.getCancel_Mandate_Data]);
  const handleSearch = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const result = current_Collection.filter((item) => {
        return (
          item?.mobile?.toLowerCase().includes(value.toLowerCase()) ||
          item?.first_name?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilterData(result);
    } else {
      setFilterData(current_Collection);
    }
  };

  const handlePageClick = ({ selected}) => {
    setCurrentPage(selected+1);
    let query= {
        page: selected + 1,
        statuses:8
    }
    // let obj = {
    //    type :4
    //   }
    props.actions.getBorrowerLoanReportAction( query)
  };
  const cancel_mandate =(id,borrower_id)=>{
    let param={
      loan_id:id,
      // borrower_id:borrower_id
    }
    setdisablepresanction(borrower_id); // Disable the clicked button immediately
    fetchCancelEnachMandate(param).then((res)=>{
      if (res?.data?.success === true) {
        setdisbled_Staus(res)
        
        addToast({message:res?.data?.message,status:"success"})
        props?.actions?.cleanUpToast()
        let query= {
            page:currentPage,
            statuses:8
        }
      props?.actions?.getBorrowerClosedAction(query);
      }
      else{
        addToast({message:res?.data?.message,status:"error"})
        setdisablepresanction(null); 
        props?.actions?.cleanUpToast()
      }
    }).catch((error) => {
      addToast({message: 'Getting Invalid Response From Bank', status: 'error'})
      setdisablepresanction(null); 

})
  }
  return (
    <>
      {(adminState?.getClosedLoanData?.loading ||
        adminState?.getUserByToken?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box mt={1}>
        <Box className="search_box">
          <Input
            type="text"
            size="md"
            name="search"
            placeholder="Search"
            onChange={handleSearch}
            className="input_box font-poppins"
          />
        </Box>
        <>
          {filterData?.data?.length > 0 ? (
            <Box mt={2} className="custom_table_wrapper custom_table_wrapper2">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>S.No.</Th>
                      <Th>Loan Details</Th>
                      <Th>Loan Type</Th>
                      <Th>Loan Amount</Th>
                      <Th>Loan Disbursed Date</Th>
                      <Th>Loan Close Date</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                         {filterData
                      ?.data?.map((item, index) => {
                        const serialNumber = index + 1;
                        return (
                          <Tr key={index}>
                            <Td>
                              <Text className="font-poppins table_text">
                                {serialNumber}
                              </Text>
                            </Td>
                            <Td>
                            <Text className="font-poppins  gray_text">
                                {item?.enach_request?.UMRN ? item?.enach_request?.UMRN : "NA"}
                              </Text>
                              <Td>
                              
                              <Text className="font-poppins table_text">
                                {item?.loan_number ? item?.loan_number : "NA"}
                              </Text>
                            </Td>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.products?.name
                                  ? item?.products?.name
                                  : "Not Assigned"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                              {item?.loan_amount ? `₹ ${item.loan_amount}` : "Not Assigned"}
                                
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.disbursed_date
                                  ? moment(item?.disbursed_date).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : "Not Available"}
                              </Text>
                            </Td>
                            <Td>
                              <Text className="font-poppins table_text">
                                {item?.updated_at
                                  ? moment(item?.updated_at).format("DD-MMM-YYYY")
                                  : "Not Available"}
                              </Text>
                              <Td>
                              <Text className="font-poppins red_text">
                                {item?.failure_response ? item?.failure_response : ""}
                              </Text>
                            </Td>
                            </Td>
                            
                            <Td>
                                      <Text  className="font-poppins table_text">
                                      {item?.enach_request?.status ==2 ? "Mandate Cancelled ":"-"}
                                      </Text>
                            </Td>
                            
                            <Td>
                            {(item?.enach_request?.status !== 2 && item?.enach_request?.UMRN) ? 
                            <Button size="md"  className="btn_table_dark_red-collection font-poppins" disabled={disablepresanction === item?.id } onClick={()=>cancel_mandate(item?.id,item?.borrower_id)}>
                                          
                                Cancel Mandatation
                            </Button>
                            :  

                                <Button size="md"  className="btn_table_dark_red-collection font-poppins" disabled={(item?.enach_request?.status == 2 || !item?.enach_request?.UMRN ) && true}>
                              
                            
                            {/* // <Button size="md"  className="btn_table_dark_red-collection font-poppins" disabled={disablepresanction === item?.id } onClick={()=>cancel_mandate(item?.id,item?.borrower_id)}> */}

                             Cancel Mandatation
                            </Button> 
                                    }
                            </Td>
                            <Td>
                              <Box className="actions_td action_table">
                                <IconButton
                                  onClick={() =>
                                    props.handleVerify(item?.id,item?.borrower_id,5)
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </Box>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </Box>
          ) : (
            <NoData title="No Record Found" />
          )}
        </>
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(ClosedLoan);
