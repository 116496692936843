import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
    getRegistrationReport: {
      data: null,
      loading: false,
      error: null,
    },
    getDCRReport: {
      data: null,
      loading: false,
      error: null,
    },
    getActiveOneShotPayment: {
      data: null,
      loading: false,
      error: null,
    },
    getClosedOneShotPayment: {
      data: null,
      loading: false,
      error: null,
    },
    getActiveMultipleEmiPayment: {
      data: null,
      loading: false,
      error: null,
    },
    getClosedMultipleEmiPayment: {
      data: null,
      loading: false,
      error: null,
    },
    getCKYCReport: {
      data: null,
      loading: false,
      error: null,
    },
  }

  const reportsReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.GET_REGISTRATION_REPORT_REQUEST:
        return {
          ...state,
          getRegistrationReport: {
            ...state.getRegistrationReport,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_REGISTRATION_REPORT_SUCCESS:
        return {
          ...state,
          getRegistrationReport: {
            ...state.getRegistrationReport,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_REGISTRATION_REPORT_FAILURE:
        return {
          ...state,
          getRegistrationReport: {
            ...state.getRegistrationReport,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_DCR_REPORT_REQUEST:
        return {
          ...state,
          getDCRReport: {
            ...state.getDCRReport,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_DCR_REPORT_SUCCESS:
        return {
          ...state,
          getDCRReport: {
            ...state.getDCRReport,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_DCR_REPORT_FAILURE:
        return {
          ...state,
          getDCRReport: {
            ...state.getDCRReport,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.ACTIVE_ONE_SHOT_PAYMENT_REQUEST:
        return {
          ...state,
          getActiveOneShotPayment: {
            ...state.getActiveOneShotPayment,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.ACTIVE_ONE_SHOT_PAYMENT_SUCCESS:
        return {
          ...state,
          getActiveOneShotPayment: {
            ...state.getActiveOneShotPayment,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.ACTIVE_ONE_SHOT_PAYMENT_FAILURE:
        return {
          ...state,
          getActiveOneShotPayment: {
            ...state.getActiveOneShotPayment,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.CLOSED_ONE_SHOT_PAYMENT_REQUEST:
        return {
          ...state,
          getClosedOneShotPayment: {
            ...state.getClosedOneShotPayment,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.CLOSED_ONE_SHOT_PAYMENT_SUCCESS:
        return {
          ...state,
          getClosedOneShotPayment: {
            ...state.getClosedOneShotPayment,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.CLOSED_ONE_SHOT_PAYMENT_FAILURE:
        return {
          ...state,
          getClosedOneShotPayment: {
            ...state.getClosedOneShotPayment,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_REQUEST:
        return {
          ...state,
          getActiveMultipleEmiPayment: {
            ...state.getActiveMultipleEmiPayment,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_SUCCESS:
        return {
          ...state,
          getActiveMultipleEmiPayment: {
            ...state.getActiveMultipleEmiPayment,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.ACTIVE_MULTIPLE_EMI_PAYMENT_FAILURE:
        return {
          ...state,
          getActiveMultipleEmiPayment: {
            ...state.getActiveMultipleEmiPayment,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_REQUEST:
        return {
          ...state,
          getClosedMultipleEmiPayment: {
            ...state.getClosedMultipleEmiPayment,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_SUCCESS:
        return {
          ...state,
          getClosedMultipleEmiPayment: {
            ...state.getClosedMultipleEmiPayment,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.CLOSED_MULTIPLE_EMI_PAYMENT_FAILURE:
        return {
          ...state,
          getClosedMultipleEmiPayment: {
            ...state.getClosedMultipleEmiPayment,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_CKYC_REPORT_REQUEST:
        return {
          ...state,
          getCKYCReport: {
            ...state.getCKYCReport,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_CKYC_REPORT_SUCCESS:
        return {
          ...state,
          getCKYCReport: {
            ...state.getCKYCReport,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_CKYC_REPORT_FAILURE:
        return {
          ...state,
          getCKYCReport: {
            ...state.getCKYCReport,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      default:
        return state
    }
  }
  
  export default reportsReducers