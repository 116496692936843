import React, { useState } from 'react'
import {Box, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import Pagination from '../../../elements/Pagination'
import NoData from '../../../elements/NoData'
import moment from 'moment'

export default function LoanDetailsTable(props) {

    const reportFile = props?.fileUrl
    const reportItems = props?.reportData
    const reportLength = props?.fileLength?.userslength
    const pageCount = Math.ceil(reportLength / props.PER_PAGE);

  return (
    <>
        <Box className='search_box'>
            <Input
                type="text"
                size="md"
                name="search"
                placeholder="Search"
                onChange={(e) => props.handleSearch(e)}
                className="input_box font-poppins"
            />
        </Box>
        {reportItems?.length > 0 ?
            <Box mt={2}>
                <Box className='d-flex justify-content-end'>
                    <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
                </Box>
                <Box mt={3} className='custom_table_wrapper'>
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>S.No</Th>
                                    <Th>Loan Creation Date/Time</Th>
                                    <Th>Loan Number</Th>
                                    <Th>Borrower Name</Th>
                                    <Th>Lender Name</Th>
                                    <Th>Borrower Mobile</Th>
                                    <Th>Loan Type</Th>
                                    <Th>Loan Amount</Th>
                                    <Th>Tenure</Th>
                                    <Th>Rate of Interest</Th>
                                    <Th>Pledged Amount</Th>
                                    <Th>Loan Status</Th>
                                    <Th>Maturity Date</Th>
                                    <Th>Disbursal Date</Th>
                                    <Th>EMI Amount</Th>
                                    <Th>Penalty Amount</Th>
                                    <Th>Total Outstanding Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {reportItems?.map((item, index) => {
                                    const serialNumber = index + 1;
                                    return (
                                        <Tr key={index}>
                                            <Td>
                                                <Text className="font-poppins table_text">{serialNumber}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {moment(item['loan_creation_date']).format("DD MMM YY")}
                                                </Text>
                                                </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['loan_contract_number']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['borrower_name']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['lender_name']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['borrower_mobile']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {(() => {
                                                                const statusMapping = {
                                                                    1: 'Personal Loan',
                                                                    2: 'Bullet Loan',
                                                                    3: 'Insta Loan',
                                                                };
                                                        const status = Number(item['loan_type']);
                                                                return statusMapping[status] || '-';
                                                            })()}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['loan_amount']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['tenure']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['rate_of_interest']}%</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['pledge_amount']}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">
                                                    {Number(item['loan_status']) === 1 && 'Ready For Funding'}
                                                    {Number(item['loan_status']) === 2 && 'In Funding'}
                                                    {Number(item['loan_status']) === 3 && 'Funded Awaiting E-sign'}
                                                    {Number(item['loan_status']) === 4 && 'Ready For Disbursal'}
                                                    {Number(item['loan_status']) === 5 && 'Active Loan'}
                                                    {Number(item['loan_status']) === 6 && 'Cancelled'}
                                                    {Number(item['loan_status']) === 7 && 'Rejected '}
                                                    {Number(item['loan_status']) === 8 && 'Closed'}
                                                    {Number(item['loan_status']) === 9 && ' Withdrwan'}
                                                    {Number(item['loan_status']) === 10 && 'Approved'}
                                                    {Number(item['loan_status']) === 11 && 'Expired'}
                                                    {Number(item['loan_status']) === 12 && 'Closing Underprocess'}
                                                </Text>
                                                
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['maturity_date'] ? item['maturity_date'] : '-'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">{item['disbursal_date'] ? item['disbursal_date'] : '-'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['emi_amount'] ? item['emi_amount'] : '0'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['penalty_amount'] ? item['penalty_amount'] : '0'}</Text>
                                            </Td>
                                            <Td>
                                                <Text className="font-poppins table_text">₹{item['total_outstanding_amount'] ? item['total_outstanding_amount'] : '0'}</Text>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pageCount={pageCount} handlePageClick={props.handlePageClick} />
                </Box>
            </Box> :
            <NoData title="No Record Found" />
        }
    </>
  )
}
