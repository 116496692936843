import apiInstance from '../config/api/axios'
import { getItem } from '../utilities/authUtils'

export const getMobileOTP = async(payload) => {
  const response = await apiInstance.post('/get-mobile-otp', payload)
  return response
}

export const verifyMobileOTP = async(payload) => {
  const response = await apiInstance.post('/verify-mobile-otp', payload)
  return response
}

export const createNewUser = async(payload) => {
  const response = await apiInstance.post('/v2/create-new-user', payload)
  return response
}

export const getCPOTP = async(payload) => {
  const response = await apiInstance.post('/get-cp-otp', payload)
  return response
}

export const verifyCPOTP = async(payload) => {
  const response = await apiInstance.post('/verify-cp-otp', payload)
  return response
}

export const getSalesOTP = async(payload) => {
  const response = await apiInstance.post('/get-sales-otp', payload)
  return response
}

export const verifySalesOTP = async(payload) => {
  const response = await apiInstance.post('/verify-sales-otp', payload)
  return response
}