import React, { useEffect, useState } from 'react'
import { Box, Grid, GridItem, Heading, Input, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import Loader from '../../../elements/loader'
import { useHistory } from 'react-router-dom'
import { removeAll } from '../../../../utilities/authUtils'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import * as routesNames from '../../../../constants/routes'
import CustomButton from '../../../elements/customButton'

const timePeriod = [
    {
        id: 1,
        name: 'Daily',
    },
    {
        id: 2,
        name: 'Weekly'
    },
    {
        id: 3,
        name: 'Monthly'
    }
]

const roiPeriodData = [
    {
        id: 1,
        name: 'Daily',
    },
    {
        id: 2,
        name: 'Yearly'
    },
    {
        id: 3,
        name: 'Fixed'
    }
]

const OwnProducts = (props) => {

    const history = useHistory()
    const adminState = useSelector((state) => state.adminState)
    const [inputValues, setInputValues] = useState({})
    const [paymentRange, setPaymentRange] = useState(10000)
    const [roiRange, setROIRange] = useState(12)
    const [processingFee, setProcessingFee] = useState(5)
    const [investmentFee, setInvestmentFee] = useState(2)
    const [period, setPeriod] = useState(null)
    const [roiPeriod, setROIPeriod] = useState(null)
    const userData = adminState?.getUserByToken?.data?.data

    useEffect(() => {
        props.actions.getOperationDataAction()
        props.actions.getUserByTokenAction()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    const handlePaymentRange = (e) => {
        setPaymentRange(e)
    }

    const handleROIRange = (e) => {
        setROIRange(e)
    }

    const handleSelectPeriod = (id) => {
        setPeriod(id)
    }

    const handleSelectROIPeriod = (id) => {
        setROIPeriod(id)
    }

    const handleProcessingFee = (e) => {
        setProcessingFee(e)
    }

    const handleInvestmentFee = (e) => {
        setInvestmentFee(e)
    }

    const handleSubmit = () => {
        console.log('first')
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <>
        {(adminState?.getUserByToken?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={8}>
                    <Heading as="h2" className='font-poppins page_heading'>Bring Your Own Product</Heading>
                </GridItem>
                <GridItem colSpan={4}>
                    <NavBar data={userData} isAdmin />
                </GridItem>
            </Grid>
            <Box mt={6}>
                <Text className="font-poppins page_description">Welcome to our platform where your product offerings come to life! Easily showcase and add your products with our secure Know Your Customer (KYC) verification and strict underwriting process. With our streamlined procedures, you can confidently introduce new items or update existing ones. Dive in and start adding your products with confidence!</Text>
            </Box>
            <Box mt={8}>
                <Grid className="main_grid_container grid-gap-20">
                    <GridItem colSpan={4}>
                        <Box className='repayment_frequency' mb={8}>
                            <Heading mb={2} className='font-poppins' as="h5">Product Name</Heading>
                            <Input
                                type="text"
                                size="md"
                                name="product_name"
                                placeholder="Please Enter Product Name"
                                onChange={handleChange}
                                className="input_box_admin font-poppins"
                                value={inputValues?.product_name ?? ''}
                            />
                        </Box>
                        <Box className='repayment_frequency' mb={8}>
                            <Heading mb={2} className='font-poppins' as="h5">Repayment Frequency</Heading>
                            <Box className='time_period_select'>
                                {timePeriod?.map((item, index) => {
                                    return (
                                        <Box key={index} onClick={() => handleSelectPeriod(item.id)}
                                            className={period === item.id ? 'time_period_card time_period_card_active' : 'time_period_card time_period_card_normal'} >
                                            <Heading as="h6" className="font-poppins">{item.name}</Heading>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box' mb={6}>
                            <Heading mb={2} className='font-poppins' as="h5">Tenure</Heading>
                            <Grid className="main_grid_container admin_grid_container">
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>Months</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            name="months"
                                            placeholder="Enter Months"
                                            onChange={handleChange}
                                            className="input_box_admin font-poppins"
                                            value={inputValues?.months ?? ''}
                                        />
                                    </Box>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>Days</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            name="days"
                                            placeholder="Enter Days"
                                            onChange={handleChange}
                                            className="input_box_admin font-poppins"
                                            value={inputValues?.days ?? ''}
                                        />
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box'>
                            <Heading mb={2} className='font-poppins' as="h5">Range Payment</Heading>
                            <Grid className="main_grid_container admin_grid_container">
                                <GridItem colSpan={12}>
                                    {/* <RangeSlider value={paymentRange} min={10000} max={300000} step={1000}
                                        onChange={(e) => handlePaymentRange(e)}>
                                        <RangeSliderTrack className='admin_range_slider_bg'>
                                            <RangeSliderFilledTrack bg='#667085' />
                                        </RangeSliderTrack>
                                        <RangeSliderThumb boxSize={4} index={0} className='admin_thumb' />
                                        <RangeSliderThumb boxSize={4} index={1} className='admin_thumb'  />
                                    </RangeSlider> */}
                                    <Slider defaultValue={paymentRange} min={10000} max={300000} step={1000}
                                        onChange={(e) => handlePaymentRange(e)} className='position-relative'>
                                        <Box className='faded_node' />
                                        <SliderTrack className='admin_range_slider_bg'>
                                            <SliderFilledTrack bg='#EAECF0' />
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} className='admin_thumb' />
                                        <Box className='fixed_node' />
                                    </Slider>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>From</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            className="input_box_admin font-poppins text-center"
                                            value={`${'₹'}${paymentRange}`}
                                            isReadOnly
                                        />
                                    </Box>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>To</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            className="input_box_admin font-poppins fixed_input text-center"
                                            value={`${'₹300000'}`}
                                            isReadOnly
                                        />
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box' mb={4}>
                            <Heading mb={2} className='font-poppins' as="h5">Rate of Interest</Heading>
                            <Box className='time_period_select' mb={3}>
                                {roiPeriodData?.map((item, index) => {
                                    return (
                                        <Box key={index} onClick={() => handleSelectROIPeriod(item.id)}
                                            className={roiPeriod === item.id ? 'time_period_card time_period_card_active' : 'time_period_card time_period_card_normal'} >
                                            <Heading as="h6" className="font-poppins">{item.name}</Heading>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Grid className="main_grid_container admin_grid_container">
                                <GridItem colSpan={12} mb={2}>
                                    <Slider defaultValue={roiRange} min={12} max={36} step={1}
                                        onChange={(e) => handleROIRange(e)} className='position-relative'>
                                        <Box className='faded_node' />
                                        <SliderTrack className='admin_range_slider_bg'>
                                            <SliderFilledTrack bg='#EAECF0' />
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} className='admin_thumb' />
                                        <Box className='fixed_node' />
                                        <SliderMark value={roiRange} className='data-label-slider font-poppins' mt='2.5' ml='-5' w='9'>
                                            {roiRange}%
                                        </SliderMark>
                                    </Slider>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>From</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            className="input_box_admin font-poppins text-center"
                                            value={`${roiRange}${'%'}`}
                                            isReadOnly
                                        />
                                    </Box>
                                </GridItem>
                                <GridItem colSpan={6}>
                                    <Box className='admin_product_card'>
                                        <Text className='font-poppins'>To</Text>
                                        <Input
                                            type="text"
                                            size="md"
                                            className="input_box_admin font-poppins fixed_input text-center"
                                            value={`${'36%'}`}
                                            isReadOnly
                                        />
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box' mb={4}>
                            <Heading mb={2} className='font-poppins' as="h5">Processing Fee</Heading>
                            <Box className='slider_data_box d-flex align-items-center justify-content-between' mb={3}>
                                <Text className='font-poppins'>Your Margin</Text>
                                <Heading as="h5" className='font-poppins'>0.5%</Heading>
                            </Box>
                            <Box className='admin_grid_container'>
                                <Slider defaultValue={processingFee} min={3} max={8} step={1}
                                    onChange={(e) => handleProcessingFee(e)} className='position-relative'>
                                    <Box className='faded_node_data'>
                                        <Text className='font-poppins'>3%</Text>
                                    </Box>
                                    <SliderTrack className='admin_range_slider_bg_fee'>
                                        <SliderFilledTrack bg='transparent' />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4} className='admin_thumb fee_thumb' />
                                    <Box className='fixed_node_data'>
                                        <Text className='font-poppins'>8%</Text>
                                    </Box>
                                    <SliderMark value={processingFee} className='data-label-slider font-poppins' mt='3.5' ml='-5' w='9'>
                                        {processingFee}%
                                    </SliderMark>
                                </Slider>
                            </Box>
                        </Box>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box'>
                            <Heading mb={2} className='font-poppins' as="h5">Investment Fee</Heading>
                            <Box className='slider_data_box d-flex align-items-center justify-content-between' mb={3}>
                                <Text className='font-poppins'>Your Margin</Text>
                                <Heading as="h5" className='font-poppins'>0.5%</Heading>
                            </Box>
                            <Box className='admin_grid_container'>
                                <Slider defaultValue={investmentFee} min={1} max={3} step={1}
                                    onChange={(e) => handleInvestmentFee(e)} className='position-relative'>
                                    <Box className='faded_node_data'>
                                        <Text className='font-poppins'>1%</Text>
                                    </Box>
                                    <SliderTrack className='admin_range_slider_bg_fee'>
                                        <SliderFilledTrack bg='transparent' />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4} className='admin_thumb fee_thumb' />
                                    <Box className='fixed_node_data'>
                                        <Text className='font-poppins'>3%</Text>
                                    </Box>
                                    <SliderMark value={investmentFee} className='data-label-slider font-poppins' mt='3.5' ml='-5' w='9'>
                                        {investmentFee}%
                                    </SliderMark>
                                </Slider>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box' mb={4}>
                            <Heading mb={2} className='font-poppins' as="h5">Processing Fee</Heading>
                            <Box className='slider_data_box d-flex align-items-center justify-content-between'>
                                <Text className='font-poppins'>Your Margin</Text>
                                <Heading as="h5" className='font-poppins'>0.5%</Heading>
                            </Box>
                        </Box>
                        <Box className='repayment_frequency bg_shadow admin_product_card_box'>
                            <Heading mb={2} className='font-poppins' as="h5">Investment Fee</Heading>
                            <Box className='slider_data_box d-flex align-items-center justify-content-between'>
                                <Text className='font-poppins'>Your Margin</Text>
                                <Heading as="h5" className='font-poppins'>0.5%</Heading>
                            </Box>
                        </Box>
                    </GridItem>
                </Grid>
                <Box mt={4} className='d-flex justify-content-end'>
                    <CustomButton size="md" type="submit" title="Submit" className="btn_green font-poppins" onClick={handleSubmit} />
                </Box>
            </Box>
        </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  })
  
  export default connect(null, mapDispatchToProps)(OwnProducts)
  