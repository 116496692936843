import React from "react";
import { Box, Heading } from '@chakra-ui/react'
import { Stepper, Step } from 'react-form-stepper';
import { CheckCircleIcon } from '@chakra-ui/icons'

const RevenueStepper = (props) => {
    
    return (
        <Box className='investor_chart' mt={4}>
            <Heading as="h2" className='font-poppins block_heading' mb={3}>Month-wise Interest & Principal Received </Heading>
            <Stepper activeStep={props?.stepData?.length - 1} connectorStateColors={true} connectorStyleConfig={props.style} >
                {props?.stepData?.map((step, index) => (
                    <Step key={index} className="custom-stepper-investor" children={step.status === 'Complete' ? <CheckCircleIcon /> : step.status === 'Active' ? <Box className="on_going_month" /> : <Box className="current_month" />}
                        label={
                            <>
                                <Heading as="h5" className={step.status === 'Complete' ? "font-poppins step_data_title_active" : step.status === 'Active' ? "font-poppins step_data_title_active" : "font-poppins step_data_title"}>
                                    {step.month} {step.year}
                                </Heading>
                                <Box mt={3}>
                                    <Heading as="p" className="font-poppins step_data_label"> Intrest Earned: ₹{step.interest_recieved?.toFixed(2)} </Heading>
                                    <Heading as="p" className="font-poppins step_data_label"> Principal Received: ₹{step.principal_recieved?.toFixed(2)} </Heading>
                                </Box>
                            </>
                        }
                    />
                ))}
            </Stepper>
        </Box>
    )
}

export default RevenueStepper