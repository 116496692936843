import React from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Image, Input, Text, Tooltip} from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import UploadIcon from '../../../assets/images/upload.svg'
import DownloadIcon from '../../../assets/images/download.png'
import { Document, Page, pdfjs } from 'react-pdf';
import { InfoOutlineIcon } from '@chakra-ui/icons'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function InstitutionalDocuments({refs, fileData, userData, rejectData, isReject, pageNumber, showOpenFileDialog, onChangeFile}) {

  const displayGst = () => {
    if (typeof(fileData?.gst) === 'string') {
      return (
        <>
          {fileData?.gst && (fileData?.gst.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.gst} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.gst} download="uploaded_gst.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.gst} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.gst && (fileData?.gst.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.gst} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.gst)} download="uploaded_gst.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.gst ? URL.createObjectURL(fileData?.gst) : UploadIcon}
              className={fileData?.gst ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayRbiLicense = () => {
    if (typeof(fileData?.rbi) === 'string') {
      return (
        <>
          {fileData?.rbi && (fileData?.rbi.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.rbi} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.rbi} download="uploaded_rbi.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.rbi} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.rbi && (fileData?.rbi.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.rbi} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.rbi)} download="uploaded_rbi.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.rbi ? URL.createObjectURL(fileData?.rbi) : UploadIcon}
              className={fileData?.rbi ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayAddressProof = () => {
    if (typeof(fileData?.addressProof) === 'string') {
      return (
        <>
          {fileData?.addressProof && (fileData?.addressProof.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.addressProof} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.addressProof} download="uploaded_addressProof.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.addressProof} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.addressProof && (fileData?.addressProof.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.addressProof} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.addressProof)} download="uploaded_addressProof.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.addressProof ? URL.createObjectURL(fileData?.addressProof) : UploadIcon}
              className={fileData?.addressProof ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayboardResolution = () => {
    if (typeof(fileData?.boardResolution) === 'string') {
      return (
        <>
          {fileData?.boardResolution && (fileData?.boardResolution.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.boardResolution} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.boardResolution} download="uploaded_boardResolution.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.boardResolution} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.boardResolution && (fileData?.boardResolution.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.boardResolution} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.boardResolution)} download="uploaded_boardResolution.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.boardResolution ? URL.createObjectURL(fileData?.boardResolution) : UploadIcon}
              className={fileData?.boardResolution ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayCobCertificate = () => {
    if (typeof(fileData?.cobCertificate) === 'string') {
      return (
        <>
          {fileData?.cobCertificate && (fileData?.cobCertificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.cobCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.cobCertificate} download="uploaded_cobCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.cobCertificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.cobCertificate && (fileData?.cobCertificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.cobCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.cobCertificate)} download="uploaded_cobCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.cobCertificate ? URL.createObjectURL(fileData?.cobCertificate) : UploadIcon}
              className={fileData?.cobCertificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayMemAssociation = () => {
    if (typeof(fileData?.memAssociation) === 'string') {
      return (
        <>
          {fileData?.memAssociation && (fileData?.memAssociation.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.memAssociation} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.memAssociation} download="uploaded_memAssociation.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.memAssociation} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.memAssociation && (fileData?.memAssociation.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.memAssociation} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.memAssociation)} download="uploaded_memAssociation.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.memAssociation ? URL.createObjectURL(fileData?.memAssociation) : UploadIcon}
              className={fileData?.memAssociation ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayBankStatement = () => {
    if (typeof(fileData?.bankStatement) === 'string') {
      return (
        <>
          {fileData?.bankStatement && (fileData?.bankStatement.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.bankStatement} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.bankStatement} download="uploaded_bankStatement.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.bankStatement} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.bankStatement && (fileData?.bankStatement.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.bankStatement} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.bankStatement)} download="uploaded_bankStatement.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.bankStatement ? URL.createObjectURL(fileData?.bankStatement) : UploadIcon}
              className={fileData?.bankStatement ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayIncorpCertificate = () => {
    if (typeof(fileData?.incorpCertificate) === 'string') {
      return (
        <>
          {fileData?.incorpCertificate && (fileData?.incorpCertificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.incorpCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.incorpCertificate} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.incorpCertificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.incorpCertificate && (fileData?.incorpCertificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.incorpCertificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.incorpCertificate)} download="uploaded_incorpCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.incorpCertificate ? URL.createObjectURL(fileData?.incorpCertificate) : UploadIcon}
              className={fileData?.incorpCertificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  
    const displaUdyamCertificate = () => {
      console.info('displaUdyamCertificatedisplaUdyamCertificate', fileData?.udyam_certificate)
    if (typeof(fileData?.udyam_certificate) === 'string') {
      return (
        <>
          {fileData?.udyam_certificate && (fileData?.udyam_certificate.includes('.pdf')) ?
            <Box className='pdf_block'>
              {console.info('fileDatafileDatafileDatafileData', fileData)}
              <Document file={fileData?.udyam_certificate} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.udyam_certificate} download="uploaded_udyamCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.udyam_certificate} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.udyam_certificate && (fileData?.udyam_certificate.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.udyam_certificate} >
                {console.info('fileDatafileDatafileDatafileData', fileData)}
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.udyam_certificate)} download="uploaded_udyamCertificate.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.udyam_certificate ? URL.createObjectURL(fileData?.udyam_certificate) : UploadIcon}
              className={fileData?.udyam_certificate ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }
  return (
    <>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">GST Certificate
              <Tooltip label="Either GST certificate to be provided or Undertaking on letterhead to be uploaded."  hasArrow fontSize='xs' placement='bottom-start'>
                <InfoOutlineIcon ml={1} />
              </Tooltip>
            </Heading>
            {rejectData?.gst_registration_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.gst_registration_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayGst()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.gst ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('gst')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'gst')}
                className="d-none"
                ref={refs?.gst}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      {userData?.employment_type === '4' ?
          <GridItem>
            <Box className='form_kyc'>
              <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                <Heading as="h1" className="font-poppins label_heading">RBI Licence copy</Heading>
                {rejectData?.rbi_licence_copy_reject_reason &&
                  <Text className="font-poppins">Admin Comment
                    <Tooltip label={rejectData?.rbi_licence_copy_reject_reason}
                      hasArrow fontSize='xs' placement='bottom-start'>
                      <InfoOutlineIcon />
                    </Tooltip>
                  </Text>
                }
              </Box>
              <Box className='upload_container'>
                {displayRbiLicense()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.rbi ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('rbi')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'rbi')}
                    className="d-none"
                    ref={refs?.rbi}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
              </Box>
            </Box>
          </GridItem> :
          <GridItem>
            <Box className='form_kyc'>
              <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                <Heading as="h1" className="font-poppins label_heading">Address Proof</Heading>
                {rejectData?.additional_address_proof &&
                  <Text className="font-poppins">Admin Comment
                    <Tooltip label={rejectData?.additional_address_proof}
                      hasArrow fontSize='xs' placement='bottom-start'>
                      <InfoOutlineIcon />
                    </Tooltip>
                  </Text>
                }
              </Box>
              <Box className='upload_container'>
                {displayAddressProof()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.addressProof ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('addressProof')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'addressProof')}
                    className="d-none"
                    ref={refs?.addressProof}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
              </Box>
            </Box>
          </GridItem>
      }
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Board resolution</Heading>
            {rejectData?.board_resolution_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.board_resolution_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayboardResolution()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.boardResolution ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('boardResolution')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'boardResolution')}
                className="d-none"
                ref={refs?.boardResolution}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Certificate of commencement of the business</Heading>
            {rejectData?.cob_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.cob_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayCobCertificate()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.cobCertificate ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('cobCertificate')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'cobCertificate')}
                className="d-none"
                ref={refs?.cobCertificate}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Memorandum and Articles of Association</Heading>
            {rejectData?.mem_art_association_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.mem_art_association_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayMemAssociation()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.memAssociation ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('memAssociation')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'memAssociation')}
                className="d-none"
                ref={refs?.memAssociation}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Consecutive Bank Statement for 3 Months</Heading>
            {rejectData?.v2_bank_statement_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.v2_bank_statement_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayBankStatement()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.bankStatement ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('bankStatement')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'bankStatement')}
                className="d-none"
                ref={refs?.bankStatement}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
      <GridItem>
        <Box className='form_kyc'>
          <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
            <Heading as="h1" className="font-poppins label_heading">Certification of Incorporation</Heading>
            {rejectData?.incorp_certificate_reject_reason &&
              <Text className="font-poppins">Admin Comment
                <Tooltip label={rejectData?.incorp_certificate_reject_reason}
                  hasArrow fontSize='xs' placement='bottom-start'>
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
            }
          </Box>
          <Box className='upload_container'>
            {displayIncorpCertificate()}
              <CustomButton
                className="btn_theme font-poppins"
                title={fileData?.incorpCertificate ? 'Re-Upload' : 'Browse'}
                onClick={() => showOpenFileDialog('incorpCertificate')}
              />
              <Input
                size="md"
                name="upload"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => onChangeFile(e, 'incorpCertificate')}
                className="d-none"
                ref={refs?.incorpCertificate}
              />
              <Heading mt={4} as="p" className="font-poppins note">
                Supported File .png, .jpg & .pdf Upto 5 MB
              </Heading>
          </Box>
        </Box>
      </GridItem>
     {
      userData?.employment_type == 5 &&
      <GridItem>
      <Box className='form_kyc'>
                <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                  <Heading as="h1" className="font-poppins label_heading">Registerationion on Udyam Portal (Optional)</Heading>
                  {rejectData?.udyam_certificate_reject_reason &&
                    <Text className="font-poppins">Admin Comment
                      <Tooltip label={rejectData?.udyam_certificate_reject_reason}
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Text>
                  }
                </Box>
                <Box className='upload_container'>
                  {displaUdyamCertificate()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.udyam_certificate ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('udyam_certificate')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'udyam_certificate')}
                    className="d-none"
                    ref={refs?.udyam_certificate}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
                </Box>
              </Box>
      </GridItem>
     }
    </>
  )
}
