import React from 'react'
import { Box, Heading, Input } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'

export default function ProfileEscrowDetails(props) {

    const escrow_details = props?.unmaskedData?.escrow_details
    const accountName = `${props?.userData?.[0]?.first_name}${' '}${props?.userData?.[0]?.last_name}`

  return (
    <Box mt={7}>
        <Box className='details_container'>
            <Box mb={3} className='d-flex justify-content-end'>
                <CustomButton size="md" type="submit" title="Show Details" className="btn_theme font-poppins" onClick={() => props.handleShowDetails('escrow_details')} />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Account Number</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={props?.unmaskedData?.escrow_account_id ? props?.unmaskedData?.escrow_account_id : (props?.userData?.[0]?.escrow_account_id ?props?.userData?.[0]?.escrow_account_id : "N/A" )} 
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Account Holder Name</Heading>
                <Input size="md" type="text" className="font-poppins" value={accountName.trim().length > 0 ? accountName : 'N/A'} isReadOnly />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank IfSC</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={escrow_details?.bank_ifsc ? escrow_details?.bank_ifsc : props?.userData?.[1]?.bank_ifsc ? props?.userData?.[1]?.bank_ifsc : 'N/A'} 
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank Name</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={escrow_details?.bank_name ? escrow_details?.bank_name : props?.userData?.[1]?.bank_name ? props?.userData?.[1]?.bank_name : 'N/A'} 
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank Branch</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={escrow_details?.bank_branch ? escrow_details?.bank_branch : props?.userData?.[1]?.bank_branch ? props?.userData?.[1]?.bank_branch : 'N/A'} 
                />
            </Box>
            <Box mb={4} className='details_box'>
                <Heading as="h5" className='font-poppins'>Bank City</Heading>
                <Input size="md" type="text" className="font-poppins" isReadOnly
                    value={escrow_details?.bank_city ? escrow_details?.bank_city : props?.userData?.[1]?.bank_city ? props?.userData?.[1]?.bank_city : 'N/A'} 
                />
            </Box>
        </Box>
    </Box>
  )
}
