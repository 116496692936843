/* eslint-disable no-unused-vars */
import * as adminServices from '../../services/adminServices'
import * as actionTypes from './actionsTypes'

//actions for get user by token
const getUserByTokenRequest = () => {
  return {
    type: actionTypes.GET_USER_BY_TOKEN_REQUEST,
  }
}

const getUserByTokenSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_BY_TOKEN_SUCCESS,
    payload: data,
  }
}

const getUserByTokenFailure = (error) => {
  return {
    type: actionTypes.GET_USER_BY_TOKEN_FAILURE,
    payload: error,
  }
}

//getUserByToken action
export const getUserByTokenAction = (payload) =>
  async(dispatch) => {
    dispatch(getUserByTokenRequest())
    try {
      const responseData = await adminServices.getUserByToken(payload)
      if (responseData?.status === 200) {
        dispatch(getUserByTokenSuccess(responseData?.data))
      } else {
        dispatch(getUserByTokenFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getUserByTokenFailure(error?.response?.data))
    }
  }

//actions for get operations dashboard data
const getOperationDataRequest = () => {
  return {
    type: actionTypes.GET_OPERATION_DATA_REQUEST,
  }
}

const getOperationDataSuccess = (data) => {
  return {
    type: actionTypes.GET_OPERATION_DATA_SUCCESS,
    payload: data,
  }
}

const getOperationDataFailure = (error) => {
  return {
    type: actionTypes.GET_OPERATION_DATA_FAILURE,
    payload: error,
  }
}

//getOperationData action
export const getOperationDataAction = (payload) =>
  async(dispatch) => {
    dispatch(getOperationDataRequest())
    try {
      const responseData = await adminServices.getOperationData(payload)
      if (responseData?.status === 200) {
        dispatch(getOperationDataSuccess(responseData?.data))
      } else {
        dispatch(getOperationDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getOperationDataFailure(error?.response?.data))
    }
  }

//actions for get getApprovedBorrowerLoanData
const getApprovedBorrowerLoanDataRequest = () => {
  return {
    type: actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_REQUEST,
  }
}

const getApprovedBorrowerLoanDataSuccess = (data) => {
  return {
    type: actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_SUCCESS,
    payload: data,
  }
}

const getApprovedBorrowerLoanDataFailure = (error) => {
  return {
    type: actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_FAILURE,
    payload: error,
  }
}

//getApprovedBorrowerLoanData action
export const getApprovedBorrowerLoanDataAction = (payload) =>
  async(dispatch) => {
    dispatch(getApprovedBorrowerLoanDataRequest())
    try {
      const responseData = await adminServices.getApprovedBorrowerLoanData(payload)
      if (responseData?.status === 200) {
        dispatch(getApprovedBorrowerLoanDataSuccess(responseData?.data))
      } else {
        dispatch(getApprovedBorrowerLoanDataFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getApprovedBorrowerLoanDataFailure(error?.response?.data))
    }
  }

//actions for get user details by id
const getUserDetailsByIdRequest = () => {
  return {
    type: actionTypes.GET_USER_DETAILS_BY_ID_REQUEST,
  }
}

const getUserDetailsByIdSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS,
    payload: data,
  }
}

const getUserDetailsByIdFailure = (error) => {
  return {
    type: actionTypes.GET_USER_DETAILS_BY_ID_FAILURE,
    payload: error,
  }
}

//getUserDetailsById action
export const getUserDetailsByIdAction = (payload) =>
  async(dispatch) => {
    dispatch(getUserDetailsByIdRequest())
    try {
      const responseData = await adminServices.getUserDetailsById(payload)
      if (responseData?.status === 200) {
        dispatch(getUserDetailsByIdSuccess(responseData?.data))
      } else {
        dispatch(getUserDetailsByIdFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getUserDetailsByIdFailure(error?.response?.data))
    }
  }

//actions for post Borrower expenses
const postBorrowerExpensesRequest = () => {
  return {
    type: actionTypes.POST_BORROWER_EXPENSES_REQUEST,
  }
}

const postBorrowerExpensesSuccess = (data) => {
  return {
    type: actionTypes.POST_BORROWER_EXPENSES_SUCCESS,
    payload: data,
  }
}

const postBorrowerExpensesFailure = (error) => {
  return {
    type: actionTypes.POST_BORROWER_EXPENSES_FAILURE,
    payload: error,
  }
}

//postBorrowerExpenses action
export const postBorrowerExpensesAction = (payload, data) =>
  async(dispatch) => {
    dispatch(postBorrowerExpensesRequest())
    try {
      const responseData = await adminServices.postBorrowerExpenses(payload)
      if (responseData?.status === 200) {
        dispatch(postBorrowerExpensesSuccess(responseData?.data))
        dispatch(getUserDetailsByIdAction(data))
      } else {
        dispatch(postBorrowerExpensesFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(postBorrowerExpensesFailure(error?.response?.data))
    }
  }

//actions for post reject Borrower
const postRejectBorrowerRequest = () => {
  return {
    type: actionTypes.POST_REJECT_BORROWER_REQUEST,
  }
}

const postRejectBorrowerSuccess = (data) => {
  return {
    type: actionTypes.POST_REJECT_BORROWER_SUCCESS,
    payload: data,
  }
}

const postRejectBorrowerFailure = (error) => {
  return {
    type: actionTypes.POST_REJECT_BORROWER_FAILURE,
    payload: error,
  }
}

//postRejectBorrower action
export const postRejectBorrowerAction = (payload) =>
  async(dispatch) => {
    dispatch(postRejectBorrowerRequest())
    try {
      const responseData = await adminServices.rejectBorrower(payload)
      if (responseData?.status === 200) {
        dispatch(postRejectBorrowerSuccess(responseData?.data))
      } else {
        dispatch(postRejectBorrowerFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(postRejectBorrowerFailure(error?.response?.data))
  }
}

//actions for post approve Borrower
const postApproveBorrowerRequest = () => {
  return {
    type: actionTypes.POST_APPROVE_BORROWER_REQUEST,
  }
}

const postApproveBorrowerSuccess = (data) => {
  return {
    type: actionTypes.POST_APPROVE_BORROWER_SUCCESS,
    payload: data,
  }
}

const postApproveBorrowerFailure = (error) => {
  return {
    type: actionTypes.POST_APPROVE_BORROWER_FAILURE,
    payload: error,
  }
}

//postApproveBorrower action
export const postApproveBorrowerAction = (payload) =>
  async(dispatch) => {
    dispatch(postApproveBorrowerRequest())
    try {
      const responseData = await adminServices.approveBorrower(payload)
      if (responseData?.status === 200) {
        dispatch(postApproveBorrowerSuccess(responseData?.data))
      } else {
        dispatch(postApproveBorrowerFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(postApproveBorrowerFailure(error?.response?.data))
    }
}

//actions for get new account request
const getNewAccountRequest = () => {
  return {
    type: actionTypes.GET_NEW_ACCOUNT_REQUEST,
  }
}

const getNewAccountSuccess = (data) => {
  return {
    type: actionTypes.GET_NEW_ACCOUNT_SUCCESS,
    payload: data,
  }
}

const getNewAccountFailure = (error) => {
  return {
    type: actionTypes.GET_NEW_ACCOUNT_FAILURE,
    payload: error,
  }
}

//getNewAccount action
export const getNewAccountAction = (payload) =>
  async(dispatch) => {
    dispatch(getNewAccountRequest())
    try {
      const responseData = await adminServices.getNewAccountRequest(payload)
      if (responseData?.status === 200) {
        dispatch(getNewAccountSuccess(responseData?.data))
      } else {
        dispatch(getNewAccountFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getNewAccountFailure(error?.response?.data))
    }
}

//actions for get e-signed loan list
const getESignedLoanListRequest = () => {
  return {
    type: actionTypes.GET_ESIGNED_LOAN_LIST_REQUEST,
  }
}

const getESignedLoanListSuccess = (data) => {
  return {
    type: actionTypes.GET_ESIGNED_LOAN_LIST_SUCCESS,
    payload: data,
  }
}

const getESignedLoanListFailure = (error) => {
  return {
    type: actionTypes.GET_ESIGNED_LOAN_LIST_FAILURE,
    payload: error,
  }
}

//getESignedLoanList action
export const getESignedLoanListAction = (payload) =>
  async(dispatch) => {
    dispatch(getESignedLoanListRequest())
    try {
      const responseData = await adminServices.getESignedLoanList(payload)
      if (responseData?.status === 200) {
        dispatch(getESignedLoanListSuccess(responseData?.data))
      } else {
        dispatch(getESignedLoanListFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(getESignedLoanListFailure(error?.response?.data))
    }
}

//actions for verify agreement
const verifyAgreementRequest = () => {
  return {
    type: actionTypes.VERIFY_AGREEMENT_REQUEST,
  }
}

const verifyAgreementSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_AGREEMENT_SUCCESS,
    payload: data,
  }
}

const verifyAgreementFailure = (error) => {
  return {
    type: actionTypes.VERIFY_AGREEMENT_FAILURE,
    payload: error,
  }
}

//verifyAgreement action
export const verifyAgreementAction = (payload) =>
  async(dispatch) => {
    dispatch(verifyAgreementRequest())
    try {
      const responseData = await adminServices.verifyAgreement(payload)
      if (responseData?.status === 200) {
        dispatch(verifyAgreementSuccess(responseData?.data))
      } else {
        dispatch(verifyAgreementFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(verifyAgreementFailure(error?.response?.data))
    }
}

//actions for approve user
const approveUserRequest = () => {
  return {
    type: actionTypes.APPROVE_USER_REQUEST,
  }
}

const approveUserSuccess = (data) => {
  return {
    type: actionTypes.APPROVE_USER_SUCCESS,
    payload: data,
  }
}

const approveUserFailure = (error) => {
  return {
    type: actionTypes.APPROVE_USER_FAILURE,
    payload: error,
  }
}

//approveUser action
export const approveUserAction = (payload) =>
  async(dispatch) => {
    dispatch(approveUserRequest())
    try {
      const responseData = await adminServices.approveUser(payload)
      if (responseData?.status === 200) {
        dispatch(approveUserSuccess(responseData?.data))
      } else {
        dispatch(approveUserFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(approveUserFailure(error?.response?.data))
    }
}

//actions for reject loan contract
const rejectLoanContractRequest = () => {
  return {
    type: actionTypes.REJECT_LOAN_CONTRACT_REQUEST,
  }
}

const rejectLoanContractSuccess = (data) => {
  return {
    type: actionTypes.REJECT_LOAN_CONTRACT_SUCCESS,
    payload: data,
  }
}

const rejectLoanContractFailure = (error) => {
  return {
    type: actionTypes.REJECT_LOAN_CONTRACT_FAILURE,
    payload: error,
  }
}

//rejectLoanContract action
export const rejectLoanContractAction = (payload) =>
  async(dispatch) => {
    dispatch(rejectLoanContractRequest())
    try {
      const responseData = await adminServices.rejectLoanContract(payload)
      if (responseData?.status === 200) {
        dispatch(rejectLoanContractSuccess(responseData?.data))
      } else {
        dispatch(rejectLoanContractFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(rejectLoanContractFailure(error?.response?.data))
    }
}

//actions for create loan contract
const createLoanContractRequest = () => {
  return {
    type: actionTypes.CREATE_LOAN_CONTRACT_REQUEST,
  }
}

const createLoanContractSuccess = (data) => {
  return {
    type: actionTypes.CREATE_LOAN_CONTRACT_SUCCESS,
    payload: data,
  }
}

const createLoanContractFailure = (error) => {
  return {
    type: actionTypes.CREATE_LOAN_CONTRACT_FAILURE,
    payload: error,
  }
}

//createLoanContract action
export const createLoanContractAction = (payload) =>
  async(dispatch) => {
    dispatch(createLoanContractRequest())
    try {
      const responseData = await adminServices.createLoanContract(payload)
      if (responseData?.status === 200) {
        dispatch(createLoanContractSuccess(responseData?.data))
      } else {
        dispatch(createLoanContractFailure(responseData.errors))
      }
    } catch (error) {
      dispatch(createLoanContractFailure(error?.response?.data))
    }
}
//actions for get current collection request
const getCurrentCollectionRequest = () => {
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_REQUEST,
  };
};

const getCurrentCollectionSuccess = (data) => {
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_SUCCESS,
    payload: data,
  };
};

const getCurrentCollectionFailure = (error) => {
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_FAILURE,
    payload: error,
  };
};

//getCurrentCollection action
export const getCurrentCollectionAction = (payload) => 
  async (dispatch) => {
    console.info('payloadpayloadpayloadpayload', payload)
    dispatch(getCurrentCollectionRequest());
    try {
      const responseData = await adminServices.fetchCurrentCollection(payload);
      console.log("responseData", responseData);
      if (responseData?.status === 200) {
        console.log("success");
        dispatch(getCurrentCollectionSuccess(responseData?.data));
      } else {
        console.log("failure errors");
        dispatch(getCurrentCollectionFailure(responseData.errors));
      }
    } catch (error) {
      console.log("failure errors");
      dispatch(getCurrentCollectionFailure(error?.response?.data));
    }
};


//actions for get current loan collection request
const getCurrentCollectionLoanidRequest = () => {
  console.info('asdasdasdasdasdasd',  actionTypes.CREATE_LOAN_CONTRACT_FAILURE)
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_Loanid_REQUEST,
  };
};

const getCurrentCollectionLoanidSuccess = (data) => {
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_Loanid_SUCCESS,
    payload: data,
  };
};

const getCurrentCollectionLoanidFailure = (error) => {
  return {
    type: actionTypes.GET_CURRENT_COLLECTION_Loanid_FAILURE,
    payload: error,
  };
};

//getCurrentCollection action
export const getCurrentCollectionLoanidAction = (params, payload) => 
  async (dispatch) => {
    console.info('only', payload)
    console.info('payloaoad', payload?.loan_id)
    console.info('params', params)
    dispatch(getCurrentCollectionLoanidRequest());
    try {
      const responseData = await adminServices.fetchCurrentCollection_Loan_id(params,payload);
      console.log("responseData", responseData);
      if (responseData?.status === 200) {
        console.log("success");
        dispatch(getCurrentCollectionLoanidSuccess(responseData?.data));
      } else {
        console.log("failure errors");
        dispatch(getCurrentCollectionLoanidFailure(responseData.errors));
      }
    } catch (error) {
      console.log("failure errors");
      dispatch(getCurrentCollectionLoanidFailure(error?.response?.data));
    }
};

const getCOllection_LoanidDetilsRequest = () => {
  console.info('asdasdasdasdasdasd',  actionTypes.CREATE_LOAN_CONTRACT_FAILURE)
  return {
    type: actionTypes.GET_COllection_LoanidDetils_Request,
  };
};

const getCOllection_LoanidDetilsSucess = (data) => {
  return {
    type: actionTypes.GET_COllection_LoanidDetils_SUCCESS,
    payload: data,
  };
};

const getCOllection_LoanidDetilsFailure = (error) => {
  return {
    type: actionTypes.GET_COllection_LoanidDetils_FAILURE,
    payload: error,
  };
};

export const getCOllection_LoanidDetilsAction = (payload) => 
  async (dispatch) => {
    dispatch(getCOllection_LoanidDetilsRequest());
    try {
      const responseData = await adminServices.fetchdata_LoanidDetilsRequest(payload);
      console.log("responseData", responseData);
      if (responseData?.status === 200) {
        dispatch(getCOllection_LoanidDetilsSucess(responseData?.data));
      } else {
        dispatch(getCOllection_LoanidDetilsFailure(responseData.errors));
      }
    } catch (error) {
      dispatch(getCOllection_LoanidDetilsFailure(error?.response?.data));
    }
};



///Reinitiate

const getReiniate_DetailsRequest = () => {
  // console.info('asdasdasdasdasdasd',  actionTypes.CREATE_LOAN_CONTRACT_FAILURE)
  return {
    type: actionTypes.GET_Reiniate_Details_Request,
  };
};

const getReiniate_DetailsSucess = (data) => {
  return {
    type: actionTypes.GET_Reiniate_Details_SUCCESS,
    payload: data,
  };
};

const getReiniate_DetailsFailure = (error) => {
  return {
    type: actionTypes.GET_Reiniate_Details_FAILURE,
    payload: error,
  };
};

export const getReiniateAction = (payload) => 
  async (dispatch) => {
    dispatch(getReiniate_DetailsRequest());
    try {
      const responseData = await adminServices.fetchDateInitiate(payload);
      if (responseData?.status === 200) {
        dispatch(getReiniate_DetailsSucess(responseData?.data));
      } else {
        dispatch(getReiniate_DetailsFailure(responseData.errors));
      }
    } catch (error) {
      dispatch(getReiniate_DetailsFailure(error?.response?.data));
    }
};

///CLosed Loans

const getClosedLoanRequest = () => {
  return {
    type: actionTypes.GET_CLOSED_LOAN_REQUEST,
  };
};

const getClosedLoanSucess = (data) => {
  return {
    type: actionTypes.GET_CLOSED_LOAN_SUCCESS,
    payload: data,
  };
};

const getClosedLoanFailure = (error) => {
  return {
    type: actionTypes.GET_CLOSED_LOAN_FAILURE,
    payload: error,
  };
};

export const getBorrowerClosedAction = (query) =>
async(dispatch) => {
  dispatch(getClosedLoanRequest())
  try {
    // const headers = getAuthHeaders();
    const responseData = await adminServices.fetchClosedLoan(query)
    if (responseData?.status === 200) {
      dispatch(getClosedLoanSucess(responseData?.data))
    } else {
      dispatch(getClosedLoanFailure(responseData.errors))
    }
  } catch (error) {
    dispatch(getClosedLoanFailure(error?.response?.data))
}
}

///cancel Presanctation

const getCancelEnachMandateRequest = () => {
  return {
    type: actionTypes.GET_CANCELEENACHMANDATE_REQUEST,
  };
};

const getCancelEnachMandateSucess = (data) => {
  return {
    type: actionTypes.GET_CANCELEENACHMANDATE_SUCCES,
    payload: data,
  };
};

const getCancelEnachMandateFailure = (error) => {
  return {
    type: actionTypes.GET_CANCELEENACHMANDATE_FAILURE,
    payload: error,
  };
};

export const getCancelEnachMandateAction = (query) =>
async(dispatch) => {
  dispatch(getCancelEnachMandateRequest())
  try {
    const responseData = await adminServices.fetchCancelEnachMandate(query)
    if (responseData?.status === 200) {
      dispatch(getCancelEnachMandateSucess(responseData?.data))
    } else {
      dispatch(getCancelEnachMandateFailure(responseData.errors))
    }
  } catch (error) {
    dispatch(getCancelEnachMandateFailure(error?.response?.data))
}
}
