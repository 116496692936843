import { Box, Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../../../elements/customButton'
import Lottie from "lottie-react";
import KarzaIcon from '../../../../assets/images/karza.png'
import LoanSuccessAnimation from "../../../../assets/lottiFiles/loanSuccess.json";

export default function LoanAppliedDialog(props) {

    const PopupBox = () => (
        <Box className='kyc_complete_popup'>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Box className='kyc_image'>
                        <Lottie animationData={LoanSuccessAnimation} loop={false} />
                    </Box>
                    <Heading mb={3} className="success_modal_title font-poppins">Congratulations</Heading>
                    <Heading mb={4} as="p" className='font-poppins success_modal_subtitle'>
                        Your loan application has been created. Please wait for 3 days for your application to be funded.
                    </Heading>
                    <Heading as="p" className='font-poppins kyc_modal_description'>
                        Note: You will be able to apply for a new loan only after the expiry of 3 Days.
                    </Heading>
                    <Box className='kyc_modal_actions'>
                        <CustomButton size="md" type="submit" title="View Loan Application Status" className="btn_theme" onClick={props.handleGoToLoanStatus} />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseLoanSuccess}
        isOpen={props.loanSuccess}
        motionPreset="slideInBottom"
        size={'xl'}
    >
    {PopupBox()}
    </Modal>
  )
}
