import React, { useState } from 'react'
import { Box, Heading, Image, Grid, GridItem, IconButton } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton'
import { Document, Page, pdfjs } from 'react-pdf';
import { detectFileType } from '../../../../utilities/DownloadDocuments';
import { ViewIcon } from '@chakra-ui/icons';
import OpenFIleDialog from '../../../elements/OpenFIleDialog';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ProfileDocumentDetails(props) {

    const doc_details = props?.unmaskedData?.doc_details
    const [openModal, setOpenModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [currentFileType, setCurrentFileType] = useState(null)

    const fileType = {
        panCard: detectFileType(doc_details?.pan_card),
        aadhaarFront: detectFileType(doc_details?.aadhaar_front),
        aadhaarBack: detectFileType(doc_details?.aadhaar_back),
    }

    const handleModal = (data, fileType) => {
        setOpenModal(!openModal)
        setModalData(data)
        setCurrentFileType(fileType)
    }

  return (
    <Box mt={7}>
        <Box className='details_container'>
            <Box mb={3} className='d-flex justify-content-end'>
                <CustomButton size="md" type="submit" title="Show Details" className="btn_theme font-poppins" onClick={() => props.handleShowDetails('doc_details')} />
            </Box>
            <Grid className='document_grid_container'>
                <GridItem colSpan={6}>
                    <Box mb={4} className='details_box'>
                        <Heading as="h5" className='font-poppins'>Photo</Heading>
                        <Image src={props?.userData?.[0]?.photo} />
                    </Box>
                </GridItem>
                <GridItem colSpan={6}>
                    <Box mb={4} className='details_box'>
                        <Heading as="h5" className='font-poppins'>PAN Card</Heading>
                        {doc_details?.pan_card ?
                            <Box className='pdf_read' mt={0}>
                                {fileType.panCard === 'pdf' ?
                                    <Document file={doc_details?.pan_card}>
                                        <Page pageNumber={1} />
                                    </Document>
                                    : 
                                    <Image src={doc_details?.pan_card} className='docs_image' />
                                }
                                {doc_details?.pan_card ?
                                <IconButton onClick={() => handleModal(doc_details?.pan_card, fileType.panCard)} className="icon_button_view" 
                                    icon={<ViewIcon />} /> : null }
                            </Box> : 
                            <Image className={!doc_details?.pan_card ? 'blur_image' : null}
                            src={doc_details?.pan_card ? doc_details?.pan_card : props?.userData?.[0]?.document_data?.pan_card}
                        />
                        }
                    </Box>
                </GridItem>
                <GridItem colSpan={6}>
                    <Box mb={4} className='details_box'>
                        <Heading as="h5" className='font-poppins'>Aadhaar Card Front</Heading>
                        {doc_details?.aadhaar_front ?
                            <Box className='pdf_read' mt={0}>
                                {fileType.aadhaarFront === 'pdf' ?
                                    <Document file={doc_details?.aadhaar_front}>
                                        <Page pageNumber={1} />
                                    </Document>
                                    : 
                                    <Image src={doc_details?.aadhaar_front} className='docs_image' />
                                }
                                {doc_details?.aadhaar_front ?
                                <IconButton onClick={() => handleModal(doc_details?.aadhaar_front, fileType.aadhaarFront)} className="icon_button_view" 
                                    icon={<ViewIcon />} /> : null }
                            </Box> : 
                            <Image className={!doc_details?.aadhaar_front ? 'blur_image' : null}
                            src={doc_details?.aadhaar_front ? doc_details?.aadhaar_front : props?.userData?.[0]?.document_data?.aadhaar_front}
                        />
                        }
                    </Box>
                </GridItem>
                <GridItem colSpan={6}>
                    <Box mb={4} className='details_box'>
                        <Heading as="h5" className='font-poppins'>Aadhaar Card Back</Heading>
                        {doc_details?.aadhaar_back ?
                            <Box className='pdf_read' mt={0}>
                                {fileType.aadhaarBack === 'pdf' ?
                                    <Document file={doc_details?.aadhaar_back}>
                                        <Page pageNumber={1} />
                                    </Document>
                                    : 
                                    <Image src={doc_details?.aadhaar_back} className='docs_image' />
                                }
                                {doc_details?.aadhaar_back ?
                                <IconButton onClick={() => handleModal(doc_details?.aadhaar_back, fileType.aadhaarBack)} className="icon_button_view" 
                                    icon={<ViewIcon />} /> : null }
                            </Box> : 
                            <Image className={!doc_details?.aadhaar_back ? 'blur_image' : null}
                            src={doc_details?.aadhaar_back ? doc_details?.aadhaar_back : props?.userData?.[0]?.document_data?.aadhaar_back}
                        />
                        }
                    </Box>
                </GridItem>
            </Grid>
        </Box>
        <OpenFIleDialog handleModal={handleModal} openModal={openModal} fileType={currentFileType} modalData={modalData} />
    </Box>
  )
}
