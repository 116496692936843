import React, { useEffect, useRef, useState } from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Image, Input, Text, Tooltip} from '@chakra-ui/react'
import CustomButton from '../../elements/customButton'
import UploadIcon from '../../../assets/images/upload.svg'
import DownloadIcon from '../../../assets/images/download.png'
import { Document, Page, pdfjs } from 'react-pdf';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../redux/actions'
import CustomToast from '../../elements/customToast'
import Loader from '../../elements/loader'
import InstitutionalDocuments from './InstitutionalDocuments'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import SoloPrioDocument from './SoloPrioDocument'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadDocuments = (props) => {

  const {addToast} = CustomToast()
  const kycState = useSelector((state) => state.kycState)
  const masterState = useSelector((state) => state.masterState)
  const refs = {
    photo: useRef(),
    pan: useRef(),
    adharFront: useRef(),
    adharBack: useRef(),
    gst: useRef(),
    rbi: useRef(),
    addressProof: useRef(),
    boardResolution: useRef(),
    cobCertificate: useRef(),
    memAssociation: useRef(),
    bankStatement: useRef(),
    incorpCertificate: useRef(),
    partnership_deed:useRef(),
    udyam_certificate:useRef(),
    reg_act_certificate:useRef(),
  }
  const [fileData, setFileData] = useState({
    photo: null,
    pan: null,
    adharFront: null,
    adharBack: null,
    gst: null,
    rbi: null,
    addressProof: null,
    boardResolution: null,
    cobCertificate: null,
    memAssociation: null,
    bankStatement: null,
    incorpCertificate: null,
    partnership_deed:null,
    udyam_certificate:null,
    reg_act_certificate:null,
  })
  const [pageNumber, setPageNumber] = useState(1)
  const isReject = props?.userData?.document_data?.doc_approval_status === 1
  const rejectData = (props?.userData?.user_document_checked_data?.document && JSON.parse(props?.userData?.user_document_checked_data?.document))

  useEffect(() => {
    let documentData = {
      photo: props?.userData?.photo,
      pan: props?.userData?.document_data?.pan_card,
      adharFront: props?.userData?.document_data?.aadhaar_front,
      adharBack: props?.userData?.document_data?.aadhaar_back,
      gst: props?.userData?.document_data?.gst_registration_certificate,
      rbi: props?.userData?.document_data?.rbi_licence_copy,
      boardResolution: props?.userData?.document_data?.board_resolution,
      cobCertificate: props?.userData?.document_data?.cob_certificate,
      memAssociation: props?.userData?.document_data?.mem_art_association,
      bankStatement: props?.userData?.document_data?.bank_statement_1,
      incorpCertificate: props?.userData?.document_data?.incorp_certificate,
      addressProof: props?.userData?.document_data?.additional_address_proof,
      partnership_deed: props?.userData?.document_data?.partnership_deed,
      udyam_certificate: props?.userData?.document_data?.udyam_certificate,
      reg_act_certificate: props?.userData?.document_data?.reg_act_certificate,
      
    }
    setFileData(documentData)
  }, [props?.userData?.document_data])

  useEffect(() => {
    if (kycState?.saveDocs?.data?.success === true){
      addToast({message: kycState?.saveDocs?.data?.message, status: 'success'})
      props?.actions?.cleanUpToast()
      props?.actions?.getCurrentUserDetailsAction()
      props.onClick()
    } else if (kycState?.saveDocs?.data?.success === false){
      addToast({message: kycState?.saveDocs?.data?.message, status: 'error'})
    }
  }, [kycState?.saveDocs])

  // useEffect(() => {
  //   return () => {
  //     props?.actions?.cleanUpToast()
  //   }
  // }, [])

  const showOpenFileDialog = (type) => {
    refs[type]?.current?.click()
  }

  const onChangeFile = (e, type) => {
    setFileData({ ...fileData, [type]: e.target.files[0]})
    setPageNumber(1)
  }

  const handleNext = () => {
    if (!props?.userData?.personal_data) {
      addToast({message: 'Please Complete Personal Details First', status: 'error'})
      return
    }
    if (!props?.userData?.bank_data) {
      addToast({message: 'Please Complete Bank Details First', status: 'error'})
      return
    }

    const { photo, pan, adharFront, adharBack, gst, rbi, addressProof, boardResolution, cobCertificate, memAssociation, bankStatement, incorpCertificate,udyam_certificate, reg_act_certificate,partnership_deed } = fileData

    if (!props?.userData?.document_data || !props?.userData?.photo) {
      if (!photo) {
        addToast({message: 'Please Upload Photo', status: 'error'})
        return
      }
      if (!pan) {
        addToast({message: 'Please Upload Pan Card', status: 'error'})
        return
      }
      if (!adharFront) {
        addToast({message: 'Please Upload Aadhaar Front', status: 'error'})
        return
      }
      if (!adharBack) {
        addToast({message: 'Please Upload Aadhaar Back', status: 'error'})
        return
      }
      if (props?.user?.employment_type === '4' || props?.user?.employment_type === '5') {
        if (!gst) {
          addToast({message: 'Please Upload GST Certificate', status: 'error'})
          return
        }
        if (props?.user?.employment_type === '4') {
          if (!rbi) {
            addToast({message: 'Please Upload RBI License Copy', status: 'error'})
            return
          }
        }
        if (props?.user?.employment_type === '5') {
          if (!addressProof) {
            addToast({message: 'Please Upload Address Proof', status: 'error'})
            return
          }
        }
        if (!boardResolution) {
          addToast({message: 'Please Upload Board Resolution', status: 'error'})
          return
        }
        if (!cobCertificate) {
          addToast({message: 'Please Upload Certificate of commencement of the business', status: 'error'})
          return
        }
        if (!memAssociation) {
          addToast({message: 'Please Upload Memorandum and Articles of Association', status: 'error'})
          return
        }
        if (!bankStatement) {
          addToast({message: 'Please Upload Bank Statement', status: 'error'})
          return
        }
        if (!incorpCertificate) {
          addToast({message: 'Please Upload Certification of Incorporation', status: 'error'})
          return
        }
      }
      if (props?.user?.employment_type === '6' || props?.user?.employment_type === '7') {
        if (!gst) {
          addToast({message: 'Please Upload GST Certificate', status: 'error'})
          return
        }
        if (!bankStatement) {
          addToast({message: 'Please Upload Bank Statement', status: 'error'})
          return
        }
        if (!incorpCertificate) {
          addToast({message: 'Please Upload Certification of Business', status: 'error'})
          return
        }
        if (props?.user?.employment_type === '7') {
          if (!partnership_deed) {
            addToast({message: 'Please Upload Partnership_deed Proof', status: 'error'})
            return
          }
        }
     
      }
    }

    let docsData = {
      photo: photo ?? '',
      pan: pan ?? '',
      aadhaarFront: adharFront ?? '',
      aadhaarBack: adharBack ?? '',
      gst: gst ?? '',
      rbi: rbi ?? '',
      boardResolution: boardResolution ?? '',
      cobCertificate: cobCertificate ?? '',
      memAssociation: memAssociation ?? '',
      incorpCertificate: incorpCertificate ?? '',
      bankStatement: bankStatement ?? '',
      addressProof: addressProof ?? '',
      udyam_certificate: udyam_certificate ?? '',
      partnership_deed: partnership_deed ?? '',
      employment_type: props?.userData?.employment_type,
      reg_act_certificate:reg_act_certificate ?? ''
      
    }

    if (props?.userData?.document_data && props?.userData?.document_data?.doc_approval_status === 1) {
      props.actions.saveDocsAction(docsData)
    } else if (!props?.userData?.document_data) {
      props.actions.saveDocsAction(docsData)
    } else if (props?.userData?.document_data && props?.userData?.document_data?.doc_approval_status === 0) {
      props.onClick()
    }

    // const hasDataChanged = () => {
    //   return (
    //     photo !== props?.userData?.photo ||
    //     pan !== props?.userData?.document_data?.pan_card ||
    //     adharFront !== props?.userData?.document_data?.aadhaar_front ||
    //     adharBack !== props?.userData?.document_data?.aadhaar_back
    //     // gst !== props?.userData?.document_data?.gst ||
    //     // rbi !== props?.userData?.document_data?.rbi ||
    //     // addressProof !== props?.userData?.document_data?.address_proof ||
    //     // boardResolution !== props?.userData?.document_data?.board_resolution ||
    //     // cobCertificate !== props?.userData?.document_data?.cob_certificate ||
    //     // memAssociation !== props?.userData?.document_data?.mem_association ||
    //     // incorpCertificate !== props?.userData?.document_data?.incorp_certificate ||
    //     // bankStatement !== props?.userData?.document_data?.bank_statement
    //   )
    // }
    // if (hasDataChanged()) {
    //   props.actions.saveDocsAction(docsData)
    // } else {
    //   props.onClick()
    // }
  }

  const displayPhoto = () => {
    if (typeof(fileData?.photo) === 'string') {
      return (
        <Image mb={4} src={fileData?.photo} className="uploaded_image" />
      )
    } else {
      return (
        <Image
          mb={4}
          src={fileData?.photo ? URL.createObjectURL(fileData?.photo) : UploadIcon}
          className={fileData?.photo ? "uploaded_image" : 'upload_icon'}
        />
      )
    }
  }

  const displayPan = () => {
    if (typeof(fileData?.pan) === 'string') {
      return (
        <>
          {fileData?.pan && (fileData?.pan.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.pan} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.pan} download="uploaded_pan.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.pan} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.pan && (fileData?.pan.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.pan} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.pan)} download="uploaded_pan.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.pan ? URL.createObjectURL(fileData?.pan) : UploadIcon}
              className={fileData?.pan ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayAadhaarFront = () => {
    if (typeof(fileData?.adharFront) === 'string') {
      return (
        <>
          {fileData?.adharFront && (fileData?.adharFront.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.adharFront} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.adharFront} download="uploaded_adharFront.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.adharFront} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.adharFront && (fileData?.adharFront.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.adharFront} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.adharFront)} download="uploaded_adharFront.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.adharFront ? URL.createObjectURL(fileData?.adharFront) : UploadIcon}
              className={fileData?.adharFront ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  const displayAadhaarBack = () => {
    if (typeof(fileData?.adharBack) === 'string') {
      return (
        <>
          {fileData?.adharBack && (fileData?.adharBack.includes('.pdf')) ?
            <Box className='pdf_block'>
              <Document file={fileData?.adharBack} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={fileData?.adharBack} download="uploaded_adharBack.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image mb={4} src={fileData?.adharBack} className="uploaded_image" />
          }
        </>
      )
    } else {
      return (
        <>
          {fileData?.adharBack && (fileData?.adharBack.type === 'application/pdf') ?
            <Box className='pdf_block'>
              <Document file={fileData?.adharBack} >
                <Page pageNumber={pageNumber} className="preview_pdf" />
              </Document>
              <a href={URL.createObjectURL(fileData?.adharBack)} download="uploaded_adharBack.pdf">
                <IconButton className="icon_button" icon={<Image src={DownloadIcon} className="download-link" />} />
              </a>
            </Box> :
            <Image
              mb={4}
              src={fileData?.adharBack ? URL.createObjectURL(fileData?.adharBack) : UploadIcon}
              className={fileData?.adharBack ? "uploaded_image" : 'upload_icon'}
            />
          }
        </>
      )
    }
  }

  return (
    <>
    {(kycState?.saveDocs?.loading || masterState?.getCurrentUserDetails?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <Box className='main_form_container'>
          <Heading as="h1" className="font-poppins text_heading">Upload Documents</Heading>
          <Grid className="documents_grid_container" mb={7} mt={7}>
            <GridItem>
              <Box className='form_kyc'>
                <Heading as="h1" className="font-poppins label_heading" mb={3}>Photo</Heading>
                <Box className='upload_container'>
                  {displayPhoto()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.photo ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('photo')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => onChangeFile(e, 'photo')}
                    className="d-none"
                    ref={refs?.photo}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg Upto 5 MB
                  </Heading>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box className='form_kyc'>
                <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                            <Heading as="h1" className="font-poppins label_heading">
              {(props?.userData?.employment_type === '6') && 'Upload Proprietor PAN'}
              {(props?.userData?.employment_type === '7') && 'Upload PAN of the Firm'}
              {(props?.userData?.employment_type !== '6' && props?.userData?.employment_type !== '7') && 'Upload PAN'}
                              </Heading>
                              {rejectData?.v2_pan_reject_reason &&
                    <Text className="font-poppins">Admin Comment
                      <Tooltip label={rejectData?.v2_pan_reject_reason}
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Text>
                  }
                </Box>
                <Box className='upload_container'>
                  {displayPan()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.pan ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('pan')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'pan')}
                    className="d-none"
                    ref={refs?.pan}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box className='form_kyc'>
                <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                  <Heading as="h1" className="font-poppins label_heading">Upload Aadhaar Front</Heading>
                  {rejectData?.v2_aadhar_reject_reason &&
                    <Text className="font-poppins">Admin Comment
                      <Tooltip label={rejectData?.v2_aadhar_reject_reason}
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Text>
                  }
                </Box>
                <Box className='upload_container'>
                  {displayAadhaarFront()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.adharFront ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('adharFront')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'adharFront')}
                    className="d-none"
                    ref={refs?.adharFront}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box className='form_kyc'>
                <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                  <Heading as="h1" className="font-poppins label_heading">Upload Aadhaar Back</Heading>
                  {rejectData?.v2_aadhar_reject_reason &&
                    <Text className="font-poppins">Admin Comment
                      <Tooltip label={rejectData?.v2_aadhar_reject_reason}
                        hasArrow fontSize='xs' placement='bottom-start'>
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Text>
                  }
                </Box>
                <Box className='upload_container'>
                  {displayAadhaarBack()}
                  <CustomButton
                    className="btn_theme font-poppins"
                    title={fileData?.adharBack ? 'Re-Upload' : 'Browse'}
                    onClick={() => showOpenFileDialog('adharBack')}
                  />
                  <Input
                    size="md"
                    name="upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => onChangeFile(e, 'adharBack')}
                    className="d-none"
                    ref={refs?.adharBack}
                  />
                  <Heading mt={4} as="p" className="font-poppins note">
                    Supported File .png, .jpg & .pdf Upto 5 MB
                  </Heading>
                </Box>
              </Box>
            </GridItem>
            {props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5' ?
              <InstitutionalDocuments
                refs={refs}
                fileData={fileData} isReject={isReject} rejectData={rejectData}
                userData={props?.userData} pageNumber={pageNumber}
                showOpenFileDialog={showOpenFileDialog} onChangeFile={onChangeFile}
              /> :
              null
            }
              {props?.userData?.employment_type === '6' || props?.userData?.employment_type === '7'  ?
              <SoloPrioDocument
                refs={refs}
                fileData={fileData} isReject={isReject} rejectData={rejectData}
                userData={props?.userData} pageNumber={pageNumber}
                showOpenFileDialog={showOpenFileDialog} onChangeFile={onChangeFile}
              /> :
              null
            }
          </Grid>
          
          <Box className="kyc_actions">
            <CustomButton size="md" type="submit" title="Next" className="btn_green font-poppins" onClick={handleNext} />
          </Box>
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(UploadDocuments)