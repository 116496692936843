import React, { useEffect, useState } from 'react'
import { Box,Checkbox, Flex, FormLabel, Grid, GridItem, Heading, Input, InputGroup, InputRightElement, Select, Spacer, Switch, Text, Tooltip } from '@chakra-ui/react'
import CustomButton from '../../../elements/customButton';
import CustomToast from '../../../elements/customToast';
import { ActionCreators } from '../.././../../redux/actions'
import { connect, useSelector } from 'react-redux';
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { bindActionCreators } from 'redux';
import Loader from '../../../elements/loader';
import { DIGI_LOCKER_SDK } from '../../../../constants/Constant';
import Multiselect from 'multiselect-react-dropdown';
// import InstitutionalPan from './InstitutionalPan';
import { searchPinCode } from '../../../../services/masterServices';
import { getKycEmailOtp, verifyKycEmailOtp } from '../../../../services/kycServices';
import { getItem, setItem } from '../../../../utilities/authUtils';
import { preventInvalidKey } from '../../../../utilities/CustomFunctions';
import { searchCPPinCode, verifyCPPanCard } from '../../../../services/cpServices';
import OnBoardInstitutionalPan from './OnBoardInstitutionalPan';

const genderData = [
  {
    id: 'M',
    name: 'Male'
  },
  {
    id: 'F',
    name: 'Female'
  },
]

const OnBoardPersonalDetails = (props) => {

  const { addToast } = CustomToast()
  const kycState = useSelector((state) => state.kycState)
  const cpState = useSelector((state) => state.cpState)
  const masterState = useSelector((state) => state.masterState)
  const [inputValues, setInputValues] = useState({})
  const [checkSwitch, setCheckSwitch] = useState(false)
  const [checkAddress, setCheckAddress] = useState(false)
  const [otpStatus, setOtpStatus] = useState(null)
  const [verifyStatus, setVerifyStatus] = useState(null)
  const [panData, setPanData] = useState(null)
  const [timerCount, setTimerCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [aadhaarVerified, setAadhaarVerified] = useState(null)
  const [investmentValue, setInvestmentValue] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  let requestId = cpState?.createCPDigiLocker?.data?.request_id
  let email = props?.userData?.email
  const dropdownData = masterState?.getDropdownData?.data?.data

  const digioSdkInstantiation = async () => {
    try {
      const options = {
        environment:"production",
        callback: async function (response) {
          if (response.hasOwnProperty("error_code")) {
            setLoading(false);
            setAadhaarVerified(null)
          } else {
            setAadhaarVerified(response)
          }
        },
        is_iframe: true,
        Is_redirection_approach: false,
        theme: {
          primaryColor: "#000",
          secondaryColor: "#000000"
        }
      };
      var digio = new window.Digio(options);
      digio.init();
      digio.submit(requestId, email);
    } catch (error) {
      console.error("Error in digioSdkInstantiation:", error);
    }
  };

  const investmentTypeKeys = props?.userData?.preference_data?.investment_type.split(',');
  const investmentTypeValues = props?.userData?.preference_data?.pref_investment_types.split(',');

  const selectedValues = investmentTypeKeys?.map((key, index) => {
   return { key, value: investmentTypeValues[index] };
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = DIGI_LOCKER_SDK;
    script.async = true;
    document.body.appendChild(script);
    props.actions.getDropdownDataAction({ type: 'employment_type_lender|employment_type_borrower|premise_status|educational_qualification|loan_type|loan_tenure|risk_appetite',platform:'web'})
  }, [])

  useEffect(() => {
    let formData = {
      ...inputValues,
      email: props?.userData?.email,
      pan: props?.userData?.pan,
      dob: props?.userData?.dob,
      first_name: props?.userData?.first_name,
      last_name: props?.userData?.last_name,
      middle_name: props?.userData?.middle_name,
      premise_status: props?.userData?.personal_data?.premise_status,
      educational_qualification: props?.userData?.personal_data?.educational_qualification,
      aadhar_number: props?.userData?.aadhar_card_data?.aadhar_number,
      fatherName: props?.userData?.aadhar_card_data?.fatherName,
      address_district: props?.userData?.aadhar_card_data?.address_district,
      address_houseNumber: props?.userData?.aadhar_card_data?.address_houseNumber,
      address_pincode: props?.userData?.aadhar_card_data?.address_pincode,
      address_state: props?.userData?.aadhar_card_data?.address_state,
      address_subdistrict: props?.userData?.aadhar_card_data?.address_subdistrict,
      gender: props?.userData?.aadhar_card_data?.gender,
      otp: props?.userData?.email ? true : false,
      pref_amount: props?.userData?.preference_data?.amount,
      pref_risk_appetite: props?.userData?.preference_data?.risk_appetite,
      pref_tenure: props?.userData?.preference_data?.tenure,
      // legal_name: props?.userData?.legal_name,
      current_address: props?.userData?.personal_data?.current_address,
      current_addressline2: props?.userData?.personal_data?.current_addressline2,
      current_city: props?.userData?.personal_data?.current_city,
      current_pincode: props?.userData?.personal_data?.current_pincode,
      current_state: props?.userData?.personal_data?.current_state,
      legal_name: props?.userData?.legal_name,
      aadhar_dob: props?.userData?.aadhar_card_data?.dob,
      is_current_address_same: props?.userData?.personal_data?.is_current_address_same,
      employment_type: props?.userData?.employment_type
    }
    setInputValues(JSON.parse(JSON.stringify(formData)))
    if (props?.userData?.preference_data?.investment_type) {
      setInvestmentValue(props?.userData?.preference_data?.investment_type)
    }
    if (props?.userData?.personal_data?.is_current_address_same){
      setCheckAddress(props?.userData?.personal_data?.is_current_address_same)
    }
  }, [props?.userData])

  useEffect(() => {
    if (requestId && email) {
      digioSdkInstantiation()
      props?.actions?.cleanUpToast()
    }
  }, [requestId, email])

  useEffect(() => {
    if (kycState?.postPersonalDetails?.data?.success === true) {
      addToast({ message: kycState?.postPersonalDetails?.data?.message, status: 'success' })
      props?.actions?.cleanUpToast()
      props?.actions?.getCPUserDetailsByIdAction({user_id: props?.userData?.id})
      props.onClick()
    } else if (kycState?.postPersonalDetails?.data?.success === false) {
      addToast({ message: kycState?.postPersonalDetails?.data?.message, status: 'error' })
      props?.actions?.cleanUpToast()
    }
  }, [kycState?.postPersonalDetails])

  useEffect(() => {
    let data = {
      request_id: aadhaarVerified?.digio_doc_id,
      mobile: props?.userData?.mobile,
    }
    let userByIdData = {
      user_id: props?.userData?.id,
      // user_id: '1456'
    }
    if (aadhaarVerified?.digio_doc_id) {
      addToast({ message: aadhaarVerified?.message, status: 'success' })
      props?.actions?.getDownloadCPAadharDataAction(data, userByIdData)
      // props.actions.getDropdownDataAction({ type: 'employment_type_lender|employment_type_borrower|premise_status|educational_qualification|loan_type|loan_tenure|risk_appetite'})
    }
  }, [aadhaarVerified])

  useEffect(() => {
    let data = {
      request_id: aadhaarVerified?.digio_doc_id,
      mobile: props?.userData?.mobile,
    }
    let userByIdData = {
      user_id: props?.userData?.id,
      // user_id: '1456'
    }
    if (cpState?.getdownloadCPAadhaarData?.data?.success === 'false') {
      props?.actions?.getDownloadCPAadharDataAction(data, userByIdData)
    } else if (cpState?.getdownloadCPAadhaarData?.data?.success === 'true') {
      return
    }
  }, [cpState?.getdownloadCPAadhaarData])

  useEffect(() => {
    let timer;
    if (timerCount > 0) {
      timer = setTimeout(() => setTimerCount(timerCount - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [timerCount])

  const premiseStatusOptions = dropdownData?.premise_status
  const qualificationsOptions = dropdownData?.educational_qualification
  const investmentType = dropdownData?.loan_type
  const loanTenure = dropdownData?.loan_tenure
  const riskAppetite = dropdownData?.risk_appetite
  const userType = props?.userData?.usertype

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'otp') {
      const truncatedOTP = value.slice(0, 6);
      setInputValues({ ...inputValues, [name]: truncatedOTP });
      if (truncatedOTP.length === 6) {
        let otpData = {
          email: inputValues?.email,
          otp: truncatedOTP,
          mobile: props?.userData?.mobile,
        }
        setIsLoading(true)
        verifyKycEmailOtp(otpData).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true) {
            console.log(res?.data, 'res?.data')
            setVerifyStatus(res?.data?.success)
            addToast({ message: res?.data?.message, status: 'success' })
            setTimerCount(0)
            let userByIdData = {
              user_id: props?.userData?.id,
              // user_id: '1456'
            }
            props?.actions?.getCPUserDetailsByIdAction(userByIdData)
          } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
          }
        }).catch((error) => {
          addToast({message: error, status: 'error'})
          setIsLoading(false)
      })
    }
    } else if (name === 'aadhar_number') {
      const truncatedAadhar = value.slice(0, 12);
      setInputValues({ ...inputValues, [name]: truncatedAadhar });
    } else if (name === 'pan') {
      const truncatedPAN = value.slice(0, 10);
      setInputValues({ ...inputValues, [name]: truncatedPAN.toUpperCase() });
    } else if (name === 'address_pincode') {
      const truncatedPinCode = value.slice(0, 6);
      setInputValues({ ...inputValues, [name]: truncatedPinCode });
      if (truncatedPinCode.length === 6) {
        setIsLoading(true)
        searchPinCode({pincode: truncatedPinCode}).then((res) => {
          setIsLoading(false)
          if (res?.data?.success === true && res?.data?.status === true) {
            setInputValues({...inputValues, address_pincode: truncatedPinCode, address_district: res?.data?.data[0]?.Districtname ?? '', address_state: res?.data?.data[0]?.statename ?? ''})
          } else if (res?.data?.success === false && res?.data?.status === false) {
            addToast({message: 'Invalid Pincode', status: 'error'})
          }
        }).catch((error) => {
            addToast({message: error, status: 'error'})
            setIsLoading(false)
        })
      }
    } else if (name === 'current_pincode') {
        const truncatedPinCode = value.slice(0, 6);
        setInputValues({ ...inputValues, [name]: truncatedPinCode });
        if (truncatedPinCode.length === 6) {
          setIsLoading(true)
          searchPinCode({pincode: truncatedPinCode}).then((res) => {
              setIsLoading(false)
              if (res?.data?.success === true) {
                setInputValues({...inputValues, current_pincode: truncatedPinCode, current_city: res?.data?.data[0]?.Districtname ?? '', current_state: res?.data?.data[0]?.statename ?? ''})
              } else if (res?.data?.success === false) {
                addToast({message: "Not Found", status: 'error'})
              }
            }).catch((error) => {
              addToast({message: error, status: 'error'})
              setIsLoading(false)
          })
        }
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  }

  const handleEmailOTP = () => {
    let data = {
      email: inputValues?.email,
      checkType: true,
      mobile: props?.userData?.mobile,
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputValues?.email || !emailPattern.test(inputValues.email)) {
      addToast({ message: 'Please Enter a Valid Email', status: 'error' })
      return
    }
    if (inputValues?.email) {
      setIsLoading(true)
      getKycEmailOtp(data).then((res) => {
        setIsLoading(false)
        if (res?.data?.success === true) {
            setOtpStatus(res?.data?.success)
            addToast({ message: res?.data?.message, status: 'success' })
            setTimerCount(120);
        } else if (res?.data?.success === false) {
          addToast({message: res?.data?.message, status: 'error'})
        }
      }).catch((error) => {
        addToast({message: error, status: 'error'})
        setIsLoading(false)
      })
    }
  }

  const handleResendEmailOTP = () => {
    let data = {
      email: inputValues?.email,
      checkType: true,
      mobile: props?.userData?.mobile,
    }
    setIsLoading(true)
    getKycEmailOtp(data).then((res) => {
        setIsLoading(false)
        if (res?.data?.success === true) {
            setOtpStatus(res?.data?.success)
            addToast({ message: res?.data?.message, status: 'success' })
            setTimerCount(120);
        } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
        }
        }).catch((error) => {
        addToast({message: error, status: 'error'})
        setIsLoading(false)
    })
  }

  const handleVerifyPan = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputValues?.email)) {
      addToast({ message: 'Please Enter a Valid Email', status: 'error' })
      return
    }
    if (inputValues?.pan?.length === 10) {
      let panCardData = {
        pan: inputValues?.pan,
        aadhaarLastFour: "",
        consent: 'Y',
        mobile: props?.userData?.mobile,
      }
      setIsLoading(true)
      verifyCPPanCard(panCardData).then((res) => {
        setIsLoading(false)
        if (res?.data?.success === true) {
            addToast({ message: res?.data?.message, status: 'success' })
            setPanData(res?.data?.data)
            // props?.actions?.getCPUserDetailsByIdAction({user_id: '1456'})
            props?.actions?.getCPUserDetailsByIdAction({user_id: props?.userData?.id})
        } else if (res?.data?.success === false) {
            addToast({message: res?.data?.message, status: 'error'})
        }
        }).catch((error) => {
        addToast({message: error, status: 'error'})
        setIsLoading(false)
    })
    } else if (inputValues?.pan?.length !== 10) {
      addToast({ message: 'Please Enter Pan Number', status: 'error' })
    }
  }

  const handleSwitch = (e) => {
    setCheckSwitch(e.target.checked)
    setItem('switch', e.target.checked)
  }

  const handleCheckAddress = (e) => {
    setCheckAddress(e.target.checked)
  }

  const handleSelectInvestment = (val) => {
    const names = val?.map((item) => { return item.key })
    const values = names.join(",")
    setInvestmentValue(values)
  }

  const handleAadharDigilocker = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputValues.email)) {
      addToast({ message: 'Please Enter a Valid Email', status: 'error' })
      return
    }
    if (props?.userData?.employment_type === '1' || props?.userData?.employment_type === '2' || props?.userData?.employment_type === '3') {
      if (!inputValues?.pan || !inputValues?.first_name || !inputValues?.last_name) {
        addToast({ message: 'Please Verify Pan First', status: 'error' })
        return
      }
    }
    if (props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') {
      if (!inputValues?.legal_name || !inputValues?.first_name) {
        addToast({ message: 'Please Verify Pan First', status: 'error' })
        return
      }
    }
    props.actions.createCPDigiLockerAction({mobile: props?.userData?.mobile})
  }

  console.log(props?.userData, 'props?.userData?')

  const handleNext = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(inputValues?.email)) {
      addToast({ message: 'Please Enter a Valid Email', status: 'error' })
      return
    }
    if (!inputValues?.otp) {
      addToast({ message: 'Please Verify Email First', status: 'error' });
      return
    }
    if (props?.userData?.employment_type === '1' || props?.userData?.employment_type === '2' || props?.userData?.employment_type === '3') {
      if (!inputValues?.pan || !inputValues?.first_name || !inputValues?.last_name) {
        addToast({ message: 'Please Verify Pan First', status: 'error' })
        return
      }
      if (!props?.userData?.digilocker_request) {
        if (!inputValues?.aadhar_number) {
          addToast({ message: 'Please Enter Aadhaar Number', status: 'error' });
          return
        } else if (!inputValues?.fatherName) {
          addToast({ message: 'Please Enter Fathers Name', status: 'error' });
          return
        } else if (!inputValues?.address_houseNumber || !inputValues?.address_subdistrict) {
          addToast({ message: 'Please Enter House Number & Full Address', status: 'error' });
          return
        } else if (!inputValues?.gender) {
          addToast({ message: 'Please Select Gender', status: 'error' });
          return
        } else if (!inputValues?.address_pincode) {
          addToast({ message: 'Please Enter Pin Code', status: 'error' });
          return
        }
      }
      if (!checkAddress) {
        // addToast({ message: 'Please Check Current address is same as My Aadhar Address', status: 'error' });
        // return
        if (!inputValues?.current_address || !inputValues?.current_addressline2) {
          addToast({ message: 'Please Enter Current Full Address', status: 'error' });
          return
        } else if (!inputValues?.current_pincode) {
          addToast({ message: 'Please Enter Current Pin Code', status: 'error' });
          return
        }
      }
    }
    if (props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') {
      if (!inputValues?.legal_name || !inputValues?.first_name) {
        addToast({ message: 'Please Verify Pan First', status: 'error' })
        return
      }
    }
    if (userType === '2') {
      if (inputValues?.premise_status === "0") {
        addToast({ message: 'Please Select Ownership', status: 'error' });
        return
      }
      if (inputValues?.educational_qualification === "0") {
        addToast({ message: 'Please Select Education Qualification', status: 'error' });
        return
      }
    }
    if (userType === '3') {
      if (!investmentValue) {
        addToast({ message: 'Please Select Investment Type', status: 'error' });
        return
      }
      if (!inputValues?.pref_amount) {
        addToast({ message: 'Please Select Amount', status: 'error' });
        return
      }
      if (!inputValues?.pref_tenure) {
        addToast({ message: 'Please Select Tenure', status: 'error' });
        return
      }
      if (!inputValues?.pref_risk_appetite) {
        addToast({ message: 'Please Select Risk Appetite', status: 'error' });
        return
      }
    }

    let personalData = {
      aadhar_number: inputValues?.aadhar_number ?? props?.userData?.aadhar_card_data?.aadhar_number,
      address_district: inputValues?.address_district ?? props?.userData?.aadhar_card_data?.address_district,
      address_houseNumber: inputValues?.address_houseNumber ?? props?.userData?.aadhar_card_data?.address_houseNumber,
      address_subdistrict: inputValues?.address_subdistrict ?? props?.userData?.aadhar_card_data?.address_subdistrict,
      address_pincode: inputValues?.address_pincode ?? props?.userData?.aadhar_card_data?.address_pincode,
      address_state: inputValues?.address_state ?? props?.userData?.aadhar_card_data?.address_state,
      current_address: inputValues?.current_address ?? props?.userData?.personal_data?.current_address,
      current_addressline2: inputValues?.current_addressline2 ?? props?.userData?.personal_data?.current_addressline2,
      current_city: inputValues?.current_city ?? props?.userData?.personal_data?.current_city,
      current_pincode: inputValues?.current_pincode ?? props?.userData?.personal_data?.current_pincode,
      current_state: inputValues?.current_state ?? props?.userData?.personal_data?.current_state,
      dob: inputValues?.dob ?? props?.userData?.dob,
      email: inputValues?.email ?? props?.userData?.email,
      father_name: inputValues?.fatherName ?? props?.userData?.aadhar_card_data?.fatherName,
      first_name: inputValues?.first_name ?? props?.userData?.first_name,
      middle_name: inputValues?.middle_name ?? props?.userData?.middle_name,
      last_name: inputValues?.last_name ?? props?.userData?.last_name,
      gender: inputValues?.gender ?? props?.userData?.aadhar_card_data?.gender,
      pan: inputValues?.pan ?? props?.userData?.pan,
      is_current_address_same: checkAddress ? true : false,
      is_aadhaar_manual: getItem('switch') ? 1 : 0,
      status: 1,
      mobile: props?.userData?.mobile
    }

    let commonObj; // common object for investor and borrower

    if (userType === '2') {
      commonObj = {
        ...personalData,
        premise_status: inputValues?.premise_status ?? props?.userData?.personal_data?.premise_status,
        educational_qualification: inputValues?.educational_qualification ?? props?.userData?.personal_data?.educational_qualification
      }
    }
    else if (userType === '3') {
      commonObj = {
        ...personalData,
        pref_amount: inputValues?.pref_amount ?? props?.userData?.preference_data?.amount,
        pref_investment_types: investmentValue ? investmentValue : '',
        pref_risk_appetite: inputValues?.pref_risk_appetite ?? props?.userData?.preference_data?.risk_appetite,
        pref_tenure: inputValues?.pref_tenure ??  props?.userData?.preference_data?.tenure,
        // legal_name: inputValues?.legal_name ?? props?.userData?.legal_name
        legal_name: inputValues?.legal_name ?? props?.userData?.legal_name,
        aadhar_dob: inputValues?.aadhar_dob ?? props?.userData?.aadhar_card_data?.dob
      }
    }
    // console.log(commonObj, 'commonObj')
    props.actions.postPersonalDetailsAction(commonObj)
  }

  const rejectData = (props?.userData?.user_document_checked_data?.document && JSON.parse(props?.userData?.user_document_checked_data?.document))
  console.log(inputValues, 'inputValues')
  // console.log(checkAddress, 'checkAddress')
  // console.log(investmentValue, 'investmentValue')

  return (
    <>
      {(kycState?.postPersonalDetails?.loading || cpState?.getCPUserDetailsById?.loading || loading || isLoading) &&
        <div>
          <Loader />

        </div>
      }
      <Box className='main_Kyc_form_container'>
        <Grid className="grid_container" mb={5}>
          <GridItem>
            <Box className='form_kyc_cp'>
              <Box>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Borrower Type</Heading>
                <Box mb={2}>
                  <Select
                    placeholder="Please Select Borrower Type"
                    name="employment_type"
                    variant="filled"
                    onChange={handleChange}
                    className="input_box_select font-poppins bg_white"
                    value={inputValues?.employment_type ?? ''}
                  >
                  {props?.userData?.usertype === '2' ?
                    <>
                      {dropdownData?.employment_type_borrower?.map((item) => (
                        <option key={item.key} value={item.key}>{item.value}</option>
                      ))}
                    </> :
                    <>
                      {dropdownData?.employment_type_lender?.map((item) => (
                        <option key={item.key} value={item.key}>{item.value}</option>
                      ))}
                    </>
                  }
                  </Select>
                </Box>
              </Box>
              <Box mt={5}>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Borrower’s Mobile Number</Heading>
                <Box mb={2}>
                  <InputGroup>
                    {props?.userData?.mobile ?
                      <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} /> : null}
                      <Input
                        type="number"
                        size="md"
                        className="input_box font-poppins bg_white"
                        value={props?.userData?.mobile ?? ''}
                        isReadOnly={props?.userData?.mobile}
                      />
                  </InputGroup>
                </Box>
              </Box>
              <Box mt={5}>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Email Address</Heading>
                <Box mb={3}>
                  <InputGroup>
                    {verifyStatus || props?.userData?.email ?
                      <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} /> : null}
                      <Input
                        type="email"
                        size="md"
                        id="email"
                        name="email"
                        placeholder="Verify Email Address*"
                        onChange={handleChange}
                        className="input_box font-poppins bg_white"
                        value={inputValues?.email ?? props?.userData?.email ?? ''}
                        isReadOnly={props?.userData?.email || otpStatus}
                      />
                  </InputGroup>
                </Box>
                {!props?.userData?.email ?
                  <>
                    {!verifyStatus ?
                      <>
                        <Box mb={3} className="right_align">
                          {otpStatus ?
                            <Flex className="right_align resend">
                              <CustomButton size="md" type="submit" title="Resend OTP" className="font-poppins btn_transparent" onClick={handleResendEmailOTP}
                                isDisabled={timerCount === 0 ? false : true} />
                              {timerCount === 0 ? null : <Heading as="p"> {timerCount} Sec </Heading>}
                            </Flex>
                            :
                            <CustomButton size="md" type="submit" title="Get OTP" className="font-poppins btn_transparent" onClick={() => handleEmailOTP()} />
                          }
                        </Box>
                        <Box mb={3}>
                          <Input
                            size="md"
                            id="otp"
                            name="otp"
                            type="number"
                            disabled={!otpStatus}
                            isReadOnly={verifyStatus}
                            placeholder="Enter Otp"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.otp ?? ''}
                          />
                        </Box>
                      </> : null}
                  </> : null}
              </Box>
              <Box mt={5} className='pan_box'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>{(props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') ? 'PAN Number Of Company' : 'PAN Number'}</Heading>
                {(props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') ?
                  <OnBoardInstitutionalPan inputValues={inputValues} userData={props?.userData} panData={panData} handleChange={handleChange} handleVerifyPan={handleVerifyPan} /> :
                  <Box mb={3}>
                    <InputGroup>
                      <Input
                        type="text"
                        size="md"
                        name="pan"
                        placeholder="Enter PAN Number"
                        onChange={handleChange}
                        className="input_box font-poppins bg_white"
                        value={inputValues?.pan ?? props?.userData?.pan ?? ''}
                        isReadOnly={props?.userData?.pan || panData}
                      />
                      {props?.userData?.is_pan_verified === 1 || panData ?
                        <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} /> :
                        <CustomButton size="md" type="submit" title="Verify" className="font-poppins btn_link" onClick={() => handleVerifyPan()} />
                      }
                    </InputGroup>
                    <Box className='verified_data'>
                      <Heading as="p" className='font-poppins'><b>Name:</b>
                        {' '}{inputValues?.first_name ? inputValues?.first_name : props?.userData?.first_name}
                        {' '}{inputValues?.middle_name ? inputValues?.middle_name : props?.userData?.middle_name}
                        {' '}{inputValues?.last_name ? inputValues?.last_name : props?.userData?.last_name}
                      </Heading>
                      <Heading as="p" className='font-poppins'><b>Date of Birth:</b> {inputValues?.dob ? inputValues?.dob : props?.userData?.dob}</Heading>
                    </Box>
                  </Box>
                }
              </Box>
              <Box mt={5}>
                <Heading as="h1" className="font-poppins label_heading" mb={4}>{(props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') ? 'Aadhaar Details (Promoter Or Director)' : 'Aadhaar Details'}</Heading>
                {props?.userData?.digilocker_request?.status === 'approved' ?
                  <Box mb={3}>
                    <InputGroup>
                      <Input
                        type="text"
                        size="md"
                        className="input_box font-poppins bg_white"
                        value={props?.userData?.aadhar_card_data?.aadhar_number}
                        isReadOnly={props?.userData?.aadhar_card_data?.aadhar_number}
                      />
                      <InputRightElement pointerEvents="none" children={<CheckCircleIcon className='verify_icon' />} />
                    </InputGroup>
                    <Box className='verified_data'>
                      {props?.userData?.aadhar_card_data?.gender ? <Heading as="p" className='font-poppins'><b>Gender:</b> {props?.userData?.aadhar_card_data?.gender}</Heading> : null}
                      {props?.userData?.aadhar_card_data?.fatherName ? <Heading as="p" className='font-poppins'><b>Guardian's Name:</b> {props?.userData?.aadhar_card_data?.fatherName}</Heading> : null}
                      <Heading as="p" className='font-poppins'><b>Address:</b> {props?.userData?.aadhar_card_data?.address_houseNumber}</Heading>
                    </Box>
                    {props?.userData?.aadhar_card_data?.fatherName ? null :
                      <Box mt={3}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Guardian's Name{' '}{' '}{' '}{' '}
                          <Tooltip label='Your Father Name Here' fontSize='md'>
                            <InfoOutlineIcon />
                          </Tooltip>
                        </Heading>
                        <Input
                          type="text"
                          size="md"
                          name="fatherName"
                          placeholder="Enter Guardian's Name"
                          onChange={handleChange}
                          className="input_box font-poppins bg_white"
                          value={inputValues?.fatherName ?? props?.userData?.aadhar_card_data?.fatherName ?? ''}
                          isReadOnly={props?.userData?.aadhar_card_data?.fatherName}
                        />
                      </Box>}
                    {!props?.userData?.aadhar_card_data?.gender ?
                      <Box className='w-100' mt={5}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Gender</Heading>
                        <Select placeholder="Select Gender" name="gender" variant="filled" onChange={handleChange}
                          className="input_box_select font-poppins bg_white" value={inputValues?.gender ?? props?.userData?.aadhar_card_data?.gender ?? ''}>
                          {genderData?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>{item.name}</option>
                            )
                          })}
                        </Select>
                      </Box> : null}
                  </Box> :
                  props?.userData?.isAadhaarManual === 1 ?
                    <>
                      <Box mt={7}>
                        <Box mb={3} className='d-flex align-items-center justify-content-between reject_block'>
                          <Heading as="h1" className="font-poppins label_heading">Aadhar Number</Heading>
                          {rejectData?.v2_aadhar_reject_reason &&
                            <Text className="font-poppins">Admin Comment
                              <Tooltip label={rejectData?.v2_aadhar_reject_reason}
                                hasArrow fontSize='xs' placement='bottom-start'>
                                <InfoOutlineIcon />
                              </Tooltip>
                            </Text>
                          }
                        </Box>
                        <Input
                          type="text"
                          size="md"
                          name="aadhar_number"
                          placeholder="Enter Aadhar Number"
                          onChange={handleChange}
                          className="input_box font-poppins bg_white"
                          value={inputValues?.aadhar_number ?? props?.userData?.aadhar_card_data?.aadhar_number ?? ''}
                        />
                      </Box>
                      {(props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') &&
                        <Box mt={7}>
                          <Heading as="h1" className="font-poppins label_heading" mb={1}>Aadhaar DOB</Heading>
                          <Input
                            type="date"
                            size="md"
                            name="aadhar_dob"
                            placeholder="Enter Guardian's Name"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.aadhar_dob ?? props?.userData?.aadhar_card_data?.aadhar_dob ?? ''}
                          />
                        </Box>
                      }
                      <Box mt={7}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Guardian's Name</Heading>
                        <Input
                          type="text"
                          size="md"
                          name="fatherName"
                          placeholder="Enter Guardian's Name"
                          onChange={handleChange}
                          className="input_box font-poppins bg_white"
                          value={inputValues?.fatherName ?? props?.userData?.aadhar_card_data?.fatherName ?? ''}
                        />
                      </Box>
                      <Box mt={7}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Address</Heading>
                        <Input
                          mb={5}
                          type="text"
                          size="md"
                          name="address_houseNumber"
                          placeholder="Aadhaar Address Line 1"
                          onChange={handleChange}
                          className="input_box font-poppins bg_white"
                          value={inputValues?.address_houseNumber ?? props?.userData?.aadhar_card_data?.address_houseNumber ?? ''}
                        />
                        <Input
                          type="text"
                          size="md"
                          name="address_subdistrict"
                          placeholder="Aadhaar Address Line 2"
                          onChange={handleChange}
                          className="input_box font-poppins bg_white"
                          value={inputValues?.address_subdistrict ?? props?.userData?.aadhar_card_data?.address_subdistrict ?? ''}
                        />
                      </Box>
                      <Flex mt={7} className='col-gap'>
                        <Box className='w-100'>
                          <Heading as="h1" className="font-poppins label_heading" mb={1}>Gender</Heading>
                          <Select placeholder="Select Gender" name="gender" variant="filled" onChange={handleChange}
                            className="input_box_select font-poppins bg_white" value={inputValues?.gender ?? props?.userData?.aadhar_card_data?.gender ?? ''}>
                            {genderData?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>{item.name}</option>
                              )
                            })}
                          </Select>
                        </Box>
                        <Spacer />
                        <Box className='w-100'>
                          <Heading as="h1" className="font-poppins label_heading" mb={1}>Pin Code</Heading>
                          <Input
                            type="text"
                            size="md"
                            name="address_pincode"
                            placeholder="Enter Pin Code"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.address_pincode ?? props?.userData?.aadhar_card_data?.address_pincode ?? ''}
                            onKeyDown={preventInvalidKey}
                          />
                        </Box>
                      </Flex>
                      <Flex mt={7} className='col-gap'>
                        <Box className='w-100'>
                          <Heading as="h1" className="font-poppins label_heading" mb={1}>City</Heading>
                          <Input
                            type="text"
                            size="md"
                            name="address_district"
                            isReadOnly={true}
                            placeholder="Enter City"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.address_district ?? props?.userData?.aadhar_card_data?.address_district ?? ''}
                          />
                        </Box>
                        <Spacer />
                        <Box className='w-100'>
                          <Heading as="h1" className="font-poppins label_heading" mb={1}>State</Heading>
                          <Input
                            type="text"
                            size="md"
                            name="address_state"
                            isReadOnly={true}
                            placeholder="Enter State"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.address_state ?? props?.userData?.aadhar_card_data?.address_state ?? ''}
                          />
                        </Box>
                      </Flex>
                    </> :
                    <>
                      <Flex mb={5} className='switch_box'>
                        <FormLabel className="font-poppins label_heading">Upload Aadhaar Manually</FormLabel>
                        <Switch size="md" className="switch_check" onChange={handleSwitch} />
                      </Flex>
                      <>
                        {checkSwitch ?
                          <>
                            <Box mt={7}>
                              <Heading as="h1" className="font-poppins label_heading" mb={1}>Aadhar Number</Heading>
                              <Input
                                type="text"
                                size="md"
                                name="aadhar_number"
                                placeholder="Enter Aadhar Number"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.aadhar_number ?? props?.userData?.aadhar_card_data?.aadhar_number ?? ''}
                              />
                            </Box>
                            {(props?.userData?.employment_type === '4' || props?.userData?.employment_type === '5') &&
                              <Box mt={7}>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Aadhaar DOB</Heading>
                                <Input
                                  type="date"
                                  size="md"
                                  name="aadhar_dob"
                                  placeholder="Enter Guardian's Name"
                                  onChange={handleChange}
                                  className="input_box font-poppins bg_white"
                                  value={inputValues?.aadhar_dob ?? props?.userData?.aadhar_card_data?.aadhar_dob ?? ''}
                                />
                              </Box>
                            }
                            <Box mt={7}>
                              <Heading as="h1" className="font-poppins label_heading" mb={1}>Guardian's Name</Heading>
                              <Input
                                type="text"
                                size="md"
                                name="fatherName"
                                placeholder="Enter Guardian's Name"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.fatherName ?? props?.userData?.aadhar_card_data?.fatherName ?? ''}
                              />
                            </Box>
                            <Box mt={7}>
                              <Heading as="h1" className="font-poppins label_heading" mb={1}>Address</Heading>
                              <Input
                                mb={5}
                                type="text"
                                size="md"
                                name="address_houseNumber"
                                placeholder="Aadhaar Address Line 1"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.address_houseNumber ?? props?.userData?.aadhar_card_data?.address_houseNumber ?? ''}
                              />
                              <Input
                                type="text"
                                size="md"
                                name="address_subdistrict"
                                placeholder="Aadhaar Address Line 2"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.address_subdistrict ?? props?.userData?.aadhar_card_data?.address_subdistrict ?? ''}
                              />
                            </Box>
                            <Flex mt={7} className='col-gap'>
                              <Box className='w-100'>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Gender</Heading>
                                <Select placeholder="Select Gender" name="gender" variant="filled" onChange={handleChange}
                                  className="input_box_select font-poppins bg_white" value={inputValues?.gender ?? props?.userData?.aadhar_card_data?.gender ?? ''}>
                                  {genderData?.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                  })}
                                </Select>
                              </Box>
                              <Spacer />
                              <Box className='w-100'>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>Pin Code</Heading>
                                <Input
                                  type="number"
                                  size="md"
                                  name="address_pincode"
                                  placeholder="Enter Pin Code"
                                  onChange={handleChange}
                                  className="input_box font-poppins bg_white"
                                  value={inputValues?.address_pincode ?? props?.userData?.aadhar_card_data?.address_pincode ?? ''}
                                  onKeyDown={preventInvalidKey}
                                />
                              </Box>
                            </Flex>
                            <Flex mt={7} className='col-gap'>
                              <Box className='w-100'>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>City</Heading>
                                <Input
                                  type="text"
                                  size="md"
                                  name="address_district"
                                  isReadOnly={true}
                                  placeholder="Enter City"
                                  onChange={handleChange}
                                  className="input_box font-poppins bg_white"
                                  value={inputValues?.address_district ?? props?.userData?.aadhar_card_data?.address_district ?? ''}
                                />
                              </Box>
                              <Spacer />
                              <Box className='w-100'>
                                <Heading as="h1" className="font-poppins label_heading" mb={1}>State</Heading>
                                <Input
                                  type="text"
                                  size="md"
                                  name="address_state"
                                  isReadOnly={true}
                                  placeholder="Enter State"
                                  onChange={handleChange}
                                  className="input_box font-poppins bg_white"
                                  value={inputValues?.address_state ?? props?.userData?.aadhar_card_data?.address_state ?? ''}
                                />
                              </Box>
                            </Flex>
                          </>
                          :
                          <CustomButton size="md" type="submit" title="Verify via Digilocker" onClick={handleAadharDigilocker} className="font-poppins btn_theme" />
                        }
                      </>
                    </>
                }
              </Box>
              <Box mt={5}>
                <Box className="checkbox_item" >
                    <Checkbox onChange={(e) => handleCheckAddress(e)} isChecked={checkAddress}>
                        My Current address is same as My Aadhar Address
                    </Checkbox>
                </Box>
                {!checkAddress ?
                <>
                    <Box mt={3}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Current Address</Heading>
                        <Input
                            mb={5}
                            type="text"
                            size="md"
                            name="current_address"
                            placeholder="Aadhaar Address Line 1"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.current_address ?? props?.userData?.personal_data?.current_address ?? ''}
                        />
                        <Input
                            type="text"
                            size="md"
                            name="current_addressline2"
                            placeholder="Aadhaar Address Line 2"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.current_addressline2 ?? props?.userData?.personal_data?.current_addressline2 ?? ''}
                        />
                    </Box>
                    <Box mt={7}>
                        <Heading as="h1" className="font-poppins label_heading" mb={1}>Pin Code</Heading>
                        <Input
                            type="number"
                            size="md"
                            name="current_pincode"
                            placeholder="Enter Pin Code"
                            onChange={handleChange}
                            className="input_box font-poppins bg_white"
                            value={inputValues?.current_pincode ?? props?.userData?.personal_data?.current_pincode ?? ''}
                            onKeyDown={preventInvalidKey}
                        />
                    </Box>
                    <Flex mt={7} className='col-gap'>
                        <Box className='w-100'>
                            <Heading as="h1" className="font-poppins label_heading" mb={1}>City</Heading>
                            <Input
                                type="text"
                                size="md"
                                name="current_city"
                                isReadOnly={true}
                                placeholder="Enter City"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.current_city ?? props?.userData?.personal_data?.current_city ?? ''}
                            />
                        </Box>
                        <Spacer />
                        <Box className='w-100'>
                            <Heading as="h1" className="font-poppins label_heading" mb={1}>State</Heading>
                            <Input
                                type="text"
                                size="md"
                                name="current_state"
                                isReadOnly={true}
                                placeholder="Enter State"
                                onChange={handleChange}
                                className="input_box font-poppins bg_white"
                                value={inputValues?.current_state ?? props?.userData?.personal_data?.current_state ?? ''}
                            />
                        </Box>
                    </Flex>
                </> : null}
              </Box>
            </Box>
          </GridItem>
          {userType === '2' ?
            <GridItem>
              <Box className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>House Ownership</Heading>
                <Select placeholder="Select Your Ownership" name="premise_status" variant="filled" onChange={handleChange}
                  className="input_box_select font-poppins bg_white"
                  value={inputValues?.premise_status ?? props?.userData?.personal_data?.premise_status ?? ''}>
                  {premiseStatusOptions?.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>{item.value}</option>
                    )
                  })}
                </Select>
              </Box>
              <Box mt={5} className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Education Qualification</Heading>
                <Select placeholder="Please Select Your Edu. Qualification" name="educational_qualification" variant="filled" onChange={handleChange}
                  className="input_box_select font-poppins bg_white"
                  value={inputValues?.educational_qualification ?? props?.userData?.personal_data?.educational_qualification ?? ''}>
                  {qualificationsOptions?.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>{item.value}</option>
                    )
                  })}
                </Select>
              </Box>
            </GridItem> :
            <GridItem>
              <Heading as="h1" className="font-poppins heading_invest" mb={3}>Your Investment Preferences</Heading>
              <Box mt={5} className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Investment Type</Heading>
                <Multiselect
                  className='multiSelect_box font-poppins'
                  placeholder="Select Your Investment Type"
                  onRemove={handleSelectInvestment}
                  onSelect={handleSelectInvestment}
                  options={investmentType}
                  displayValue="value"
                  selectedValues={selectedValues}
                />
              </Box>
              <Box mt={5} className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Amount</Heading>
                <Input
                  type="number"
                  placeholder="Please Enter Amount"
                  name="pref_amount"
                  variant="filled"
                  onChange={handleChange}
                  className="input_box_select font-poppins bg_white"
                  value={inputValues?.pref_amount ?? props?.userData?.preference_data?.amount ?? ''}
                >
                </Input>
              </Box>
              <Box mt={5} className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Tenure</Heading>
                <Select
                  placeholder="Please Select Tenure"
                  name="pref_tenure"
                  variant="filled"
                  onChange={handleChange}
                  className="input_box_select font-poppins bg_white"
                  value={inputValues?.pref_tenure ?? props?.userData?.preference_data?.tenure ?? ''}
                >
                  {loanTenure?.map((item) => (
                    <option key={item.key} value={item.key}>{item.value}</option>
                  ))}
                </Select>
              </Box>
              <Box mt={5} className='form_kyc_cp'>
                <Heading as="h1" className="font-poppins label_heading" mb={1}>Risk Appetite</Heading>
                <Select
                  placeholder="Please Select"
                  name="pref_risk_appetite"
                  variant="filled"
                  onChange={handleChange}
                  className="input_box_select font-poppins bg_white"
                  value={inputValues?.pref_risk_appetite ?? props?.userData?.preference_data?.risk_appetite ?? ''}
                >
                  {riskAppetite?.map((item) => (
                    <option key={item.key} value={item.key}>{item.value}</option>
                  ))}
                </Select>
              </Box>
            </GridItem>
          }
        </Grid>
        <Box className="kyc_actions">
          <CustomButton size="md" type="submit" title="Next" className="btn_green font-poppins" onClick={handleNext} />
        </Box>
      </Box>
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(OnBoardPersonalDetails)