import React, { useEffect, useState } from 'react'
import {Box, Flex, Grid, GridItem, Heading, Input, Select, Spacer, Text} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import CustomButton from '../../../elements/customButton'
import Loader from '../../../elements/loader'
import NoData from '../../../elements/NoData'
import moment from 'moment'
import CustomToast from '../../../elements/customToast'
import CKYCReportTable from './components/CKYCReportTable'
import { downloadDocumentReport } from '../../../../utilities/DownloadDocuments'

const reportType = [
  {
    id: 1,
    name: 'CKYC Report',
  },
]

const CKYCReport = (props) => {

    const history = useHistory()
    const { addToast } = CustomToast()
    const adminState = useSelector((state) => state.adminState)
    const reportState = useSelector((state) => state.reportState)
    const [inputValues, setInputValues] = useState({})
    const userData = adminState?.getUserByToken?.data?.data
    const CKYCReportData = reportState?.getCKYCReport?.data
    // const userType = userData?.usertype

    useEffect(() => {
      props?.actions?.getUserByTokenAction()
      const today = moment(new Date()).format('YYYY-MM-DD')
      setInputValues({
        from_date: today,
        to_date: today,
        reporting_date: today,
      });
    }, [])

    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValues({...inputValues, [name]: value})
    }

    const handleGenerateReport = () => {
      if (!inputValues?.report_type) {
        addToast({message: 'Please Select Report', status: 'error'})
        return
      }
      let data = {
        start_date: inputValues?.from_date,
        end_date : inputValues?.to_date,
      }
      if(inputValues?.report_type === '1') {
        props.actions.getCKYCReportAction(data)
      }
    }

    const handleDownloadReport = async (reportFile) => {
      try {
        downloadDocumentReport(reportFile)
      } catch (error) {
        console.error("Error downloading the report:", error)
      }
    }

    // const handleLogout = () => {
    //   removeAll()
    //   history.push(routesNames.LOGIN)
    // }

  return (
    <>
      {(adminState?.getUserByToken?.loading || reportState?.getCKYCReport?.loading) &&
        <div>
            <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
          <Grid className="main_grid_container align-items-center">
              <GridItem colSpan={8}>
                  <Heading as="h2" className='font-poppins page_heading'>CKYC Report</Heading>
              </GridItem>
              <GridItem colSpan={4}>
                  <NavBar data={userData} isAdmin />
              </GridItem>
          </Grid>
          <Grid className="main_grid_container" mb={4} mt={7} gap={14}>
            <GridItem colSpan={6}>
              <Box className='select_report_type'>
                <Text className="font-poppins">Select Report Type</Text>
                <Select placeholder="Select Report" variant="filled" className="select_report font-poppins"
                  name="report_type" onChange={handleChange} value={inputValues?.report_type ?? ''}>
                  {reportType?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.name}</option>
                    )
                  })}
                </Select>
              </Box>
              <Box mt={3}>
                <CustomButton size="md" type="submit" title="Generate Report" className="font-poppins btn_theme" onClick={handleGenerateReport} />
              </Box>
            </GridItem>
            <GridItem colSpan={6}>
              <Flex alignItems='center' justifyContent='space-between' gap={2} className='custom_select'>
                <Box className='select_report_type'>
                  <Text className="font-poppins">From Date</Text>
                  <Input size="md" name="from_date"  type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                      onChange={handleChange} value={inputValues?.from_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                  />
                </Box>
                <Spacer />
                <Box className='select_report_type'>
                  <Text className="font-poppins">To Date</Text>
                  <Input size="md" name="to_date"  type="date" placeholder="DD/MM/YYYY" className="select_report font-poppins"
                      onChange={handleChange} value={inputValues?.to_date ?? ''} max={moment(new Date()).format('YYYY-MM-DD')}
                  />
                </Box>
              </Flex>
            </GridItem>
          </Grid>
          {inputValues?.report_type === '1' ?
            <CKYCReportTable reportData={CKYCReportData} handleDownloadReport={handleDownloadReport} /> :
            <NoData title="No Record Found" />
          }
      </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(CKYCReport)