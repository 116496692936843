import React from 'react'
import { Box, Heading, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/react'

export default function PersonalLoanEmiCalculator(props) {

  return (
    <Box mt={4}>
        <Box mb={6}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>Loan Amount</Heading>
                <Heading as="h5" className='font-poppins'>₹ {props.personalLoanDetails.loanAmount[1].toLocaleString('en-US')}</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.personalLoanDetails.loanAmount} onChange={(e) => props.handleChangePersonalLoan(e, 'loanAmount')} min={props?.isPersonalLoan?.min_loan_amount} max={props?.isPersonalLoan?.max_loan_amount} step={1000}>
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box mb={6}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>ROI (Per Annum)</Heading>
                <Heading as="h5" className='font-poppins'>{props.personalLoanDetails.roi[1]}%</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.personalLoanDetails.roi} onChange={(e) => props.handleChangePersonalLoan(e, 'roi')} min={props?.isPersonalLoan?.min_interest} max={props?.isPersonalLoan?.max_interest} step={1}>
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box mb={5}>
            <Box className='loan_block'>
                <Heading as="h2" className='font-poppins block_heading'>Loan Tenure</Heading>
                <Heading as="h5" className='font-poppins'>{props.personalLoanDetails.tenure[1]} Months</Heading>
            </Box>
            <Box mt={3}>
                <RangeSlider value={props.personalLoanDetails.tenure} onChange={(e) => props.handleChangePersonalLoan(e, 'tenure')} min={props?.isPersonalLoan?.min_tenure} max={props?.isPersonalLoan?.max_tenure} step={1}>
                    <RangeSliderTrack className='range_slider_bg'>
                        <RangeSliderFilledTrack bg='#BA1F33' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={6} index={0} className='left_thumb' />
                    <RangeSliderThumb boxSize={6} index={1} className='right_thumb' />
                </RangeSlider>
            </Box>
        </Box>
        <Box>
            <Box mb={4} className='emi_data'>
                <Heading as="h5" className='font-poppins'>Principal Amount</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props.personalLoanDetails.loanAmount[1].toLocaleString('en-US')}</Heading>
            </Box>
            <Box mb={4} className='emi_data'>
                <Heading as="h5" className='font-poppins'>Total Interest</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.interestPayable}</Heading>
            </Box>
            <Box mb={4} className='emi_data'>
                <Heading as="h5" className='font-poppins'>Total Amount</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.totalPayable}</Heading>
            </Box>
            <Box className='emi_data'>
                <Heading as="h5" className='font-poppins'>EMI Amount</Heading>
                <Heading as="h4" className='font-poppins'>₹ {props?.emiCalculator?.emi}</Heading>
            </Box>
        </Box>
    </Box>
  )
}
