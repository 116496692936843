import React, { useEffect, useState } from 'react';
import { Box, Stack, Heading } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../../../redux/actions';
import Loader from '../../../elements/loader';
import OnBoardPersonalDetails from './OnBoardPersonalDetails';
import OnBoardBankDetails from './OnBoardBankDetails';
import OnBoardUploadDocuments from './OnBoardUploadDocuments';
import OnBoardFeePayment from './OnBoardFeePayment';

const OnBoardKYC = (props) => {

  const cpState = useSelector((state) => state.cpState)
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [userData, setUserData] = useState(null);
  const [activePersonalDetails, setActivePersonalDetails] = useState(false);
  const [activeBankDetails, setActiveBankDetails] = useState(false);
  const [activeDocuments, setActiveDocuments] = useState(false);
  const [activeFeeDetails, setActiveFeeDetails] = useState(false);
  const USERID = props?.userFound?.id

  let userByIdData = {
    user_id: USERID,
    // user_id: '1456'
  }
  useEffect(() => {
    props?.actions?.getCPUserDetailsByIdAction(userByIdData)
  }, [])

  useEffect(() => {
    if (cpState?.getCPUserDetailsById?.data?.success === true){
      setUserData(cpState?.getCPUserDetailsById?.data?.data)
      setActivePersonalDetails(cpState?.getCPUserDetailsById?.data?.data?.personal_data?.status === 1 ? true : false)
      setActiveBankDetails(cpState?.getCPUserDetailsById?.data?.data?.bank_data ? true : false)
      setActiveDocuments(cpState?.getCPUserDetailsById?.data?.data?.document_data ? true : false)
      setActiveFeeDetails(cpState?.getCPUserDetailsById?.data?.data?.fee_data?.transaction_status === '2'? true : false)
    }
  }, [cpState?.getCPUserDetailsById])

  const nextStep = () => {
    setStep(step + 1);
  };

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const markStepAsCompleted = (stepNumber) => {
    if (!completedSteps.includes(stepNumber)) {
      setCompletedSteps([...completedSteps, stepNumber]);
    }
  }

  const handleSubmit = () => {
    console.log('Form submitted!');
  };

  return (
    <Box>
      {(cpState?.getCPUserDetailsById?.loading) &&
      <div>
        <Loader />
      </div>
      }
        <Box className='dashboard_box bg_shadow' mt={4}>
          <Box className='d-flex align-items-center justify-content-between'>
            <Heading as="h2" className='font-poppins page_heading w-100'>User Onboarding</Heading>
          </Box>
          <Box className='stepper_box'>
            <Stack direction="row" className='steps_align'>
              <StepLabel active={step === 1} completed={completedSteps.includes(1) || activePersonalDetails} onClick={() => goToStep(1)}>
                  {completedSteps.includes(1) || activePersonalDetails ? <CheckCircleIcon className='icon_size' /> : <span>01</span>} Personal Details
              </StepLabel>
              <StepLabel active={step === 2} completed={completedSteps.includes(2) || activeBankDetails} onClick={() => goToStep(2)}>
                  {completedSteps.includes(2) || activeBankDetails ? <CheckCircleIcon className='icon_size' /> : <span>02</span>} Bank Details
              </StepLabel>
              <StepLabel active={step === 3} completed={completedSteps.includes(3) || activeDocuments} onClick={() => goToStep(3)}>
                  {completedSteps.includes(3) || activeDocuments ? <CheckCircleIcon className='icon_size' /> : <span>03</span>} Documents
              </StepLabel>
              <StepLabel active={step === 4} completed={completedSteps.includes(4) || activeFeeDetails} onClick={() => goToStep(4)}>
                  {completedSteps.includes(4) || activeFeeDetails ? <CheckCircleIcon className='icon_size' /> : <span>04</span>} Fee Payment
              </StepLabel>
              {/* {userData?.usertype === '2' &&
                <StepLabel active={step === 5} completed={completedSteps.includes(5)} onClick={() => goToStep(5)}>
                    {completedSteps.includes(5) ? <CheckCircleIcon className='icon_size' /> : <span>05</span>} Loan Application
                </StepLabel>
              } */}
            </Stack>
            <>
              {step === 1 && (
                <Box>
                  <OnBoardPersonalDetails onClick={() => { markStepAsCompleted(1); nextStep();}} userData={userData} />
                </Box>
              )}
              {step === 2 && (
                <Box>
                  <OnBoardBankDetails onClick={() => { markStepAsCompleted(2); nextStep(); }} userData={userData} activeBankDetails={activeBankDetails} />
                </Box>
              )}
              {step === 3 && (
                <Box>
                  <OnBoardUploadDocuments onClick={() => { markStepAsCompleted(3); nextStep(); }} userData={userData} />
                </Box>
              )}
              {step === 4 && (
                <Box>
                  <OnBoardFeePayment onClick={() => { markStepAsCompleted(4); handleSubmit(); }} feeData={userData} />
                  {/* {userData?.usertype === '2' ? 
                    <OnBoardFeePayment onClick={() => { markStepAsCompleted(4); nextStep(); }} feeData={userData} /> :
                    <OnBoardFeePayment onClick={() => { markStepAsCompleted(4); handleSubmit(); }} feeData={userData} />
                  } */}
                </Box>
              )}
              {/* {(step === 5 && userData?.usertype === '2') && (
                <Box>
                  <OnBoardLoanApplication onClick={() => { markStepAsCompleted(5); handleSubmit(); }} feeData={userData} />
                </Box>
              )} */}
            </>
          </Box>
        </Box>
    </Box>
  );
};

const StepLabel = ({ children, active, completed, onClick }) => {
  return (
    <Box className='step_label'>
      <Heading as="h3" className={completed ? 'tab_heading font-poppins completed' : 
      (active ? 'tab_heading font-poppins visited' : 'tab_heading font-poppins default')} onClick={onClick}>
        {children}
      </Heading>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(OnBoardKYC)
