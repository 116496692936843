import * as actionTypes from '../actions/actionsTypes'

const INITIAL_STATE = {
    getUserByToken: {
      data: null,
      loading: false,
      error: null,
    },
    getOperationData: {
      data: null,
      loading: false,
      error: null,
    },
    getApprovedBorrowerLoanData: {
      data: null,
      loading: false,
      error: null,
    },
    getUserDetailsById: {
      data: null,
      loading: false,
      error: null,
    },
    postBorrowerExpenses: {
      data: null,
      loading: false,
      error: null,
    },
    postRejectBorrower: {
      data: null,
      loading: false,
      error: null,
    },
    postApproveBorrower: {
      data: null,
      loading: false,
      error: null,
    },
    getNewAccount: {
      data: null,
      loading: false,
      error: null,
    },
    getESignedLoanList: {
      data: null,
      loading: false,
      error: null,
    },
    verifyAgreement: {
      data: null,
      loading: false,
      error: null,
    },
    approveUser: {
      data: null,
      loading: false,
      error: null,
    },
    rejectLoanContract: {
      data: null,
      loading: false,
      error: null,
    },
    createLoanContract: {
      data: null,
      loading: false,
      error: null,
    },
    getCurrentCollection: {
      data: null,
      loading: false,
      error: null,
    },
    getCurrentCollection_loan: {
      data: null,
      loading: false,
      error: null,
    },
    getCurrentCollection_loan_list: {
      data: null,
      loading: false,
      error: null,
    },
    getReiniateacData: {
      data: null,
      loading: false,
      error: null,
    },
    getClosedLoanData: {
      data: null,
      loading: false,
      error: null,
    },
    getCancel_Mandate_Data: {
      data: null,
      loading: false,
      error: null,
    },
  }

  const adminReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.GET_USER_BY_TOKEN_REQUEST:
        return {
          ...state,
          getUserByToken: {
            ...state.getUserByToken,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_USER_BY_TOKEN_SUCCESS:
        return {
          ...state,
          getUserByToken: {
            ...state.getUserByToken,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_USER_BY_TOKEN_FAILURE:
        return {
          ...state,
          getUserByToken: {
            ...state.getUserByToken,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_OPERATION_DATA_REQUEST:
        return {
          ...state,
          getOperationData: {
            ...state.getOperationData,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_OPERATION_DATA_SUCCESS:
        return {
          ...state,
          getOperationData: {
            ...state.getOperationData,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_OPERATION_DATA_FAILURE:
        return {
          ...state,
          getOperationData: {
            ...state.getOperationData,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_REQUEST:
        return {
          ...state,
          getApprovedBorrowerLoanData: {
            ...state.getApprovedBorrowerLoanData,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_SUCCESS:
        return {
          ...state,
          getApprovedBorrowerLoanData: {
            ...state.getApprovedBorrowerLoanData,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_APPROVED_BOROWWER_LOAN_DATA_FAILURE:
        return {
          ...state,
          getApprovedBorrowerLoanData: {
            ...state.getApprovedBorrowerLoanData,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_USER_DETAILS_BY_ID_REQUEST:
        return {
          ...state,
          getUserDetailsById: {
            ...state.getUserDetailsById,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_USER_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          getUserDetailsById: {
            ...state.getUserDetailsById,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_USER_DETAILS_BY_ID_FAILURE:
        return {
          ...state,
          getUserDetailsById: {
            ...state.getUserDetailsById,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.POST_BORROWER_EXPENSES_REQUEST:
        return {
          ...state,
          postBorrowerExpenses: {
            ...state.postBorrowerExpenses,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.POST_BORROWER_EXPENSES_SUCCESS:
        return {
          ...state,
          postBorrowerExpenses: {
            ...state.postBorrowerExpenses,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.POST_BORROWER_EXPENSES_FAILURE:
        return {
          ...state,
          postBorrowerExpenses: {
            ...state.postBorrowerExpenses,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.POST_REJECT_BORROWER_REQUEST:
        return {
          ...state,
          postRejectBorrower: {
            ...state.postRejectBorrower,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.POST_REJECT_BORROWER_SUCCESS:
        return {
          ...state,
          postRejectBorrower: {
            ...state.postRejectBorrower,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.POST_REJECT_BORROWER_FAILURE:
        return {
          ...state,
          postRejectBorrower: {
            ...state.postRejectBorrower,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.POST_APPROVE_BORROWER_REQUEST:
        return {
          ...state,
          postApproveBorrower: {
            ...state.postApproveBorrower,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.POST_APPROVE_BORROWER_SUCCESS:
        return {
          ...state,
          postApproveBorrower: {
            ...state.postApproveBorrower,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.POST_APPROVE_BORROWER_FAILURE:
        return {
          ...state,
          postApproveBorrower: {
            ...state.postApproveBorrower,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_NEW_ACCOUNT_REQUEST:
        return {
          ...state,
          getNewAccount: {
            ...state.getNewAccount,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_NEW_ACCOUNT_SUCCESS:
        return {
          ...state,
          getNewAccount: {
            ...state.getNewAccount,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_NEW_ACCOUNT_FAILURE:
        return {
          ...state,
          getNewAccount: {
            ...state.getNewAccount,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.GET_ESIGNED_LOAN_LIST_REQUEST:
        return {
          ...state,
          getESignedLoanList: {
            ...state.getESignedLoanList,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.GET_ESIGNED_LOAN_LIST_SUCCESS:
        return {
          ...state,
          getESignedLoanList: {
            ...state.getESignedLoanList,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.GET_ESIGNED_LOAN_LIST_FAILURE:
        return {
          ...state,
          getESignedLoanList: {
            ...state.getESignedLoanList,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.VERIFY_AGREEMENT_REQUEST:
        return {
          ...state,
          verifyAgreement: {
            ...state.verifyAgreement,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.VERIFY_AGREEMENT_SUCCESS:
        return {
          ...state,
          verifyAgreement: {
            ...state.verifyAgreement,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.VERIFY_AGREEMENT_FAILURE:
        return {
          ...state,
          verifyAgreement: {
            ...state.verifyAgreement,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.APPROVE_USER_REQUEST:
        return {
          ...state,
          approveUser: {
            ...state.approveUser,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.APPROVE_USER_SUCCESS:
        return {
          ...state,
          approveUser: {
            ...state.approveUser,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.APPROVE_USER_FAILURE:
        return {
          ...state,
          approveUser: {
            ...state.approveUser,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.REJECT_LOAN_CONTRACT_REQUEST:
        return {
          ...state,
          rejectLoanContract: {
            ...state.rejectLoanContract,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.REJECT_LOAN_CONTRACT_SUCCESS:
        return {
          ...state,
          rejectLoanContract: {
            ...state.rejectLoanContract,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.REJECT_LOAN_CONTRACT_FAILURE:
        return {
          ...state,
          rejectLoanContract: {
            ...state.rejectLoanContract,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
      case actionTypes.CREATE_LOAN_CONTRACT_REQUEST:
        return {
          ...state,
          createLoanContract: {
            ...state.createLoanContract,
            data: null,
            loading: true,
            error: null,
          },
        }
      case actionTypes.CREATE_LOAN_CONTRACT_SUCCESS:
        return {
          ...state,
          createLoanContract: {
            ...state.createLoanContract,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      case actionTypes.CREATE_LOAN_CONTRACT_FAILURE:
        return {
          ...state,
          createLoanContract: {
            ...state.createLoanContract,
            data: null,
            loading: false,
            error: action.payload,
          },
        }
        case actionTypes.CLEANUP_TOAST:
          return {
            ...state,
            approveUser: {
              data: null,
              loading: false,
              error: null,
            },
            postApproveBorrower: {
              data: null,
              loading: false,
              error: null,
            },
            postRejectBorrower: {
              data: null,
              loading: false,
              error: null,
            },
            createLoanContract: {
              data: null,
              loading: false,
              error: null,
            },
            rejectLoanContract: {
              data: null,
              loading: false,
              error: null,
            },
          }
          case actionTypes.GET_CURRENT_COLLECTION_REQUEST:
            return {
              ...state,
              getCurrentCollection: {
                ...state.getCurrentCollection,
                data: null,
                loading: true,
                error: null,
              },
            }
          case actionTypes.GET_CURRENT_COLLECTION_SUCCESS:
            return {
              ...state,
              getCurrentCollection: {
                ...state.getCurrentCollection,
                data: action.payload,
                loading: false,
                error: null,
              },
            }
          case actionTypes.GET_CURRENT_COLLECTION_FAILURE:
            return {
              ...state,
              getCurrentCollection: {
                ...state.getCurrentCollection,
                data: null,
                loading: false,
                error: action.payload,
              },
            }
            case actionTypes.GET_CURRENT_COLLECTION_Loanid_REQUEST:
              return {
                ...state,
                getCurrentCollection_loan: {
                  ...state.getCurrentCollection_loan,
                  data: null,
                  loading: true,
                  error: null,
                },
              }
            case actionTypes.GET_CURRENT_COLLECTION_Loanid_SUCCESS:
              return {
                ...state,
                getCurrentCollection_loan: {
                  ...state.getCurrentCollection_loan,
                  data: action.payload,
                  loading: false,
                  error: null,
                },
              }
            case actionTypes.GET_CURRENT_COLLECTION_Loanid_FAILURE:
              return {
                ...state,
                getCurrentCollection_loan: {
                  ...state.getCurrentCollection_loan,
                  data: null,
                  loading: false,
                  error: action.payload,
                },
              }
              case actionTypes.GET_COllection_LoanidDetils_Request:
                return {
                  ...state,
                  getCurrentCollection_loan_list: {
                    ...state.getCurrentCollection_loan_list,
                    data: null,
                    loading: true,
                    error: null,
                  },
                }
              case actionTypes.GET_COllection_LoanidDetils_SUCCESS:
                return {
                  ...state,
                  getCurrentCollection_loan_list: {
                    ...state.getCurrentCollection_loan_list,
                    data: action.payload,
                    loading: false,
                    error: null,
                  },
                }
              case actionTypes.GET_COllection_LoanidDetils_FAILURE:
                return {
                  ...state,
                  getCurrentCollection_loan_list: {
                    ...state.getCurrentCollection_loan_list,
                    data: null,
                    loading: false,
                    error: action.payload,
                  },
                }

                case actionTypes.GET_Reiniate_Details_Request:
                  return {
                    ...state,
                    getReiniateacData: {
                      ...state.getReiniateacData,
                      data: null,
                      loading: true,
                      error: null,
                    },
                  }
                case actionTypes.GET_Reiniate_Details_SUCCESS:
                  return {
                    ...state,
                    getReiniateacData: {
                      ...state.getReiniateacData,
                      data: action.payload,
                      loading: false,
                      error: null,
                    },
                  }
                case actionTypes.GET_Reiniate_Details_FAILURE:
                  return {
                    ...state,
                    getReiniateacData: {
                      ...state.getReiniateacData,
                      data: null,
                      loading: false,
                      error: action.payload,
                    },
                  }

                  case actionTypes.GET_CLOSED_LOAN_REQUEST:
                    return {
                      ...state,
                      getClosedLoanData: {
                        ...state.getClosedLoanData,
                        data: null,
                        loading: true,
                        error: null,
                      },
                    }
                  case actionTypes.GET_CLOSED_LOAN_SUCCESS:
                    return {
                      ...state,
                      getClosedLoanData: {
                        ...state.getClosedLoanData,
                        data: action.payload,
                        loading: false,
                        error: null,
                      },
                    }
                  case actionTypes.GET_CLOSED_LOAN_FAILURE:
                    return {
                      ...state,
                      getClosedLoanData: {
                        ...state.getClosedLoanData,
                        data: null,
                        loading: false,
                        error: action.payload,
                      },
                    }
                    case actionTypes.GET_CANCELEENACHMANDATE_REQUEST:
                      return {
                        ...state,
                        getCancel_Mandate_Data: {
                          ...state.getCancel_Mandate_Data,
                          data: null,
                          loading: true,
                          error: null,
                        },
                      }
                    case actionTypes.GET_CANCELEENACHMANDATE_SUCCES:
                      return {
                        ...state,
                        getCancel_Mandate_Data: {
                          ...state.getClosedLoanData,
                          data: action.payload,
                          loading: false,
                          error: null,
                        },
                      }
                    case actionTypes.GET_CANCELEENACHMANDATE_FAILURE:
                      return {
                        ...state,
                        getCancel_Mandate_Data: {
                          ...state.getCancel_Mandate_Data,
                          data: null,
                          loading: false,
                          error: action.payload,
                        },
                      }
      default:
        return state
    }
  }
  
  export default adminReducers