import React, { useEffect, useRef, useState } from 'react'
import {Box, Grid, GridItem, Heading, IconButton, Image, Input, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import { bindActionCreators } from 'redux';
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { ActionCreators } from '../../../../redux/actions';
import { connect, useSelector } from 'react-redux';
import NavBar from '../../../elements/NavBar';
import { removeAll } from '../../../../utilities/authUtils';
import ProfilePersonalDetails from './ProfilePersonalDetails';
import ProfileBankDetails from './ProfileBankDetails';
import ProfileDocumentDetails from './ProfileDocumentDetails'
import ProfileEscrowDetails from './ProfileEscrowDetails';
import UserIcon from "../../../../assets/images/userIcon.svg";
import CameraIcon from "../../../../assets/images/camera.svg";
import Loader from '../../../elements/loader';
import ProfileSidebar from './ProfileSidebar';
import { uploadProfilePhoto } from '../../../../services/masterServices';
import CustomToast from '../../../elements/customToast';
import ImportantDialog from '../../../elements/ImportantDialog';
import DeleteAccount from '../../../modules/DeleteAccount/DeleteAccount';
import SetPin from '../../../modules/SetPin/SetPin';

const tabNames = [
  {
      id: 1,
      name: 'Personal Details',
  },
  {
      id: 2,
      name: 'Bank Details',
  },
  {
      id: 3,
      name: 'Documents Details',
  },
  {
    id: 4,
    name: 'Escrow Account Details',
  },
]

const BorrowerProfile = (props) => {

  const history = useHistory()
  const {addToast} = CustomToast()
  const photoRef = useRef()
  const masterState = useSelector((state) => state.masterState)
  const kycState = useSelector((state) => state.kycState)
  const [unmaskedData, setUnmaskedData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
  const [openSetPin, setOpenSetPin] = useState(false)
  const userData = masterState?.getCurrentUserDetails?.data?.data

  useEffect(() => {
    props.actions.getCurrentUserDetailsAction()
  }, [])

  useEffect(() => {
    if (kycState?.getPiiData?.data?.success === true) {
      // setUnmaskedData({...unmaskedData, [type]: kycState?.getPiiData?.data?.data?.[type]})
      setUnmaskedData(kycState?.getPiiData?.data?.data)
    }
  }, [kycState?.getPiiData])

  const handleShowDetails = (item) => {
    const dataMap = {
      personal_details: 'personal_details',
      bank_details: 'bank_details',
      doc_details: 'doc_details',
      escrow_details: 'escrow_details,escrow_account_id',
    }
    if (dataMap[item]) {
      const data = {
        accessed_data: dataMap[item],
        accessed_user: userData?.[0]?.id,
      }
      props.actions.getPiiDataAction(data)
    }
  }

  const showOpenFileDialog = () => {
    photoRef.current.click()
  }

  const onChangeFile = (e) => {
    const formData = new FormData()
    formData.append('photo', e.target.files[0])
    setIsLoading(true)
    uploadProfilePhoto(formData).then((res) => {
      setIsLoading(false)
      if (res?.data?.success === true) {
        addToast({message: res?.data?.message, status: 'success'})
        props.actions.getCurrentUserDetailsAction()
      } else if (res?.data?.success === false) {
        addToast({message: res?.data?.message, status: 'error'})
      }
    }).catch((error) => {
      addToast({message: error, status: 'error'})
      setIsLoading(false)
    })
  }

  const handleOpenDeleteAccount = () => {
    setOpenDeleteAccount(true)
  }

  const handleCloseDeleteAccount = () => {
    setOpenDeleteAccount(false)
  }

  const handleOpenSetPin = () => {
    setOpenSetPin(true)
  }

  const handleCloseSetPin = () => {
    setOpenSetPin(false)
  }

  const handleProfile = () => {
    history.push(routesNames.BORROWERPROFILE)
  }

  // const handleLogout = () => {
  //   removeAll()
  //   history.push(routesNames.LOGIN)
  // }

  return (
    <>
      {(isLoading || masterState?.getCurrentUserDetails?.loading || kycState?.getPiiData?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
          <GridItem colSpan={8}>
            <Box className='d-flex align-items-center justify-content-between'>
              <Heading as="h2" className='font-poppins page_heading'>Profile</Heading>
              <ImportantDialog />
            </Box>
          </GridItem>
          <GridItem colSpan={4}>
              <NavBar handleProfile={handleProfile} data={userData} />
          </GridItem>
        </Grid>
        <Grid className="main_grid_container">
          <GridItem colSpan={8} className='details_left_grid'>
            <Box className='change_profile' mt={5} mb={8}>
              <Box className='change_profile_container'>
                {/* <Image src={photoData ? URL.createObjectURL(photoData) : userData?.[0]?.photo ? userData?.[0]?.photo : UserIcon} className="change_profile_image" /> */}
                <Image src={userData?.[0]?.photo ? userData?.[0]?.photo : UserIcon} className="change_profile_image" />
                <IconButton className='upload_profile_icon' onClick={() => showOpenFileDialog()}>
                  <Image src={CameraIcon} />
                </IconButton>
                <Input
                  size="md"
                  name="upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onChangeFile(e)}
                  className="d-none"
                  ref={photoRef}
                />
              </Box>
              <Box className='user_change_profile'>
                <Heading as="h3" className='font-poppins'>{`${userData?.[0]?.first_name}${' '}${userData?.[0]?.last_name}`}</Heading>
                <Heading as="h5" className='font-poppins'>Borrower ID: <span>{userData?.[0]?.id}</span></Heading>
              </Box>
            </Box>
            <Box>
              <Tabs className='tabs_container'>
                  <TabList className='tabs_list'>
                      {tabNames.map((item, index) => {
                        return (
                          <Tab key={index}>{item.name}</Tab>
                        )
                      })}
                  </TabList>
                  <TabPanels>
                      {tabNames.map((item, index) => {
                        return (
                          <TabPanel key={index} className='tab_panel'>
                            {item.name === 'Personal Details' &&
                              <ProfilePersonalDetails userData={userData} 
                                handleShowDetails={handleShowDetails} unmaskedData={unmaskedData}
                              />
                            }
                            {item.name === 'Bank Details' && <ProfileBankDetails userData={userData}
                              handleShowDetails={handleShowDetails} unmaskedData={unmaskedData}
                            />}
                            {item.name === 'Documents Details' && <ProfileDocumentDetails userData={userData}
                              handleShowDetails={handleShowDetails} unmaskedData={unmaskedData}
                            />}
                            {item.name === 'Escrow Account Details' && <ProfileEscrowDetails userData={userData}
                              handleShowDetails={handleShowDetails} unmaskedData={unmaskedData}
                            />}
                          </TabPanel>
                        )
                      })}
                  </TabPanels>
              </Tabs>
            </Box>
          </GridItem>
          <GridItem colSpan={4}>
            <ProfileSidebar handleOpenDeleteAccount={handleOpenDeleteAccount} handleOpenSetPin={handleOpenSetPin} />
          </GridItem>
        </Grid>
      </Box>
      <DeleteAccount openDeleteAccount={openDeleteAccount} handleCloseDeleteAccount={handleCloseDeleteAccount} />
      <SetPin openSetPin={openSetPin} handleCloseSetPin={handleCloseSetPin} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(BorrowerProfile)