/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import * as routesNames from '../../../constants/routes'
import {getItem} from '../../../utilities/authUtils'
import Login from '../login/Login'
import CPLogin from '../CPPartner/CPLogin'
import Register from '../login/Register'
import Signup from '../login/Signup'
import decode from 'jwt-decode';
import NotFound from '../NotFound'
import { useSelector } from 'react-redux'
import SalesLogin from '../sales/SalesLogin'
import BorrowerRoutes from './BorrowerRoutes'
import InvestorRoutes from './InvestorRoutes'
import AdminRoutes from './AdminRoutes'
import SalesRoutes from './SalesRoutes'
import CPRoutes from './CPRoutes'

const AppRoutes = () => {

  const authState = useSelector((state) => state.authState)
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const idToken = getItem('id_token');
    if (idToken) {
      const decodedToken = decode(idToken);
      setUserRole(decodedToken);
    } else {
      setUserRole(null);
    }
  }, [authState?.verifyMobileOTP, authState?.verifySalesOTP, authState?.createNewUser, authState?.verifyCPOTP]);

  console.log(userRole, 'userRole')

  const PrivateRouteWithoutLogin = ({component: Component, ...rest}) => {
    const reqId = getItem('req_id')
    if (reqId) {
      return <Route {...rest} component={Component} />
    }
    return <Redirect to={{ pathname: routesNames.LOGIN }} />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/" exact to={{pathname: routesNames.LOGIN}} />
        <Route exact path={routesNames.LOGIN} component={Login} />
        <Route exact path={routesNames.CPLOGIN} component={CPLogin}/>
        <Route exact path={routesNames.CPLOGINTOKEN} component={Login}/> 
        <Route exact path={routesNames.SALESLOGIN} component={SalesLogin}/> 
        <PrivateRouteWithoutLogin exact path={routesNames.REGISTER} component={Register}/>
        <PrivateRouteWithoutLogin exact path={routesNames.SIGNUP} component={Signup}/>
        <PrivateRouteWithoutLogin exact path={routesNames.BORROWERSIGNUP} component={Signup}/>
        <PrivateRouteWithoutLogin exact path={routesNames.LENDERSIGNUP} component={Signup}/>
        {
          userRole && userRole?.usertype === 2 ? <BorrowerRoutes /> :
          userRole && userRole?.usertype === 3 ? <InvestorRoutes /> :
          userRole && (userRole?.role === '1' || userRole?.role === '5' || userRole?.role === '4' || userRole?.role === '6' || userRole?.role === '7') ? <AdminRoutes /> :
          userRole && userRole?.role === 'Sales' ? <SalesRoutes /> :
          userRole && userRole?.role === "channel_partner" ? <CPRoutes /> :
          <Route path="*" component={NotFound} />
        }
      </Switch>
    </BrowserRouter>
  )
}

export default AppRoutes
