import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import NewBorrowers from './components/NewBorrowers'
import ApprovedBorrowers from './components/ApprovedBorrowers'
import PendingBorrowers from './components/PendingBorrowers'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll, setItem } from '../../../../utilities/authUtils'
import { useSelector } from 'react-redux'

const tabNames = [
    {
        id: 1,
        name: 'New Borrowers',
    },
    {
        id: 2,
        name: 'Approved Borrowers',
    },
    {
        id: 3,
        name: 'Pending Borrowers',
    },
]

export default function Borrowers(props) {

    const history = useHistory()
    const adminState = useSelector((state) => state.adminState)
    const [selectedTab, setSelectedTab] = useState(tabNames[0].name)
    const [inputValues, setInputValues] = useState({})
    const userData = adminState?.getUserByToken?.data?.data
    const userType = userData?.usertype

    const handleTabChange = (index) => {
        setSelectedTab(tabNames[index].name);
    }

    const handleVerify = (id) => {
        setItem('borrowerId', id)
        history.push(routesNames.PRESANCTIONBORROWERSVERIFY)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

  return (
    <Box className='dashboard_box bg_shadow'>
        <Grid className="main_grid_container align-items-center">
            <GridItem colSpan={8}>
                <Heading as="h2" className='font-poppins page_heading'>Pre Sanction Borrowers List</Heading>
            </GridItem>
            <GridItem colSpan={4}>
                <NavBar data={userData} isAdmin />
            </GridItem>
        </Grid>
        <Box mt={5}>
            <Tabs className='tabs_container' onChange={handleTabChange}>
                <TabList className='tabs_list'>
                    {tabNames?.map((item, index) => {
                        return (
                            <Tab key={index}>{item.name}</Tab>
                        )
                    })}
                </TabList>
                <TabPanels>
                    {tabNames.map((item, index) => {
                        return (
                            <TabPanel key={index} className='tab_panel'>
                                {item.name === 'New Borrowers' &&
                                    <NewBorrowers
                                        tabSelected={selectedTab}
                                        handleVerify={handleVerify}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                        userType={userType}
                                    />
                                }
                                {item.name === 'Approved Borrowers' &&
                                    <ApprovedBorrowers
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                    />
                                }
                                {item.name === 'Pending Borrowers' &&
                                    <PendingBorrowers
                                        tabSelected={selectedTab}
                                        inputValues={inputValues}
                                        handleChange={handleChange}
                                    />
                                }
                            </TabPanel>
                        )
                    })}
                </TabPanels>
            </Tabs>
        </Box>
    </Box>
  )
}
