import React from 'react'
import { Box, Grid, Button,GridItem, Heading, Input, Modal,Select, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'

export default function VerifyEnachDetailsDialog(props) {
console.log("propsauth",props)
console.log("props?.inputValues?.auth_mode",props?.inputValues?.auth_mode)
    const PopupBox = () => (
        <Box className='verify_popup'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="confirm_title font-poppins">Verify Enach Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody className='lending_body' mt={4}>
                    <Grid className="main_grid_container">
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Full Name</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={`${props?.ENachData?.user_data?.first_name}${' '}${props?.ENachData?.user_data?.last_name}`} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Mobile</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.user_data?.mobile} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Bank Name</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.bank_data?.bank_name} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Account Type</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.bank_data?.account_type == 0 ? 'Saving' : 'Current'} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Account Number</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.bank_data?.account_number} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>IFSC</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.bank_data?.ifsc_code} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Email</Heading>
                                <Input size="md" type="text" className="input_box font-poppins" value={props?.ENachData?.user_data?.email} isReadOnly />
                            </Box>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className='font-poppins'>Maximum Debit Amount</Heading>
                                <Input size="md" name="loan_amount" type="number" className="input_box font-poppins" onChange={props.handleChange} value={props?.inputValues?.loan_amount ?? props?.amount ?? ''} />
                            </Box>
                            <Text className="font-poppins additional_text">Loan amount <b>{props?.loanAmount}</b> + 25% of loan amount <b>{props?.additionAmount}</b></Text>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <Box mb={2} className='form_inputs'>
                                <Heading as="h5" className="font-poppins " mb={3}>Select Auth Mode</Heading>
                                <Select placeholder="Please Select" name="auth_mode" variant="filled" onChange={props?.handleChange}
                                    className="input_box_select font-poppins bg_white"
                                    value={props?.inputValues?.auth_mode ?? props?.inputValues?.auth_mode ?? ''}>
                                    {props?.enachAuthModeOption?.map((item) => {
                                        return (
                                            <option key={item.id} value={item.key}>{item.value}</option>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </GridItem>
 
                    </Grid>
                    <Box className='d-flex align-items-center justify-content-center' mt={5}>
                        {/* <Button size="md" type="submit" disabled={(props?.inputValues?.auth_mode =='api' || props?.inputValues?.auth_mode =='physical') ? true : false}  className="btn_confirm-f134 font-poppins" onClick={props.generateENach} >Submit</Button> */}
                        <CustomButton size="md" type="submit" title="Submit" className="btn_confirm font-poppins" onClick={props.generateENach} />
                   
                    </Box>
                </ModalBody>
            </ModalContent>
        </Box>
    )

  return (
    <Modal
        isCentered
        onClose={props.handleCloseDetails}
        isOpen={props.openDetails}
        motionPreset="slideInBottom"
        size='2xl'
    >
    {PopupBox()}
    </Modal>
  )
}
