import React, { useEffect, useState } from 'react'
import {Box, Grid, GridItem, Heading, Stack, Image} from '@chakra-ui/react'
import NavBar from '../../../elements/NavBar'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../redux/actions'
import { getItem, removeItem } from '../../../../utilities/authUtils'
import Loader from '../../../elements/loader'
import { CheckCircleIcon } from '@chakra-ui/icons'
import UserIcon from "../../../../assets/images/userIcon.svg";
import Summary from './components/Summary'
import PersonalDetails from './components/PersonalDetails'
import FinancialDetails from './components/FinancialDetails'
import CreditReport from './components/CreditReport'
import {useHistory} from 'react-router-dom'
import * as routesNames from '../../../../constants/routes'
import { removeAll } from '../../../../utilities/authUtils'
import CustomButton from '../../../elements/customButton'
import LoanConfirmDialog from './components/LoanConfirmDialog'
import CustomToast from '../../../elements/customToast'

const UnderWritingProcess = (props) => {

    const history = useHistory()
    const USERID = getItem('userID')
    const LOANNUMBER = getItem('loanNumber')
    const {addToast} = CustomToast()
    const adminState = useSelector((state) => state.adminState)
    const [step, setStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [userData, setUserData] = useState(null);
    const [activeSteps, setActiveSteps] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [type, setType] = useState(null);
    const [inputValues, setInputValues] = useState({});
    const [selectedCard, setSelectedCard] = useState(null)
    const userDataProfile = adminState?.getUserByToken?.data?.data

    let userByIdData = {
        user_id: USERID,
        loan_number: LOANNUMBER
    }

    useEffect(() => {
        props.actions.getUserDetailsByIdAction(userByIdData)
        props.actions.getUserByTokenAction()
        // props.actions.getBorrowerCreditDataAction({id: '1175'})
    }, [])

    useEffect(() => {
        if (adminState?.getUserDetailsById?.data?.success === true){
            setUserData(adminState?.getUserDetailsById?.data)
            setActiveSteps(adminState?.getUserDetailsById?.data?.data?.occupational_data?.net_monthly_income_underwriter &&
            adminState?.getUserDetailsById?.data?.data?.occupational_data?.total_monthly_obligation ? true : false)
        }
    }, [adminState?.getUserDetailsById])

    useEffect(() => {
        if (adminState?.postRejectBorrower?.data?.success === true){
            removeItem('userID')
            removeItem('loanNumber')
            setInputValues({})
            addToast({message: adminState?.postRejectBorrower?.data?.message, status: 'success'})
            props?.actions?.cleanUpToast()
            history.push(routesNames.UNDERWRITERLOANAPPLICATION)
        } else if (adminState?.postRejectBorrower?.data?.success === false){
            addToast({message: adminState?.postRejectBorrower?.data?.message, status: 'error'})
        }
    }, [adminState?.postRejectBorrower])

    useEffect(() => {
        if (adminState?.postApproveBorrower?.data?.success === true){
            removeItem('userID')
            removeItem('loanNumber')
            addToast({message: adminState?.postApproveBorrower?.data?.message, status: 'success'})
            props?.actions?.cleanUpToast()
            history.push(routesNames.UNDERWRITERLOANAPPLICATION)
        } else if (adminState?.postApproveBorrower?.data?.success === false){
            addToast({message: adminState?.postApproveBorrower?.data?.message, status: 'error'})
        }
    }, [adminState?.postApproveBorrower])

    const nextStep = () => {
        // if (step === 4) {
        //     setStep(1);
        // } else {
        //     setStep(step + 1);
        // }
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1)
    }
    
    const markStepAsCompleted = (stepNumber) => {
        if (!completedSteps.includes(stepNumber)) {
            setCompletedSteps([...completedSteps, stepNumber]);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({...inputValues, [name]: value})
    }

    const handleApplyROI = () => {
        if (!inputValues?.borrower_roi) {
            addToast({message: 'Please Enter ROI', status: 'error'})
            return
        }
        if (inputValues?.borrower_roi < 12) {
            addToast({message: 'Rate of Interest not less than 12%', status: 'error'})
            return
        }
        if (inputValues?.borrower_roi > 36) {
            addToast({message: 'Rate of Interest not greater than 36%', status: 'error'})
            return
        }
        let roiData = {
            user_id: USERID,
            loan_number: LOANNUMBER,
            requested_roi: inputValues?.borrower_roi
        }
        props.actions.getUserDetailsByIdAction(roiData)
    }

    const handleConfirmation = (type) => {
        setType(type)
        setOpenConfirmation(!openConfirmation)
    }

    const handleSelectApplyLoanCard = (item) => {
        setSelectedCard(item)
    }

    const handleReject = () => {
        if (!inputValues?.reason) {
            addToast({message: 'Please enter the reason', status: 'error'})
            return
        }
        let rejectData = {
            loan_id: userData?.data?.loanDetails?.id,
            rejection_reason: '2',
            rejection_reason_comment: inputValues?.reason,
        }
        props.actions.postRejectBorrowerAction(rejectData)
    }

    const handleFinops = () => {
        if (!inputValues?.reason) {
            addToast({message: 'Please enter the reason', status: 'error'})
            return
        }
        let assignData = {
            loan_id: userData?.data?.loanDetails?.id,
            // rejection_reason: '2',
            assign_reason_comment: inputValues?.reason,
        }
        console.log(assignData, 'data')
    }

    const handleApprove = () => {
        if (!selectedCard) {
            addToast({message: 'Please Select atleast one eligible loan details', status: 'error'})
            return
        }
        let approveData = {
            borrower_id: USERID,
            eligible_loan_amt: selectedCard?.loan_amount,
            eligible_loan_tenure: selectedCard?.tenure,
            eligible_loan_interest: selectedCard?.interest_rate,
            eligible_loan_emi: selectedCard?.emi,
            alpha_score: userData?.data?.alpha_rating,
        }
        props.actions.postApproveBorrowerAction(approveData)
    }

    // const handleLogout = () => {
    //     removeAll()
    //     history.push(routesNames.LOGIN)
    // }

    console.log(step, 'step')

  return (
    <>
        {(adminState?.getUserDetailsById?.loading || adminState?.postRejectBorrower?.loading || adminState?.postApproveBorrower?.loading || adminState?.getUserByToken?.loading) &&
            <div>
                <Loader />
            </div>
        }
        <Box className='dashboard_box bg_shadow'>
            <Grid className="main_grid_container align-items-center">
                <GridItem colSpan={8}>
                    <Heading as="h2" className='font-poppins page_heading'>Loan Applications</Heading>
                </GridItem>
                <GridItem colSpan={4}>
                    <NavBar data={userDataProfile} isAdmin />
                </GridItem>
            </Grid>
            <Box className='steps_box process_box' mt={5}>
                <Stack direction="row" className='steps_align'>
                    <StepLabel active={step === 1} completed={completedSteps.includes(4) || activeSteps}>
                        {completedSteps.includes(4) || activeSteps ? <CheckCircleIcon className='icon_size' /> : <span>01</span>} Summary
                    </StepLabel>
                    <StepLabel active={step === 2} completed={completedSteps.includes(2) || activeSteps}>
                        {completedSteps.includes(2) || activeSteps ? <CheckCircleIcon className='icon_size' /> : <span>02</span>} Personal Details
                    </StepLabel>
                    <StepLabel active={step === 3} completed={completedSteps.includes(3) || activeSteps}>
                        {completedSteps.includes(3) || activeSteps ? <CheckCircleIcon className='icon_size' /> : <span>03</span>} Financial Detail
                    </StepLabel>
                    <StepLabel active={step === 4} completed={completedSteps.includes(4) || activeSteps}>
                        {completedSteps.includes(4) || activeSteps ? <CheckCircleIcon className='icon_size' /> : <span>04</span>} Credit Report
                    </StepLabel>
                </Stack>
                <Box mt={5}>
                    <Box className='profile_info'>
                        <Image src={userData?.data?.photo ? userData?.data?.photo : UserIcon} className="user_image" />
                        <Box className='user_profile'>
                            <Heading as="h3" className='font-poppins'>{userData?.data?.first_name}{' '}{userData?.data?.last_name}</Heading>
                            <Heading as="h5" className='font-poppins'>{userData?.data?.mobile}</Heading>
                            <Heading as="h5" className='font-poppins'>{userData?.data?.email}</Heading>
                        </Box>
                    </Box>
                </Box>
                {step === 1 && (
                    <Summary onClick={() => { markStepAsCompleted(1); nextStep();}} userData={userData}
                        handleSelectApplyLoanCard={handleSelectApplyLoanCard} selectedCard={selectedCard}
                        handleChange={handleChange} inputValues={inputValues} handleApplyROI={handleApplyROI} />
                )}
                {step === 2 && (
                    <PersonalDetails onClick={() => { markStepAsCompleted(2); nextStep(); }} userData={userData} />
                )}
                {step === 3 && (
                    <FinancialDetails onClick={() => { markStepAsCompleted(3); nextStep(); }} userData={userData} />
                )}
                {step === 4 && (
                    <CreditReport onClick={() => { markStepAsCompleted(4); nextStep()}} userData={userData} handlePrevious={handlePrevious} />
                )}
                <Box className="kyc_actions btn_gap" mb={4} mt={4}>
                    {!(userData?.data?.occupational_data?.total_monthly_obligation && userData?.data?.occupational_data?.net_monthly_income_underwriter) ?
                        <CustomButton size="md" type="submit" title="Assign To FinOps" className="btn_green_ouline font-poppins" onClick={() => handleConfirmation('finops')} /> : null
                    }
                    <CustomButton size="md" type="submit" title="Reject Loan" className="btn_theme_transparent font-poppins" onClick={() => handleConfirmation('reject')} />
                    {((userData?.data?.occupational_data?.total_monthly_obligation && userData?.data?.occupational_data?.net_monthly_income_underwriter) || userData?.data?.is_not_eligible !== 1) ?
                        <CustomButton size="md" type="submit" title="Approve Loan" className="btn_theme font-poppins" onClick={() => handleConfirmation('approve')} /> :
                    null }
                </Box>
            </Box>
        </Box>
        <LoanConfirmDialog openConfirmation={openConfirmation} handleConfirmation={handleConfirmation} type={type}
            handleChange={handleChange} handleReject={handleReject} inputValues={inputValues} handleApprove={handleApprove}
            handleFinops={handleFinops}
        />
    </>
  )
}

const StepLabel = ({ children, active, completed, onClick }) => {
    return (
      <Box className='step_label'>
        <Heading as="h3" className={completed ? 'tab_heading font-poppins completed' : 
        (active ? 'tab_heading font-poppins visited' : 'tab_heading font-poppins default')} onClick={onClick}>
          {children}
        </Heading>
      </Box>
    );
  };

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
})
  
export default connect(null, mapDispatchToProps)(UnderWritingProcess)
