import React, { useState } from 'react'
import {Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomButton from '../../../../elements/customButton'
import Pagination from '../../../../elements/Pagination';

const tableData = [
    {
      id: 1,
      name: 'John Doe',
      loanNo: 30,
      phone: '5345333563',
      applicationDate: '20th March 2024',
      loanAmount: '24000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 1,
  },
  {
      id: 2,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  {
      id: 3,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 1,
  },
  {
      id: 4,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  {
      id: 5,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  {
      id: 6,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 1,
  },
  {
      id: 7,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  {
      id: 8,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  {
      id: 9,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 1,
  },
  {
      id: 10,
      name: 'Divyansh Garg',
      loanNo: 31,
      phone: '4234252535',
      applicationDate: '22nd March 2024',
      loanAmount: '25000',
      tenure: '365 days',
      loanType: 'New',
      status: 'Active',
      isVerified: 0,
  },
  ];

export default function AssignedFinops() {

    const [currentPage, setCurrentPage] = useState(0);
  
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(tableData.length / PER_PAGE);
  
    const handlePageClick = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    };

  return (
    <Box mt={6} className='custom_table_wrapper'>
        <TableContainer>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>S.No.</Th>
                        <Th>Name</Th>
                        <Th>Loan App. No.</Th>
                        <Th>Phone No.</Th>
                        <Th>Application Date</Th>
                        <Th>Loan Amount</Th>
                        <Th>Tenure</Th>
                        <Th>Loan Type</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tableData?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                        return (
                            <Tr key={index}>
                                <Td>
                                    <Text className="font-poppins table_text">{item.id}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.name}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.loanNo}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.phone}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.applicationDate}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.loanAmount}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.tenure}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.loanType}</Text>
                                </Td>
                                <Td>
                                    <Text className="font-poppins table_text">{item.status}</Text>
                                </Td>
                                <Td>
                                    <Box className='actions_td'>
                                        {item.isVerified === 1 ?
                                            <CustomButton size="md" type="submit" title="Verified" className="btn_table_green font-poppins" /> :
                                            <CustomButton size="md" type="submit" title="Verify" className="btn_table_theme font-poppins" />
                                        }
                                    </Box>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </Box>
  )
}
