import React from 'react'
import {Box, Heading} from '@chakra-ui/react'

export default function Charges() {
  return (
    <Box mt={6} className='charges_box'>
        <Heading as="h4" className='font-poppins'>Penal Interest For Late Payment</Heading>
        <Heading as="h3" className='font-poppins'>36% p.a accrued on a daily basis for the delayed day</Heading>
    </Box> 
  )
}
