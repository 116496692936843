import React, { useState } from 'react'
import {Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import Pagination from '../../../../elements/Pagination';
import CustomButton from '../../../../elements/customButton';
import NoData from '../../../../elements/NoData';

export default function OneShotClosedLoans(props) {

    const reportFile = props?.reportData?.data?.file_url
    const reportItems = props?.reportData?.records
    const tableHeaders = (reportItems && Object.keys(reportItems[0]))
    const [currentPage, setCurrentPage] = useState(0)
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(reportItems?.length / PER_PAGE);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    }

  return (
    <>
      {reportItems?.length > 0 ?
          <Box mt={2}>
              <Box className='d-flex justify-content-end'>
                  <CustomButton size="md" type="submit" title="Download Report" className="btn_theme font-poppins" onClick={() => props.handleDownloadReport(reportFile)} />
              </Box>
              <Box mt={3} className='custom_table_wrapper'>
                  <TableContainer>
                      <Table variant="simple">
                          <Thead>
                              <Tr>
                                  {tableHeaders?.map((item, index) => (
                                      <Th key={index}>{item}</Th>
                                  ))}
                              </Tr>
                          </Thead>
                          <Tbody>
                              {reportItems?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                                  return (
                                      <Tr key={index}>
                                          {tableHeaders?.map((elem, idx) => (
                                              <Td key={idx}>
                                                  <Text className="font-poppins table_text">{item[elem] || 'Not Available'}</Text>
                                              </Td>
                                          ))}
                                      </Tr>
                                  )
                              })}
                          </Tbody>
                      </Table>
                  </TableContainer>
                  <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
              </Box>
          </Box> :
          <NoData title="No Record Found" />
      }
    </>
  )
}
