import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "./assets/scss/main.scss";
import {ChakraProvider} from '@chakra-ui/react'
import configureStore from './redux/store';
import {Provider} from 'react-redux';
import App from "./components/pages/App/App";
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ChakraProvider>
                <App />
            </ChakraProvider>
        </BrowserRouter>
    </Provider>

, document.getElementById("root"));
