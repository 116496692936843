import React, { useState } from 'react'
import { Box, Grid, GridItem, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react'
import CustomCard from '../../../elements/CustomCard'
import Pagination from '../../../elements/Pagination';

export default function LendersPayout(props) {

    const [currentPage, setCurrentPage] = useState(0)
    const data = props?.payoutData
    const PER_PAGE = 20;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(data?.lender?.investors_data?.length / PER_PAGE);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    }

  return (
    <Box mt={6}>
        <Grid className="main_grid_container">
            <GridItem colSpan={3}>
                <CustomCard className="cardOne" title="Number of Investor Registered" value={data?.lender?.number_of_approved_lenders ? data?.lender?.number_of_approved_lenders : 0} />
            </GridItem>
            <GridItem colSpan={3}>
                <CustomCard className="cardTwo" title="Total Investment Amount" value={data?.lender?.total_investment_amount ? '₹' + data?.lender?.total_investment_amount : 0} />
            </GridItem>
            <GridItem colSpan={3}>
                <CustomCard className="cardThree" title="Investor Registration Payout" value={data?.lender?.investor_registration_payout ? '₹' + data?.lender?.investor_registration_payout : 0} />
            </GridItem>
            <GridItem colSpan={3}>
                <CustomCard className="cardFour" title="Total Investment Count" value={data?.payout_summary?.investments_count ? data?.payout_summary?.investments_count : 0} />
            </GridItem>
        </Grid>
        <Box mt={5} className='custom_table_wrapper'>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>S.No.</Th>
                            <Th>User Id</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Loan Count</Th>
                            <Th>Loan Amount</Th>
                            <Th>Personal Loan Count</Th>
                            <Th>Personal Loan Amount</Th>
                            <Th>Bullet Loan Count</Th>
                            <Th>Bullet Loan Amount</Th>
                            <Th>Fixed Loan Count</Th>
                            <Th>Fixed Loan Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.lender?.investors_data?.slice(offset, offset + PER_PAGE)?.map((item, index) => {
                            const serialNumber = offset + index + 1;
                            return (
                                <Tr key={index}>
                                    <Td>
                                        <Text className="font-poppins table_text">{serialNumber}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.id}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.name}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.email}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.loan_count}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">₹{item?.loan_amount}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.personal_loan_count}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">₹{item?.pl_amount}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">{item?.bullet_loan_count}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">₹{item?.bl_amount}</Text>
                                    </Td><Td>
                                        <Text className="font-poppins table_text">{item?.fixed_loan_count}</Text>
                                    </Td>
                                    <Td>
                                        <Text className="font-poppins table_text">₹{item?.fixed_amount}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </Box>
    </Box>
  )
}
